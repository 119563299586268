"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.polyOut = exports.polyInOut = exports.polyIn = void 0;
var exponent = 3;
var polyIn = exports.polyIn = function custom(e) {
  e = +e;
  function polyIn(t) {
    return Math.pow(t, e);
  }
  polyIn.exponent = custom;
  return polyIn;
}(exponent);
var polyOut = exports.polyOut = function custom(e) {
  e = +e;
  function polyOut(t) {
    return 1 - Math.pow(1 - t, e);
  }
  polyOut.exponent = custom;
  return polyOut;
}(exponent);
var polyInOut = exports.polyInOut = function custom(e) {
  e = +e;
  function polyInOut(t) {
    return ((t *= 2) <= 1 ? Math.pow(t, e) : 2 - Math.pow(2 - t, e)) / 2;
  }
  polyInOut.exponent = custom;
  return polyInOut;
}(exponent);