[
  {
    "availability": "regular",
    "avgThrustN": 0.284,
    "burnTimeS": 0.772,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/8A0.3",
    "dataFiles": 3,
    "delays": "1",
    "designation": "MICRO_MAXX_II",
    "diameter": 6,
    "impulseClass": "A",
    "length": 26,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 2.108,
    "motorId": "5f4294d20002310000000001",
    "propInfo": "black powder",
    "propWeightG": 0.5,
    "samples": [
      [ 0, 0 ], [ 0.026, 0.03946 ], [ 0.036, 0.1215 ], [ 0.04, 0.206 ], [ 0.05, 0.4447 ],[ 0.056, 0.6803 ],
      [ 0.06, 0.8996 ], [ 0.066, 1.359 ], [ 0.068, 1.476 ], [ 0.07, 1.567 ], [ 0.074, 1.625 ],
      [ 0.088, 1.19 ], [ 0.112, 0.831 ], [ 0.126, 0.4399 ], [ 0.134, 0.3198 ], [ 0.144, 0.249 ],
      [ 0.174, 0.1657 ], [ 0.206, 0.1919 ], [ 0.258, 0.1262 ], [ 0.342, 0.1601 ], [ 0.366, 0.1386 ],
      [ 0.384, 0.1795 ], [ 0.494, 0.2184 ], [ 0.546, 0.1541 ], [ 0.56, 0.1782 ], [ 0.608, 0.1524 ],
      [ 0.682, 0.2095 ], [ 0.698, 0.1837 ], [ 0.712, 0.2202 ], [ 0.742, 0.209 ], [ 0.768, 0.04035 ],
      [ 0.785, 0 ]
],
    "totImpulseNs": 0.218,
    "totalWeightG": 1,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 0.163,
    "burnTimeS": 0.827,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/8A0.3",
    "dataFiles": 2,
    "delays": "1",
    "designation": "MICRO_MAXX",
    "diameter": 6,
    "impulseClass": "A",
    "infoUrl": "http://www.nar.org/SandT/pdf/Quest/MicroMaxx.pdf",
    "length": 26,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 1.898,
    "motorId": "5f4294d20002310000000002",
    "propInfo": "black powder",
    "propWeightG": 0.4,
    "samples": [
      [ 0, 0 ], [ 0.016, 0.05304 ], [ 0.02, 0.09855 ], [ 0.024, 0.1767 ], [ 0.03, 0.4159 ],
      [ 0.032, 0.4349 ], [ 0.038, 0.658 ], [ 0.052, 1.247 ], [ 0.058, 1.454 ], [ 0.064, 1.701 ],
      [ 0.066, 1.756 ], [ 0.07, 1.952 ], [ 0.074, 2.065 ], [ 0.076, 2.099 ], [ 0.078, 2.099 ],
      [ 0.08, 2.028 ], [ 0.082, 1.912 ], [ 0.084, 1.619 ], [ 0.086, 1.152 ], [ 0.088, 0.7283 ],
      [ 0.09, 0.4336 ], [ 0.092, 0.293 ], [ 0.098, 0.2175 ], [ 0.154, 0.2244 ], [ 0.172, 0.1502 ],
      [ 0.196, 0.09986 ], [ 0.23, 0.0786 ], [ 0.414, 0.06256 ], [ 0.7559, 0.08211 ], [ 0.8219, 0.06076 ],
      [ 0.833, 0.02085 ], [ 0.834, 0 ]
],
    "totImpulseNs": 0.135,
    "totalWeightG": 1.07,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2.48,
    "burnTimeS": 0.23,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/4A2",
    "dataFiles": 2,
    "delays": "2,4",
    "designation": "1/4A2",
    "diameter": 10.5,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/qtrA2.pdf",
    "length": 38,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 5.4,
    "motorId": "5f4294d20002310000000003",
    "propWeightG": 0.75,
    "samples": [
      [ 0, 0 ], [ 0.007, 0.162 ], [ 0.023, 0.65 ], [ 0.041, 1.463 ], [ 0.058, 2.519 ], [ 0.074, 3.738 ],
      [ 0.079, 3.9 ], [ 0.088, 4.915 ], [ 0.097, 5.119 ], [ 0.106, 5.4 ], [ 0.11, 5.119 ],
      [ 0.118, 3.981 ], [ 0.125, 3.656 ], [ 0.132, 3.453 ], [ 0.136, 3.209 ], [ 0.151, 3.169 ],
      [ 0.156, 2.966 ], [ 0.168, 2.884 ], [ 0.18, 2.397 ], [ 0.194, 1.625 ], [ 0.207, 1.056 ],
      [ 0.218, 0.406 ], [ 0.23, 0 ]
],
    "totImpulseNs": 0.57,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2.36,
    "burnTimeS": 0.25,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/4A3",
    "dataFiles": 2,
    "delays": "3",
    "designation": "1/4A3",
    "diameter": 13,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/qtrA3.pdf",
    "length": 45,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 4.95,
    "motorId": "5f4294d20002310000000004",
    "propInfo": "black powder",
    "propWeightG": 0.83,
    "samples": [
      [ 0, 0 ], [ 0.016, 0.243 ], [ 0.044, 1.164 ], [ 0.08, 2.698 ], [ 0.088, 2.851 ], [ 0.096, 3.312 ],
      [ 0.105, 3.804 ], [ 0.116, 4.325 ], [ 0.129, 4.754 ], [ 0.131, 4.754 ], [ 0.135, 4.95 ],
      [ 0.139, 4.815 ], [ 0.143, 4.814 ], [ 0.149, 4.66 ], [ 0.157, 4.289 ], [ 0.173, 3.548 ],
      [ 0.187, 2.808 ], [ 0.194, 2.592 ], [ 0.197, 2.13 ], [ 0.202, 1.913 ], [ 0.206, 1.512 ],
      [ 0.213, 1.389 ], [ 0.218, 1.112 ], [ 0.227, 0.802 ], [ 0.236, 0.493 ], [ 0.241, 0.277 ],
      [ 0.25, 0 ]
],
    "totImpulseNs": 0.59,
    "totalWeightG": 6.1,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1.94,
    "burnTimeS": 0.63,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/2A2",
    "dataFiles": 2,
    "delays": "2,4,6",
    "designation": "1/2A2",
    "diameter": 10.5,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/halfA2.pdf",
    "length": 38,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 3.97,
    "motorId": "5f4294d20002310000000005",
    "propWeightG": 1.5,
    "samples": [
      [ 0, 0 ], [ 0.007, 0.19 ], [ 0.045, 1.494 ], [ 0.078, 3.152 ], [ 0.088, 3.805 ], [ 0.093, 3.805 ],
      [ 0.1, 3.97 ], [ 0.105, 3.696 ], [ 0.11, 3.071 ], [ 0.117, 2.554 ], [ 0.123, 2.582 ],
      [ 0.132, 2.31 ], [ 0.163, 2.146 ], [ 0.2, 1.984 ], [ 0.242, 1.902 ], [ 0.253, 2.01 ],
      [ 0.275, 1.929 ], [ 0.342, 1.929 ], [ 0.403, 1.929 ], [ 0.41, 1.848 ], [ 0.42, 1.902 ],
      [ 0.467, 1.902 ], [ 0.528, 1.929 ], [ 0.565, 1.929 ], [ 0.58, 1.902 ], [ 0.593, 1.848 ],
      [ 0.603, 1.657 ], [ 0.61, 1.141 ], [ 0.615, 0.597 ], [ 0.622, 0.244 ], [ 0.63, 0 ]
],
    "totImpulseNs": 1.22,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.03,
    "burnTimeS": 0.36,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/2A3",
    "dataFiles": 2,
    "delays": "2,4",
    "designation": "1/2A3",
    "diameter": 13,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/halfA3T.pdf",
    "length": 45,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 7.62,
    "motorId": "5f4294d20002310000000006",
    "propInfo": "black powder",
    "propWeightG": 2,
    "samples": [
      [ 0, 0 ], [ 0.024, 0.501 ], [ 0.042, 1.454 ], [ 0.064, 3.009 ], [ 0.076, 4.062 ], [ 0.088, 4.914 ],
      [ 0.093, 5.065 ], [ 0.103, 6.068 ], [ 0.112, 6.87 ], [ 0.117, 7.021 ], [ 0.126, 7.62 ],
      [ 0.137, 7.472 ], [ 0.146, 6.87 ], [ 0.153, 6.118 ], [ 0.159, 5.065 ], [ 0.166, 4.363 ],
      [ 0.179, 3.66 ], [ 0.197, 2.908 ], [ 0.222, 2.256 ], [ 0.25, 2.156 ], [ 0.277, 2.106 ],
      [ 0.294, 2.056 ], [ 0.304, 2.156 ], [ 0.316, 1.955 ], [ 0.326, 1.554 ], [ 0.339, 1.053 ],
      [ 0.35, 0.651 ], [ 0.36, 0 ]
],
    "totImpulseNs": 1.09,
    "totalWeightG": 6.6,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.42,
    "burnTimeS": 0.33,
    "certOrg": "National Association of Rocketry",
    "commonName": "1/2A6",
    "dataFiles": 2,
    "delays": "2",
    "designation": "1/2A6",
    "diameter": 18,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/halfA6.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 7.86,
    "motorId": "5f4294d20002310000000007",
    "propInfo": "black powder",
    "propWeightG": 2.6,
    "samples": [
      [ 0, 0 ], [ 0.031, 0.404 ], [ 0.064, 1.258 ], [ 0.096, 2.263 ], [ 0.124, 3.467 ], [ 0.149, 4.72 ],
      [ 0.172, 6.023 ], [ 0.196, 7.027 ], [ 0.21, 7.528 ], [ 0.225, 7.86 ], [ 0.235, 7.482 ],
      [ 0.244, 6.683 ], [ 0.254, 5.685 ], [ 0.263, 4.487 ], [ 0.269, 4.087 ], [ 0.279, 3.039 ],
      [ 0.29, 1.79 ], [ 0.297, 1.042 ], [ 0.306, 0.593 ], [ 0.314, 0.344 ], [ 0.33, 0 ]
],
    "totImpulseNs": 1.13,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1.89,
    "burnTimeS": 1.31,
    "certOrg": "National Association of Rocketry",
    "commonName": "A2",
    "dataFiles": 2,
    "delays": "0,3,5,7",
    "designation": "A2",
    "diameter": 10.5,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/A2.pdf",
    "length": 57,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 4.82,
    "motorId": "5f4294d20002310000000008",
    "propWeightG": 3,
    "samples": [
      [ 0, 0 ], [ 0.014, 0.241 ], [ 0.036, 0.895 ], [ 0.064, 2.618 ], [ 0.1, 4.82 ], [ 0.111, 4.133 ],
      [ 0.125, 2.687 ], [ 0.139, 2.307 ], [ 0.185, 2.031 ], [ 0.296, 1.928 ], [ 0.481, 1.825 ],
      [ 0.517, 1.722 ], [ 0.538, 1.791 ], [ 0.649, 1.688 ], [ 0.748, 1.757 ], [ 0.869, 1.825 ],
      [ 1.04, 1.894 ], [ 1.101, 1.894 ], [ 1.119, 1.825 ], [ 1.144, 1.928 ], [ 1.229, 1.859 ],
      [ 1.265, 1.894 ], [ 1.283, 1.757 ], [ 1.29, 1.412 ], [ 1.293, 0.688 ], [ 1.3, 0.275 ],
      [ 1.31, 0 ]
],
    "totImpulseNs": 2.47,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2.2,
    "burnTimeS": 1.01,
    "certOrg": "National Association of Rocketry",
    "commonName": "A3",
    "dataFiles": 2,
    "delays": "2,4,6",
    "designation": "A3",
    "diameter": 13,
    "impulseClass": "A",
    "infoUrl": "https://nar.org/SandT/pdf/Estes/Estes_A3T_2022.pdf",
    "length": 45,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 5.83,
    "motorId": "5f4294d20002310000000009",
    "propInfo": "black powder",
    "propWeightG": 3.3,
    "samples": [
      [ 0, 0 ], [ 0.024, 0.195 ], [ 0.048, 0.899 ], [ 0.086, 2.658 ], [ 0.11, 4.183 ], [ 0.14, 5.83 ],
      [ 0.159, 5.395 ], [ 0.18, 4.301 ], [ 0.199, 3.635 ], [ 0.215, 2.736 ], [ 0.234, 2.267 ],
      [ 0.258, 2.15 ], [ 0.315, 2.072 ], [ 0.441, 1.993 ], [ 0.554, 2.033 ], [ 0.605, 2.072 ],
      [ 0.673, 1.954 ], [ 0.764, 1.954 ], [ 0.874, 2.072 ], [ 0.931, 2.15 ], [ 0.953, 2.072 ],
      [ 0.966, 1.719 ], [ 0.977, 1.173 ], [ 0.993, 0.547 ], [ 1.01, 0 ]
],
    "totImpulseNs": 2.5,
    "totalWeightG": 8.5,
    "type": "SU",
    "updatedOn": "2022-07-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.16,
    "burnTimeS": 0.55,
    "certOrg": "National Association of Rocketry",
    "commonName": "A6",
    "dataFiles": 2,
    "delays": "4",
    "designation": "A6",
    "diameter": 18,
    "impulseClass": "A",
    "infoUrl": "http://www.nar.org/SandT/pdf/Quest/A6_cn.pdf",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 10.07,
    "motorId": "5f4294d2000231000000000a",
    "propInfo": "black powder",
    "propWeightG": 3.5,
    "samples": [
      [ 0, 0 ], [ 0.1, 4.8 ], [ 0.2, 11.82 ], [ 0.23, 7.9 ], [ 0.3, 4.8 ], [ 0.41, 0 ]
],
    "totImpulseNs": 2.3,
    "totalWeightG": 12,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.18,
    "burnTimeS": 0.73,
    "certOrg": "National Association of Rocketry",
    "commonName": "A8",
    "dataFiles": 3,
    "delays": "0,3,5",
    "designation": "A8",
    "diameter": 18,
    "impulseClass": "A",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/A8.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 9.73,
    "motorId": "5f4294d2000231000000000b",
    "propInfo": "black powder",
    "propWeightG": 3.3,
    "samples": [
      [ 0, 0 ], [ 0.016, 1.08 ], [ 0.036, 0.293 ], [ 0.05, 0.259 ], [ 0.06, 0.357 ], [ 0.07, 0.419 ],
      [ 0.08, 0.355 ], [ 0.102, 0.781 ], [ 0.124, 1.443 ], [ 0.152, 2.966 ], [ 0.17, 4.426 ],
      [ 0.202, 7.16 ], [ 0.224, 9.051 ], [ 0.246, 9.555 ], [ 0.256, 9.317 ], [ 0.276, 7.958 ],
      [ 0.294, 6.108 ], [ 0.326, 4.235 ], [ 0.35, 3.813 ], [ 0.376, 3.997 ], [ 0.41, 3.926 ],
      [ 0.452, 3.724 ], [ 0.474, 4.046 ], [ 0.502, 4.092 ], [ 0.532, 4.146 ], [ 0.534, 0 ]
],
    "totImpulseNs": 2.5,
    "totalWeightG": 16.3,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "regular",
    "avgThrustN": 1.78,
    "burnTimeS": 1.06,
    "certOrg": "National Association of Rocketry",
    "commonName": "A10",
    "dataFiles": 3,
    "delays": "0,3,P",
    "designation": "A10",
    "diameter": 13,
    "impulseClass": "A",
    "length": 45,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 9.74,
    "motorId": "5f4294d2000231000000000c",
    "propInfo": "black powder",
    "propWeightG": 3.6,
    "samples": [
      [ 0, 0 ], [ 0.016, 0.492 ], [ 0.04, 0.752 ], [ 0.062, 0.962 ], [ 0.078, 2.051 ], [ 0.096, 2.858 ],
      [ 0.122, 4.611 ], [ 0.138, 6.054 ], [ 0.162, 8.1 ], [ 0.18, 9.502 ], [ 0.198, 9.74 ],
      [ 0.212, 8.204 ], [ 0.224, 4.53 ], [ 0.238, 2.054 ], [ 0.268, 1.414 ], [ 0.292, 1.385 ],
      [ 0.324, 1.067 ], [ 0.364, 1.099 ], [ 0.396, 0.957 ], [ 0.446, 0.995 ], [ 0.49, 0.901 ],
      [ 0.574, 0.909 ], [ 0.676, 0.909 ], [ 0.782, 1.031 ], [ 0.886, 1.048 ], [ 0.98, 1.029 ],
      [ 1.006, 0.995 ], [ 1.038, 1.049 ], [ 1.05, 0.741 ], [ 1.06, 0 ]
],
    "totImpulseNs": 1.88,
    "totalWeightG": 7,
    "type": "SU",
    "updatedOn": "2024-04-26"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1.91,
    "burnTimeS": 2.53,
    "certOrg": "National Association of Rocketry",
    "commonName": "B2",
    "dataFiles": 2,
    "delays": "0,3,5,7,9",
    "designation": "B2",
    "diameter": 10.5,
    "impulseClass": "B",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/B2.pdf",
    "length": 88,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 5.48,
    "motorId": "5f4294d2000231000000000d",
    "propWeightG": 6,
    "samples": [
      [ 0, 0 ], [ 0.057, 1.637 ], [ 0.093, 4.091 ], [ 0.121, 5.48 ], [ 0.143, 4.787 ], [ 0.157, 3.478 ],
      [ 0.207, 2.578 ], [ 0.328, 2.087 ], [ 0.371, 2.087 ], [ 0.406, 1.882 ], [ 0.641, 1.841 ],
      [ 0.869, 1.841 ], [ 1.283, 1.882 ], [ 1.361, 1.882 ], [ 1.397, 1.718 ], [ 1.439, 1.841 ],
      [ 1.532, 1.718 ], [ 1.71, 1.841 ], [ 1.888, 1.882 ], [ 2.095, 1.8 ], [ 2.23, 1.8 ],[ 2.295, 1.677 ],
      [ 2.423, 1.759 ], [ 2.444, 1.637 ], [ 2.466, 0.982 ], [ 2.494, 0.327 ], [ 2.53, 0 ]
],
    "totImpulseNs": 4.82,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.17,
    "burnTimeS": 1.03,
    "certOrg": "National Association of Rocketry",
    "commonName": "B4",
    "dataFiles": 3,
    "delays": "2,4",
    "designation": "B4",
    "diameter": 18,
    "impulseClass": "B",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/B4.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 12.75,
    "motorId": "5f4294d2000231000000000e",
    "propInfo": "black powder",
    "propWeightG": 6,
    "samples": [
      [ 0, 0 ], [ 0.02, 0.418 ], [ 0.04, 1.673 ], [ 0.065, 4.076 ], [ 0.085, 6.69 ], [ 0.105, 9.304 ],
      [ 0.119, 11.5 ], [ 0.136, 12.75 ], [ 0.153, 11.92 ], [ 0.173, 10.67 ], [ 0.187, 9.304 ],
      [ 0.198, 7.214 ], [ 0.207, 5.645 ], [ 0.226, 4.809 ], [ 0.258, 4.182 ], [ 0.326, 3.763 ],
      [ 0.422, 3.554 ], [ 0.549, 3.345 ], [ 0.665, 3.345 ], [ 0.776, 3.345 ], [ 0.863, 3.345 ],
      [ 0.94, 3.449 ], [ 0.991, 3.449 ], [ 1.002, 2.404 ], [ 1.01, 1.254 ], [ 1.03, 0 ]
],
    "totImpulseNs": 5,
    "totalWeightG": 18.85,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "OOP",
    "avgThrustN": 6.17,
    "burnTimeS": 0.75,
    "certOrg": "National Association of Rocketry",
    "commonName": "B6",
    "dataFiles": 2,
    "delays": "0,2,4",
    "designation": "B6",
    "diameter": 18,
    "impulseClass": "B",
    "infoUrl": "http://nar.org/SandT/pdf/Quest/B6~04.pdf",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 14.38,
    "motorId": "5f4294d2000231000000000f",
    "propInfo": "black powder",
    "propWeightG": 6.5,
    "samples": [
      [ 0, 0 ], [ 0.1, 7 ], [ 0.18, 14.38 ], [ 0.2, 10.2 ], [ 0.24, 6.6 ], [ 0.3, 6 ], [ 0.4, 6.1 ],
      [ 0.5, 6.2 ], [ 0.6, 6.3 ], [ 0.65, 6.6 ], [ 0.7, 3 ], [ 0.75, 0 ]
],
    "totImpulseNs": 4.63,
    "totalWeightG": 18.4,
    "type": "SU",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 5.03,
    "burnTimeS": 0.86,
    "certOrg": "National Association of Rocketry",
    "commonName": "B6",
    "dataFiles": 2,
    "delays": "0,2,4,6",
    "designation": "B6",
    "diameter": 18,
    "impulseClass": "B",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/B6.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 12.14,
    "motorId": "5f4294d20002310000000010",
    "propInfo": "black powder",
    "propWeightG": 5.6,
    "samples": [
      [ 0, 0 ], [ 0.036, 1.364 ], [ 0.064, 2.727 ], [ 0.082, 4.215 ], [ 0.111, 6.694 ], [ 0.135, 9.05 ],
      [ 0.146, 9.545 ], [ 0.172, 11.9 ], [ 0.181, 12.15 ], [ 0.191, 11.9 ], [ 0.211, 9.174 ],
      [ 0.239, 7.314 ], [ 0.264, 6.074 ], [ 0.275, 5.95 ], [ 0.333, 5.207 ], [ 0.394, 4.835 ],
      [ 0.445, 4.835 ], [ 0.556, 4.339 ], [ 0.667, 4.587 ], [ 0.723, 4.339 ], [ 0.78, 4.339 ],
      [ 0.793, 4.091 ], [ 0.812, 2.603 ], [ 0.833, 1.24 ], [ 0.857, 0 ]
],
    "totImpulseNs": 4.33,
    "totalWeightG": 19.1,
    "type": "SU",
    "updatedOn": "2022-11-30"
  },
  {
    "availability": "OOP",
    "avgThrustN": 6.72,
    "burnTimeS": 0.74,
    "certOrg": "National Association of Rocketry",
    "commonName": "B7",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "B7",
    "diameter": 13,
    "impulseClass": "B",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/B7.pdf",
    "length": 50,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 14.76,
    "motorId": "5f4294d20002310000000012",
    "propWeightG": 2.8,
    "samples": [
      [ 0, 0 ], [ 0.007, 5.708 ], [ 0.013, 7.211 ], [ 0.032, 6.111 ], [ 0.045, 8.116 ], [ 0.056, 7.717 ],
      [ 0.069, 9.02 ], [ 0.078, 12.12 ], [ 0.087, 14.76 ], [ 0.106, 13.83 ], [ 0.117, 13.73 ],
      [ 0.125, 12.64 ], [ 0.155, 12.44 ], [ 0.168, 11.84 ], [ 0.2, 11.24 ], [ 0.209, 11.74 ],
      [ 0.219, 10.74 ], [ 0.266, 9.846 ], [ 0.29, 9.849 ], [ 0.299, 8.949 ], [ 0.367, 7.456 ],
      [ 0.393, 7.159 ], [ 0.429, 5.761 ], [ 0.487, 4.567 ], [ 0.571, 2.975 ], [ 0.607, 2.178 ],
      [ 0.669, 1.084 ], [ 0.708, 0.489 ], [ 0.74, 0 ]
],
    "totImpulseNs": 4.97,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3.8,
    "burnTimeS": 2.35,
    "certOrg": "National Association of Rocketry",
    "commonName": "C4",
    "dataFiles": 2,
    "delays": "3,5,7",
    "designation": "C4",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/C4.pdf",
    "length": 50,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 11.31,
    "motorId": "5f4294d20002310000000013",
    "propWeightG": 4.5,
    "samples": [
      [ 0, 0 ], [ 0.018, 3.23 ], [ 0.041, 6.874 ], [ 0.147, 8.779 ], [ 0.294, 10.68 ], [ 0.365, 11.31 ],
      [ 0.388, 10.52 ], [ 0.412, 8.779 ], [ 0.441, 7.04 ], [ 0.465, 4.555 ], [ 0.529, 3.479 ],
      [ 0.629, 2.981 ], [ 0.653, 3.23 ], [ 0.718, 2.816 ], [ 0.853, 2.733 ], [ 1.065, 2.65 ],
      [ 1.253, 2.567 ], [ 1.453, 2.401 ], [ 1.694, 2.484 ], [ 1.794, 2.484 ], [ 1.812, 2.733 ],
      [ 1.841, 2.401 ], [ 1.947, 2.401 ], [ 2.112, 2.401 ], [ 2.235, 2.401 ], [ 2.282, 2.236 ],
      [ 2.312, 1.656 ], [ 2.329, 0.662 ], [ 2.35, 0 ]
],
    "totImpulseNs": 8.92,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.91,
    "burnTimeS": 1.99,
    "certOrg": "National Association of Rocketry",
    "commonName": "C5",
    "dataFiles": 4,
    "delays": "3",
    "designation": "C5",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "https://nar.org/SandT/pdf/Estes/Estes_C5_2019.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 20.41,
    "motorId": "5f4294d20002310000000014",
    "propInfo": "black powder",
    "propWeightG": 11.3,
    "samples": [
      [ 0, 0 ], [ 0.005, 1.422 ], [ 0.031, 2.695 ], [ 0.063, 5.503 ], [ 0.117, 10.44 ], [ 0.151, 14.37 ],
      [ 0.168, 16.36 ], [ 0.174, 16.47 ], [ 0.179, 17.59 ], [ 0.199, 18.6 ], [ 0.211, 19.88 ],
      [ 0.217, 20.4 ], [ 0.23, 20.29 ], [ 0.234, 18.68 ], [ 0.239, 18.23 ], [ 0.252, 13.81 ],
      [ 0.258, 9.021 ], [ 0.265, 6.588 ], [ 0.28, 4.904 ], [ 0.296, 4.08 ], [ 0.349, 3.481 ],
      [ 0.438, 2.995 ], [ 0.504, 2.733 ], [ 0.611, 2.807 ], [ 0.815, 2.695 ], [ 0.97, 2.695 ],
      [ 1.335, 2.695 ], [ 1.598, 2.658 ], [ 1.794, 2.695 ], [ 1.96, 2.733 ], [ 1.987, 1.16 ],
      [ 1.992, 0 ]
],
    "totImpulseNs": 7.79,
    "totalWeightG": 24.8,
    "type": "SU",
    "updatedOn": "2022-07-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.74,
    "burnTimeS": 1.86,
    "certOrg": "National Association of Rocketry",
    "commonName": "C6",
    "dataFiles": 3,
    "delays": "0,3,5,7",
    "designation": "C6",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/C6.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 14.09,
    "motorId": "5f4294d20002310000000015",
    "propInfo": "black powder",
    "propWeightG": 10.8,
    "samples": [
      [ 0, 0 ], [ 0.031, 0.946 ], [ 0.092, 4.826 ], [ 0.139, 9.936 ], [ 0.192, 14.09 ], [ 0.209, 11.45 ],
      [ 0.231, 7.381 ], [ 0.248, 6.151 ], [ 0.292, 5.489 ], [ 0.37, 4.921 ], [ 0.475, 4.448 ],
      [ 0.671, 4.258 ], [ 0.702, 4.542 ], [ 0.723, 4.164 ], [ 0.85, 4.448 ], [ 1.063, 4.353 ],
      [ 1.211, 4.353 ], [ 1.242, 4.069 ], [ 1.303, 4.258 ], [ 1.468, 4.353 ], [ 1.656, 4.448 ],
      [ 1.821, 4.448 ], [ 1.834, 2.933 ], [ 1.847, 1.325 ], [ 1.86, 0 ]
],
    "totImpulseNs": 8.82,
    "totalWeightG": 24.1,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.45,
    "burnTimeS": 2.54,
    "certOrg": "National Association of Rocketry",
    "commonName": "C6",
    "dataFiles": 2,
    "delays": "0,3,5",
    "designation": "C6",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "http://www.nar.org/SandT/pdf/Quest/C6_cn.pdf",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 15.46,
    "motorId": "5f4294d20002310000000016",
    "propInfo": "black powder",
    "propWeightG": 12,
    "samples": [
      [ 0, 0 ], [ 0.02, 0.497 ], [ 0.057, 2.539 ], [ 0.089, 5.132 ], [ 0.129, 7.947 ], [ 0.159, 9.437 ],
      [ 0.171, 21.25 ], [ 0.181, 23.23 ], [ 0.194, 22.96 ], [ 0.204, 22.18 ], [ 0.218, 19.59 ],
      [ 0.233, 17.88 ], [ 0.258, 10.49 ], [ 0.308, 2.428 ], [ 0.338, 2.539 ], [ 0.385, 2.98 ],
      [ 0.412, 3.091 ], [ 0.442, 3.422 ], [ 0.459, 2.98 ], [ 0.536, 3.256 ], [ 0.732, 3.311 ],
      [ 0.747, 2.483 ], [ 0.78, 2.98 ], [ 1.323, 3.587 ], [ 1.365, 2.815 ], [ 1.887, 3.808 ],
      [ 1.974, 3.256 ], [ 2.1, 3.532 ], [ 2.227, 3.201 ], [ 2.247, 0 ]
],
    "totImpulseNs": 8.76,
    "totalWeightG": 21,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 7.56,
    "burnTimeS": 1.32,
    "certOrg": "National Association of Rocketry",
    "commonName": "C6",
    "dataFiles": 2,
    "delays": "4,7,10",
    "designation": "C6",
    "diameter": 13,
    "impulseClass": "C",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/C6.pdf",
    "length": 83,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 21.1,
    "motorId": "5f4294d20002310000000018",
    "propWeightG": 7,
    "samples": [
      [ 0, 0 ], [ 0.008, 13.96 ], [ 0.016, 21.1 ], [ 0.022, 15.51 ], [ 0.03, 12.83 ], [ 0.052, 14.8 ],
      [ 0.081, 15.93 ], [ 0.092, 14.66 ], [ 0.114, 16.07 ], [ 0.125, 14.66 ], [ 0.136, 15.37 ],
      [ 0.168, 14.8 ], [ 0.214, 13.82 ], [ 0.225, 12.97 ], [ 0.247, 13.96 ], [ 0.252, 12.83 ],
      [ 0.285, 12.55 ], [ 0.307, 12.4 ], [ 0.317, 12.83 ], [ 0.328, 11.56 ], [ 0.347, 11.99 ],
      [ 0.393, 11.42 ], [ 0.442, 10.72 ], [ 0.464, 11.14 ], [ 0.488, 9.164 ], [ 0.545, 8.459 ],
      [ 0.624, 7.754 ], [ 0.716, 6.485 ], [ 0.838, 5.075 ], [ 0.977, 3.102 ], [ 1.096, 1.833 ],
      [ 1.207, 0.986 ], [ 1.32, 0 ]
],
    "totImpulseNs": 9.98,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 9.96,
    "burnTimeS": 0.96,
    "certOrg": "National Association of Rocketry",
    "commonName": "C10",
    "dataFiles": 2,
    "delays": "4,7,10",
    "designation": "C10",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/C10.pdf",
    "length": 50,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 25.72,
    "motorId": "5f4294d20002310000000019",
    "propWeightG": 4.9,
    "samples": [
      [ 0, 0 ], [ 0.01, 2.712 ], [ 0.019, 5.842 ], [ 0.029, 17.12 ], [ 0.037, 25.72 ], [ 0.051, 22.54 ],
      [ 0.07, 20.45 ], [ 0.106, 18.98 ], [ 0.164, 17.09 ], [ 0.188, 17.09 ], [ 0.2, 15.82 ],
      [ 0.216, 16.04 ], [ 0.255, 15.6 ], [ 0.293, 14.35 ], [ 0.343, 13.5 ], [ 0.394, 12.65 ],
      [ 0.41, 11.61 ], [ 0.434, 11.61 ], [ 0.521, 9.287 ], [ 0.631, 6.34 ], [ 0.741, 4.021 ],
      [ 0.851, 2.119 ], [ 0.911, 1.48 ], [ 0.945, 1.264 ], [ 0.96, 0 ]
],
    "totImpulseNs": 9.56,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 10.86,
    "burnTimeS": 0.81,
    "certOrg": "National Association of Rocketry",
    "commonName": "C11",
    "dataFiles": 2,
    "delays": "0,3,5,7",
    "designation": "C11",
    "diameter": 24,
    "impulseClass": "C",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/C11.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 21.73,
    "motorId": "5f4294d2000231000000001a",
    "propInfo": "black powder",
    "propWeightG": 12,
    "samples": [
      [ 0, 0 ], [ 0.034, 1.692 ], [ 0.066, 3.782 ], [ 0.107, 7.566 ], [ 0.145, 10.95 ], [ 0.183, 14.83 ],
      [ 0.214, 17.62 ], [ 0.226, 18.21 ], [ 0.256, 20.11 ], [ 0.281, 21.21 ], [ 0.298, 21.73 ],
      [ 0.306, 20.21 ], [ 0.323, 17.32 ], [ 0.337, 14.93 ], [ 0.358, 13.24 ], [ 0.385, 11.95 ],
      [ 0.413, 11.65 ], [ 0.468, 10.95 ], [ 0.539, 10.45 ], [ 0.619, 10.65 ], [ 0.683, 10.65 ],
      [ 0.715, 10.65 ], [ 0.726, 10.05 ], [ 0.74, 8.163 ], [ 0.758, 5.773 ], [ 0.778, 3.185 ],
      [ 0.795, 1.394 ], [ 0.81, 0 ]
],
    "totImpulseNs": 8.8,
    "totalWeightG": 35.23,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2.87,
    "burnTimeS": 6.39,
    "certOrg": "National Association of Rocketry",
    "commonName": "D3",
    "dataFiles": 2,
    "delays": "3,5,7",
    "designation": "D3",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/D3.pdf",
    "length": 77,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 10.64,
    "motorId": "5f4294d2000231000000001b",
    "propWeightG": 9.8,
    "samples": [
      [ 0, 0 ], [ 0.05, 6.79 ], [ 0.168, 8.788 ], [ 0.318, 10.46 ], [ 0.385, 10.07 ], [ 0.402, 7.909 ],
      [ 0.469, 5.432 ], [ 0.486, 3.914 ], [ 0.687, 3.115 ], [ 1.122, 2.876 ], [ 2.06, 2.636 ],
      [ 3.349, 2.397 ], [ 4.639, 2.156 ], [ 5.727, 1.997 ], [ 6.163, 1.837 ], [ 6.263, 3.994 ],
      [ 6.347, 2.317 ], [ 6.364, 0.719 ], [ 6.39, 0 ]
],
    "totImpulseNs": 18.36,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 6.46,
    "burnTimeS": 2.87,
    "caseInfo": "RMS-R/C-24/20-40",
    "certOrg": "National Association of Rocketry",
    "commonName": "D7",
    "dataFiles": 2,
    "delays": "P",
    "designation": "D7-RCT",
    "diameter": 24,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D7TRC.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 10.99,
    "motorId": "5f4294d2000231000000001c",
    "propInfo": "Blue Thunder",
    "propWeightG": 10.5,
    "samples": [
      [ 0, 0 ], [ 0.036, 3.336 ], [ 0.084, 9.326 ], [ 0.101, 10.28 ], [ 0.143, 10.83 ], [ 0.213, 10.99 ],
      [ 0.271, 10.89 ], [ 0.359, 10.69 ], [ 0.471, 10.13 ], [ 0.506, 10.34 ], [ 0.535, 9.929 ],
      [ 0.81, 8.697 ], [ 1.226, 6.713 ], [ 1.589, 5.138 ], [ 1.8, 4.861 ], [ 2.151, 4.581 ],
      [ 2.649, 4.57 ], [ 2.696, 3.887 ], [ 2.748, 2.388 ], [ 2.807, 0.889 ], [ 2.842, 0.207 ],
      [ 2.87, 0 ]
],
    "totImpulseNs": 18.53,
    "totalWeightG": 42.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 9.98,
    "burnTimeS": 1.88,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "D9",
    "dataFiles": 3,
    "delays": "4,5,7",
    "designation": "D9W",
    "diameter": 24,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D9.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 20,
    "motorId": "5f4294d2000231000000001d",
    "propInfo": "White Lightning",
    "propWeightG": 10.1,
    "samples": [
      [ 0, 0 ], [ 0.1, 13.7 ], [ 0.15, 15.4 ], [ 0.2, 16.3 ], [ 0.25, 16.8 ], [ 0.35, 17.2 ],
      [ 0.4, 17.2 ], [ 0.5, 16.8 ], [ 0.65, 15.9 ], [ 0.8, 14.5 ], [ 1.1, 9.2 ], [ 1.25, 7 ],
      [ 1.4, 4.8 ], [ 1.6, 2.5 ], [ 1.9, 0 ]
],
    "totImpulseNs": 18.76,
    "totalWeightG": 47.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 13.39,
    "burnTimeS": 1.4,
    "certOrg": "National Association of Rocketry",
    "commonName": "D10",
    "dataFiles": 2,
    "delays": "3,5,7",
    "designation": "D10",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Apogee/D10.pdf",
    "length": 70,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 25.13,
    "motorId": "5f4294d2000231000000001e",
    "propWeightG": 9.8,
    "samples": [
      [ 0, 0 ], [ 0.011, 14.51 ], [ 0.018, 25.13 ], [ 0.032, 20.94 ], [ 0.079, 19.07 ], [ 0.122, 21.14 ],
      [ 0.136, 19.69 ], [ 0.169, 21.14 ], [ 0.201, 20.73 ], [ 0.223, 21.76 ], [ 0.233, 20.94 ],
      [ 0.255, 21.97 ], [ 0.276, 20.94 ], [ 0.352, 20.73 ], [ 0.402, 20.11 ], [ 0.42, 20.73 ],
      [ 0.459, 20.11 ], [ 0.488, 20.52 ], [ 0.556, 18.24 ], [ 0.671, 15.96 ], [ 0.707, 14.72 ],
      [ 0.729, 15.13 ], [ 0.779, 12.85 ], [ 0.793, 13.47 ], [ 0.836, 11.4 ], [ 0.904, 10.16 ],
      [ 0.926, 10.57 ], [ 0.99, 8.083 ], [ 1.026, 8.498 ], [ 1.123, 6.011 ], [ 1.231, 2.487 ],
      [ 1.342, 0.829 ], [ 1.4, 0 ]
],
    "totImpulseNs": 18.75,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 9.4,
    "burnTimeS": 1.86,
    "certOrg": "National Association of Rocketry",
    "commonName": "D11",
    "dataFiles": 2,
    "designation": "D11",
    "diameter": 24,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/D11.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 26.01,
    "motorId": "5f4294d2000231000000001f",
    "propInfo": "black powder",
    "propWeightG": 24.5,
    "samples": [
      [ 0, 0 ], [ 0.033, 2.393 ], [ 0.084, 5.783 ], [ 0.144, 12.17 ], [ 0.214, 20.76 ], [ 0.261, 24.35 ],
      [ 0.289, 26.01 ], [ 0.311, 23.33 ], [ 0.325, 18.53 ], [ 0.338, 14.54 ], [ 0.356, 12.33 ],
      [ 0.398, 10.72 ], [ 0.48, 9.303 ], [ 0.618, 8.676 ], [ 0.761, 8.247 ], [ 0.955, 8.209 ],
      [ 1.222, 7.955 ], [ 1.402, 8.319 ], [ 1.54, 8.291 ], [ 1.701, 8.459 ], [ 1.784, 8.442 ],
      [ 1.803, 6.239 ], [ 1.834, 3.033 ], [ 1.86, 0 ]
],
    "totImpulseNs": 17.49,
    "totalWeightG": 44.8,
    "type": "SU",
    "updatedOn": "2021-07-04"
  },
  {
    "availability": "regular",
    "avgThrustN": 10.21,
    "burnTimeS": 1.65,
    "certOrg": "National Association of Rocketry",
    "commonName": "D12",
    "dataFiles": 2,
    "delays": "0,3,5,7",
    "designation": "D12",
    "diameter": 24,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Estes/D12.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 29.73,
    "motorId": "5f4294d20002310000000020",
    "propInfo": "black powder",
    "propWeightG": 21.1,
    "samples": [
      [ 0, 0 ], [ 0.049, 2.569 ], [ 0.116, 9.369 ], [ 0.184, 17.27 ], [ 0.237, 24.26 ], [ 0.282, 29.73 ],
      [ 0.297, 27.01 ], [ 0.311, 22.59 ], [ 0.322, 17.99 ], [ 0.348, 14.13 ], [ 0.386, 12.1 ],
      [ 0.442, 10.81 ], [ 0.546, 9.876 ], [ 0.718, 9.306 ], [ 0.879, 9.105 ], [ 1.066, 8.901 ],
      [ 1.257, 8.698 ], [ 1.436, 8.31 ], [ 1.59, 8.294 ], [ 1.612, 4.613 ], [ 1.65, 0 ]
],
    "totImpulseNs": 16.84,
    "totalWeightG": 44,
    "type": "SU",
    "updatedOn": "2022-07-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 12.67,
    "burnTimeS": 1.52,
    "caseInfo": "RMS-18/20",
    "certOrg": "National Association of Rocketry",
    "commonName": "D13",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,10",
    "designation": "D13W",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D13.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 23.61,
    "motorId": "5f4294d20002310000000021",
    "propInfo": "White Lightning",
    "propWeightG": 9.8,
    "samples": [
      [ 0, 0 ], [ 0.03, 13.46 ], [ 0.061, 21.17 ], [ 0.085, 20.62 ], [ 0.127, 21.61 ], [ 0.158, 21.04 ],
      [ 0.182, 22.31 ], [ 0.217, 22.59 ], [ 0.227, 23.61 ], [ 0.248, 21.89 ], [ 0.279, 23.16 ],
      [ 0.317, 22.04 ], [ 0.366, 21.34 ], [ 0.383, 21.9 ], [ 0.449, 20.65 ], [ 0.462, 21.49 ],
      [ 0.48, 19.95 ], [ 0.507, 19.95 ], [ 0.521, 20.51 ], [ 0.559, 18.69 ], [ 0.58, 19.12 ],
      [ 0.66, 17.58 ], [ 0.743, 15.34 ], [ 0.861, 12.41 ], [ 0.947, 9.329 ], [ 1.068, 5.834 ],
      [ 1.155, 4.158 ], [ 1.172, 4.72 ], [ 1.231, 2.762 ], [ 1.328, 1.928 ], [ 1.404, 1.093 ],
      [ 1.52, 0 ]
],
    "totImpulseNs": 19.26,
    "totalWeightG": 31.9,
    "type": "reload",
    "updatedOn": "2022-03-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 16.49,
    "burnTimeS": 1.15,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "D15",
    "dataFiles": 2,
    "delays": "4,5,7",
    "designation": "D15T",
    "diameter": 24,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D15.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 31.36,
    "motorId": "5f4294d20002310000000022",
    "propInfo": "Blue Thunder",
    "propWeightG": 8.9,
    "samples": [
      [ 0, 0 ], [ 0.014, 11.48 ], [ 0.049, 26.27 ], [ 0.081, 30.09 ], [ 0.107, 31.26 ], [ 0.121, 31.25 ],
      [ 0.159, 31.36 ], [ 0.208, 31.25 ], [ 0.283, 29.58 ], [ 0.439, 23.35 ], [ 0.551, 18.48 ],
      [ 0.675, 13.43 ], [ 0.863, 6.422 ], [ 0.938, 3.892 ], [ 1.01, 2.335 ], [ 1.085, 0.778 ],
      [ 1.142, 0.389 ], [ 1.15, 0 ]
],
    "totImpulseNs": 18.96,
    "totalWeightG": 46.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 20.84,
    "burnTimeS": 0.94,
    "certOrg": "National Association of Rocketry",
    "commonName": "D21",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "D21T",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D21.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 32.12,
    "motorId": "5f4294d20002310000000023",
    "propInfo": "Blue Thunder",
    "propWeightG": 9.6,
    "samples": [
      [ 0, 0 ], [ 0.01, 1.367 ], [ 0.021, 19.37 ], [ 0.029, 32.12 ], [ 0.037, 31.67 ], [ 0.051, 30.53 ],
      [ 0.094, 30.07 ], [ 0.115, 31.21 ], [ 0.133, 30.07 ], [ 0.177, 30.76 ], [ 0.189, 29.84 ],
      [ 0.203, 30.53 ], [ 0.226, 29.84 ], [ 0.275, 28.93 ], [ 0.296, 29.39 ], [ 0.331, 28.03 ],
      [ 0.421, 25.97 ], [ 0.478, 24.15 ], [ 0.579, 20.73 ], [ 0.659, 17.77 ], [ 0.739, 14.36 ],
      [ 0.799, 9.569 ], [ 0.852, 4.557 ], [ 0.899, 1.139 ], [ 0.94, 0 ]
],
    "totImpulseNs": 19.59,
    "totalWeightG": 25,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 14.77,
    "burnTimeS": 1.22,
    "caseInfo": "RMS-18/20",
    "certOrg": "National Association of Rocketry",
    "commonName": "D24",
    "dataFiles": 3,
    "delays": "4,5,6,7,8,10",
    "designation": "D24T",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/D24.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 25.52,
    "motorId": "5f4294d20002310000000024",
    "propInfo": "Blue Thunder",
    "propWeightG": 8.7,
    "samples": [
      [ 0, 0 ], [ 0.008, 10 ], [ 0.015, 15 ], [ 0.035, 25.52 ], [ 0.06, 22.5 ], [ 0.1, 21.8 ],
      [ 0.2, 21 ], [ 0.4, 20 ], [ 0.6, 17.8 ], [ 0.8, 14.3 ], [ 0.94, 10 ], [ 1.03, 5.2 ], [ 1.1, 2.45 ],
      [ 1.15, 1 ], [ 1.22, 0 ]
],
    "totImpulseNs": 18.02,
    "totalWeightG": 32,
    "type": "reload",
    "updatedOn": "2022-03-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 6,
    "burnTimeS": 6.67,
    "caseInfo": "RMS 24/20-40 R/C",
    "certOrg": "National Association of Rocketry",
    "commonName": "E6",
    "dataFiles": 1,
    "delays": "4,6,8,P",
    "designation": "E6",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/E6.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000025",
    "propInfo": "Blue Thunder",
    "propWeightG": 21.5,
    "samples": [
      [ 0, 0 ], [ 0.011, 18.09 ], [ 0.109, 19.68 ], [ 0.217, 16.31 ], [ 0.315, 13.47 ], [ 0.457, 11.35 ],
      [ 0.63, 9.043 ], [ 0.804, 7.801 ], [ 0.989, 6.738 ], [ 1.272, 6.028 ], [ 2, 5.851 ],
      [ 3, 5.496 ], [ 4, 5.496 ], [ 4.446, 4.965 ], [ 5.011, 4.965 ], [ 5.533, 4.787 ], [ 5.609, 6.56 ],
      [ 5.707, 4.255 ], [ 6.033, 0 ]
],
    "totImpulseNs": 40,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 5.27,
    "burnTimeS": 7.12,
    "caseInfo": "RMS-R/C-24/20-40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E6",
    "dataFiles": 1,
    "designation": "E6-RCT",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/E6TRC.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 11.9,
    "motorId": "5f4294d20002310000000026",
    "propInfo": "Blue Thunder",
    "propWeightG": 21.5,
    "samples": [
      [ 0, 0 ], [ 0.047, 10.87 ], [ 0.127, 11.69 ], [ 0.19, 11.9 ], [ 0.316, 11.62 ], [ 0.522, 10.59 ],
      [ 0.743, 9.287 ], [ 0.996, 7.842 ], [ 1.249, 6.19 ], [ 1.47, 5.296 ], [ 1.787, 4.747 ],
      [ 2.372, 4.471 ], [ 3.02, 4.403 ], [ 3.747, 4.264 ], [ 4.49, 4.403 ], [ 5.375, 4.333 ],
      [ 6.087, 4.264 ], [ 6.719, 4.264 ], [ 6.877, 4.196 ], [ 6.957, 3.783 ], [ 7.004, 2.614 ],
      [ 7.036, 1.513 ], [ 7.083, 0.55 ], [ 7.12, 0 ]
],
    "totImpulseNs": 37.5,
    "totalWeightG": 50,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 5.41,
    "burnTimeS": 5.43,
    "caseInfo": "RMS-R/C-24/20-40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E7",
    "dataFiles": 2,
    "designation": "E7-RCT",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E7TRC.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 11.58,
    "motorId": "5f4294d20002310000000027",
    "propInfo": "Blue Thunder",
    "propWeightG": 17.1,
    "samples": [
      [ 0, 0 ], [ 0.038, 6.636 ], [ 0.063, 10.06 ], [ 0.087, 11.02 ], [ 0.134, 11.42 ], [ 0.206, 11.58 ],
      [ 0.312, 11.15 ], [ 0.466, 10.74 ], [ 0.667, 9.777 ], [ 0.94, 8.132 ], [ 1.223, 6.281 ],
      [ 1.484, 5.182 ], [ 1.709, 4.701 ], [ 2.112, 4.423 ], [ 2.776, 4.279 ], [ 3.31, 4.205 ],
      [ 3.926, 4.266 ], [ 4.401, 4.192 ], [ 4.638, 4.258 ], [ 4.744, 4.119 ], [ 5.124, 3.979 ],
      [ 5.219, 3.977 ], [ 5.266, 3.156 ], [ 5.313, 1.992 ], [ 5.36, 0.965 ], [ 5.43, 0 ]
],
    "totImpulseNs": 29.35,
    "totalWeightG": 48.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 9.02,
    "burnTimeS": 3.09,
    "certOrg": "National Association of Rocketry",
    "commonName": "E9",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "E9",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/E9.pdf",
    "length": 95,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 19.47,
    "motorId": "5f4294d20002310000000028",
    "propInfo": "black powder",
    "propWeightG": 35.8,
    "samples": [
      [ 0, 0 ], [ 0.046, 1.913 ], [ 0.235, 16.7 ], [ 0.273, 18.43 ], [ 0.326, 14.96 ], [ 0.38, 12.17 ],
      [ 0.44, 10.44 ], [ 0.835, 9.043 ], [ 1.093, 8.87 ], [ 1.496, 8.696 ], [ 1.997, 8.696 ],
      [ 2.498, 8.696 ], [ 3.014, 9.217 ], [ 3.037, 5.043 ], [ 3.067, 1.217 ], [ 3.09, 0 ]
],
    "totImpulseNs": 27.87,
    "totalWeightG": 58,
    "type": "SU",
    "updatedOn": "2021-07-31"
  },
  {
    "availability": "OOP",
    "avgThrustN": 12,
    "burnTimeS": 2.983,
    "caseInfo": "SU 24x102",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "E12",
    "dataFiles": 1,
    "delays": "6,10",
    "designation": "E12",
    "diameter": 24,
    "impulseClass": "E",
    "length": 102,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "motorId": "5f4294d2000231000000002b",
    "propWeightG": 28.3,
    "samples": [
      [ 0, 0 ], [ 0.01, 31.17 ], [ 0.13, 24.43 ], [ 0.17, 23.34 ], [ 1.5, 14.83 ], [ 2, 10.28 ],
      [ 2.5, 4.8 ], [ 2.91, 2.3 ], [ 2.92, 0 ]
],
    "totImpulseNs": 35.8,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 11.22,
    "burnTimeS": 3.05,
    "caseInfo": "RMS-R/C-24/20-40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E12",
    "dataFiles": 2,
    "designation": "E12-RCJ",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E12JRC.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 18.33,
    "motorId": "5f4294d2000231000000002c",
    "propInfo": "Blackjack",
    "propWeightG": 30.3,
    "samples": [
      [ 0, 0 ], [ 0.054, 16.76 ], [ 0.095, 18.33 ], [ 0.197, 16.55 ], [ 0.313, 16.65 ], [ 0.36, 17.21 ],
      [ 0.401, 16.32 ], [ 0.442, 17.55 ], [ 0.476, 16.21 ], [ 0.578, 16.32 ], [ 0.666, 16.76 ],
      [ 0.7, 15.65 ], [ 0.768, 16.32 ], [ 0.89, 16.1 ], [ 1.019, 15.65 ], [ 1.162, 14.98 ],
      [ 1.23, 14.98 ], [ 1.25, 13.97 ], [ 1.291, 14.75 ], [ 1.332, 13.75 ], [ 1.373, 14.2 ],
      [ 1.434, 13.53 ], [ 1.488, 13.75 ], [ 1.597, 12.63 ], [ 1.726, 11.4 ], [ 1.828, 10.62 ],
      [ 1.889, 9.613 ], [ 1.957, 9.613 ], [ 1.998, 8.495 ], [ 2.093, 8.607 ], [ 2.277, 7.042 ],
      [ 2.487, 5.813 ], [ 3.05, 0 ]
],
    "totImpulseNs": 34.22,
    "totalWeightG": 59.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 21.8,
    "burnTimeS": 1.6,
    "certOrg": "National Association of Rocketry",
    "commonName": "E15",
    "dataFiles": 4,
    "delays": "4,6,7,8,10",
    "designation": "E15W",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "https://www.nar.org/SandT/pdf/Aerotech/E15.pdf",
    "length": 65,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 34.9,
    "motorId": "5f4294d2000231000000002d",
    "propInfo": "White Lightning",
    "propWeightG": 16.2,
    "samples": [
      [ 0, 0 ], [ 0.001, 0.02904 ], [ 0.025, 0.2276 ], [ 0.039, 1.394 ], [ 0.049, 2.672 ],
      [ 0.057, 5.817 ], [ 0.061, 7.769 ], [ 0.063, 9.004 ], [ 0.065, 10.72 ], [ 0.073, 20.34 ],
      [ 0.075, 22.41 ], [ 0.081, 27.13 ], [ 0.085, 28.99 ], [ 0.091, 30.54 ], [ 0.105, 32.95 ],
      [ 0.125, 34.26 ], [ 0.139, 34.68 ], [ 0.253, 33.85 ], [ 0.361, 32.08 ], [ 0.631, 29.81 ],
      [ 0.673, 28.83 ], [ 0.771, 27.36 ], [ 0.801, 26.39 ], [ 0.901, 24.21 ], [ 1.085, 18.07 ],
      [ 1.135, 16.89 ], [ 1.161, 15.87 ], [ 1.291, 12.19 ], [ 1.373, 9.381 ], [ 1.509, 4.039 ],
      [ 1.553, 2.722 ], [ 1.603, 1.721 ], [ 1.759, 0 ]
],
    "totImpulseNs": 35,
    "totalWeightG": 49,
    "type": "SU",
    "updatedOn": "2021-09-08"
  },
  {
    "availability": "OOP",
    "avgThrustN": 15.06,
    "burnTimeS": 2.64,
    "caseInfo": "SU 24x70",
    "certOrg": "National Association of Rocketry",
    "commonName": "E15",
    "dataFiles": 1,
    "delays": "4,7",
    "designation": "E15",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/RocketVision/E15.pdf",
    "length": 70,
    "manufacturer": "Rocketvision Flight-Star",
    "manufacturerAbbrev": "RV",
    "maxThrustN": 28.84,
    "motorId": "5f4294d2000231000000002e",
    "propWeightG": 17.8,
    "samples": [
      [ 0, 0 ], [ 0.02, 23.33 ], [ 0.036, 27.32 ], [ 0.058, 28.84 ], [ 0.079, 27.17 ], [ 0.139, 25.64 ],
      [ 0.183, 24.26 ], [ 0.237, 24.11 ], [ 0.297, 22.43 ], [ 0.373, 21.96 ], [ 0.4, 20.89 ],
      [ 0.443, 21.36 ], [ 0.487, 20.44 ], [ 0.617, 19.83 ], [ 0.742, 18.46 ], [ 0.812, 20 ],
      [ 0.85, 18.01 ], [ 0.899, 18.47 ], [ 1.035, 17.71 ], [ 1.1, 16.95 ], [ 1.16, 16.95 ],
      [ 1.377, 15.74 ], [ 1.426, 14.66 ], [ 1.436, 16.2 ], [ 1.463, 14.81 ], [ 1.55, 14.36 ],
      [ 1.572, 15.43 ], [ 1.61, 13.75 ], [ 1.827, 12.84 ], [ 2.126, 10.1 ], [ 2.337, 6.116 ],
      [ 2.538, 1.369 ], [ 2.64, 0 ]
],
    "totImpulseNs": 39.76,
    "totalWeightG": 50.1,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 18.84,
    "burnTimeS": 2,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "E16",
    "dataFiles": 1,
    "delays": "4,5,7",
    "designation": "E16W",
    "diameter": 29,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E16.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 37.2,
    "motorId": "5f4294d20002310000000030",
    "propInfo": "White Lightning",
    "propWeightG": 19,
    "samples": [
      [ 0, 0 ], [ 0.132, 32.22 ], [ 0.221, 37.2 ], [ 0.255, 36.7 ], [ 0.306, 36.7 ], [ 0.371, 35.36 ],
      [ 0.414, 33.78 ], [ 0.437, 34.91 ], [ 0.472, 33.78 ], [ 0.53, 32.89 ], [ 0.553, 31.77 ],
      [ 0.576, 32.44 ], [ 0.638, 29.31 ], [ 0.72, 27.3 ], [ 0.867, 23.94 ], [ 1.083, 19.25 ],
      [ 1.273, 14.32 ], [ 1.458, 9.397 ], [ 1.513, 8.055 ], [ 1.524, 8.279 ], [ 1.555, 6.936 ],
      [ 1.656, 4.474 ], [ 1.814, 1.79 ], [ 2, 0 ]
],
    "totImpulseNs": 37.67,
    "totalWeightG": 109,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 17.07,
    "burnTimeS": 2.14,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E18",
    "dataFiles": 2,
    "delays": "4,5,7",
    "designation": "E18W",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E18.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 30.08,
    "motorId": "5f4294d20002310000000031",
    "propInfo": "White Lightning",
    "propWeightG": 20.7,
    "samples": [
      [ 0, 0 ], [ 0.016, 6.586 ], [ 0.042, 18 ], [ 0.073, 27.14 ], [ 0.098, 29.82 ], [ 0.134, 30.36 ],
      [ 0.17, 30.35 ], [ 0.195, 31.08 ], [ 0.236, 30.35 ], [ 0.287, 30.88 ], [ 0.338, 30.34 ],
      [ 0.368, 30.88 ], [ 0.404, 29.8 ], [ 0.424, 30.69 ], [ 0.465, 29.98 ], [ 0.526, 29.79 ],
      [ 0.592, 29.06 ], [ 0.669, 28.34 ], [ 0.786, 26.91 ], [ 0.908, 23.85 ], [ 1.025, 21.16 ],
      [ 1.157, 17.91 ], [ 1.284, 14.86 ], [ 1.462, 11.34 ], [ 1.66, 7.106 ], [ 1.838, 3.47 ],
      [ 2.006, 1.309 ], [ 2.083, 0.588 ], [ 2.14, 0 ]
],
    "totImpulseNs": 36.54,
    "totalWeightG": 59,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 22.5,
    "burnTimeS": 1.57,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "E23",
    "dataFiles": 2,
    "delays": "4,5,6,8",
    "designation": "E23T",
    "diameter": 29,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E23.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 38.22,
    "motorId": "5f4294d20002310000000032",
    "propInfo": "Blue Thunder",
    "propWeightG": 17.4,
    "samples": [
      [ 0, 0 ], [ 0.024, 16.3 ], [ 0.035, 21.96 ], [ 0.067, 30.79 ], [ 0.09, 35.77 ], [ 0.153, 37.58 ],
      [ 0.2, 38.22 ], [ 0.24, 37.36 ], [ 0.322, 37.58 ], [ 0.393, 35.09 ], [ 0.534, 32.38 ],
      [ 0.727, 27.17 ], [ 0.766, 26.94 ], [ 0.798, 25.13 ], [ 0.908, 21.73 ], [ 1.057, 16.98 ],
      [ 1.187, 12.68 ], [ 1.336, 7.471 ], [ 1.45, 3.169 ], [ 1.497, 1.584 ], [ 1.532, 0.679 ],
      [ 1.57, 0 ]
],
    "totImpulseNs": 35.32,
    "totalWeightG": 104,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 32.53,
    "burnTimeS": 1.22,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E28",
    "dataFiles": 2,
    "delays": "4,5,7",
    "designation": "E28T",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E28.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 50.52,
    "motorId": "5f4294d20002310000000033",
    "propInfo": "Blue Thunder",
    "propWeightG": 18.4,
    "samples": [
      [ 0, 0 ], [ 0.01, 29.86 ], [ 0.018, 45.14 ], [ 0.038, 47.56 ], [ 0.081, 50.52 ], [ 0.106, 48.95 ],
      [ 0.146, 48.26 ], [ 0.161, 48.95 ], [ 0.197, 48.95 ], [ 0.242, 47.56 ], [ 0.313, 46.18 ],
      [ 0.411, 43.06 ], [ 0.494, 40.62 ], [ 0.527, 39.58 ], [ 0.542, 40.27 ], [ 0.562, 38.54 ],
      [ 0.633, 36.46 ], [ 0.683, 34.38 ], [ 0.743, 31.24 ], [ 0.799, 29.16 ], [ 0.877, 26.04 ],
      [ 0.97, 20.83 ], [ 1.006, 17.36 ], [ 1.046, 11.46 ], [ 1.089, 6.943 ], [ 1.132, 3.819 ],
      [ 1.172, 1.735 ], [ 1.22, 0 ]
],
    "totImpulseNs": 39.69,
    "totalWeightG": 55.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 32.38,
    "burnTimeS": 1.02,
    "certOrg": "National Association of Rocketry",
    "commonName": "E30",
    "dataFiles": 3,
    "delays": "4,7",
    "designation": "E30T",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/E30.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 49.3,
    "motorId": "5f4294d20002310000000034",
    "propInfo": "Blue Thunder",
    "propWeightG": 17.8,
    "samples": [
      [ 0, 0 ], [ 0.006, 49.3 ], [ 0.03, 45.7 ], [ 0.038, 47.18 ], [ 0.05, 45.18 ], [ 0.062, 43.7 ],
      [ 0.112, 43.4 ], [ 0.138, 42.82 ], [ 0.206, 42.07 ], [ 0.238, 43.37 ], [ 0.282, 41.58 ],
      [ 0.324, 41.09 ], [ 0.342, 41.73 ], [ 0.362, 40.17 ], [ 0.436, 38.16 ], [ 0.524, 36.4 ],
      [ 0.65, 32.97 ], [ 0.712, 31.05 ], [ 0.836, 25.08 ], [ 0.874, 22.48 ], [ 0.888, 21.51 ],
      [ 0.9, 20.01 ], [ 0.912, 17.18 ], [ 0.924, 13.3 ], [ 0.942, 6.65 ], [ 0.962, 2.755 ],
      [ 0.974, 1.637 ], [ 0.988, 0.781 ], [ 1, 0.429 ], [ 1.01, 0.141 ], [ 1.02, 0 ]
],
    "totImpulseNs": 33.56,
    "totalWeightG": 47,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 10.71,
    "burnTimeS": 7.13,
    "caseInfo": "SU 29x85",
    "certOrg": "National Association of Rocketry",
    "commonName": "F10",
    "dataFiles": 1,
    "delays": "4,6,8",
    "designation": "F10",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F10.pdf",
    "length": 85,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 28.22,
    "motorId": "5f4294d20002310000000035",
    "propWeightG": 40.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 16.81 ], [ 0.03, 22.34 ], [ 0.11, 22.23 ], [ 0.26, 21.49 ], [ 0.37, 20 ],
      [ 0.47, 20.21 ], [ 0.67, 18.09 ], [ 0.99, 15.74 ], [ 1.31, 13.4 ], [ 1.81, 10.85 ],[ 2.49, 10.21 ],
      [ 3.13, 8.94 ], [ 3.6, 8.83 ], [ 4.11, 8.62 ], [ 4.95, 8.62 ], [ 5.45, 8.62 ], [ 5.58, 8.51 ],
      [ 5.88, 8.72 ], [ 6.22, 8.51 ], [ 6.46, 8.51 ], [ 6.6, 7.77 ], [ 6.71, 7.02 ], [ 6.79, 5.64 ],
      [ 6.91, 3.83 ], [ 6.95, 2.23 ], [ 7, 0.96 ], [ 7.05, 0 ]
],
    "totImpulseNs": 76.33,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 14.74,
    "burnTimeS": 2.93,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "F12",
    "dataFiles": 2,
    "delays": "3,5",
    "designation": "F12J",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F12.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 23.54,
    "motorId": "5f4294d20002310000000036",
    "propInfo": "Blackjack",
    "propWeightG": 30.3,
    "samples": [
      [ 0, 0 ], [ 0.037, 20.89 ], [ 0.054, 22.15 ], [ 0.101, 22.15 ], [ 0.148, 22.57 ], [ 0.165, 23.41 ],
      [ 0.2, 22.42 ], [ 0.281, 22.14 ], [ 0.369, 22.13 ], [ 0.474, 22.27 ], [ 0.526, 23.54 ],
      [ 0.549, 21.98 ], [ 0.637, 22.12 ], [ 0.724, 21.84 ], [ 0.8, 21.41 ], [ 0.823, 22.25 ],
      [ 0.846, 20.71 ], [ 0.881, 21.55 ], [ 0.945, 21.12 ], [ 1.021, 20.7 ], [ 1.114, 20.55 ],
      [ 1.213, 19.3 ], [ 1.382, 18.3 ], [ 1.481, 18.02 ], [ 1.737, 15.34 ], [ 1.79, 17.3 ],
      [ 1.883, 13.94 ], [ 2.051, 11.26 ], [ 2.22, 7.468 ], [ 2.447, 3.671 ], [ 2.709, 1.135 ],
      [ 2.93, 0 ]
],
    "totImpulseNs": 43.2,
    "totalWeightG": 68.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 12.17,
    "burnTimeS": 5.1,
    "caseInfo": "RMS-R/C-32/60-100",
    "certOrg": "National Association of Rocketry",
    "commonName": "F13",
    "dataFiles": 2,
    "designation": "F13-RCT",
    "diameter": 32,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F13RCT.pdf",
    "length": 107,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 19.98,
    "motorId": "5f4294d20002310000000037",
    "propInfo": "Blue Thunder",
    "propWeightG": 32.3,
    "samples": [
      [ 0, 0 ], [ 0.048, 15.31 ], [ 0.084, 18.63 ], [ 0.143, 19.98 ], [ 0.311, 18.97 ], [ 0.538, 18.17 ],
      [ 0.729, 17.14 ], [ 0.992, 15.43 ], [ 1.279, 13.83 ], [ 1.673, 12.46 ], [ 1.984, 11.88 ],
      [ 2.044, 12.23 ], [ 2.139, 11.31 ], [ 2.378, 11.19 ], [ 2.51, 11.08 ], [ 2.558, 12.11 ],
      [ 2.641, 10.86 ], [ 2.976, 10.74 ], [ 3.49, 10.63 ], [ 3.873, 10.51 ], [ 3.992, 10.96 ],
      [ 4.028, 10.63 ], [ 4.41, 10.51 ], [ 4.625, 10.74 ], [ 4.769, 9.941 ], [ 4.829, 8.684 ],
      [ 4.865, 6.742 ], [ 4.96, 3.199 ], [ 5.02, 1.485 ], [ 5.1, 0 ]
],
    "totImpulseNs": 62.07,
    "totalWeightG": 110.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 13.27,
    "burnTimeS": 5.69,
    "caseInfo": "RMS-R/C-32/60-100",
    "certOrg": "National Association of Rocketry",
    "commonName": "F16",
    "dataFiles": 2,
    "designation": "F16-RCJ",
    "diameter": 32,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F16RCJ.pdf",
    "length": 107,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 26.35,
    "motorId": "5f4294d20002310000000038",
    "propInfo": "Blackjack",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.046, 26.35 ], [ 0.116, 22.39 ], [ 0.139, 21.37 ], [ 0.185, 21.89 ], [ 0.22, 20.54 ],
      [ 0.301, 19.7 ], [ 0.498, 18.35 ], [ 0.579, 19.19 ], [ 0.637, 16.49 ], [ 0.718, 18.35 ],
      [ 0.834, 18.35 ], [ 0.95, 18.35 ], [ 1.054, 19.19 ], [ 1.147, 17.85 ], [ 1.181, 18.85 ],
      [ 1.263, 17.34 ], [ 1.436, 18.01 ], [ 1.633, 17.16 ], [ 1.784, 17.34 ], [ 1.865, 18.68 ],
      [ 1.934, 16.83 ], [ 1.981, 17.34 ], [ 2.178, 16.33 ], [ 2.375, 16.33 ], [ 2.502, 18.18 ],
      [ 2.664, 15.66 ], [ 2.896, 15.49 ], [ 3.29, 13.8 ], [ 3.718, 11.61 ], [ 4.181, 9.426 ],
      [ 4.888, 5.891 ], [ 5.69, 0 ]
],
    "totImpulseNs": 75.48,
    "totalWeightG": 140.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 20.8,
    "burnTimeS": 2.49,
    "certOrg": "National Association of Rocketry",
    "commonName": "F20",
    "dataFiles": 3,
    "delays": "4,7",
    "designation": "F20W/L",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/wp-content/uploads/2014/08/F20.pdf",
    "length": 83,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 40.33,
    "motorId": "5f4294d20002310000000039",
    "propInfo": "White Lightning",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.023, 16.43 ], [ 0.043, 28.54 ], [ 0.062, 36.69 ], [ 0.078, 40.33 ], [ 0.12, 37.68 ],
      [ 0.167, 36.93 ], [ 0.213, 36.93 ], [ 0.298, 36.43 ], [ 0.318, 37.17 ], [ 0.333, 35.69 ],
      [ 0.368, 36.18 ], [ 0.395, 35.19 ], [ 0.43, 36.43 ], [ 0.446, 37.17 ], [ 0.481, 34.45 ],
      [ 0.554, 34.44 ], [ 0.946, 30.96 ], [ 0.965, 29.48 ], [ 1.008, 29.73 ], [ 1.062, 27.75 ],
      [ 1.097, 27.5 ], [ 1.136, 26.26 ], [ 1.31, 20.57 ], [ 1.36, 19.82 ], [ 1.438, 17.35 ],
      [ 1.465, 17.35 ], [ 1.527, 15.12 ], [ 1.597, 13.88 ], [ 1.81, 9.176 ], [ 1.969, 6.203 ],
      [ 2.49, 0 ]
],
    "totImpulseNs": 51.75,
    "totalWeightG": 80.2,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 21,
    "burnTimeS": 2.5,
    "caseInfo": "SU 24x95",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F21",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F21W",
    "diameter": 24,
    "impulseClass": "F",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 42,
    "motorId": "5f4294d2000231000000003a",
    "propInfo": "White Lightning",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.0045, 37.23 ], [ 0.009, 42.15 ], [ 0.018, 42.5 ], [ 0.027, 40.51 ], [ 0.0337, 38.87 ],
      [ 0.0427, 38.23 ], [ 0.0517, 37.73 ], [ 0.0607, 36.87 ], [ 0.072, 36.44 ], [ 0.0877, 36.44 ],
      [ 0.1102, 35.37 ], [ 0.135, 35.87 ], [ 0.1552, 35.44 ], [ 0.1732, 36.23 ], [ 0.2025, 35.66 ],
      [ 0.2452, 37.3 ], [ 0.2835, 36.58 ], [ 0.3195, 38.01 ], [ 0.3375, 37.44 ], [ 0.3757, 38.58 ],
      [ 0.396, 38.23 ], [ 0.4297, 39.37 ], [ 0.4454, 38.01 ], [ 0.4747, 38.72 ], [ 0.4882, 37.8 ],
      [ 0.5084, 38.15 ], [ 0.5354, 38.51 ], [ 0.5647, 37.94 ], [ 0.5849, 37.23 ], [ 0.6007, 37.87 ],
      [ 0.6389, 36.87 ], [ 0.6704, 37.16 ], [ 0.7649, 35.94 ], [ 0.9201, 32.95 ], [ 1.006, 30.31 ],
      [ 1.071, 29.74 ], [ 1.264, 24.03 ], [ 1.287, 24.03 ], [ 1.372, 20.82 ], [ 1.393, 20.82 ],
      [ 1.588, 15.48 ], [ 1.611, 15.48 ], [ 2.011, 5.063 ], [ 2.119, 3.423 ], [ 2.241, 2.425 ],
      [ 2.378, 1.498 ], [ 2.493, 0.9271 ], [ 2.515, 0 ]
],
    "totImpulseNs": 55,
    "totalWeightG": 64,
    "type": "SU",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 19.64,
    "burnTimeS": 3.31,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "F22",
    "dataFiles": 2,
    "delays": "5,7",
    "designation": "F22J",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F22.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 31.15,
    "motorId": "5f4294d2000231000000003b",
    "propInfo": "Blackjack",
    "propWeightG": 46.3,
    "samples": [
      [ 0, 0 ], [ 0.014, 11.53 ], [ 0.075, 20.13 ], [ 0.157, 26.57 ], [ 0.293, 29.11 ], [ 0.382, 30.28 ],
      [ 0.45, 29.69 ], [ 0.539, 30.67 ], [ 0.614, 30.09 ], [ 0.662, 31.15 ], [ 0.771, 30.48 ],
      [ 0.948, 29.89 ], [ 0.996, 28.71 ], [ 1.078, 28.14 ], [ 1.187, 27.74 ], [ 1.289, 26.76 ],
      [ 1.337, 26.96 ], [ 1.412, 25.98 ], [ 1.474, 25.01 ], [ 1.515, 26.17 ], [ 1.542, 24.81 ],
      [ 1.706, 22.86 ], [ 1.938, 20.9 ], [ 2.101, 18.17 ], [ 2.129, 19.34 ], [ 2.251, 16.21 ],
      [ 2.402, 13.48 ], [ 2.64, 8.791 ], [ 2.961, 3.32 ], [ 3.31, 0 ]
],
    "totImpulseNs": 65,
    "totalWeightG": 135.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 23.81,
    "burnTimeS": 2.22,
    "certOrg": "National Association of Rocketry",
    "commonName": "F23",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "F23FJ/L",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://www.nar.org/SandT/pdf/AeroTech/F23.pdf",
    "length": 73,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 48.7,
    "motorId": "5f4294d2000231000000003c",
    "propInfo": "Black Max",
    "propWeightG": 32,
    "samples": [
      [ 0, 0 ], [ 0.03, 48.7 ], [ 0.05, 43.11 ], [ 0.08, 41.41 ], [ 0.1, 42.26 ], [ 0.13, 40.84 ],
      [ 0.17, 39.42 ], [ 0.23, 38.85 ], [ 0.27, 38.85 ], [ 0.3, 37.44 ], [ 0.31, 38.57 ],[ 0.36, 37.72 ],
      [ 0.43, 36.59 ], [ 0.5, 36.02 ], [ 0.56, 36.02 ], [ 0.59, 34.6 ], [ 0.69, 33.18 ], [ 0.77, 32.61 ],
      [ 0.85, 31.2 ], [ 0.94, 29.5 ], [ 1.04, 27.79 ], [ 1.18, 24.39 ], [ 1.2, 25.24 ], [ 1.25, 22.97 ],
      [ 1.37, 20.98 ], [ 1.53, 16.73 ], [ 1.69, 12.48 ], [ 1.83, 9.07 ], [ 1.95, 5.11 ], [ 2.07, 2.27 ],
      [ 2.22, 0 ]
],
    "totImpulseNs": 52.85,
    "totalWeightG": 79,
    "type": "SU",
    "updatedOn": "2023-11-08"
  },
  {
    "availability": "regular",
    "avgThrustN": 23,
    "burnTimeS": 3.47,
    "caseInfo": "RMS-R/C-32/60-100",
    "certOrg": "National Association of Rocketry",
    "commonName": "F23",
    "dataFiles": 2,
    "designation": "F23-RCW-SK",
    "diameter": 32,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F23RCWSK.pdf",
    "length": 107,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 36,
    "motorId": "5f4294d2000231000000003d",
    "propInfo": "White Lightning",
    "propWeightG": 37.8,
    "samples": [
      [ 0, 0 ], [ 0.042, 22.64 ], [ 0.133, 28.19 ], [ 0.161, 27.26 ], [ 0.189, 29.57 ], [ 0.252, 31.42 ],
      [ 0.343, 32.58 ], [ 0.399, 32.35 ], [ 0.441, 33.74 ], [ 0.476, 30.73 ], [ 0.539, 33.51 ],
      [ 0.609, 34.2 ], [ 0.777, 34.89 ], [ 0.826, 34.66 ], [ 0.896, 36 ], [ 0.938, 34.66 ],
      [ 1.015, 34.66 ], [ 1.071, 34.2 ], [ 1.12, 33.04 ], [ 1.218, 32.58 ], [ 1.267, 29.81 ],
      [ 1.351, 29.34 ], [ 1.393, 27.73 ], [ 1.54, 26.8 ], [ 1.645, 24.26 ], [ 1.799, 21.25 ],
      [ 1.862, 19.87 ], [ 2.051, 15.48 ], [ 2.317, 11.55 ], [ 2.618, 6.7 ], [ 2.884, 3.234 ],
      [ 3.185, 1.386 ], [ 3.47, 0 ]
],
    "totImpulseNs": 70,
    "totalWeightG": 128.7,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 23,
    "burnTimeS": 3.58,
    "caseInfo": "SU 24x140",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F23",
    "dataFiles": 1,
    "delays": "6,10",
    "designation": "F23",
    "diameter": 24,
    "impulseClass": "F",
    "length": 140,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 39.2,
    "motorId": "5f4294d2000231000000003e",
    "propWeightG": 31.2,
    "samples": [
      [ 0, 0 ], [ 0.01, 24.19 ], [ 0.05, 39 ], [ 0.07, 39.14 ], [ 0.13, 34.58 ], [ 1, 26.94 ],
      [ 2, 19.52 ], [ 2.4, 14 ], [ 2.7, 7.96 ], [ 3, 4.46 ], [ 3.55, 1.27 ], [ 3.58, 0 ]
],
    "totImpulseNs": 67.9,
    "totalWeightG": 76.5,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 22.21,
    "burnTimeS": 2.13,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "F24",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "F24W",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F24.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 40.95,
    "motorId": "5f4294d2000231000000003f",
    "propInfo": "White Lightning",
    "propWeightG": 19,
    "samples": [
      [ 0, 0 ], [ 0.033, 16.44 ], [ 0.112, 40.65 ], [ 0.125, 41.45 ], [ 0.18, 40.93 ], [ 0.245, 40.63 ],
      [ 0.281, 41.02 ], [ 0.355, 40.02 ], [ 0.438, 39.71 ], [ 0.543, 38.23 ], [ 0.603, 37.03 ],
      [ 0.658, 33.78 ], [ 0.685, 34.66 ], [ 0.726, 29.93 ], [ 0.772, 30.22 ], [ 0.951, 26.95 ],
      [ 1.071, 25.17 ], [ 1.107, 23.09 ], [ 1.185, 21.31 ], [ 1.383, 17.14 ], [ 1.649, 10.91 ],
      [ 1.828, 5.869 ], [ 1.938, 2.903 ], [ 1.988, 2.306 ], [ 2.048, 1.412 ], [ 2.13, 0 ]
],
    "totImpulseNs": 47.31,
    "totalWeightG": 62.9,
    "type": "reload",
    "updatedOn": "2022-10-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 25.55,
    "burnTimeS": 3.05,
    "certOrg": "National Association of Rocketry",
    "commonName": "F25",
    "dataFiles": 2,
    "delays": "4,6,7,9",
    "designation": "F25W",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F25W.pdf",
    "length": 98,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 46.79,
    "motorId": "5f4294d20002310000000040",
    "propInfo": "White Lightning",
    "propWeightG": 35.6,
    "samples": [
      [ 0, 0 ], [ 0.039, 57.63 ], [ 0.187, 53.49 ], [ 0.342, 51.24 ], [ 0.5, 47.86 ], [ 1, 33.81 ],
      [ 1.5, 22.94 ], [ 2, 10.13 ], [ 2.207, 4.504 ], [ 2.69, 0 ]
],
    "totImpulseNs": 77.92,
    "totalWeightG": 90,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 26,
    "burnTimeS": 2.39,
    "certOrg": "National Association of Rocketry",
    "commonName": "F26",
    "dataFiles": 2,
    "delays": "6,7,9",
    "designation": "F26FJ",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F26FJ.pdf",
    "length": 98,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 39.2,
    "motorId": "5f4294d20002310000000041",
    "propInfo": "Black Max",
    "propWeightG": 43.1,
    "samples": [
      [ 0, 0 ], [ 0.041, 38.29 ], [ 0.114, 36.32 ], [ 0.293, 34.35 ], [ 0.497, 32.94 ], [ 0.774, 32.38 ],
      [ 1, 31.25 ], [ 1.254, 28.72 ], [ 1.498, 25.34 ], [ 1.743, 22.24 ], [ 2.003, 17.74 ],
      [ 2.077, 15.48 ], [ 2.304, 5.349 ], [ 2.484, 1.689 ], [ 2.61, 0 ]
],
    "totImpulseNs": 62.2,
    "totalWeightG": 101,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 29.12,
    "burnTimeS": 2.72,
    "caseInfo": "SU 24x95",
    "certOrg": "National Association of Rocketry",
    "commonName": "F32",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,13,15",
    "designation": "F32W",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F32.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 55.64,
    "motorId": "5f4294d20002310000000042",
    "propInfo": "White Lightning",
    "propWeightG": 37.7,
    "samples": [
      [ 0, 0 ], [ 0.025, 46.7 ], [ 0.031, 51.85 ], [ 0.061, 55.64 ], [ 0.085, 52.87 ], [ 0.126, 47.37 ],
      [ 0.245, 45.64 ], [ 0.34, 44.95 ], [ 0.394, 42.87 ], [ 0.447, 42.87 ], [ 0.572, 41.14 ],
      [ 0.72, 39.41 ], [ 0.744, 40.78 ], [ 0.786, 38.03 ], [ 1.041, 35.59 ], [ 1.136, 33.18 ],
      [ 1.177, 34.54 ], [ 1.225, 32.82 ], [ 1.379, 31.44 ], [ 1.474, 30.39 ], [ 1.635, 28.31 ],
      [ 1.676, 27.28 ], [ 1.694, 29.68 ], [ 1.712, 26.93 ], [ 1.854, 25.54 ], [ 1.943, 23.82 ],
      [ 2.092, 21.05 ], [ 2.187, 18.29 ], [ 2.276, 13.82 ], [ 2.382, 7.281 ], [ 2.525, 2.457 ],
      [ 2.72, 0 ]
],
    "totImpulseNs": 79.2,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 29.12,
    "burnTimeS": 2.72,
    "caseInfo": "SU 24x124",
    "certOrg": "National Association of Rocketry",
    "commonName": "F32",
    "dataFiles": 1,
    "delays": "5,10,15",
    "designation": "F32",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/RocketVision/F32.pdf",
    "length": 124,
    "manufacturer": "Rocketvision Flight-Star",
    "manufacturerAbbrev": "RV",
    "maxThrustN": 55.64,
    "motorId": "5f4294d20002310000000043",
    "propWeightG": 37.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 50 ], [ 0.05, 56 ], [ 0.1, 48 ], [ 2, 24 ], [ 2.2, 19 ], [ 2.45, 5 ],[ 2.72, 0 ]
],
    "totImpulseNs": 79.2,
    "totalWeightG": 69.5,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 35,
    "burnTimeS": 2.3,
    "caseInfo": "SU 29x112",
    "certOrg": "National Association of Rocketry",
    "commonName": "F35",
    "dataFiles": 2,
    "delays": "4,10",
    "designation": "F35",
    "diameter": 29,
    "impulseClass": "F",
    "length": 112,
    "manufacturer": "Roadrunner Rocketry",
    "manufacturerAbbrev": "Roadrunner",
    "maxThrustN": 50,
    "motorId": "5f4294d20002310000000044",
    "propInfo": "Roadrunner propellant",
    "propWeightG": 40.1,
    "samples": [
      [ 0, 0 ], [ 0.023, 33.7 ], [ 0.04, 44.46 ], [ 0.081, 47.21 ], [ 0.121, 48.58 ], [ 0.166, 49.27 ],
      [ 0.242, 49.55 ], [ 0.315, 51.01 ], [ 0.411, 50.11 ], [ 0.528, 49.71 ], [ 0.664, 48.21 ],
      [ 0.791, 47.26 ], [ 0.896, 46.99 ], [ 1, 45.48 ], [ 1.097, 44.94 ], [ 1.194, 42.34 ],
      [ 1.277, 40.4 ], [ 1.323, 40.71 ], [ 1.356, 37.69 ], [ 1.402, 35.64 ], [ 1.451, 32.75 ],
      [ 1.505, 29.47 ], [ 1.578, 25.49 ], [ 1.675, 20.83 ], [ 1.75, 17.14 ], [ 1.828, 13.02 ],
      [ 1.907, 8.638 ], [ 1.984, 5.075 ], [ 2.049, 2.61 ], [ 2.13, 0 ]
],
    "totImpulseNs": 78,
    "totalWeightG": 111,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 31.67,
    "burnTimeS": 1.6,
    "caseInfo": "RMS-29/60",
    "certOrg": "National Association of Rocketry",
    "commonName": "F37",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "F37W",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F37.pdf",
    "length": 99,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 46.47,
    "motorId": "5f4294d20002310000000045",
    "propInfo": "White Lightning",
    "propWeightG": 28.2,
    "samples": [
      [ 0, 0 ], [ 0.018, 7.251 ], [ 0.053, 13.63 ], [ 0.088, 22.33 ], [ 0.106, 25.23 ], [ 0.141, 26.39 ],
      [ 0.183, 28.41 ], [ 0.26, 37.69 ], [ 0.31, 41.45 ], [ 0.422, 44.03 ], [ 0.524, 45.18 ],
      [ 0.59, 46.47 ], [ 0.682, 45.15 ], [ 0.864, 43.39 ], [ 0.934, 40.47 ], [ 1.042, 35.23 ],
      [ 1.151, 29.7 ], [ 1.246, 25.04 ], [ 1.354, 19.8 ], [ 1.445, 13.4 ], [ 1.498, 7.586 ],
      [ 1.54, 3.226 ], [ 1.6, 0 ]
],
    "totImpulseNs": 50.67,
    "totalWeightG": 109.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 37.34,
    "burnTimeS": 1.33,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "F39",
    "dataFiles": 2,
    "delays": "4,5,6,7,9",
    "designation": "F39T",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F39.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 59.47,
    "motorId": "5f4294d20002310000000046",
    "propInfo": "Blue Thunder",
    "propWeightG": 22.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 45.06 ], [ 0.016, 54.13 ], [ 0.046, 58.32 ], [ 0.079, 59.47 ], [ 0.103, 58.31 ],
      [ 0.13, 57.25 ], [ 0.172, 55.49 ], [ 0.235, 53.74 ], [ 0.321, 51.27 ], [ 0.363, 50.57 ],
      [ 0.387, 49.51 ], [ 0.408, 50.2 ], [ 0.426, 48.8 ], [ 0.453, 47.75 ], [ 0.48, 47.04 ],
      [ 0.68, 41.06 ], [ 0.716, 39.65 ], [ 0.752, 38.94 ], [ 0.809, 36.49 ], [ 0.86, 34.38 ],
      [ 0.893, 33.32 ], [ 0.917, 32.62 ], [ 1, 28.75 ], [ 1.075, 25.25 ], [ 1.105, 22.09 ],
      [ 1.126, 17.2 ], [ 1.144, 13 ], [ 1.174, 8.109 ], [ 1.219, 4.606 ], [ 1.261, 2.5 ],[ 1.33, 0 ]
],
    "totImpulseNs": 49.66,
    "totalWeightG": 60.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 37.91,
    "burnTimeS": 2.06,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "F40",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,10",
    "designation": "F40W",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F40.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 68.07,
    "motorId": "5f4294d20002310000000047",
    "propInfo": "White Lightning",
    "propWeightG": 40,
    "samples": [
      [ 0, 0 ], [ 0.015, 17.78 ], [ 0.049, 41.02 ], [ 0.089, 58.79 ], [ 0.124, 62.9 ], [ 0.148, 65.17 ],
      [ 0.183, 62.44 ], [ 0.242, 68.07 ], [ 0.292, 60.62 ], [ 0.321, 61.52 ], [ 0.415, 60.62 ],
      [ 0.524, 58.33 ], [ 0.741, 52.41 ], [ 0.87, 48.31 ], [ 0.889, 49.22 ], [ 0.914, 47.4 ],
      [ 1.102, 40.11 ], [ 1.285, 33.73 ], [ 1.492, 25.06 ], [ 1.665, 15.95 ], [ 1.808, 8.659 ],
      [ 1.942, 3.19 ], [ 2.06, 0 ]
],
    "totImpulseNs": 78.09,
    "totalWeightG": 126.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 42,
    "burnTimeS": 1.26,
    "certOrg": "National Association of Rocketry",
    "commonName": "F42",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F42T/L",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F42.pdf",
    "length": 83,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 66,
    "motorId": "5f4294d20002310000000048",
    "propInfo": "Blue Thunder",
    "propWeightG": 27,
    "samples": [
      [ 0, 0 ], [ 0.01, 68.69 ], [ 0.029, 65.88 ], [ 0.202, 62.5 ], [ 0.511, 51.8 ], [ 0.739, 43.36 ],
      [ 0.993, 31.53 ], [ 1.02, 29.28 ], [ 1.072, 23.09 ], [ 1.199, 9.572 ], [ 1.262, 4.505 ],
      [ 1.319, 2.815 ], [ 1.47, 0 ]
],
    "totImpulseNs": 52.9,
    "totalWeightG": 75.9,
    "type": "SU",
    "updatedOn": "2022-08-09"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.73,
    "burnTimeS": 1.43,
    "certOrg": "National Association of Rocketry",
    "commonName": "F50",
    "dataFiles": 2,
    "delays": "4,6,7,9",
    "designation": "F50T",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F50.pdf",
    "length": 98,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 79.59,
    "motorId": "5f4294d20002310000000049",
    "propInfo": "Blue Thunder",
    "propWeightG": 37.9,
    "samples": [
      [ 0, 0 ], [ 0.013, 73.76 ], [ 0.0326, 70.38 ], [ 0.267, 69.82 ], [ 0.518, 67 ], [ 0.792, 56.87 ],
      [ 0.906, 50.68 ], [ 1, 44.48 ], [ 1.036, 39.98 ], [ 1.107, 23.65 ], [ 1.199, 6.194 ],
      [ 1.316, 1.126 ], [ 1.43, 0 ]
],
    "totImpulseNs": 76.83,
    "totalWeightG": 84.9,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 53.73,
    "burnTimeS": 1.43,
    "caseInfo": "SU 29x98",
    "certOrg": "National Association of Rocketry",
    "commonName": "F50",
    "dataFiles": 1,
    "designation": "F50T",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/PML/F50T.pdf",
    "length": 98,
    "manufacturer": "Public Missiles, Ltd.",
    "manufacturerAbbrev": "PML",
    "maxThrustN": 79.59,
    "motorId": "5f4294d2000231000000004a",
    "propWeightG": 37.9,
    "samples": [
      [ 0, 0 ], [ 0.01, 37.97 ], [ 0.02, 56.27 ], [ 0.03, 65.08 ], [ 0.12, 71.86 ], [ 0.23, 75.25 ],
      [ 0.33, 77.29 ], [ 0.35, 77.7 ], [ 0.45, 75.25 ], [ 0.59, 71.86 ], [ 0.72, 65.76 ],[ 0.83, 58.98 ],
      [ 1.01, 43.39 ], [ 1.19, 25.76 ], [ 1.25, 15.59 ], [ 1.3, 8.81 ], [ 1.36, 4.75 ], [ 1.42, 0 ]
],
    "totImpulseNs": 76.83,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 51.37,
    "burnTimeS": 1.42,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "F52",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,11",
    "designation": "F52T",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F52.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 78.95,
    "motorId": "5f4294d2000231000000004b",
    "propInfo": "Blue Thunder",
    "propWeightG": 36.6,
    "samples": [
      [ 0, 0 ], [ 0.012, 46.9 ], [ 0.033, 61.78 ], [ 0.056, 69.44 ], [ 0.097, 73.48 ], [ 0.115, 76.64 ],
      [ 0.13, 74.38 ], [ 0.153, 74.82 ], [ 0.168, 78.42 ], [ 0.182, 78.95 ], [ 0.206, 77.96 ],
      [ 0.238, 77.5 ], [ 0.258, 73.89 ], [ 0.314, 72.97 ], [ 0.39, 72.05 ], [ 0.428, 70.68 ],
      [ 0.501, 65.7 ], [ 0.565, 62.98 ], [ 0.688, 58.87 ], [ 0.749, 56.15 ], [ 0.837, 52.52 ],
      [ 0.901, 49.79 ], [ 0.971, 46.16 ], [ 1.088, 39.37 ], [ 1.144, 34.39 ], [ 1.173, 29.42 ],
      [ 1.222, 20.38 ], [ 1.275, 13.15 ], [ 1.339, 5.461 ], [ 1.389, 1.838 ], [ 1.42, 0 ]
],
    "totImpulseNs": 72.95,
    "totalWeightG": 121.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 60,
    "burnTimeS": 1.3,
    "caseInfo": "SU 29x112",
    "certOrg": "National Association of Rocketry",
    "commonName": "F60",
    "dataFiles": 2,
    "delays": "4,7,10",
    "designation": "F60",
    "diameter": 29,
    "impulseClass": "F",
    "length": 112,
    "manufacturer": "Roadrunner Rocketry",
    "manufacturerAbbrev": "Roadrunner",
    "maxThrustN": 90,
    "motorId": "5f4294d2000231000000004c",
    "propInfo": "Rapid propellant",
    "propWeightG": 38.1,
    "samples": [
      [ 0, 0 ], [ 0.013, 45.86 ], [ 0.021, 63.94 ], [ 0.029, 72.29 ], [ 0.041, 75.21 ], [ 0.061, 74.37 ],
      [ 0.087, 76.87 ], [ 0.155, 83.12 ], [ 0.231, 86.44 ], [ 0.309, 88.09 ], [ 0.329, 90.07 ],
      [ 0.345, 88.33 ], [ 0.395, 87.9 ], [ 0.454, 87.21 ], [ 0.514, 87.19 ], [ 0.616, 82.14 ],
      [ 0.699, 77.11 ], [ 0.765, 70.4 ], [ 0.807, 61.61 ], [ 0.859, 51.98 ], [ 0.926, 42.35 ],
      [ 0.978, 33.56 ], [ 1.022, 21.43 ], [ 1.061, 13.06 ], [ 1.101, 6.776 ], [ 1.133, 3.423 ],
      [ 1.19, 0 ]
],
    "totImpulseNs": 77,
    "totalWeightG": 109,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 62,
    "burnTimeS": 1.13,
    "caseInfo": "RMS-29/60",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F62",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "F62T",
    "diameter": 29,
    "impulseClass": "F",
    "length": 89,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 56.5,
    "motorId": "5f4294d2000231000000004d",
    "propInfo": "Blue Thunder",
    "propWeightG": 30.5,
    "samples": [
      [ 0, 0 ], [ 0.0046, 53.64 ], [ 0.0416, 55.27 ], [ 0.0909, 58.36 ], [ 0.1356, 61.64 ],
      [ 0.1649, 64.91 ], [ 0.1864, 67.64 ], [ 0.5085, 67.64 ], [ 0.5701, 64.73 ], [ 0.6687, 60 ],
      [ 0.7427, 55.09 ], [ 0.7982, 49.64 ], [ 0.9029, 48.73 ], [ 0.9492, 24.73 ], [ 0.9661, 20.18 ],
      [ 0.9985, 0 ]
],
    "totImpulseNs": 51,
    "totalWeightG": 109,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 61.92,
    "burnTimeS": 1.21,
    "caseInfo": "SU 24x124",
    "certOrg": "National Association of Rocketry",
    "commonName": "F72",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,13,15",
    "designation": "F72",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/F72.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 98.78,
    "motorId": "5f4294d2000231000000004e",
    "propWeightG": 36.8,
    "samples": [
      [ 0, 0 ], [ 0.012, 62.59 ], [ 0.017, 84.99 ], [ 0.02, 98.78 ], [ 0.03, 94.75 ], [ 0.05, 90.15 ],
      [ 0.069, 82.69 ], [ 0.089, 85.56 ], [ 0.104, 80.39 ], [ 0.136, 83.25 ], [ 0.146, 80.96 ],
      [ 0.176, 82.69 ], [ 0.198, 78.67 ], [ 0.213, 80.96 ], [ 0.253, 80.39 ], [ 0.315, 80.96 ],
      [ 0.38, 79.82 ], [ 0.429, 79.24 ], [ 0.489, 78.09 ], [ 0.523, 78.67 ], [ 0.536, 75.22 ],
      [ 0.675, 73.5 ], [ 0.699, 67.18 ], [ 0.719, 68.33 ], [ 0.747, 64.88 ], [ 0.769, 66.03 ],
      [ 0.858, 60.87 ], [ 0.923, 52.82 ], [ 0.98, 40.2 ], [ 1.012, 29.86 ], [ 1.034, 20.09 ],
      [ 1.089, 11.48 ], [ 1.21, 0 ]
],
    "totImpulseNs": 74.92,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 61.92,
    "burnTimeS": 1.21,
    "caseInfo": "SU 24x124",
    "certOrg": "National Association of Rocketry",
    "commonName": "F72",
    "dataFiles": 1,
    "delays": "5,10,15",
    "designation": "F72",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://nar.org/SandT/pdf/RocketVision/F72.pdf",
    "length": 124,
    "manufacturer": "Rocketvision Flight-Star",
    "manufacturerAbbrev": "RV",
    "maxThrustN": 98.78,
    "motorId": "5f4294d2000231000000004f",
    "propWeightG": 37.7,
    "samples": [
      [ 0, 0 ], [ 0.004, 37.67 ], [ 0.01, 78.08 ], [ 0.017, 97.26 ], [ 0.027, 91.78 ], [ 0.043, 89.73 ],
      [ 0.06, 80.82 ], [ 0.087, 84.93 ], [ 0.101, 78.77 ], [ 0.132, 81.51 ], [ 0.143, 78.77 ],
      [ 0.171, 81.51 ], [ 0.192, 78.08 ], [ 0.215, 80.82 ], [ 0.24, 78.08 ], [ 0.264, 81.51 ],
      [ 0.279, 78.77 ], [ 0.298, 80.14 ], [ 0.517, 76.03 ], [ 0.68, 70.55 ], [ 0.855, 58.22 ],
      [ 0.934, 49.31 ], [ 0.961, 43.15 ], [ 0.996, 31.51 ], [ 1.025, 21.23 ], [ 1.054, 14.38 ],
      [ 1.103, 7.534 ], [ 1.147, 3.425 ], [ 1.196, 0 ]
],
    "totImpulseNs": 74.92,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 10.2,
    "burnTimeS": 8.55,
    "caseInfo": "RMS-R/C-32/60-100",
    "certOrg": "National Association of Rocketry",
    "commonName": "G12",
    "dataFiles": 2,
    "designation": "G12-RCT",
    "diameter": 32,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G12RC.pdf",
    "length": 107,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 20.64,
    "motorId": "5f4294d20002310000000050",
    "propInfo": "Blue Thunder",
    "propWeightG": 51.1,
    "samples": [
      [ 0, 0 ], [ 0.03, 18.55 ], [ 0.117, 19.96 ], [ 0.239, 20.64 ], [ 0.362, 20.11 ], [ 0.519, 18.98 ],
      [ 0.694, 17.14 ], [ 0.886, 15.02 ], [ 1.131, 13.19 ], [ 1.375, 11.91 ], [ 1.689, 11.07 ],
      [ 2.021, 10.36 ], [ 2.422, 10.23 ], [ 3.172, 9.677 ], [ 4.114, 9.267 ], [ 5.039, 8.857 ],
      [ 6.137, 8.733 ], [ 7.132, 8.607 ], [ 7.795, 8.335 ], [ 7.952, 8.196 ], [ 8.074, 8.055 ],
      [ 8.179, 6.924 ], [ 8.319, 4.661 ], [ 8.476, 1.973 ], [ 8.55, 0 ]
],
    "totImpulseNs": 87.22,
    "totalWeightG": 131,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 20,
    "burnTimeS": 5.47,
    "caseInfo": "SU 29x149",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G20",
    "dataFiles": 2,
    "designation": "G20",
    "diameter": 29,
    "impulseClass": "G",
    "length": 149,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 46,
    "motorId": "5f4294d20002310000000051",
    "propWeightG": 62,
    "samples": [
      [ 0, 0 ], [ 0.04637, 46.68 ], [ 0.2782, 30.39 ], [ 0.4791, 26.87 ], [ 1.005, 24.66 ],
      [ 3.478, 22.02 ], [ 4.328, 13.65 ], [ 5.116, 3.083 ], [ 5.47, 0 ]
],
    "totImpulseNs": 113.5,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 22.17,
    "burnTimeS": 5.3,
    "caseInfo": "RMS-29/120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G25",
    "dataFiles": 3,
    "delays": "4,6,8,10",
    "designation": "G25W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G25.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 41.18,
    "motorId": "5f4294d20002310000000052",
    "propInfo": "White Lightning",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 38.05 ], [ 0.029, 21.77 ], [ 0.11, 30.48 ], [ 0.239, 35.77 ], [ 0.496, 40.7 ],
      [ 0.568, 42.12 ], [ 0.592, 45.43 ], [ 0.749, 46.19 ], [ 0.997, 45.71 ], [ 1.255, 44.2 ],
      [ 1.498, 39.94 ], [ 1.999, 31.42 ], [ 2.501, 23.09 ], [ 3.006, 14.2 ], [ 3.503, 7.288 ],
      [ 3.999, 4.07 ], [ 4.371, 1.988 ], [ 4.414, 0 ]
],
    "totImpulseNs": 117.5,
    "totalWeightG": 121,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 30.09,
    "burnTimeS": 3.27,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G33",
    "dataFiles": 2,
    "delays": "5,7",
    "designation": "G33",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G33.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 50.92,
    "motorId": "5f4294d20002310000000053",
    "propWeightG": 72.2,
    "samples": [
      [ 0, 0 ], [ 0.027, 22.64 ], [ 0.061, 42.2 ], [ 0.117, 47.35 ], [ 0.243, 46.68 ], [ 0.34, 46.34 ],
      [ 0.438, 47.38 ], [ 0.48, 50.92 ], [ 0.508, 46.36 ], [ 0.543, 47.73 ], [ 0.662, 45.69 ],
      [ 0.851, 42.28 ], [ 1.039, 41.27 ], [ 1.116, 42.99 ], [ 1.193, 39.23 ], [ 1.221, 42.31 ],
      [ 1.312, 38.89 ], [ 1.326, 40.61 ], [ 1.479, 38.22 ], [ 1.675, 35.16 ], [ 1.843, 32.77 ],
      [ 1.878, 36.89 ], [ 1.899, 32.09 ], [ 1.997, 30.38 ], [ 2.13, 26.62 ], [ 2.263, 23.55 ],
      [ 2.444, 19.11 ], [ 2.591, 13.98 ], [ 2.752, 8.502 ], [ 2.892, 4.743 ], [ 3.053, 2.014 ],
      [ 3.27, 0 ]
],
    "totImpulseNs": 98.39,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 34.65,
    "burnTimeS": 2.91,
    "caseInfo": "SU 29x98",
    "certOrg": "National Association of Rocketry",
    "commonName": "G35",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "G35",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G35.pdf",
    "length": 98,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 76.22,
    "motorId": "5f4294d20002310000000054",
    "propWeightG": 50,
    "samples": [
      [ 0, 0 ], [ 0.01, 39.14 ], [ 0.02, 76.22 ], [ 0.05, 64.46 ], [ 0.13, 57.54 ], [ 0.21, 57.53 ],
      [ 0.24, 64.43 ], [ 0.25, 57.06 ], [ 0.35, 56.12 ], [ 0.43, 55.2 ], [ 0.48, 57.49 ],[ 0.51, 52.41 ],
      [ 0.55, 53.33 ], [ 0.76, 50.54 ], [ 0.91, 50.06 ], [ 1.11, 44.96 ], [ 1.32, 41.24 ],
      [ 1.55, 35.68 ], [ 1.6, 36.13 ], [ 1.63, 33.36 ], [ 1.67, 34.28 ], [ 1.8, 30.12 ], [ 2, 25.02 ],
      [ 2.14, 21.32 ], [ 2.23, 19.46 ], [ 2.3, 15.77 ], [ 2.41, 9.76 ], [ 2.53, 6.52 ], [ 2.65, 3.74 ],
      [ 2.74, 1.88 ], [ 2.91, 0 ]
],
    "totImpulseNs": 100.8,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 35,
    "burnTimeS": 3.9,
    "caseInfo": "SU 29x165",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G35",
    "dataFiles": 2,
    "delays": "6,10",
    "designation": "G35",
    "diameter": 29,
    "impulseClass": "G",
    "length": 165,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 56,
    "motorId": "5f4294d20002310000000055",
    "propWeightG": 62,
    "samples": [
      [ 0, 0 ], [ 0.01, 51.12 ], [ 0.04, 57.55 ], [ 0.08, 43.78 ], [ 2.73, 28.16 ], [ 3.28, 28.16 ],
      [ 3.78, 6.73 ], [ 4, 0 ]
],
    "totImpulseNs": 124,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 37,
    "burnTimeS": 3.1,
    "caseInfo": "SU 24x181",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G37",
    "dataFiles": 2,
    "delays": "6,10,P",
    "designation": "G37",
    "diameter": 24,
    "impulseClass": "G",
    "length": 181,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 68,
    "motorId": "5f4294d20002310000000056",
    "propWeightG": 62,
    "samples": [
      [ 0, 0 ], [ 0.02318, 69.59 ], [ 0.1623, 55.93 ], [ 0.3323, 48.01 ], [ 0.5023, 44.92 ],
      [ 0.9969, 40.96 ], [ 1.499, 38.76 ], [ 2.002, 34.35 ], [ 2.496, 28.19 ], [ 2.751, 18.5 ],
      [ 2.998, 5.285 ], [ 3.1, 0 ]
],
    "totImpulseNs": 110.5,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 40.22,
    "burnTimeS": 2.18,
    "certOrg": "National Association of Rocketry",
    "commonName": "G38",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "G38FJ",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G38.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 78.19,
    "motorId": "5f4294d20002310000000057",
    "propInfo": "Black Max",
    "propWeightG": 55,
    "samples": [
      [ 0, 0 ], [ 0.024, 52.93 ], [ 0.171, 48.42 ], [ 0.497, 45.05 ], [ 1, 42.23 ], [ 1.279, 39.98 ],
      [ 1.498, 36.6 ], [ 1.783, 30.41 ], [ 2.011, 23.09 ], [ 2.272, 10.13 ], [ 2.467, 3.941 ],
      [ 2.64, 0 ]
],
    "totImpulseNs": 87.68,
    "totalWeightG": 126,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 37.17,
    "burnTimeS": 3.06,
    "caseInfo": "SU 29x124",
    "certOrg": "National Association of Rocketry",
    "commonName": "G40",
    "dataFiles": 1,
    "delays": "4,7",
    "designation": "G40W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/PML/G40W.pdf",
    "length": 124,
    "manufacturer": "Public Missiles, Ltd.",
    "manufacturerAbbrev": "PML",
    "maxThrustN": 66.85,
    "motorId": "5f4294d20002310000000058",
    "propWeightG": 62.4,
    "samples": [
      [ 0, 0 ], [ 0.015, 40 ], [ 0.037, 66.48 ], [ 0.066, 60.84 ], [ 0.161, 55.77 ], [ 0.308, 55.77 ],
      [ 0.447, 54.09 ], [ 0.549, 52.39 ], [ 0.637, 51.27 ], [ 0.857, 52.96 ], [ 1.018, 52.39 ],
      [ 1.172, 50.14 ], [ 1.362, 46.76 ], [ 1.611, 41.69 ], [ 1.691, 41.13 ], [ 1.845, 34.37 ],
      [ 1.999, 30.42 ], [ 2.372, 23.1 ], [ 2.585, 13.52 ], [ 2.738, 6.761 ], [ 3.039, 0 ]
],
    "totImpulseNs": 113.7,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 40.88,
    "burnTimeS": 2.38,
    "certOrg": "National Association of Rocketry",
    "commonName": "G40",
    "dataFiles": 2,
    "delays": "4,6,7,8,10",
    "designation": "G40W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G40.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 84.51,
    "motorId": "5f4294d20002310000000059",
    "propInfo": "White Lightning",
    "propWeightG": 53.8,
    "samples": [
      [ 0, 0 ], [ 0.024, 74.33 ], [ 0.057, 67 ], [ 0.252, 65.88 ], [ 0.5, 63.06 ], [ 0.765, 60.25 ],
      [ 1, 54.05 ], [ 1.25, 47.3 ], [ 1.502, 36.6 ], [ 1.751, 25.34 ], [ 1.999, 12.95 ], [ 2.121, 3.941 ],
      [ 2.3, 0 ]
],
    "totImpulseNs": 97.14,
    "totalWeightG": 123,
    "type": "SU",
    "updatedOn": "2022-01-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.68,
    "burnTimeS": 1.51,
    "caseInfo": "RMS-29/100",
    "certOrg": "National Association of Rocketry",
    "commonName": "G54",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G54W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G54.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 81.64,
    "motorId": "5f4294d2000231000000005a",
    "propInfo": "White Lightning",
    "propWeightG": 46,
    "samples": [
      [ 0, 0 ], [ 0.018, 10.95 ], [ 0.042, 39.22 ], [ 0.083, 66.89 ], [ 0.14, 72.08 ], [ 0.223, 74.96 ],
      [ 0.25, 76.69 ], [ 0.282, 80.16 ], [ 0.315, 79.58 ], [ 0.336, 79.58 ], [ 0.354, 81.64 ],
      [ 0.365, 77.84 ], [ 0.374, 80.72 ], [ 0.389, 76.69 ], [ 0.455, 76.12 ], [ 0.523, 74.39 ],
      [ 0.639, 70.93 ], [ 0.722, 67.47 ], [ 0.82, 64 ], [ 0.897, 58.82 ], [ 0.992, 51.89 ],
      [ 1.084, 43.82 ], [ 1.197, 34.02 ], [ 1.268, 28.25 ], [ 1.283, 29.99 ], [ 1.295, 27.1 ],
      [ 1.328, 23.64 ], [ 1.366, 16.72 ], [ 1.399, 9.803 ], [ 1.435, 4.612 ], [ 1.51, 0 ]
],
    "totImpulseNs": 81.05,
    "totalWeightG": 136.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 49,
    "burnTimeS": 2.44,
    "caseInfo": "SU 24x177",
    "certOrg": "National Association of Rocketry",
    "commonName": "G55",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,13,15",
    "designation": "G55",
    "diameter": 24,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G55.pdf",
    "length": 177,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 84.65,
    "motorId": "5f4294d2000231000000005b",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.009, 81.14 ], [ 0.014, 84.65 ], [ 0.034, 80.56 ], [ 0.084, 77.06 ], [ 0.13, 71.82 ],
      [ 0.18, 72.42 ], [ 0.206, 68.92 ], [ 0.342, 69.54 ], [ 0.483, 68.99 ], [ 0.513, 66.66 ],
      [ 0.543, 68.42 ], [ 0.664, 66.11 ], [ 0.876, 66.16 ], [ 0.901, 64.42 ], [ 0.997, 65.03 ],
      [ 1.062, 66.79 ], [ 1.088, 63.88 ], [ 1.148, 63.89 ], [ 1.158, 66.81 ], [ 1.173, 63.31 ],
      [ 1.209, 62.74 ], [ 1.325, 61.59 ], [ 1.395, 59.28 ], [ 1.456, 57.54 ], [ 1.486, 58.13 ],
      [ 1.587, 52.32 ], [ 1.708, 40.09 ], [ 1.824, 26.11 ], [ 1.95, 15.63 ], [ 2.112, 7.498 ],
      [ 2.258, 3.446 ], [ 2.44, 0 ]
],
    "totImpulseNs": 119.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 49,
    "burnTimeS": 2.44,
    "caseInfo": "SU 24x177",
    "certOrg": "National Association of Rocketry",
    "commonName": "G55",
    "dataFiles": 1,
    "delays": "5,10,15",
    "designation": "G55",
    "diameter": 24,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/RocketVision/G55.pdf",
    "length": 177,
    "manufacturer": "Rocketvision Flight-Star",
    "manufacturerAbbrev": "RV",
    "maxThrustN": 84.65,
    "motorId": "5f4294d2000231000000005c",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.004, 74.65 ], [ 0.012, 85.21 ], [ 0.054, 81.69 ], [ 0.128, 73.24 ], [ 0.182, 73.94 ],
      [ 0.231, 69.72 ], [ 0.508, 69.01 ], [ 0.868, 67.61 ], [ 1.037, 65.49 ], [ 1.07, 67.61 ],
      [ 1.091, 64.08 ], [ 1.14, 63.38 ], [ 1.161, 66.9 ], [ 1.19, 62.68 ], [ 1.269, 62.68 ],
      [ 1.397, 59.16 ], [ 1.496, 57.04 ], [ 1.583, 52.11 ], [ 1.653, 45.07 ], [ 1.719, 38.03 ],
      [ 1.76, 31.69 ], [ 1.831, 24.65 ], [ 1.901, 19.01 ], [ 1.988, 12.68 ], [ 2.083, 9.155 ],
      [ 2.169, 5.634 ], [ 2.252, 3.521 ], [ 2.36, 0 ]
],
    "totImpulseNs": 119.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 60.3,
    "burnTimeS": 2.23,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G60",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "134G60-14A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 87.8,
    "motorId": "5f4294d2000231000000005d",
    "propInfo": "Red Lightning",
    "propWeightG": 68.3,
    "samples": [
      [ 0, 0 ], [ 0.043, 65.22 ], [ 0.13, 74.91 ], [ 0.218, 84.6 ], [ 0.305, 83.96 ], [ 0.393, 81.98 ],
      [ 0.48, 81.96 ], [ 0.568, 81.14 ], [ 0.655, 80.53 ], [ 0.743, 79.92 ], [ 0.83, 78.87 ],
      [ 0.918, 76.67 ], [ 1.005, 75.12 ], [ 1.094, 73.73 ], [ 1.182, 71.31 ], [ 1.27, 68.78 ],
      [ 1.357, 66.85 ], [ 1.445, 65.11 ], [ 1.532, 63.53 ], [ 1.62, 61.23 ], [ 1.707, 59.25 ],
      [ 1.795, 57.11 ], [ 1.882, 51.67 ], [ 1.97, 18.56 ], [ 2.057, 2.667 ], [ 2.146, 1.47 ],
      [ 2.234, 0 ]
],
    "totImpulseNs": 134.2,
    "totalWeightG": 206,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 61,
    "burnTimeS": 2.04,
    "caseInfo": "RMS-38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G61",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G61W",
    "diameter": 38,
    "impulseClass": "G",
    "length": 106,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 63.2,
    "motorId": "5f4294d2000231000000005e",
    "propInfo": "White Lightning",
    "propWeightG": 62.3,
    "samples": [
      [ 0, 0 ], [ 0.008, 3.083 ], [ 0.054, 71.35 ], [ 0.089, 72.23 ], [ 0.174, 75.31 ], [ 0.216, 78.39 ],
      [ 0.247, 79.72 ], [ 0.502, 81.04 ], [ 0.753, 77.07 ], [ 1.001, 72.67 ], [ 1.132, 66.94 ],
      [ 1.252, 55.93 ], [ 1.503, 38.32 ], [ 1.754, 10.13 ], [ 1.905, 3.523 ], [ 2.04, 0 ]
],
    "totImpulseNs": 110.5,
    "totalWeightG": 194,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 63,
    "burnTimeS": 1.3,
    "caseInfo": "29X304.8MM (75CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G63",
    "dataFiles": 2,
    "designation": "G63",
    "diameter": 29,
    "impulseClass": "G",
    "length": 305,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 157.6,
    "motorId": "5f4294d2000231000000005f",
    "propWeightG": 5,
    "samples": [
      [ 0, 0 ], [ 0.01, 121.9 ], [ 0.03, 142.7 ], [ 0.07, 156.6 ], [ 0.09, 133.6 ], [ 0.13, 100.6 ],
      [ 0.18, 114.8 ], [ 0.2, 105.2 ], [ 0.27, 104.9 ], [ 0.35, 93.38 ], [ 0.41, 83.85 ],[ 0.5, 67.95 ],
      [ 0.65, 61.99 ], [ 0.83, 61.99 ], [ 0.93, 40.14 ], [ 1.09, 17.09 ], [ 1.18, 13.78 ],
      [ 1.29, 5.56 ], [ 1.3, 0 ]
],
    "totImpulseNs": 85,
    "totalWeightG": 210,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 56.84,
    "burnTimeS": 2.09,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G64",
    "dataFiles": 1,
    "delays": "4,5,6,7,8,10",
    "designation": "G64W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/G64.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 98.31,
    "motorId": "5f4294d20002310000000060",
    "propInfo": "White Lightning",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 9.564 ], [ 0.02, 60.05 ], [ 0.03, 77.49 ], [ 0.04, 90.32 ], [ 0.05, 98.81 ],
      [ 0.06, 103 ], [ 0.07, 106 ], [ 0.08, 108.1 ], [ 0.09, 108.1 ], [ 0.1, 106.4 ], [ 0.15, 104.1 ],
      [ 0.25, 103.3 ], [ 0.35, 100.4 ], [ 0.45, 95.44 ], [ 0.55, 90.5 ], [ 0.65, 85.05 ],[ 0.75, 79.26 ],
      [ 0.95, 67.66 ], [ 1.15, 53.08 ], [ 1.35, 38.15 ], [ 1.55, 23.72 ], [ 1.75, 11.46 ],
      [ 1.95, 4.63 ], [ 2.15, 1.452 ], [ 2.35, 0.1101 ], [ 2.44, 0 ]
],
    "totImpulseNs": 118.8,
    "totalWeightG": 151,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 67,
    "burnTimeS": 1.642,
    "caseInfo": "RMS-38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G67",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G67R",
    "diameter": 38,
    "impulseClass": "G",
    "length": 106,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000061",
    "propInfo": "Redline",
    "propWeightG": 60,
    "samples": [
      [ 0, 0 ], [ 0.04, 4.92 ], [ 0.05, 6.56 ], [ 0.06, 9.84 ], [ 0.07, 16.41 ], [ 0.08, 32.81 ],
      [ 0.1, 49.22 ], [ 0.13, 68.08 ], [ 0.15, 76.29 ], [ 0.18, 80.39 ], [ 0.24, 82.85 ],[ 0.26, 85.31 ],
      [ 0.31, 87.77 ], [ 0.51, 89.41 ], [ 0.53, 91.05 ], [ 0.56, 87.77 ], [ 0.6, 86.95 ],[ 0.61, 88.59 ],
      [ 0.67, 86.95 ], [ 0.69, 85.31 ], [ 0.71, 86.95 ], [ 0.72, 85.31 ], [ 0.74, 86.13 ],
      [ 0.77, 85.31 ], [ 0.81, 82.03 ], [ 0.95, 78.75 ], [ 1.05, 73.82 ], [ 1.43, 53.32 ],
      [ 1.5, 52.5 ], [ 1.52, 50.86 ], [ 1.54, 45.94 ], [ 1.62, 11.48 ], [ 1.64, 0 ]
],
    "totImpulseNs": 110,
    "totalWeightG": 201,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 69,
    "burnTimeS": 1.8,
    "caseInfo": "29X406.4MM (125CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G69",
    "dataFiles": 2,
    "designation": "G69",
    "diameter": 29,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 137.9,
    "motorId": "5f4294d20002310000000062",
    "propWeightG": 5,
    "samples": [
      [ 0, 0 ], [ 0.01, 99.8 ], [ 0.04, 137.5 ], [ 0.07, 103 ], [ 0.13, 94.13 ], [ 0.3, 80.09 ],
      [ 0.49, 72.2 ], [ 0.57, 70.72 ], [ 0.65, 71.96 ], [ 0.74, 80.83 ], [ 0.81, 81.81 ],[ 0.94, 73.43 ],
      [ 1.01, 74.42 ], [ 1.06, 85.02 ], [ 1.11, 83.78 ], [ 1.19, 62.1 ], [ 1.24, 60.62 ],[ 1.32, 65.3 ],
      [ 1.37, 64.81 ], [ 1.43, 52.98 ], [ 1.49, 48.55 ], [ 1.55, 44.85 ], [ 1.64, 28.59 ],
      [ 1.85, 17.74 ], [ 1.99, 14.29 ], [ 2, 0 ]
],
    "totImpulseNs": 128,
    "totalWeightG": 236,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 69.2,
    "burnTimeS": 1.75,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G69",
    "dataFiles": 4,
    "delays": "5,7,9,11,14",
    "designation": "117G69-14A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 103.9,
    "motorId": "5f4294d20002310000000063",
    "propInfo": "Skidmark",
    "propWeightG": 61.5,
    "samples": [
      [ 0, 0 ], [ 0.015, 49.37 ], [ 0.023, 79.41 ], [ 0.035, 91.87 ], [ 0.064, 82.61 ], [ 0.099, 52.41 ],
      [ 0.166, 57.04 ], [ 0.228, 59.28 ], [ 0.375, 64.23 ], [ 1.297, 69.02 ], [ 1.453, 68.86 ],
      [ 1.533, 67.75 ], [ 1.589, 65.19 ], [ 1.686, 58.64 ], [ 1.735, 42.82 ], [ 1.781, 26.36 ],
      [ 1.816, 13.9 ], [ 1.89, 5.432 ], [ 1.954, 1.278 ], [ 1.969, 0 ]
],
    "sparky": true,
    "totImpulseNs": 121.1,
    "totalWeightG": 197,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 75,
    "burnTimeS": 2.43,
    "caseInfo": "RMS-29/180",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G75",
    "dataFiles": 3,
    "delays": "4,6,8,10",
    "designation": "G75J",
    "diameter": 29,
    "impulseClass": "G",
    "length": 194,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 70.3,
    "motorId": "5f4294d20002310000000064",
    "propInfo": "Blackjack",
    "propWeightG": 104.3,
    "samples": [
      [ 0, 0 ], [ 0.047, 65.7 ], [ 0.143, 68.56 ], [ 0.239, 72.14 ], [ 0.334, 73.26 ], [ 0.43, 73.96 ],
      [ 0.526, 75.04 ], [ 0.622, 75.7 ], [ 0.718, 75.03 ], [ 0.814, 77.89 ], [ 0.909, 76.18 ],
      [ 1.005, 76.85 ], [ 1.101, 75.73 ], [ 1.197, 78.85 ], [ 1.293, 78.67 ], [ 1.389, 76.46 ],
      [ 1.484, 76.44 ], [ 1.58, 74.98 ], [ 1.676, 72.66 ], [ 1.772, 69.46 ], [ 1.868, 62.12 ],
      [ 1.964, 39.09 ], [ 2.059, 19.7 ], [ 2.155, 7.554 ], [ 2.251, 2.062 ], [ 2.347, 0.382 ],
      [ 2.443, 0 ]
],
    "totImpulseNs": 135.6,
    "totalWeightG": 233,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 77,
    "burnTimeS": 1.364,
    "caseInfo": "RMS-29/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G77",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G77R",
    "diameter": 29,
    "impulseClass": "G",
    "length": 150,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000065",
    "propInfo": "Redline",
    "propWeightG": 58,
    "samples": [
      [ 0, 0 ], [ 0.0132, 14.83 ], [ 0.0243, 32.45 ], [ 0.0331, 46.35 ], [ 0.0375, 52.84 ],
      [ 0.0463, 56.55 ], [ 0.0617, 59.33 ], [ 0.258, 73.24 ], [ 0.6548, 87.15 ], [ 0.8709, 89 ],
      [ 0.8885, 85.29 ], [ 1.025, 86.22 ], [ 1.047, 84.36 ], [ 1.071, 86.22 ], [ 1.1, 84.36 ],
      [ 1.133, 85.29 ], [ 1.195, 76.95 ], [ 1.21, 76.02 ], [ 1.237, 65.82 ], [ 1.261, 43.57 ],
      [ 1.29, 27.81 ], [ 1.332, 12.05 ], [ 1.363, 4.635 ], [ 1.4, 0 ]
],
    "totImpulseNs": 105,
    "totalWeightG": 165,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 79.9,
    "burnTimeS": 1.63,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G79",
    "dataFiles": 3,
    "delays": "4,6,8,10,13",
    "designation": "129G79-13A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 125,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 95.85,
    "motorId": "5f4294d20002310000000066",
    "propInfo": "Smoky Sam",
    "propWeightG": 82.7,
    "samples": [
      [ 0, 0 ], [ 0.001, 9.07 ], [ 0.031, 54.45 ], [ 0.071, 76.33 ], [ 0.091, 70.95 ], [ 0.111, 65.92 ],
      [ 0.171, 68.59 ], [ 0.201, 70.64 ], [ 0.301, 74.89 ], [ 0.401, 80.39 ], [ 0.501, 83.76 ],
      [ 0.601, 86.45 ], [ 0.701, 88.65 ], [ 0.811, 91.4 ], [ 0.901, 93.06 ], [ 1.001, 93.99 ],
      [ 1.101, 95.81 ], [ 1.201, 90.7 ], [ 1.301, 86.92 ], [ 1.401, 81.98 ], [ 1.501, 76.54 ],
      [ 1.551, 58.92 ], [ 1.601, 16.41 ], [ 1.631, 5.16 ], [ 1.631, 0 ]
],
    "totImpulseNs": 129,
    "totalWeightG": 226,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 79,
    "burnTimeS": 1.42,
    "caseInfo": "RMS-29/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G79",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G79W",
    "diameter": 29,
    "impulseClass": "G",
    "length": 150,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 100.7,
    "motorId": "5f4294d20002310000000067",
    "propInfo": "White Lightning",
    "propWeightG": 62,
    "samples": [
      [ 0, 0 ], [ 0.015, 7.157 ], [ 0.074, 91.94 ], [ 0.09, 91.39 ], [ 0.114, 84.78 ], [ 0.145, 84.23 ],
      [ 0.201, 89.19 ], [ 0.291, 94.69 ], [ 0.4, 98.54 ], [ 0.6, 99.64 ], [ 0.708, 96.89 ],
      [ 0.8, 93.04 ], [ 0.915, 85.33 ], [ 1, 77.62 ], [ 1.085, 71.02 ], [ 1.175, 68.27 ],[ 1.199, 44.59 ],
      [ 1.28, 22.02 ], [ 1.36, 4.955 ], [ 1.42, 0 ]
],
    "totImpulseNs": 108.6,
    "totalWeightG": 157,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 77.6,
    "burnTimeS": 1.7,
    "certOrg": "National Association of Rocketry",
    "commonName": "G80",
    "dataFiles": 4,
    "delays": "6,7,8,9,10,11,12,13,14",
    "designation": "G80T",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://www.nar.org/SandT/pdf/Aerotech/G80-20071207.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 108.5,
    "motorId": "5f4294d20002310000000068",
    "propInfo": "Blue Thunder",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.013, 89.05 ], [ 0.018, 101.6 ], [ 0.029, 105.4 ], [ 0.047, 102.9 ], [ 0.104, 100 ],
      [ 0.19, 102.3 ], [ 0.268, 104.9 ], [ 0.306, 104.3 ], [ 0.352, 105.8 ], [ 0.41, 104.9 ],
      [ 0.432, 106.7 ], [ 0.512, 105.6 ], [ 0.563, 107 ], [ 0.591, 104.5 ], [ 0.62, 106.3 ],
      [ 0.715, 103.2 ], [ 0.76, 103.4 ], [ 0.806, 101.4 ], [ 0.848, 102 ], [ 1.2, 88.38 ],
      [ 1.243, 77.87 ], [ 1.269, 57.95 ], [ 1.302, 44.08 ], [ 1.329, 34.01 ], [ 1.367, 26.4 ],
      [ 1.398, 22.82 ], [ 1.433, 20.59 ], [ 1.48, 21.7 ], [ 1.508, 20.81 ], [ 1.584, 13.2 ],
      [ 1.604, 11.63 ], [ 1.652, 11.19 ], [ 1.683, 5.37 ], [ 1.701, 0 ]
],
    "totImpulseNs": 136.6,
    "totalWeightG": 127.9,
    "type": "SU",
    "updatedOn": "2021-01-19"
  },
  {
    "availability": "OOP",
    "avgThrustN": 77.5,
    "burnTimeS": 1.5,
    "caseInfo": "SU 29x124",
    "certOrg": "National Association of Rocketry",
    "commonName": "G80",
    "dataFiles": 1,
    "delays": "4,7",
    "designation": "G80T",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://nar.org/SandT/pdf/PML/G80T.pdf",
    "length": 124,
    "manufacturer": "Public Missiles, Ltd.",
    "manufacturerAbbrev": "PML",
    "maxThrustN": 105.2,
    "motorId": "5f4294d20002310000000069",
    "propWeightG": 57.4,
    "samples": [
      [ 0, 0 ], [ 0.007, 82.75 ], [ 0.018, 104.8 ], [ 0.051, 104.8 ], [ 0.095, 97.71 ], [ 0.245, 94.19 ],
      [ 0.458, 95.07 ], [ 0.6, 93.31 ], [ 0.86, 84.51 ], [ 1.003, 76.58 ], [ 1.139, 69.54 ],
      [ 1.252, 57.22 ], [ 1.303, 51.06 ], [ 1.34, 37.85 ], [ 1.38, 19.37 ], [ 1.424, 7.923 ],
      [ 1.461, 2.641 ], [ 1.497, 0 ]
],
    "totImpulseNs": 116.3,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 80,
    "burnTimeS": 1.4,
    "caseInfo": "SU 29x140",
    "certOrg": "National Association of Rocketry",
    "commonName": "G80",
    "dataFiles": 2,
    "delays": "4,7,10",
    "designation": "G80",
    "diameter": 29,
    "impulseClass": "G",
    "length": 140,
    "manufacturer": "Roadrunner Rocketry",
    "manufacturerAbbrev": "Roadrunner",
    "maxThrustN": 114,
    "motorId": "5f4294d2000231000000006a",
    "propInfo": "Rapid propellant",
    "propWeightG": 54.7,
    "samples": [
      [ 0, 0 ], [ 0.012, 63.56 ], [ 0.028, 84.08 ], [ 0.057, 89.56 ], [ 0.119, 96.03 ], [ 0.206, 102.5 ],
      [ 0.242, 104.4 ], [ 0.297, 106.9 ], [ 0.356, 109.8 ], [ 0.422, 111.8 ], [ 0.483, 111.3 ],
      [ 0.558, 112.6 ], [ 0.622, 112.8 ], [ 0.683, 112.1 ], [ 0.739, 109.1 ], [ 0.796, 102 ],
      [ 0.863, 90.49 ], [ 0.901, 82.75 ], [ 0.935, 72.41 ], [ 0.976, 59.87 ], [ 1.018, 49.83 ],
      [ 1.028, 44.32 ], [ 1.042, 39.8 ], [ 1.073, 28.27 ], [ 1.113, 18.23 ], [ 1.17, 11.18 ],
      [ 1.218, 4.636 ], [ 1.31, 0 ]
],
    "totImpulseNs": 108,
    "totalWeightG": 135,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 101,
    "burnTimeS": 1.43,
    "caseInfo": "38 16 S PVC (140CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G100",
    "dataFiles": 2,
    "designation": "G100-PVC",
    "diameter": 38,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 230,
    "motorId": "5f4294d2000231000000006b",
    "propWeightG": 93,
    "samples": [
      [ 0, 0 ], [ 0.001, 182.8 ], [ 0.2001, 177.6 ], [ 0.6073, 132.8 ], [ 0.9876, 53.45 ],
      [ 1.431, 0 ]
],
    "totImpulseNs": 146,
    "totalWeightG": 511,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 101,
    "burnTimeS": 1,
    "caseInfo": "RMS-29/100",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G101",
    "dataFiles": 1,
    "delays": "4,6,8,10,12,14",
    "designation": "G101T",
    "diameter": 29,
    "impulseClass": "G",
    "length": 125,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 102.3,
    "motorId": "5f4294d2000231000000006c",
    "propInfo": "Blue Thunder",
    "propWeightG": 46,
    "samples": [
      [ 0, 0 ], [ 0.01, 35.29 ], [ 0.02, 63.97 ], [ 0.02, 78.09 ], [ 0.03, 84.71 ], [ 0.04, 89.96 ],
      [ 0.06, 93.96 ], [ 0.12, 97.26 ], [ 0.17, 99.14 ], [ 0.21, 101.7 ], [ 0.27, 104.1 ],
      [ 0.31, 104.6 ], [ 0.36, 103.6 ], [ 0.4, 103.4 ], [ 0.45, 101 ], [ 0.51, 99.27 ], [ 0.53, 94.41 ],
      [ 0.56, 93.09 ], [ 0.64, 87.35 ], [ 0.76, 78.53 ], [ 0.8, 75.88 ], [ 0.88, 68.82 ],[ 0.89, 65.73 ],
      [ 0.9, 61.32 ], [ 0.91, 55.15 ], [ 0.93, 35.73 ], [ 0.94, 32.65 ], [ 0.95, 22.5 ], [ 0.98, 10.59 ],
      [ 1, 5.29 ], [ 1.05, 0 ]
],
    "totImpulseNs": 85,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 104,
    "burnTimeS": 1,
    "caseInfo": "RMS-29/100",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G104",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "G104T",
    "diameter": 29,
    "impulseClass": "G",
    "length": 125,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 113.8,
    "motorId": "5f4294d2000231000000006d",
    "propInfo": "Blue Thunder",
    "propWeightG": 43.9,
    "samples": [
      [ 0, 0 ], [ 0.0067, 125.3 ], [ 0.0471, 123.5 ], [ 0.0856, 122 ], [ 0.1019, 121.4 ],[ 0.1462, 121.2 ],
      [ 0.1837, 120.8 ], [ 0.2029, 120.5 ], [ 0.2385, 118.8 ], [ 0.2644, 117.2 ], [ 0.2798, 116.9 ],
      [ 0.3279, 116.7 ], [ 0.3923, 116.7 ], [ 0.4298, 116.1 ], [ 0.4615, 114.1 ], [ 0.5067, 110.2 ],
      [ 0.5404, 104.6 ], [ 0.576, 96.2 ], [ 0.6067, 89.56 ], [ 0.6817, 78.87 ], [ 0.7692, 67.96 ],
      [ 0.7865, 64.92 ], [ 0.799, 62.56 ], [ 0.8058, 58.06 ], [ 0.8192, 50.52 ], [ 0.8385, 39.04 ],
      [ 0.8625, 27.57 ], [ 0.8769, 16.65 ], [ 0.9019, 0 ]
],
    "totImpulseNs": 81.5,
    "totalWeightG": 136,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 123,
    "burnTimeS": 1.15,
    "caseInfo": "38 16 S HP (140CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G123",
    "dataFiles": 2,
    "designation": "G123-HP",
    "diameter": 38,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 413,
    "motorId": "5f4294d2000231000000006e",
    "propWeightG": 83,
    "samples": [
      [ 0, 0 ], [ 0.002237, 217.2 ], [ 0.006711, 400 ], [ 0.02013, 220.7 ], [ 0.915, 72.41 ],
      [ 0.9553, 37.93 ], [ 1.15, 0 ]
],
    "totImpulseNs": 142,
    "totalWeightG": 511,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 125,
    "burnTimeS": 1.16,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G125",
    "dataFiles": 2,
    "designation": "G125",
    "diameter": 38,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "motorId": "5f4294d2000231000000006f",
    "samples": [
      [ 0, 0 ], [ 0.01, 346.9 ], [ 0.04, 325.7 ], [ 0.07, 324.6 ], [ 0.08, 415.6 ], [ 0.09, 219.6 ],
      [ 0.13, 194.8 ], [ 0.2, 177.8 ], [ 0.4, 157.1 ], [ 0.6, 131.9 ], [ 0.8, 88.31 ], [ 1, 42.44 ],
      [ 1.09, 14.64 ], [ 1.2, 0 ]
],
    "totImpulseNs": 145,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 130,
    "burnTimeS": 0.86,
    "caseInfo": "38 16 M PVC (140CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G130",
    "dataFiles": 2,
    "designation": "G130-PVC",
    "diameter": 38,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 706,
    "motorId": "5f4294d20002310000000070",
    "propWeightG": 93,
    "samples": [
      [ 0, 0 ], [ 0.001, 662.1 ], [ 0.01554, 448.3 ], [ 0.02449, 241.4 ], [ 0.6431, 41.38 ],
      [ 0.861, 0 ]
],
    "totImpulseNs": 100,
    "totalWeightG": 516,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 221,
    "burnTimeS": 0.53,
    "caseInfo": "38 16 F HP (90CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G234",
    "dataFiles": 2,
    "designation": "G234-HP",
    "diameter": 38,
    "impulseClass": "G",
    "length": 414,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 606.5,
    "motorId": "5f4294d20002310000000071",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001695, 245.4 ], [ 0.09732, 540.6 ], [ 0.1834, 526.9 ], [ 0.2025, 191.6 ],
      [ 0.2371, 143.7 ], [ 0.2606, 136.9 ], [ 0.533, 0 ]
],
    "totImpulseNs": 118,
    "totalWeightG": 544,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 288,
    "burnTimeS": 0.25,
    "caseInfo": "38 16 F PVC (90CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G300",
    "dataFiles": 2,
    "designation": "G300-PVC",
    "diameter": 38,
    "impulseClass": "G",
    "length": 406,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 689,
    "motorId": "5f4294d20002310000000072",
    "propWeightG": 215,
    "samples": [
      [ 0, 0 ], [ 0.001119, 602.2 ], [ 0.04978, 814.4 ], [ 0.1007, 670.7 ], [ 0.1141, 266.9 ],
      [ 0.1588, 239.5 ], [ 0.25, 0 ]
],
    "totImpulseNs": 100,
    "totalWeightG": 535,
    "type": "hybrid",
    "updatedOn": "2022-01-08"
  },
  {
    "availability": "OOP",
    "avgThrustN": 45,
    "burnTimeS": 6,
    "caseInfo": "SU 38x194",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H45",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,13,15",
    "designation": "H45W",
    "diameter": 38,
    "impulseClass": "H",
    "length": 194,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 102,
    "motorId": "5f4294d20002310000000073",
    "propInfo": "White Lightning",
    "propWeightG": 197.4,
    "samples": [
      [ 0, 0 ], [ 0.141, 62.55 ], [ 0.424, 63.5 ], [ 0.707, 65.91 ], [ 0.992, 68.37 ], [ 1.276, 69.31 ],
      [ 1.559, 68.52 ], [ 1.843, 67.23 ], [ 2.127, 65.7 ], [ 2.411, 63.15 ], [ 2.695, 59.21 ],
      [ 2.979, 55.6 ], [ 3.264, 50.79 ], [ 3.547, 45.24 ], [ 3.83, 39.84 ], [ 4.115, 34.56 ],
      [ 4.399, 29.21 ], [ 4.682, 24.72 ], [ 4.967, 20.62 ], [ 5.251, 17.48 ], [ 5.534, 14.5 ],
      [ 5.818, 12.7 ], [ 6.102, 10.79 ], [ 6.386, 9.229 ], [ 6.67, 7.754 ], [ 6.954, 6.075 ],
      [ 7.239, 0 ]
],
    "totImpulseNs": 289,
    "totalWeightG": 294.8,
    "type": "SU",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 48,
    "burnTimeS": 4.4,
    "caseInfo": "SU 38x172",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H48",
    "dataFiles": 2,
    "delays": "8,P",
    "designation": "H48",
    "diameter": 38,
    "impulseClass": "H",
    "length": 172,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 101,
    "motorId": "5f4294d20002310000000074",
    "propWeightG": 145,
    "samples": [
      [ 0, 0 ], [ 0.05, 101.5 ], [ 0.1, 101.5 ], [ 0.21, 92.18 ], [ 0.46, 86.48 ], [ 0.74, 83.38 ],
      [ 1, 80 ], [ 1.49, 74.57 ], [ 1.99, 68.36 ], [ 2.48, 63.18 ], [ 2.99, 56.45 ], [ 3.2, 34.18 ],
      [ 3.5, 18 ], [ 3.69, 13.46 ], [ 4, 11 ], [ 4.36, 7.77 ], [ 4.4, 0 ]
],
    "totImpulseNs": 249,
    "totalWeightG": 292,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 50,
    "burnTimeS": 5,
    "caseInfo": "SU 29x279",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H50",
    "dataFiles": 2,
    "delays": "6,10",
    "designation": "H50",
    "diameter": 29,
    "impulseClass": "H",
    "length": 279,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 109,
    "motorId": "5f4294d20002310000000075",
    "propWeightG": 142,
    "samples": [
      [ 0, 0 ], [ 0.01, 63.67 ], [ 0.17, 108.9 ], [ 0.27, 94.9 ], [ 0.47, 81.43 ], [ 0.79, 71.02 ],
      [ 1.27, 64.9 ], [ 1.97, 60.61 ], [ 2.56, 56.94 ], [ 3.01, 52.04 ], [ 3.52, 45.31 ],[ 3.97, 34.9 ],
      [ 4.49, 18.37 ], [ 4.97, 4.9 ], [ 5.28, 0 ]
],
    "totImpulseNs": 264,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 55,
    "burnTimeS": 2.45,
    "caseInfo": "SU 29x191",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H55",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "H55W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 113.3,
    "motorId": "5f4294d20002310000000076",
    "propInfo": "White Lightning",
    "propWeightG": 99.7,
    "samples": [
      [ 0, 0 ], [ 0.052, 92.75 ], [ 0.159, 98.02 ], [ 0.268, 95.82 ], [ 0.375, 96.16 ], [ 0.482, 97.15 ],
      [ 0.591, 96.93 ], [ 0.699, 95.92 ], [ 0.806, 94.45 ], [ 0.914, 92 ], [ 1.022, 88.76 ],
      [ 1.129, 86.97 ], [ 1.236, 84.07 ], [ 1.345, 80.17 ], [ 1.453, 74.34 ], [ 1.56, 64.99 ],
      [ 1.668, 46.38 ], [ 1.776, 32.84 ], [ 1.883, 25.73 ], [ 1.991, 19.92 ], [ 2.099, 16.23 ],
      [ 2.207, 13.06 ], [ 2.315, 10.45 ], [ 2.422, 7.7 ], [ 2.53, 5.696 ], [ 2.639, 3.979 ],
      [ 2.747, 0 ]
],
    "totImpulseNs": 162.3,
    "totalWeightG": 188.2,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 70,
    "burnTimeS": 3.6,
    "caseInfo": "29 h 136cc",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H70",
    "dataFiles": 1,
    "designation": "H70",
    "diameter": 29,
    "impulseClass": "H",
    "length": 457,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 204.3,
    "motorId": "5f4294d20002310000000077",
    "propWeightG": 145.2,
    "samples": [
      [ 0, 0 ], [ 0.051, 124.1 ], [ 0.156, 134.9 ], [ 0.261, 127 ], [ 0.367, 115.9 ], [ 0.473, 110.3 ],
      [ 0.578, 108.6 ], [ 0.683, 105.7 ], [ 0.789, 104.1 ], [ 0.894, 101.5 ], [ 1, 98.47 ],
      [ 1.105, 93.81 ], [ 1.21, 90.54 ], [ 1.316, 85.22 ], [ 1.421, 74.35 ], [ 1.527, 57.74 ],
      [ 1.632, 44.02 ], [ 1.738, 34.55 ], [ 1.843, 27.99 ], [ 1.948, 22.69 ], [ 2.054, 19.06 ],
      [ 2.159, 15.66 ], [ 2.265, 12.9 ], [ 2.37, 11.33 ], [ 2.475, 10.11 ], [ 2.581, 8.591 ],
      [ 2.688, 0 ]
],
    "totImpulseNs": 207.5,
    "totalWeightG": 352,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 72,
    "burnTimeS": 3.16,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H70",
    "dataFiles": 1,
    "designation": "240NS-H70",
    "diameter": 38,
    "impulseClass": "H",
    "length": 464,
    "manufacturer": "Propulsion Polymers",
    "manufacturerAbbrev": "PP",
    "maxThrustN": 171,
    "motorId": "5f4294d20002310000000078",
    "propWeightG": 318.3,
    "samples": [
      [ 0, 0 ], [ 0.05, 170.9 ], [ 0.09, 122.3 ], [ 0.37, 106.8 ], [ 0.74, 97.9 ], [ 1.11, 93.4 ],
      [ 1.48, 89 ], [ 1.84, 84.5 ], [ 2.21, 77.1 ], [ 2.33, 74 ], [ 2.58, 41.5 ], [ 2.95, 23.7 ],
      [ 3.32, 15.6 ], [ 3.69, 0 ]
],
    "totImpulseNs": 237,
    "totalWeightG": 626.6,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 70,
    "burnTimeS": 2,
    "caseInfo": "SU 29x229",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H70",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "H70W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 229,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 174,
    "motorId": "5f4294d20002310000000079",
    "propInfo": "White Lightning",
    "propWeightG": 126,
    "samples": [
      [ 0, 0 ], [ 0.055, 114.8 ], [ 0.169, 131.4 ], [ 0.283, 126.9 ], [ 0.397, 127.1 ], [ 0.51, 127.3 ],
      [ 0.625, 125.9 ], [ 0.739, 124.9 ], [ 0.852, 122 ], [ 0.967, 119 ], [ 1.08, 115.1 ],
      [ 1.194, 108.4 ], [ 1.308, 102.3 ], [ 1.422, 96.1 ], [ 1.535, 86.95 ], [ 1.65, 75.7 ],
      [ 1.764, 62.4 ], [ 1.877, 48.13 ], [ 1.992, 36.86 ], [ 2.105, 28.07 ], [ 2.219, 21.59 ],
      [ 2.333, 16.89 ], [ 2.447, 12.69 ], [ 2.56, 9.681 ], [ 2.675, 6.818 ], [ 2.79, 4.488 ],
      [ 2.904, 0 ]
],
    "totImpulseNs": 215,
    "totalWeightG": 224,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 73,
    "burnTimeS": 2.55,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H73",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H73J",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 97.1,
    "motorId": "5f4294d2000231000000007a",
    "propInfo": "Blackjack",
    "propWeightG": 142.7,
    "samples": [
      [ 0, 0 ], [ 0.056, 49.25 ], [ 0.172, 82 ], [ 0.287, 82.13 ], [ 0.403, 84.6 ], [ 0.52, 86.88 ],
      [ 0.635, 88.89 ], [ 0.751, 89.65 ], [ 0.867, 91.34 ], [ 0.982, 92.98 ], [ 1.099, 94.57 ],
      [ 1.215, 94.64 ], [ 1.33, 93.55 ], [ 1.446, 91.45 ], [ 1.561, 88.19 ], [ 1.678, 82.44 ],
      [ 1.794, 77.4 ], [ 1.909, 70.77 ], [ 2.025, 61.17 ], [ 2.141, 51.16 ], [ 2.257, 38.54 ],
      [ 2.373, 21.56 ], [ 2.489, 12.21 ], [ 2.604, 7.327 ], [ 2.72, 3.706 ], [ 2.836, 1.777 ],
      [ 2.953, 0 ]
],
    "totImpulseNs": 185.6,
    "totalWeightG": 309.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 78,
    "burnTimeS": 2.75,
    "caseInfo": "29X520MM",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H78",
    "dataFiles": 2,
    "designation": "H78",
    "diameter": 29,
    "impulseClass": "H",
    "length": 523,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 150.4,
    "motorId": "5f4294d2000231000000007b",
    "propWeightG": 5,
    "samples": [
      [ 0, 0 ], [ 0.01, 138.2 ], [ 0.08, 150.1 ], [ 0.12, 142.7 ], [ 0.15, 132.3 ], [ 0.22, 130.5 ],
      [ 0.29, 90.3 ], [ 0.34, 88.27 ], [ 0.4, 86.81 ], [ 0.61, 86.52 ], [ 0.72, 81.59 ], [ 0.76, 71.72 ],
      [ 0.86, 64.46 ], [ 1.01, 63.3 ], [ 1.18, 62.42 ], [ 1.28, 60.39 ], [ 1.5, 57.49 ], [ 1.8, 58.36 ],
      [ 1.89, 59.23 ], [ 2.01, 55.46 ], [ 2.21, 36.29 ], [ 2.36, 22.94 ], [ 2.54, 13.36 ],
      [ 2.72, 9.58 ], [ 2.75, 0 ]
],
    "totImpulseNs": 172.9,
    "totalWeightG": 284,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 97,
    "burnTimeS": 2.23,
    "caseInfo": "RMS-29/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H97",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H97J",
    "diameter": 29,
    "impulseClass": "H",
    "length": 238,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 111.5,
    "motorId": "5f4294d2000231000000007c",
    "propInfo": "Blackjack",
    "propWeightG": 137.1,
    "samples": [
      [ 0, 0 ], [ 0.045, 89.41 ], [ 0.136, 100.3 ], [ 0.228, 100.5 ], [ 0.32, 102 ], [ 0.411, 102.8 ],
      [ 0.503, 103.5 ], [ 0.595, 101.7 ], [ 0.686, 103.1 ], [ 0.778, 103.3 ], [ 0.87, 102.6 ],
      [ 0.961, 103.4 ], [ 1.053, 101 ], [ 1.145, 101.2 ], [ 1.236, 99.86 ], [ 1.328, 98.42 ],
      [ 1.42, 96.83 ], [ 1.511, 95.03 ], [ 1.603, 93.24 ], [ 1.695, 93.48 ], [ 1.786, 88.07 ],
      [ 1.878, 64.36 ], [ 1.97, 30.26 ], [ 2.061, 8.691 ], [ 2.153, 1.399 ], [ 2.245, 0.525 ],
      [ 2.336, 0 ]
],
    "totImpulseNs": 177.3,
    "totalWeightG": 277.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 96.9,
    "burnTimeS": 2.56,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H100",
    "dataFiles": 1,
    "designation": "246H100-P",
    "diameter": 38,
    "impulseClass": "H",
    "length": 403,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 174.9,
    "motorId": "5f4294d2000231000000007d",
    "propWeightG": 145.6,
    "samples": [
      [ 0, 0 ], [ 0.017, 32.44 ], [ 0.168, 168 ], [ 0.206, 169.3 ], [ 0.217, 166.7 ], [ 0.324, 139.7 ],
      [ 0.423, 137.1 ], [ 0.484, 134.6 ], [ 0.548, 130.4 ], [ 0.582, 127.2 ], [ 0.643, 125.3 ],
      [ 1.002, 115 ], [ 1.037, 114.3 ], [ 1.092, 114 ], [ 1.159, 113.1 ], [ 1.243, 108.6 ],
      [ 1.269, 104.7 ], [ 1.335, 96.67 ], [ 1.373, 93.46 ], [ 1.408, 91.86 ], [ 1.472, 89.61 ],
      [ 1.512, 86.39 ], [ 1.547, 82.86 ], [ 1.582, 77.72 ], [ 1.75, 54.92 ], [ 1.868, 41.43 ],
      [ 2.071, 25.05 ], [ 2.5, 0 ]
],
    "totImpulseNs": 266,
    "totalWeightG": 450,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 112,
    "burnTimeS": 2.92,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H112",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H112J",
    "diameter": 38,
    "impulseClass": "H",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 121.7,
    "motorId": "5f4294d2000231000000007e",
    "propInfo": "Blackjack",
    "propWeightG": 191.2,
    "samples": [
      [ 0, 0 ], [ 0.064, 85.43 ], [ 0.194, 101.9 ], [ 0.324, 101.9 ], [ 0.454, 102.8 ], [ 0.584, 104.5 ],
      [ 0.715, 103.8 ], [ 0.845, 103.4 ], [ 0.975, 104.3 ], [ 1.106, 104.9 ], [ 1.236, 104.4 ],
      [ 1.367, 102.8 ], [ 1.497, 102.2 ], [ 1.627, 100 ], [ 1.757, 98.34 ], [ 1.888, 94.56 ],
      [ 2.018, 89.02 ], [ 2.148, 82.86 ], [ 2.279, 77.69 ], [ 2.409, 72.37 ], [ 2.54, 67.04 ],
      [ 2.67, 59.76 ], [ 2.8, 37.62 ], [ 2.93, 14.46 ], [ 3.06, 4.642 ], [ 3.192, 1.818 ],
      [ 3.323, 0 ]
],
    "totImpulseNs": 261.1,
    "totalWeightG": 379.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 121,
    "burnTimeS": 1.85,
    "caseInfo": "38 20 S HP (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H121",
    "dataFiles": 2,
    "designation": "H121-HP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 516,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 294,
    "motorId": "5f4294d2000231000000007f",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.002237, 251.7 ], [ 0.04027, 265.5 ], [ 0.07383, 203.4 ], [ 0.4004, 179.3 ],
      [ 0.6018, 134.5 ], [ 1.089, 127.6 ], [ 1.403, 93.1 ], [ 1.62, 37.93 ], [ 1.85, 0 ]
],
    "totImpulseNs": 225,
    "totalWeightG": 612,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 123,
    "burnTimeS": 1.76,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H123",
    "dataFiles": 1,
    "delays": "4,6,8,10",
    "designation": "H123W",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 174.2,
    "motorId": "5f4294d20002310000000080",
    "propInfo": "White Lightning",
    "propWeightG": 128.7,
    "samples": [
      [ 0, 0 ], [ 0.047, 96.76 ], [ 0.143, 146.3 ], [ 0.239, 150.7 ], [ 0.334, 152.5 ], [ 0.43, 151.2 ],
      [ 0.526, 149.9 ], [ 0.622, 150.2 ], [ 0.718, 149.2 ], [ 0.814, 144.9 ], [ 0.909, 143.5 ],
      [ 1.005, 141.4 ], [ 1.101, 135.1 ], [ 1.198, 125.3 ], [ 1.295, 114 ], [ 1.391, 101.6 ],
      [ 1.486, 90.17 ], [ 1.582, 78.69 ], [ 1.678, 66.36 ], [ 1.774, 54.26 ], [ 1.87, 46.87 ],
      [ 1.966, 38.19 ], [ 2.061, 22.74 ], [ 2.157, 13.48 ], [ 2.253, 7.587 ], [ 2.35, 5.252 ],
      [ 2.447, 0 ]
],
    "totImpulseNs": 223.6,
    "totalWeightG": 275,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 124,
    "burnTimeS": 1.7,
    "caseInfo": "H 38/220",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H124",
    "dataFiles": 2,
    "designation": "H124",
    "diameter": 38,
    "impulseClass": "H",
    "length": 533,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 335.3,
    "motorId": "5f4294d20002310000000081",
    "propWeightG": 142,
    "samples": [
      [ 0, 0 ], [ 0.01, 198.2 ], [ 0.02, 300.8 ], [ 0.03, 314.3 ], [ 0.03, 316 ], [ 0.04, 305.4 ],
      [ 0.08, 280.4 ], [ 0.11, 221.4 ], [ 0.69, 168.4 ], [ 0.78, 180.8 ], [ 0.81, 158.6 ],
      [ 0.84, 167.6 ], [ 0.89, 152.3 ], [ 0.92, 120.7 ], [ 0.95, 123.5 ], [ 1.06, 82.23 ],
      [ 1.35, 48.36 ], [ 1.55, 25.63 ], [ 1.6, 21.88 ], [ 1.62, 23.66 ], [ 1.66, 18.58 ],[ 1.67, 12.46 ],
      [ 1.68, 0 ]
],
    "totImpulseNs": 224.5,
    "totalWeightG": 664,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 125,
    "burnTimeS": 2.6,
    "caseInfo": "SU 29x330",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H125",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "H125W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 330,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 307,
    "motorId": "5f4294d20002310000000082",
    "propInfo": "White Lightning",
    "propWeightG": 187.9,
    "samples": [
      [ 0, 0 ], [ 0.053, 276 ], [ 0.161, 241.5 ], [ 0.27, 216.3 ], [ 0.378, 199.5 ], [ 0.488, 188.8 ],
      [ 0.597, 182.3 ], [ 0.705, 175.9 ], [ 0.814, 169.4 ], [ 0.922, 162.3 ], [ 1.031, 154.3 ],
      [ 1.141, 143.9 ], [ 1.249, 133.5 ], [ 1.357, 123 ], [ 1.466, 113.1 ], [ 1.575, 101.8 ],
      [ 1.684, 88.42 ], [ 1.793, 73.53 ], [ 1.901, 60.42 ], [ 2.009, 46.64 ], [ 2.119, 36.78 ],
      [ 2.228, 29.55 ], [ 2.336, 23.64 ], [ 2.445, 18.79 ], [ 2.553, 14.73 ], [ 2.663, 10.97 ],
      [ 2.772, 0 ]
],
    "totImpulseNs": 299.4,
    "totalWeightG": 322.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 128,
    "burnTimeS": 1.27,
    "caseInfo": "RMS-29/180",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H128",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H128W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 194,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 168.7,
    "motorId": "5f4294d20002310000000083",
    "propInfo": "White Lightning",
    "propWeightG": 94.2,
    "samples": [
      [ 0, 0 ], [ 0.024, 102.4 ], [ 0.074, 175.2 ], [ 0.125, 181.4 ], [ 0.176, 179.3 ], [ 0.226, 181.4 ],
      [ 0.277, 186.1 ], [ 0.328, 190.5 ], [ 0.378, 189.5 ], [ 0.429, 186.2 ], [ 0.48, 184.1 ],
      [ 0.53, 180.3 ], [ 0.581, 174.1 ], [ 0.632, 172 ], [ 0.683, 169.4 ], [ 0.734, 166 ],
      [ 0.784, 161.9 ], [ 0.835, 157.3 ], [ 0.886, 153.1 ], [ 0.936, 152 ], [ 0.988, 139.9 ],
      [ 1.039, 87.86 ], [ 1.089, 40.86 ], [ 1.14, 14.33 ], [ 1.191, 4.33 ], [ 1.242, 1.55 ],
      [ 1.293, 0 ]
],
    "totImpulseNs": 172.9,
    "totalWeightG": 206,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 141,
    "burnTimeS": 1.7,
    "caseInfo": "38 20 S PVC (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H141",
    "dataFiles": 2,
    "designation": "H141-PVC",
    "diameter": 38,
    "impulseClass": "H",
    "length": 508,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 297,
    "motorId": "5f4294d20002310000000084",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.002237, 265.5 ], [ 0.1119, 262.1 ], [ 1.201, 106.9 ], [ 1.26, 55.17 ],
      [ 1.356, 27.59 ], [ 1.7, 0 ]
],
    "totImpulseNs": 247,
    "totalWeightG": 612,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 142.4,
    "burnTimeS": 1.73,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H143",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "247H143-13A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 185,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 167,
    "motorId": "5f4294d20002310000000085",
    "propInfo": "Smoky Sam",
    "propWeightG": 165.4,
    "samples": [
      [ 0, 0 ], [ 0.06, 114.7 ], [ 0.19, 134.3 ], [ 0.4, 149.6 ], [ 0.6, 158.1 ], [ 0.8, 163.8 ],
      [ 1, 167 ], [ 1.21, 160.9 ], [ 1.4, 148.8 ], [ 1.6, 128.6 ], [ 1.63, 117.3 ], [ 1.65, 106.3 ],
      [ 1.7, 35.63 ], [ 1.73, 0 ]
],
    "totImpulseNs": 247,
    "totalWeightG": 347,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 155,
    "burnTimeS": 1.53,
    "caseInfo": "38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H144",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "H144-LW",
    "diameter": 38,
    "impulseClass": "H",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20White.pdf",
    "length": 190,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 254,
    "motorId": "5f4294d20002310000000086",
    "propInfo": "Loki White",
    "propWeightG": 126,
    "samples": [
      [ 0, 0 ], [ 0.02, 209 ], [ 0.04, 247.6 ], [ 0.05, 241.2 ], [ 0.1, 247.6 ], [ 0.15, 244.4 ],
      [ 0.2, 237.9 ], [ 0.25, 231.5 ], [ 0.3, 228.3 ], [ 0.4, 215.3 ], [ 0.45, 212.4 ], [ 0.5, 204.5 ],
      [ 0.6, 194.4 ], [ 0.7, 189.7 ], [ 0.8, 170.4 ], [ 0.9, 154.3 ], [ 1, 127.8 ], [ 1.1, 109.3 ],
      [ 1.2, 80.4 ], [ 1.3, 64.6 ], [ 1.4, 44.6 ], [ 1.5, 32.1 ], [ 1.6, 0 ]
],
    "totImpulseNs": 239.3,
    "totalWeightG": 336,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 148,
    "burnTimeS": 1.392,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H148",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H148R",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000087",
    "propInfo": "Redline",
    "propWeightG": 122,
    "samples": [
      [ 0, 0 ], [ 0.027, 77.23 ], [ 0.088, 174.3 ], [ 0.148, 185 ], [ 0.208, 190.5 ], [ 0.268, 192.5 ],
      [ 0.327, 192 ], [ 0.388, 188.8 ], [ 0.448, 187.5 ], [ 0.509, 182.7 ], [ 0.57, 178.2 ],
      [ 0.63, 172.9 ], [ 0.69, 169.6 ], [ 0.75, 164.5 ], [ 0.81, 158.4 ], [ 0.87, 153 ], [ 0.93, 146.8 ],
      [ 0.991, 139.4 ], [ 1.053, 132 ], [ 1.112, 123.3 ], [ 1.173, 112.6 ], [ 1.233, 104.7 ],
      [ 1.292, 97.66 ], [ 1.353, 94.93 ], [ 1.413, 60.64 ], [ 1.474, 13.01 ], [ 1.535, 0 ]
],
    "totImpulseNs": 206,
    "totalWeightG": 309.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 153,
    "burnTimeS": 1.75,
    "caseInfo": "Pro38-2G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H153",
    "dataFiles": 2,
    "designation": "H153",
    "diameter": 38,
    "impulseClass": "H",
    "length": 185,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 188.3,
    "motorId": "5f4294d20002310000000088",
    "propWeightG": 143.9,
    "samples": [
      [ 0, 0 ], [ 0.13, 149.4 ], [ 0.17, 173.7 ], [ 0.23, 171.8 ], [ 0.39, 179.9 ], [ 0.6, 188.3 ],
      [ 0.81, 180.4 ], [ 1.01, 168.3 ], [ 1.18, 160.9 ], [ 1.29, 149.6 ], [ 1.41, 136.9 ],
      [ 1.6, 105.4 ], [ 1.69, 23.58 ], [ 1.75, 0 ]
],
    "totImpulseNs": 258,
    "totalWeightG": 303.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 155,
    "burnTimeS": 1.69,
    "caseInfo": "H 38/220",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H155",
    "dataFiles": 2,
    "designation": "H155",
    "diameter": 38,
    "impulseClass": "H",
    "length": 533,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 416.8,
    "motorId": "5f4294d20002310000000089",
    "propWeightG": 142,
    "samples": [
      [ 0, 0 ], [ 0.03, 308.8 ], [ 0.05, 362.2 ], [ 0.09, 252.4 ], [ 1.02, 102.1 ], [ 1.08, 120 ],
      [ 1.45, 33.45 ], [ 1.66, 19.06 ], [ 1.68, 12.59 ], [ 1.69, 0 ]
],
    "totImpulseNs": 228.7,
    "totalWeightG": 664,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 165,
    "burnTimeS": 1,
    "caseInfo": "RMS-29/180",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H165",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H165R",
    "diameter": 29,
    "impulseClass": "H",
    "length": 194,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d2000231000000008a",
    "propInfo": "Redline",
    "propWeightG": 90,
    "samples": [
      [ 0, 0 ], [ 0.018, 55.05 ], [ 0.059, 157.3 ], [ 0.101, 168.5 ], [ 0.144, 173.2 ], [ 0.186, 179.2 ],
      [ 0.229, 183.9 ], [ 0.271, 187.9 ], [ 0.314, 188.1 ], [ 0.356, 188.9 ], [ 0.399, 190.5 ],
      [ 0.441, 187.3 ], [ 0.484, 189.2 ], [ 0.525, 186.5 ], [ 0.566, 185.5 ], [ 0.609, 180.8 ],
      [ 0.651, 177.7 ], [ 0.694, 170.6 ], [ 0.736, 167.2 ], [ 0.779, 158.8 ], [ 0.821, 155.7 ],
      [ 0.864, 153.3 ], [ 0.906, 136.3 ], [ 0.949, 73.53 ], [ 0.991, 20.67 ], [ 1.034, 4.448 ],
      [ 1.076, 0 ]
],
    "totImpulseNs": 165,
    "totalWeightG": 201.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 180,
    "burnTimeS": 1.3,
    "caseInfo": "RMS-29/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H180",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H180W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 238,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 228.5,
    "motorId": "5f4294d2000231000000008b",
    "propInfo": "White Lightning",
    "propWeightG": 123.9,
    "samples": [
      [ 0, 0 ], [ 0.024, 149.4 ], [ 0.075, 222.3 ], [ 0.127, 222.3 ], [ 0.178, 227.8 ], [ 0.229, 235 ],
      [ 0.281, 238.2 ], [ 0.333, 240.3 ], [ 0.384, 243.1 ], [ 0.435, 240.8 ], [ 0.487, 240.7 ],
      [ 0.539, 236.3 ], [ 0.59, 236.8 ], [ 0.642, 234.9 ], [ 0.694, 232.8 ], [ 0.745, 229.2 ],
      [ 0.796, 228.8 ], [ 0.848, 231.9 ], [ 0.899, 225.9 ], [ 0.95, 188.3 ], [ 1.002, 134.7 ],
      [ 1.054, 78.94 ], [ 1.105, 34.56 ], [ 1.156, 15.48 ], [ 1.208, 7.279 ], [ 1.26, 3.585 ],
      [ 1.313, 0 ]
],
    "totImpulseNs": 217.7,
    "totalWeightG": 252,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 215.4,
    "burnTimeS": 1,
    "caseInfo": "RMS-29/240",
    "certOrg": "National Association of Rocketry",
    "commonName": "H210",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H210R",
    "diameter": 29,
    "impulseClass": "H",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/H210.pdf",
    "length": 238,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 275.7,
    "motorId": "5f4294d2000231000000008c",
    "propInfo": "Redline",
    "propWeightG": 106.4,
    "samples": [
      [ 0, 0 ], [ 0.019, 105.9 ], [ 0.059, 211.3 ], [ 0.099, 219.8 ], [ 0.139, 229.6 ], [ 0.179, 235.1 ],
      [ 0.22, 241.6 ], [ 0.26, 242.7 ], [ 0.3, 245.3 ], [ 0.341, 249.1 ], [ 0.381, 253.4 ],
      [ 0.421, 258.6 ], [ 0.461, 260.2 ], [ 0.502, 258 ], [ 0.543, 259.2 ], [ 0.583, 256.6 ],
      [ 0.623, 252.4 ], [ 0.663, 245.1 ], [ 0.704, 219.9 ], [ 0.744, 209.3 ], [ 0.784, 200.6 ],
      [ 0.824, 193.6 ], [ 0.865, 184.3 ], [ 0.905, 153.9 ], [ 0.945, 58.24 ], [ 0.986, 13.21 ],
      [ 1.027, 0 ]
],
    "totImpulseNs": 215.4,
    "totalWeightG": 246.4,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 211,
    "burnTimeS": 0.97,
    "caseInfo": "38 20 M PVC (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H211",
    "dataFiles": 2,
    "designation": "H211-PVC",
    "diameter": 38,
    "impulseClass": "H",
    "length": 516,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 674,
    "motorId": "5f4294d2000231000000008d",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.001119, 531 ], [ 0.01902, 634.5 ], [ 0.02237, 593.1 ], [ 0.03356, 544.8 ],
      [ 0.2964, 317.2 ], [ 0.3132, 186.2 ], [ 0.7438, 96.55 ], [ 0.97, 0 ]
],
    "totImpulseNs": 206,
    "totalWeightG": 600,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 220,
    "burnTimeS": 1,
    "caseInfo": "RMS-29/240",
    "certOrg": "National Association of Rocketry",
    "commonName": "H220",
    "dataFiles": 2,
    "delays": "4,6,8,10,12,14",
    "designation": "H220T",
    "diameter": 29,
    "impulseClass": "H",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/H220.pdf",
    "length": 238,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 275.5,
    "motorId": "5f4294d2000231000000008e",
    "propInfo": "Blue Thunder",
    "propWeightG": 106.4,
    "samples": [
      [ 0, 0 ], [ 0.001, 314.1 ], [ 0.101, 236.6 ], [ 0.201, 269.2 ], [ 0.301, 261.1 ], [ 0.401, 252.9 ],
      [ 0.721, 252.9 ], [ 0.801, 112.6 ], [ 0.901, 9.78 ], [ 0.961, 0 ]
],
    "totImpulseNs": 220,
    "totalWeightG": 250,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 225,
    "burnTimeS": 0.7,
    "caseInfo": "38 16 M HP (140CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H222",
    "dataFiles": 2,
    "designation": "H222-HP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 407,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 619,
    "motorId": "5f4294d2000231000000008f",
    "propWeightG": 22,
    "samples": [
      [ 0, 0 ], [ 0.001, 684.3 ], [ 0.0312, 657 ], [ 0.05357, 574.8 ], [ 0.05917, 349 ], [ 0.3478, 260.1 ],
      [ 0.3657, 191.6 ], [ 0.701, 0 ]
],
    "totImpulseNs": 161,
    "totalWeightG": 520,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 238,
    "burnTimeS": 0.71,
    "caseInfo": "RMS-29/180",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H238",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H238T",
    "diameter": 29,
    "impulseClass": "H",
    "length": 194,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 263.4,
    "motorId": "5f4294d20002310000000090",
    "propInfo": "Blue Thunder",
    "propWeightG": 83.4,
    "samples": [
      [ 0, 0 ], [ 0.019, 173.1 ], [ 0.059, 206.9 ], [ 0.1, 211 ], [ 0.141, 214.4 ], [ 0.181, 217.1 ],
      [ 0.222, 219.3 ], [ 0.263, 221 ], [ 0.303, 225 ], [ 0.344, 229.3 ], [ 0.384, 229.3 ],
      [ 0.425, 228.6 ], [ 0.466, 226.6 ], [ 0.506, 222.4 ], [ 0.547, 219.2 ], [ 0.588, 217.8 ],
      [ 0.628, 213.3 ], [ 0.669, 208.1 ], [ 0.709, 201 ], [ 0.75, 197 ], [ 0.791, 151.4 ],
      [ 0.831, 96.27 ], [ 0.872, 59.48 ], [ 0.912, 18.62 ], [ 0.953, 7.986 ], [ 0.995, 3.697 ],
      [ 1.036, 0 ]
],
    "totImpulseNs": 165.5,
    "totalWeightG": 196,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 242,
    "burnTimeS": 1.06,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H242",
    "dataFiles": 3,
    "delays": "4,6,8,10",
    "designation": "H242T",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 276.6,
    "motorId": "5f4294d20002310000000091",
    "propInfo": "Blue Thunder",
    "propWeightG": 114.7,
    "samples": [
      [ 0, 0 ], [ 0.03, 164.1 ], [ 0.093, 197.5 ], [ 0.155, 204.3 ], [ 0.218, 209 ], [ 0.28, 211.5 ],
      [ 0.343, 211.3 ], [ 0.405, 209.3 ], [ 0.468, 208.4 ], [ 0.531, 206.7 ], [ 0.595, 204 ],
      [ 0.657, 198.2 ], [ 0.72, 192.1 ], [ 0.782, 186.8 ], [ 0.845, 180.8 ], [ 0.907, 174.6 ],
      [ 0.97, 165.6 ], [ 1.033, 159.7 ], [ 1.097, 151.7 ], [ 1.159, 144.2 ], [ 1.222, 138.6 ],
      [ 1.284, 119.1 ], [ 1.347, 69.06 ], [ 1.409, 21.4 ], [ 1.472, 3.473 ], [ 1.535, 0.594 ],
      [ 1.599, 0 ]
],
    "totImpulseNs": 231.7,
    "totalWeightG": 264,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 238,
    "burnTimeS": 0.9,
    "caseInfo": "38 20 M HP (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H246",
    "dataFiles": 2,
    "designation": "H246-HP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 508,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 653,
    "motorId": "5f4294d20002310000000092",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001119, 609.1 ], [ 0.0123, 499.6 ], [ 0.5022, 253.2 ], [ 0.5145, 157.4 ],
      [ 0.9, 0 ]
],
    "totImpulseNs": 222,
    "totalWeightG": 598,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 268,
    "burnTimeS": 1.19,
    "caseInfo": "RMS-29/360",
    "certOrg": "National Association of Rocketry",
    "commonName": "H268",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H268R",
    "diameter": 29,
    "impulseClass": "H",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/H268.pdf",
    "length": 333,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000093",
    "propInfo": "Redline",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.022, 268.1 ], [ 0.069, 332.4 ], [ 0.116, 312.4 ], [ 0.164, 306.8 ], [ 0.211, 305.8 ],
      [ 0.259, 306.6 ], [ 0.306, 312.5 ], [ 0.354, 319.7 ], [ 0.401, 321.2 ], [ 0.448, 321 ],
      [ 0.495, 321.2 ], [ 0.542, 321.8 ], [ 0.59, 323.3 ], [ 0.638, 322.8 ], [ 0.685, 307 ],
      [ 0.732, 291.6 ], [ 0.779, 277.7 ], [ 0.826, 267.1 ], [ 0.874, 257.5 ], [ 0.921, 252.8 ],
      [ 0.969, 222.6 ], [ 1.016, 159.7 ], [ 1.064, 108.7 ], [ 1.111, 52.09 ], [ 1.159, 15.57 ],
      [ 1.207, 0 ]
],
    "totImpulseNs": 320,
    "totalWeightG": 358.4,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 275,
    "burnTimeS": 1.1,
    "caseInfo": "SU 29x275",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H275",
    "dataFiles": 2,
    "designation": "H275",
    "diameter": 29,
    "impulseClass": "H",
    "length": 275,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 641.6,
    "motorId": "5f4294d20002310000000094",
    "propWeightG": 163,
    "samples": [
      [ 0, 0 ], [ 0.01236, 792.8 ], [ 0.01546, 356.7 ], [ 0.1978, 312.7 ], [ 0.7975, 268.7 ],
      [ 0.9119, 255.4 ], [ 0.9923, 123.3 ], [ 1.042, 39.64 ], [ 1.1, 0 ]
],
    "totImpulseNs": 293.5,
    "totalWeightG": 255,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 252,
    "burnTimeS": 1.15,
    "caseInfo": "38 28 F HP (370CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H277",
    "dataFiles": 2,
    "designation": "H277-HP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 793,
    "motorId": "5f4294d20002310000000095",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001, 765.5 ], [ 0.07483, 703.4 ], [ 0.1196, 337.9 ], [ 0.9182, 179.3 ],
      [ 0.9585, 75.86 ], [ 0.9965, 41.38 ], [ 1.03, 48.28 ], [ 1.151, 0 ]
],
    "totImpulseNs": 292,
    "totalWeightG": 710,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 259,
    "burnTimeS": 0.62,
    "caseInfo": "38 20 F HP (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H300",
    "dataFiles": 2,
    "designation": "H300-HP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 508,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 710,
    "motorId": "5f4294d20002310000000096",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001, 558.6 ], [ 0.1162, 717.2 ], [ 0.1263, 269 ], [ 0.2158, 248.3 ], [ 0.2874, 241.4 ],
      [ 0.3355, 227.6 ], [ 0.621, 0 ]
],
    "totImpulseNs": 161,
    "totalWeightG": 612,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 283,
    "burnTimeS": 0.6,
    "caseInfo": "48 20 F PVC (185CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H303",
    "dataFiles": 2,
    "designation": "H303-PVC",
    "diameter": 38,
    "impulseClass": "H",
    "length": 508,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 752,
    "motorId": "5f4294d20002310000000097",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.001, 663.8 ], [ 0.04574, 780.1 ], [ 0.1095, 704.9 ], [ 0.1129, 342.2 ],
      [ 0.1777, 328.5 ], [ 0.1979, 308 ], [ 0.601, 0 ]
],
    "totImpulseNs": 172,
    "totalWeightG": 589,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 321,
    "burnTimeS": 0.95,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H340",
    "dataFiles": 2,
    "designation": "H340-SP",
    "diameter": 38,
    "impulseClass": "H",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 966,
    "motorId": "5f4294d20002310000000098",
    "samples": [
      [ 0, 0 ], [ 0.001, 920.3 ], [ 0.08575, 715.8 ], [ 0.1027, 345.1 ], [ 0.6841, 332.3 ],
      [ 0.7417, 255.6 ], [ 0.7671, 153.4 ], [ 0.951, 0 ]
],
    "totImpulseNs": 304.5,
    "totalWeightG": 816,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 500,
    "burnTimeS": 0.634,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H500",
    "dataFiles": 3,
    "designation": "H500-LW",
    "diameter": 38,
    "impulseClass": "H",
    "length": 295,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "motorId": "5f4294d20002310000000099",
    "propInfo": "Loki White",
    "propWeightG": 160,
    "samples": [
      [ 0, 0 ], [ 0.001, 189.3 ], [ 0.0116, 534.7 ], [ 0.09977, 539.5 ], [ 0.1995, 544.2 ],
      [ 0.3028, 553.7 ], [ 0.4026, 548.9 ], [ 0.5046, 544.2 ], [ 0.5847, 435.4 ], [ 0.6195, 9.464 ],
      [ 0.62, 0 ]
],
    "totImpulseNs": 317,
    "totalWeightG": 460,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 565,
    "burnTimeS": 0.566,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H565",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "320H565-14A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 244,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "motorId": "5f4294d2000231000000009a",
    "propInfo": "Vmax",
    "propWeightG": 165,
    "samples": [
      [ 0, 0 ], [ 0.01, 106.9 ], [ 0.02, 479.8 ], [ 0.03471, 528.9 ], [ 0.05151, 553.7 ],[ 0.07615, 578.5 ],
      [ 0.1008, 586.8 ], [ 0.1501, 611.6 ], [ 0.2, 607.1 ], [ 0.3, 614.7 ], [ 0.4, 616.6 ],
      [ 0.5, 622.4 ], [ 0.51, 645.3 ], [ 0.52, 629 ], [ 0.53, 535.2 ], [ 0.54, 327.4 ], [ 0.55, 148 ],
      [ 0.56, 60.36 ], [ 0.57, 0 ]
],
    "totImpulseNs": 319.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 651.4,
    "burnTimeS": 0.339,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H669",
    "dataFiles": 2,
    "delays": "P",
    "designation": "H669N",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 961.5,
    "motorId": "5f4294d2000231000000009b",
    "propInfo": "Warp 9",
    "propWeightG": 98,
    "samples": [
      [ 0, 0 ], [ 0.003, 141 ], [ 0.006, 523 ], [ 0.009, 934 ], [ 0.012, 1178 ], [ 0.016, 926 ],
      [ 0.019, 684 ], [ 0.022, 487 ], [ 0.025, 415 ], [ 0.028, 622 ], [ 0.031, 801 ], [ 0.0325, 906 ],
      [ 0.034, 866 ], [ 0.037, 755 ], [ 0.04, 737 ], [ 0.043, 666 ], [ 0.047, 737 ], [ 0.0485, 802 ],
      [ 0.05, 755 ], [ 0.053, 791 ], [ 0.056, 765 ], [ 0.059, 755 ], [ 0.062, 747 ], [ 0.069, 737 ],
      [ 0.075, 761 ], [ 0.082, 755 ], [ 0.088, 729 ], [ 0.093, 741 ], [ 0.1, 751 ], [ 0.2, 703 ],
      [ 0.25, 640 ], [ 0.3, 586 ], [ 0.306, 584 ], [ 0.309, 576 ], [ 0.312, 506 ], [ 0.318, 292 ],
      [ 0.325, 93 ], [ 0.329, 0 ]
],
    "totImpulseNs": 221,
    "totalWeightG": 252,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 999,
    "burnTimeS": 0.3202,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H999",
    "dataFiles": 2,
    "delays": "P",
    "designation": "H999N",
    "diameter": 38,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1710,
    "motorId": "5f4294d2000231000000009c",
    "propInfo": "Warp 9",
    "propWeightG": 147,
    "samples": [
      [ 0, 0 ], [ 0.003, 204 ], [ 0.006, 757 ], [ 0.009, 1357 ], [ 0.012, 1710 ], [ 0.016, 1345 ],
      [ 0.019, 995 ], [ 0.022, 710 ], [ 0.025, 606 ], [ 0.028, 905 ], [ 0.031, 1165 ], [ 0.0325, 1311 ],
      [ 0.034, 1258 ], [ 0.037, 1098 ], [ 0.04, 1072 ], [ 0.043, 969 ], [ 0.047, 1072 ], [ 0.0485, 1166 ],
      [ 0.05, 1098 ], [ 0.053, 1160 ], [ 0.056, 1117 ], [ 0.059, 1103 ], [ 0.062, 1093 ],[ 0.069, 1076 ],
      [ 0.075, 1110 ], [ 0.082, 1105 ], [ 0.088, 1065 ], [ 0.093, 1082 ], [ 0.1, 1092 ], [ 0.2, 1022 ],
      [ 0.25, 931 ], [ 0.3, 853 ], [ 0.306, 850 ], [ 0.309, 838 ], [ 0.312, 735 ], [ 0.318, 435 ],
      [ 0.325, 161 ], [ 0.329, 0 ]
],
    "totImpulseNs": 319.9,
    "totalWeightG": 331,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 76.33,
    "burnTimeS": 8.26,
    "caseInfo": "SU 54x235",
    "certOrg": "National Association of Rocketry",
    "commonName": "I65",
    "dataFiles": 2,
    "designation": "I65W",
    "diameter": 54,
    "impulseClass": "I",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/I65.pdf",
    "length": 235,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 150.1,
    "motorId": "5f4294d2000231000000009d",
    "propInfo": "White Lightning",
    "propWeightG": 369.7,
    "samples": [
      [ 0, 0 ], [ 0.18, 125.4 ], [ 0.544, 139.3 ], [ 0.908, 145.4 ], [ 1.273, 148.3 ], [ 1.638, 146.7 ],
      [ 2.002, 139 ], [ 2.367, 131.2 ], [ 2.731, 123.3 ], [ 3.096, 113.5 ], [ 3.46, 102.4 ],
      [ 3.825, 90.21 ], [ 4.19, 78.08 ], [ 4.554, 66.81 ], [ 4.919, 55.78 ], [ 5.283, 47.28 ],
      [ 5.648, 39.15 ], [ 6.012, 32.53 ], [ 6.377, 27.07 ], [ 6.742, 22.1 ], [ 7.106, 18.09 ],
      [ 7.471, 14.82 ], [ 7.835, 12.1 ], [ 8.2, 9.763 ], [ 8.565, 7.875 ], [ 8.929, 5.999 ],
      [ 9.294, 0 ]
],
    "totImpulseNs": 630.5,
    "totalWeightG": 761.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 69,
    "burnTimeS": 5,
    "caseInfo": "SU 29x406",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I69",
    "dataFiles": 2,
    "designation": "I69",
    "diameter": 29,
    "impulseClass": "I",
    "length": 406,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 149,
    "motorId": "5f4294d2000231000000009e",
    "propWeightG": 236,
    "samples": [
      [ 0, 0 ], [ 0.05, 78.67 ], [ 0.1, 149.7 ], [ 0.25, 133.5 ], [ 0.49, 111.5 ], [ 0.75, 100 ],
      [ 1.07, 93.18 ], [ 1.48, 87.83 ], [ 2, 82.49 ], [ 2.5, 78 ], [ 2.99, 73.32 ], [ 3.5, 64.5 ],
      [ 3.99, 48.88 ], [ 4.5, 29.79 ], [ 4.99, 9.17 ], [ 5.28, 0 ]
],
    "totImpulseNs": 369,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 80,
    "burnTimeS": 5.8,
    "caseInfo": "H 276 cc",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I80",
    "dataFiles": 1,
    "designation": "I80",
    "diameter": 29,
    "impulseClass": "I",
    "length": 730,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 104.4,
    "motorId": "5f4294d2000231000000009f",
    "propWeightG": 221.8,
    "samples": [
      [ 0, 0 ], [ 0.093, 84.1 ], [ 0.28, 102.1 ], [ 0.469, 118.7 ], [ 0.657, 117.1 ], [ 0.846, 114.8 ],
      [ 1.034, 112.2 ], [ 1.222, 109.8 ], [ 1.41, 107.6 ], [ 1.599, 105.9 ], [ 1.787, 102.2 ],
      [ 1.976, 100.4 ], [ 2.164, 99.27 ], [ 2.352, 96.27 ], [ 2.541, 91.95 ], [ 2.729, 89.38 ],
      [ 2.918, 86.43 ], [ 3.105, 54.54 ], [ 3.294, 41.9 ], [ 3.482, 33.37 ], [ 3.671, 26.52 ],
      [ 3.859, 21.32 ], [ 4.047, 16.95 ], [ 4.235, 14.39 ], [ 4.424, 13.46 ], [ 4.612, 12.78 ],
      [ 4.801, 0 ]
],
    "totImpulseNs": 359.5,
    "totalWeightG": 553,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 80,
    "burnTimeS": 5.75,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I80",
    "dataFiles": 1,
    "designation": "460NS-I80",
    "diameter": 38,
    "impulseClass": "I",
    "length": 646,
    "manufacturer": "Propulsion Polymers",
    "manufacturerAbbrev": "PP",
    "maxThrustN": 198.8,
    "motorId": "5f4294d200023100000000a0",
    "propWeightG": 332.4,
    "samples": [
      [ 0, 0 ], [ 0.04, 198.7 ], [ 0.08, 158.4 ], [ 0.57, 133.4 ], [ 0.9, 114.5 ], [ 1.15, 110 ],
      [ 1.73, 108 ], [ 2.3, 106 ], [ 2.88, 104 ], [ 3.45, 96.3 ], [ 3.75, 66.7 ], [ 4.03, 50 ],
      [ 4.6, 35.6 ], [ 5.18, 22.2 ], [ 5.75, 0 ]
],
    "totImpulseNs": 460,
    "totalWeightG": 842.4,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 90,
    "burnTimeS": 5.391,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I90",
    "dataFiles": 1,
    "designation": "I90L",
    "diameter": 29,
    "impulseClass": "I",
    "length": 921,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "motorId": "5f4294d200023100000000a1",
    "propWeightG": 289.3,
    "samples": [
      [ 0, 0 ], [ 0.127, 137.7 ], [ 0.383, 121.4 ], [ 0.64, 117 ], [ 0.897, 121.4 ], [ 1.154, 118.4 ],
      [ 1.41, 116.5 ], [ 1.668, 114.8 ], [ 1.925, 112.4 ], [ 2.181, 110.3 ], [ 2.439, 107.8 ],
      [ 2.696, 105.7 ], [ 2.952, 104.7 ], [ 3.209, 102.8 ], [ 3.467, 95.41 ], [ 3.723, 68.28 ],
      [ 3.98, 55 ], [ 4.237, 43.62 ], [ 4.494, 33.78 ], [ 4.751, 31.7 ], [ 5.008, 27.71 ],
      [ 5.265, 24.88 ], [ 5.522, 22.93 ], [ 5.779, 21.38 ], [ 6.035, 20.88 ], [ 6.293, 18.64 ],
      [ 6.55, 0 ]
],
    "totImpulseNs": 485.2,
    "totalWeightG": 684.5,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 108.5,
    "burnTimeS": 4.64,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I110",
    "dataFiles": 2,
    "designation": "499I110-P",
    "diameter": 38,
    "impulseClass": "I",
    "length": 606,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 162.6,
    "motorId": "5f4294d200023100000000a2",
    "propWeightG": 327,
    "samples": [
      [ 0, 0 ], [ 0.05, 240 ], [ 0.38, 200 ], [ 0.76, 182 ], [ 1.14, 160 ], [ 1.52, 142 ], [ 1.9, 125 ],
      [ 2.23, 113 ], [ 2.66, 71 ], [ 3.04, 44 ], [ 3.42, 29 ], [ 3.78, 22 ], [ 4, 0 ]
],
    "totImpulseNs": 499.7,
    "totalWeightG": 824,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 117,
    "burnTimeS": 4.4,
    "caseInfo": "38/580",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I117",
    "dataFiles": 2,
    "designation": "I117",
    "diameter": 38,
    "impulseClass": "I",
    "length": 914,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 435.6,
    "motorId": "5f4294d200023100000000a3",
    "propWeightG": 370,
    "samples": [
      [ 0, 0 ], [ 0.01, 203.4 ], [ 0.02, 339.9 ], [ 0.02, 358.8 ], [ 0.03, 367.9 ], [ 0.03, 361.5 ],
      [ 0.06, 277.4 ], [ 0.08, 277.1 ], [ 0.15, 251 ], [ 0.27, 256.1 ], [ 0.3, 248.6 ], [ 0.42, 194.8 ],
      [ 0.47, 222.6 ], [ 1.35, 174.8 ], [ 1.39, 184 ], [ 1.79, 150.1 ], [ 2.12, 156.6 ], [ 3.42, 54.16 ],
      [ 3.8, 40 ], [ 4.2, 17.22 ], [ 4.23, 0 ]
],
    "totImpulseNs": 591.6,
    "totalWeightG": 1133,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 119,
    "burnTimeS": 3,
    "caseInfo": "38/400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I119",
    "dataFiles": 2,
    "designation": "I119",
    "diameter": 38,
    "impulseClass": "I",
    "length": 711,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 454.4,
    "motorId": "5f4294d200023100000000a4",
    "propWeightG": 264,
    "samples": [
      [ 0, 0 ], [ 0.001, 192.1 ], [ 0.011, 262.2 ], [ 0.021, 341.5 ], [ 0.031, 338.4 ], [ 0.061, 221.3 ],
      [ 0.071, 195.9 ], [ 0.111, 189.2 ], [ 0.151, 202.2 ], [ 0.181, 233.4 ], [ 0.471, 230.3 ],
      [ 0.991, 200.9 ], [ 1.181, 165.4 ], [ 1.271, 172.1 ], [ 1.481, 162.6 ], [ 2.181, 74.18 ],
      [ 2.921, 24.69 ], [ 2.971, 10.98 ], [ 3.101, 10.06 ], [ 3.121, 0 ]
],
    "totImpulseNs": 407,
    "totalWeightG": 967,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 130,
    "burnTimeS": 3.62,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I130",
    "dataFiles": 1,
    "designation": "300CC098J-I130",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000a5",
    "propWeightG": 298,
    "samples": [
      [ 0, 0 ], [ 0.05, 200 ], [ 0.1, 223 ], [ 0.5, 205 ], [ 1, 187 ], [ 1.5, 169 ], [ 2, 151 ],
      [ 2.25, 143 ], [ 2.4, 89 ], [ 2.5, 71 ], [ 3, 40 ], [ 3.5, 18 ], [ 4, 0 ]
],
    "totImpulseNs": 470.6,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 130,
    "burnTimeS": 4.43,
    "caseInfo": "SU 38x330",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I130",
    "dataFiles": 2,
    "designation": "I130",
    "diameter": 38,
    "impulseClass": "I",
    "length": 330,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 258.7,
    "motorId": "5f4294d200023100000000a6",
    "propWeightG": 308,
    "samples": [
      [ 0, 0 ], [ 0.01546, 266.5 ], [ 0.0541, 160.8 ], [ 0.5023, 169.6 ], [ 2.233, 180.6 ],
      [ 2.488, 149.7 ], [ 2.991, 136.5 ], [ 3.493, 77.07 ], [ 4.011, 26.43 ], [ 4.43, 0 ]
],
    "totImpulseNs": 597.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 132,
    "burnTimeS": 4.83,
    "caseInfo": "SU 38x335",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I132",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "I132W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 335,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 512.9,
    "motorId": "5f4294d200023100000000a7",
    "propInfo": "White Lightning",
    "propWeightG": 376.4,
    "samples": [
      [ 0, 0 ], [ 0.096, 204 ], [ 0.29, 174.2 ], [ 0.484, 168.9 ], [ 0.679, 170.8 ], [ 0.874, 173 ],
      [ 1.069, 174.3 ], [ 1.264, 174.6 ], [ 1.458, 174.4 ], [ 1.652, 174.6 ], [ 1.847, 173 ],
      [ 2.042, 169.2 ], [ 2.236, 164.3 ], [ 2.431, 157.1 ], [ 2.626, 149.6 ], [ 2.821, 138.4 ],
      [ 3.016, 124.2 ], [ 3.21, 107.6 ], [ 3.404, 89.78 ], [ 3.599, 71.75 ], [ 3.794, 55.12 ],
      [ 3.989, 42.26 ], [ 4.183, 31.37 ], [ 4.378, 21.98 ], [ 4.573, 14.39 ], [ 4.768, 8.794 ],
      [ 4.962, 0 ]
],
    "totImpulseNs": 610.7,
    "totalWeightG": 512.1,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 134,
    "burnTimeS": 5.15,
    "caseInfo": "SU 38x355",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I134",
    "dataFiles": 2,
    "designation": "I134",
    "diameter": 38,
    "impulseClass": "I",
    "length": 355,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 268.8,
    "motorId": "5f4294d200023100000000a8",
    "propWeightG": 300.5,
    "samples": [
      [ 0, 0 ], [ 0.1, 268.8 ], [ 0.2, 138 ], [ 1, 116 ], [ 2, 102 ], [ 3, 85 ], [ 4, 67 ], [ 4.65, 16.46 ],
      [ 4.82, 6.86 ], [ 5.07, 6.86 ], [ 5.15, 0 ]
],
    "totImpulseNs": 444.9,
    "totalWeightG": 581,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 136,
    "burnTimeS": 3.728,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I136",
    "dataFiles": 1,
    "designation": "300CC098J2-I136",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000a9",
    "propWeightG": 283,
    "samples": [
      [ 0, 0 ], [ 0.155, 256.2 ], [ 0.5, 232.8 ], [ 1, 212.8 ], [ 1.5, 196 ], [ 2, 175 ], [ 2.21, 163.3 ],
      [ 2.4, 100.9 ], [ 2.5, 83.81 ], [ 3, 42.47 ], [ 3.5, 19.75 ], [ 3.7, 15.26 ], [ 3.8, 0 ]
],
    "totImpulseNs": 507,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 145,
    "burnTimeS": 3.731,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I145",
    "dataFiles": 1,
    "designation": "300CC098JFX-I145FX",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000aa",
    "propWeightG": 298,
    "samples": [
      [ 0, 0 ], [ 0.057, 256.2 ], [ 0.204, 253.4 ], [ 0.497, 236.5 ], [ 1.002, 208.3 ], [ 1.205, 199.9 ],
      [ 1.376, 211.2 ], [ 1.482, 197.1 ], [ 2.003, 177.4 ], [ 2.125, 168.9 ], [ 2.5, 90.09 ],
      [ 2.997, 45.05 ], [ 3.282, 16.89 ], [ 3.7, 0 ]
],
    "totImpulseNs": 541,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 147,
    "burnTimeS": 3.5,
    "caseInfo": "38/400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I147",
    "dataFiles": 2,
    "designation": "I147",
    "diameter": 38,
    "impulseClass": "I",
    "length": 711,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 497.3,
    "motorId": "5f4294d200023100000000ab",
    "propWeightG": 264,
    "samples": [
      [ 0, 0 ], [ 0.001, 194.5 ], [ 0.021, 366.7 ], [ 0.031, 390.1 ], [ 0.031, 392.3 ], [ 0.051, 359 ],
      [ 0.071, 217.4 ], [ 0.101, 207.7 ], [ 0.381, 241.5 ], [ 1.071, 183.8 ], [ 2.331, 141.3 ],
      [ 2.511, 100 ], [ 2.751, 71.51 ], [ 3.321, 34.49 ], [ 3.891, 17.18 ], [ 4.431, 7.17 ],
      [ 4.451, 0 ]
],
    "totImpulseNs": 519.5,
    "totalWeightG": 967,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 150,
    "burnTimeS": 2.75,
    "caseInfo": "38X285.7 38X314(D)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I150",
    "dataFiles": 2,
    "delays": "20,P",
    "designation": "I150",
    "diameter": 38,
    "impulseClass": "I",
    "length": 229,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 155.5,
    "motorId": "5f4294d200023100000000ac",
    "propWeightG": 171.8,
    "samples": [
      [ 0, 0 ], [ 0.05, 101.3 ], [ 0.152, 159.2 ], [ 0.255, 169.7 ], [ 0.358, 179.6 ], [ 0.46, 188.2 ],
      [ 0.564, 193.4 ], [ 0.667, 204.5 ], [ 0.769, 212 ], [ 0.872, 212.9 ], [ 0.975, 208.1 ],
      [ 1.077, 196.6 ], [ 1.18, 191 ], [ 1.283, 186.1 ], [ 1.386, 181.8 ], [ 1.49, 177.9 ],
      [ 1.592, 175.9 ], [ 1.695, 173.7 ], [ 1.798, 170.7 ], [ 1.9, 161.8 ], [ 2.003, 149.1 ],
      [ 2.106, 124.9 ], [ 2.208, 68.39 ], [ 2.311, 20.12 ], [ 2.415, 7.794 ], [ 2.518, 4.464 ],
      [ 2.621, 0 ]
],
    "totImpulseNs": 346.2,
    "totalWeightG": 422,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 154,
    "burnTimeS": 2.99,
    "caseInfo": "RMS-38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I154",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I154J",
    "diameter": 38,
    "impulseClass": "I",
    "length": 241,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 170.5,
    "motorId": "5f4294d200023100000000ad",
    "propInfo": "Blackjack",
    "propWeightG": 252.8,
    "samples": [
      [ 0, 0 ], [ 0.066, 120.4 ], [ 0.199, 150.6 ], [ 0.332, 151.7 ], [ 0.466, 156.8 ], [ 0.599, 150.3 ],
      [ 0.732, 150.6 ], [ 0.866, 145.1 ], [ 0.999, 144.5 ], [ 1.133, 145.2 ], [ 1.268, 145.9 ],
      [ 1.401, 141.7 ], [ 1.534, 142.8 ], [ 1.668, 141.2 ], [ 1.801, 141 ], [ 1.934, 137.8 ],
      [ 2.068, 128.4 ], [ 2.202, 122.3 ], [ 2.336, 112 ], [ 2.47, 105.3 ], [ 2.603, 96.6 ],
      [ 2.736, 90.47 ], [ 2.87, 57.43 ], [ 3.003, 20.49 ], [ 3.136, 4.707 ], [ 3.271, 2.966 ],
      [ 3.405, 0 ]
],
    "totImpulseNs": 378,
    "totalWeightG": 491.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 155,
    "burnTimeS": 3.5,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I155",
    "dataFiles": 2,
    "designation": "I155-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 355,
    "motorId": "5f4294d200023100000000ae",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.01119, 222.4 ], [ 2.713, 150.6 ], [ 2.83, 82.12 ], [ 2.964, 58.17 ], [ 3.5, 0 ]
],
    "totImpulseNs": 541,
    "totalWeightG": 725,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 155,
    "burnTimeS": 3.13,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I160",
    "dataFiles": 1,
    "delays": "20,P",
    "designation": "484NS-I160",
    "diameter": 38,
    "impulseClass": "I",
    "length": 646,
    "manufacturer": "Propulsion Polymers",
    "manufacturerAbbrev": "PP",
    "maxThrustN": 299,
    "motorId": "5f4294d200023100000000af",
    "propWeightG": 307.7,
    "samples": [
      [ 0, 0 ], [ 0.04, 298.9 ], [ 0.08, 272.5 ], [ 0.32, 264.7 ], [ 0.65, 241.3 ], [ 0.97, 218 ],
      [ 1.29, 194.6 ], [ 1.61, 179 ], [ 2, 163.5 ], [ 2.26, 93.4 ], [ 2.58, 62.3 ], [ 2.9, 31.1 ],
      [ 3.23, 0 ]
],
    "totImpulseNs": 484,
    "totalWeightG": 856.4,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 160,
    "burnTimeS": 3.5,
    "caseInfo": "38X336.6 38X365(D)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I160",
    "dataFiles": 2,
    "designation": "I160",
    "diameter": 38,
    "impulseClass": "I",
    "length": 280,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 265.3,
    "motorId": "5f4294d200023100000000b0",
    "propWeightG": 231.9,
    "samples": [
      [ 0, 0 ], [ 0.068, 169.4 ], [ 0.206, 199.4 ], [ 0.346, 205.1 ], [ 0.485, 206.1 ], [ 0.624, 205.8 ],
      [ 0.763, 204.1 ], [ 0.902, 200.8 ], [ 1.042, 200.9 ], [ 1.18, 203.1 ], [ 1.319, 204.2 ],
      [ 1.458, 206.4 ], [ 1.598, 210.1 ], [ 1.736, 212.8 ], [ 1.875, 211.2 ], [ 2.015, 207.5 ],
      [ 2.154, 189.8 ], [ 2.293, 136.1 ], [ 2.431, 52.31 ], [ 2.571, 42.84 ], [ 2.71, 41.8 ],
      [ 2.849, 33.04 ], [ 2.987, 24.61 ], [ 3.127, 17.15 ], [ 3.267, 7.477 ], [ 3.406, 1.777 ],
      [ 3.546, 0 ]
],
    "totImpulseNs": 462.7,
    "totalWeightG": 516,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 161,
    "burnTimeS": 1.82,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I161",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "I161W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 266.6,
    "motorId": "5f4294d200023100000000b1",
    "propInfo": "White Lightning",
    "propWeightG": 193.2,
    "samples": [
      [ 0, 0 ], [ 0.043, 178.9 ], [ 0.131, 206.8 ], [ 0.221, 206.1 ], [ 0.31, 205.2 ], [ 0.4, 206.9 ],
      [ 0.49, 210.6 ], [ 0.579, 210.5 ], [ 0.669, 211.6 ], [ 0.758, 212.4 ], [ 0.848, 212.1 ],
      [ 0.938, 209.1 ], [ 1.027, 202.3 ], [ 1.116, 192.4 ], [ 1.204, 179.5 ], [ 1.294, 162.2 ],
      [ 1.383, 148.4 ], [ 1.473, 135.2 ], [ 1.563, 120.1 ], [ 1.652, 104 ], [ 1.742, 87.96 ],
      [ 1.831, 74.79 ], [ 1.921, 54.36 ], [ 2.01, 23.39 ], [ 2.1, 7.332 ], [ 2.19, 5.171 ],
      [ 2.279, 0 ]
],
    "totImpulseNs": 328.7,
    "totalWeightG": 366,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 169.2,
    "burnTimeS": 2.26,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I170",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "382I170-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 260.6,
    "motorId": "5f4294d200023100000000b2",
    "propInfo": "Classic",
    "propWeightG": 187.5,
    "samples": [
      [ 0, 0 ], [ 0.044, 133.3 ], [ 0.134, 230.1 ], [ 0.226, 224.1 ], [ 0.318, 223.7 ], [ 0.409, 223.9 ],
      [ 0.501, 225.3 ], [ 0.593, 226.7 ], [ 0.684, 229.4 ], [ 0.775, 232 ], [ 0.866, 233 ],
      [ 0.957, 233.1 ], [ 1.049, 232.7 ], [ 1.141, 231.6 ], [ 1.232, 227.7 ], [ 1.324, 225.8 ],
      [ 1.416, 221.2 ], [ 1.506, 217.4 ], [ 1.597, 211.7 ], [ 1.689, 205.7 ], [ 1.78, 198 ],
      [ 1.872, 125 ], [ 1.964, 61.85 ], [ 2.055, 23.28 ], [ 2.147, 2.066 ], [ 2.239, 0.799 ],
      [ 2.33, 0 ]
],
    "totImpulseNs": 382,
    "totalWeightG": 392,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 170,
    "burnTimeS": 2,
    "caseInfo": "38-390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I170",
    "dataFiles": 2,
    "designation": "I170S",
    "diameter": 38,
    "impulseClass": "I",
    "length": 314,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 211,
    "motorId": "5f4294d200023100000000b3",
    "propWeightG": 182,
    "samples": [
      [ 0, 0 ], [ 0.019, 194.9 ], [ 0.131, 190.5 ], [ 0.255, 191.6 ], [ 0.513, 199.3 ], [ 0.641, 204.8 ],
      [ 0.753, 207 ], [ 0.88, 209.2 ], [ 1, 208.1 ], [ 1.051, 208.1 ], [ 1.147, 207 ], [ 1.24, 201.5 ],
      [ 1.391, 198.2 ], [ 1.537, 190.5 ], [ 1.707, 181.7 ], [ 1.746, 178.4 ], [ 1.781, 174 ],
      [ 1.808, 168.5 ], [ 1.854, 132.1 ], [ 1.939, 53.95 ], [ 2.005, 22.02 ], [ 2.059, 9.909 ],
      [ 2.13, 0 ]
],
    "totImpulseNs": 374,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 195,
    "burnTimeS": 2.1,
    "caseInfo": "RMS-38/600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I195",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I195J",
    "diameter": 38,
    "impulseClass": "I",
    "length": 299,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 216.3,
    "motorId": "5f4294d200023100000000b4",
    "propInfo": "Blackjack",
    "propWeightG": 301,
    "samples": [
      [ 0, 0 ], [ 0.033, 190.1 ], [ 0.103, 354 ], [ 0.173, 393.5 ], [ 0.243, 414.8 ], [ 0.314, 379.7 ],
      [ 0.383, 364.6 ], [ 0.453, 364.8 ], [ 0.524, 357.2 ], [ 0.594, 355.8 ], [ 0.664, 355.6 ],
      [ 0.734, 353.6 ], [ 0.804, 339.9 ], [ 0.874, 309.8 ], [ 0.944, 275 ], [ 1.014, 243.7 ],
      [ 1.084, 218.1 ], [ 1.154, 197.3 ], [ 1.224, 173.7 ], [ 1.295, 147 ], [ 1.365, 116.5 ],
      [ 1.434, 83.11 ], [ 1.505, 51.01 ], [ 1.575, 26.48 ], [ 1.645, 13.93 ], [ 1.716, 7.273 ],
      [ 1.786, 0 ]
],
    "totImpulseNs": 426.1,
    "totalWeightG": 572,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 200,
    "burnTimeS": 1.65,
    "caseInfo": "RMS-29/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I200",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I200W",
    "diameter": 29,
    "impulseClass": "I",
    "length": 333,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 463.3,
    "motorId": "5f4294d200023100000000b5",
    "propInfo": "White Lightning",
    "propWeightG": 187.3,
    "samples": [
      [ 0, 0 ], [ 0.033, 304 ], [ 0.103, 273.5 ], [ 0.174, 276.1 ], [ 0.245, 271.6 ], [ 0.316, 268.2 ],
      [ 0.386, 258.4 ], [ 0.457, 252.5 ], [ 0.528, 246.6 ], [ 0.599, 242.3 ], [ 0.67, 237.7 ],
      [ 0.741, 234.8 ], [ 0.811, 233.2 ], [ 0.882, 230.7 ], [ 0.953, 225 ], [ 1.024, 221.7 ],
      [ 1.095, 214.5 ], [ 1.166, 177.4 ], [ 1.236, 154.2 ], [ 1.307, 119.1 ], [ 1.378, 91.59 ],
      [ 1.449, 65.33 ], [ 1.52, 32.88 ], [ 1.591, 28.7 ], [ 1.661, 22.21 ], [ 1.732, 15.56 ],
      [ 1.803, 0 ]
],
    "totImpulseNs": 324.5,
    "totalWeightG": 357.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 205,
    "burnTimeS": 2.288,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I205",
    "dataFiles": 1,
    "designation": "300CC125J-I205",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000b6",
    "propWeightG": 298,
    "samples": [
      [ 0, 0 ], [ 0.05, 312 ], [ 0.1, 347 ], [ 0.5, 312 ], [ 1, 258 ], [ 1.35, 223 ], [ 1.6, 125 ],
      [ 1.75, 80 ], [ 2, 45 ], [ 2.25, 22 ], [ 2.75, 0 ]
],
    "totImpulseNs": 469,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 205,
    "burnTimeS": 1.88,
    "caseInfo": "Pro38-3G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I205",
    "dataFiles": 2,
    "designation": "I205",
    "diameter": 38,
    "impulseClass": "I",
    "length": 244,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 236,
    "motorId": "5f4294d200023100000000b7",
    "propWeightG": 206.1,
    "samples": [
      [ 0, 0 ], [ 0.1, 181.5 ], [ 0.13, 213.3 ], [ 0.2, 210.1 ], [ 0.4, 226.6 ], [ 0.6, 235.8 ],
      [ 0.8, 234 ], [ 1, 232.8 ], [ 1.2, 227.7 ], [ 1.4, 216.8 ], [ 1.6, 197.2 ], [ 1.74, 183.1 ],
      [ 1.8, 87.3 ], [ 1.88, 0 ]
],
    "totImpulseNs": 380.9,
    "totalWeightG": 402.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 182,
    "burnTimeS": 2.72,
    "caseInfo": "38 36 M PVC (550CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I210",
    "dataFiles": 2,
    "designation": "I210-PVC",
    "diameter": 38,
    "impulseClass": "I",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 648,
    "motorId": "5f4294d200023100000000b8",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.001, 469 ], [ 0.4652, 386.2 ], [ 0.4988, 206.9 ], [ 2.255, 110.3 ], [ 2.35, 41.38 ],
      [ 2.406, 13.79 ], [ 2.721, 0 ]
],
    "totImpulseNs": 497,
    "totalWeightG": 870,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 211,
    "burnTimeS": 1.72,
    "caseInfo": "RMS-38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I211",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "I211W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 248,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 399.5,
    "motorId": "5f4294d200023100000000b9",
    "propInfo": "White Lightning",
    "propWeightG": 251.3,
    "samples": [
      [ 0, 0 ], [ 0.044, 257.3 ], [ 0.134, 295.5 ], [ 0.226, 296.1 ], [ 0.318, 298.2 ], [ 0.408, 295.1 ],
      [ 0.499, 287.7 ], [ 0.591, 282.6 ], [ 0.682, 272.9 ], [ 0.773, 267 ], [ 0.864, 257.6 ],
      [ 0.955, 250.5 ], [ 1.047, 238.6 ], [ 1.138, 228.6 ], [ 1.228, 215.1 ], [ 1.32, 198 ],
      [ 1.411, 180.6 ], [ 1.502, 161.3 ], [ 1.593, 146.7 ], [ 1.684, 134.5 ], [ 1.776, 101.2 ],
      [ 1.867, 52.69 ], [ 1.957, 35.46 ], [ 2.049, 24.32 ], [ 2.141, 11.16 ], [ 2.232, 4.587 ],
      [ 2.324, 0 ]
],
    "totImpulseNs": 441.6,
    "totalWeightG": 455,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 212.7,
    "burnTimeS": 1.71,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I212",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "364I212-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 244,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 255.2,
    "motorId": "5f4294d200023100000000ba",
    "propInfo": "Smoky Sam",
    "propWeightG": 248.1,
    "samples": [
      [ 0, 0 ], [ 0.04, 189.7 ], [ 0.2, 207.1 ], [ 0.4, 222.4 ], [ 0.6, 236.6 ], [ 0.8, 249.6 ],
      [ 0.95, 255.2 ], [ 1.01, 250.2 ], [ 1.21, 233.5 ], [ 1.4, 209 ], [ 1.55, 184 ], [ 1.6, 168.1 ],
      [ 1.63, 134.6 ], [ 1.69, 25.86 ], [ 1.71, 0 ]
],
    "totImpulseNs": 364,
    "totalWeightG": 475,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 218,
    "burnTimeS": 1.51,
    "caseInfo": "RMS-38/360",
    "certOrg": "National Association of Rocketry",
    "commonName": "I218",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I218R",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/I218.pdf",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000000bb",
    "propInfo": "Redline",
    "propWeightG": 172.7,
    "samples": [
      [ 0, 0 ], [ 0.027, 136.1 ], [ 0.088, 275 ], [ 0.148, 281 ], [ 0.208, 284.4 ], [ 0.268, 284 ],
      [ 0.327, 279.3 ], [ 0.388, 277.8 ], [ 0.448, 276.3 ], [ 0.509, 269.4 ], [ 0.57, 266 ],
      [ 0.63, 261.9 ], [ 0.69, 256.4 ], [ 0.75, 250.6 ], [ 0.81, 242.2 ], [ 0.87, 234.6 ],
      [ 0.93, 225.5 ], [ 0.991, 216.2 ], [ 1.053, 205.4 ], [ 1.112, 193.2 ], [ 1.173, 177.2 ],
      [ 1.233, 161.3 ], [ 1.292, 139.1 ], [ 1.353, 96.08 ], [ 1.413, 38.85 ], [ 1.474, 5.978 ],
      [ 1.535, 0 ]
],
    "totImpulseNs": 330,
    "totalWeightG": 371.8,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 203,
    "burnTimeS": 1.74,
    "caseInfo": "38 28 M PVC (370CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I221",
    "dataFiles": 2,
    "designation": "I221-PVC",
    "diameter": 38,
    "impulseClass": "I",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 433,
    "motorId": "5f4294d200023100000000bc",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.001, 482.8 ], [ 0.5044, 358.6 ], [ 0.52, 179.3 ], [ 1.493, 103.4 ], [ 1.538, 27.59 ],
      [ 1.741, 0 ]
],
    "totImpulseNs": 355,
    "totalWeightG": 710,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 222,
    "burnTimeS": 2.338,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I222",
    "dataFiles": 1,
    "designation": "300CC125J2-I222",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000bd",
    "propWeightG": 275,
    "samples": [
      [ 0, 0 ], [ 0.037, 394.1 ], [ 0.065, 439.2 ], [ 0.12, 450.5 ], [ 0.24, 436.7 ], [ 0.5, 411.2 ],
      [ 0.66, 392.5 ], [ 1, 338.3 ], [ 1.348, 292.6 ], [ 1.432, 259.3 ], [ 1.5, 193.7 ], [ 1.67, 117.1 ],
      [ 2, 57.36 ], [ 2.3, 22.36 ], [ 2.4, 0 ]
],
    "totImpulseNs": 519,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 225,
    "burnTimeS": 2.344,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I225",
    "dataFiles": 1,
    "designation": "300CC125JFX-I225FX",
    "diameter": 54,
    "impulseClass": "I",
    "length": 546,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000be",
    "propWeightG": 298,
    "samples": [
      [ 0, 0 ], [ 0.012, 309.7 ], [ 0.037, 343.5 ], [ 0.106, 351.9 ], [ 0.244, 354.7 ], [ 0.497, 337.8 ],
      [ 0.749, 320.9 ], [ 0.998, 298.4 ], [ 1.254, 273.1 ], [ 1.433, 239.3 ], [ 1.502, 194.3 ],
      [ 1.755, 101.4 ], [ 1.999, 53.49 ], [ 2.211, 11.26 ], [ 2.37, 0 ]
],
    "totImpulseNs": 527.5,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 230,
    "burnTimeS": 3.07,
    "caseInfo": "38X387.4 38X416(D)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I230",
    "dataFiles": 2,
    "delays": "20,P",
    "designation": "I230",
    "diameter": 38,
    "impulseClass": "I",
    "length": 331,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 359.2,
    "motorId": "5f4294d200023100000000bf",
    "propWeightG": 292,
    "samples": [
      [ 0, 0 ], [ 0.058, 292.6 ], [ 0.178, 317.7 ], [ 0.298, 309.9 ], [ 0.418, 305.2 ], [ 0.537, 299.7 ],
      [ 0.657, 298.2 ], [ 0.777, 294.6 ], [ 0.897, 293.8 ], [ 1.018, 289.7 ], [ 1.138, 288.2 ],
      [ 1.257, 284.6 ], [ 1.377, 281.1 ], [ 1.497, 274.9 ], [ 1.617, 269.8 ], [ 1.736, 258.9 ],
      [ 1.856, 242.2 ], [ 1.976, 207.6 ], [ 2.097, 136.7 ], [ 2.217, 86.51 ], [ 2.336, 74.32 ],
      [ 2.456, 51.25 ], [ 2.576, 45.55 ], [ 2.696, 27.05 ], [ 2.816, 6.382 ], [ 2.936, 1.423 ],
      [ 3.057, 0 ]
],
    "totImpulseNs": 540,
    "totalWeightG": 610,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 240,
    "burnTimeS": 2,
    "caseInfo": "Pro38-4G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I240",
    "dataFiles": 2,
    "designation": "I240",
    "diameter": 38,
    "impulseClass": "I",
    "length": 302,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 279,
    "motorId": "5f4294d200023100000000c0",
    "propWeightG": 279,
    "samples": [
      [ 0, 0 ], [ 0.043, 265.3 ], [ 0.131, 320.9 ], [ 0.221, 314.1 ], [ 0.31, 312.4 ], [ 0.399, 313.6 ],
      [ 0.488, 314.3 ], [ 0.577, 321.1 ], [ 0.667, 325.9 ], [ 0.755, 327 ], [ 0.844, 326.8 ],
      [ 0.933, 324.3 ], [ 1.023, 321.1 ], [ 1.111, 317.4 ], [ 1.2, 308.3 ], [ 1.29, 300.6 ],
      [ 1.379, 293.5 ], [ 1.468, 283.4 ], [ 1.556, 273.8 ], [ 1.646, 259.7 ], [ 1.735, 190.7 ],
      [ 1.824, 124.1 ], [ 1.912, 60.88 ], [ 2.002, 26.97 ], [ 2.092, 7.636 ], [ 2.181, 2.296 ],
      [ 2.271, 0 ]
],
    "totImpulseNs": 556,
    "totalWeightG": 503.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 260,
    "burnTimeS": 2.194,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I260",
    "dataFiles": 1,
    "designation": "440CC172J-I260",
    "diameter": 54,
    "impulseClass": "I",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000c2",
    "propWeightG": 382.8,
    "samples": [
      [ 0, 0 ], [ 0.03, 339 ], [ 0.041, 425.1 ], [ 0.12, 413.9 ], [ 0.216, 394.1 ], [ 0.354, 391.3 ],
      [ 0.497, 368.8 ], [ 0.749, 346.3 ], [ 1.002, 306.9 ], [ 1.36, 264.6 ], [ 1.454, 228 ],
      [ 1.502, 180.2 ], [ 1.686, 109.8 ], [ 2.003, 50.68 ], [ 2.2, 27.48 ], [ 2.3, 0 ]
],
    "totImpulseNs": 570.4,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 271,
    "burnTimeS": 1.44,
    "caseInfo": "AMW 38-390",
    "certOrg": "National Association of Rocketry",
    "commonName": "I271",
    "dataFiles": 2,
    "designation": "BB-38-390",
    "diameter": 38,
    "impulseClass": "I",
    "length": 258,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000000c3",
    "propInfo": "Blue Baboon",
    "propWeightG": 188.7,
    "samples": [
      [ 0, 0 ], [ 0.011, 119.5 ], [ 0.035, 213.9 ], [ 0.05, 245.9 ], [ 0.074, 262.7 ], [ 0.115, 269.4 ],
      [ 0.225, 267.7 ], [ 0.346, 282.9 ], [ 0.465, 296.4 ], [ 0.584, 303.2 ], [ 0.727, 311.5 ],
      [ 0.916, 318.2 ], [ 1.054, 325 ], [ 1.162, 331.4 ], [ 1.201, 326.7 ], [ 1.225, 313.2 ],
      [ 1.242, 286.2 ], [ 1.268, 241 ], [ 1.294, 188.9 ], [ 1.323, 136.8 ], [ 1.346, 87.56 ],
      [ 1.368, 45.47 ], [ 1.392, 18.52 ], [ 1.43, 0 ]
],
    "totImpulseNs": 390,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 280,
    "burnTimeS": 1,
    "caseInfo": "38-390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I280",
    "dataFiles": 2,
    "designation": "I280F",
    "diameter": 38,
    "impulseClass": "I",
    "length": 314,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 334,
    "motorId": "5f4294d200023100000000c4",
    "propWeightG": 182,
    "samples": [
      [ 0, 0 ], [ 0.009, 253.2 ], [ 0.055, 255.4 ], [ 0.219, 277.5 ], [ 0.482, 301.7 ], [ 0.67, 323.7 ],
      [ 0.735, 330.3 ], [ 0.797, 323.7 ], [ 1.001, 297.3 ], [ 1.162, 266.5 ], [ 1.205, 259.8 ],
      [ 1.236, 237.8 ], [ 1.363, 50.65 ], [ 1.428, 26.43 ], [ 1.5, 0 ]
],
    "totImpulseNs": 382,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 284,
    "burnTimeS": 1.94,
    "caseInfo": "RMS-38/600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I284",
    "dataFiles": 3,
    "delays": "4,6,8,10",
    "designation": "I284W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 299,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 570.6,
    "motorId": "5f4294d200023100000000c5",
    "propInfo": "White Lightning",
    "propWeightG": 315.9,
    "samples": [
      [ 0, 0 ], [ 0.033, 370.7 ], [ 0.103, 483.6 ], [ 0.174, 483.3 ], [ 0.245, 486.9 ], [ 0.316, 490.8 ],
      [ 0.386, 499.4 ], [ 0.457, 508.8 ], [ 0.528, 506.3 ], [ 0.599, 485.3 ], [ 0.67, 481 ],
      [ 0.741, 455.8 ], [ 0.811, 426.9 ], [ 0.882, 393.4 ], [ 0.953, 367.4 ], [ 1.024, 347.5 ],
      [ 1.095, 325.2 ], [ 1.166, 304.1 ], [ 1.236, 284.2 ], [ 1.307, 271.2 ], [ 1.378, 228.6 ],
      [ 1.449, 130.5 ], [ 1.52, 57.21 ], [ 1.591, 29.55 ], [ 1.661, 16.41 ], [ 1.732, 10.37 ],
      [ 1.803, 0 ]
],
    "totImpulseNs": 607.3,
    "totalWeightG": 555.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 285,
    "burnTimeS": 1.91,
    "caseInfo": "Pro38-4G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I285",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "512I285-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 302,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 350.6,
    "motorId": "5f4294d200023100000000c6",
    "propInfo": "Classic",
    "propWeightG": 272.4,
    "samples": [
      [ 0, 0 ], [ 0.1, 350.6 ], [ 0.15, 318.7 ], [ 0.2, 312.3 ], [ 0.4, 322.4 ], [ 0.6, 330.6 ],
      [ 0.8, 329.4 ], [ 1, 319.6 ], [ 1.2, 294.1 ], [ 1.4, 271.9 ], [ 1.6, 239.9 ], [ 1.66, 178.1 ],
      [ 1.8, 44.8 ], [ 1.91, 0 ]
],
    "totImpulseNs": 510.1,
    "totalWeightG": 505.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 285,
    "burnTimeS": 1.47,
    "caseInfo": "RMS-38/480",
    "certOrg": "National Association of Rocketry",
    "commonName": "I285",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I285R",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/I285R.pdf",
    "length": 250,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000000c7",
    "propInfo": "Redline",
    "propWeightG": 230.2,
    "samples": [
      [ 0, 0 ], [ 0.027, 171.4 ], [ 0.088, 325.6 ], [ 0.148, 341.7 ], [ 0.208, 358.9 ], [ 0.268, 373.7 ],
      [ 0.327, 374 ], [ 0.388, 368.4 ], [ 0.448, 367.5 ], [ 0.507, 361.9 ], [ 0.568, 351.9 ],
      [ 0.628, 346.1 ], [ 0.687, 341 ], [ 0.749, 329.4 ], [ 0.81, 321.6 ], [ 0.87, 310.9 ],
      [ 0.93, 296 ], [ 0.99, 283.7 ], [ 1.05, 269.7 ], [ 1.11, 253.4 ], [ 1.17, 240.2 ], [ 1.23, 224.1 ],
      [ 1.29, 204.1 ], [ 1.35, 118.7 ], [ 1.41, 23.48 ], [ 1.471, 2.046 ], [ 1.532, 0 ]
],
    "totImpulseNs": 420,
    "totalWeightG": 492.8,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "OOP",
    "avgThrustN": 285,
    "burnTimeS": 1.49,
    "caseInfo": "AMW 38-390",
    "certOrg": "National Association of Rocketry",
    "commonName": "I285",
    "dataFiles": 2,
    "designation": "GG-38-390",
    "diameter": 38,
    "impulseClass": "I",
    "length": 258,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000000c8",
    "propInfo": "Green Gorilla",
    "propWeightG": 206.5,
    "samples": [
      [ 0, 0 ], [ 0.013, 61.58 ], [ 0.032, 119.3 ], [ 0.055, 164.6 ], [ 0.076, 191 ], [ 0.094, 201 ],
      [ 0.139, 212.3 ], [ 0.232, 231.2 ], [ 0.357, 258.9 ], [ 0.456, 267.7 ], [ 0.592, 279 ],
      [ 0.716, 289.4 ], [ 0.841, 291.2 ], [ 0.936, 290.3 ], [ 1.051, 285.2 ], [ 1.139, 277.7 ],
      [ 1.204, 280.2 ], [ 1.243, 279 ], [ 1.265, 268.9 ], [ 1.286, 242.6 ], [ 1.319, 187.2 ],
      [ 1.359, 134.4 ], [ 1.387, 86.7 ], [ 1.407, 52.78 ], [ 1.428, 31.41 ], [ 1.448, 16.34 ],
      [ 1.465, 5.026 ], [ 1.48, 0 ]
],
    "totImpulseNs": 353,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 286.2,
    "burnTimeS": 1.7,
    "caseInfo": "Pro38-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I287",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "486I287-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 302,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 344.2,
    "motorId": "5f4294d200023100000000c9",
    "propInfo": "Smoky Sam",
    "propWeightG": 330.8,
    "samples": [
      [ 0, 0 ], [ 0.05, 275.9 ], [ 0.2, 292.5 ], [ 0.41, 309.2 ], [ 0.61, 327.5 ], [ 0.8, 341.7 ],
      [ 0.9, 344.2 ], [ 1.01, 331.7 ], [ 1.2, 311.7 ], [ 1.4, 280 ], [ 1.53, 245 ], [ 1.58, 176.6 ],
      [ 1.6, 141.8 ], [ 1.64, 68.99 ], [ 1.7, 17.48 ], [ 1.7, 0 ]
],
    "totImpulseNs": 486,
    "totalWeightG": 605,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 286.6,
    "burnTimeS": 1.5,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I290",
    "dataFiles": 2,
    "designation": "I290-SP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 635.5,
    "motorId": "5f4294d200023100000000ca",
    "samples": [
      [ 0, 0 ], [ 0.001, 521.5 ], [ 0.08575, 337.5 ], [ 0.14, 357.9 ], [ 0.1976, 398.8 ],[ 0.3095, 490.8 ],
      [ 0.4044, 449.9 ], [ 0.5908, 357.9 ], [ 0.7637, 419.3 ], [ 0.9332, 265.9 ], [ 1.089, 163.6 ],
      [ 1.242, 81.81 ], [ 1.501, 0 ]
],
    "totImpulseNs": 430,
    "totalWeightG": 884.5,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 300.4,
    "burnTimeS": 1.42,
    "caseInfo": "RMS-38/480",
    "certOrg": "National Association of Rocketry",
    "commonName": "I300",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I300T",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "http://nar.org/SandT/pdf/Aerotech/I300.pdf",
    "length": 250,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 474.4,
    "motorId": "5f4294d200023100000000cb",
    "propInfo": "Blue Thunder",
    "propWeightG": 221.6,
    "samples": [
      [ 0, 0 ], [ 0.001, 473.2 ], [ 0.101, 395.7 ], [ 0.201, 375.3 ], [ 0.301, 367.1 ], [ 0.401, 359 ],
      [ 0.501, 346.7 ], [ 0.601, 338.6 ], [ 0.701, 318.2 ], [ 0.801, 305.9 ], [ 0.901, 295.4 ],
      [ 1.071, 269.2 ], [ 1.101, 258 ], [ 1.201, 246.8 ], [ 1.301, 179.5 ], [ 1.401, 48.95 ],
      [ 1.501, 13.91 ], [ 1.601, 0 ]
],
    "totImpulseNs": 426.5,
    "totalWeightG": 441,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 308,
    "burnTimeS": 1.85,
    "caseInfo": "38 36 F HP (460CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I307",
    "dataFiles": 2,
    "designation": "I307-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 777,
    "motorId": "5f4294d200023100000000cc",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.002237, 551.7 ], [ 0.1991, 717.2 ], [ 0.2103, 386.2 ], [ 0.7562, 620.7 ],
      [ 0.8345, 455.2 ], [ 0.9418, 310.3 ], [ 1.092, 200 ], [ 1.224, 117.2 ], [ 1.85, 0 ]
],
    "totImpulseNs": 573,
    "totalWeightG": 810,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 310,
    "burnTimeS": 1.853,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I310",
    "dataFiles": 1,
    "designation": "440CC172J-I310",
    "diameter": 54,
    "impulseClass": "I",
    "length": 645,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000cd",
    "propWeightG": 401.2,
    "samples": [
      [ 0, 0 ], [ 0.042, 465.9 ], [ 0.128, 450.8 ], [ 0.216, 438.4 ], [ 0.303, 443.2 ], [ 0.391, 433.8 ],
      [ 0.478, 416 ], [ 0.566, 406.7 ], [ 0.653, 380.4 ], [ 0.741, 385.2 ], [ 0.828, 372.5 ],
      [ 0.916, 358.3 ], [ 1.003, 348.6 ], [ 1.091, 337.9 ], [ 1.178, 333.9 ], [ 1.266, 303.5 ],
      [ 1.353, 301.6 ], [ 1.441, 268.8 ], [ 1.528, 222.7 ], [ 1.616, 155.3 ], [ 1.703, 112.2 ],
      [ 1.791, 80.51 ], [ 1.878, 58.56 ], [ 1.966, 41.77 ], [ 2.053, 30.24 ], [ 2.141, 21.27 ],
      [ 2.228, 0 ]
],
    "totImpulseNs": 574.5,
    "totalWeightG": 1305,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 310,
    "burnTimeS": 2.1,
    "caseInfo": "AMW 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I310",
    "dataFiles": 2,
    "designation": "I310S",
    "diameter": 38,
    "impulseClass": "I",
    "length": 368,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 473.9,
    "motorId": "5f4294d200023100000000ce",
    "propWeightG": 316.3,
    "samples": [
      [ 0, 0 ], [ 0.045, 334.7 ], [ 0.136, 314.4 ], [ 0.228, 322.6 ], [ 0.32, 326.9 ], [ 0.411, 331.9 ],
      [ 0.503, 335.9 ], [ 0.595, 336.9 ], [ 0.686, 340.2 ], [ 0.778, 342.1 ], [ 0.87, 344.7 ],
      [ 0.961, 348.6 ], [ 1.053, 349.5 ], [ 1.146, 351.9 ], [ 1.239, 347.9 ], [ 1.33, 345.1 ],
      [ 1.422, 337 ], [ 1.514, 333.3 ], [ 1.605, 323.8 ], [ 1.697, 289 ], [ 1.789, 215.1 ],
      [ 1.88, 136.6 ], [ 1.972, 83.86 ], [ 2.064, 37.92 ], [ 2.155, 20.74 ], [ 2.248, 5.943 ],
      [ 2.341, 0 ]
],
    "totImpulseNs": 633.8,
    "type": "reload",
    "updatedOn": "2022-02-19"
  },
  {
    "availability": "OOP",
    "avgThrustN": 315,
    "burnTimeS": 1.8,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "I315",
    "dataFiles": 2,
    "designation": "SK-38-640",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000000cf",
    "propInfo": "Skidmark",
    "propWeightG": 333.7,
    "samples": [
      [ 0, 0 ], [ 0.011, 314.6 ], [ 0.03, 312.8 ], [ 0.066, 300.8 ], [ 0.084, 300.5 ], [ 0.12, 304.1 ],
      [ 0.175, 313 ], [ 0.266, 324.1 ], [ 0.356, 332.2 ], [ 0.447, 347.9 ], [ 0.538, 372 ],
      [ 0.629, 382.8 ], [ 0.719, 385.6 ], [ 0.81, 385.6 ], [ 0.901, 384.8 ], [ 0.992, 382.3 ],
      [ 1.082, 378.3 ], [ 1.173, 370.8 ], [ 1.264, 357.6 ], [ 1.355, 347.1 ], [ 1.445, 328.3 ],
      [ 1.536, 202.7 ], [ 1.627, 90.87 ], [ 1.718, 35.43 ], [ 1.808, 8.192 ], [ 1.815, 0 ]
],
    "sparky": true,
    "totImpulseNs": 572,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 329,
    "burnTimeS": 1.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I333",
    "dataFiles": 2,
    "designation": "I333-PVC",
    "diameter": 38,
    "impulseClass": "I",
    "length": 992,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 853,
    "motorId": "5f4294d200023100000000d0",
    "propWeightG": 184,
    "samples": [
      [ 0, 0 ], [ 0.008949, 855.4 ], [ 0.02908, 881.1 ], [ 0.05369, 504.7 ], [ 0.604, 342.2 ],
      [ 0.7964, 461.9 ], [ 1.7, 0 ]
],
    "totImpulseNs": 556,
    "totalWeightG": 929,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 352.1,
    "burnTimeS": 1.71,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I350",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "601I350-16A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 360,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 399.7,
    "motorId": "5f4294d200023100000000d1",
    "propInfo": "Smoky Sam",
    "propWeightG": 413.5,
    "samples": [
      [ 0, 0 ], [ 0.05, 399.7 ], [ 0.13, 390.1 ], [ 0.19, 386.2 ], [ 0.4, 388.1 ], [ 0.6, 388.1 ],
      [ 0.8, 388.1 ], [ 1, 389.9 ], [ 1.2, 387.4 ], [ 1.33, 368.8 ], [ 1.44, 350.4 ], [ 1.52, 320.4 ],
      [ 1.6, 164.8 ], [ 1.68, 36.77 ], [ 1.71, 0 ]
],
    "totImpulseNs": 601,
    "totalWeightG": 782,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 357,
    "burnTimeS": 1,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I357",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "I357T",
    "diameter": 38,
    "impulseClass": "I",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 432.8,
    "motorId": "5f4294d200023100000000d3",
    "propInfo": "Blue Thunder",
    "propWeightG": 174.4,
    "samples": [
      [ 0, 0 ], [ 0.028, 311.6 ], [ 0.087, 351.8 ], [ 0.147, 349.1 ], [ 0.206, 346.2 ], [ 0.266, 341.2 ],
      [ 0.325, 336.9 ], [ 0.384, 333.7 ], [ 0.444, 327 ], [ 0.503, 319.7 ], [ 0.563, 312.5 ],
      [ 0.622, 300.8 ], [ 0.681, 292.8 ], [ 0.741, 283.8 ], [ 0.8, 274.6 ], [ 0.859, 264.9 ],
      [ 0.919, 254.3 ], [ 0.978, 241.8 ], [ 1.037, 229 ], [ 1.097, 216.2 ], [ 1.156, 187.8 ],
      [ 1.216, 109.9 ], [ 1.275, 56.46 ], [ 1.334, 24.48 ], [ 1.394, 10.98 ], [ 1.454, 3.45 ],
      [ 1.515, 0 ]
],
    "totImpulseNs": 342,
    "totalWeightG": 343,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 360,
    "burnTimeS": 1.77,
    "caseInfo": "Pro38-5G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I360",
    "dataFiles": 2,
    "designation": "I360",
    "diameter": 38,
    "impulseClass": "I",
    "length": 360,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 555.5,
    "motorId": "5f4294d200023100000000d4",
    "propWeightG": 334.6,
    "samples": [
      [ 0, 0 ], [ 0.08, 555.5 ], [ 0.1, 489.7 ], [ 0.13, 448 ], [ 0.2, 449 ], [ 0.4, 483.7 ],
      [ 0.55, 498 ], [ 0.6, 494.9 ], [ 0.7, 481.9 ], [ 0.8, 457.9 ], [ 1, 406.6 ], [ 1.2, 344.4 ],
      [ 1.3, 309.3 ], [ 1.4, 182.2 ], [ 1.55, 158.9 ], [ 1.6, 101.8 ], [ 1.7, 55.8 ], [ 1.77, 0 ]
],
    "totImpulseNs": 625.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 355,
    "burnTimeS": 1.52,
    "caseInfo": "RMS-38/600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I366",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I366R",
    "diameter": 38,
    "impulseClass": "I",
    "length": 299,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 507,
    "motorId": "5f4294d200023100000000d5",
    "propInfo": "Redline",
    "propWeightG": 300,
    "samples": [
      [ 0, 0 ], [ 0.027, 323.3 ], [ 0.088, 485.4 ], [ 0.148, 483.7 ], [ 0.208, 479.9 ], [ 0.268, 473.4 ],
      [ 0.327, 466.2 ], [ 0.388, 457.4 ], [ 0.448, 448.8 ], [ 0.509, 441.5 ], [ 0.57, 430.2 ],
      [ 0.63, 421.5 ], [ 0.69, 411.8 ], [ 0.75, 398.9 ], [ 0.81, 387.5 ], [ 0.87, 375.4 ],
      [ 0.93, 361.3 ], [ 0.991, 345.1 ], [ 1.053, 330.4 ], [ 1.112, 312.6 ], [ 1.173, 293.5 ],
      [ 1.233, 275.1 ], [ 1.292, 262.4 ], [ 1.353, 230.9 ], [ 1.413, 118 ], [ 1.474, 23.61 ],
      [ 1.535, 0 ]
],
    "totImpulseNs": 539,
    "totalWeightG": 555.5,
    "type": "reload",
    "updatedOn": "2021-09-28"
  },
  {
    "availability": "OOP",
    "avgThrustN": 370,
    "burnTimeS": 1.7,
    "caseInfo": "AMW 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I370",
    "dataFiles": 2,
    "designation": "I370F",
    "diameter": 38,
    "impulseClass": "I",
    "length": 368,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 539.2,
    "motorId": "5f4294d200023100000000d6",
    "propWeightG": 316.9,
    "samples": [
      [ 0, 0 ], [ 0.035, 373.1 ], [ 0.109, 389.9 ], [ 0.184, 401.1 ], [ 0.259, 416.6 ], [ 0.334, 429.6 ],
      [ 0.409, 438 ], [ 0.484, 443.8 ], [ 0.559, 447.3 ], [ 0.634, 446.8 ], [ 0.709, 447.3 ],
      [ 0.784, 444.7 ], [ 0.859, 441.3 ], [ 0.933, 435.7 ], [ 1.007, 425.3 ], [ 1.082, 414.9 ],
      [ 1.157, 404.2 ], [ 1.232, 395.4 ], [ 1.307, 382.1 ], [ 1.382, 334.2 ], [ 1.457, 276 ],
      [ 1.532, 179.7 ], [ 1.607, 83.02 ], [ 1.682, 39.61 ], [ 1.757, 16.11 ], [ 1.832, 4.151 ],
      [ 1.907, 0 ]
],
    "totImpulseNs": 634.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 375,
    "burnTimeS": 1.57,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "I375",
    "dataFiles": 2,
    "designation": "GG-38-640",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000000d7",
    "propInfo": "Green Gorilla",
    "propWeightG": 340.2,
    "samples": [
      [ 0, 0 ], [ 0.013, 223.9 ], [ 0.045, 273.9 ], [ 0.092, 312.4 ], [ 0.14, 334.4 ], [ 0.219, 358 ],
      [ 0.298, 382 ], [ 0.377, 410.3 ], [ 0.457, 431.1 ], [ 0.536, 454.5 ], [ 0.615, 476.8 ],
      [ 0.694, 495.5 ], [ 0.773, 504.7 ], [ 0.852, 510.9 ], [ 0.931, 512 ], [ 1.011, 489.6 ],
      [ 1.09, 441.4 ], [ 1.169, 392.8 ], [ 1.248, 354.8 ], [ 1.327, 292.4 ], [ 1.406, 177.3 ],
      [ 1.486, 63.88 ], [ 1.565, 14.9 ], [ 1.583, 0 ]
],
    "totImpulseNs": 596,
    "totalWeightG": 733.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 398,
    "burnTimeS": 1,
    "caseInfo": "38 36 XF HP (460CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I400",
    "dataFiles": 2,
    "designation": "I400-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1006,
    "motorId": "5f4294d200023100000000d8",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.004474, 667.2 ], [ 0.0783, 898.2 ], [ 0.1163, 598.8 ], [ 0.2975, 521.8 ],
      [ 0.4206, 410.6 ], [ 0.5593, 487.6 ], [ 0.7383, 367.8 ], [ 1, 0 ]
],
    "totImpulseNs": 432,
    "totalWeightG": 925,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 405,
    "burnTimeS": 1.2,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I405",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "I405-LW",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20White.pdf",
    "length": 305,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 787.8,
    "motorId": "5f4294d200023100000000d9",
    "propInfo": "Loki White",
    "propWeightG": 252,
    "samples": [
      [ 0, 0 ], [ 0.01, 151.1 ], [ 0.03, 781.4 ], [ 0.05, 800.7 ], [ 0.06, 755.7 ], [ 0.09, 724.3 ],
      [ 0.12, 697.7 ], [ 0.15, 701 ], [ 0.17, 675.3 ], [ 0.2, 643.1 ], [ 0.3, 607.7 ], [ 0.4, 569.2 ],
      [ 0.5, 517.7 ], [ 0.6, 472.7 ], [ 0.7, 392.3 ], [ 0.8, 318.3 ], [ 0.9, 241.2 ], [ 1, 151.1 ],
      [ 1.1, 93.3 ], [ 1.15, 40 ], [ 1.2, 0 ]
],
    "totImpulseNs": 493.2,
    "totalWeightG": 543,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 435,
    "burnTimeS": 1.1,
    "caseInfo": "RMS-38/600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I435",
    "dataFiles": 3,
    "delays": "4,6,8,10",
    "designation": "I435T",
    "diameter": 38,
    "impulseClass": "I",
    "length": 299,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 785.6,
    "motorId": "5f4294d200023100000000da",
    "propInfo": "Blue Thunder",
    "propWeightG": 269.9,
    "samples": [
      [ 0, 0 ], [ 0.024, 808 ], [ 0.074, 749.7 ], [ 0.124, 709.2 ], [ 0.174, 656.2 ], [ 0.224, 636.6 ],
      [ 0.274, 621.8 ], [ 0.324, 592.3 ], [ 0.374, 584.6 ], [ 0.424, 573.3 ], [ 0.474, 547.7 ],
      [ 0.524, 540 ], [ 0.574, 525.3 ], [ 0.624, 500.5 ], [ 0.674, 485 ], [ 0.724, 464.3 ],
      [ 0.774, 442.8 ], [ 0.824, 424.5 ], [ 0.874, 405.9 ], [ 0.924, 393.4 ], [ 0.974, 317.2 ],
      [ 1.024, 217.6 ], [ 1.074, 126.2 ], [ 1.124, 74.39 ], [ 1.174, 30.03 ], [ 1.224, 9.38 ],
      [ 1.274, 0 ]
],
    "totImpulseNs": 568.9,
    "totalWeightG": 518,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 450,
    "burnTimeS": 1,
    "caseInfo": "AMW 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I450",
    "dataFiles": 2,
    "designation": "I450F",
    "diameter": 38,
    "impulseClass": "I",
    "length": 368,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 627,
    "motorId": "5f4294d200023100000000db",
    "propWeightG": 302,
    "samples": [
      [ 0, 0 ], [ 0.012, 634.2 ], [ 0.037, 550.5 ], [ 0.108, 519.7 ], [ 0.241, 510.9 ], [ 0.639, 550.5 ],
      [ 0.729, 554.9 ], [ 0.809, 546.1 ], [ 0.939, 497.7 ], [ 1.072, 471.2 ], [ 1.128, 440.4 ],
      [ 1.165, 387.6 ], [ 1.211, 207 ], [ 1.295, 88.08 ], [ 1.36, 26.43 ], [ 1.41, 0 ]
],
    "totImpulseNs": 633,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 479,
    "burnTimeS": 0.7,
    "caseInfo": "38 28 XF HP (275CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I500",
    "dataFiles": 2,
    "designation": "I500-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1069,
    "motorId": "5f4294d200023100000000dc",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.001119, 1155 ], [ 0.02013, 706.9 ], [ 0.03132, 1000 ], [ 0.5749, 103.4 ],
      [ 0.623, 120.7 ], [ 0.7, 0 ]
],
    "totImpulseNs": 336,
    "totalWeightG": 800,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 725,
    "burnTimeS": 0.84,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I727",
    "dataFiles": 2,
    "designation": "I727-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1662,
    "motorId": "5f4294d200023100000000dd",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.008475, 1278 ], [ 0.03559, 1662 ], [ 0.09831, 1508 ], [ 0.1441, 1483 ],
      [ 0.1712, 1176 ], [ 0.2186, 1023 ], [ 0.422, 792.5 ], [ 0.75, 0 ]
],
    "totImpulseNs": 611,
    "totalWeightG": 929,
    "type": "hybrid",
    "updatedOn": "2020-10-28"
  },
  {
    "availability": "regular",
    "avgThrustN": 732,
    "burnTimeS": 0.59,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I747",
    "dataFiles": 2,
    "designation": "I747-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 464,
    "motorId": "5f4294d200023100000000de",
    "propWeightG": 166,
    "samples": [ [ 0, 0 ], [ 0.001, 1917 ], [ 0.451, 0 ] ],
    "totImpulseNs": 431,
    "totalWeightG": 839,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 90,
    "burnTimeS": 6.85,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J90",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J90W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 243,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 188,
    "motorId": "5f4294d200023100000000df",
    "propInfo": "White Lightning",
    "propWeightG": 426,
    "samples": [
      [ 0, 0 ], [ 0.143, 116.2 ], [ 0.43, 165.4 ], [ 0.718, 176.5 ], [ 1.005, 184.6 ], [ 1.293, 187.2 ],
      [ 1.58, 183.7 ], [ 1.868, 175.5 ], [ 2.155, 167.7 ], [ 2.443, 156.9 ], [ 2.73, 143.5 ],
      [ 3.018, 128.9 ], [ 3.305, 110.9 ], [ 3.593, 94 ], [ 3.88, 79.66 ], [ 4.168, 67.47 ],
      [ 4.455, 57.27 ], [ 4.743, 48.01 ], [ 5.03, 40.52 ], [ 5.318, 33.9 ], [ 5.605, 28.25 ],
      [ 5.893, 23.33 ], [ 6.18, 19.27 ], [ 6.468, 15.92 ], [ 6.755, 12.73 ], [ 7.044, 9.903 ],
      [ 7.332, 0 ]
],
    "totImpulseNs": 707,
    "totalWeightG": 852.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 110,
    "burnTimeS": 6.8,
    "caseInfo": "SU 54x276",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J110",
    "dataFiles": 2,
    "designation": "J110",
    "diameter": 54,
    "impulseClass": "J",
    "length": 276,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 222,
    "motorId": "5f4294d200023100000000e0",
    "propWeightG": 421,
    "samples": [
      [ 0, 0 ], [ 0.1082, 193.8 ], [ 0.3864, 147.5 ], [ 1.005, 139.8 ], [ 4.034, 116.7 ],[ 5.008, 94.69 ],
      [ 6.012, 67.16 ], [ 6.538, 37.44 ], [ 6.8, 0 ]
],
    "totImpulseNs": 751.2,
    "totalWeightG": 875,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 115,
    "burnTimeS": 5.858,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J115",
    "dataFiles": 1,
    "designation": "440CC076J-J115",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000e1",
    "propWeightG": 415.9,
    "samples": [
      [ 0, 0 ], [ 0.129, 218.3 ], [ 0.391, 230.6 ], [ 0.653, 216.2 ], [ 0.916, 165.7 ], [ 1.178, 158.8 ],
      [ 1.441, 161.9 ], [ 1.703, 158 ], [ 1.966, 153 ], [ 2.228, 148.3 ], [ 2.491, 141.9 ],
      [ 2.753, 137 ], [ 3.016, 129.2 ], [ 3.278, 121.8 ], [ 3.541, 112 ], [ 3.803, 79.16 ],
      [ 4.066, 53.43 ], [ 4.328, 42.98 ], [ 4.591, 38.39 ], [ 4.853, 33.42 ], [ 5.116, 28.71 ],
      [ 5.378, 23.89 ], [ 5.641, 19.66 ], [ 5.903, 15.89 ], [ 6.166, 11.96 ], [ 6.428, 9.151 ],
      [ 6.691, 0 ]
],
    "totImpulseNs": 673.7,
    "totalWeightG": 1282,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 120,
    "burnTimeS": 6.313,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J120",
    "dataFiles": 1,
    "designation": "440CC076JFX-J120FX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000e2",
    "propWeightG": 446.2,
    "samples": [
      [ 0, 0 ], [ 0.134, 232.7 ], [ 0.405, 264.1 ], [ 0.676, 230.7 ], [ 0.948, 186 ], [ 1.22, 174.2 ],
      [ 1.491, 173.9 ], [ 1.763, 165.8 ], [ 2.034, 158 ], [ 2.305, 152.4 ], [ 2.577, 143.4 ],
      [ 2.849, 136 ], [ 3.12, 129.5 ], [ 3.392, 124.8 ], [ 3.664, 118.9 ], [ 3.934, 109.9 ],
      [ 4.206, 69.78 ], [ 4.478, 47.84 ], [ 4.749, 40.18 ], [ 5.021, 35.77 ], [ 5.293, 31.27 ],
      [ 5.564, 26.36 ], [ 5.835, 21.21 ], [ 6.107, 17.18 ], [ 6.378, 12.93 ], [ 6.65, 9.463 ],
      [ 6.922, 0 ]
],
    "totImpulseNs": 757.5,
    "totalWeightG": 1293,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 125,
    "burnTimeS": 7.75,
    "caseInfo": "SU 54x368",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J125",
    "dataFiles": 2,
    "designation": "J125W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 368,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 338.9,
    "motorId": "5f4294d200023100000000e3",
    "propInfo": "White Lightning",
    "propWeightG": 638.7,
    "samples": [
      [ 0, 0 ], [ 0.174, 223.9 ], [ 0.525, 254.8 ], [ 0.877, 275.3 ], [ 1.229, 285.2 ], [ 1.581, 280.3 ],
      [ 1.933, 264.5 ], [ 2.285, 244.4 ], [ 2.638, 223.8 ], [ 2.99, 204.7 ], [ 3.342, 185.4 ],
      [ 3.694, 166.8 ], [ 4.046, 147.7 ], [ 4.398, 127.9 ], [ 4.75, 108.5 ], [ 5.102, 92.58 ],
      [ 5.454, 77.82 ], [ 5.806, 63.84 ], [ 6.158, 53.02 ], [ 6.51, 44.51 ], [ 6.862, 37.54 ],
      [ 7.215, 32.2 ], [ 7.567, 27.21 ], [ 7.919, 22.85 ], [ 8.271, 18.6 ], [ 8.623, 14.79 ],
      [ 8.975, 0 ]
],
    "totImpulseNs": 1202,
    "totalWeightG": 1288,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 157,
    "burnTimeS": 6.83,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J135",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J135W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 368,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 274,
    "motorId": "5f4294d200023100000000e4",
    "propInfo": "White Lightning",
    "propWeightG": 633,
    "samples": [
      [ 0, 0 ], [ 0.147, 226.3 ], [ 0.444, 243.7 ], [ 0.742, 250.9 ], [ 1.04, 257.3 ], [ 1.338, 259.3 ],
      [ 1.635, 253.7 ], [ 1.933, 246.1 ], [ 2.231, 235.8 ], [ 2.529, 221.8 ], [ 2.827, 205.1 ],
      [ 3.125, 183.6 ], [ 3.423, 161.1 ], [ 3.72, 141 ], [ 4.017, 123 ], [ 4.315, 106.6 ],
      [ 4.612, 91.96 ], [ 4.91, 77.69 ], [ 5.208, 65.3 ], [ 5.506, 54.35 ], [ 5.804, 44.25 ],
      [ 6.102, 35.4 ], [ 6.4, 27.72 ], [ 6.698, 21.12 ], [ 6.996, 14.94 ], [ 7.294, 9.737 ],
      [ 7.592, 0 ]
],
    "totImpulseNs": 1069,
    "totalWeightG": 1141,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 134,
    "burnTimeS": 4.92,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J140",
    "dataFiles": 1,
    "designation": "664NS-J140",
    "diameter": 38,
    "impulseClass": "J",
    "length": 881,
    "manufacturer": "Propulsion Polymers",
    "manufacturerAbbrev": "PP",
    "maxThrustN": 201.6,
    "motorId": "5f4294d200023100000000e5",
    "propWeightG": 538.5,
    "samples": [
      [ 0, 0 ], [ 0.02, 249 ], [ 0.2, 320 ], [ 0.25, 240 ], [ 0.3, 236 ], [ 0.35, 191 ], [ 0.45, 236 ],
      [ 1.15, 223 ], [ 1.6, 178 ], [ 3.05, 165 ], [ 3.4, 102 ], [ 4.25, 45 ], [ 4.95, 22 ],[ 5, 0 ]
],
    "totImpulseNs": 664,
    "totalWeightG": 1166,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 144,
    "burnTimeS": 4.854,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J144",
    "dataFiles": 2,
    "designation": "J144",
    "diameter": 38,
    "impulseClass": "J",
    "length": 914,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "motorId": "5f4294d200023100000000e6",
    "propWeightG": 370,
    "samples": [
      [ 0, 0 ], [ 0.01, 301.3 ], [ 0.03, 376.4 ], [ 0.03, 376.9 ], [ 0.08, 247.3 ], [ 0.22, 273.4 ],
      [ 0.45, 263.6 ], [ 0.79, 232 ], [ 1.09, 175.7 ], [ 1.21, 185.7 ], [ 2.05, 149.6 ], [ 2.86, 127.6 ],
      [ 3.42, 69.07 ], [ 3.88, 42.69 ], [ 4.59, 20.49 ], [ 5.2, 10 ], [ 5.8, 6.12 ], [ 5.8, 0 ]
],
    "totImpulseNs": 699,
    "totalWeightG": 1133,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 145,
    "burnTimeS": 5.65,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J145",
    "dataFiles": 2,
    "designation": "J145H_2JET_STD",
    "diameter": 54,
    "impulseClass": "J",
    "length": 709,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 303.3,
    "motorId": "5f4294d200023100000000e7",
    "propWeightG": 409.5,
    "samples": [
      [ 0, 0 ], [ 0.113, 253.1 ], [ 0.34, 293.7 ], [ 0.567, 300.1 ], [ 0.794, 289.5 ], [ 1.021, 253.4 ],
      [ 1.248, 251.8 ], [ 1.476, 246 ], [ 1.704, 236.6 ], [ 1.931, 229.9 ], [ 2.158, 222.6 ],
      [ 2.385, 211.1 ], [ 2.612, 201.1 ], [ 2.841, 191.1 ], [ 3.069, 139.2 ], [ 3.296, 79.89 ],
      [ 3.523, 63.9 ], [ 3.75, 51.05 ], [ 3.977, 40.56 ], [ 4.205, 31.71 ], [ 4.433, 24.43 ],
      [ 4.66, 19.95 ], [ 4.887, 15.26 ], [ 5.115, 12.41 ], [ 5.342, 10.21 ], [ 5.57, 9.135 ],
      [ 5.798, 0 ]
],
    "totImpulseNs": 825.7,
    "totalWeightG": 1797,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "OOP",
    "avgThrustN": 148,
    "burnTimeS": 6.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J148",
    "dataFiles": 2,
    "designation": "J148",
    "diameter": 54,
    "impulseClass": "J",
    "length": 356,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 182.3,
    "motorId": "5f4294d200023100000000e8",
    "propWeightG": 357.2,
    "samples": [
      [ 0, 0 ], [ 0.1391, 218 ], [ 0.2318, 183.9 ], [ 0.4482, 171.8 ], [ 1.005, 170.7 ], [ 2.102, 170.7 ],
      [ 5.023, 147.5 ], [ 5.317, 133.2 ], [ 5.672, 49.55 ], [ 6.1, 0 ]
],
    "totImpulseNs": 903.4,
    "totalWeightG": 1179,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 155,
    "burnTimeS": 4.1,
    "caseInfo": "38 36 S HP",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J150",
    "dataFiles": 2,
    "designation": "J150-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 490,
    "motorId": "5f4294d200023100000000e9",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001, 266.9 ], [ 2.003, 184.8 ], [ 2.758, 150.6 ], [ 3.01, 92.39 ], [ 4.101, 0 ]
],
    "totImpulseNs": 654,
    "totalWeightG": 889,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 150,
    "burnTimeS": 5.009,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J150",
    "dataFiles": 1,
    "designation": "440CC086J-J150",
    "diameter": 54,
    "impulseClass": "J",
    "length": 645,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000ea",
    "propWeightG": 433.5,
    "samples": [
      [ 0, 0 ], [ 0.111, 177.5 ], [ 0.336, 193.7 ], [ 0.561, 200.1 ], [ 0.786, 204 ], [ 1.011, 200.5 ],
      [ 1.236, 197.2 ], [ 1.461, 192.7 ], [ 1.686, 189.9 ], [ 1.911, 185.9 ], [ 2.136, 183.1 ],
      [ 2.361, 179.3 ], [ 2.586, 174.2 ], [ 2.813, 171.1 ], [ 3.039, 164.9 ], [ 3.264, 160 ],
      [ 3.489, 154.6 ], [ 3.714, 148.7 ], [ 3.939, 92.09 ], [ 4.164, 55.33 ], [ 4.389, 42.91 ],
      [ 4.614, 32.9 ], [ 4.839, 24.74 ], [ 5.064, 16.45 ], [ 5.289, 8.527 ], [ 5.515, 4.923 ],
      [ 5.741, 0 ]
],
    "totImpulseNs": 751.4,
    "totalWeightG": 1306,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 160,
    "burnTimeS": 5.1,
    "caseInfo": "h 490 cc",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J160",
    "dataFiles": 1,
    "designation": "J160",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1219,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 410.7,
    "motorId": "5f4294d200023100000000eb",
    "propWeightG": 4.3,
    "samples": [
      [ 0, 0 ], [ 0.01546, 262 ], [ 0.1855, 255.4 ], [ 0.2782, 240 ], [ 0.2782, 229 ], [ 0.3864, 251 ],
      [ 0.51, 242.2 ], [ 0.6492, 213.6 ], [ 0.7883, 189.4 ], [ 1.005, 178.4 ], [ 1.453, 160.8 ],
      [ 2.009, 147.5 ], [ 2.442, 129.9 ], [ 2.906, 121.1 ], [ 2.968, 163 ], [ 3.153, 149.7 ],
      [ 3.323, 125.5 ], [ 3.679, 94.69 ], [ 3.972, 74.87 ], [ 4.328, 46.24 ], [ 4.699, 19.82 ],
      [ 5.1, 0 ]
],
    "totImpulseNs": 667.7,
    "totalWeightG": 544,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 170,
    "burnTimeS": 4.282,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J170",
    "dataFiles": 1,
    "designation": "440CC098J-J170",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000ec",
    "propWeightG": 407.5,
    "samples": [
      [ 0, 0 ], [ 0.092, 315.2 ], [ 0.278, 351.5 ], [ 0.466, 314.2 ], [ 0.653, 255.3 ], [ 0.841, 235.4 ],
      [ 1.027, 234.8 ], [ 1.214, 230.9 ], [ 1.401, 223.1 ], [ 1.589, 217.7 ], [ 1.776, 209.9 ],
      [ 1.962, 203.8 ], [ 2.149, 197.5 ], [ 2.336, 191.2 ], [ 2.524, 178.6 ], [ 2.711, 129.8 ],
      [ 2.898, 82.46 ], [ 3.084, 71.69 ], [ 3.272, 64.63 ], [ 3.459, 54.02 ], [ 3.647, 45.02 ],
      [ 3.833, 36.37 ], [ 4.02, 28.4 ], [ 4.207, 21.52 ], [ 4.395, 16.07 ], [ 4.582, 11.71 ],
      [ 4.77, 0 ]
],
    "totImpulseNs": 728,
    "totalWeightG": 1282,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 170,
    "burnTimeS": 4.4,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J170",
    "dataFiles": 1,
    "designation": "J170H_3JET_STD",
    "diameter": 54,
    "impulseClass": "J",
    "length": 709,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000000ed",
    "propWeightG": 409.5,
    "samples": [
      [ 0, 0 ], [ 0.005, 432.1 ], [ 0.054, 383.5 ], [ 0.114, 334.1 ], [ 0.188, 353.7 ], [ 0.316, 362.1 ],
      [ 0.405, 353.7 ], [ 0.508, 348.1 ], [ 0.637, 326.6 ], [ 0.721, 313.6 ], [ 0.775, 311.7 ],
      [ 0.903, 294.9 ], [ 0.997, 290.2 ], [ 1.111, 300.5 ], [ 1.199, 276.2 ], [ 1.397, 268.8 ],
      [ 1.51, 274.4 ], [ 1.594, 261.3 ], [ 1.742, 256.6 ], [ 1.91, 248.2 ], [ 1.999, 238.9 ],
      [ 2.098, 210.9 ], [ 2.192, 157.7 ], [ 2.305, 139 ], [ 2.399, 123.2 ], [ 2.601, 105.5 ],
      [ 2.695, 101.7 ], [ 2.887, 85.85 ], [ 3.011, 78.39 ], [ 3.1, 63.46 ], [ 3.164, 61.59 ],
      [ 3.302, 50.39 ], [ 3.411, 52.26 ], [ 3.519, 47.59 ], [ 3.613, 37.33 ], [ 3.82, 38.26 ],
      [ 3.993, 29.86 ], [ 4.107, 29.86 ], [ 4.205, 26.13 ], [ 4.294, 16.8 ], [ 4.487, 0 ]
],
    "totImpulseNs": 739,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "regular",
    "avgThrustN": 180,
    "burnTimeS": 4.5,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J180",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J180T",
    "diameter": 54,
    "impulseClass": "J",
    "length": 230,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 392,
    "motorId": "5f4294d200023100000000ee",
    "propInfo": "Blue Thunder",
    "propWeightG": 437.1,
    "samples": [
      [ 0, 0 ], [ 0.093, 301.6 ], [ 0.281, 313.2 ], [ 0.47, 313.7 ], [ 0.658, 308.3 ], [ 0.847, 300.1 ],
      [ 1.035, 290.7 ], [ 1.224, 278.9 ], [ 1.412, 263.8 ], [ 1.601, 246 ], [ 1.79, 226.7 ],
      [ 1.978, 207.3 ], [ 2.167, 187.1 ], [ 2.355, 168.3 ], [ 2.544, 150 ], [ 2.732, 133.1 ],
      [ 2.921, 116.3 ], [ 3.109, 100.1 ], [ 3.298, 84.51 ], [ 3.486, 70.45 ], [ 3.675, 57.26 ],
      [ 3.864, 44.45 ], [ 4.052, 33.34 ], [ 4.241, 24.65 ], [ 4.429, 17.96 ], [ 4.619, 12.39 ],
      [ 4.808, 0 ]
],
    "totImpulseNs": 764,
    "totalWeightG": 809.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 190,
    "burnTimeS": 4.353,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J190",
    "dataFiles": 1,
    "designation": "440CC098JFX-J190FX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000ef",
    "propWeightG": 443.3,
    "samples": [
      [ 0, 0 ], [ 0.095, 338.6 ], [ 0.287, 384.4 ], [ 0.481, 341.5 ], [ 0.674, 279.2 ], [ 0.867, 267.5 ],
      [ 1.06, 256.3 ], [ 1.254, 250.1 ], [ 1.447, 244.4 ], [ 1.64, 238.8 ], [ 1.833, 236.5 ],
      [ 2.026, 232 ], [ 2.219, 224 ], [ 2.413, 213.2 ], [ 2.606, 201.7 ], [ 2.799, 150.5 ],
      [ 2.992, 101.3 ], [ 3.185, 84 ], [ 3.378, 73.9 ], [ 3.571, 60.22 ], [ 3.765, 49.21 ],
      [ 3.958, 39.1 ], [ 4.151, 29.87 ], [ 4.344, 22.6 ], [ 4.538, 16.84 ], [ 4.731, 11.96 ],
      [ 4.925, 0 ]
],
    "totImpulseNs": 827,
    "totalWeightG": 1293,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 210,
    "burnTimeS": 3.99,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J210",
    "dataFiles": 3,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "836J210-16A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 335,
    "motorId": "5f4294d200023100000000f0",
    "propInfo": "Classic",
    "propWeightG": 396,
    "samples": [
      [ 0, 0 ], [ 0.04, 335 ], [ 0.16, 270.9 ], [ 0.41, 269.3 ], [ 0.8, 268.5 ], [ 1.18, 256.3 ],
      [ 1.62, 236.8 ], [ 2.03, 214.1 ], [ 2.38, 193.9 ], [ 2.79, 174.4 ], [ 3.2, 163.8 ],[ 3.6, 157.4 ],
      [ 3.75, 135.5 ], [ 3.86, 85.17 ], [ 3.99, 0 ]
],
    "totImpulseNs": 836,
    "totalWeightG": 842,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 213.5,
    "burnTimeS": 4,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J210",
    "dataFiles": 2,
    "designation": "J210H_4JET_STD",
    "diameter": 54,
    "impulseClass": "J",
    "length": 709,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 651.8,
    "motorId": "5f4294d200023100000000f1",
    "propWeightG": 471,
    "samples": [
      [ 0, 0 ], [ 0.007728, 651.8 ], [ 0.06955, 528.5 ], [ 0.2009, 488.9 ], [ 0.5023, 409.6 ],
      [ 0.9969, 374.4 ], [ 1.492, 312.7 ], [ 1.592, 286.3 ], [ 2.009, 167.4 ], [ 2.434, 88.08 ],
      [ 2.504, 101.3 ], [ 2.55, 74.87 ], [ 3.022, 57.25 ], [ 4, 0 ]
],
    "totImpulseNs": 853.8,
    "totalWeightG": 1497,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "OOP",
    "avgThrustN": 220,
    "burnTimeS": 3.277,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J220",
    "dataFiles": 1,
    "designation": "440CC110J-J220",
    "diameter": 54,
    "impulseClass": "J",
    "length": 645,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000f2",
    "propWeightG": 422.6,
    "samples": [
      [ 0, 0 ], [ 0.071, 278 ], [ 0.215, 271.7 ], [ 0.358, 289.9 ], [ 0.502, 298.2 ], [ 0.647, 293.8 ],
      [ 0.792, 290.6 ], [ 0.935, 283.2 ], [ 1.079, 276 ], [ 1.223, 271.8 ], [ 1.368, 269.8 ],
      [ 1.513, 263 ], [ 1.656, 257.5 ], [ 1.8, 253.3 ], [ 1.944, 245.8 ], [ 2.089, 239.7 ],
      [ 2.233, 230.9 ], [ 2.377, 220.2 ], [ 2.521, 159.2 ], [ 2.665, 97.18 ], [ 2.809, 73.15 ],
      [ 2.954, 58.77 ], [ 3.098, 48.97 ], [ 3.242, 37.55 ], [ 3.385, 27.41 ], [ 3.53, 19.27 ],
      [ 3.675, 0 ]
],
    "totImpulseNs": 720.9,
    "totalWeightG": 1305,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 216,
    "burnTimeS": 4.3,
    "caseInfo": "38 48 M HP (830CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J222",
    "dataFiles": 2,
    "designation": "J222-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 786,
    "motorId": "5f4294d200023100000000f3",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.005593, 547.5 ], [ 0.1678, 355.9 ], [ 2.864, 191.6 ], [ 2.959, 143.7 ],
      [ 3.087, 130 ], [ 3.468, 95.81 ], [ 4.3, 0 ]
],
    "totImpulseNs": 928,
    "totalWeightG": 1043,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 228,
    "burnTimeS": 5,
    "caseInfo": "SU 38x562",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J228",
    "dataFiles": 2,
    "designation": "J228",
    "diameter": 38,
    "impulseClass": "J",
    "length": 562,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 616,
    "motorId": "5f4294d200023100000000f4",
    "propWeightG": 569,
    "samples": [
      [ 0, 0 ], [ 0.03091, 665 ], [ 0.09274, 444.8 ], [ 0.2628, 356.7 ], [ 0.6646, 343.5 ],
      [ 0.9892, 317.1 ], [ 1.963, 259.8 ], [ 2.998, 193.8 ], [ 4.019, 118.9 ], [ 4.992, 35.23 ],
      [ 5.2, 0 ]
],
    "totImpulseNs": 1115,
    "totalWeightG": 839,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 240,
    "burnTimeS": 4.3,
    "caseInfo": "54 36 S BG (910CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J234",
    "dataFiles": 2,
    "designation": "J234-BG",
    "diameter": 54,
    "impulseClass": "J",
    "length": 9122,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 567.6,
    "motorId": "5f4294d200023100000000f5",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.005593, 229.3 ], [ 0.5034, 349 ], [ 3.479, 208.7 ], [ 3.624, 116.3 ],[ 3.758, 78.7 ],
      [ 4.3, 0 ]
],
    "totImpulseNs": 1033,
    "totalWeightG": 1764,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 242,
    "burnTimeS": 3.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J242",
    "dataFiles": 2,
    "designation": "J242-PVC",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 875,
    "motorId": "5f4294d200023100000000f6",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.01119, 448.3 ], [ 1.739, 269 ], [ 1.762, 165.5 ], [ 2.975, 48.28 ], [ 3.1, 0 ]
],
    "totImpulseNs": 752,
    "totalWeightG": 1065,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 245.3,
    "burnTimeS": 2.62,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J245",
    "dataFiles": 2,
    "designation": "J245-BG",
    "diameter": 54,
    "impulseClass": "J",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 681.1,
    "motorId": "5f4294d200023100000000f7",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.001, 444.8 ], [ 0.1408, 355.9 ], [ 1.052, 308 ], [ 2.065, 184.8 ], [ 2.16, 102.7 ],
      [ 2.283, 68.43 ], [ 2.621, 0 ]
],
    "totImpulseNs": 643.5,
    "totalWeightG": 1550,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 238,
    "burnTimeS": 2.8,
    "caseInfo": "38 36 M HP (550CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J246",
    "dataFiles": 2,
    "designation": "J246-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 490,
    "motorId": "5f4294d200023100000000f8",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.01678, 492.7 ], [ 0.02796, 328.5 ], [ 0.1342, 526.9 ], [ 0.3412, 403.8 ],
      [ 0.5201, 349 ], [ 2.002, 191.6 ], [ 2.125, 116.3 ], [ 2.8, 0 ]
],
    "totImpulseNs": 673,
    "totalWeightG": 861,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 255,
    "burnTimeS": 1.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I250",
    "dataFiles": 1,
    "designation": "I250-HP",
    "diameter": 38,
    "impulseClass": "I",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 736,
    "motorId": "5f4294d200023100000000f9",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.004474, 622.8 ], [ 0.02461, 438 ], [ 1.004, 273.7 ], [ 1.022, 171.1 ],
      [ 1.201, 157.4 ], [ 1.239, 136.9 ], [ 1.7, 0 ]
],
    "totImpulseNs": 430,
    "totalWeightG": 724,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 2.978,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J250",
    "dataFiles": 2,
    "designation": "440CC125J-J250",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000fa",
    "propWeightG": 404.6,
    "samples": [
      [ 0, 0 ], [ 0.064, 409.7 ], [ 0.194, 453.2 ], [ 0.324, 416.5 ], [ 0.454, 383.8 ], [ 0.584, 359.2 ],
      [ 0.715, 344 ], [ 0.845, 336.3 ], [ 0.975, 328.8 ], [ 1.105, 318.6 ], [ 1.235, 309.1 ],
      [ 1.366, 306.2 ], [ 1.496, 290.6 ], [ 1.626, 283.2 ], [ 1.756, 261.2 ], [ 1.886, 200.2 ],
      [ 2.017, 143.6 ], [ 2.147, 126.5 ], [ 2.277, 113.2 ], [ 2.407, 91.31 ], [ 2.538, 71.22 ],
      [ 2.668, 54.18 ], [ 2.798, 40.35 ], [ 2.928, 29.06 ], [ 3.058, 20.14 ], [ 3.19, 13.79 ],
      [ 3.321, 0 ]
],
    "totImpulseNs": 744.5,
    "totalWeightG": 1292,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 260.1,
    "burnTimeS": 4.5,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J260",
    "dataFiles": 2,
    "designation": "J260HW_3JET_EFX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 709,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 599,
    "motorId": "5f4294d200023100000000fb",
    "propWeightG": 558,
    "samples": [
      [ 0, 0 ], [ 0.007728, 599 ], [ 0.03864, 475.7 ], [ 0.1082, 506.5 ], [ 0.4637, 493.3 ],
      [ 0.7805, 475.7 ], [ 1.012, 427.2 ], [ 2.002, 330.3 ], [ 2.488, 193.8 ], [ 2.991, 114.5 ],
      [ 4.011, 57.25 ], [ 4.5, 0 ]
],
    "totImpulseNs": 1170,
    "totalWeightG": 1574,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "OOP",
    "avgThrustN": 270,
    "burnTimeS": 3.47,
    "caseInfo": "38X438.1 38X467(D)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J270",
    "dataFiles": 2,
    "delays": "20,P",
    "designation": "J270",
    "diameter": 38,
    "impulseClass": "J",
    "length": 384,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 427.6,
    "motorId": "5f4294d200023100000000fc",
    "propWeightG": 347.1,
    "samples": [
      [ 0, 0 ], [ 0.057, 357.6 ], [ 0.175, 386.5 ], [ 0.294, 368.1 ], [ 0.412, 360.6 ], [ 0.53, 356.1 ],
      [ 0.648, 353.9 ], [ 0.767, 351.9 ], [ 0.885, 349.9 ], [ 1.003, 348.7 ], [ 1.121, 347.6 ],
      [ 1.24, 343.1 ], [ 1.358, 338 ], [ 1.476, 330.6 ], [ 1.594, 315.5 ], [ 1.712, 293.3 ],
      [ 1.831, 266.1 ], [ 1.949, 184 ], [ 2.067, 131.6 ], [ 2.185, 109.2 ], [ 2.304, 89.57 ],
      [ 2.422, 74.94 ], [ 2.54, 55.7 ], [ 2.658, 31.86 ], [ 2.777, 17.75 ], [ 2.896, 10.11 ],
      [ 3.015, 0 ]
],
    "totImpulseNs": 648.5,
    "totalWeightG": 712,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 270,
    "burnTimeS": 2.971,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J270",
    "dataFiles": 1,
    "designation": "440CC125JFX-J270FX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 614,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000000fd",
    "propWeightG": 423.2,
    "samples": [
      [ 0, 0 ], [ 0.064, 438.6 ], [ 0.193, 498.6 ], [ 0.322, 468.9 ], [ 0.451, 438.3 ], [ 0.581, 412.7 ],
      [ 0.711, 390.7 ], [ 0.841, 376.2 ], [ 0.97, 362.2 ], [ 1.1, 347.6 ], [ 1.23, 333.5 ],
      [ 1.359, 324.4 ], [ 1.489, 311.5 ], [ 1.619, 298.1 ], [ 1.749, 278.4 ], [ 1.878, 220.2 ],
      [ 2.007, 150.3 ], [ 2.137, 125.6 ], [ 2.268, 122 ], [ 2.397, 91.4 ], [ 2.526, 71.67 ],
      [ 2.656, 55.78 ], [ 2.786, 41.82 ], [ 2.916, 30.46 ], [ 3.045, 22.24 ], [ 3.175, 16.42 ],
      [ 3.305, 0 ]
],
    "totImpulseNs": 802.2,
    "totalWeightG": 1296,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 276,
    "burnTimeS": 3.86,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J272",
    "dataFiles": 2,
    "designation": "J272-SP",
    "diameter": 54,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 573,
    "motorId": "5f4294d200023100000000fe",
    "samples": [
      [ 0, 0 ], [ 0.008475, 398.8 ], [ 0.1695, 572.6 ], [ 0.5339, 460.2 ], [ 0.8729, 388.6 ],
      [ 1.059, 357.9 ], [ 2.915, 204.5 ], [ 3.195, 71.58 ], [ 3.517, 40.9 ], [ 3.636, 51.13 ],
      [ 3.86, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1065,
    "totalWeightG": 1746,
    "type": "hybrid",
    "updatedOn": "2019-04-18"
  },
  {
    "availability": "regular",
    "avgThrustN": 275,
    "burnTimeS": 3.3,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J275",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J275W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 230,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 370,
    "motorId": "5f4294d200023100000000ff",
    "propInfo": "White Lightning",
    "propWeightG": 472,
    "samples": [
      [ 0, 0 ], [ 0.075, 239.7 ], [ 0.227, 289.1 ], [ 0.38, 299.8 ], [ 0.533, 312.7 ], [ 0.686, 323.9 ],
      [ 0.84, 332.2 ], [ 0.992, 336.4 ], [ 1.145, 335.1 ], [ 1.298, 329.5 ], [ 1.451, 325.3 ],
      [ 1.604, 310 ], [ 1.756, 292.9 ], [ 1.909, 275.7 ], [ 2.063, 257.3 ], [ 2.216, 234.9 ],
      [ 2.369, 213.1 ], [ 2.521, 182.5 ], [ 2.674, 167.9 ], [ 2.827, 153 ], [ 2.98, 138.1 ],
      [ 3.133, 105.6 ], [ 3.285, 67.37 ], [ 3.439, 29.24 ], [ 3.592, 14.6 ], [ 3.745, 6.662 ],
      [ 3.898, 0 ]
],
    "totImpulseNs": 774,
    "totalWeightG": 864.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 280,
    "burnTimeS": 2.559,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J280",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "716J280-16A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "motorId": "5f4294d20002310000000100",
    "propInfo": "Smoky Sam",
    "propWeightG": 512,
    "samples": [
      [ 0, 0 ], [ 0.1, 259.4 ], [ 0.3, 278.9 ], [ 0.6, 293.1 ], [ 0.9, 306.9 ], [ 1.2, 319.2 ],
      [ 1.5, 321.1 ], [ 1.8, 310.9 ], [ 2.11, 279.9 ], [ 2.35, 286.7 ], [ 2.4, 269.2 ], [ 2.44, 178.2 ],
      [ 2.49, 42.8 ], [ 2.54, 0 ]
],
    "totImpulseNs": 716.5,
    "totalWeightG": 954,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 287.5,
    "burnTimeS": 2.26,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J285",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "648J285-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 360,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 369.3,
    "motorId": "5f4294d20002310000000101",
    "propInfo": "Classic",
    "propWeightG": 312.5,
    "samples": [
      [ 0, 0 ], [ 0.06, 351 ], [ 0.15, 346 ], [ 0.25, 357.6 ], [ 0.5, 363.9 ], [ 0.75, 369.3 ],
      [ 1.03, 343.3 ], [ 1.27, 337.1 ], [ 1.51, 317.4 ], [ 1.75, 282.5 ], [ 1.93, 127.9 ],
      [ 2.02, 84.94 ], [ 2.25, 11.02 ], [ 2.26, 0 ]
],
    "totImpulseNs": 648,
    "totalWeightG": 595,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 292,
    "burnTimeS": 2.53,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J292",
    "dataFiles": 2,
    "designation": "J292-SP",
    "diameter": 54,
    "impulseClass": "J",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 627.8,
    "motorId": "5f4294d20002310000000102",
    "samples": [
      [ 0, 0 ], [ 0.008475, 552.2 ], [ 0.2627, 480.6 ], [ 0.4237, 419.3 ], [ 0.7627, 337.5 ],
      [ 1.975, 245.4 ], [ 2.076, 143.2 ], [ 2.53, 0 ]
],
    "sparky": true,
    "totImpulseNs": 734,
    "totalWeightG": 1542,
    "type": "hybrid",
    "updatedOn": "2019-04-18"
  },
  {
    "availability": "regular",
    "avgThrustN": 298,
    "burnTimeS": 4,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J295",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1195J295-16A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 450.5,
    "motorId": "5f4294d20002310000000103",
    "propInfo": "Classic",
    "propWeightG": 594,
    "samples": [
      [ 0, 0 ], [ 0.04, 450.5 ], [ 0.28, 428.7 ], [ 0.54, 423.3 ], [ 1, 391.6 ], [ 1.48, 352.3 ],
      [ 1.99, 304.4 ], [ 2.51, 266.2 ], [ 3, 243.3 ], [ 3.5, 216.9 ], [ 3.67, 126.5 ], [ 3.82, 64.36 ],
      [ 4, 0 ]
],
    "totImpulseNs": 1196,
    "totalWeightG": 1119,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 295,
    "burnTimeS": 3.386,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J295",
    "dataFiles": 1,
    "designation": "440CC172JFX-J295FX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 552,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000104",
    "propWeightG": 711.9,
    "samples": [
      [ 0, 0 ], [ 0.004, 467.3 ], [ 0.244, 461.7 ], [ 0.501, 416.7 ], [ 1.002, 377.3 ], [ 1.254, 343.5 ],
      [ 1.364, 315.3 ], [ 1.502, 219.6 ], [ 1.751, 112.6 ], [ 2.003, 50.68 ], [ 2.2, 0 ]
],
    "totImpulseNs": 998.8,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 300,
    "burnTimeS": 2.05,
    "caseInfo": "Pro38-5G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J300",
    "dataFiles": 2,
    "designation": "J300",
    "diameter": 38,
    "impulseClass": "J",
    "length": 360,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 552,
    "motorId": "5f4294d20002310000000105",
    "propWeightG": 345.4,
    "samples": [
      [ 0, 0 ], [ 0.043, 357 ], [ 0.131, 436.6 ], [ 0.221, 407.9 ], [ 0.31, 399.5 ], [ 0.4, 400.6 ],
      [ 0.49, 406.7 ], [ 0.578, 414.3 ], [ 0.667, 417.1 ], [ 0.756, 418.4 ], [ 0.846, 421.3 ],
      [ 0.935, 422.2 ], [ 1.025, 416 ], [ 1.114, 406.4 ], [ 1.202, 395.2 ], [ 1.292, 381.7 ],
      [ 1.381, 369.9 ], [ 1.471, 355.5 ], [ 1.56, 331.7 ], [ 1.649, 246.2 ], [ 1.738, 161.8 ],
      [ 1.827, 109.5 ], [ 1.917, 71.41 ], [ 2.006, 37.06 ], [ 2.096, 13.88 ], [ 2.185, 5.059 ],
      [ 2.275, 0 ]
],
    "totImpulseNs": 694,
    "totalWeightG": 606.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 315,
    "burnTimeS": 2.423,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J315",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J315R",
    "diameter": 54,
    "impulseClass": "J",
    "length": 230,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000106",
    "propInfo": "Redline",
    "propWeightG": 438,
    "samples": [
      [ 0, 0 ], [ 0.051, 189.7 ], [ 0.154, 337.5 ], [ 0.259, 354.5 ], [ 0.363, 364.1 ], [ 0.468, 371.5 ],
      [ 0.572, 373.2 ], [ 0.676, 376.1 ], [ 0.78, 373 ], [ 0.884, 369 ], [ 0.989, 367 ], [ 1.093, 358.8 ],
      [ 1.197, 351.3 ], [ 1.301, 339.3 ], [ 1.406, 325.2 ], [ 1.51, 311.3 ], [ 1.614, 300.5 ],
      [ 1.718, 288.6 ], [ 1.822, 278.3 ], [ 1.927, 270.5 ], [ 2.031, 262.1 ], [ 2.136, 245 ],
      [ 2.239, 236.2 ], [ 2.344, 188.3 ], [ 2.448, 63.67 ], [ 2.552, 18.75 ], [ 2.657, 0 ]
],
    "totImpulseNs": 763.3,
    "totalWeightG": 851.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 317,
    "burnTimeS": 3.151,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J317",
    "dataFiles": 1,
    "designation": "835CC172J-J317",
    "diameter": 81,
    "impulseClass": "J",
    "length": 552,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000107",
    "propWeightG": 711.9,
    "samples": [
      [ 0, 0 ], [ 0.071, 438.5 ], [ 0.215, 471 ], [ 0.358, 459.7 ], [ 0.502, 447.3 ], [ 0.647, 431.7 ],
      [ 0.792, 418.5 ], [ 0.935, 407.8 ], [ 1.079, 400.2 ], [ 1.223, 395.8 ], [ 1.368, 382.5 ],
      [ 1.513, 372.9 ], [ 1.656, 368 ], [ 1.8, 349.3 ], [ 1.944, 336.1 ], [ 2.089, 324.5 ],
      [ 2.233, 301.2 ], [ 2.377, 233.6 ], [ 2.521, 177 ], [ 2.665, 132.5 ], [ 2.809, 96.23 ],
      [ 2.954, 69.72 ], [ 3.098, 49.46 ], [ 3.242, 33.98 ], [ 3.385, 23.06 ], [ 3.53, 16.52 ],
      [ 3.675, 0 ]
],
    "totImpulseNs": 998.8,
    "totalWeightG": 1758,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 325.3,
    "burnTimeS": 2.26,
    "caseInfo": "Pro38-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J330",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "765J330-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 419,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 459.3,
    "motorId": "5f4294d20002310000000108",
    "propInfo": "Classic",
    "propWeightG": 375,
    "samples": [
      [ 0, 0 ], [ 0.05, 459.3 ], [ 0.16, 448.2 ], [ 0.27, 440.4 ], [ 0.51, 437.1 ], [ 0.75, 427.1 ],
      [ 1.02, 412.6 ], [ 1.26, 387 ], [ 1.5, 360.3 ], [ 1.69, 321.4 ], [ 1.79, 300.3 ], [ 1.91, 126.8 ],
      [ 1.99, 107.9 ], [ 2.23, 22.56 ], [ 2.26, 0 ]
],
    "totImpulseNs": 765,
    "totalWeightG": 702,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 330,
    "burnTimeS": 3.64,
    "caseInfo": "38X488.9 38X517(D)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J330",
    "dataFiles": 2,
    "delays": "20,P",
    "designation": "J330",
    "diameter": 38,
    "impulseClass": "J",
    "length": 433,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 660.7,
    "motorId": "5f4294d20002310000000109",
    "propWeightG": 411.9,
    "samples": [
      [ 0, 0 ], [ 0.055, 482 ], [ 0.169, 515.2 ], [ 0.283, 510 ], [ 0.398, 511.5 ], [ 0.512, 509.2 ],
      [ 0.626, 503.6 ], [ 0.74, 495.5 ], [ 0.854, 486.1 ], [ 0.969, 477.8 ], [ 1.083, 472.1 ],
      [ 1.197, 455.9 ], [ 1.31, 433.7 ], [ 1.425, 407.5 ], [ 1.54, 367.9 ], [ 1.654, 271.2 ],
      [ 1.768, 203.7 ], [ 1.881, 152.8 ], [ 1.996, 106.1 ], [ 2.11, 91.4 ], [ 2.225, 72.29 ],
      [ 2.339, 63.98 ], [ 2.452, 61.81 ], [ 2.567, 42.01 ], [ 2.681, 16.44 ], [ 2.796, 4.496 ],
      [ 2.91, 0 ]
],
    "totImpulseNs": 737.8,
    "totalWeightG": 788,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 330,
    "burnTimeS": 3.19,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J330",
    "dataFiles": 1,
    "designation": "835CC172JFX-J330FX",
    "diameter": 54,
    "impulseClass": "J",
    "length": 762,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000010a",
    "propWeightG": 738,
    "samples": [
      [ 0, 0 ], [ 0.068, 453.5 ], [ 0.206, 485.7 ], [ 0.345, 476.9 ], [ 0.483, 463.6 ], [ 0.623, 440 ],
      [ 0.761, 423.3 ], [ 0.9, 426.4 ], [ 1.04, 415.2 ], [ 1.178, 443.4 ], [ 1.317, 431.4 ],
      [ 1.456, 407 ], [ 1.595, 392.1 ], [ 1.733, 390.3 ], [ 1.872, 360.1 ], [ 2.01, 334.2 ],
      [ 2.15, 307.2 ], [ 2.289, 225.6 ], [ 2.427, 169.2 ], [ 2.567, 126.6 ], [ 2.705, 93.17 ],
      [ 2.844, 68.29 ], [ 2.983, 48.1 ], [ 3.122, 32.86 ], [ 3.26, 21.86 ], [ 3.4, 15.19 ],
      [ 3.54, 0 ]
],
    "totImpulseNs": 1051,
    "totalWeightG": 1777,
    "type": "hybrid",
    "updatedOn": "2024-02-15"
  },
  {
    "availability": "regular",
    "avgThrustN": 305,
    "burnTimeS": 2.4,
    "caseInfo": "38 48 M PVC (830CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J333",
    "dataFiles": 2,
    "designation": "J333-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1227,
    "motorId": "5f4294d2000231000000010b",
    "propWeightG": 110,
    "samples": [
      [ 0, 0 ], [ 0.001, 717.2 ], [ 0.2051, 800 ], [ 0.7532, 448.3 ], [ 0.7644, 269 ], [ 2.165, 62.07 ],
      [ 2.238, 27.59 ], [ 2.401, 0 ]
],
    "totImpulseNs": 733,
    "totalWeightG": 1064,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 318,
    "burnTimeS": 2.7,
    "caseInfo": "38 48 F PVC (735CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J345",
    "dataFiles": 2,
    "designation": "J345-PVC",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1222,
    "motorId": "5f4294d2000231000000010c",
    "propWeightG": 184,
    "samples": [
      [ 0, 0 ], [ 0.005593, 881.1 ], [ 0.0783, 667.2 ], [ 1.219, 376.4 ], [ 1.264, 359.3 ],
      [ 2.7, 0 ]
],
    "totImpulseNs": 891,
    "totalWeightG": 1118,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 445,
    "burnTimeS": 1.5,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J350",
    "dataFiles": 2,
    "delays": "4,6,8,10,12,14",
    "designation": "J350W",
    "diameter": 38,
    "impulseClass": "J",
    "length": 337,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 822.5,
    "motorId": "5f4294d2000231000000010d",
    "propInfo": "White Lightning",
    "propWeightG": 375.1,
    "samples": [
      [ 0, 0 ], [ 0.041, 841.4 ], [ 0.051, 767.1 ], [ 0.088, 698.2 ], [ 0.173, 644.5 ], [ 0.256, 621.1 ],
      [ 0.298, 564.6 ], [ 0.547, 544 ], [ 0.783, 487.5 ], [ 0.989, 418.7 ], [ 1.16, 359.4 ],
      [ 1.192, 340.2 ], [ 1.213, 320.9 ], [ 1.287, 216.2 ], [ 1.319, 179 ], [ 1.342, 126.7 ],
      [ 1.386, 84.01 ], [ 1.427, 53.71 ], [ 1.48, 45.45 ], [ 1.591, 20.66 ], [ 1.695, 0 ]
],
    "totImpulseNs": 700,
    "totalWeightG": 665,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 394.2,
    "burnTimeS": 1.78,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J350",
    "dataFiles": 2,
    "delays": "4,6,8,10,12,14",
    "designation": "J350W-OLD",
    "diameter": 38,
    "impulseClass": "J",
    "length": 337,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 890.4,
    "motorId": "5f4294d2000231000000010e",
    "propInfo": "White Lightning",
    "propWeightG": 381.1,
    "samples": [
      [ 0, 0 ], [ 0.038, 706.8 ], [ 0.115, 669.1 ], [ 0.192, 602.5 ], [ 0.27, 565.1 ], [ 0.348, 539.1 ],
      [ 0.425, 514.9 ], [ 0.503, 483.1 ], [ 0.581, 449.1 ], [ 0.658, 437.3 ], [ 0.736, 424.2 ],
      [ 0.815, 414.5 ], [ 0.892, 403 ], [ 0.97, 393.6 ], [ 1.048, 377.8 ], [ 1.125, 359.8 ],
      [ 1.203, 341.9 ], [ 1.281, 324.7 ], [ 1.358, 305.9 ], [ 1.436, 264.3 ], [ 1.515, 175.5 ],
      [ 1.592, 110.9 ], [ 1.67, 77.1 ], [ 1.748, 55.47 ], [ 1.825, 39.99 ], [ 1.903, 26.28 ],
      [ 1.981, 0 ]
],
    "totImpulseNs": 697.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 354.9,
    "burnTimeS": 1.91,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J355",
    "dataFiles": 2,
    "designation": "J355-BG",
    "diameter": 54,
    "impulseClass": "J",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 803.1,
    "motorId": "5f4294d2000231000000010f",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.001, 562.4 ], [ 0.1773, 501.1 ], [ 0.201, 286.3 ], [ 0.4349, 286.3 ],[ 0.6891, 337.5 ],
      [ 0.7027, 501.1 ], [ 0.8078, 490.8 ], [ 1.004, 521.5 ], [ 1.218, 419.3 ], [ 1.313, 429.5 ],
      [ 1.377, 460.2 ], [ 1.499, 429.5 ], [ 1.547, 225 ], [ 1.642, 122.7 ], [ 1.911, 0 ]
],
    "totImpulseNs": 678.9,
    "totalWeightG": 1564,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 338.7,
    "burnTimeS": 2.95,
    "caseInfo": "AMW 54-1050",
    "certOrg": "National Association of Rocketry",
    "commonName": "J357",
    "dataFiles": 2,
    "designation": "WT-54-1050",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/J357.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 402.4,
    "motorId": "5f4294d20002310000000110",
    "propInfo": "White Wolf",
    "propWeightG": 548.1,
    "samples": [
      [ 0, 0 ], [ 0.02, 129.6 ], [ 0.03, 205.9 ], [ 0.05, 265 ], [ 0.06, 316.5 ], [ 0.09, 326.1 ],
      [ 0.13, 314.6 ], [ 0.18, 301.3 ], [ 0.24, 299.3 ], [ 0.35, 312.7 ], [ 0.5, 326.1 ],[ 0.66, 333.7 ],
      [ 0.87, 345.1 ], [ 1.07, 358.5 ], [ 1.46, 383.2 ], [ 1.77, 398.4 ], [ 1.86, 400.4 ],
      [ 1.98, 402.4 ], [ 2.18, 398.4 ], [ 2.29, 390.8 ], [ 2.41, 369.9 ], [ 2.51, 354.7 ],
      [ 2.55, 352.8 ], [ 2.6, 347 ], [ 2.65, 335.6 ], [ 2.69, 310.8 ], [ 2.75, 249.7 ], [ 2.81, 175.4 ],
      [ 2.84, 108.7 ], [ 2.9, 53.38 ], [ 2.92, 20.98 ], [ 2.95, 0 ]
],
    "totImpulseNs": 998.4,
    "totalWeightG": 1210,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 340.8,
    "burnTimeS": 2.69,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J358",
    "dataFiles": 2,
    "designation": "J358-BG",
    "diameter": 54,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1147,
    "motorId": "5f4294d20002310000000111",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.008475, 726 ], [ 0.09322, 726 ], [ 0.1102, 501.1 ], [ 0.4831, 480.6 ],
      [ 0.5508, 398.8 ], [ 2.237, 286.3 ], [ 2.322, 153.4 ], [ 2.449, 112.5 ], [ 2.69, 0 ]
],
    "totImpulseNs": 913.9,
    "totalWeightG": 1743,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 360,
    "burnTimeS": 1.9,
    "caseInfo": "Pro38-6G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J360",
    "dataFiles": 2,
    "designation": "J360",
    "diameter": 38,
    "impulseClass": "J",
    "length": 419,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 828,
    "motorId": "5f4294d20002310000000112",
    "propWeightG": 413.1,
    "samples": [
      [ 0, 0 ], [ 0.041, 618.9 ], [ 0.124, 616.6 ], [ 0.207, 563.8 ], [ 0.291, 557.7 ], [ 0.374, 558.4 ],
      [ 0.457, 562.1 ], [ 0.541, 561.3 ], [ 0.624, 563.2 ], [ 0.708, 565.3 ], [ 0.793, 566.6 ],
      [ 0.876, 549.4 ], [ 0.959, 529.6 ], [ 1.043, 511.1 ], [ 1.126, 483.3 ], [ 1.209, 445.4 ],
      [ 1.293, 421.7 ], [ 1.377, 378.3 ], [ 1.461, 261.6 ], [ 1.545, 197.4 ], [ 1.628, 146.6 ],
      [ 1.711, 101.8 ], [ 1.795, 78.04 ], [ 1.878, 47.85 ], [ 1.961, 31.86 ], [ 2.046, 9.22 ],
      [ 2.13, 0 ]
],
    "totImpulseNs": 826,
    "totalWeightG": 709.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 365,
    "burnTimeS": 3.16,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "J365",
    "dataFiles": 2,
    "designation": "SK-54-1400",
    "diameter": 54,
    "impulseClass": "J",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000113",
    "propInfo": "Skidmark",
    "propWeightG": 702.3,
    "samples": [
      [ 0, 0 ], [ 0.029, 389.7 ], [ 0.123, 360.2 ], [ 0.218, 334.2 ], [ 0.376, 326.1 ], [ 0.534, 334.2 ],
      [ 0.692, 341.7 ], [ 0.85, 347.7 ], [ 1.007, 359.4 ], [ 1.165, 370 ], [ 1.323, 383.3 ],
      [ 1.481, 399.2 ], [ 1.639, 417.5 ], [ 1.797, 443.7 ], [ 1.955, 472.7 ], [ 2.112, 501.7 ],
      [ 2.27, 497.1 ], [ 2.428, 425.4 ], [ 2.586, 349 ], [ 2.744, 262.1 ], [ 2.902, 107.1 ],
      [ 3.06, 41.82 ], [ 3.157, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1125,
    "totalWeightG": 1459,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 365.6,
    "burnTimeS": 2.82,
    "caseInfo": "AMW 54-1050",
    "certOrg": "National Association of Rocketry",
    "commonName": "J370",
    "dataFiles": 2,
    "designation": "GG-54-1050",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/J370.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 458.5,
    "motorId": "5f4294d20002310000000114",
    "propInfo": "Green Gorilla",
    "propWeightG": 598.3,
    "samples": [
      [ 0, 0 ], [ 0.008, 185.5 ], [ 0.024, 149.5 ], [ 0.063, 225.3 ], [ 0.087, 272.6 ], [ 0.122, 304.8 ],
      [ 0.158, 304.8 ], [ 0.273, 335.2 ], [ 0.431, 363.8 ], [ 0.573, 390.4 ], [ 0.707, 413.2 ],
      [ 0.877, 428.5 ], [ 1.019, 441.9 ], [ 1.126, 441.9 ], [ 1.224, 458.5 ], [ 1.284, 444 ],
      [ 1.386, 440.2 ], [ 1.572, 438.5 ], [ 1.651, 438.6 ], [ 1.813, 417.8 ], [ 2.022, 404.7 ],
      [ 2.141, 385.9 ], [ 2.212, 385.9 ], [ 2.255, 374.6 ], [ 2.299, 387.9 ], [ 2.362, 357.6 ],
      [ 2.401, 384.2 ], [ 2.421, 348.2 ], [ 2.457, 316.1 ], [ 2.559, 251.8 ], [ 2.697, 115.6 ],
      [ 2.753, 45.62 ], [ 2.82, 0 ]
],
    "totImpulseNs": 1031,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 382,
    "burnTimeS": 2.73,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J380",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1043J380-16A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 434.6,
    "motorId": "5f4294d20002310000000115",
    "propInfo": "Smoky Sam",
    "propWeightG": 769,
    "samples": [
      [ 0, 0 ], [ 0.05, 368.5 ], [ 0.3, 348.3 ], [ 0.6, 378.8 ], [ 0.9, 400.9 ], [ 1.2, 419.5 ],
      [ 1.5, 433.1 ], [ 1.8, 434.6 ], [ 2.1, 408.8 ], [ 2.4, 369.9 ], [ 2.56, 410.6 ], [ 2.59, 297.8 ],
      [ 2.71, 45.25 ], [ 2.73, 0 ]
],
    "totImpulseNs": 1043,
    "totalWeightG": 1293,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 390,
    "burnTimeS": 3.28,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J390",
    "dataFiles": 2,
    "designation": "J390H-TURBO",
    "diameter": 54,
    "impulseClass": "J",
    "length": 709,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000116",
    "propWeightG": 391,
    "samples": [
      [ 0, 0 ], [ 0.01546, 440.4 ], [ 0.1005, 550.5 ], [ 0.1932, 546.1 ], [ 0.3014, 656.2 ],
      [ 0.5023, 647.4 ], [ 0.9737, 581.4 ], [ 1.484, 471.2 ], [ 1.986, 378.8 ], [ 2.179, 334.7 ],
      [ 2.303, 255.4 ], [ 2.496, 158.6 ], [ 3.014, 57.25 ], [ 3.5, 0 ]
],
    "totImpulseNs": 1280,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "OOP",
    "avgThrustN": 400,
    "burnTimeS": 2.6,
    "caseInfo": "AMW 54-1050",
    "certOrg": "National Association of Rocketry",
    "commonName": "J400",
    "dataFiles": 2,
    "designation": "RR-54-1050",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/J400.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000117",
    "propInfo": "Red Rhino",
    "propWeightG": 558,
    "samples": [
      [ 0, 0 ], [ 0.043, 246.6 ], [ 0.06, 317.4 ], [ 0.081, 344.7 ], [ 0.107, 358.4 ], [ 0.15, 356.1 ],
      [ 0.201, 365.2 ], [ 0.308, 392.5 ], [ 0.568, 435.7 ], [ 0.863, 458.3 ], [ 1.094, 469.2 ],
      [ 1.209, 467.2 ], [ 1.466, 460.1 ], [ 1.705, 444 ], [ 1.923, 423.3 ], [ 2.132, 409.4 ],
      [ 2.303, 413.8 ], [ 2.402, 420.6 ], [ 2.47, 413.8 ], [ 2.517, 395.4 ], [ 2.543, 347.4 ],
      [ 2.568, 265.2 ], [ 2.598, 128.2 ], [ 2.615, 68.8 ], [ 2.632, 25.4 ], [ 2.66, 0 ]
],
    "totImpulseNs": 1100,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 402.1,
    "burnTimeS": 1.75,
    "caseInfo": "Pro38-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J400",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "700J400-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 419,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 482.1,
    "motorId": "5f4294d20002310000000118",
    "propInfo": "Smoky Sam",
    "propWeightG": 392,
    "samples": [
      [ 0, 0 ], [ 0.05, 451.8 ], [ 0.2, 461.1 ], [ 0.31, 465.8 ], [ 0.44, 463.5 ], [ 0.6, 477.5 ],
      [ 0.8, 482.1 ], [ 1, 461.3 ], [ 1.2, 433.1 ], [ 1.35, 402.8 ], [ 1.4, 382.9 ], [ 1.47, 321 ],
      [ 1.55, 258 ], [ 1.6, 178.6 ], [ 1.73, 14.58 ], [ 1.75, 0 ]
],
    "totImpulseNs": 700,
    "totalWeightG": 702,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 405,
    "burnTimeS": 1.93,
    "caseInfo": "38-800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J405",
    "dataFiles": 2,
    "designation": "J405S",
    "diameter": 38,
    "impulseClass": "J",
    "length": 476,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 518,
    "motorId": "5f4294d20002310000000119",
    "propWeightG": 367,
    "samples": [
      [ 0, 0 ], [ 0.009, 528.5 ], [ 0.024, 488.9 ], [ 0.046, 462.4 ], [ 0.136, 462.4 ], [ 0.268, 458 ],
      [ 0.986, 453.6 ], [ 1.421, 444.8 ], [ 1.523, 255.4 ], [ 1.697, 92.49 ], [ 1.93, 0 ]
],
    "totImpulseNs": 722,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 415,
    "burnTimeS": 2.88,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J415",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J415W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 314,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 732.8,
    "motorId": "5f4294d2000231000000011a",
    "propInfo": "White Lightning",
    "propWeightG": 693.3,
    "samples": [
      [ 0, 0 ], [ 0.065, 431.3 ], [ 0.196, 452.4 ], [ 0.327, 489.9 ], [ 0.458, 513.5 ], [ 0.591, 523.2 ],
      [ 0.723, 531.4 ], [ 0.854, 542.2 ], [ 0.985, 542.7 ], [ 1.118, 549.8 ], [ 1.25, 553.9 ],
      [ 1.381, 537.3 ], [ 1.512, 512.1 ], [ 1.645, 517.3 ], [ 1.777, 498.1 ], [ 1.908, 473.4 ],
      [ 2.04, 444.2 ], [ 2.172, 413.2 ], [ 2.304, 384.9 ], [ 2.435, 360.6 ], [ 2.567, 297.6 ],
      [ 2.699, 178.3 ], [ 2.831, 89.89 ], [ 2.962, 43.07 ], [ 3.094, 19.13 ], [ 3.226, 8.995 ],
      [ 3.358, 0 ]
],
    "totImpulseNs": 1232,
    "totalWeightG": 1168,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 411,
    "burnTimeS": 2.75,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J416",
    "dataFiles": 2,
    "designation": "J416-SP",
    "diameter": 54,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1038,
    "motorId": "5f4294d2000231000000011b",
    "samples": [
      [ 0, 0 ], [ 0.001, 787.4 ], [ 0.07727, 777.2 ], [ 0.2129, 572.6 ], [ 0.4332, 531.7 ],
      [ 0.8654, 511.3 ], [ 1.264, 480.6 ], [ 1.823, 470.4 ], [ 2.009, 347.7 ], [ 2.137, 276.1 ],
      [ 2.247, 184.1 ], [ 2.408, 81.81 ], [ 2.751, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1130,
    "totalWeightG": 1700,
    "type": "hybrid",
    "updatedOn": "2019-04-18"
  },
  {
    "availability": "regular",
    "avgThrustN": 420,
    "burnTimeS": 1.567,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J420",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "J420R",
    "diameter": 38,
    "impulseClass": "J",
    "length": 337,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d2000231000000011c",
    "propInfo": "Redline",
    "propWeightG": 345,
    "samples": [
      [ 0, 0 ], [ 0.031, 61.08 ], [ 0.095, 563.5 ], [ 0.16, 525.3 ], [ 0.224, 521.2 ], [ 0.288, 527.4 ],
      [ 0.352, 537.1 ], [ 0.418, 535.1 ], [ 0.481, 534.6 ], [ 0.545, 530.2 ], [ 0.61, 526.4 ],
      [ 0.674, 517.2 ], [ 0.738, 510.3 ], [ 0.802, 500.9 ], [ 0.868, 479.4 ], [ 0.931, 460.7 ],
      [ 0.995, 438.6 ], [ 1.06, 409.6 ], [ 1.124, 383.5 ], [ 1.188, 361 ], [ 1.252, 339.7 ],
      [ 1.318, 319.2 ], [ 1.381, 296.7 ], [ 1.445, 195.2 ], [ 1.51, 61.98 ], [ 1.575, 7.22 ],
      [ 1.64, 0 ]
],
    "totImpulseNs": 658,
    "totalWeightG": 649.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 440,
    "burnTimeS": 1.5,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "J440",
    "dataFiles": 2,
    "designation": "BB-38-640",
    "diameter": 38,
    "impulseClass": "J",
    "length": 370,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000011d",
    "propInfo": "Blue Baboon",
    "propWeightG": 313.1,
    "samples": [
      [ 0, 0 ], [ 0.007, 468.5 ], [ 0.022, 510 ], [ 0.037, 527.7 ], [ 0.052, 532.8 ], [ 0.082, 530.2 ],
      [ 0.127, 525.6 ], [ 0.202, 521.6 ], [ 0.277, 519.8 ], [ 0.352, 521.5 ], [ 0.426, 525.4 ],
      [ 0.501, 531.2 ], [ 0.576, 538.7 ], [ 0.651, 541.8 ], [ 0.726, 538.5 ], [ 0.801, 531.1 ],
      [ 0.876, 516.2 ], [ 0.95, 494.9 ], [ 1.025, 477.3 ], [ 1.1, 433.3 ], [ 1.175, 313.9 ],
      [ 1.25, 187.5 ], [ 1.325, 101.5 ], [ 1.4, 45.75 ], [ 1.474, 22.08 ], [ 1.497, 0 ]
],
    "totImpulseNs": 653,
    "totalWeightG": 698.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 459.1,
    "burnTimeS": 2.33,
    "caseInfo": "AMW 54-1050",
    "certOrg": "National Association of Rocketry",
    "commonName": "J450",
    "dataFiles": 3,
    "designation": "ST-54-1050",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/J450.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 563.2,
    "motorId": "5f4294d2000231000000011e",
    "propInfo": "Super Tiger",
    "propWeightG": 533.1,
    "samples": [
      [ 0, 0 ], [ 0.009, 251.6 ], [ 0.016, 376.1 ], [ 0.03, 413.4 ], [ 0.051, 430.8 ], [ 0.094, 423.3 ],
      [ 0.162, 413.1 ], [ 0.262, 395.6 ], [ 0.402, 420.2 ], [ 0.495, 444.9 ], [ 0.805, 504.1 ],
      [ 1.048, 536 ], [ 1.223, 550.6 ], [ 1.299, 563.2 ], [ 1.334, 555.3 ], [ 1.47, 560 ],
      [ 1.588, 559.8 ], [ 1.764, 547 ], [ 1.921, 516.8 ], [ 1.993, 496.7 ], [ 2.025, 499.2 ],
      [ 2.047, 479.2 ], [ 2.086, 414.4 ], [ 2.115, 344.5 ], [ 2.141, 252.3 ], [ 2.177, 140.2 ],
      [ 2.213, 82.78 ], [ 2.239, 50.35 ], [ 2.271, 27.86 ], [ 2.296, 12.86 ], [ 2.33, 0 ]
],
    "totImpulseNs": 1070,
    "totalWeightG": 1196,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 460,
    "burnTimeS": 1.81,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J460",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J460T",
    "diameter": 54,
    "impulseClass": "J",
    "length": 230,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 870.5,
    "motorId": "5f4294d2000231000000011f",
    "propInfo": "Blue Thunder",
    "propWeightG": 415.4,
    "samples": [
      [ 0, 0 ], [ 0.041, 500.9 ], [ 0.125, 509.4 ], [ 0.209, 516.4 ], [ 0.294, 527.8 ], [ 0.379, 535.1 ],
      [ 0.464, 541.9 ], [ 0.548, 545.8 ], [ 0.633, 545.7 ], [ 0.718, 544.8 ], [ 0.802, 540.3 ],
      [ 0.887, 533.7 ], [ 0.972, 526.3 ], [ 1.056, 511 ], [ 1.141, 492.5 ], [ 1.225, 475 ],
      [ 1.31, 457 ], [ 1.395, 437.2 ], [ 1.479, 418.1 ], [ 1.565, 403.2 ], [ 1.649, 339.2 ],
      [ 1.733, 203.9 ], [ 1.819, 102.6 ], [ 1.903, 49.3 ], [ 1.987, 9.538 ], [ 2.073, 2.155 ],
      [ 2.158, 0 ]
],
    "totImpulseNs": 805.5,
    "totalWeightG": 789,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 480,
    "burnTimeS": 2.4,
    "caseInfo": "AMW 54-1050",
    "certOrg": "National Association of Rocketry",
    "commonName": "J480",
    "dataFiles": 2,
    "designation": "BB-54-1050",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/J480.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000120",
    "propInfo": "Blue Baboon",
    "propWeightG": 556,
    "samples": [
      [ 0, 0 ], [ 0.015, 225.4 ], [ 0.041, 348.2 ], [ 0.071, 388.1 ], [ 0.194, 422.5 ], [ 0.385, 459.5 ],
      [ 0.699, 502.3 ], [ 0.968, 528 ], [ 1.2, 536.6 ], [ 1.454, 543.1 ], [ 1.674, 533.8 ],
      [ 1.887, 522.3 ], [ 2.044, 519.4 ], [ 2.108, 525.1 ], [ 2.164, 528 ], [ 2.197, 488.1 ],
      [ 2.25, 419.5 ], [ 2.283, 333.9 ], [ 2.328, 231.2 ], [ 2.354, 176.9 ], [ 2.392, 111.3 ],
      [ 2.418, 68.5 ], [ 2.436, 37.11 ], [ 2.49, 0 ]
],
    "totImpulseNs": 1165,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 500,
    "burnTimeS": 1.3,
    "caseInfo": "AMW 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J500",
    "dataFiles": 2,
    "designation": "J500ST",
    "diameter": 38,
    "impulseClass": "J",
    "length": 370,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 779,
    "motorId": "5f4294d20002310000000121",
    "propWeightG": 326.5,
    "samples": [
      [ 0, 0 ], [ 0.006, 444.8 ], [ 0.025, 475.7 ], [ 0.04, 418.4 ], [ 0.053, 466.8 ], [ 0.059, 409.6 ],
      [ 0.071, 458 ], [ 0.077, 409.6 ], [ 0.1, 444.8 ], [ 0.127, 506.5 ], [ 0.204, 590.2 ],
      [ 0.25, 645 ], [ 0.3, 678.2 ], [ 0.34, 709.1 ], [ 0.402, 735.5 ], [ 0.445, 766.3 ],[ 0.516, 783.9 ],
      [ 0.6, 787.3 ], [ 0.637, 770.7 ], [ 0.68, 744.3 ], [ 0.76, 621 ], [ 0.859, 475.7 ],[ 1.005, 303.9 ],
      [ 1.122, 171.8 ], [ 1.227, 52.85 ], [ 1.3, 0 ]
],
    "totImpulseNs": 660,
    "type": "reload",
    "updatedOn": "2024-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 525,
    "burnTimeS": 2.1,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J525",
    "dataFiles": 2,
    "designation": "J525-LW",
    "diameter": 54,
    "impulseClass": "J",
    "length": 330,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 640.8,
    "motorId": "5f4294d20002310000000122",
    "propInfo": "Loki White",
    "propWeightG": 625,
    "samples": [
      [ 0, 0 ], [ 0.01, 210.9 ], [ 0.03, 499.3 ], [ 0.05, 628.5 ], [ 0.1, 594 ], [ 0.13, 568.2 ],
      [ 0.15, 559.6 ], [ 0.2, 555.3 ], [ 0.3, 572.5 ], [ 0.4, 589.7 ], [ 0.5, 606.9 ], [ 0.6, 624.2 ],
      [ 0.7, 637.1 ], [ 0.8, 645.7 ], [ 0.9, 650 ], [ 1, 658.6 ], [ 1.1, 637.1 ], [ 1.2, 628.5 ],
      [ 1.3, 615.5 ], [ 1.41, 586.3 ], [ 1.52, 561.5 ], [ 1.67, 536.8 ], [ 1.78, 517.7 ],[ 1.85, 485.4 ],
      [ 1.92, 91.37 ], [ 2, 0 ]
],
    "totImpulseNs": 1113,
    "totalWeightG": 1240,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 528,
    "burnTimeS": 1.2,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J528",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "J528-LW",
    "diameter": 38,
    "impulseClass": "J",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20White.pdf",
    "length": 419,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 896,
    "motorId": "5f4294d20002310000000123",
    "propInfo": "Loki White",
    "propWeightG": 374,
    "samples": [
      [ 0, 0 ], [ 0.01, 704.2 ], [ 0.02, 1019 ], [ 0.03, 983.9 ], [ 0.05, 881.1 ], [ 0.1, 797.5 ],
      [ 0.15, 771.7 ], [ 0.17, 765.7 ], [ 0.21, 765.7 ], [ 0.25, 778.2 ], [ 0.42, 789.3 ],
      [ 0.51, 771.6 ], [ 0.6, 756.9 ], [ 0.66, 751 ], [ 0.71, 762.8 ], [ 0.76, 698 ], [ 0.8, 665.6 ],
      [ 0.84, 612.6 ], [ 0.92, 488.9 ], [ 0.95, 385.8 ], [ 1.02, 282.7 ], [ 1.06, 179.7 ],
      [ 1.14, 53.01 ], [ 1.19, 35.34 ], [ 1.23, 32.2 ], [ 1.25, 0 ]
],
    "totImpulseNs": 741,
    "totalWeightG": 745,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 540,
    "burnTimeS": 2.15,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J540",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J540R",
    "diameter": 54,
    "impulseClass": "J",
    "length": 314,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000124",
    "propInfo": "Redline",
    "propWeightG": 679,
    "samples": [
      [ 0, 0 ], [ 0.044, 498.8 ], [ 0.134, 639.6 ], [ 0.224, 649.3 ], [ 0.314, 658 ], [ 0.404, 664 ],
      [ 0.494, 666.9 ], [ 0.584, 663.7 ], [ 0.675, 658.4 ], [ 0.765, 651.2 ], [ 0.855, 638.5 ],
      [ 0.945, 626.4 ], [ 1.035, 612.6 ], [ 1.126, 590.1 ], [ 1.216, 562.4 ], [ 1.306, 536.9 ],
      [ 1.396, 511.6 ], [ 1.486, 490.4 ], [ 1.576, 469 ], [ 1.667, 451.3 ], [ 1.758, 430.2 ],
      [ 1.847, 414.5 ], [ 1.937, 398.1 ], [ 2.027, 305.9 ], [ 2.118, 55.54 ], [ 2.208, 1.523 ],
      [ 2.299, 0 ]
],
    "totImpulseNs": 1161,
    "totalWeightG": 1084,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 497,
    "burnTimeS": 1.6,
    "caseInfo": "38 48 XF HP (735CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J555",
    "dataFiles": 2,
    "designation": "J555-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 2500,
    "motorId": "5f4294d20002310000000125",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.001, 931 ], [ 0.05917, 1345 ], [ 0.2784, 810.3 ], [ 1.173, 241.4 ], [ 1.278, 68.96 ],
      [ 1.319, 51.72 ], [ 1.601, 0 ]
],
    "totImpulseNs": 795,
    "totalWeightG": 1132,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 509.3,
    "burnTimeS": 1.91,
    "caseInfo": "RMS-38/1080",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J570",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "J570W",
    "diameter": 38,
    "impulseClass": "J",
    "length": 479,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1143,
    "motorId": "5f4294d20002310000000126",
    "propInfo": "White Lightning",
    "propWeightG": 535.8,
    "samples": [
      [ 0, 0 ], [ 0.039, 1150 ], [ 0.119, 1043 ], [ 0.199, 960.9 ], [ 0.279, 900 ], [ 0.36, 837.8 ],
      [ 0.441, 792.8 ], [ 0.521, 735.5 ], [ 0.602, 685.9 ], [ 0.682, 649.6 ], [ 0.762, 608.8 ],
      [ 0.844, 597.4 ], [ 0.924, 568.9 ], [ 1.004, 548.6 ], [ 1.084, 505.1 ], [ 1.165, 484.6 ],
      [ 1.246, 452.3 ], [ 1.326, 362.4 ], [ 1.406, 298 ], [ 1.487, 262.4 ], [ 1.568, 195.7 ],
      [ 1.648, 156.7 ], [ 1.729, 124.6 ], [ 1.809, 113.7 ], [ 1.89, 69.81 ], [ 1.971, 46.02 ],
      [ 2.052, 0 ]
],
    "totImpulseNs": 973.1,
    "totalWeightG": 902,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 605,
    "burnTimeS": 1.3,
    "caseInfo": "38-800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J605",
    "dataFiles": 2,
    "designation": "J605F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 476,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 882,
    "motorId": "5f4294d20002310000000127",
    "propWeightG": 367,
    "samples": [
      [ 0, 0 ], [ 0.024, 886.3 ], [ 0.037, 704.7 ], [ 0.077, 660.6 ], [ 0.438, 704.7 ], [ 0.506, 715.7 ],
      [ 0.59, 710.2 ], [ 0.853, 655.1 ], [ 0.973, 594.6 ], [ 1.041, 412.9 ], [ 1.091, 324.8 ],
      [ 1.177, 132.1 ], [ 1.3, 0 ]
],
    "totImpulseNs": 737,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 633.7,
    "burnTimeS": 1.72,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J642",
    "dataFiles": 2,
    "designation": "J642-BG",
    "diameter": 54,
    "impulseClass": "J",
    "length": 922,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1494,
    "motorId": "5f4294d20002310000000128",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.00678, 1483 ], [ 0.07797, 997 ], [ 0.4712, 1304 ], [ 0.5424, 818.1 ],[ 0.6339, 741.4 ],
      [ 0.7424, 588 ], [ 1.251, 485.7 ], [ 1.298, 332.3 ], [ 1.397, 179 ], [ 1.475, 51.13 ],
      [ 1.72, 0 ]
],
    "totImpulseNs": 1093,
    "totalWeightG": 1791,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 696.5,
    "burnTimeS": 1.76,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J800",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J800T",
    "diameter": 54,
    "impulseClass": "J",
    "length": 316,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1001,
    "motorId": "5f4294d20002310000000129",
    "propInfo": "Blue Thunder",
    "propWeightG": 618,
    "samples": [
      [ 0, 0 ], [ 0.04, 841.3 ], [ 0.121, 818.5 ], [ 0.203, 776.4 ], [ 0.285, 784.3 ], [ 0.367, 785.3 ],
      [ 0.449, 783.3 ], [ 0.531, 782.5 ], [ 0.612, 780 ], [ 0.695, 773.7 ], [ 0.777, 765.3 ],
      [ 0.858, 755.5 ], [ 0.941, 744.8 ], [ 1.023, 733.1 ], [ 1.105, 719.9 ], [ 1.187, 702.2 ],
      [ 1.269, 685.4 ], [ 1.351, 668.3 ], [ 1.433, 650.3 ], [ 1.515, 630.5 ], [ 1.597, 615.5 ],
      [ 1.679, 470.3 ], [ 1.76, 256.6 ], [ 1.843, 108.7 ], [ 1.925, 15.01 ], [ 2.007, 1.249 ],
      [ 2.09, 0 ]
],
    "totImpulseNs": 1229,
    "totalWeightG": 1086,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 758,
    "burnTimeS": 1.2,
    "caseInfo": "38 48 XXF HP(685CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J800",
    "dataFiles": 2,
    "designation": "J800-HP",
    "diameter": 38,
    "impulseClass": "J",
    "length": 1227,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 4840,
    "motorId": "5f4294d2000231000000012a",
    "propWeightG": 166,
    "samples": [
      [ 0, 0 ], [ 0.002237, 1831 ], [ 0.5235, 889.6 ], [ 0.6398, 650.1 ], [ 0.7405, 444.8 ],
      [ 0.8233, 273.7 ], [ 0.906, 154 ], [ 0.9978, 136.9 ], [ 1.2, 0 ]
],
    "totImpulseNs": 928,
    "totalWeightG": 1148,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 185,
    "burnTimeS": 6.87,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K185",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K185W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 437,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 404.7,
    "motorId": "5f4294d2000231000000012d",
    "propInfo": "White Lightning",
    "propWeightG": 836.8,
    "samples": [
      [ 0, 0 ], [ 0.15, 279.1 ], [ 0.452, 308.2 ], [ 0.754, 328.4 ], [ 1.056, 338.9 ], [ 1.359, 339.7 ],
      [ 1.663, 333.2 ], [ 1.965, 321.9 ], [ 2.267, 309.7 ], [ 2.57, 293.3 ], [ 2.873, 271.5 ],
      [ 3.175, 247.2 ], [ 3.477, 216.9 ], [ 3.78, 187 ], [ 4.083, 161.1 ], [ 4.385, 138.1 ],
      [ 4.688, 117.7 ], [ 4.991, 99.37 ], [ 5.294, 82.76 ], [ 5.596, 68.43 ], [ 5.898, 55.13 ],
      [ 6.201, 44.16 ], [ 6.504, 34.21 ], [ 6.806, 25.06 ], [ 7.108, 16.88 ], [ 7.411, 9.2 ],
      [ 7.715, 0 ]
],
    "totImpulseNs": 1417,
    "totalWeightG": 1434,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 235,
    "burnTimeS": 7.04,
    "caseInfo": "54 48 S BG",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K234",
    "dataFiles": 2,
    "designation": "K234-BG",
    "diameter": 54,
    "impulseClass": "K",
    "length": 1263,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 477.5,
    "motorId": "5f4294d2000231000000012e",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.001, 92.39 ], [ 0.2359, 396.9 ], [ 0.9742, 338.7 ], [ 5.974, 171.1 ],[ 6.052, 106.1 ],
      [ 6.198, 78.7 ], [ 6.377, 54.75 ], [ 7.051, 0 ]
],
    "totImpulseNs": 1657,
    "totalWeightG": 2063,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 240,
    "burnTimeS": 5.38,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K240",
    "dataFiles": 2,
    "designation": "835CC125J-K240",
    "diameter": 54,
    "impulseClass": "K",
    "length": 815,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000012f",
    "propWeightG": 799.2,
    "samples": [
      [ 0, 0 ], [ 0.131, 278 ], [ 0.396, 329.6 ], [ 0.66, 338 ], [ 0.925, 334.1 ], [ 1.191, 326.2 ],
      [ 1.456, 319.7 ], [ 1.721, 315.2 ], [ 1.985, 311.2 ], [ 2.25, 302.9 ], [ 2.516, 305.9 ],
      [ 2.781, 290 ], [ 3.046, 281.8 ], [ 3.31, 273.3 ], [ 3.575, 268.9 ], [ 3.841, 255.7 ],
      [ 4.106, 251.1 ], [ 4.371, 234.8 ], [ 4.635, 160 ], [ 4.9, 96.54 ], [ 5.166, 73.37 ],
      [ 5.431, 55.48 ], [ 5.696, 40.93 ], [ 5.96, 29.54 ], [ 6.225, 21.25 ], [ 6.491, 14.79 ],
      [ 6.756, 0 ]
],
    "totImpulseNs": 1292,
    "totalWeightG": 1807,
    "type": "hybrid",
    "updatedOn": "2024-02-15"
  },
  {
    "availability": "regular",
    "avgThrustN": 240,
    "burnTimeS": 7.686,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K240",
    "dataFiles": 1,
    "designation": "K240H",
    "diameter": 64,
    "impulseClass": "K",
    "length": 908,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "motorId": "5f4294d20002310000000130",
    "propWeightG": 1292,
    "samples": [
      [ 0, 0 ], [ 0.164, 414 ], [ 0.493, 393 ], [ 0.822, 370.8 ], [ 1.151, 362.1 ], [ 1.48, 337.8 ],
      [ 1.809, 321.4 ], [ 2.139, 285.1 ], [ 2.468, 280 ], [ 2.798, 275.1 ], [ 3.128, 278.4 ],
      [ 3.457, 274.4 ], [ 3.786, 252.3 ], [ 4.116, 272.9 ], [ 4.445, 264.7 ], [ 4.774, 255.6 ],
      [ 5.103, 210.3 ], [ 5.433, 164 ], [ 5.764, 126.2 ], [ 6.093, 98.02 ], [ 6.422, 74.49 ],
      [ 6.751, 55.62 ], [ 7.08, 40.78 ], [ 7.409, 29.62 ], [ 7.739, 21.91 ], [ 8.069, 17.21 ],
      [ 8.399, 0 ]
],
    "totImpulseNs": 1845,
    "totalWeightG": 2814,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 6.48,
    "caseInfo": "54/2000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K250",
    "dataFiles": 2,
    "designation": "K250-LW",
    "diameter": 54,
    "impulseClass": "K",
    "length": 499,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 728.3,
    "motorId": "5f4294d20002310000000131",
    "propInfo": "Loki White",
    "propWeightG": 975,
    "samples": [
      [ 0, 0 ], [ 0.03, 800 ], [ 0.1, 682 ], [ 0.125, 574 ], [ 0.15, 476 ], [ 0.175, 447 ],[ 0.25, 385 ],
      [ 0.45, 340 ], [ 0.6, 320 ], [ 1, 313 ], [ 1.5, 300 ], [ 2, 297 ], [ 2.5, 303 ], [ 3, 294 ],
      [ 3.5, 287 ], [ 4, 248 ], [ 4.5, 222 ], [ 5, 187 ], [ 5.5, 147 ], [ 6, 114 ], [ 6.5, 62 ],
      [ 7, 0 ]
],
    "totImpulseNs": 1608,
    "totalWeightG": 1791,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 9,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K250",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K250W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 673,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 545.8,
    "motorId": "5f4294d20002310000000132",
    "propInfo": "White Lightning",
    "propWeightG": 1434,
    "samples": [
      [ 0, 0 ], [ 0.199, 365.3 ], [ 0.599, 403.3 ], [ 0.999, 418.7 ], [ 1.4, 409.8 ], [ 1.801, 408.9 ],
      [ 2.201, 412.1 ], [ 2.602, 412 ], [ 3.003, 409.5 ], [ 3.403, 393.2 ], [ 3.804, 373.6 ],
      [ 4.205, 348.9 ], [ 4.605, 328.5 ], [ 5.006, 307.2 ], [ 5.407, 281.5 ], [ 5.807, 249 ],
      [ 6.208, 217.2 ], [ 6.609, 185.9 ], [ 7.009, 149.2 ], [ 7.41, 119.8 ], [ 7.811, 92.1 ],
      [ 8.211, 69.73 ], [ 8.613, 52.61 ], [ 9.014, 35.88 ], [ 9.414, 16.73 ], [ 9.815, 4.086 ],
      [ 10.22, 0 ]
],
    "totImpulseNs": 2353,
    "totalWeightG": 2211,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 268.9,
    "burnTimeS": 6.22,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K265",
    "dataFiles": 2,
    "designation": "K265-SP",
    "diameter": 54,
    "impulseClass": "K",
    "length": 1219,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 777.1,
    "motorId": "5f4294d20002310000000134",
    "samples": [
      [ 0, 0 ], [ 0.001, 470.4 ], [ 2.442, 347.7 ], [ 3.916, 225 ], [ 4.781, 173.8 ], [ 5.137, 112.5 ],
      [ 5.34, 51.13 ], [ 6.261, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1684,
    "totalWeightG": 1814,
    "type": "hybrid",
    "updatedOn": "2019-04-18"
  },
  {
    "availability": "regular",
    "avgThrustN": 293,
    "burnTimeS": 6.5,
    "caseInfo": "75 40 S BS (2050CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K300",
    "dataFiles": 2,
    "designation": "K300-BS",
    "diameter": 75,
    "impulseClass": "K",
    "length": 1016,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 690,
    "motorId": "5f4294d20002310000000135",
    "propWeightG": 580,
    "samples": [
      [ 0, 0 ], [ 0.001, 431.1 ], [ 0.3254, 526.9 ], [ 0.9853, 479 ], [ 1.175, 369.5 ], [ 5.001, 280.6 ],
      [ 5.191, 171.1 ], [ 5.359, 102.7 ], [ 5.627, 54.75 ], [ 5.795, 27.37 ], [ 6.501, 0 ]
],
    "totImpulseNs": 1909,
    "totalWeightG": 4059,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 320.5,
    "burnTimeS": 4.89,
    "caseInfo": "54 48 M BG (1490CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K321",
    "dataFiles": 2,
    "designation": "K321-BG",
    "diameter": 54,
    "impulseClass": "K",
    "length": 1220,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 738,
    "motorId": "5f4294d20002310000000136",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.005593, 219 ], [ 0.2181, 410.6 ], [ 0.9732, 718.6 ], [ 0.9899, 732.2 ],
      [ 1.057, 444.8 ], [ 1.488, 403.8 ], [ 3.971, 232.7 ], [ 4.116, 88.96 ], [ 4.234, 54.75 ],
      [ 4.346, 54.75 ], [ 4.9, 0 ]
],
    "totImpulseNs": 1570,
    "totalWeightG": 2043,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 350,
    "burnTimeS": 6.36,
    "caseInfo": "54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K350",
    "dataFiles": 2,
    "designation": "K350-LW",
    "diameter": 54,
    "impulseClass": "K",
    "length": 736,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1329,
    "motorId": "5f4294d20002310000000137",
    "propInfo": "Loki White",
    "propWeightG": 1406,
    "samples": [
      [ 0, 0 ], [ 0.025, 1329 ], [ 0.0375, 1061 ], [ 0.1, 1006 ], [ 0.15, 891 ], [ 0.2, 768 ],
      [ 0.4, 571 ], [ 0.5, 542 ], [ 0.75, 486 ], [ 1, 486 ], [ 1.25, 477 ], [ 1.5, 481 ], [ 2.506, 460 ],
      [ 3.005, 427 ], [ 3.5, 375 ], [ 4, 333 ], [ 4.5, 297 ], [ 5, 249 ], [ 5.5, 210 ], [ 6, 164 ],
      [ 6.5, 98 ], [ 7, 0 ]
],
    "totImpulseNs": 2527,
    "totalWeightG": 2540,
    "type": "reload",
    "updatedOn": "2020-09-03"
  },
  {
    "availability": "OOP",
    "avgThrustN": 365,
    "burnTimeS": 4.55,
    "caseInfo": "AMW 75-1700",
    "certOrg": "National Association of Rocketry",
    "commonName": "K365",
    "dataFiles": 2,
    "designation": "RR-75-1700",
    "diameter": 75,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K365.pdf",
    "length": 244,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000138",
    "propInfo": "Red Rhino",
    "propWeightG": 946,
    "samples": [
      [ 0, 0 ], [ 0.049, 138.2 ], [ 0.068, 381.2 ], [ 0.084, 454.8 ], [ 0.106, 481.5 ], [ 0.164, 488.2 ],
      [ 0.291, 514.9 ], [ 0.435, 546 ], [ 0.666, 561.5 ], [ 0.868, 565.7 ], [ 1.082, 565.5 ],
      [ 1.296, 550.1 ], [ 1.591, 529.9 ], [ 1.805, 509.7 ], [ 1.828, 536.5 ], [ 1.886, 498.6 ],
      [ 2.124, 467.2 ], [ 2.501, 411.4 ], [ 2.924, 328.7 ], [ 3.296, 241.6 ], [ 3.638, 172.3 ],
      [ 3.969, 100.8 ], [ 4.195, 56.1 ], [ 4.265, 51.61 ], [ 4.346, 35.96 ], [ 4.433, 15.86 ],
      [ 4.51, 0 ]
],
    "totImpulseNs": 1680,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 400,
    "burnTimeS": 3.5,
    "caseInfo": "54X403(1400)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K400",
    "dataFiles": 2,
    "delays": "S,M,L",
    "designation": "K400S",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 578.2,
    "motorId": "5f4294d20002310000000139",
    "propWeightG": 721.8,
    "samples": [
      [ 0, 0 ], [ 0.074, 465.9 ], [ 0.225, 441.9 ], [ 0.377, 442.4 ], [ 0.529, 445.5 ], [ 0.681, 449 ],
      [ 0.833, 451.9 ], [ 0.985, 454.5 ], [ 1.138, 456.9 ], [ 1.29, 458.2 ], [ 1.442, 457 ],
      [ 1.594, 455.6 ], [ 1.746, 451.8 ], [ 1.897, 446.4 ], [ 2.048, 438.8 ], [ 2.2, 429.4 ],
      [ 2.352, 419 ], [ 2.504, 408.3 ], [ 2.656, 397.6 ], [ 2.808, 388 ], [ 2.96, 367.1 ],
      [ 3.113, 263.7 ], [ 3.265, 114.4 ], [ 3.417, 46.24 ], [ 3.569, 8.62 ], [ 3.721, 2.401 ],
      [ 3.873, 0 ]
],
    "totImpulseNs": 1386,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 379,
    "burnTimeS": 6.4,
    "caseInfo": "75 40 S SP (2050CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K404",
    "dataFiles": 2,
    "designation": "K404-SP",
    "diameter": 75,
    "impulseClass": "K",
    "length": 1016,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1036,
    "motorId": "5f4294d2000231000000013a",
    "propWeightG": 703,
    "samples": [
      [ 0, 0 ], [ 0.01119, 670.7 ], [ 4.631, 335.3 ], [ 4.81, 205.3 ], [ 4.922, 130 ], [ 5.078, 82.12 ],
      [ 5.268, 41.06 ], [ 6.4, 0 ]
],
    "totImpulseNs": 2441,
    "totalWeightG": 4150,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 445,
    "burnTimeS": 3.67,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K445",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1635K445-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 664.8,
    "motorId": "5f4294d2000231000000013b",
    "propInfo": "Classic",
    "propWeightG": 792,
    "samples": [
      [ 0, 0 ], [ 0.05, 664.8 ], [ 0.19, 640.7 ], [ 0.48, 623 ], [ 1, 576.3 ], [ 1.51, 515.1 ],
      [ 2, 442.7 ], [ 2.5, 392.3 ], [ 3.02, 350.9 ], [ 3.13, 339.7 ], [ 3.31, 210.9 ], [ 3.47, 78.88 ],
      [ 3.67, 0 ]
],
    "totImpulseNs": 1636,
    "totalWeightG": 1398,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 450,
    "burnTimeS": 4.07,
    "caseInfo": "AMW 75-1700",
    "certOrg": "National Association of Rocketry",
    "commonName": "K450",
    "dataFiles": 2,
    "designation": "BB-75-1700",
    "diameter": 75,
    "impulseClass": "K",
    "length": 244,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000013c",
    "propInfo": "Blue Baboon",
    "propWeightG": 881.6,
    "samples": [
      [ 0, 0 ], [ 0.03, 78.9 ], [ 0.045, 227.9 ], [ 0.064, 450 ], [ 0.069, 508.4 ], [ 0.094, 555.2 ],
      [ 0.151, 564 ], [ 0.362, 625.4 ], [ 0.562, 651.9 ], [ 0.825, 660.6 ], [ 1.134, 652.3 ],
      [ 1.453, 626.1 ], [ 1.793, 594.3 ], [ 2.113, 539 ], [ 2.458, 469.1 ], [ 2.798, 384.5 ],
      [ 3.165, 276.7 ], [ 3.201, 279.6 ], [ 3.325, 232.9 ], [ 3.51, 171.8 ], [ 3.732, 107.7 ],
      [ 3.861, 58.02 ], [ 3.959, 40.55 ], [ 4.036, 20.15 ], [ 4.11, 0 ]
],
    "totImpulseNs": 1845,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 439,
    "burnTimeS": 3.7,
    "caseInfo": "75 32 S BG (1400CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K456",
    "dataFiles": 2,
    "designation": "K456-BG",
    "diameter": 75,
    "impulseClass": "K",
    "length": 813,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1422,
    "motorId": "5f4294d2000231000000013d",
    "propWeightG": 580,
    "samples": [
      [ 0, 0 ], [ 0.005593, 681 ], [ 0.2125, 896.5 ], [ 0.5034, 775.9 ], [ 1.365, 577.6 ],
      [ 1.527, 525.9 ], [ 2.511, 370.7 ], [ 2.668, 129.3 ], [ 3.7, 0 ]
],
    "totImpulseNs": 1628,
    "totalWeightG": 3704,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 458,
    "burnTimeS": 6,
    "caseInfo": "RMS-98/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K458",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K458W",
    "diameter": 98,
    "impulseClass": "K",
    "length": 275,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 585.8,
    "motorId": "5f4294d2000231000000013e",
    "propInfo": "White Lightning",
    "propWeightG": 1425,
    "samples": [
      [ 0, 0 ], [ 0.133, 294.9 ], [ 0.403, 404.8 ], [ 0.674, 462 ], [ 0.944, 515.9 ], [ 1.214, 555.1 ],
      [ 1.484, 583.2 ], [ 1.755, 600.3 ], [ 2.025, 610.3 ], [ 2.295, 618.5 ], [ 2.566, 623.2 ],
      [ 2.835, 618.9 ], [ 3.105, 589.1 ], [ 3.376, 546.3 ], [ 3.647, 505 ], [ 3.917, 451.4 ],
      [ 4.186, 391.7 ], [ 4.457, 338.4 ], [ 4.727, 288.4 ], [ 4.997, 245.8 ], [ 5.268, 208.2 ],
      [ 5.539, 178.2 ], [ 5.808, 149.8 ], [ 6.078, 62.93 ], [ 6.349, 8.427 ], [ 6.62, 2.562 ],
      [ 6.891, 0 ]
],
    "totImpulseNs": 2465,
    "totalWeightG": 3164,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 470,
    "burnTimeS": 3.7,
    "caseInfo": "AMW 75-1700",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K470",
    "dataFiles": 2,
    "designation": "ST-75-1700",
    "diameter": 75,
    "impulseClass": "K",
    "length": 302,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 804,
    "motorId": "5f4294d2000231000000013f",
    "propInfo": "Super Tiger",
    "propWeightG": 826,
    "samples": [
      [ 0, 0 ], [ 0.028, 699.3 ], [ 0.039, 799.3 ], [ 0.09, 765.8 ], [ 0.157, 770.3 ], [ 0.258, 785.9 ],
      [ 0.41, 804 ], [ 0.572, 794.4 ], [ 0.707, 794.4 ], [ 0.886, 792.2 ], [ 0.998, 783.3 ],
      [ 1.15, 752 ], [ 1.318, 709.6 ], [ 1.447, 656 ], [ 1.593, 595.7 ], [ 1.728, 522 ], [ 1.885, 444.1 ],
      [ 2.092, 354.9 ], [ 2.356, 270.2 ], [ 2.664, 187.6 ], [ 2.945, 131.7 ], [ 3.27, 78.06 ],
      [ 3.433, 55.69 ], [ 3.478, 48.99 ], [ 3.556, 28.91 ], [ 3.7, 0 ]
],
    "totImpulseNs": 1679,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 475,
    "burnTimeS": 5.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K475",
    "dataFiles": 2,
    "designation": "K475",
    "diameter": 54,
    "impulseClass": "K",
    "length": 664,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 885.9,
    "motorId": "5f4294d20002310000000140",
    "propWeightG": 1133,
    "samples": [
      [ 0, 0 ], [ 0.04637, 797.2 ], [ 0.1546, 616.6 ], [ 0.2782, 585.8 ], [ 0.4791, 568.1 ],
      [ 2.921, 576.9 ], [ 3.292, 568.1 ], [ 4.003, 303.9 ], [ 4.513, 224.6 ], [ 5.023, 74.87 ],
      [ 5.5, 0 ]
],
    "totImpulseNs": 2435,
    "totalWeightG": 2168,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 474.1,
    "burnTimeS": 2.94,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "K475",
    "dataFiles": 2,
    "designation": "WT-54-1400",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K475.pdf",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 588.3,
    "motorId": "5f4294d20002310000000141",
    "propInfo": "White Wolf",
    "propWeightG": 728.6,
    "samples": [
      [ 0, 0 ], [ 0.022, 127.8 ], [ 0.041, 386 ], [ 0.063, 548.3 ], [ 0.096, 521.3 ], [ 0.134, 499.1 ],
      [ 0.18, 486.8 ], [ 0.285, 486.8 ], [ 0.478, 501.6 ], [ 0.731, 523.7 ], [ 1.096, 553.3 ],
      [ 1.433, 578 ], [ 1.601, 588.3 ], [ 1.756, 582.7 ], [ 1.895, 580.3 ], [ 1.958, 575.3 ],
      [ 2.063, 550.7 ], [ 2.209, 518.8 ], [ 2.344, 477.1 ], [ 2.495, 418 ], [ 2.561, 354.1 ],
      [ 2.582, 334.4 ], [ 2.599, 332 ], [ 2.62, 297.5 ], [ 2.67, 226.2 ], [ 2.707, 157.4 ],
      [ 2.74, 98.35 ], [ 2.799, 49.18 ], [ 2.853, 17.21 ], [ 2.94, 0 ]
],
    "totImpulseNs": 1394,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 485,
    "burnTimeS": 3.5,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K485",
    "dataFiles": 2,
    "designation": "K485H-3JET",
    "diameter": 54,
    "impulseClass": "K",
    "length": 699,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 851.1,
    "motorId": "5f4294d20002310000000142",
    "propWeightG": 923.4,
    "samples": [
      [ 0, 0 ], [ 0.075, 454.5 ], [ 0.227, 568.7 ], [ 0.38, 831.3 ], [ 0.533, 825.6 ], [ 0.686, 795.9 ],
      [ 0.84, 759.5 ], [ 0.992, 727.2 ], [ 1.145, 680.1 ], [ 1.298, 653.1 ], [ 1.451, 627.3 ],
      [ 1.604, 601.5 ], [ 1.756, 576.3 ], [ 1.909, 542 ], [ 2.063, 479.1 ], [ 2.216, 394.2 ],
      [ 2.369, 346.7 ], [ 2.521, 307.4 ], [ 2.674, 276.3 ], [ 2.827, 216.6 ], [ 2.98, 146 ],
      [ 3.133, 106.8 ], [ 3.285, 81.23 ], [ 3.439, 52.1 ], [ 3.592, 37.38 ], [ 3.745, 29.46 ],
      [ 3.898, 0 ]
],
    "totImpulseNs": 1687,
    "totalWeightG": 2220,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "regular",
    "avgThrustN": 514,
    "burnTimeS": 4.84,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K510",
    "dataFiles": 3,
    "designation": "2486K510-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 689.8,
    "motorId": "5f4294d20002310000000143",
    "propInfo": "Classic",
    "propWeightG": 1197,
    "samples": [
      [ 0, 0 ], [ 0.04, 394.4 ], [ 0.07, 617.7 ], [ 0.1, 645.2 ], [ 0.21, 658.2 ], [ 0.35, 669.2 ],
      [ 0.53, 667.7 ], [ 0.82, 661.6 ], [ 1.18, 626.9 ], [ 1.72, 588.5 ], [ 2.15, 557.7 ],
      [ 2.39, 542.3 ], [ 2.9, 492.9 ], [ 3.07, 470.3 ], [ 3.56, 426.8 ], [ 3.98, 399 ], [ 4.32, 394 ],
      [ 4.48, 380.6 ], [ 4.6, 364.2 ], [ 4.65, 290.9 ], [ 4.8, 91.23 ], [ 4.84, 45.82 ], [ 4.84, 0 ]
],
    "totImpulseNs": 2486,
    "totalWeightG": 2590,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 526.9,
    "burnTimeS": 2.66,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "K530",
    "dataFiles": 2,
    "designation": "GG-54-1400",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K530.pdf",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 657.2,
    "motorId": "5f4294d20002310000000144",
    "propInfo": "Green Gorilla",
    "propWeightG": 796.7,
    "samples": [
      [ 0, 0 ], [ 0.013, 129.8 ], [ 0.054, 171.9 ], [ 0.096, 284.1 ], [ 0.138, 392.9 ], [ 0.171, 456 ],
      [ 0.217, 501.7 ], [ 0.238, 498.1 ], [ 0.326, 508.7 ], [ 0.542, 564.7 ], [ 0.755, 613.8 ],
      [ 1.01, 645.4 ], [ 1.17, 657.2 ], [ 1.273, 648.9 ], [ 1.51, 638.4 ], [ 1.656, 634.9 ],
      [ 1.702, 606.8 ], [ 1.803, 606.8 ], [ 1.857, 585.8 ], [ 1.936, 589.3 ], [ 1.974, 575.2 ],
      [ 2.015, 589.3 ], [ 2.04, 564.7 ], [ 2.132, 536.7 ], [ 2.207, 540.3 ], [ 2.291, 522.7 ],
      [ 2.357, 487.6 ], [ 2.42, 375.3 ], [ 2.478, 242 ], [ 2.529, 140.4 ], [ 2.583, 66.65 ],
      [ 2.66, 0 ]
],
    "totImpulseNs": 1402,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 529,
    "burnTimeS": 2.67,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K530",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1412K530-16A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 596.4,
    "motorId": "5f4294d20002310000000145",
    "propInfo": "Smoky Sam",
    "propWeightG": 1025,
    "samples": [
      [ 0, 0 ], [ 0.05, 533.6 ], [ 0.09, 504 ], [ 0.29, 514.1 ], [ 0.6, 534.3 ], [ 0.9, 557.4 ],
      [ 1.2, 577.6 ], [ 1.5, 587.7 ], [ 1.8, 596.4 ], [ 2.1, 535.4 ], [ 2.31, 502.5 ], [ 2.47, 551.6 ],
      [ 2.56, 393.9 ], [ 2.6, 274.4 ], [ 2.64, 137.2 ], [ 2.67, 0 ]
],
    "totImpulseNs": 1412,
    "totalWeightG": 1640,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 396.8,
    "burnTimeS": 3.879,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K550",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K550W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 410,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 655.3,
    "motorId": "5f4294d20002310000000146",
    "propInfo": "White Lightning",
    "propWeightG": 889.1,
    "samples": [
      [ 0, 0 ], [ 0.065, 604.3 ], [ 0.196, 642.6 ], [ 0.327, 682.2 ], [ 0.458, 733 ], [ 0.591, 758.2 ],
      [ 0.723, 780.3 ], [ 0.854, 794.5 ], [ 0.985, 797.9 ], [ 1.117, 797.6 ], [ 1.249, 773.8 ],
      [ 1.381, 711.6 ], [ 1.512, 646.5 ], [ 1.644, 590.7 ], [ 1.775, 537.5 ], [ 1.907, 491 ],
      [ 2.04, 445.8 ], [ 2.171, 401.5 ], [ 2.302, 364.3 ], [ 2.433, 319.6 ], [ 2.566, 255.6 ],
      [ 2.698, 172.6 ], [ 2.829, 103.5 ], [ 2.96, 51.8 ], [ 3.092, 26.81 ], [ 3.224, 15.2 ],
      [ 3.356, 0 ]
],
    "totImpulseNs": 1539,
    "totalWeightG": 1487,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 555,
    "burnTimeS": 2.36,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K555",
    "dataFiles": 2,
    "designation": "SK-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000147",
    "propInfo": "Skidmark",
    "propWeightG": 862,
    "samples": [
      [ 0, 0 ], [ 0.063, 507.3 ], [ 0.144, 535.2 ], [ 0.226, 559.8 ], [ 0.308, 585.8 ], [ 0.389, 607.2 ],
      [ 0.471, 629 ], [ 0.553, 664.6 ], [ 0.634, 683.7 ], [ 0.716, 697.6 ], [ 0.798, 719.6 ],
      [ 0.879, 756.5 ], [ 0.961, 777.7 ], [ 1.043, 789 ], [ 1.124, 797.9 ], [ 1.206, 801.7 ],
      [ 1.288, 804.3 ], [ 1.369, 799.4 ], [ 1.451, 768 ], [ 1.533, 704.5 ], [ 1.614, 641.7 ],
      [ 1.696, 568.7 ], [ 1.778, 481 ], [ 1.859, 401.6 ], [ 1.941, 333.9 ], [ 2.023, 277.2 ],
      [ 2.104, 205 ], [ 2.186, 129.4 ], [ 2.268, 73.72 ], [ 2.349, 22.38 ], [ 2.368, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1300,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 560,
    "burnTimeS": 2.6,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "K560",
    "dataFiles": 2,
    "designation": "RR-54-1400",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K560.pdf",
    "length": 430,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000148",
    "propInfo": "Red Rhino",
    "propWeightG": 750,
    "samples": [
      [ 0, 0 ], [ 0.023, 229.1 ], [ 0.046, 415.1 ], [ 0.059, 485.3 ], [ 0.078, 512.3 ], [ 0.106, 525.7 ],
      [ 0.154, 523 ], [ 0.211, 528.4 ], [ 0.261, 536.5 ], [ 0.369, 560.7 ], [ 0.511, 587.7 ],
      [ 0.657, 603.9 ], [ 0.77, 612 ], [ 1.096, 625.8 ], [ 1.358, 620.1 ], [ 1.627, 612 ],
      [ 1.839, 603.9 ], [ 2.057, 590.5 ], [ 2.218, 598.5 ], [ 2.335, 609.3 ], [ 2.385, 601.2 ],
      [ 2.407, 585 ], [ 2.426, 533.8 ], [ 2.467, 385.5 ], [ 2.507, 283 ], [ 2.542, 164.4 ],
      [ 2.576, 67.4 ], [ 2.595, 29.65 ], [ 2.62, 0 ]
],
    "totImpulseNs": 1480,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 560,
    "burnTimeS": 4.09,
    "caseInfo": "RMS-75/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K560",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K560W",
    "diameter": 75,
    "impulseClass": "K",
    "length": 396,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 753.7,
    "motorId": "5f4294d20002310000000149",
    "propInfo": "White Lightning",
    "propWeightG": 1425,
    "samples": [
      [ 0, 0 ], [ 0.096, 552.1 ], [ 0.29, 645.4 ], [ 0.484, 681.1 ], [ 0.679, 716.2 ], [ 0.874, 742.7 ],
      [ 1.069, 764.8 ], [ 1.264, 775.7 ], [ 1.458, 785.9 ], [ 1.653, 789.3 ], [ 1.848, 789.1 ],
      [ 2.043, 744.6 ], [ 2.237, 676.9 ], [ 2.432, 614.7 ], [ 2.627, 557.9 ], [ 2.822, 503.6 ],
      [ 3.017, 455.5 ], [ 3.211, 412 ], [ 3.406, 373 ], [ 3.601, 336 ], [ 3.796, 307.3 ],[ 3.991, 279.9 ],
      [ 4.185, 223.5 ], [ 4.38, 70.44 ], [ 4.575, 10.03 ], [ 4.77, 2.445 ], [ 4.965, 0 ]
],
    "totImpulseNs": 2417,
    "totalWeightG": 2744,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 574,
    "burnTimeS": 3.59,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K570",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "2060K570-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 892.7,
    "motorId": "5f4294d2000231000000014a",
    "propInfo": "Classic",
    "propWeightG": 990,
    "samples": [
      [ 0, 0 ], [ 0.04, 892.7 ], [ 0.5, 798 ], [ 1, 738.7 ], [ 1.5, 659.4 ], [ 2, 586 ], [ 2.5, 512.9 ],
      [ 2.97, 417.2 ], [ 3.2, 224.8 ], [ 3.47, 67 ], [ 3.59, 0 ]
],
    "totImpulseNs": 2063,
    "totalWeightG": 1685,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 559.1,
    "burnTimeS": 3.04,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K570",
    "dataFiles": 2,
    "designation": "WT-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K570.pdf",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 751.5,
    "motorId": "5f4294d2000231000000014b",
    "propInfo": "White Wolf",
    "propWeightG": 914.6,
    "samples": [
      [ 0, 0 ], [ 0.02, 364.4 ], [ 0.03, 664.8 ], [ 0.051, 751.5 ], [ 0.071, 745.8 ], [ 0.096, 705.3 ],
      [ 0.137, 674.9 ], [ 0.284, 661.4 ], [ 0.528, 651.2 ], [ 0.913, 644.5 ], [ 1.192, 651.2 ],
      [ 1.43, 651.2 ], [ 1.649, 651.2 ], [ 1.872, 644.5 ], [ 2.176, 624.2 ], [ 2.318, 600.6 ],
      [ 2.394, 597.3 ], [ 2.455, 546.6 ], [ 2.501, 485.9 ], [ 2.562, 421.8 ], [ 2.597, 340.8 ],
      [ 2.638, 266.5 ], [ 2.734, 175.5 ], [ 2.836, 97.86 ], [ 2.927, 47.24 ], [ 3.04, 0 ]
],
    "totImpulseNs": 1697,
    "totalWeightG": 1815,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 578,
    "burnTimeS": 4.31,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K575",
    "dataFiles": 2,
    "designation": "2493K575-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 395,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 667.3,
    "motorId": "5f4294d2000231000000014c",
    "propWeightG": 1720,
    "samples": [
      [ 0, 0 ], [ 0.001, 16 ], [ 0.111, 664.5 ], [ 0.431, 620.2 ], [ 0.871, 629 ], [ 1.301, 637.9 ],
      [ 1.731, 637.9 ], [ 2.171, 629 ], [ 2.601, 615.8 ], [ 3.031, 553.8 ], [ 3.471, 518.3 ],
      [ 3.901, 438.6 ], [ 4.181, 79.74 ], [ 4.331, 0 ]
],
    "totImpulseNs": 2493,
    "totalWeightG": 3143,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 600,
    "burnTimeS": 2.38,
    "caseInfo": "54X403(1400)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K600",
    "dataFiles": 1,
    "delays": "S,M,L,P",
    "designation": "K600F",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 874,
    "motorId": "5f4294d2000231000000014d",
    "propWeightG": 724.3,
    "samples": [
      [ 0, 0 ], [ 0.045, 639.7 ], [ 0.148, 711.3 ], [ 0.252, 695.6 ], [ 0.358, 696.3 ], [ 0.462, 701.3 ],
      [ 0.568, 703.2 ], [ 0.67, 705.3 ], [ 0.772, 704.3 ], [ 0.878, 702.8 ], [ 0.982, 701.3 ],
      [ 1.088, 696.9 ], [ 1.192, 689.3 ], [ 1.295, 681.2 ], [ 1.398, 668.9 ], [ 1.502, 653.5 ],
      [ 1.608, 637.4 ], [ 1.712, 619.8 ], [ 1.818, 599.5 ], [ 1.92, 586.3 ], [ 2.022, 510.7 ],
      [ 2.128, 334.7 ], [ 2.232, 125.4 ], [ 2.338, 37.92 ], [ 2.442, 17.16 ], [ 2.548, 4.025 ],
      [ 2.653, 0 ]
],
    "totImpulseNs": 1429,
    "totalWeightG": 1416,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 614.1,
    "burnTimeS": 4.07,
    "caseInfo": "AMW 75-2500",
    "certOrg": "National Association of Rocketry",
    "commonName": "K600",
    "dataFiles": 3,
    "designation": "WT-75-2500",
    "diameter": 75,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K600.pdf",
    "length": 368,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 766.4,
    "motorId": "5f4294d2000231000000014e",
    "propInfo": "White Wolf",
    "propWeightG": 1223,
    "samples": [
      [ 0, 0 ], [ 0.01, 412.2 ], [ 0.029, 522.2 ], [ 0.059, 547.2 ], [ 0.083, 524.8 ], [ 0.122, 497.3 ],
      [ 0.181, 484.9 ], [ 0.333, 495.1 ], [ 0.69, 560.5 ], [ 1.195, 643.5 ], [ 1.4, 673.8 ],
      [ 1.42, 708.8 ], [ 1.508, 701.4 ], [ 1.591, 721.6 ], [ 1.782, 731.7 ], [ 2.017, 752 ],
      [ 2.174, 756.8 ], [ 2.257, 765.2 ], [ 2.502, 766.4 ], [ 2.727, 752.9 ], [ 2.918, 738.2 ],
      [ 3.143, 705.9 ], [ 3.408, 643.8 ], [ 3.603, 569.1 ], [ 3.692, 526.8 ], [ 3.745, 439.4 ],
      [ 3.799, 289.6 ], [ 3.883, 112.3 ], [ 3.922, 64.86 ], [ 3.971, 37.44 ], [ 3.995, 22.47 ],
      [ 4.07, 0 ]
],
    "totImpulseNs": 2499,
    "totalWeightG": 2913,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 605,
    "burnTimeS": 3.97,
    "caseInfo": "AMW 75-2500",
    "certOrg": "National Association of Rocketry",
    "commonName": "K605",
    "dataFiles": 2,
    "designation": "RR-75-2500",
    "diameter": 75,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K605.pdf",
    "length": 368,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000014f",
    "propInfo": "Red Rhino",
    "propWeightG": 1231,
    "samples": [
      [ 0, 0 ], [ 0.03, 165.8 ], [ 0.053, 309.1 ], [ 0.077, 361.9 ], [ 0.142, 392 ], [ 0.527, 501.4 ],
      [ 0.988, 606.9 ], [ 1.515, 682.4 ], [ 2.101, 730.6 ], [ 2.355, 737.6 ], [ 2.692, 731.3 ],
      [ 3, 712.5 ], [ 3.361, 671 ], [ 3.503, 663.5 ], [ 3.586, 659.7 ], [ 3.645, 633.4 ],[ 3.692, 573 ],
      [ 3.734, 444.8 ], [ 3.775, 297.8 ], [ 3.828, 162.1 ], [ 3.864, 98.02 ], [ 3.905, 41.47 ],
      [ 3.95, 0 ]
],
    "totImpulseNs": 2410,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 609.4,
    "burnTimeS": 2.2,
    "caseInfo": "75 32 M SP (1400CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K630",
    "dataFiles": 2,
    "designation": "K630-SP",
    "diameter": 75,
    "impulseClass": "K",
    "length": 813,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 973,
    "motorId": "5f4294d20002310000000150",
    "propWeightG": 515,
    "samples": [
      [ 0, 0 ], [ 0.005593, 308 ], [ 0.09787, 573.1 ], [ 0.5006, 889.6 ], [ 1.753, 667.2 ],
      [ 1.854, 410.6 ], [ 1.938, 239.5 ], [ 2.05, 128.3 ], [ 2.2, 0 ]
],
    "totImpulseNs": 1341,
    "totalWeightG": 3550,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 650,
    "burnTimeS": 2.8,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K650",
    "dataFiles": 2,
    "designation": "RR-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K650.pdf",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000151",
    "propInfo": "Red Rhino",
    "propWeightG": 931,
    "samples": [
      [ 0, 0 ], [ 0.022, 308.3 ], [ 0.045, 566.5 ], [ 0.058, 620.4 ], [ 0.081, 639.7 ], [ 0.135, 639.7 ],
      [ 0.229, 643.5 ], [ 0.351, 662.8 ], [ 0.594, 701.4 ], [ 0.81, 724.4 ], [ 0.999, 743.8 ],
      [ 1.151, 751.2 ], [ 1.381, 747.6 ], [ 1.61, 736 ], [ 1.835, 709 ], [ 2.073, 685.9 ],
      [ 2.244, 674.4 ], [ 2.334, 682.1 ], [ 2.429, 685.9 ], [ 2.469, 666.6 ], [ 2.528, 597.3 ],
      [ 2.573, 481.7 ], [ 2.609, 358.4 ], [ 2.631, 250.5 ], [ 2.681, 146.5 ], [ 2.721, 65.51 ],
      [ 2.748, 23.12 ], [ 2.77, 0 ]
],
    "totImpulseNs": 1840,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 656,
    "burnTimeS": 2.66,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K650",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1750K650-16A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 728.7,
    "motorId": "5f4294d20002310000000152",
    "propInfo": "Smoky Sam",
    "propWeightG": 1281,
    "samples": [
      [ 0, 0 ], [ 0.04, 664.5 ], [ 0.12, 645.9 ], [ 0.31, 642.2 ], [ 0.6, 664.8 ], [ 0.91, 684.6 ],
      [ 1.22, 712.8 ], [ 1.5, 723.4 ], [ 1.8, 728.7 ], [ 2.1, 664.5 ], [ 2.4, 614.7 ], [ 2.51, 680.5 ],
      [ 2.55, 534.6 ], [ 2.61, 268.2 ], [ 2.66, 0 ]
],
    "totImpulseNs": 1750,
    "totalWeightG": 1990,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 650,
    "burnTimeS": 3.67,
    "caseInfo": "RMS-98/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K650",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K650T",
    "diameter": 98,
    "impulseClass": "K",
    "length": 289,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 752.8,
    "motorId": "5f4294d20002310000000153",
    "propInfo": "Blue Thunder",
    "propWeightG": 1280,
    "samples": [
      [ 0, 0 ], [ 0.079, 514.3 ], [ 0.24, 594.3 ], [ 0.401, 618.8 ], [ 0.563, 641.7 ], [ 0.723, 665.1 ],
      [ 0.884, 686.5 ], [ 1.046, 704.7 ], [ 1.206, 720.2 ], [ 1.368, 730.1 ], [ 1.529, 736.9 ],
      [ 1.69, 743.1 ], [ 1.851, 747.5 ], [ 2.013, 747.6 ], [ 2.174, 744.1 ], [ 2.335, 732.3 ],
      [ 2.496, 710.4 ], [ 2.657, 682.7 ], [ 2.819, 653.2 ], [ 2.979, 627 ], [ 3.141, 595.5 ],
      [ 3.302, 563.8 ], [ 3.463, 551.1 ], [ 3.624, 236.1 ], [ 3.785, 1.383 ], [ 3.947, 1.234 ],
      [ 4.108, 0 ]
],
    "totImpulseNs": 2406,
    "totalWeightG": 2935,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 659,
    "burnTimeS": 3.69,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K660",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "2437K660-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1079,
    "motorId": "5f4294d20002310000000154",
    "propInfo": "Classic",
    "propWeightG": 1177,
    "samples": [
      [ 0, 0 ], [ 0.07, 1079 ], [ 0.23, 1006 ], [ 0.4, 966.8 ], [ 0.8, 897.5 ], [ 1.2, 842.7 ],
      [ 1.6, 794.1 ], [ 2.01, 744.5 ], [ 2.4, 692.3 ], [ 2.54, 671.4 ], [ 2.68, 439.1 ], [ 2.8, 400.7 ],
      [ 3.01, 386.9 ], [ 3.2, 234.3 ], [ 3.45, 106.7 ], [ 3.6, 44.03 ], [ 3.69, 0 ]
],
    "totImpulseNs": 2437,
    "totalWeightG": 1949,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 661,
    "burnTimeS": 2.8,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K678",
    "dataFiles": 2,
    "designation": "K678-BG",
    "diameter": 75,
    "impulseClass": "K",
    "length": 1016,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 2342,
    "motorId": "5f4294d20002310000000155",
    "propWeightG": 580,
    "samples": [
      [ 0, 0 ], [ 0.005593, 1163 ], [ 2.215, 444.8 ], [ 2.321, 256.6 ], [ 2.388, 102.7 ],[ 2.8, 0 ]
],
    "totImpulseNs": 1847,
    "totalWeightG": 4050,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 668.4,
    "burnTimeS": 2.62,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K670",
    "dataFiles": 3,
    "designation": "GG-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K670.pdf",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 804.6,
    "motorId": "5f4294d20002310000000156",
    "propInfo": "Green Gorilla",
    "propWeightG": 1014,
    "samples": [
      [ 0, 0 ], [ 0.016, 294.1 ], [ 0.035, 398.6 ], [ 0.086, 506.3 ], [ 0.153, 496.4 ], [ 0.264, 506.1 ],
      [ 0.461, 558.1 ], [ 0.722, 629.6 ], [ 0.983, 688 ], [ 1.116, 714.1 ], [ 1.193, 785.8 ],
      [ 1.409, 788.8 ], [ 1.737, 804.6 ], [ 2.074, 781.4 ], [ 2.195, 764.9 ], [ 2.226, 781.2 ],
      [ 2.277, 764.8 ], [ 2.398, 751.5 ], [ 2.44, 744.9 ], [ 2.468, 718.8 ], [ 2.484, 666.5 ],
      [ 2.525, 418.1 ], [ 2.551, 218.8 ], [ 2.573, 120.8 ], [ 2.595, 52.14 ], [ 2.62, 0 ]
],
    "totImpulseNs": 1751,
    "totalWeightG": 1915,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 680,
    "burnTimeS": 3.49,
    "caseInfo": "RMS-98/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K680",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K680R",
    "diameter": 98,
    "impulseClass": "K",
    "length": 289,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 835,
    "motorId": "5f4294d20002310000000157",
    "propInfo": "Redline",
    "propWeightG": 1316,
    "samples": [
      [ 0, 0 ], [ 0.085, 629.8 ], [ 0.494, 717.9 ], [ 0.996, 797.2 ], [ 1.29, 819.2 ], [ 1.506, 819.2 ],
      [ 2.001, 775.1 ], [ 2.519, 673.8 ], [ 2.99, 563.7 ], [ 3.137, 541.7 ], [ 3.176, 532.9 ],
      [ 3.238, 563.7 ], [ 3.276, 563.7 ], [ 3.408, 52.85 ], [ 3.431, 22.02 ], [ 3.49, 0 ]
],
    "totImpulseNs": 2358,
    "totalWeightG": 3035,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 695,
    "burnTimeS": 2.178,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K695",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K695R",
    "diameter": 54,
    "impulseClass": "K",
    "length": 410,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000158",
    "propInfo": "Redline",
    "propWeightG": 903,
    "samples": [
      [ 0, 0 ], [ 0.044, 618.6 ], [ 0.134, 727.8 ], [ 0.224, 752 ], [ 0.314, 812.5 ], [ 0.404, 900.1 ],
      [ 0.495, 884.8 ], [ 0.585, 873.5 ], [ 0.675, 864.6 ], [ 0.765, 849.7 ], [ 0.856, 838.9 ],
      [ 0.946, 822.5 ], [ 1.036, 806.2 ], [ 1.126, 781.3 ], [ 1.216, 754 ], [ 1.307, 728.5 ],
      [ 1.398, 697.6 ], [ 1.487, 673 ], [ 1.578, 646.7 ], [ 1.667, 620.9 ], [ 1.758, 595.6 ],
      [ 1.849, 571.7 ], [ 1.939, 546.8 ], [ 2.029, 272.8 ], [ 2.119, 57.95 ], [ 2.209, 4.509 ],
      [ 2.3, 0 ]
],
    "totImpulseNs": 1514,
    "totalWeightG": 1487,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 700,
    "burnTimeS": 2.3,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "K700",
    "dataFiles": 2,
    "designation": "BB-54-1400",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K700.pdf",
    "length": 430,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000159",
    "propInfo": "Blue Baboon",
    "propWeightG": 745,
    "samples": [
      [ 0, 0 ], [ 0.014, 359.6 ], [ 0.022, 625.4 ], [ 0.03, 737.8 ], [ 0.047, 771.5 ], [ 0.082, 786.5 ],
      [ 0.106, 771.5 ], [ 0.144, 775.2 ], [ 0.272, 786.5 ], [ 0.477, 812.7 ], [ 0.693, 842.6 ],
      [ 0.97, 847.1 ], [ 1.283, 838.9 ], [ 1.516, 816.4 ], [ 1.706, 801.4 ], [ 1.779, 794 ],
      [ 1.811, 775.2 ], [ 1.841, 726.6 ], [ 1.873, 625.4 ], [ 1.909, 509.4 ], [ 1.95, 393.2 ],
      [ 1.982, 337.1 ], [ 2.035, 292.2 ], [ 2.073, 228.5 ], [ 2.111, 153.5 ], [ 2.155, 86.14 ],
      [ 2.193, 37.45 ], [ 2.24, 0 ]
],
    "totImpulseNs": 1650,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 700,
    "burnTimeS": 3.51,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K700",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K700W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 568,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1617,
    "motorId": "5f4294d2000231000000015a",
    "propInfo": "White Lightning",
    "propWeightG": 1303,
    "samples": [
      [ 0, 0 ], [ 0.069, 1005 ], [ 0.209, 1019 ], [ 0.35, 1027 ], [ 0.491, 1029 ], [ 0.632, 1029 ],
      [ 0.773, 1004 ], [ 0.914, 970.7 ], [ 1.055, 946.5 ], [ 1.196, 918.4 ], [ 1.336, 873.8 ],
      [ 1.478, 821.3 ], [ 1.619, 773.3 ], [ 1.759, 735.6 ], [ 1.9, 692.7 ], [ 2.041, 659 ],
      [ 2.182, 626.7 ], [ 2.323, 591.4 ], [ 2.464, 508.7 ], [ 2.605, 420.2 ], [ 2.746, 328.3 ],
      [ 2.886, 202.4 ], [ 3.028, 121.7 ], [ 3.169, 80.45 ], [ 3.309, 50.87 ], [ 3.451, 31.55 ],
      [ 3.593, 0 ]
],
    "totImpulseNs": 2261,
    "totalWeightG": 2035,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 698,
    "burnTimeS": 1.98,
    "caseInfo": "75 32 M BG (1400CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K707",
    "dataFiles": 2,
    "designation": "K707-BG",
    "diameter": 75,
    "impulseClass": "K",
    "length": 813,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1486,
    "motorId": "5f4294d2000231000000015b",
    "propWeightG": 159,
    "samples": [
      [ 0, 0 ], [ 0.04661, 281.2 ], [ 0.1229, 1278 ], [ 0.1653, 894.8 ], [ 0.4958, 1432 ],
      [ 0.6186, 1150 ], [ 0.6949, 945.9 ], [ 0.8347, 920.3 ], [ 1.013, 664.7 ], [ 1.508, 536.9 ],
      [ 1.623, 281.2 ], [ 1.729, 127.8 ], [ 2, 0 ]
],
    "totImpulseNs": 1386,
    "totalWeightG": 3674,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 695,
    "burnTimeS": 2.6,
    "caseInfo": "75 40 M SP (2050CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K777",
    "dataFiles": 2,
    "designation": "K777-SP",
    "diameter": 75,
    "impulseClass": "K",
    "length": 1016,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1908,
    "motorId": "5f4294d2000231000000015c",
    "propWeightG": 645,
    "samples": [
      [ 0, 0 ], [ 0.001, 931 ], [ 0.09608, 965.5 ], [ 0.1129, 1793 ], [ 0.1688, 1741 ], [ 0.2079, 1345 ],
      [ 0.7281, 1138 ], [ 1.002, 810.3 ], [ 1.975, 413.8 ], [ 2.048, 172.4 ], [ 2.601, 0 ]
],
    "totImpulseNs": 1833,
    "totalWeightG": 4050,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 780,
    "burnTimeS": 2.98,
    "caseInfo": "RMS-75/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K780",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K780R",
    "diameter": 75,
    "impulseClass": "K",
    "length": 395,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 965,
    "motorId": "5f4294d2000231000000015d",
    "propInfo": "Redline",
    "propWeightG": 1268,
    "samples": [
      [ 0, 0 ], [ 0.053, 383.3 ], [ 0.173, 718.2 ], [ 0.292, 849.3 ], [ 0.413, 885.5 ], [ 0.533, 903.2 ],
      [ 0.652, 924.4 ], [ 0.772, 938.8 ], [ 0.892, 938.6 ], [ 1.013, 947.1 ], [ 1.133, 953.6 ],
      [ 1.253, 944 ], [ 1.373, 935.4 ], [ 1.495, 929.4 ], [ 1.617, 920.4 ], [ 1.737, 897.3 ],
      [ 1.857, 888.9 ], [ 1.977, 861.1 ], [ 2.098, 841 ], [ 2.217, 812.4 ], [ 2.337, 779.6 ],
      [ 2.457, 747.9 ], [ 2.578, 726.8 ], [ 2.697, 729.3 ], [ 2.817, 279.9 ], [ 2.94, 10.97 ],
      [ 3.063, 0 ]
],
    "totImpulseNs": 2371,
    "totalWeightG": 2934,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 800,
    "burnTimeS": 2.3,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K800",
    "dataFiles": 2,
    "designation": "BB-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K800.pdf",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000015e",
    "propInfo": "Blue Baboon",
    "propWeightG": 914,
    "samples": [
      [ 0, 0 ], [ 0.017, 516.3 ], [ 0.035, 745.8 ], [ 0.046, 817.6 ], [ 0.09, 860.6 ], [ 0.191, 889.3 ],
      [ 0.27, 908.4 ], [ 0.438, 918 ], [ 0.689, 945.9 ], [ 0.996, 955.1 ], [ 1.325, 922.7 ],
      [ 1.557, 894 ], [ 1.726, 874.9 ], [ 1.849, 884.5 ], [ 1.92, 894 ], [ 1.954, 894 ], [ 1.984, 855.9 ],
      [ 2.011, 741 ], [ 2.049, 592.9 ], [ 2.079, 492.4 ], [ 2.113, 430.3 ], [ 2.154, 377.7 ],
      [ 2.196, 329.9 ], [ 2.237, 243.8 ], [ 2.275, 153 ], [ 2.309, 71.72 ], [ 2.339, 33.47 ],
      [ 2.38, 0 ]
],
    "totImpulseNs": 1950,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 943.5,
    "burnTimeS": 1.97,
    "caseInfo": "AMW 54-1750",
    "certOrg": "National Association of Rocketry",
    "commonName": "K950",
    "dataFiles": 3,
    "designation": "ST-54-1750",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K950.pdf",
    "length": 492,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1205,
    "motorId": "5f4294d2000231000000015f",
    "propInfo": "Super Tiger",
    "propWeightG": 887.4,
    "samples": [
      [ 0, 0 ], [ 0.011, 771.8 ], [ 0.025, 1205 ], [ 0.039, 1083 ], [ 0.053, 1158 ], [ 0.067, 1036 ],
      [ 0.085, 1110 ], [ 0.099, 1022 ], [ 0.135, 982.1 ], [ 0.22, 968.8 ], [ 0.404, 1010 ],
      [ 0.566, 1044 ], [ 0.701, 1079 ], [ 0.867, 1106 ], [ 0.995, 1134 ], [ 1.211, 1114 ],
      [ 1.313, 1101 ], [ 1.43, 1067 ], [ 1.529, 1020 ], [ 1.579, 993.8 ], [ 1.642, 892.4 ],
      [ 1.674, 818.1 ], [ 1.717, 757.3 ], [ 1.738, 621.9 ], [ 1.766, 466.3 ], [ 1.791, 351.3 ],
      [ 1.823, 249.9 ], [ 1.865, 175.6 ], [ 1.908, 87.7 ], [ 1.943, 33.65 ], [ 1.97, 0 ]
],
    "totImpulseNs": 1859,
    "totalWeightG": 1795,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 960,
    "burnTimeS": 1.98,
    "caseInfo": "54/2000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K960",
    "dataFiles": 2,
    "designation": "K960-LW",
    "diameter": 54,
    "impulseClass": "K",
    "length": 499,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1529,
    "motorId": "5f4294d20002310000000160",
    "propInfo": "Loki White",
    "propWeightG": 930,
    "samples": [
      [ 0, 0 ], [ 0.03, 1210 ], [ 0.05, 1512 ], [ 0.075, 1535 ], [ 0.1, 1502 ], [ 0.125, 1437 ],
      [ 0.2, 1237 ], [ 0.3, 1175 ], [ 0.5, 1139 ], [ 0.6, 1130 ], [ 0.7, 1156 ], [ 0.8, 1182 ],
      [ 0.9, 1192 ], [ 1, 1166 ], [ 1.1, 1139 ], [ 1.2, 1101 ], [ 1.3, 1091 ], [ 1.4, 1026 ],
      [ 1.5, 839 ], [ 1.6, 790 ], [ 1.7, 575 ], [ 1.8, 284 ], [ 1.9, 150 ], [ 2, 0 ]
],
    "totImpulseNs": 1946,
    "totalWeightG": 1746,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 976.4,
    "burnTimeS": 2.51,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K975",
    "dataFiles": 2,
    "designation": "WT-54-2550",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K975.pdf",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1291,
    "motorId": "5f4294d20002310000000161",
    "propInfo": "White Wolf",
    "propWeightG": 1357,
    "samples": [
      [ 0, 0 ], [ 0.017, 526.6 ], [ 0.029, 901.9 ], [ 0.038, 1099 ], [ 0.046, 1152 ], [ 0.076, 1113 ],
      [ 0.13, 1060 ], [ 0.219, 1053 ], [ 0.336, 1053 ], [ 0.479, 1059 ], [ 0.609, 1092 ],[ 0.866, 1137 ],
      [ 1.046, 1177 ], [ 1.164, 1176 ], [ 1.202, 1228 ], [ 1.239, 1209 ], [ 1.315, 1215 ],
      [ 1.353, 1267 ], [ 1.387, 1228 ], [ 1.487, 1241 ], [ 1.538, 1260 ], [ 1.634, 1291 ],
      [ 1.723, 1281 ], [ 1.794, 1266 ], [ 1.836, 1208 ], [ 1.933, 1049 ], [ 1.992, 851.4 ],
      [ 2.08, 666.9 ], [ 2.118, 640.5 ], [ 2.193, 462.6 ], [ 2.269, 212.3 ], [ 2.378, 119.9 ],
      [ 2.51, 0 ]
],
    "totImpulseNs": 2454,
    "totalWeightG": 2599,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1000,
    "burnTimeS": 2,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K1000",
    "dataFiles": 2,
    "designation": "SK-54-2550",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000162",
    "propInfo": "Skidmark",
    "propWeightG": 1297,
    "samples": [
      [ 0, 0 ], [ 0.019, 1155 ], [ 0.045, 1426 ], [ 0.094, 1248 ], [ 0.161, 1113 ], [ 0.239, 1128 ],
      [ 0.343, 1114 ], [ 0.377, 1149 ], [ 0.44, 1121 ], [ 0.544, 1221 ], [ 0.633, 1178 ],[ 0.674, 1221 ],
      [ 0.737, 1193 ], [ 0.883, 1200 ], [ 1.009, 1194 ], [ 1.057, 1236 ], [ 1.188, 1137 ],
      [ 1.299, 1145 ], [ 1.396, 1088 ], [ 1.516, 954.1 ], [ 1.631, 855.2 ], [ 1.717, 827.1 ],
      [ 1.777, 650.1 ], [ 1.848, 465.9 ], [ 1.93, 303.1 ], [ 2.023, 147.5 ], [ 2.083, 83.88 ],
      [ 2.132, 41.48 ], [ 2.18, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2120,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1000,
    "burnTimeS": 2.6,
    "caseInfo": "54X728",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1000",
    "dataFiles": 0,
    "designation": "K1000S",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 1738,
    "motorId": "5f4294d20002310000000163",
    "propWeightG": 1301,
    "totImpulseNs": 2593,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1050,
    "burnTimeS": 2.28,
    "caseInfo": "SU 54x676",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1050",
    "dataFiles": 2,
    "designation": "K1050W-SU",
    "diameter": 54,
    "impulseClass": "K",
    "length": 676,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2164,
    "motorId": "5f4294d20002310000000164",
    "propInfo": "White Lightning",
    "propWeightG": 1362,
    "samples": [
      [ 0, 0 ], [ 0.049, 1306 ], [ 0.149, 1270 ], [ 0.249, 1289 ], [ 0.349, 1327 ], [ 0.449, 1346 ],
      [ 0.549, 1360 ], [ 0.649, 1364 ], [ 0.749, 1365 ], [ 0.849, 1377 ], [ 0.949, 1380 ],
      [ 1.049, 1347 ], [ 1.149, 1287 ], [ 1.249, 1232 ], [ 1.349, 1186 ], [ 1.449, 1157 ],
      [ 1.549, 1120 ], [ 1.649, 1099 ], [ 1.749, 1070 ], [ 1.849, 889.9 ], [ 1.949, 646.7 ],
      [ 2.049, 441.2 ], [ 2.149, 302.2 ], [ 2.249, 155 ], [ 2.349, 52.19 ], [ 2.449, 43.41 ],
      [ 2.549, 0 ]
],
    "totImpulseNs": 2530,
    "totalWeightG": 2128,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 526.9,
    "burnTimeS": 2.2,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K1075",
    "dataFiles": 2,
    "designation": "GG-54-2550",
    "diameter": 54,
    "impulseClass": "K",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/K1075.pdf",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1385,
    "motorId": "5f4294d20002310000000165",
    "propInfo": "Green Gorilla",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.009, 672.7 ], [ 0.015, 963.5 ], [ 0.022, 860.5 ], [ 0.047, 987.9 ], [ 0.075, 975.8 ],
      [ 0.106, 921.3 ], [ 0.215, 958 ], [ 0.529, 1092 ], [ 0.878, 1220 ], [ 1.077, 1269 ],
      [ 1.158, 1311 ], [ 1.235, 1293 ], [ 1.448, 1331 ], [ 1.577, 1318 ], [ 1.672, 1319 ],
      [ 1.721, 1338 ], [ 1.759, 1338 ], [ 1.805, 1338 ], [ 1.829, 1332 ], [ 1.856, 1385 ],
      [ 1.889, 1277 ], [ 1.906, 1216 ], [ 1.938, 1053 ], [ 1.96, 871.3 ], [ 1.988, 659.2 ],
      [ 2.027, 453.4 ], [ 2.062, 302 ], [ 2.115, 138.5 ], [ 2.168, 41.61 ], [ 2.2, 0 ]
],
    "totImpulseNs": 2392,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1100,
    "burnTimeS": 1.61,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1100",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K1100T",
    "diameter": 54,
    "impulseClass": "K",
    "length": 398,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2004,
    "motorId": "5f4294d20002310000000166",
    "propInfo": "Blue Thunder",
    "propWeightG": 773.3,
    "samples": [
      [ 0, 0 ], [ 0.034, 1235 ], [ 0.105, 1233 ], [ 0.176, 1192 ], [ 0.247, 1163 ], [ 0.318, 1148 ],
      [ 0.389, 1146 ], [ 0.46, 1141 ], [ 0.532, 1133 ], [ 0.603, 1124 ], [ 0.674, 1109 ],[ 0.745, 1091 ],
      [ 0.816, 1074 ], [ 0.887, 1049 ], [ 0.959, 1021 ], [ 1.03, 994.6 ], [ 1.101, 966.6 ],
      [ 1.172, 940.2 ], [ 1.243, 909.8 ], [ 1.315, 880.3 ], [ 1.386, 844.5 ], [ 1.457, 643.6 ],
      [ 1.528, 401.9 ], [ 1.599, 145.5 ], [ 1.67, 28.37 ], [ 1.742, 0 ]
],
    "totImpulseNs": 1472,
    "totalWeightG": 1336,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1275,
    "burnTimeS": 1.75,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1275",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K1275R",
    "diameter": 54,
    "impulseClass": "K",
    "length": 568,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1554,
    "motorId": "5f4294d20002310000000167",
    "propInfo": "Redline",
    "propWeightG": 1222,
    "samples": [
      [ 0, 0 ], [ 0.039, 1283 ], [ 0.119, 1558 ], [ 0.199, 1540 ], [ 0.279, 1527 ], [ 0.359, 1501 ],
      [ 0.44, 1457 ], [ 0.52, 1426 ], [ 0.6, 1390 ], [ 0.68, 1355 ], [ 0.761, 1323 ], [ 0.841, 1283 ],
      [ 0.921, 1248 ], [ 1.001, 1194 ], [ 1.081, 1151 ], [ 1.162, 1108 ], [ 1.242, 1069 ],
      [ 1.322, 1037 ], [ 1.403, 997.4 ], [ 1.482, 964.6 ], [ 1.563, 933.3 ], [ 1.644, 890 ],
      [ 1.724, 599.5 ], [ 1.804, 134.6 ], [ 1.884, 5.63 ], [ 1.964, 0.205 ], [ 2.045, 0 ]
],
    "totImpulseNs": 2225,
    "totalWeightG": 1986,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1400,
    "burnTimeS": 1.88,
    "caseInfo": "54 X 727",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1400",
    "dataFiles": 0,
    "designation": "K1400F",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 1989,
    "motorId": "5f4294d20002310000000168",
    "propWeightG": 1248,
    "totImpulseNs": 2640,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 200,
    "burnTimeS": 13.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L200",
    "dataFiles": 1,
    "designation": "1685CC098L-L200",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000169",
    "propWeightG": 1614,
    "samples": [
      [ 0, 0 ], [ 0.292, 310.9 ], [ 0.877, 311.9 ], [ 1.464, 284.6 ], [ 2.05, 259.2 ], [ 2.636, 245.1 ],
      [ 3.223, 240.8 ], [ 3.809, 246 ], [ 4.396, 251.5 ], [ 4.981, 255.6 ], [ 5.568, 250 ],
      [ 6.154, 242.3 ], [ 6.741, 236.2 ], [ 7.327, 230.5 ], [ 7.914, 224.1 ], [ 8.5, 218.2 ],
      [ 9.086, 212.2 ], [ 9.673, 189.7 ], [ 10.26, 94.61 ], [ 10.85, 67.13 ], [ 11.43, 53.35 ],
      [ 12.02, 41.55 ], [ 12.6, 31.11 ], [ 13.19, 22.45 ], [ 13.78, 16.76 ], [ 14.36, 10.89 ],
      [ 14.95, 0 ]
],
    "totImpulseNs": 2639,
    "totalWeightG": 3895,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 225,
    "burnTimeS": 12.39,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L225",
    "dataFiles": 1,
    "designation": "1685CC098LFX-L225FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000016a",
    "propWeightG": 1688,
    "samples": [
      [ 0, 0 ], [ 0.271, 380.6 ], [ 0.815, 364.1 ], [ 1.359, 347.7 ], [ 1.904, 312 ], [ 2.449, 292.8 ],
      [ 2.994, 284.6 ], [ 3.539, 284.1 ], [ 4.083, 295.8 ], [ 4.628, 286.7 ], [ 5.173, 271.8 ],
      [ 5.718, 258.2 ], [ 6.263, 249.4 ], [ 6.807, 240.4 ], [ 7.352, 232.7 ], [ 7.897, 226.8 ],
      [ 8.442, 217.6 ], [ 8.986, 208.6 ], [ 9.531, 122.7 ], [ 10.08, 74.67 ], [ 10.62, 60.93 ],
      [ 11.17, 48.9 ], [ 11.71, 39 ], [ 12.26, 29.72 ], [ 12.8, 21.93 ], [ 13.35, 16.36 ],
      [ 13.89, 0 ]
],
    "totImpulseNs": 2789,
    "totalWeightG": 3948,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2456,
    "burnTimeS": 1.9,
    "caseInfo": "75x1524 (3200CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2525",
    "dataFiles": 3,
    "designation": "L2525-GF",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1524,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 4906,
    "motorId": "5f4294d2000231000000016b",
    "propWeightG": 1306,
    "samples": [
      [ 0, 0 ], [ 0.001, 4200 ], [ 0.7558, 3295 ], [ 1.901, 0 ]
],
    "totImpulseNs": 4677,
    "totalWeightG": 5579,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 330,
    "burnTimeS": 7.3,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L330",
    "dataFiles": 2,
    "designation": "L330",
    "diameter": 76,
    "impulseClass": "L",
    "length": 381,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 484.6,
    "motorId": "5f4294d2000231000000016c",
    "propWeightG": 1490,
    "samples": [
      [ 0, 0 ], [ 0.194, 299 ], [ 0.584, 378.8 ], [ 0.975, 376.2 ], [ 1.366, 382.5 ], [ 1.757, 391.2 ],
      [ 2.148, 399.4 ], [ 2.539, 406 ], [ 2.93, 407.7 ], [ 3.321, 405.7 ], [ 3.711, 400.6 ],
      [ 4.103, 393.4 ], [ 4.494, 384.5 ], [ 4.884, 377 ], [ 5.275, 368.4 ], [ 5.666, 359 ],
      [ 6.057, 350.1 ], [ 6.448, 341.6 ], [ 6.839, 337.1 ], [ 7.23, 300 ], [ 7.621, 194.6 ],
      [ 8.011, 123.4 ], [ 8.403, 66.94 ], [ 8.794, 32.23 ], [ 9.184, 8.248 ], [ 9.576, 1.563 ],
      [ 9.968, 0 ]
],
    "totImpulseNs": 2973,
    "totalWeightG": 2670,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 350,
    "burnTimeS": 8.694,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L350",
    "dataFiles": 1,
    "designation": "1685CC125L-L350",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000016d",
    "propWeightG": 1620,
    "samples": [
      [ 0, 0 ], [ 0.188, 592.2 ], [ 0.566, 598 ], [ 0.945, 479.8 ], [ 1.324, 427.2 ], [ 1.702, 406.6 ],
      [ 2.08, 395.1 ], [ 2.459, 393.3 ], [ 2.839, 410.7 ], [ 3.217, 517.3 ], [ 3.595, 506.5 ],
      [ 3.974, 439.5 ], [ 4.353, 391.7 ], [ 4.731, 463.4 ], [ 5.109, 446.9 ], [ 5.489, 436.2 ],
      [ 5.868, 387.5 ], [ 6.246, 247.3 ], [ 6.624, 147.8 ], [ 7.003, 117.5 ], [ 7.382, 93.08 ],
      [ 7.76, 73.03 ], [ 8.139, 55.6 ], [ 8.518, 40.85 ], [ 8.897, 29.57 ], [ 9.276, 21.63 ],
      [ 9.655, 0 ]
],
    "totImpulseNs": 3043,
    "totalWeightG": 3903,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 355,
    "burnTimeS": 8.031,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L355",
    "dataFiles": 1,
    "designation": "1685CC125LFX-L355FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000016e",
    "propWeightG": 1638,
    "samples": [
      [ 0, 0 ], [ 0.185, 638.7 ], [ 0.559, 623.8 ], [ 0.933, 538.1 ], [ 1.307, 493.2 ], [ 1.682, 465.1 ],
      [ 2.056, 432.7 ], [ 2.43, 410.6 ], [ 2.804, 388.9 ], [ 3.178, 395.5 ], [ 3.553, 384.9 ],
      [ 3.927, 370.1 ], [ 4.301, 356.5 ], [ 4.675, 346.2 ], [ 5.049, 342.7 ], [ 5.424, 325.4 ],
      [ 5.798, 317.8 ], [ 6.172, 272.5 ], [ 6.546, 156.2 ], [ 6.92, 117.6 ], [ 7.295, 93.2 ],
      [ 7.669, 73.06 ], [ 8.043, 57 ], [ 8.417, 42.99 ], [ 8.791, 30.34 ], [ 9.166, 21.73 ],
      [ 9.541, 0 ]
],
    "totImpulseNs": 2851,
    "totalWeightG": 3954,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 361.7,
    "burnTimeS": 10.58,
    "caseInfo": "75 52 S SP (3200CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L369",
    "dataFiles": 2,
    "designation": "L369-SP",
    "diameter": 75,
    "impulseClass": "L",
    "length": 813,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 651.9,
    "motorId": "5f4294d2000231000000016f",
    "propWeightG": 785,
    "samples": [
      [ 0, 0 ], [ 0.02237, 540.6 ], [ 1.454, 533.8 ], [ 8.926, 260.1 ], [ 9.083, 130 ], [ 9.284, 68.43 ],
      [ 10.6, 0 ]
],
    "totImpulseNs": 3829,
    "totalWeightG": 4800,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 475,
    "burnTimeS": 5.841,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L475",
    "dataFiles": 1,
    "designation": "1685CC172L-L475",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000170",
    "propWeightG": 1543,
    "samples": [
      [ 0, 0 ], [ 0.129, 640.8 ], [ 0.391, 638.1 ], [ 0.652, 619 ], [ 0.914, 609.7 ], [ 1.176, 597.9 ],
      [ 1.437, 596.3 ], [ 1.699, 594 ], [ 1.961, 572.2 ], [ 2.223, 578.3 ], [ 2.484, 589.2 ],
      [ 2.747, 578.8 ], [ 3.008, 612.4 ], [ 3.27, 646.2 ], [ 3.531, 674.6 ], [ 3.793, 652.6 ],
      [ 4.055, 555.4 ], [ 4.317, 299.7 ], [ 4.578, 220.3 ], [ 4.841, 170 ], [ 5.102, 127 ],
      [ 5.364, 95 ], [ 5.626, 70.21 ], [ 5.888, 49.46 ], [ 6.149, 32.1 ], [ 6.411, 18.38 ],
      [ 6.674, 0 ]
],
    "totImpulseNs": 2774,
    "totalWeightG": 3898,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 535,
    "burnTimeS": 5.597,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L535",
    "dataFiles": 1,
    "designation": "1685CC172LFX-L535FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000171",
    "propWeightG": 1616,
    "samples": [
      [ 0, 0 ], [ 0.119, 838.5 ], [ 0.358, 768.7 ], [ 0.598, 727.6 ], [ 0.838, 745.2 ], [ 1.077, 712.9 ],
      [ 1.317, 694.6 ], [ 1.556, 672.1 ], [ 1.796, 656.7 ], [ 2.035, 685.1 ], [ 2.275, 808.7 ],
      [ 2.515, 798.5 ], [ 2.754, 755.3 ], [ 2.995, 727 ], [ 3.235, 699.3 ], [ 3.475, 670 ],
      [ 3.715, 515.3 ], [ 3.954, 293.9 ], [ 4.194, 227.9 ], [ 4.433, 180.2 ], [ 4.673, 140.7 ],
      [ 4.912, 107.6 ], [ 5.152, 80.91 ], [ 5.392, 58.9 ], [ 5.631, 39.78 ], [ 5.872, 24.9 ],
      [ 6.113, 0 ]
],
    "totImpulseNs": 2994,
    "totalWeightG": 3948,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 540,
    "burnTimeS": 8.546,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L540",
    "dataFiles": 2,
    "designation": "2800CC172L-L540",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1387,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000172",
    "propWeightG": 2559,
    "samples": [
      [ 0, 0 ], [ 0.191, 685.5 ], [ 0.574, 665.2 ], [ 0.957, 635.5 ], [ 1.341, 634.1 ], [ 1.725, 656.2 ],
      [ 2.109, 706.9 ], [ 2.493, 696.5 ], [ 2.876, 777.7 ], [ 3.26, 775.9 ], [ 3.645, 781.6 ],
      [ 4.028, 712.7 ], [ 4.411, 695.6 ], [ 4.796, 701.3 ], [ 5.18, 646 ], [ 5.564, 607.8 ],
      [ 5.947, 546.4 ], [ 6.331, 387.4 ], [ 6.716, 234.2 ], [ 7.099, 181.1 ], [ 7.482, 139.3 ],
      [ 7.867, 106.1 ], [ 8.251, 78.29 ], [ 8.634, 54.85 ], [ 9.018, 36.38 ], [ 9.402, 20.38 ],
      [ 9.786, 0 ]
],
    "totImpulseNs": 4615,
    "totalWeightG": 5656,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 550,
    "burnTimeS": 5.627,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L550",
    "dataFiles": 1,
    "designation": "1685CCRGL-L550",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000173",
    "propWeightG": 1552,
    "samples": [
      [ 0, 0 ], [ 0.124, 816.8 ], [ 0.375, 796 ], [ 0.626, 781.9 ], [ 0.877, 767.4 ], [ 1.129, 759.6 ],
      [ 1.38, 735.9 ], [ 1.631, 714.5 ], [ 1.883, 701.6 ], [ 2.134, 674.7 ], [ 2.385, 656.5 ],
      [ 2.637, 636.1 ], [ 2.889, 612.4 ], [ 3.14, 587.8 ], [ 3.391, 567.2 ], [ 3.642, 560 ],
      [ 3.894, 534.2 ], [ 4.145, 444.6 ], [ 4.396, 280.5 ], [ 4.648, 216.7 ], [ 4.899, 163.1 ],
      [ 5.15, 120.6 ], [ 5.402, 86.54 ], [ 5.653, 59.99 ], [ 5.904, 39.53 ], [ 6.156, 25.91 ],
      [ 6.408, 0 ]
],
    "totImpulseNs": 3095,
    "totalWeightG": 3898,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 570,
    "burnTimeS": 8.273,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L570",
    "dataFiles": 2,
    "designation": "2800CC172LFX-L570FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1387,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000174",
    "propWeightG": 2608,
    "samples": [
      [ 0, 0 ], [ 0.181, 793.9 ], [ 0.547, 800 ], [ 0.914, 811.8 ], [ 1.28, 761.3 ], [ 1.647, 725.7 ],
      [ 2.014, 733.2 ], [ 2.38, 783.2 ], [ 2.747, 795.3 ], [ 3.112, 823.2 ], [ 3.478, 831.8 ],
      [ 3.845, 805.6 ], [ 4.211, 780.5 ], [ 4.578, 741.9 ], [ 4.945, 629 ], [ 5.311, 547.9 ],
      [ 5.678, 537.8 ], [ 6.044, 330.9 ], [ 6.409, 230.8 ], [ 6.776, 180.5 ], [ 7.143, 140.2 ],
      [ 7.509, 108.3 ], [ 7.876, 81.34 ], [ 8.243, 59.61 ], [ 8.609, 41.59 ], [ 8.976, 25.54 ],
      [ 9.343, 0 ]
],
    "totImpulseNs": 4716,
    "totalWeightG": 5706,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 575,
    "burnTimeS": 8.402,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L575",
    "dataFiles": 2,
    "designation": "2800CCRGL-L575",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1387,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000175",
    "propWeightG": 2553,
    "samples": [
      [ 0, 0 ], [ 0.19, 705.3 ], [ 0.572, 723.4 ], [ 0.955, 738.4 ], [ 1.337, 749.4 ], [ 1.72, 735.2 ],
      [ 2.103, 725.1 ], [ 2.486, 733.8 ], [ 2.869, 700.5 ], [ 3.251, 690.8 ], [ 3.634, 682.9 ],
      [ 4.017, 674.8 ], [ 4.399, 687.5 ], [ 4.782, 675.4 ], [ 5.166, 645.7 ], [ 5.548, 643.6 ],
      [ 5.93, 634.7 ], [ 6.314, 559.7 ], [ 6.696, 304 ], [ 7.078, 229.4 ], [ 7.461, 167.6 ],
      [ 7.845, 121 ], [ 8.227, 83.67 ], [ 8.609, 54.31 ], [ 8.993, 33.03 ], [ 9.376, 19.89 ],
      [ 9.759, 0 ]
],
    "totImpulseNs": 4831,
    "totalWeightG": 5653,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 600,
    "burnTimeS": 7.1,
    "caseInfo": "h 1858 cc",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L600",
    "dataFiles": 1,
    "designation": "L600",
    "diameter": 64,
    "impulseClass": "L",
    "length": 1066,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 2072,
    "motorId": "5f4294d20002310000000176",
    "propWeightG": 2200,
    "samples": [
      [ 0, 0 ], [ 0.001, 88.62 ], [ 0.021, 153.8 ], [ 0.191, 158 ], [ 0.351, 169.9 ], [ 0.401, 1127 ],
      [ 0.781, 1001 ], [ 2.231, 778.1 ], [ 2.841, 736.2 ], [ 3.071, 704 ], [ 3.621, 673.4 ],
      [ 3.811, 435.3 ], [ 3.891, 350.4 ], [ 4.141, 240.1 ], [ 4.311, 197.2 ], [ 4.491, 165.4 ],
      [ 4.661, 139.3 ], [ 4.821, 119.2 ], [ 5.011, 99.01 ], [ 5.341, 70.99 ], [ 5.931, 49.74 ],
      [ 6.491, 38.96 ], [ 6.981, 36.42 ], [ 7.101, 0 ]
],
    "totImpulseNs": 3152,
    "totalWeightG": 2200,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 600,
    "burnTimeS": 8,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L600",
    "dataFiles": 2,
    "designation": "L600",
    "diameter": 76,
    "impulseClass": "L",
    "length": 584,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 1056,
    "motorId": "5f4294d20002310000000177",
    "propWeightG": 2473,
    "samples": [
      [ 0, 0 ], [ 0.186, 829.7 ], [ 0.561, 773.9 ], [ 0.936, 767.8 ], [ 1.313, 755 ], [ 1.689, 736.5 ],
      [ 2.064, 722.7 ], [ 2.44, 706.2 ], [ 2.816, 688.3 ], [ 3.191, 673.5 ], [ 3.567, 661 ],
      [ 3.943, 648.1 ], [ 4.318, 634.7 ], [ 4.694, 622.1 ], [ 5.07, 608 ], [ 5.445, 594.9 ],
      [ 5.821, 583 ], [ 6.197, 573.1 ], [ 6.572, 553.5 ], [ 6.948, 399.4 ], [ 7.324, 270.4 ],
      [ 7.699, 211.4 ], [ 8.075, 144.2 ], [ 8.451, 74.23 ], [ 8.826, 19.38 ], [ 9.202, 4.274 ],
      [ 9.578, 0 ]
],
    "totImpulseNs": 4995,
    "totalWeightG": 4120,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 610,
    "burnTimeS": 5.133,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L610",
    "dataFiles": 1,
    "designation": "1685CCRGLFX-L610FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1031,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000178",
    "propWeightG": 1603,
    "samples": [
      [ 0, 0 ], [ 0.11, 850.8 ], [ 0.333, 837.7 ], [ 0.556, 775.1 ], [ 0.779, 739.7 ], [ 1.002, 807.3 ],
      [ 1.225, 809.5 ], [ 1.448, 801.8 ], [ 1.671, 789.5 ], [ 1.894, 763.8 ], [ 2.117, 858.1 ],
      [ 2.34, 890.6 ], [ 2.563, 837.6 ], [ 2.785, 749.6 ], [ 3.008, 649 ], [ 3.231, 643.1 ],
      [ 3.454, 637.4 ], [ 3.677, 431.3 ], [ 3.9, 276.2 ], [ 4.123, 220.9 ], [ 4.346, 166.6 ],
      [ 4.569, 124 ], [ 4.792, 89.72 ], [ 5.015, 64.3 ], [ 5.237, 45.36 ], [ 5.461, 30.4 ],
      [ 5.685, 0 ]
],
    "totImpulseNs": 3131,
    "totalWeightG": 3951,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 625,
    "burnTimeS": 7.921,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L625",
    "dataFiles": 2,
    "designation": "2800CCRGLFX-L625FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1387,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000179",
    "propWeightG": 2602,
    "samples": [
      [ 0, 0 ], [ 0.169, 855 ], [ 0.509, 896.2 ], [ 0.851, 905.9 ], [ 1.193, 902.1 ], [ 1.534, 853 ],
      [ 1.876, 953.2 ], [ 2.218, 904.2 ], [ 2.559, 856.5 ], [ 2.901, 743.5 ], [ 3.243, 758.6 ],
      [ 3.584, 751.6 ], [ 3.926, 745.7 ], [ 4.267, 751.9 ], [ 4.607, 728.3 ], [ 4.949, 691.7 ],
      [ 5.291, 658.8 ], [ 5.632, 504.4 ], [ 5.974, 279.7 ], [ 6.316, 216.7 ], [ 6.657, 165 ],
      [ 6.999, 123.4 ], [ 7.341, 89.43 ], [ 7.682, 62.95 ], [ 8.024, 43.52 ], [ 8.366, 27.96 ],
      [ 8.707, 0 ]
],
    "totImpulseNs": 4951,
    "totalWeightG": 5706,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 666,
    "burnTimeS": 3.97,
    "caseInfo": "AMW 75-3500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L666",
    "dataFiles": 2,
    "designation": "SK-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "length": 870,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000017a",
    "propInfo": "Skidmark",
    "propWeightG": 1647,
    "samples": [
      [ 0, 0 ], [ 0.096, 105.9 ], [ 0.175, 509.8 ], [ 0.312, 549.5 ], [ 0.449, 577.3 ], [ 0.586, 602.9 ],
      [ 0.722, 615.6 ], [ 0.859, 632.5 ], [ 0.996, 652.1 ], [ 1.133, 671.4 ], [ 1.27, 685.7 ],
      [ 1.407, 701.3 ], [ 1.543, 718.1 ], [ 1.68, 734.1 ], [ 1.817, 753.3 ], [ 1.954, 771.6 ],
      [ 2.091, 790.5 ], [ 2.228, 819.2 ], [ 2.364, 846.7 ], [ 2.501, 874.6 ], [ 2.638, 890.1 ],
      [ 2.775, 898.3 ], [ 2.912, 899.3 ], [ 3.049, 881.7 ], [ 3.185, 845.2 ], [ 3.322, 768.5 ],
      [ 3.459, 672.8 ], [ 3.596, 525.5 ], [ 3.733, 304.7 ], [ 3.87, 86.66 ], [ 3.968, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2700,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 700,
    "burnTimeS": 3.27,
    "caseInfo": "AMW 75-2500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L700",
    "dataFiles": 2,
    "designation": "BB-75-2500",
    "diameter": 75,
    "impulseClass": "L",
    "length": 368,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000017b",
    "propInfo": "Blue Baboon",
    "propWeightG": 1194,
    "samples": [
      [ 0, 0 ], [ 0.02, 221.9 ], [ 0.03, 399.3 ], [ 0.05, 467.6 ], [ 0.08, 494.9 ], [ 0.13, 498.4 ],
      [ 0.24, 536 ], [ 0.48, 614.7 ], [ 0.77, 683.2 ], [ 1.23, 755.3 ], [ 1.62, 789.7 ], [ 1.92, 810.4 ],
      [ 2.26, 821.1 ], [ 2.58, 817.9 ], [ 2.91, 801.1 ], [ 3.14, 773.9 ], [ 3.25, 750.1 ],
      [ 3.32, 743.4 ], [ 3.37, 729.8 ], [ 3.42, 688.8 ], [ 3.46, 593.4 ], [ 3.5, 484.1 ],[ 3.53, 368.2 ],
      [ 3.57, 248.8 ], [ 3.62, 149.8 ], [ 3.66, 61.13 ], [ 3.72, 0 ]
],
    "totImpulseNs": 2590,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 733,
    "burnTimeS": 3.77,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L730",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2765L730-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1215,
    "motorId": "5f4294d2000231000000017c",
    "propInfo": "Classic",
    "propWeightG": 1351,
    "samples": [
      [ 0, 0 ], [ 0.001, 81.36 ], [ 0.011, 1080 ], [ 0.021, 1217 ], [ 0.041, 1155 ], [ 0.201, 1128 ],
      [ 0.451, 1055 ], [ 0.601, 1028 ], [ 0.751, 995.2 ], [ 1.001, 959.3 ], [ 1.501, 898.7 ],
      [ 2.001, 830.7 ], [ 2.501, 730.8 ], [ 2.601, 592.5 ], [ 2.701, 511 ], [ 2.901, 487.9 ],
      [ 3.001, 405.7 ], [ 3.101, 299.8 ], [ 3.201, 296.1 ], [ 3.301, 251.8 ], [ 3.401, 171.7 ],
      [ 3.501, 165.3 ], [ 3.601, 139.4 ], [ 3.651, 117.8 ], [ 3.771, 45.38 ], [ 3.771, 0 ]
],
    "totImpulseNs": 2765,
    "totalWeightG": 2247,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 740,
    "burnTimeS": 6.801,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L740",
    "dataFiles": 1,
    "designation": "2800CC200MFX-L740FX",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000017d",
    "propWeightG": 2598,
    "samples": [
      [ 0, 0 ], [ 0.02, 767.8 ], [ 0.05, 1085 ], [ 0.07, 1167 ], [ 0.09, 1199 ], [ 0.12, 1183 ],
      [ 0.38, 1089 ], [ 0.63, 1140 ], [ 0.89, 1131 ], [ 1.15, 1109 ], [ 1.4, 1097 ], [ 1.66, 1049 ],
      [ 1.92, 1027 ], [ 2.18, 980.7 ], [ 2.43, 949.7 ], [ 2.69, 909.6 ], [ 2.95, 893.6 ],[ 3.21, 866.6 ],
      [ 3.46, 825.3 ], [ 3.72, 820 ], [ 3.98, 789.8 ], [ 4.24, 874.1 ], [ 4.49, 804.4 ], [ 4.75, 738 ],
      [ 5.01, 383.8 ], [ 5.38, 255.8 ], [ 5.75, 183.3 ], [ 6.13, 130.5 ], [ 6.5, 94.03 ],[ 6.8, 0 ]
],
    "totImpulseNs": 5033,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 774.5,
    "burnTimeS": 4.05,
    "caseInfo": "AMW 75-3500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L777",
    "dataFiles": 3,
    "designation": "WT-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L777.pdf",
    "length": 497,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1000,
    "motorId": "5f4294d2000231000000017e",
    "propInfo": "White Wolf",
    "propWeightG": 1762,
    "samples": [
      [ 0, 0 ], [ 0.025, 140.9 ], [ 0.064, 209.5 ], [ 0.108, 360.1 ], [ 0.204, 652.2 ], [ 0.36, 641.5 ],
      [ 0.373, 693.7 ], [ 0.418, 683.3 ], [ 0.528, 730.1 ], [ 0.67, 761.3 ], [ 0.761, 782 ],
      [ 0.787, 802.8 ], [ 0.871, 802.7 ], [ 1.065, 854.8 ], [ 1.338, 911.8 ], [ 1.668, 963.6 ],
      [ 1.914, 989.5 ], [ 2.115, 1000 ], [ 2.368, 962.8 ], [ 2.647, 926 ], [ 2.985, 878.6 ],
      [ 3.303, 805.1 ], [ 3.472, 752.8 ], [ 3.55, 705.7 ], [ 3.602, 648.3 ], [ 3.647, 611.6 ],
      [ 3.693, 512.4 ], [ 3.779, 334.9 ], [ 3.857, 178.2 ], [ 3.935, 89.38 ], [ 3.981, 26.69 ],
      [ 4.05, 0 ]
],
    "totImpulseNs": 3137,
    "totalWeightG": 3699,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 804,
    "burnTimeS": 4.67,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L800",
    "dataFiles": 3,
    "designation": "3757L800-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1286,
    "motorId": "5f4294d2000231000000017f",
    "propInfo": "Classic",
    "propWeightG": 1795,
    "samples": [
      [ 0, 0 ], [ 0.001, 27.28 ], [ 0.011, 402.4 ], [ 0.011, 1286 ], [ 0.121, 1057 ], [ 0.261, 1042 ],
      [ 0.711, 1027 ], [ 1.281, 998.4 ], [ 2.051, 901.4 ], [ 2.411, 849.6 ], [ 2.831, 763.5 ],
      [ 3.251, 707.1 ], [ 3.651, 655.1 ], [ 3.801, 651.7 ], [ 4.001, 624.1 ], [ 4.101, 601.3 ],
      [ 4.191, 536.2 ], [ 4.311, 415.7 ], [ 4.411, 270.2 ], [ 4.521, 140.2 ], [ 4.601, 76.92 ],
      [ 4.651, 54.94 ], [ 4.671, 40.16 ], [ 5.001, 0 ]
],
    "totImpulseNs": 3757,
    "totalWeightG": 3511,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 813,
    "burnTimeS": 4.6,
    "caseInfo": "75 54 M BG (3200CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L800",
    "dataFiles": 2,
    "designation": "L800-BG",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1372,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1608,
    "motorId": "5f4294d20002310000000180",
    "propWeightG": 3200,
    "samples": [
      [ 0, 0 ], [ 0.005593, 1352 ], [ 0.1678, 1129 ], [ 0.33, 1266 ], [ 0.5537, 1249 ], [ 0.6655, 1129 ],
      [ 3.484, 821.2 ], [ 3.596, 496.1 ], [ 3.697, 273.7 ], [ 3.837, 154 ], [ 4.6, 0 ]
],
    "totImpulseNs": 3759,
    "totalWeightG": 4726,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 850,
    "burnTimeS": 4.42,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L850",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L850W",
    "diameter": 75,
    "impulseClass": "L",
    "length": 531,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1866,
    "motorId": "5f4294d20002310000000181",
    "propInfo": "White Lightning",
    "propWeightG": 2095,
    "samples": [
      [ 0, 0 ], [ 0.091, 1016 ], [ 0.274, 1065 ], [ 0.458, 1101 ], [ 0.643, 1143 ], [ 0.827, 1171 ],
      [ 1.011, 1185 ], [ 1.196, 1178 ], [ 1.38, 1178 ], [ 1.564, 1175 ], [ 1.748, 1170 ],[ 1.932, 1114 ],
      [ 2.117, 1043 ], [ 2.301, 972.8 ], [ 2.485, 908.1 ], [ 2.67, 844.5 ], [ 2.854, 773.6 ],
      [ 3.039, 714 ], [ 3.222, 649.1 ], [ 3.406, 597.3 ], [ 3.591, 557.4 ], [ 3.775, 422.2 ],
      [ 3.959, 200.7 ], [ 4.144, 79.41 ], [ 4.328, 43.96 ], [ 4.513, 14.86 ], [ 4.697, 0 ]
],
    "totImpulseNs": 3646,
    "totalWeightG": 3742,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 890,
    "burnTimeS": 4.22,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L890",
    "dataFiles": 2,
    "designation": "3762L890-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 530,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1228,
    "motorId": "5f4294d20002310000000182",
    "propWeightG": 2580,
    "samples": [
      [ 0, 0 ], [ 0.001, 20 ], [ 0.051, 1152 ], [ 0.411, 1054 ], [ 0.831, 1045 ], [ 1.241, 1037 ],
      [ 1.651, 1028 ], [ 2.071, 1019 ], [ 2.891, 886 ], [ 3.311, 775.3 ], [ 3.721, 664.5 ],
      [ 3.981, 177.2 ], [ 4.131, 0 ]
],
    "totImpulseNs": 3762,
    "totalWeightG": 4346,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 900,
    "burnTimeS": 3.78,
    "caseInfo": "AMW 75-3500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L900",
    "dataFiles": 2,
    "designation": "RR-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L900.pdf",
    "length": 497,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000183",
    "propInfo": "Red Rhino",
    "propWeightG": 1771,
    "samples": [
      [ 0, 0 ], [ 0.029, 464.3 ], [ 0.053, 630.9 ], [ 0.059, 684.5 ], [ 0.096, 702.3 ], [ 0.133, 696.4 ],
      [ 0.201, 714.3 ], [ 0.486, 803.5 ], [ 0.777, 910.7 ], [ 1.099, 988.1 ], [ 1.26, 1041 ],
      [ 1.284, 1071 ], [ 1.378, 1053 ], [ 1.607, 1102 ], [ 1.917, 1143 ], [ 2.208, 1174 ],
      [ 2.413, 1161 ], [ 2.624, 1108 ], [ 2.866, 976.2 ], [ 3.053, 886.9 ], [ 3.208, 839.3 ],
      [ 3.314, 827.4 ], [ 3.382, 809.5 ], [ 3.432, 720.3 ], [ 3.495, 547.6 ], [ 3.57, 345.3 ],
      [ 3.627, 214.3 ], [ 3.714, 77.38 ], [ 3.79, 0 ]
],
    "totImpulseNs": 3450,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 930,
    "burnTimeS": 3.79,
    "caseInfo": "76/3600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L930",
    "dataFiles": 2,
    "designation": "L930-LW",
    "diameter": 76,
    "impulseClass": "L",
    "length": 499,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1137,
    "motorId": "5f4294d20002310000000184",
    "propInfo": "Loki White",
    "propWeightG": 1905,
    "samples": [
      [ 0, 0 ], [ 0.025, 532 ], [ 0.05, 1123 ], [ 0.075, 1123 ], [ 0.125, 1094 ], [ 0.2, 930 ],
      [ 0.5, 881 ], [ 0.6, 878 ], [ 0.75, 898 ], [ 1, 921 ], [ 1.25, 940 ], [ 1.5, 1012 ], [ 1.75, 1081 ],
      [ 2, 1100 ], [ 2.25, 1120 ], [ 2.5, 1051 ], [ 2.75, 980 ], [ 3, 934 ], [ 3.25, 826 ],[ 3.5, 722 ],
      [ 3.75, 280 ], [ 4, 0 ]
],
    "totImpulseNs": 3587,
    "totalWeightG": 3538,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 952,
    "burnTimeS": 6.15,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L952",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L952W",
    "diameter": 98,
    "impulseClass": "L",
    "length": 427,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1022,
    "motorId": "5f4294d20002310000000185",
    "propInfo": "White Lightning",
    "propWeightG": 2750,
    "samples": [
      [ 0, 0 ], [ 0.141, 679.1 ], [ 0.425, 801.6 ], [ 0.709, 848.5 ], [ 0.994, 913.3 ], [ 1.278, 981.6 ],
      [ 1.562, 1044 ], [ 1.847, 1088 ], [ 2.131, 1113 ], [ 2.416, 1122 ], [ 2.7, 1119 ], [ 2.984, 1101 ],
      [ 3.269, 1039 ], [ 3.553, 965.8 ], [ 3.837, 876.8 ], [ 4.122, 780.7 ], [ 4.406, 693.9 ],
      [ 4.691, 608 ], [ 4.975, 528.3 ], [ 5.259, 463.5 ], [ 5.544, 405.8 ], [ 5.828, 358.4 ],
      [ 6.112, 279 ], [ 6.397, 99.9 ], [ 6.681, 20.11 ], [ 6.967, 3.317 ], [ 7.252, 0 ]
],
    "totImpulseNs": 4656,
    "totalWeightG": 5012,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 970,
    "burnTimeS": 5.256,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L970",
    "dataFiles": 1,
    "designation": "2800CC300M-L970",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000186",
    "propWeightG": 2537,
    "samples": [
      [ 0, 0 ], [ 0.01, 534.3 ], [ 0.02, 1008 ], [ 0.03, 1321 ], [ 0.04, 1463 ], [ 0.05, 1482 ],
      [ 0.25, 1316 ], [ 0.44, 1363 ], [ 0.64, 1441 ], [ 0.84, 1453 ], [ 1.04, 1418 ], [ 1.23, 1404 ],
      [ 1.43, 1338 ], [ 1.63, 1311 ], [ 1.83, 1257 ], [ 2.02, 1279 ], [ 2.22, 1230 ], [ 2.42, 1174 ],
      [ 2.62, 1163 ], [ 2.81, 1122 ], [ 3.02, 1109 ], [ 3.21, 1058 ], [ 3.41, 981.2 ], [ 3.61, 959.4 ],
      [ 3.8, 778.8 ], [ 4.09, 437.6 ], [ 4.38, 294.3 ], [ 4.66, 194.7 ], [ 4.94, 129.3 ],[ 5.23, 86.31 ],
      [ 5.23, 0 ]
],
    "totImpulseNs": 5098,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1000,
    "burnTimeS": 2.6,
    "caseInfo": "54-2550 (K1100)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1000",
    "dataFiles": 2,
    "designation": "L1000S",
    "diameter": 54,
    "impulseClass": "L",
    "length": 728,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 1738,
    "motorId": "5f4294d20002310000000187",
    "propWeightG": 1301,
    "samples": [
      [ 0, 0 ], [ 0.055, 795.3 ], [ 0.175, 981.6 ], [ 0.295, 989.2 ], [ 0.415, 1009 ], [ 0.535, 1029 ],
      [ 0.655, 1048 ], [ 0.775, 1068 ], [ 0.895, 1087 ], [ 1.015, 1109 ], [ 1.135, 1132 ],
      [ 1.255, 1157 ], [ 1.375, 1177 ], [ 1.498, 1200 ], [ 1.62, 1213 ], [ 1.74, 1227 ], [ 1.86, 1232 ],
      [ 1.98, 1250 ], [ 2.1, 1026 ], [ 2.22, 737.1 ], [ 2.34, 565.9 ], [ 2.46, 313.4 ], [ 2.58, 89.71 ],
      [ 2.7, 20.76 ], [ 2.82, 8.526 ], [ 2.942, 5.338 ], [ 3.065, 0 ]
],
    "totImpulseNs": 2592,
    "totalWeightG": 2325,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1065,
    "burnTimeS": 3.4,
    "caseInfo": "AMW 75-3500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L1060",
    "dataFiles": 3,
    "designation": "GG-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L1060.pdf",
    "length": 497,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1298,
    "motorId": "5f4294d20002310000000188",
    "propInfo": "Green Gorilla",
    "propWeightG": 1919,
    "samples": [
      [ 0, 0 ], [ 0.02, 258.8 ], [ 0.024, 368.2 ], [ 0.032, 328.4 ], [ 0.076, 428 ], [ 0.1, 567.3 ],
      [ 0.116, 751.4 ], [ 0.128, 791.2 ], [ 0.169, 816.1 ], [ 0.225, 816.1 ], [ 0.309, 875.8 ],
      [ 0.518, 985.3 ], [ 0.763, 1080 ], [ 1.024, 1175 ], [ 1.308, 1229 ], [ 1.606, 1288 ],
      [ 1.782, 1298 ], [ 1.983, 1293 ], [ 2.256, 1239 ], [ 2.525, 1185 ], [ 2.822, 1130 ],
      [ 3.038, 1070 ], [ 3.111, 1045 ], [ 3.135, 995.1 ], [ 3.183, 835.9 ], [ 3.239, 552.3 ],
      [ 3.299, 268.7 ], [ 3.327, 164.2 ], [ 3.339, 84.59 ], [ 3.36, 44.78 ], [ 3.4, 0 ]
],
    "totImpulseNs": 3623,
    "totalWeightG": 3939,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1080,
    "burnTimeS": 3.37,
    "caseInfo": "AMW 75-3500",
    "certOrg": "National Association of Rocketry",
    "commonName": "L1080",
    "dataFiles": 2,
    "designation": "BB-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L1080.pdf",
    "length": 497,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000189",
    "propInfo": "Blue Baboon",
    "propWeightG": 1717,
    "samples": [
      [ 0, 0 ], [ 0.024, 406.3 ], [ 0.043, 812.5 ], [ 0.052, 895.2 ], [ 0.088, 929.6 ], [ 0.314, 991.5 ],
      [ 0.626, 1088 ], [ 0.988, 1163 ], [ 1.346, 1219 ], [ 1.638, 1246 ], [ 1.864, 1258 ],
      [ 2.247, 1255 ], [ 2.6, 1219 ], [ 2.766, 1212 ], [ 2.851, 1198 ], [ 2.942, 1205 ], [ 3.002, 1226 ],
      [ 3.033, 1205 ], [ 3.089, 1040 ], [ 3.124, 874.5 ], [ 3.15, 661 ], [ 3.191, 461.3 ],
      [ 3.232, 275.4 ], [ 3.268, 144.6 ], [ 3.303, 75.74 ], [ 3.339, 41.32 ], [ 3.39, 0 ]
],
    "totImpulseNs": 3700,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1090,
    "burnTimeS": 4.43,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1090",
    "dataFiles": 2,
    "designation": "4815L1090-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1702,
    "motorId": "5f4294d2000231000000018a",
    "propWeightG": 3440,
    "samples": [
      [ 0, 0 ], [ 0.001, 487.3 ], [ 0.111, 1639 ], [ 0.221, 1484 ], [ 0.441, 1418 ], [ 0.871, 1373 ],
      [ 1.311, 1329 ], [ 1.741, 1307 ], [ 2.181, 1263 ], [ 2.611, 1218 ], [ 3.051, 1152 ],
      [ 3.211, 775.3 ], [ 3.481, 598 ], [ 3.921, 553.8 ], [ 4.131, 221.5 ], [ 4.351, 0 ]
],
    "totImpulseNs": 4815,
    "totalWeightG": 5461,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1100,
    "burnTimeS": 2.3,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "L1100",
    "dataFiles": 2,
    "designation": "RR-54-2550",
    "diameter": 54,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L1100.pdf",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d2000231000000018b",
    "propInfo": "Red Rhino",
    "propWeightG": 1346,
    "samples": [
      [ 0, 0 ], [ 0.013, 681.5 ], [ 0.029, 1117 ], [ 0.041, 1196 ], [ 0.079, 1210 ], [ 0.147, 1203 ],
      [ 0.257, 1218 ], [ 0.366, 1225 ], [ 0.567, 1255 ], [ 0.824, 1283 ], [ 1.059, 1312 ],
      [ 1.267, 1340 ], [ 1.459, 1312 ], [ 1.622, 1298 ], [ 1.713, 1291 ], [ 1.785, 1269 ],
      [ 1.83, 1218 ], [ 1.886, 1081 ], [ 1.969, 819.2 ], [ 2.048, 558.2 ], [ 2.108, 377 ],
      [ 2.156, 246.5 ], [ 2.205, 145 ], [ 2.269, 72.5 ], [ 2.35, 0 ]
],
    "totImpulseNs": 2600,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1111,
    "burnTimeS": 3.14,
    "caseInfo": "AMW 75-3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1111",
    "dataFiles": 2,
    "designation": "ST-75-3500",
    "diameter": 75,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1379,
    "motorId": "5f4294d2000231000000018c",
    "propInfo": "Super Tiger",
    "propWeightG": 1642,
    "samples": [
      [ 0, 0 ], [ 0.015, 1024 ], [ 0.1, 924.9 ], [ 0.147, 902.9 ], [ 0.502, 1035 ], [ 0.75, 1156 ],
      [ 1.005, 1266 ], [ 1.229, 1354 ], [ 1.492, 1398 ], [ 1.739, 1398 ], [ 2.009, 1354 ],
      [ 2.272, 1244 ], [ 2.504, 1123 ], [ 2.728, 968.9 ], [ 2.782, 902.9 ], [ 2.836, 770.7 ],
      [ 2.98, 363.3 ], [ 3.053, 99.09 ], [ 3.083, 22.02 ], [ 3.14, 0 ]
],
    "totImpulseNs": 3480,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1119,
    "burnTimeS": 4.48,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1115",
    "dataFiles": 3,
    "designation": "5015L1115-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1713,
    "motorId": "5f4294d2000231000000018d",
    "propInfo": "Classic",
    "propWeightG": 2394,
    "samples": [
      [ 0, 0 ], [ 0.1, 1469 ], [ 0.3, 1491 ], [ 0.8, 1402 ], [ 1, 1437 ], [ 1.5, 1335 ], [ 2, 1268 ],
      [ 2.2, 1246 ], [ 2.5, 1113 ], [ 3, 1090 ], [ 3.3, 979 ], [ 3.8, 979 ], [ 4, 623 ], [ 4.2, 311.5 ],
      [ 4.4, 35 ], [ 4.48, 0 ]
],
    "totImpulseNs": 5015,
    "totalWeightG": 4404,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1120,
    "burnTimeS": 4.43,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1120",
    "dataFiles": 2,
    "designation": "L1120W",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1794,
    "motorId": "5f4294d2000231000000018e",
    "propInfo": "White Lightning",
    "propWeightG": 2778,
    "samples": [
      [ 0, 0 ], [ 0.097, 1377 ], [ 0.293, 1443 ], [ 0.489, 1497 ], [ 0.685, 1537 ], [ 0.882, 1555 ],
      [ 1.078, 1554 ], [ 1.275, 1548 ], [ 1.472, 1533 ], [ 1.668, 1510 ], [ 1.865, 1472 ],
      [ 2.061, 1363 ], [ 2.257, 1245 ], [ 2.454, 1149 ], [ 2.651, 1063 ], [ 2.847, 985 ],[ 3.044, 916.2 ],
      [ 3.241, 831.9 ], [ 3.436, 766.5 ], [ 3.633, 699 ], [ 3.83, 563 ], [ 4.026, 384.6 ],
      [ 4.223, 227.7 ], [ 4.42, 105.1 ], [ 4.616, 56.34 ], [ 4.813, 21.71 ], [ 5.009, 0 ]
],
    "totImpulseNs": 4947,
    "totalWeightG": 4658,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1150,
    "burnTimeS": 3.07,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1150",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1150R",
    "diameter": 75,
    "impulseClass": "L",
    "length": 530,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1346,
    "motorId": "5f4294d2000231000000018f",
    "propInfo": "Redline",
    "propWeightG": 1902,
    "samples": [
      [ 0, 0 ], [ 0.053, 935.9 ], [ 0.175, 1293 ], [ 0.3, 1261 ], [ 0.425, 1241 ], [ 0.55, 1257 ],
      [ 0.675, 1272 ], [ 0.8, 1288 ], [ 0.925, 1301 ], [ 1.048, 1310 ], [ 1.17, 1308 ], [ 1.295, 1305 ],
      [ 1.42, 1285 ], [ 1.545, 1268 ], [ 1.67, 1256 ], [ 1.795, 1227 ], [ 1.92, 1202 ], [ 2.043, 1183 ],
      [ 2.165, 1151 ], [ 2.29, 1118 ], [ 2.415, 1082 ], [ 2.54, 1038 ], [ 2.665, 1007 ], [ 2.79, 1009 ],
      [ 2.915, 643.1 ], [ 3.04, 64.37 ], [ 3.165, 0 ]
],
    "totImpulseNs": 3517,
    "totalWeightG": 3674,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1173,
    "burnTimeS": 3.1,
    "caseInfo": "75x1524 (3200CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1222",
    "dataFiles": 2,
    "designation": "L1222-SM",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1371,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 2892,
    "motorId": "5f4294d20002310000000190",
    "propWeightG": 786,
    "samples": [
      [ 0, 0 ], [ 0.001, 455 ], [ 0.251, 455 ], [ 0.501, 2725 ], [ 0.751, 1816 ], [ 2.751, 680 ],
      [ 3.101, 0 ]
],
    "sparky": true,
    "totImpulseNs": 3682,
    "totalWeightG": 4989,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1300,
    "burnTimeS": 2,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "L1300",
    "dataFiles": 2,
    "designation": "BB-54-2550",
    "diameter": 54,
    "impulseClass": "L",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/L1300.pdf",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d20002310000000191",
    "propInfo": "Blue Baboon",
    "propWeightG": 1314,
    "samples": [
      [ 0, 0 ], [ 0.014, 710.5 ], [ 0.025, 1248 ], [ 0.039, 1384 ], [ 0.053, 1448 ], [ 0.074, 1421 ],
      [ 0.12, 1448 ], [ 0.276, 1474 ], [ 0.475, 1520 ], [ 0.712, 1555 ], [ 0.942, 1587 ],[ 1.147, 1562 ],
      [ 1.36, 1535 ], [ 1.484, 1552 ], [ 1.537, 1552 ], [ 1.569, 1497 ], [ 1.59, 1406 ], [ 1.604, 1452 ],
      [ 1.615, 1334 ], [ 1.64, 1169 ], [ 1.689, 986.7 ], [ 1.753, 767.7 ], [ 1.824, 512.5 ],
      [ 1.891, 275.5 ], [ 1.933, 147.8 ], [ 1.987, 74.74 ], [ 2.06, 0 ]
],
    "totImpulseNs": 2675,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1300,
    "burnTimeS": 3.513,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1300R",
    "diameter": 98,
    "impulseClass": "L",
    "length": 443,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1552,
    "motorId": "5f4294d20002310000000192",
    "propInfo": "Redline",
    "propWeightG": 2632,
    "samples": [
      [ 0, 0 ], [ 0.034, 16.21 ], [ 0.039, 275.6 ], [ 0.044, 563.4 ], [ 0.054, 1127 ], [ 0.058, 591.7 ],
      [ 0.131, 1188 ], [ 0.248, 1256 ], [ 0.511, 1370 ], [ 0.759, 1435 ], [ 1.002, 1496 ],
      [ 1.251, 1532 ], [ 1.372, 1536 ], [ 1.504, 1528 ], [ 1.757, 1516 ], [ 2.01, 1487 ],[ 2.263, 1423 ],
      [ 2.418, 1402 ], [ 2.501, 1350 ], [ 2.672, 1297 ], [ 2.759, 1265 ], [ 2.847, 1208 ],
      [ 3.007, 1123 ], [ 3.085, 1094 ], [ 3.148, 1050 ], [ 3.182, 1034 ], [ 3.251, 1038 ],
      [ 3.304, 1062 ], [ 3.333, 1106 ], [ 3.372, 887.6 ], [ 3.382, 672.8 ], [ 3.406, 445.8 ],
      [ 3.411, 316.1 ], [ 3.431, 222.9 ], [ 3.455, 141.9 ], [ 3.479, 56.74 ], [ 3.552, 0 ]
],
    "totImpulseNs": 4567,
    "totalWeightG": 4884,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1400,
    "burnTimeS": 2,
    "caseInfo": "54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1400",
    "dataFiles": 2,
    "designation": "L1400-LW",
    "diameter": 54,
    "impulseClass": "L",
    "length": 736,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 2711,
    "motorId": "5f4294d20002310000000193",
    "propInfo": "Loki White",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.0058, 1606 ], [ 0.1189, 1536 ], [ 0.3277, 1536 ], [ 0.4988, 1589 ], [ 1.001, 1783 ],
      [ 1.407, 1906 ], [ 1.499, 1377 ], [ 1.607, 953.2 ], [ 1.746, 547.2 ], [ 1.905, 335.4 ],
      [ 1.998, 211.8 ], [ 2, 0 ]
],
    "totImpulseNs": 2851,
    "totalWeightG": 2540,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1400,
    "burnTimeS": 1.88,
    "caseInfo": "54-2550 (K1400)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1400",
    "dataFiles": 2,
    "designation": "L1400F",
    "diameter": 54,
    "impulseClass": "L",
    "length": 728,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 1989,
    "motorId": "5f4294d20002310000000194",
    "propWeightG": 1248,
    "samples": [
      [ 0, 0 ], [ 0.037, 1541 ], [ 0.061, 1453 ], [ 0.166, 1354 ], [ 1.001, 1773 ], [ 1.279, 1784 ],
      [ 1.329, 1883 ], [ 1.387, 1993 ], [ 1.486, 1387 ], [ 1.604, 869.8 ], [ 1.65, 748.7 ],
      [ 1.666, 726.7 ], [ 1.69, 924.9 ], [ 1.697, 594.6 ], [ 1.758, 319.3 ], [ 1.88, 0 ]
],
    "totImpulseNs": 2640,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1420,
    "burnTimeS": 3.24,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1420",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1420R",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1814,
    "motorId": "5f4294d20002310000000195",
    "propInfo": "Redline",
    "propWeightG": 2560,
    "samples": [
      [ 0, 0 ], [ 0.03864, 1332 ], [ 0.1236, 1563 ], [ 0.5023, 1519 ], [ 0.9969, 1574 ], [ 1.499, 1663 ],
      [ 2.002, 1574 ], [ 2.481, 1409 ], [ 2.921, 1299 ], [ 2.991, 1167 ], [ 3.114, 187.2 ],
      [ 3.24, 0 ]
],
    "totImpulseNs": 4603,
    "totalWeightG": 4562,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1500,
    "burnTimeS": 3.47,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1500",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1500T",
    "diameter": 98,
    "impulseClass": "L",
    "length": 443,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1752,
    "motorId": "5f4294d20002310000000196",
    "propInfo": "Blue Thunder",
    "propWeightG": 2491,
    "samples": [
      [ 0, 0 ], [ 0.073, 1320 ], [ 0.222, 1455 ], [ 0.372, 1509 ], [ 0.522, 1557 ], [ 0.672, 1602 ],
      [ 0.822, 1642 ], [ 0.971, 1670 ], [ 1.12, 1695 ], [ 1.27, 1701 ], [ 1.42, 1704 ], [ 1.57, 1701 ],
      [ 1.72, 1695 ], [ 1.869, 1684 ], [ 2.018, 1660 ], [ 2.168, 1620 ], [ 2.318, 1570 ],[ 2.468, 1518 ],
      [ 2.618, 1463 ], [ 2.767, 1401 ], [ 2.916, 1331 ], [ 3.066, 1279 ], [ 3.216, 1109 ],
      [ 3.366, 217.8 ], [ 3.516, 10.58 ], [ 3.666, 3.245 ], [ 3.816, 0 ]
],
    "totImpulseNs": 5089,
    "totalWeightG": 4659,
    "type": "reload",
    "updatedOn": "2021-10-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 542,
    "burnTimeS": 13.81,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M520",
    "dataFiles": 2,
    "designation": "7400M520-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 548,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1184,
    "motorId": "5f4294d20002310000000197",
    "propInfo": "Classic",
    "propWeightG": 3658,
    "samples": [
      [ 0, 0 ], [ 0.01, 1077 ], [ 0.25, 1063 ], [ 0.38, 1066 ], [ 0.5, 971 ], [ 0.71, 938.1 ],
      [ 0.93, 915.5 ], [ 1.23, 878.6 ], [ 2.07, 907 ], [ 2.61, 901.3 ], [ 3.03, 892.8 ], [ 3.5, 872.9 ],
      [ 3.93, 836.1 ], [ 4.96, 756.7 ], [ 6.08, 657.5 ], [ 7.05, 549.8 ], [ 7.79, 462 ], [ 8.39, 391.1 ],
      [ 9.06, 323.1 ], [ 10.01, 243.7 ], [ 11.01, 172.9 ], [ 12, 116.2 ], [ 13.95, 0 ]
],
    "totImpulseNs": 7441,
    "totalWeightG": 6693,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 762.4,
    "burnTimeS": 7.22,
    "caseInfo": "75mm X1371mm (3200 cc)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M711",
    "dataFiles": 2,
    "designation": "M711-BS",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1371,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1872,
    "motorId": "5f4294d20002310000000198",
    "propWeightG": 727,
    "samples": [
      [ 0, 0 ], [ 0.001, 1140 ], [ 1.468, 1070 ], [ 4.001, 680 ], [ 6.474, 589.1 ], [ 6.675, 279.1 ],
      [ 7.221, 0 ]
],
    "totImpulseNs": 5507,
    "totalWeightG": 4900,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 740,
    "burnTimeS": 6.95,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M740",
    "dataFiles": 1,
    "designation": "2800CC200M-M740",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d20002310000000199",
    "propWeightG": 2589,
    "samples": [
      [ 0, 0 ], [ 0.04, 979.3 ], [ 0.08, 1136 ], [ 0.12, 1066 ], [ 0.16, 1027 ], [ 0.2, 1023 ],
      [ 0.44, 982.9 ], [ 0.68, 1066 ], [ 0.91, 1101 ], [ 1.15, 1067 ], [ 1.39, 1073 ], [ 1.63, 1013 ],
      [ 1.87, 1017 ], [ 2.11, 1012 ], [ 2.35, 1007 ], [ 2.58, 968.1 ], [ 2.82, 948.7 ], [ 3.06, 944 ],
      [ 3.3, 905.5 ], [ 3.54, 899.5 ], [ 3.77, 866.2 ], [ 4.02, 847.5 ], [ 4.25, 822.7 ],[ 4.49, 814 ],
      [ 4.73, 789.3 ], [ 4.97, 752.5 ], [ 5.21, 344.8 ], [ 5.45, 271.9 ], [ 5.84, 195.2 ],
      [ 6.46, 108.8 ], [ 6.97, 0 ]
],
    "totImpulseNs": 5143,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 845,
    "burnTimeS": 7.29,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M845",
    "dataFiles": 2,
    "designation": "M845H",
    "diameter": 98,
    "impulseClass": "M",
    "length": 782,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d2000231000000019a",
    "propWeightG": 3433,
    "samples": [
      [ 0, 0 ], [ 0.01546, 1332 ], [ 0.04637, 1707 ], [ 0.07728, 1178 ], [ 0.1855, 1310 ],
      [ 0.9737, 1222 ], [ 1.515, 1200 ], [ 1.978, 1123 ], [ 3.972, 1057 ], [ 4.204, 880.8 ],
      [ 6.012, 627.6 ], [ 6.495, 418.4 ], [ 7.017, 99.09 ], [ 7.5, 0 ]
],
    "totImpulseNs": 6159,
    "type": "hybrid",
    "updatedOn": "2022-06-15"
  },
  {
    "availability": "regular",
    "avgThrustN": 900,
    "burnTimeS": 12.3,
    "caseInfo": "h 3716 cc (M700)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M900",
    "dataFiles": 1,
    "designation": "M900",
    "diameter": 64,
    "impulseClass": "M",
    "length": 1828,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 1945,
    "motorId": "5f4294d2000231000000019b",
    "propWeightG": 3288,
    "samples": [
      [ 0, 0 ], [ 0.04, 151.9 ], [ 0.43, 241.8 ], [ 0.5, 1055 ], [ 7.04, 718.1 ], [ 7.22, 346.4 ],
      [ 7.26, 297.1 ], [ 7.35, 241.2 ], [ 7.48, 200.7 ], [ 7.66, 164.8 ], [ 12.28, 49.36 ],
      [ 12.3, 0 ]
],
    "totImpulseNs": 6463,
    "totalWeightG": 3288,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 956,
    "burnTimeS": 6.717,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M956",
    "dataFiles": 1,
    "designation": "3500CCRGM-M956",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1166,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000019c",
    "propWeightG": 3162,
    "samples": [
      [ 0, 0 ], [ 0.04, 1325 ], [ 0.08, 1335 ], [ 0.12, 1274 ], [ 0.16, 1246 ], [ 0.2, 1261 ],
      [ 0.46, 1284 ], [ 0.71, 1340 ], [ 0.97, 1333 ], [ 1.23, 1323 ], [ 1.49, 1330 ], [ 1.75, 1295 ],
      [ 2.01, 1272 ], [ 2.27, 1246 ], [ 2.52, 1233 ], [ 2.78, 1214 ], [ 3.04, 1199 ], [ 3.3, 1152 ],
      [ 3.56, 1128 ], [ 3.81, 1119 ], [ 4.08, 1099 ], [ 4.33, 1054 ], [ 4.59, 1032 ], [ 4.85, 965 ],
      [ 5.11, 548.4 ], [ 5.45, 373.4 ], [ 5.79, 248.1 ], [ 6.14, 160.9 ], [ 6.48, 109.1 ],
      [ 6.7, 0 ]
],
    "totImpulseNs": 6421,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 960,
    "burnTimeS": 5.34,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M960",
    "dataFiles": 1,
    "designation": "2800CC300MFX-M960FX",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000019d",
    "propWeightG": 2629,
    "samples": [
      [ 0, 0 ], [ 0.01, 508.3 ], [ 0.02, 1058 ], [ 0.03, 1394 ], [ 0.04, 1535 ], [ 0.05, 1585 ],
      [ 0.25, 1375 ], [ 0.44, 1355 ], [ 0.64, 1365 ], [ 0.84, 1385 ], [ 1.04, 1418 ], [ 1.23, 1359 ],
      [ 1.43, 1348 ], [ 1.63, 1291 ], [ 1.83, 1241 ], [ 2.02, 1236 ], [ 2.22, 1222 ], [ 2.42, 1182 ],
      [ 2.62, 1151 ], [ 2.81, 1106 ], [ 3.02, 1040 ], [ 3.21, 982.5 ], [ 3.41, 999.6 ], [ 3.61, 943.7 ],
      [ 3.8, 871.9 ], [ 4.11, 526.7 ], [ 4.42, 331 ], [ 4.72, 212.7 ], [ 5.03, 135.8 ], [ 5.33, 0 ]
],
    "totImpulseNs": 5126,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1000,
    "burnTimeS": 9.155,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1000",
    "dataFiles": 2,
    "designation": "4630CCRGM-M1000",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1405,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d2000231000000019e",
    "propWeightG": 4236,
    "samples": [
      [ 0, 0 ], [ 0.197, 1368 ], [ 0.593, 1448 ], [ 0.989, 1482 ], [ 1.384, 1431 ], [ 1.78, 1410 ],
      [ 2.176, 1400 ], [ 2.573, 1366 ], [ 2.97, 1339 ], [ 3.366, 1296 ], [ 3.761, 1280 ],[ 4.157, 1236 ],
      [ 4.553, 1213 ], [ 4.95, 1197 ], [ 5.347, 1172 ], [ 5.743, 1129 ], [ 6.139, 1052 ],[ 6.534, 635.3 ],
      [ 6.93, 474.4 ], [ 7.327, 360 ], [ 7.724, 273 ], [ 8.12, 205.2 ], [ 8.516, 149.1 ],[ 8.911, 103.6 ],
      [ 9.307, 70.12 ], [ 9.704, 48.71 ], [ 10.1, 0 ]
],
    "totImpulseNs": 9155,
    "totalWeightG": 8907,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1000,
    "burnTimeS": 7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1000",
    "dataFiles": 2,
    "designation": "M1000",
    "diameter": 76,
    "impulseClass": "M",
    "length": 787,
    "manufacturer": "Ellis Mountain",
    "manufacturerAbbrev": "Ellis",
    "maxThrustN": 2425,
    "motorId": "5f4294d2000231000000019f",
    "propWeightG": 3511,
    "samples": [
      [ 0, 0 ], [ 0.159, 1897 ], [ 0.481, 1606 ], [ 0.803, 1442 ], [ 1.125, 1360 ], [ 1.447, 1300 ],
      [ 1.769, 1259 ], [ 2.091, 1231 ], [ 2.413, 1203 ], [ 2.735, 1180 ], [ 3.057, 1155 ],
      [ 3.379, 1109 ], [ 3.701, 1075 ], [ 4.023, 1045 ], [ 4.345, 1010 ], [ 4.667, 951.2 ],
      [ 4.989, 860.5 ], [ 5.31, 727.4 ], [ 5.633, 595.7 ], [ 5.955, 518.9 ], [ 6.277, 439.9 ],
      [ 6.599, 347.7 ], [ 6.921, 239.4 ], [ 7.243, 144.6 ], [ 7.565, 75.11 ], [ 7.887, 33.54 ],
      [ 8.21, 0 ]
],
    "totImpulseNs": 7320,
    "totalWeightG": 5578,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1001,
    "burnTimeS": 9.825,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1001",
    "dataFiles": 1,
    "designation": "5478CCRGM-M1001",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000001a0",
    "propWeightG": 5161,
    "samples": [
      [ 0, 0 ], [ 0.04, 1394 ], [ 0.08, 1440 ], [ 0.12, 1322 ], [ 0.16, 1329 ], [ 0.2, 1341 ],
      [ 0.57, 1411 ], [ 0.95, 1421 ], [ 1.32, 1416 ], [ 1.69, 1405 ], [ 2.07, 1384 ], [ 2.44, 1371 ],
      [ 2.82, 1354 ], [ 3.19, 1319 ], [ 3.57, 1327 ], [ 3.94, 1338 ], [ 4.32, 1247 ], [ 4.69, 1287 ],
      [ 5.07, 1220 ], [ 5.44, 1124 ], [ 5.82, 1075 ], [ 6.19, 1078 ], [ 6.56, 996.4 ], [ 6.94, 953.2 ],
      [ 7.31, 676.7 ], [ 7.83, 419.5 ], [ 8.35, 285.8 ], [ 8.87, 192.2 ], [ 9.39, 128.7 ],
      [ 9.87, 0 ]
],
    "totImpulseNs": 9835,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1010,
    "burnTimeS": 9.024,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1010",
    "dataFiles": 2,
    "designation": "4630CCRGMFX-M1010FX",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1405,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000001a1",
    "propWeightG": 4301,
    "samples": [
      [ 0, 0 ], [ 0.199, 1473 ], [ 0.599, 1473 ], [ 0.999, 1464 ], [ 1.399, 1381 ], [ 1.799, 1408 ],
      [ 2.199, 1384 ], [ 2.599, 1333 ], [ 2.999, 1357 ], [ 3.399, 1339 ], [ 3.799, 1306 ],
      [ 4.199, 1266 ], [ 4.599, 1224 ], [ 4.999, 1191 ], [ 5.399, 1145 ], [ 5.799, 1103 ],
      [ 6.199, 1061 ], [ 6.599, 696.8 ], [ 6.999, 487.5 ], [ 7.399, 377.9 ], [ 7.799, 288.2 ],
      [ 8.199, 221.4 ], [ 8.599, 166.7 ], [ 8.999, 123.7 ], [ 9.399, 90.17 ], [ 9.8, 64.57 ],
      [ 10.2, 0 ]
],
    "totImpulseNs": 9114,
    "totalWeightG": 9000,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1015,
    "burnTimeS": 6.24,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1015",
    "dataFiles": 1,
    "designation": "3500CCRGMFX-M1015FX",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1166,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000001a2",
    "propWeightG": 3250,
    "samples": [
      [ 0, 0 ], [ 0.04, 1515 ], [ 0.08, 1634 ], [ 0.12, 1567 ], [ 0.16, 1507 ], [ 0.2, 1477 ],
      [ 0.41, 1418 ], [ 0.63, 1420 ], [ 0.85, 1436 ], [ 1.06, 1405 ], [ 1.28, 1371 ], [ 1.49, 1355 ],
      [ 1.71, 1320 ], [ 1.93, 1287 ], [ 2.14, 1269 ], [ 2.36, 1268 ], [ 2.58, 1281 ], [ 2.79, 1249 ],
      [ 3.01, 1268 ], [ 3.23, 1273 ], [ 3.44, 1298 ], [ 3.66, 1214 ], [ 3.87, 1167 ], [ 4.09, 1135 ],
      [ 4.31, 1121 ], [ 4.69, 544.6 ], [ 5.07, 363.6 ], [ 5.46, 234.8 ], [ 5.84, 149.4 ],[ 6.22, 94.73 ],
      [ 6.23, 0 ]
],
    "totImpulseNs": 6334,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1040,
    "burnTimeS": 9.71,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1040",
    "dataFiles": 1,
    "designation": "4630CCRGMFX-M1040FX",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1438,
    "manufacturer": "Hypertek",
    "manufacturerAbbrev": "Hypertek",
    "motorId": "5f4294d200023100000001a3",
    "propWeightG": 5293,
    "samples": [
      [ 0, 0 ], [ 0.02, 1288 ], [ 0.05, 1545 ], [ 0.07, 1584 ], [ 0.09, 1531 ], [ 0.12, 1498 ],
      [ 0.53, 1422 ], [ 0.95, 1430 ], [ 1.37, 1412 ], [ 1.78, 1399 ], [ 2.2, 1372 ], [ 2.61, 1383 ],
      [ 3.03, 1406 ], [ 3.44, 1479 ], [ 3.86, 1421 ], [ 4.27, 1378 ], [ 4.69, 1334 ], [ 5.1, 1289 ],
      [ 5.52, 1275 ], [ 5.93, 1176 ], [ 6.35, 1152 ], [ 6.77, 891.1 ], [ 7.18, 582.2 ], [ 7.6, 429 ],
      [ 8.01, 320.7 ], [ 8.43, 238.7 ], [ 8.84, 175.9 ], [ 9.25, 128.9 ], [ 9.66, 92.08 ],
      [ 9.7, 0 ]
],
    "totImpulseNs": 10100,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1297,
    "burnTimeS": 4.17,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1297",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1297W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2049,
    "motorId": "5f4294d200023100000001a4",
    "propInfo": "White Lightning",
    "propWeightG": 2722,
    "samples": [
      [ 0, 0 ], [ 0.1, 1433 ], [ 0.15, 1789 ], [ 0.2, 1923 ], [ 0.25, 1869 ], [ 0.3, 1856 ],
      [ 0.35, 1834 ], [ 0.4, 1767 ], [ 0.5, 1723 ], [ 0.6, 1709 ], [ 0.9, 1700 ], [ 1, 1688 ],
      [ 1.5, 1679 ], [ 1.75, 1635 ], [ 1.85, 1622 ], [ 1.95, 1573 ], [ 2, 1554 ], [ 2.5, 1347 ],
      [ 3, 1136 ], [ 3.2, 1053 ], [ 3.25, 1044 ], [ 3.35, 1032 ], [ 3.38, 1020 ], [ 3.4, 937 ],
      [ 3.5, 738 ], [ 3.6, 545 ], [ 3.75, 393 ], [ 4, 226 ], [ 4.25, 94 ], [ 4.35, 45 ], [ 4.4, 0 ]
],
    "totImpulseNs": 5417,
    "totalWeightG": 4637,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1248,
    "burnTimeS": 5.4,
    "caseInfo": "RMS-75/6400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1315",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1315W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 801,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2362,
    "motorId": "5f4294d200023100000001a5",
    "propInfo": "White Lightning",
    "propWeightG": 3499,
    "samples": [
      [ 0, 0 ], [ 0.116, 1729 ], [ 0.349, 1673 ], [ 0.582, 1687 ], [ 0.816, 1696 ], [ 1.049, 1663 ],
      [ 1.282, 1631 ], [ 1.516, 1620 ], [ 1.749, 1620 ], [ 1.982, 1621 ], [ 2.216, 1615 ],
      [ 2.449, 1567 ], [ 2.682, 1494 ], [ 2.916, 1420 ], [ 3.149, 1359 ], [ 3.382, 1293 ],
      [ 3.616, 1225 ], [ 3.849, 1172 ], [ 4.082, 928.8 ], [ 4.316, 577.9 ], [ 4.549, 395.4 ],
      [ 4.782, 314 ], [ 5.016, 228.3 ], [ 5.249, 159.8 ], [ 5.482, 118.3 ], [ 5.716, 109.8 ],
      [ 5.949, 0 ]
],
    "totImpulseNs": 6714,
    "totalWeightG": 5645,
    "type": "reload",
    "updatedOn": "2023-03-11"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1350,
    "burnTimeS": 4.23,
    "caseInfo": "AMW 75-6000",
    "certOrg": "National Association of Rocketry",
    "commonName": "M1350",
    "dataFiles": 2,
    "designation": "WT-75-6000",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/M1350.pdf",
    "length": 785,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1663,
    "motorId": "5f4294d200023100000001a6",
    "propInfo": "White Wolf",
    "propWeightG": 2927,
    "samples": [
      [ 0, 0 ], [ 0.03, 1198 ], [ 0.04, 1465 ], [ 0.07, 1660 ], [ 0.09, 1666 ], [ 0.16, 1588 ],
      [ 0.45, 1588 ], [ 0.61, 1577 ], [ 1.86, 1649 ], [ 2.27, 1643 ], [ 2.64, 1599 ], [ 3.18, 1504 ],
      [ 3.29, 1354 ], [ 3.41, 991.6 ], [ 3.49, 841.2 ], [ 3.62, 646.2 ], [ 3.74, 429 ], [ 3.9, 373.3 ],
      [ 4.22, 0 ]
],
    "totImpulseNs": 5711,
    "totalWeightG": 5403,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1398,
    "burnTimeS": 4.47,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1400",
    "dataFiles": 3,
    "designation": "6251M1400-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2292,
    "motorId": "5f4294d200023100000001a7",
    "propInfo": "Classic",
    "propWeightG": 2992,
    "samples": [
      [ 0, 0 ], [ 0.02, 991.6 ], [ 0.07, 1940 ], [ 0.11, 2292 ], [ 0.14, 1976 ], [ 0.19, 1962 ],
      [ 0.29, 1936 ], [ 0.52, 1881 ], [ 0.75, 1833 ], [ 1, 1778 ], [ 1.25, 1739 ], [ 1.7, 1655 ],
      [ 2.4, 1502 ], [ 2.85, 1389 ], [ 3.25, 1283 ], [ 3.4, 1232 ], [ 3.53, 1200 ], [ 3.65, 1084 ],
      [ 3.7, 909.4 ], [ 3.9, 641.5 ], [ 4, 502.8 ], [ 4.03, 463 ], [ 4.22, 336.1 ], [ 4.43, 138.7 ],
      [ 4.47, 93.21 ], [ 5, 0 ]
],
    "totImpulseNs": 6251,
    "totalWeightG": 5302,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1419,
    "burnTimeS": 7.1,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1419",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1419W",
    "diameter": 98,
    "impulseClass": "M",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1591,
    "motorId": "5f4294d200023100000001a8",
    "propInfo": "White Lightning",
    "propWeightG": 4077,
    "samples": [
      [ 0, 0 ], [ 0.154, 1155 ], [ 0.465, 1241 ], [ 0.776, 1300 ], [ 1.087, 1358 ], [ 1.399, 1411 ],
      [ 1.71, 1461 ], [ 2.022, 1486 ], [ 2.333, 1504 ], [ 2.644, 1513 ], [ 2.955, 1512 ],[ 3.267, 1492 ],
      [ 3.578, 1418 ], [ 3.89, 1327 ], [ 4.201, 1219 ], [ 4.513, 1088 ], [ 4.824, 937.1 ],
      [ 5.135, 810.1 ], [ 5.446, 709.1 ], [ 5.757, 624.7 ], [ 6.069, 557.2 ], [ 6.38, 437.8 ],
      [ 6.692, 252.1 ], [ 7.003, 107.7 ], [ 7.315, 19.97 ], [ 7.626, 0.515 ], [ 7.937, 0 ]
],
    "totImpulseNs": 7756,
    "totalWeightG": 6916,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1480,
    "burnTimeS": 3.9,
    "caseInfo": "AMW 75-6000",
    "certOrg": "National Association of Rocketry",
    "commonName": "M1480",
    "dataFiles": 2,
    "designation": "RR-75-6000",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/M1480.pdf",
    "length": 785,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001a9",
    "propInfo": "Red Rhino",
    "propWeightG": 3000,
    "samples": [
      [ 0, 0 ], [ 0.022, 713 ], [ 0.032, 1255 ], [ 0.055, 1473 ], [ 0.078, 1569 ], [ 0.156, 1569 ],
      [ 0.352, 1559 ], [ 0.642, 1597 ], [ 0.974, 1645 ], [ 1.289, 1702 ], [ 1.52, 1739 ],[ 1.918, 1797 ],
      [ 2.279, 1815 ], [ 2.481, 1797 ], [ 2.707, 1739 ], [ 2.968, 1645 ], [ 3.058, 1616 ],
      [ 3.135, 1521 ], [ 3.218, 1379 ], [ 3.284, 1217 ], [ 3.332, 1065 ], [ 3.344, 1141 ],
      [ 3.368, 1017 ], [ 3.41, 741.5 ], [ 3.5, 522.9 ], [ 3.613, 275.7 ], [ 3.691, 171.1 ],
      [ 3.768, 66.55 ], [ 3.85, 0 ]
],
    "totImpulseNs": 5800,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1550,
    "burnTimeS": 3,
    "caseInfo": "RMS-75/6400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1550",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1550R",
    "diameter": 75,
    "impulseClass": "M",
    "length": 801,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2180,
    "motorId": "5f4294d200023100000001aa",
    "propInfo": "Redline",
    "propWeightG": 3170,
    "samples": [
      [ 0, 0 ], [ 0.069, 1721 ], [ 0.212, 2125 ], [ 0.358, 1996 ], [ 0.501, 1908 ], [ 0.645, 1869 ],
      [ 0.79, 1836 ], [ 0.935, 1809 ], [ 1.079, 1796 ], [ 1.222, 1785 ], [ 1.368, 1773 ],[ 1.511, 1747 ],
      [ 1.655, 1716 ], [ 1.8, 1690 ], [ 1.945, 1661 ], [ 2.089, 1633 ], [ 2.232, 1606 ], [ 2.378, 1570 ],
      [ 2.521, 1535 ], [ 2.665, 1503 ], [ 2.81, 1461 ], [ 2.955, 1428 ], [ 3.099, 1393 ],[ 3.242, 940 ],
      [ 3.388, 268.5 ], [ 3.532, 4.985 ], [ 3.677, 0 ]
],
    "totImpulseNs": 5600,
    "totalWeightG": 5645,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1556,
    "burnTimeS": 4.2,
    "caseInfo": "98 60 M BG(5300CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1575",
    "dataFiles": 2,
    "designation": "M1575-BG",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1524,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 2965,
    "motorId": "5f4294d200023100000001ab",
    "propWeightG": 2210,
    "samples": [
      [ 0, 0 ], [ 0.1398, 2429 ], [ 0.5034, 2977 ], [ 2.953, 1403 ], [ 3.065, 923.9 ], [ 3.21, 376.4 ],
      [ 3.311, 205.3 ], [ 4.2, 0 ]
],
    "totImpulseNs": 6547,
    "totalWeightG": 10680,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1600,
    "burnTimeS": 4,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1600",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1600R",
    "diameter": 98,
    "impulseClass": "M",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1917,
    "motorId": "5f4294d200023100000001ac",
    "propInfo": "Redline",
    "propWeightG": 4026,
    "samples": [
      [ 0, 0 ], [ 0.088, 1370 ], [ 0.268, 1627 ], [ 0.448, 1673 ], [ 0.628, 1721 ], [ 0.808, 1763 ],
      [ 0.987, 1801 ], [ 1.167, 1830 ], [ 1.348, 1845 ], [ 1.529, 1856 ], [ 1.71, 1850 ],[ 1.89, 1847 ],
      [ 2.07, 1829 ], [ 2.25, 1811 ], [ 2.43, 1785 ], [ 2.61, 1754 ], [ 2.79, 1727 ], [ 2.971, 1689 ],
      [ 3.152, 1642 ], [ 3.332, 1582 ], [ 3.513, 1511 ], [ 3.692, 1431 ], [ 3.872, 1361 ],
      [ 4.053, 1235 ], [ 4.232, 621.2 ], [ 4.414, 42.47 ], [ 4.595, 0 ]
],
    "totImpulseNs": 7084,
    "totalWeightG": 6917,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1730,
    "burnTimeS": 4.68,
    "caseInfo": "AMW 98-11000",
    "certOrg": "National Association of Rocketry",
    "commonName": "M1730",
    "dataFiles": 2,
    "designation": "SK-98-11000",
    "diameter": 98,
    "impulseClass": "M",
    "length": 870,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001ad",
    "propInfo": "Skidmark",
    "propWeightG": 4945,
    "samples": [
      [ 0, 0 ], [ 0.04, 682.6 ], [ 0.064, 1153 ], [ 0.221, 1355 ], [ 0.269, 1415 ], [ 0.381, 1458 ],
      [ 0.541, 1527 ], [ 0.701, 1589 ], [ 0.861, 1675 ], [ 1.021, 1733 ], [ 1.181, 1802 ],
      [ 1.341, 1887 ], [ 1.5, 1974 ], [ 1.66, 2071 ], [ 1.82, 2184 ], [ 1.98, 2299 ], [ 2.14, 2434 ],
      [ 2.3, 2568 ], [ 2.46, 2679 ], [ 2.62, 2638 ], [ 2.78, 2484 ], [ 2.94, 2306 ], [ 3.099, 2174 ],
      [ 3.259, 2075 ], [ 3.419, 1961 ], [ 3.579, 1808 ], [ 3.739, 1640 ], [ 3.899, 1303 ],
      [ 4.059, 940.6 ], [ 4.219, 567.2 ], [ 4.379, 309.1 ], [ 4.539, 189 ], [ 4.637, 0 ]
],
    "sparky": true,
    "totImpulseNs": 8115,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1882,
    "burnTimeS": 3,
    "caseInfo": "76/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1882",
    "dataFiles": 2,
    "designation": "M1882-LW",
    "diameter": 76,
    "impulseClass": "M",
    "length": 787,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 2860,
    "motorId": "5f4294d200023100000001ae",
    "propInfo": "Loki White",
    "propWeightG": 3130,
    "samples": [
      [ 0, 0 ], [ 0.01, 4.8 ], [ 0.0174, 2579 ], [ 0.06961, 2392 ], [ 0.232, 2299 ], [ 0.5046, 2261 ],
      [ 0.7715, 2299 ], [ 0.9861, 2411 ], [ 1.189, 2579 ], [ 1.497, 2598 ], [ 1.729, 2486 ],
      [ 2.001, 2355 ], [ 2.5, 1645 ], [ 2.999, 243 ], [ 3.25, 0 ]
],
    "totImpulseNs": 6303,
    "totalWeightG": 5534,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1850,
    "burnTimeS": 3,
    "caseInfo": "AMW 75-6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1850",
    "dataFiles": 3,
    "designation": "GG-75-6000",
    "diameter": 75,
    "impulseClass": "M",
    "length": 781,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 2269,
    "motorId": "5f4294d200023100000001af",
    "propInfo": "Green Gorilla",
    "propWeightG": 3375,
    "samples": [
      [ 0, 0 ], [ 0.08, 979 ], [ 0.13, 1180 ], [ 0.28, 1290 ], [ 0.33, 1468 ], [ 0.73, 1936 ],
      [ 1.33, 2202 ], [ 1.73, 2279 ], [ 2.58, 2105 ], [ 2.83, 2007 ], [ 2.88, 1860 ], [ 3.08, 538 ],
      [ 3.2, 174 ], [ 3.3, 0 ]
],
    "totImpulseNs": 5920,
    "totalWeightG": 4500,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1900,
    "burnTimeS": 2.85,
    "caseInfo": "AMW 75-6000",
    "certOrg": "National Association of Rocketry",
    "commonName": "M1900",
    "dataFiles": 2,
    "designation": "BB-75-6000",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/M1900.pdf",
    "length": 785,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001b0",
    "propInfo": "Blue Baboon",
    "propWeightG": 2733,
    "samples": [
      [ 0, 0 ], [ 0.018, 1109 ], [ 0.044, 1762 ], [ 0.061, 1911 ], [ 0.085, 1939 ], [ 0.159, 1930 ],
      [ 0.29, 1957 ], [ 0.409, 2032 ], [ 0.438, 1975 ], [ 0.569, 2012 ], [ 0.815, 2105 ],[ 1.073, 2197 ],
      [ 1.401, 2280 ], [ 1.688, 2325 ], [ 1.905, 2297 ], [ 2.073, 2241 ], [ 2.254, 2138 ],
      [ 2.397, 2064 ], [ 2.479, 2017 ], [ 2.54, 2026 ], [ 2.581, 2007 ], [ 2.63, 1886 ], [ 2.716, 1494 ],
      [ 2.805, 1120 ], [ 2.887, 840.6 ], [ 2.972, 570 ], [ 3.046, 299.5 ], [ 3.119, 150.2 ],
      [ 3.168, 56.83 ], [ 3.23, 0 ]
],
    "totImpulseNs": 6100,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1939,
    "burnTimeS": 6.2,
    "caseInfo": "RMS-98/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1939",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1939W",
    "diameter": 98,
    "impulseClass": "M",
    "length": 732,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2430,
    "motorId": "5f4294d200023100000001b1",
    "propInfo": "White Lightning",
    "propWeightG": 5719,
    "samples": [
      [ 0, 0 ], [ 0.134, 1905 ], [ 0.406, 2021 ], [ 0.679, 2096 ], [ 0.952, 2158 ], [ 1.225, 2198 ],
      [ 1.498, 2220 ], [ 1.77, 2229 ], [ 2.042, 2230 ], [ 2.315, 2226 ], [ 2.587, 2212 ],[ 2.86, 2165 ],
      [ 3.133, 2047 ], [ 3.405, 1916 ], [ 3.677, 1806 ], [ 3.95, 1658 ], [ 4.223, 1498 ],[ 4.496, 1339 ],
      [ 4.769, 1213 ], [ 5.041, 1102 ], [ 5.313, 966.5 ], [ 5.585, 670.3 ], [ 5.858, 444 ],
      [ 6.131, 155.4 ], [ 6.404, 41.36 ], [ 6.677, 5.775 ], [ 6.95, 0 ]
],
    "totImpulseNs": 10480,
    "totalWeightG": 8988,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2000,
    "burnTimeS": 4,
    "caseInfo": "RMS-98/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M2000R",
    "diameter": 98,
    "impulseClass": "M",
    "length": 732,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2327,
    "motorId": "5f4294d200023100000001b2",
    "propInfo": "Redline",
    "propWeightG": 5368,
    "samples": [
      [ 0, 0 ], [ 0.091, 1531 ], [ 0.279, 2186 ], [ 0.466, 2167 ], [ 0.655, 2187 ], [ 0.844, 2219 ],
      [ 1.031, 2248 ], [ 1.22, 2274 ], [ 1.409, 2298 ], [ 1.596, 2310 ], [ 1.785, 2316 ],[ 1.974, 2316 ],
      [ 2.161, 2306 ], [ 2.35, 2282 ], [ 2.539, 2252 ], [ 2.726, 2210 ], [ 2.915, 2169 ],[ 3.104, 2117 ],
      [ 3.291, 2068 ], [ 3.48, 2005 ], [ 3.669, 1934 ], [ 3.856, 1831 ], [ 4.045, 1746 ],[ 4.234, 1504 ],
      [ 4.421, 649.8 ], [ 4.61, 58.18 ], [ 4.799, 0 ]
],
    "totImpulseNs": 9218,
    "totalWeightG": 8987,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2020,
    "burnTimeS": 4.998,
    "caseInfo": "AMW 98-11000",
    "certOrg": "National Association of Rocketry",
    "commonName": "N2020",
    "dataFiles": 2,
    "designation": "WT-98-11000",
    "diameter": 98,
    "impulseClass": "N",
    "length": 870,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001b3",
    "propInfo": "White Wolf",
    "propWeightG": 5161,
    "samples": [
      [ 0, 0 ], [ 0.106, 1941 ], [ 0.221, 2151 ], [ 0.381, 2253 ], [ 0.541, 2341 ], [ 0.701, 2401 ],
      [ 0.861, 2454 ], [ 1.021, 2506 ], [ 1.181, 2556 ], [ 1.341, 2607 ], [ 1.5, 2653 ], [ 1.66, 2689 ],
      [ 1.82, 2711 ], [ 1.98, 2730 ], [ 2.14, 2734 ], [ 2.3, 2704 ], [ 2.46, 2635 ], [ 2.62, 2532 ],
      [ 2.78, 2433 ], [ 2.94, 2330 ], [ 3.099, 2234 ], [ 3.259, 2166 ], [ 3.419, 2100 ], [ 3.579, 2028 ],
      [ 3.739, 1951 ], [ 3.899, 1871 ], [ 4.059, 1558 ], [ 4.219, 1053 ], [ 4.379, 890.5 ],
      [ 4.539, 636.7 ], [ 4.998, 0 ]
],
    "totImpulseNs": 10280,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2200,
    "burnTimeS": 2.85,
    "caseInfo": "AMW 75-7600",
    "certOrg": "National Association of Rocketry",
    "commonName": "M2200",
    "dataFiles": 1,
    "designation": "SK-75-7600",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/M2200.pdf",
    "length": 1039,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001b4",
    "propInfo": "Skidmark",
    "propWeightG": 3766,
    "samples": [
      [ 0, 0 ], [ 0.02, 972.3 ], [ 0.049, 1613 ], [ 0.101, 1602 ], [ 0.243, 1701 ], [ 0.482, 1878 ],
      [ 0.571, 1889 ], [ 0.802, 2088 ], [ 0.997, 2254 ], [ 1.073, 2254 ], [ 1.227, 2487 ],
      [ 1.426, 2574 ], [ 1.616, 2652 ], [ 1.681, 2751 ], [ 1.782, 2840 ], [ 1.831, 2817 ],
      [ 1.961, 2917 ], [ 2.07, 2979 ], [ 2.155, 2939 ], [ 2.196, 2961 ], [ 2.256, 2873 ],[ 2.321, 2840 ],
      [ 2.374, 2685 ], [ 2.422, 2332 ], [ 2.483, 1569 ], [ 2.524, 1216 ], [ 2.584, 1094 ],
      [ 2.674, 1061 ], [ 2.747, 961.3 ], [ 2.868, 740.4 ], [ 2.953, 464 ], [ 3.046, 165.7 ],
      [ 3.11, 0 ]
],
    "sparky": true,
    "totImpulseNs": 6350,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2552,
    "burnTimeS": 2.14,
    "caseInfo": "75x1753 (3200CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2281",
    "dataFiles": 1,
    "designation": "M2281-BF",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1524,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 6040,
    "motorId": "5f4294d200023100000001b5",
    "propWeightG": 1306,
    "samples": [
      [ 0, 0 ], [ 0.001, 2725 ], [ 0.201, 4550 ], [ 0.601, 4550 ], [ 0.801, 4550 ], [ 0.901, 4000 ],
      [ 0.951, 2725 ], [ 1.989, 116.3 ], [ 2.141, 0 ]
],
    "totImpulseNs": 5482,
    "totalWeightG": 5579,
    "type": "hybrid",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2400,
    "burnTimeS": 3.2,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2400",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M2400T",
    "diameter": 98,
    "impulseClass": "M",
    "length": 597,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3402,
    "motorId": "5f4294d200023100000001b6",
    "propInfo": "Blue Thunder",
    "propWeightG": 3693,
    "samples": [
      [ 0, 0 ], [ 0.07, 2442 ], [ 0.211, 2495 ], [ 0.353, 2556 ], [ 0.495, 2602 ], [ 0.636, 2638 ],
      [ 0.778, 2661 ], [ 0.92, 2676 ], [ 1.061, 2687 ], [ 1.203, 2696 ], [ 1.345, 2694 ],[ 1.486, 2684 ],
      [ 1.628, 2667 ], [ 1.771, 2630 ], [ 1.914, 2579 ], [ 2.055, 2522 ], [ 2.197, 2462 ],
      [ 2.339, 2394 ], [ 2.48, 2304 ], [ 2.622, 2202 ], [ 2.764, 2097 ], [ 2.905, 2010 ],[ 3.047, 1276 ],
      [ 3.189, 418.8 ], [ 3.33, 17.59 ], [ 3.473, 3.669 ], [ 3.616, 0 ]
],
    "totImpulseNs": 7717,
    "totalWeightG": 6451,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2501,
    "burnTimeS": 3.11,
    "caseInfo": "AMW 75-7600",
    "certOrg": "National Association of Rocketry",
    "commonName": "M2500",
    "dataFiles": 2,
    "designation": "GG-75-7600",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://nar.org/SandT/pdf/Animal/M2500.pdf",
    "length": 1039,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 2996,
    "motorId": "5f4294d200023100000001b7",
    "propInfo": "Green Gorilla",
    "propWeightG": 4248,
    "samples": [
      [ 0, 0 ], [ 0.026, 1289 ], [ 0.053, 2021 ], [ 0.079, 2140 ], [ 0.123, 2105 ], [ 0.207, 2117 ],
      [ 0.54, 2309 ], [ 0.971, 2561 ], [ 1.265, 2727 ], [ 1.48, 2837 ], [ 1.678, 2920 ], [ 1.757, 2980 ],
      [ 1.946, 2996 ], [ 2.047, 2959 ], [ 2.24, 2890 ], [ 2.31, 2855 ], [ 2.486, 2821 ], [ 2.526, 2881 ],
      [ 2.592, 2774 ], [ 2.653, 2822 ], [ 2.706, 2752 ], [ 2.758, 2752 ], [ 2.807, 2764 ],
      [ 2.842, 2505 ], [ 2.886, 2115 ], [ 2.93, 1631 ], [ 2.987, 1052 ], [ 3.04, 437.6 ],[ 3.057, 284.1 ],
      [ 3.079, 142.4 ], [ 3.11, 0 ]
],
    "totImpulseNs": 7784,
    "totalWeightG": 7552,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2500,
    "burnTimeS": 3.85,
    "caseInfo": "RMS-98/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2500",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M2500T",
    "diameter": 98,
    "impulseClass": "M",
    "length": 751,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3711,
    "motorId": "5f4294d200023100000001b8",
    "propInfo": "Blue Thunder",
    "propWeightG": 4711,
    "samples": [
      [ 0, 0 ], [ 0.082, 2652 ], [ 0.249, 2780 ], [ 0.416, 2821 ], [ 0.583, 2843 ], [ 0.751, 2848 ],
      [ 0.918, 2851 ], [ 1.084, 2855 ], [ 1.252, 2862 ], [ 1.42, 2858 ], [ 1.586, 2851 ],[ 1.754, 2845 ],
      [ 1.922, 2831 ], [ 2.089, 2805 ], [ 2.255, 2766 ], [ 2.423, 2711 ], [ 2.591, 2648 ],
      [ 2.757, 2587 ], [ 2.925, 2521 ], [ 3.093, 2462 ], [ 3.259, 2420 ], [ 3.426, 1895 ],
      [ 3.594, 808 ], [ 3.761, 282.4 ], [ 3.928, 97.88 ], [ 4.096, 24.49 ], [ 4.264, 0 ]
],
    "totImpulseNs": 9671,
    "totalWeightG": 8064,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2491,
    "burnTimeS": 3,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2505",
    "dataFiles": 2,
    "designation": "7450M2505-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 579,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2953,
    "motorId": "5f4294d200023100000001b9",
    "propInfo": "White Thunder",
    "propWeightG": 3339,
    "samples": [
      [ 0, 0 ], [ 0.12, 2600 ], [ 0.21, 2482 ], [ 0.6, 2715 ], [ 0.9, 2876 ], [ 1.2, 2938 ],
      [ 1.5, 2889 ], [ 1.8, 2785 ], [ 2.1, 2573 ], [ 2.4, 2349 ], [ 2.7, 2182 ], [ 3, 85 ],[ 3, 0 ]
],
    "totImpulseNs": 7450,
    "totalWeightG": 6250,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2600,
    "burnTimeS": 4.03,
    "caseInfo": "AMW 98-11000",
    "certOrg": "National Association of Rocketry",
    "commonName": "N2600",
    "dataFiles": 2,
    "designation": "GG-98-11000",
    "diameter": 98,
    "impulseClass": "N",
    "length": 870,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001ba",
    "propInfo": "Green Gorilla",
    "propWeightG": 5591,
    "samples": [
      [ 0, 0 ], [ 0.024, 1674 ], [ 0.064, 1950 ], [ 0.104, 2040 ], [ 0.306, 2190 ], [ 0.508, 2334 ],
      [ 0.709, 2491 ], [ 0.911, 2669 ], [ 1.113, 2875 ], [ 1.314, 3039 ], [ 1.516, 3191 ],
      [ 1.718, 3266 ], [ 1.92, 3319 ], [ 2.121, 3336 ], [ 2.323, 3229 ], [ 2.525, 3090 ],[ 2.726, 2944 ],
      [ 2.928, 2848 ], [ 3.13, 2752 ], [ 3.331, 2682 ], [ 3.533, 2463 ], [ 3.735, 1340 ],[ 3.937, 269.8 ],
      [ 4.034, 0 ]
],
    "totImpulseNs": 10610,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2700,
    "burnTimeS": 4.16,
    "caseInfo": "AMW 98-11000",
    "certOrg": "National Association of Rocketry",
    "commonName": "N2700",
    "dataFiles": 2,
    "designation": "BB-98-11000",
    "diameter": 98,
    "impulseClass": "N",
    "length": 870,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001bb",
    "propInfo": "Blue Baboon",
    "propWeightG": 5147,
    "samples": [
      [ 0, 0 ], [ 0.027, 2230 ], [ 0.069, 2476 ], [ 0.111, 2540 ], [ 0.36, 2723 ], [ 0.527, 2864 ],
      [ 0.735, 3016 ], [ 0.943, 3141 ], [ 1.151, 3242 ], [ 1.359, 3336 ], [ 1.567, 3520 ],
      [ 1.775, 3426 ], [ 1.983, 3421 ], [ 2.191, 3356 ], [ 2.399, 3270 ], [ 2.607, 3183 ],
      [ 2.815, 3098 ], [ 3.023, 3003 ], [ 3.231, 2889 ], [ 3.439, 2267 ], [ 3.647, 1498 ],
      [ 3.855, 780 ], [ 4.063, 233.5 ], [ 4.16, 0 ]
],
    "totImpulseNs": 11450,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2645,
    "burnTimeS": 2.3,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2700",
    "dataFiles": 2,
    "designation": "M2700-BS",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1524,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 5484,
    "motorId": "5f4294d200023100000001bc",
    "propWeightG": 1730,
    "samples": [
      [ 0, 0 ], [ 0.008475, 2965 ], [ 0.05085, 3272 ], [ 0.1059, 5931 ], [ 0.3475, 5829 ],
      [ 0.5042, 6442 ], [ 0.5127, 5726 ], [ 0.6017, 5113 ], [ 0.7458, 3681 ], [ 0.9025, 3068 ],
      [ 1.064, 2454 ], [ 1.186, 1943 ], [ 1.343, 1738 ], [ 1.75, 715.8 ], [ 1.958, 102.3 ],
      [ 2.3, 0 ]
],
    "totImpulseNs": 6034,
    "totalWeightG": 10430,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2570,
    "burnTimeS": 2.3,
    "caseInfo": "98 60 XF BG (5300CC)",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2800",
    "dataFiles": 2,
    "designation": "M2800-BG",
    "diameter": 98,
    "impulseClass": "M",
    "length": 1524,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 4964,
    "motorId": "5f4294d200023100000001bd",
    "propWeightG": 2210,
    "samples": [
      [ 0, 0 ], [ 0.008389, 2395 ], [ 0.2517, 2806 ], [ 0.5453, 3695 ], [ 0.7578, 5612 ],[ 0.9116, 4311 ],
      [ 1.175, 3559 ], [ 1.409, 2258 ], [ 1.709, 1506 ], [ 2.3, 0 ]
],
    "totImpulseNs": 6060,
    "totalWeightG": 10700,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3000,
    "burnTimeS": 2.49,
    "caseInfo": "AMW 75-7600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M3000",
    "dataFiles": 2,
    "designation": "ST-75-7600",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 4387,
    "motorId": "5f4294d200023100000001be",
    "propInfo": "Super Tiger",
    "propWeightG": 3819,
    "samples": [
      [ 0, 0 ], [ 0.032, 2494 ], [ 0.113, 2621 ], [ 0.242, 2706 ], [ 0.355, 2811 ], [ 0.435, 2896 ],
      [ 0.5, 2959 ], [ 0.645, 3128 ], [ 0.75, 3297 ], [ 0.871, 3382 ], [ 0.968, 3551 ], [ 1.032, 3657 ],
      [ 1.145, 3805 ], [ 1.355, 3974 ], [ 1.452, 4037 ], [ 1.629, 4080 ], [ 1.742, 4143 ],
      [ 1.903, 4185 ], [ 1.935, 3847 ], [ 2.081, 3424 ], [ 2.129, 2959 ], [ 2.177, 2537 ],
      [ 2.194, 2114 ], [ 2.226, 1691 ], [ 2.274, 1268 ], [ 2.323, 845.5 ], [ 2.403, 422.8 ],
      [ 2.5, 0 ]
],
    "totImpulseNs": 7367,
    "totalWeightG": 6720,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2000,
    "burnTimeS": 6.9,
    "caseInfo": "RMS-98/15360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N2000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "N2000W",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1046,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3141,
    "motorId": "5f4294d200023100000001bf",
    "propInfo": "White Lightning",
    "propWeightG": 7753,
    "samples": [
      [ 0, 0 ], [ 0.146, 2775 ], [ 0.446, 2832 ], [ 0.746, 2834 ], [ 1.046, 2830 ], [ 1.346, 2778 ],
      [ 1.646, 2688 ], [ 1.95, 2598 ], [ 2.254, 2501 ], [ 2.554, 2416 ], [ 2.854, 2344 ],[ 3.154, 2263 ],
      [ 3.454, 2178 ], [ 3.758, 2104 ], [ 4.062, 2024 ], [ 4.362, 1936 ], [ 4.663, 1840 ],
      [ 4.962, 1757 ], [ 5.262, 1352 ], [ 5.567, 954.6 ], [ 5.871, 681.8 ], [ 6.171, 475.9 ],
      [ 6.471, 361.1 ], [ 6.771, 194.6 ], [ 7.071, 44.94 ], [ 7.375, 6.03 ], [ 7.679, 0 ]
],
    "totImpulseNs": 13350,
    "totalWeightG": 12280,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2562,
    "burnTimeS": 5.38,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2500",
    "dataFiles": 2,
    "designation": "13766N2500-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3876,
    "motorId": "5f4294d200023100000001c0",
    "propInfo": "Classic",
    "propWeightG": 6778,
    "samples": [
      [ 0, 0 ], [ 0.02, 773.7 ], [ 0.05, 3357 ], [ 0.06, 3658 ], [ 0.1, 3547 ], [ 0.25, 3404 ],
      [ 0.4, 3309 ], [ 0.8, 3263 ], [ 1, 3206 ], [ 1.5, 3089 ], [ 2, 2940 ], [ 2.5, 2793 ],[ 3, 2598 ],
      [ 3.5, 2403 ], [ 4, 2227 ], [ 4.25, 2153 ], [ 4.4, 2103 ], [ 4.5, 2007 ], [ 4.6, 1684 ],
      [ 4.75, 1270 ], [ 5, 767.3 ], [ 5.41, 341.3 ], [ 5.42, 0 ]
],
    "totImpulseNs": 13770,
    "totalWeightG": 11670,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 4000,
    "burnTimeS": 4.2,
    "caseInfo": "AMW 98-17500",
    "certOrg": "National Association of Rocketry",
    "commonName": "N4000",
    "dataFiles": 2,
    "designation": "BB-98-17500",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1213,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "motorId": "5f4294d200023100000001c1",
    "propInfo": "Blue Baboon",
    "propWeightG": 7566,
    "samples": [
      [ 0, 0 ], [ 0.029, 4208 ], [ 0.071, 4710 ], [ 0.113, 4906 ], [ 0.155, 5008 ], [ 0.239, 5042 ],
      [ 0.323, 4994 ], [ 0.534, 5047 ], [ 0.744, 5146 ], [ 0.954, 5248 ], [ 1.165, 5293 ],
      [ 1.375, 5232 ], [ 1.585, 5210 ], [ 1.796, 5165 ], [ 2.006, 5048 ], [ 2.216, 4913 ],
      [ 2.427, 4783 ], [ 2.637, 4659 ], [ 2.847, 4196 ], [ 3.058, 2851 ], [ 3.268, 1982 ],
      [ 3.478, 1296 ], [ 3.689, 907.7 ], [ 3.899, 490.2 ], [ 4.11, 316.3 ], [ 4.207, 0 ]
],
    "totImpulseNs": 16460,
    "totalWeightG": 13670,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 4800,
    "burnTimeS": 4.44,
    "caseInfo": "RMS-98/18000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N4800",
    "dataFiles": 2,
    "designation": "N4800T",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1201,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 6599,
    "motorId": "5f4294d200023100000001c2",
    "propInfo": "Blue Thunder",
    "propWeightG": 9571,
    "samples": [
      [ 0, 0 ], [ 0.098, 4753 ], [ 0.301, 6008 ], [ 0.506, 5594 ], [ 0.71, 5270 ], [ 0.914, 5150 ],
      [ 1.119, 5108 ], [ 1.324, 5086 ], [ 1.528, 5032 ], [ 1.731, 4942 ], [ 1.936, 4800 ],
      [ 2.14, 4665 ], [ 2.344, 4528 ], [ 2.549, 4401 ], [ 2.754, 4264 ], [ 2.958, 4120 ],[ 3.161, 3971 ],
      [ 3.366, 3876 ], [ 3.57, 3916 ], [ 3.774, 3914 ], [ 3.979, 3313 ], [ 4.184, 1649 ],[ 4.388, 523.4 ],
      [ 4.591, 327.2 ], [ 4.796, 251 ], [ 5.001, 128.2 ], [ 5.206, 0 ]
],
    "totImpulseNs": 19360,
    "totalWeightG": 14780,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 5113,
    "burnTimeS": 5.86,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O5100",
    "dataFiles": 2,
    "designation": "29990O5100-P",
    "diameter": 161,
    "impulseClass": "O",
    "length": 803,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 6562,
    "motorId": "5f4294d200023100000001c3",
    "propWeightG": 13250,
    "samples": [
      [ 0, 0 ], [ 0.01, 815.1 ], [ 0.02, 1408 ], [ 0.03, 2334 ], [ 0.04, 3260 ], [ 0.05, 4001 ],
      [ 0.07, 4928 ], [ 0.07, 5484 ], [ 0.09, 5817 ], [ 0.13, 6058 ], [ 0.2, 6206 ], [ 0.3, 6299 ],
      [ 0.43, 6280 ], [ 0.6, 6262 ], [ 0.78, 6299 ], [ 0.97, 6354 ], [ 1.05, 6428 ], [ 1.12, 6391 ],
      [ 1.34, 6465 ], [ 1.49, 6503 ], [ 1.75, 6540 ], [ 1.88, 6558 ], [ 2.16, 6521 ], [ 2.36, 6465 ],
      [ 2.58, 6373 ], [ 2.96, 6113 ], [ 3.56, 5558 ], [ 4.13, 4909 ], [ 4.72, 4261 ], [ 4.83, 4150 ],
      [ 4.93, 3038 ], [ 5, 2612 ], [ 5.1, 2112 ], [ 5.23, 1741 ], [ 5.32, 1538 ], [ 5.52, 1223 ],
      [ 5.8, 907.7 ], [ 5.85, 666.9 ], [ 5.89, 333.4 ], [ 5.9, 0 ]
],
    "totImpulseNs": 29990,
    "totalWeightG": 23580,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 6354,
    "burnTimeS": 4.29,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "O6300",
    "dataFiles": 2,
    "designation": "O6300-BS",
    "diameter": 152,
    "impulseClass": "O",
    "length": 1829,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 12730,
    "motorId": "5f4294d200023100000001c4",
    "propWeightG": 5216,
    "samples": [
      [ 0, 0 ], [ 0.0339, 12270 ], [ 0.7288, 9715 ], [ 1.653, 9203 ], [ 2.373, 8948 ], [ 2.517, 6647 ],
      [ 2.788, 4602 ], [ 2.992, 4857 ], [ 3.28, 3579 ], [ 3.61, 1278 ], [ 4.29, 0 ]
],
    "totImpulseNs": 27240,
    "totalWeightG": 28580,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1335,
    "burnTimeS": 0.316,
    "caseInfo": "RMS-38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I1299",
    "dataFiles": 2,
    "delays": "P",
    "designation": "I1299N",
    "diameter": 38,
    "impulseClass": "I",
    "length": 249,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1851,
    "motorId": "5f4294d200023100000001c5",
    "propInfo": "Warp 9",
    "propWeightG": 192,
    "samples": [
      [ 0, 0 ], [ 0.001, 15.72 ], [ 0.00461, 222.5 ], [ 0.0125, 1112 ], [ 0.01442, 1237 ],
      [ 0.021, 1287 ], [ 0.041, 1359 ], [ 0.101, 1451 ], [ 0.121, 1470 ], [ 0.181, 1491 ],
      [ 0.201, 1483 ], [ 0.221, 1462 ], [ 0.241, 1399 ], [ 0.281, 1208 ], [ 0.2957, 1132 ],
      [ 0.301, 1065 ], [ 0.3053, 974.5 ], [ 0.321, 305 ], [ 0.3315, 55.01 ], [ 0.3349, 11.79 ],
      [ 0.341, 0 ]
],
    "totImpulseNs": 422.9,
    "totalWeightG": 422,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1500,
    "burnTimeS": 0.88,
    "caseInfo": "RMS-75/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1499",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K1499N",
    "diameter": 75,
    "impulseClass": "K",
    "length": 260,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1720,
    "motorId": "5f4294d200023100000001c6",
    "propInfo": "Warp 9",
    "propWeightG": 604,
    "samples": [
      [ 0, 0 ], [ 0.01, 1450 ], [ 0.2, 1720 ], [ 0.35, 1700 ], [ 0.5, 1600 ], [ 0.6, 1575 ],
      [ 0.7, 1500 ], [ 0.82, 1400 ], [ 0.84, 250 ], [ 0.88, 0 ]
],
    "totImpulseNs": 1322,
    "totalWeightG": 1741,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 373.8,
    "burnTimeS": 1.52,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I364",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I364FJ",
    "diameter": 38,
    "impulseClass": "I",
    "length": 335,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 487.3,
    "motorId": "5f4294d200023100000001c7",
    "propInfo": "Black Max",
    "propWeightG": 396,
    "samples": [
      [ 0, 0 ], [ 0.02, 356 ], [ 0.1, 373.8 ], [ 0.2, 387.2 ], [ 0.3, 400.5 ], [ 0.4, 400.5 ],
      [ 0.5, 409.4 ], [ 0.6, 413.9 ], [ 0.7, 409.4 ], [ 0.8, 382.7 ], [ 0.9, 373.8 ], [ 1, 351.6 ],
      [ 1.1, 333.8 ], [ 1.2, 320.4 ], [ 1.3, 311.5 ], [ 1.4, 244.8 ], [ 1.5, 178 ], [ 1.6, 80.1 ],
      [ 1.7, 0 ]
],
    "totImpulseNs": 570.1,
    "totalWeightG": 678,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 230.5,
    "burnTimeS": 1.59,
    "caseInfo": "RMS-38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I225",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I225FJ",
    "diameter": 38,
    "impulseClass": "I",
    "length": 240,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 276.6,
    "motorId": "5f4294d200023100000001c8",
    "propInfo": "Black Max",
    "propWeightG": 264,
    "samples": [
      [ 0, 0 ], [ 0.04, 213.6 ], [ 0.1, 213.6 ], [ 0.2, 218.1 ], [ 0.28, 235.9 ], [ 0.3, 249.2 ],
      [ 0.4, 262.6 ], [ 0.5, 267 ], [ 0.6, 271.5 ], [ 0.7, 275.9 ], [ 0.8, 275.9 ], [ 0.87, 271.5 ],
      [ 0.9, 258.1 ], [ 1, 240.3 ], [ 1.1, 218.1 ], [ 1.2, 200.3 ], [ 1.3, 178 ], [ 1.4, 160.2 ],
      [ 1.5, 97.9 ], [ 1.6, 40.1 ], [ 1.7, 13.4 ], [ 1.8, 0 ]
],
    "totImpulseNs": 367.9,
    "totalWeightG": 486,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 313.5,
    "burnTimeS": 1.44,
    "caseInfo": "RMS-38/600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I305",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I305FJ",
    "diameter": 38,
    "impulseClass": "I",
    "length": 288,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 418.2,
    "motorId": "5f4294d200023100000001c9",
    "propInfo": "Black Max",
    "propWeightG": 330,
    "samples": [
      [ 0, 0 ], [ 0.02, 341.4 ], [ 0.1, 365.5 ], [ 0.2, 383.6 ], [ 0.3, 403.7 ], [ 0.4, 405.7 ],
      [ 0.5, 405.7 ], [ 0.6, 404.7 ], [ 0.7, 374.5 ], [ 0.8, 342.4 ], [ 0.9, 309.3 ], [ 1, 272.1 ],
      [ 1.1, 239 ], [ 1.15, 224.9 ], [ 1.2, 194.8 ], [ 1.3, 119.5 ], [ 1.4, 62.26 ], [ 1.45, 33.14 ],
      [ 1.5, 23.09 ], [ 1.6, 0 ]
],
    "totImpulseNs": 452.1,
    "totalWeightG": 581,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1887,
    "burnTimeS": 1.34,
    "caseInfo": "RMS-98/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1999",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K1999N",
    "diameter": 98,
    "impulseClass": "K",
    "length": 289,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2160,
    "motorId": "5f4294d200023100000001ca",
    "propInfo": "Warp 9",
    "propWeightG": 1225,
    "samples": [
      [ 0, 0 ], [ 0.019, 2337 ], [ 0.026, 717 ], [ 0.036, 2131 ], [ 0.064, 1507 ], [ 0.093, 1945 ],
      [ 0.107, 1766 ], [ 0.153, 1819 ], [ 0.205, 1866 ], [ 0.294, 1985 ], [ 0.399, 2051 ],
      [ 0.602, 2091 ], [ 0.8, 2078 ], [ 1.001, 1965 ], [ 1.06, 1912 ], [ 1.196, 1726 ], [ 1.213, 1700 ],
      [ 1.266, 1799 ], [ 1.287, 1593 ], [ 1.294, 1328 ], [ 1.311, 883 ], [ 1.328, 444.8 ],
      [ 1.333, 239 ], [ 1.347, 192.5 ], [ 1.359, 0 ]
],
    "totImpulseNs": 2540,
    "totalWeightG": 2989,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 307.5,
    "burnTimeS": 0.35,
    "caseInfo": "RMS-38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G339",
    "dataFiles": 2,
    "delays": "P",
    "designation": "G339N",
    "diameter": 38,
    "impulseClass": "G",
    "length": 97,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 503.5,
    "motorId": "5f4294d200023100000001cb",
    "propInfo": "Warp 9",
    "propWeightG": 49,
    "samples": [
      [ 0, 0 ], [ 0.009, 371 ], [ 0.05, 375 ], [ 0.1, 375 ], [ 0.15, 364 ], [ 0.2, 349 ], [ 0.25, 310 ],
      [ 0.3, 264 ], [ 0.324, 257 ], [ 0.342, 39 ], [ 0.359, 0 ]
],
    "totImpulseNs": 108.9,
    "totalWeightG": 181,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 594,
    "burnTimeS": 1.34,
    "caseInfo": "RMS-38/1080",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J575",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "J575FJ",
    "diameter": 38,
    "impulseClass": "J",
    "length": 478,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 839.5,
    "motorId": "5f4294d200023100000001cc",
    "propInfo": "Black Max",
    "propWeightG": 576,
    "samples": [
      [ 0, 0 ], [ 0.01566, 656.7 ], [ 0.01957, 840.7 ], [ 0.05502, 878.8 ], [ 0.09078, 880.6 ],
      [ 0.1541, 882.4 ], [ 0.2091, 884.2 ], [ 0.2613, 880.6 ], [ 0.3411, 878.8 ], [ 0.3906, 873.4 ],
      [ 0.4512, 862.6 ], [ 0.5254, 821.2 ], [ 0.5997, 783.4 ], [ 0.7978, 669.9 ], [ 0.8363, 639.3 ],
      [ 1.001, 340.4 ], [ 1.073, 205.3 ], [ 1.15, 108.1 ], [ 1.21, 72.04 ], [ 1.285, 32.42 ],
      [ 1.34, 0 ]
],
    "totImpulseNs": 798.1,
    "totalWeightG": 914,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1910,
    "burnTimeS": 4.01,
    "caseInfo": "RMS-75/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1850",
    "dataFiles": 4,
    "delays": "P",
    "designation": "M1850W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 923,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 4489,
    "motorId": "5f4294d200023100000001cd",
    "propInfo": "White Lightning",
    "propWeightG": 4122,
    "samples": [
      [ 0, 0 ], [ 0.006, 4486 ], [ 0.025, 1227 ], [ 0.035, 4486 ], [ 0.047, 1959 ], [ 0.057, 4262 ],
      [ 0.063, 2720 ], [ 0.076, 4068 ], [ 0.082, 3107 ], [ 0.088, 3929 ], [ 0.104, 3295 ],
      [ 0.114, 3748 ], [ 0.196, 3283 ], [ 0.281, 2877 ], [ 0.341, 2763 ], [ 0.414, 2708 ],
      [ 0.483, 2672 ], [ 0.629, 2612 ], [ 0.752, 2575 ], [ 0.837, 2551 ], [ 0.932, 2533 ],
      [ 0.983, 2515 ], [ 1.043, 2479 ], [ 1.242, 2370 ], [ 1.412, 2279 ], [ 1.539, 2225 ],
      [ 1.709, 2092 ], [ 1.826, 2086 ], [ 1.959, 2031 ], [ 2.098, 1947 ], [ 2.215, 1934 ],
      [ 2.294, 1868 ], [ 2.351, 1862 ], [ 2.439, 1862 ], [ 2.487, 1814 ], [ 2.61, 1795 ],[ 2.793, 1729 ],
      [ 2.894, 1662 ], [ 3.008, 1505 ], [ 3.065, 1409 ], [ 3.175, 1245 ], [ 3.188, 1620 ],
      [ 3.197, 1161 ], [ 3.21, 1433 ], [ 3.216, 1124 ], [ 3.232, 1269 ], [ 3.248, 1076 ],[ 3.254, 1185 ],
      [ 3.267, 1058 ], [ 3.286, 1130 ], [ 3.308, 1070 ], [ 3.355, 1028 ], [ 3.412, 931 ],[ 3.45, 870.5 ],
      [ 3.463, 791.9 ], [ 3.479, 1094 ], [ 3.485, 749.6 ], [ 3.491, 912.8 ], [ 3.507, 852.4 ],
      [ 3.51, 683.1 ], [ 3.529, 798 ], [ 3.532, 701.2 ], [ 3.539, 640.8 ], [ 3.555, 719.4 ],
      [ 3.577, 677.1 ], [ 3.649, 646.8 ], [ 3.754, 525.9 ], [ 3.893, 399 ], [ 4.025, 235.8 ],
      [ 4.231, 187.4 ], [ 4.335, 145.1 ], [ 4.566, 48.36 ], [ 4.711, 48.36 ], [ 4.717, 0 ]
],
    "totImpulseNs": 7659,
    "totalWeightG": 6032,
    "type": "reload",
    "updatedOn": "2024-01-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 1292,
    "burnTimeS": 0.653,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J1299",
    "dataFiles": 2,
    "delays": "P",
    "designation": "J1299N",
    "diameter": 54,
    "impulseClass": "J",
    "length": 231,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1468,
    "motorId": "5f4294d200023100000001ce",
    "propInfo": "Warp 9",
    "propWeightG": 371.6,
    "samples": [
      [ 0, 0 ], [ 0.01, 548 ], [ 0.02, 1152 ], [ 0.03, 1232 ], [ 0.04, 1277 ], [ 0.05, 1272 ],
      [ 0.06, 1288 ], [ 0.07, 1333 ], [ 0.08, 1347 ], [ 0.09, 1378 ], [ 0.1, 1383 ], [ 0.12, 1405 ],
      [ 0.14, 1410 ], [ 0.16, 1440 ], [ 0.18, 1444 ], [ 0.2, 1446 ], [ 0.25, 1449 ], [ 0.3, 1452 ],
      [ 0.35, 1448 ], [ 0.4, 1440 ], [ 0.45, 1405 ], [ 0.5, 1320 ], [ 0.55, 1248 ], [ 0.57, 1224 ],
      [ 0.59, 1210 ], [ 0.6, 1180 ], [ 0.61, 1188 ], [ 0.615, 1195 ], [ 0.62, 1188 ], [ 0.63, 510 ],
      [ 0.64, 220 ], [ 0.65, 96 ], [ 0.66, 46 ], [ 0.67, 26 ], [ 0.678, 0 ]
],
    "totImpulseNs": 843.4,
    "totalWeightG": 834,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1963,
    "burnTimeS": 0.61,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J1799",
    "dataFiles": 2,
    "delays": "P",
    "designation": "J1799N",
    "diameter": 54,
    "impulseClass": "J",
    "length": 330,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2966,
    "motorId": "5f4294d200023100000001cf",
    "propInfo": "Warp 9",
    "propWeightG": 591,
    "samples": [
      [ 0, 0 ], [ 0.004, 665 ], [ 0.007, 1339 ], [ 0.009, 1625 ], [ 0.014, 1497 ], [ 0.02, 2022 ],
      [ 0.026, 1572 ], [ 0.042, 1797 ], [ 0.051, 1731 ], [ 0.062, 1850 ], [ 0.075, 1788 ],
      [ 0.083, 1898 ], [ 0.096, 1832 ], [ 0.104, 1911 ], [ 0.138, 1955 ], [ 0.144, 2114 ],
      [ 0.154, 2008 ], [ 0.176, 2008 ], [ 0.18, 1964 ], [ 0.207, 1973 ], [ 0.258, 2026 ],[ 0.325, 1977 ],
      [ 0.362, 1938 ], [ 0.399, 1951 ], [ 0.428, 1933 ], [ 0.44, 1859 ], [ 0.499, 1696 ],[ 0.527, 1634 ],
      [ 0.553, 1524 ], [ 0.564, 1431 ], [ 0.572, 1114 ], [ 0.581, 665 ], [ 0.587, 409.6 ],
      [ 0.595, 220.2 ], [ 0.604, 96.89 ], [ 0.615, 26.43 ], [ 0.633, 39.64 ], [ 0.652, 0 ]
],
    "totImpulseNs": 1215,
    "totalWeightG": 1111,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 71,
    "burnTimeS": 1.51,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G71",
    "dataFiles": 3,
    "delays": "4,5,6,7,8,10",
    "designation": "G71R",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G71R.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 117,
    "motorId": "5f4294d200023100000001d0",
    "propInfo": "Redline",
    "propWeightG": 64,
    "samples": [
      [ 0, 0 ], [ 0.008, 46.66 ], [ 0.015, 74.25 ], [ 0.023, 85.79 ], [ 0.031, 100.3 ], [ 0.05, 110.9 ],
      [ 0.062, 116.9 ], [ 0.085, 120.4 ], [ 0.116, 119.9 ], [ 0.139, 118.9 ], [ 0.158, 116.9 ],
      [ 0.181, 115.4 ], [ 0.216, 114.4 ], [ 0.251, 113.4 ], [ 0.278, 111.4 ], [ 0.297, 111.4 ],
      [ 0.309, 114.4 ], [ 0.328, 112.4 ], [ 0.355, 109.4 ], [ 0.39, 107.4 ], [ 0.432, 104.9 ],
      [ 0.463, 103.8 ], [ 0.494, 100.8 ], [ 0.525, 98.83 ], [ 0.552, 95.82 ], [ 0.583, 94.32 ],
      [ 0.606, 92.31 ], [ 0.633, 89.3 ], [ 0.653, 87.29 ], [ 0.676, 85.79 ], [ 0.699, 81.27 ],
      [ 0.714, 84.28 ], [ 0.734, 81.27 ], [ 0.749, 88.8 ], [ 0.772, 80.27 ], [ 0.799, 76.25 ],
      [ 0.826, 73.75 ], [ 0.861, 70.74 ], [ 0.876, 73.75 ], [ 0.892, 69.23 ], [ 0.915, 69.73 ],
      [ 0.923, 65.72 ], [ 0.942, 63.71 ], [ 0.977, 60.7 ], [ 1.008, 58.2 ], [ 1.039, 54.18 ],
      [ 1.077, 50.17 ], [ 1.108, 46.15 ], [ 1.12, 50.17 ], [ 1.127, 46.15 ], [ 1.143, 43.14 ],
      [ 1.178, 37.63 ], [ 1.212, 32.61 ], [ 1.232, 30.1 ], [ 1.255, 26.59 ], [ 1.274, 23.58 ],
      [ 1.301, 20.57 ], [ 1.317, 18.06 ], [ 1.344, 15.55 ], [ 1.382, 11.54 ], [ 1.417, 10.03 ],
      [ 1.448, 7.024 ], [ 1.486, 6.02 ], [ 1.517, 3.512 ], [ 1.552, 3.01 ], [ 1.587, 2.508 ],
      [ 1.618, 1.003 ], [ 1.668, 0.502 ], [ 1.707, 0.502 ], [ 1.734, 0 ]
],
    "totImpulseNs": 107,
    "totalWeightG": 147,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 200,
    "burnTimeS": 1.8,
    "caseInfo": "AMW 38-390",
    "certOrg": "National Association of Rocketry",
    "commonName": "I195",
    "dataFiles": 2,
    "designation": "WW-38-390",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Animal/I195WW.pdf",
    "length": 249,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 238,
    "motorId": "5f4294d200023100000001d1",
    "propInfo": "White Wolf",
    "propWeightG": 192.2,
    "samples": [
      [ 0, 0 ], [ 0.002, 10.55 ], [ 0.018, 42.65 ], [ 0.046, 136.2 ], [ 0.064, 179.8 ], [ 0.072, 191.2 ],
      [ 0.078, 197.2 ], [ 0.088, 198.6 ], [ 0.126, 198.6 ], [ 0.175, 207.8 ], [ 0.217, 211.9 ],
      [ 0.349, 216.9 ], [ 0.401, 221.1 ], [ 0.554, 225.6 ], [ 0.586, 228.9 ], [ 0.626, 228.5 ],
      [ 0.65, 230.2 ], [ 1.013, 231.6 ], [ 1.105, 230.7 ], [ 1.2, 225.2 ], [ 1.356, 210.5 ],
      [ 1.392, 207.8 ], [ 1.441, 206.8 ], [ 1.457, 205 ], [ 1.519, 181.2 ], [ 1.563, 155.9 ],
      [ 1.693, 49.99 ], [ 1.727, 28.43 ], [ 1.756, 16.51 ], [ 1.798, 7.338 ], [ 1.86, 1.376 ],
      [ 1.89, 0 ]
],
    "totImpulseNs": 358,
    "type": "reload",
    "updatedOn": "2022-07-24"
  },
  {
    "availability": "OOP",
    "avgThrustN": 223,
    "burnTimeS": 1.61,
    "caseInfo": "AMW 38-390",
    "certOrg": "National Association of Rocketry",
    "commonName": "I220",
    "dataFiles": 2,
    "designation": "SK-38-390",
    "diameter": 38,
    "impulseClass": "I",
    "length": 249,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 312,
    "motorId": "5f4294d200023100000001d2",
    "propInfo": "Skidmark",
    "propWeightG": 201.9,
    "samples": [
      [ 0, 0 ], [ 0.005, 12.75 ], [ 0.019, 45.25 ], [ 0.036, 79.67 ], [ 0.052, 125.6 ], [ 0.069, 162.5 ],
      [ 0.076, 169.5 ], [ 0.095, 174.6 ], [ 0.167, 176.5 ], [ 0.229, 191.2 ], [ 0.447, 235.2 ],
      [ 0.602, 260.7 ], [ 0.733, 288.1 ], [ 0.85, 302.1 ], [ 0.974, 301.5 ], [ 1.094, 290 ],
      [ 1.184, 269 ], [ 1.268, 240.3 ], [ 1.302, 219.9 ], [ 1.388, 177.2 ], [ 1.418, 147.2 ],
      [ 1.435, 127.5 ], [ 1.473, 91.14 ], [ 1.504, 65.64 ], [ 1.543, 40.79 ], [ 1.593, 19.12 ],
      [ 1.622, 10.2 ], [ 1.65, 0 ]
],
    "sparky": true,
    "totImpulseNs": 358,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 328,
    "burnTimeS": 1.81,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "I325",
    "dataFiles": 2,
    "designation": "WW-38-640",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 402,
    "motorId": "5f4294d200023100000001d3",
    "propInfo": "White Wolf",
    "propWeightG": 316.8,
    "samples": [
      [ 0, 0 ], [ 0.014, 68.71 ], [ 0.022, 113 ], [ 0.026, 153.7 ], [ 0.037, 244.5 ], [ 0.045, 299.2 ],
      [ 0.055, 330.2 ], [ 0.065, 350.2 ], [ 0.079, 365.7 ], [ 0.094, 376.8 ], [ 0.124, 382 ],
      [ 0.185, 373.8 ], [ 0.252, 373.8 ], [ 0.35, 381.2 ], [ 0.47, 382.7 ], [ 0.622, 388.6 ],
      [ 1.102, 384.2 ], [ 1.366, 365 ], [ 1.379, 360.5 ], [ 1.415, 331.7 ], [ 1.49, 223.1 ],
      [ 1.505, 211.3 ], [ 1.551, 187.7 ], [ 1.592, 162.5 ], [ 1.688, 80.53 ], [ 1.726, 50.98 ],
      [ 1.775, 27.34 ], [ 1.806, 16.99 ], [ 1.834, 9.605 ], [ 1.901, 0 ]
],
    "totImpulseNs": 594,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 24.4,
    "burnTimeS": 2.03,
    "certOrg": "National Association of Rocketry",
    "commonName": "F27",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F27R/L",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F27.pdf",
    "length": 83,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 37.7,
    "motorId": "5f4294d200023100000001d4",
    "propInfo": "Redline",
    "propWeightG": 28.4,
    "samples": [
      [ 0, 0 ], [ 0.0125, 15.54 ], [ 0.0175, 18.82 ], [ 0.025, 22.53 ], [ 0.0325, 25.25 ],
      [ 0.04, 27.32 ], [ 0.0575, 30.37 ], [ 0.0725, 31.46 ], [ 0.0975, 32.25 ], [ 0.265, 35.02 ],
      [ 0.295, 35.92 ], [ 0.4675, 35.97 ], [ 0.59, 35.06 ], [ 0.8, 32.01 ], [ 0.825, 31.28 ],
      [ 0.8575, 31.11 ], [ 0.9025, 29.93 ], [ 0.955, 29.72 ], [ 1.045, 27.3 ], [ 1.085, 27.27 ],
      [ 1.117, 25.99 ], [ 1.147, 26 ], [ 1.235, 23.29 ], [ 1.28, 22.9 ], [ 1.343, 21.08 ],
      [ 1.52, 17.26 ], [ 1.808, 6.855 ], [ 1.917, 4.164 ], [ 2.05, 1.784 ], [ 2.178, 0.488 ],
      [ 2.422, 0.4439 ], [ 2.425, 0 ]
],
    "totImpulseNs": 49.6,
    "totalWeightG": 80,
    "type": "SU",
    "updatedOn": "2022-08-09"
  },
  {
    "availability": "regular",
    "avgThrustN": 72.3,
    "burnTimeS": 1.88,
    "caseInfo": "RMS-38/120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G69",
    "dataFiles": 2,
    "delays": "P",
    "designation": "G69N",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G69.pdf",
    "length": 106,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 87.3,
    "motorId": "5f4294d200023100000001d5",
    "propInfo": "Warp 9",
    "propWeightG": 62.2,
    "samples": [
      [ 0, 0 ], [ 0.001, 1.043 ], [ 0.005, 1.496 ], [ 0.009, 3.992 ], [ 0.011, 7.188 ], [ 0.015, 31.79 ],
      [ 0.017, 38.67 ], [ 0.019, 43.51 ], [ 0.027, 55.36 ], [ 0.033, 58.47 ], [ 0.039, 63.35 ],
      [ 0.041, 66.46 ], [ 0.053, 74.01 ], [ 0.065, 76.23 ], [ 0.123, 75.79 ], [ 0.169, 77.56 ],
      [ 0.667, 82 ], [ 0.719, 83.78 ], [ 1.123, 86.44 ], [ 1.415, 86 ], [ 1.461, 84.22 ],[ 1.495, 80.23 ],
      [ 1.543, 71.79 ], [ 1.705, 29.83 ], [ 1.747, 20.73 ], [ 1.825, 9.231 ], [ 1.901, 4.214 ],
      [ 1.999, 0 ]
],
    "totImpulseNs": 136.7,
    "totalWeightG": 201,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 892.9,
    "burnTimeS": 1.15,
    "caseInfo": "RMS-38/1080",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J825",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "J825R",
    "diameter": 38,
    "impulseClass": "J",
    "length": 478,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1244,
    "motorId": "5f4294d200023100000001d6",
    "propInfo": "Redline",
    "propWeightG": 500,
    "samples": [
      [ 0, 0 ], [ 0.006, 444.8 ], [ 0.014, 889.6 ], [ 0.015, 1060 ], [ 0.018, 1203 ], [ 0.029, 1041 ],
      [ 0.039, 1111 ], [ 0.047, 1071 ], [ 0.056, 1091 ], [ 0.08, 1077 ], [ 0.104, 1069 ],[ 0.134, 1063 ],
      [ 0.17, 1063 ], [ 0.202, 1069 ], [ 0.303, 1055 ], [ 0.401, 1021 ], [ 0.497, 996 ], [ 0.603, 948.4 ],
      [ 0.702, 909.2 ], [ 0.8, 842.1 ], [ 0.906, 772.1 ], [ 0.933, 749.8 ], [ 0.952, 671.4 ],
      [ 0.98, 579.1 ], [ 1.005, 470 ], [ 1.01, 405.7 ], [ 1.019, 405.7 ], [ 1.034, 226.6 ],
      [ 1.055, 123.1 ], [ 1.07, 50.36 ], [ 1.093, 22.38 ], [ 1.115, 0 ]
],
    "totImpulseNs": 974.9,
    "totalWeightG": 875,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 656,
    "burnTimeS": 9.13,
    "caseInfo": "RMS-75/6400",
    "certOrg": "National Association of Rocketry",
    "commonName": "M650",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M650W",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/M650W.pdf",
    "length": 801,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1475,
    "motorId": "5f4294d200023100000001d7",
    "propInfo": "White Lightning",
    "propWeightG": 3351,
    "samples": [
      [ 0, 0 ], [ 0.08, 1240 ], [ 0.12, 1328 ], [ 0.25, 1230 ], [ 0.5, 1142 ], [ 1, 1071 ],[ 1.5, 1048 ],
      [ 2, 1018 ], [ 2.5, 982 ], [ 3, 950 ], [ 3.5, 853 ], [ 4, 781 ], [ 5, 595 ], [ 6, 443 ],
      [ 7, 297 ], [ 8, 155 ], [ 9, 88 ], [ 10, 32 ], [ 10.5, 12 ], [ 11, 4 ], [ 11.5, 0 ]
],
    "totImpulseNs": 5964,
    "totalWeightG": 5125,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 744,
    "burnTimeS": 12.65,
    "caseInfo": "RMS-98/10240",
    "certOrg": "National Association of Rocketry",
    "commonName": "M750",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M750W",
    "diameter": 98,
    "impulseClass": "M",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/M750W.pdf",
    "length": 732,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1454,
    "motorId": "5f4294d200023100000001d8",
    "propInfo": "White Lightning",
    "propWeightG": 5540,
    "samples": [
      [ 0, 0 ], [ 0.1, 1032 ], [ 0.2, 992 ], [ 0.3, 974 ], [ 0.48, 966 ], [ 1, 1055 ], [ 1.5, 1152 ],
      [ 2, 1192 ], [ 2.5, 1218 ], [ 4, 1103 ], [ 6, 818 ], [ 8, 561 ], [ 10, 318 ], [ 11, 216 ],
      [ 12, 125 ], [ 13, 76 ], [ 14, 47 ], [ 15, 23 ], [ 15.5, 9 ], [ 16, 0 ]
],
    "totImpulseNs": 9325,
    "totalWeightG": 8776,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1391,
    "burnTimeS": 3.41,
    "caseInfo": "AMW 75-6000",
    "certOrg": "National Association of Rocketry",
    "commonName": "L1400",
    "dataFiles": 2,
    "designation": "SK-75-6000",
    "diameter": 75,
    "impulseClass": "L",
    "length": 785,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1963,
    "motorId": "5f4294d200023100000001d9",
    "propInfo": "Skidmark",
    "propWeightG": 2829,
    "samples": [
      [ 0, 0 ], [ 0.001, 68.12 ], [ 0.005, 193.8 ], [ 0.017, 690.3 ], [ 0.022, 814.6 ], [ 0.028, 900.7 ],
      [ 0.046, 997.5 ], [ 0.077, 1251 ], [ 0.093, 1355 ], [ 0.108, 1406 ], [ 0.133, 1440 ],
      [ 0.17, 1454 ], [ 0.369, 1397 ], [ 0.526, 1412 ], [ 0.706, 1488 ], [ 1.083, 1734 ],[ 1.415, 1907 ],
      [ 1.557, 1875 ], [ 1.767, 1882 ], [ 1.9, 1803 ], [ 2.143, 1745 ], [ 2.341, 1659 ], [ 2.505, 1522 ],
      [ 2.581, 1402 ], [ 2.82, 844.8 ], [ 2.848, 841.7 ], [ 2.894, 730.8 ], [ 3.069, 406.5 ],
      [ 3.177, 265.8 ], [ 3.426, 94.96 ], [ 3.609, 0.8745 ], [ 3.61, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4744,
    "totalWeightG": 5204,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2764,
    "burnTimeS": 5.36,
    "caseInfo": "AMW 98-17500",
    "certOrg": "National Association of Rocketry",
    "commonName": "N2800",
    "dataFiles": 2,
    "designation": "WW-98-17500",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1213,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 3654,
    "motorId": "5f4294d200023100000001da",
    "propInfo": "White Wolf",
    "propWeightG": 7695,
    "samples": [
      [ 0, 0 ], [ 0.001, 93.09 ], [ 0.003, 168.3 ], [ 0.007, 387.8 ], [ 0.02, 1271 ], [ 0.03, 1776 ],
      [ 0.044, 2299 ], [ 0.063, 2841 ], [ 0.073, 3021 ], [ 0.085, 3129 ], [ 0.141, 3296 ],
      [ 0.278, 3483 ], [ 0.294, 3432 ], [ 0.37, 3496 ], [ 0.979, 3599 ], [ 1.974, 3655 ],[ 2.978, 3535 ],
      [ 3.301, 3437 ], [ 3.498, 3308 ], [ 3.584, 3194 ], [ 3.652, 3016 ], [ 3.749, 2548 ],
      [ 3.837, 2224 ], [ 4.11, 1644 ], [ 4.246, 1444 ], [ 4.273, 1447 ], [ 4.398, 1164 ],[ 4.49, 1023 ],
      [ 4.517, 1057 ], [ 4.575, 883.9 ], [ 4.648, 776.4 ], [ 5.57, 0 ]
],
    "totImpulseNs": 14800,
    "totalWeightG": 13810,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 302,
    "burnTimeS": 1.84,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "I301",
    "dataFiles": 2,
    "delays": "18",
    "designation": "I301W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 433,
    "motorId": "5f4294d200023100000001db",
    "propInfo": "White Lightning",
    "propWeightG": 310,
    "samples": [
      [ 0, 0 ], [ 0.008, 266.1 ], [ 0.014, 327.1 ], [ 0.03, 354.1 ], [ 0.058, 350.1 ], [ 0.107, 335.1 ],
      [ 0.133, 326.1 ], [ 0.189, 326.1 ], [ 0.217, 333.1 ], [ 0.237, 383.1 ], [ 0.253, 402.1 ],
      [ 0.287, 395.1 ], [ 0.33, 381.1 ], [ 0.72, 381.1 ], [ 1.035, 341.1 ], [ 1.437, 317.1 ],
      [ 1.57, 262.1 ], [ 1.698, 130 ], [ 1.789, 83.03 ], [ 1.833, 74.03 ], [ 1.867, 53.02 ],
      [ 1.893, 23.01 ], [ 1.916, 13.01 ], [ 1.952, 0 ]
],
    "totImpulseNs": 554,
    "totalWeightG": 725.5,
    "type": "reload",
    "updatedOn": "2020-01-27"
  },
  {
    "availability": "OOP",
    "avgThrustN": 587,
    "burnTimeS": 0.98,
    "caseInfo": "AMW 38-640",
    "certOrg": "National Association of Rocketry",
    "commonName": "I550",
    "dataFiles": 2,
    "delays": "20",
    "designation": "I550R",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 689,
    "motorId": "5f4294d200023100000001dc",
    "propInfo": "Redline",
    "propWeightG": 295,
    "samples": [
      [ 0, 0 ], [ 0.016, 156.1 ], [ 0.028, 278.1 ], [ 0.04, 427.1 ], [ 0.054, 550.2 ], [ 0.08, 542.2 ],
      [ 0.245, 588.2 ], [ 0.332, 611.2 ], [ 0.424, 631.2 ], [ 0.496, 638.2 ], [ 0.613, 644.2 ],
      [ 0.71, 643.2 ], [ 0.758, 631.2 ], [ 0.846, 603.2 ], [ 0.894, 613.2 ], [ 0.915, 611.2 ],
      [ 0.939, 586.2 ], [ 0.949, 546.2 ], [ 0.959, 505.2 ], [ 0.969, 469.2 ], [ 0.983, 381.1 ],
      [ 0.999, 278.1 ], [ 1.011, 200.1 ], [ 1.029, 112 ], [ 1.053, 42.02 ], [ 1.069, 15.01 ],
      [ 1.089, 0 ]
],
    "totImpulseNs": 575,
    "totalWeightG": 711.6,
    "type": "reload",
    "updatedOn": "2020-01-27"
  },
  {
    "availability": "OOP",
    "avgThrustN": 752,
    "burnTimeS": 3.29,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K750",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K750W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 727,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 1188,
    "motorId": "5f4294d200023100000001dd",
    "propInfo": "White Lightning",
    "propWeightG": 1499,
    "samples": [
      [ 0, 0 ], [ 0.008, 266.1 ], [ 0.012, 457.1 ], [ 0.02, 750.5 ], [ 0.032, 999.5 ], [ 0.044, 1112 ],
      [ 0.06, 1180 ], [ 0.095, 1098 ], [ 0.127, 1057 ], [ 0.163, 1040 ], [ 0.334, 1051 ],[ 0.62, 1054 ],
      [ 0.998, 975.6 ], [ 1.324, 907.4 ], [ 1.69, 904 ], [ 2.06, 886.9 ], [ 2.184, 828.9 ],
      [ 2.299, 757.3 ], [ 2.394, 651.5 ], [ 2.502, 556 ], [ 2.609, 450.3 ], [ 2.784, 327.5 ],
      [ 2.999, 245.6 ], [ 3.039, 201.3 ], [ 3.134, 92.1 ], [ 3.206, 40.94 ], [ 3.337, 6.822 ],
      [ 3.468, 0 ]
],
    "totImpulseNs": 2468,
    "totalWeightG": 2636,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1751,
    "burnTimeS": 1.38,
    "caseInfo": "AMW 54-2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K1750",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K1750R",
    "diameter": 54,
    "impulseClass": "K",
    "length": 727,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 2070,
    "motorId": "5f4294d200023100000001de",
    "propInfo": "Redline",
    "propWeightG": 1441,
    "samples": [
      [ 0, 0 ], [ 0.02, 1309 ], [ 0.03, 1680 ], [ 0.05, 1737 ], [ 0.11, 1690 ], [ 0.26, 1800 ],
      [ 0.4, 1914 ], [ 0.46, 1897 ], [ 0.68, 2024 ], [ 0.9, 2134 ], [ 0.95, 2097 ], [ 1, 2050 ],
      [ 1.05, 1920 ], [ 1.1, 1793 ], [ 1.16, 1676 ], [ 1.21, 1720 ], [ 1.25, 1526 ], [ 1.27, 1302 ],
      [ 1.32, 875 ], [ 1.35, 454.2 ], [ 1.36, 317.3 ], [ 1.37, 200.4 ], [ 1.4, 90.17 ], [ 1.46, 0 ]
],
    "totImpulseNs": 2423,
    "totalWeightG": 2564,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1432,
    "burnTimeS": 5.46,
    "caseInfo": "AMW 75-7600",
    "certOrg": "National Association of Rocketry",
    "commonName": "M1450",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1450W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 2134,
    "motorId": "5f4294d200023100000001df",
    "propInfo": "White Lightning",
    "propWeightG": 4150,
    "samples": [
      [ 0, 0 ], [ 0.035, 1843 ], [ 0.076, 2287 ], [ 0.146, 1969 ], [ 0.215, 1883 ], [ 0.291, 1836 ],
      [ 0.499, 1863 ], [ 1.005, 1936 ], [ 1.559, 1889 ], [ 2.155, 1816 ], [ 2.862, 1750 ],
      [ 3.493, 1664 ], [ 3.853, 1359 ], [ 4.221, 1061 ], [ 4.484, 788.9 ], [ 4.761, 523.7 ],
      [ 4.942, 258.5 ], [ 5.323, 258.5 ], [ 5.6, 172.4 ], [ 5.801, 119.3 ], [ 5.96, 0 ]
],
    "totImpulseNs": 7813,
    "totalWeightG": 7615,
    "type": "reload",
    "updatedOn": "2020-01-27"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2558,
    "burnTimeS": 2.07,
    "caseInfo": "AMW 75-7600",
    "certOrg": "National Association of Rocketry",
    "commonName": "M2900",
    "dataFiles": 1,
    "designation": "M2900R",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 2979,
    "motorId": "5f4294d200023100000001e0",
    "propInfo": "Redline",
    "propWeightG": 4265,
    "samples": [
      [ 0, 0 ], [ 0.034, 19.47 ], [ 0.05, 69.69 ], [ 0.052, 166.1 ], [ 0.056, 534 ], [ 0.06, 1067 ],
      [ 0.064, 1683 ], [ 0.066, 1898 ], [ 0.072, 2295 ], [ 0.082, 2496 ], [ 0.102, 2708 ],
      [ 0.108, 2735 ], [ 0.204, 2697 ], [ 0.438, 2690 ], [ 0.726, 2745 ], [ 0.824, 2794 ],
      [ 1.116, 3042 ], [ 1.318, 3102 ], [ 1.566, 3016 ], [ 1.816, 2846 ], [ 1.864, 2744 ],
      [ 1.898, 2565 ], [ 1.908, 2456 ], [ 1.96, 1357 ], [ 1.988, 857.2 ], [ 2.006, 603.3 ],
      [ 2.026, 417.6 ], [ 2.044, 303.9 ], [ 2.09, 150.6 ], [ 2.142, 73.22 ], [ 2.226, 25.61 ],
      [ 2.412, 0 ]
],
    "totImpulseNs": 5290,
    "totalWeightG": 7195,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 45,
    "burnTimeS": 1.4,
    "caseInfo": "SU 29x93",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F45",
    "dataFiles": 2,
    "delays": "5,8,P",
    "designation": "F45",
    "diameter": 29,
    "impulseClass": "F",
    "length": 93,
    "manufacturer": "Roadrunner Rocketry",
    "manufacturerAbbrev": "Roadrunner",
    "maxThrustN": 62,
    "motorId": "5f4294d200023100000001e1",
    "propInfo": "Rapid propellant",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.001, 4.197 ], [ 0.02, 45.5 ], [ 0.039, 53.07 ], [ 0.058, 52.4 ], [ 0.096, 54.74 ],
      [ 0.114, 55.3 ], [ 0.133, 56.74 ], [ 0.152, 57.19 ], [ 0.228, 60.42 ], [ 0.285, 61.75 ],
      [ 0.417, 62.42 ], [ 0.492, 60.75 ], [ 0.511, 61.42 ], [ 0.568, 60.31 ], [ 0.625, 58.41 ],
      [ 0.663, 58.08 ], [ 0.738, 55.3 ], [ 0.776, 52.96 ], [ 0.814, 51.51 ], [ 0.889, 46.17 ],
      [ 0.984, 34.7 ], [ 1.003, 31.81 ], [ 1.097, 23.9 ], [ 1.135, 21.45 ], [ 1.211, 14.11 ],
      [ 1.23, 12.99 ], [ 1.286, 8.428 ], [ 1.343, 5.533 ], [ 1.362, 5.422 ], [ 1.419, 2.972 ],
      [ 1.421, 0 ]
],
    "totImpulseNs": 61,
    "totalWeightG": 93,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.1,
    "burnTimeS": 1.71,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G53",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,10",
    "designation": "G53FJ",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G53FJ.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 87.3,
    "motorId": "5f4294d200023100000001e2",
    "propInfo": "Black Max",
    "propWeightG": 60,
    "samples": [
      [ 0, 0 ], [ 0.084, 0.1734 ], [ 0.098, 0.8755 ], [ 0.102, 1.976 ], [ 0.104, 3.287 ],[ 0.106, 5.663 ],
      [ 0.108, 10 ], [ 0.112, 26.81 ], [ 0.114, 36.51 ], [ 0.118, 51.19 ], [ 0.12, 56.71 ],
      [ 0.124, 64.86 ], [ 0.128, 70.22 ], [ 0.134, 74.24 ], [ 0.146, 78.9 ], [ 0.152, 79.8 ],
      [ 0.164, 83.1 ], [ 0.188, 85.65 ], [ 0.234, 85.45 ], [ 0.298, 82.02 ], [ 0.354, 79.77 ],
      [ 0.748, 69.42 ], [ 1.024, 56.74 ], [ 1.16, 48.35 ], [ 1.344, 35.29 ], [ 1.56, 22.1 ],
      [ 1.6, 19.25 ], [ 1.698, 11 ], [ 1.77, 6.589 ], [ 1.818, 4.265 ], [ 1.918, 1.115 ],[ 2, 0 ]
],
    "totImpulseNs": 90.9,
    "totalWeightG": 146,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 0.9,
    "caseInfo": "RMS-29/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H250",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "H250G",
    "diameter": 29,
    "impulseClass": "H",
    "length": 238,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e3",
    "propInfo": "Mojave Green",
    "propWeightG": 116.3,
    "samples": [
      [ 0, 0 ], [ 0.002506, 88.69 ], [ 0.01253, 177.4 ], [ 0.03008, 279.7 ], [ 0.07268, 311.1 ],
      [ 0.1454, 320.7 ], [ 0.2481, 311.1 ], [ 0.3083, 297.5 ], [ 0.3985, 282.4 ], [ 0.4561, 270.2 ],
      [ 0.594, 238.8 ], [ 0.6917, 221 ], [ 0.7995, 218.3 ], [ 0.8321, 210.1 ], [ 0.8446, 189.7 ],
      [ 0.9073, 13.64 ], [ 0.92, 0 ]
],
    "totImpulseNs": 231,
    "totalWeightG": 256,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 245,
    "burnTimeS": 1.4,
    "caseInfo": "RMS-38/360",
    "certOrg": "Unknown",
    "commonName": "I245",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I245G",
    "diameter": 38,
    "impulseClass": "I",
    "length": 202,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e4",
    "propInfo": "Mojave Green",
    "propWeightG": 181.3,
    "samples": [
      [ 0, 0 ], [ 0.0245, 234.1 ], [ 0.05502, 257.9 ], [ 0.08686, 368.6 ], [ 0.1069, 382.3 ],
      [ 0.1336, 390.8 ], [ 0.2004, 405.6 ], [ 0.2628, 410.9 ], [ 0.3029, 412 ], [ 0.363, 408.8 ],
      [ 0.4013, 398.4 ], [ 0.5011, 363.3 ], [ 0.5947, 320.9 ], [ 0.6893, 278.4 ], [ 0.7973, 212.9 ],
      [ 0.8932, 181.5 ], [ 1.006, 154.2 ], [ 1.091, 133.7 ], [ 1.16, 120.7 ], [ 1.18, 122.9 ],
      [ 1.236, 106.4 ], [ 1.304, 75.05 ], [ 1.361, 36.01 ], [ 1.403, 19.06 ], [ 1.439, 5.295 ],
      [ 1.463, 0 ]
],
    "totImpulseNs": 351,
    "totalWeightG": 365,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 500,
    "burnTimeS": 1.4,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J500",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "J500G",
    "diameter": 38,
    "impulseClass": "J",
    "length": 345,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e5",
    "propInfo": "Mojave Green",
    "propWeightG": 362.6,
    "samples": [
      [ 0, 0 ], [ 0.01344, 40.25 ], [ 0.03359, 724.4 ], [ 0.04031, 781.6 ], [ 0.06047, 788 ],
      [ 0.08959, 711.7 ], [ 0.1344, 686.3 ], [ 0.3942, 637.6 ], [ 0.5756, 588.9 ], [ 0.6069, 622.8 ],
      [ 0.6338, 620.6 ], [ 1.2, 360.1 ], [ 1.241, 345.3 ], [ 1.31, 182.2 ], [ 1.382, 65.66 ],
      [ 1.433, 23.3 ], [ 1.45, 0 ]
],
    "totImpulseNs": 723,
    "totalWeightG": 654,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 249.8,
    "burnTimeS": 7.87,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K270",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K270W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 438.4,
    "motorId": "5f4294d200023100000001e6",
    "propInfo": "White Lightning",
    "propWeightG": 1188,
    "samples": [
      [ 0, 0 ], [ 0.046, 177.1 ], [ 0.062, 177.1 ], [ 0.092, 292.9 ], [ 0.154, 425.7 ], [ 0.277, 414 ],
      [ 0.446, 389.3 ], [ 0.585, 377.6 ], [ 0.738, 381.5 ], [ 1, 372.3 ], [ 1.154, 377.6 ],
      [ 1.231, 376.3 ], [ 1.308, 378.9 ], [ 1.4, 395.8 ], [ 1.569, 380.2 ], [ 1.615, 399.7 ],
      [ 1.846, 381.5 ], [ 2.369, 381.5 ], [ 2.415, 368.4 ], [ 2.554, 381.5 ], [ 3.015, 360.6 ],
      [ 3.354, 350.2 ], [ 3.723, 328.1 ], [ 4, 300.7 ], [ 4.6, 273.4 ], [ 5.262, 225.2 ],[ 5.677, 175.8 ],
      [ 6, 144.5 ], [ 6.538, 125 ], [ 7.015, 89.83 ], [ 8, 66.4 ], [ 8.323, 22.13 ], [ 8.508, 10.41 ],
      [ 8.692, 0 ], [ 8.692, 5.208 ]
],
    "totImpulseNs": 1968,
    "totalWeightG": 2100,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 115,
    "burnTimeS": 3.583,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I115",
    "dataFiles": 1,
    "delays": "4,6,8,10",
    "designation": "I115W",
    "diameter": 54,
    "impulseClass": "I",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e7",
    "propInfo": "White Lightning",
    "propWeightG": 219,
    "samples": [
      [ 0, 0 ], [ 0.034, 12.1 ], [ 0.177, 105.2 ], [ 0.206, 113.1 ], [ 1.017, 163.3 ], [ 1.166, 161.5 ],
      [ 1.257, 162.7 ], [ 1.343, 166.9 ], [ 1.417, 160.9 ], [ 1.514, 162.7 ], [ 1.617, 163.9 ],
      [ 1.686, 160.3 ], [ 1.977, 142.7 ], [ 2.497, 106.4 ], [ 2.68, 91.32 ], [ 2.994, 72.57 ],
      [ 3.103, 67.13 ], [ 3.189, 65.92 ], [ 3.24, 59.27 ], [ 3.291, 42.94 ], [ 3.331, 30.24 ],
      [ 3.377, 20.56 ], [ 3.429, 12.7 ], [ 3.491, 7.257 ], [ 3.514, 0 ]
],
    "totImpulseNs": 412,
    "totalWeightG": 545,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 229,
    "burnTimeS": 1.777,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I229",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I229T",
    "diameter": 54,
    "impulseClass": "I",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e8",
    "propInfo": "Blue Thunder",
    "propWeightG": 196,
    "samples": [
      [ 0, 0 ], [ 0.01, 44.73 ], [ 0.02, 216.7 ], [ 0.19, 244.7 ], [ 0.4, 266.6 ], [ 0.49, 266.6 ],
      [ 0.52, 273.7 ], [ 0.59, 271.9 ], [ 0.75, 272.8 ], [ 0.84, 268.4 ], [ 0.88, 271.9 ],
      [ 0.97, 262.3 ], [ 1, 265.8 ], [ 1.07, 255.2 ], [ 1.21, 249.1 ], [ 1.51, 219.3 ], [ 1.6, 230.7 ],
      [ 1.63, 191.2 ], [ 1.64, 132.4 ], [ 1.66, 86.83 ], [ 1.7, 44.73 ], [ 1.71, 21.05 ],[ 1.73, 0 ]
],
    "totImpulseNs": 407,
    "totalWeightG": 514,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 215,
    "burnTimeS": 1.856,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I215",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I215R",
    "diameter": 54,
    "impulseClass": "I",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001e9",
    "propInfo": "Redline",
    "propWeightG": 208,
    "samples": [
      [ 0, 0 ], [ 0.049, 88.39 ], [ 0.089, 154.7 ], [ 0.094, 206.9 ], [ 0.178, 245.1 ], [ 0.251, 255.1 ],
      [ 0.325, 259.1 ], [ 0.404, 249.1 ], [ 0.582, 257.1 ], [ 0.631, 253.1 ], [ 0.7, 253.1 ],
      [ 0.774, 245.1 ], [ 1.001, 239.1 ], [ 1.509, 192.9 ], [ 1.681, 178.8 ], [ 1.716, 180.8 ],
      [ 1.746, 190.8 ], [ 1.775, 178.8 ], [ 1.8, 90.4 ], [ 1.82, 34.15 ], [ 1.859, 0 ]
],
    "totImpulseNs": 399,
    "totalWeightG": 527,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 667.8,
    "burnTimeS": 0.6,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I599",
    "dataFiles": 2,
    "delays": "P",
    "designation": "I599N",
    "diameter": 54,
    "impulseClass": "I",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 763.5,
    "motorId": "5f4294d200023100000001ea",
    "propInfo": "Warp 9",
    "propWeightG": 195,
    "samples": [
      [ 0, 0 ], [ 0.007, 179.4 ], [ 0.01, 495.9 ], [ 0.012, 578.1 ], [ 0.014, 647.9 ], [ 0.017, 797.4 ],
      [ 0.024, 593.1 ], [ 0.032, 647.9 ], [ 0.045, 677.8 ], [ 0.051, 702.7 ], [ 0.055, 677.8 ],
      [ 0.062, 720.2 ], [ 0.076, 707.7 ], [ 0.12, 752.6 ], [ 0.202, 757.6 ], [ 0.225, 755.1 ],
      [ 0.241, 735.1 ], [ 0.25, 720.2 ], [ 0.263, 730.2 ], [ 0.329, 722.7 ], [ 0.399, 697.8 ],
      [ 0.45, 667.9 ], [ 0.501, 618 ], [ 0.536, 585.6 ], [ 0.55, 580.6 ], [ 0.564, 585.6 ],
      [ 0.578, 633 ], [ 0.581, 555.7 ], [ 0.584, 426.1 ], [ 0.589, 299 ], [ 0.595, 176.9 ],
      [ 0.598, 112.1 ], [ 0.604, 57.32 ], [ 0.608, 24.92 ], [ 0.619, 12.46 ], [ 0.623, 0 ]
],
    "totImpulseNs": 404.9,
    "totalWeightG": 512,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 117,
    "burnTimeS": 3.1,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I117",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I117FJ",
    "diameter": 54,
    "impulseClass": "I",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001eb",
    "propInfo": "Black Max",
    "propWeightG": 243,
    "samples": [
      [ 0, 0 ], [ 0.014, 65.9 ], [ 0.021, 94.46 ], [ 0.055, 120.8 ], [ 0.089, 107.6 ], [ 0.179, 107.6 ],
      [ 0.344, 124.1 ], [ 0.385, 134 ], [ 0.447, 123 ], [ 0.509, 138.4 ], [ 0.564, 131.8 ],
      [ 0.646, 146.1 ], [ 0.708, 145 ], [ 0.736, 127.4 ], [ 0.75, 149.4 ], [ 0.798, 141.7 ],
      [ 0.825, 164.7 ], [ 0.866, 152.7 ], [ 1.004, 158.2 ], [ 1.141, 156 ], [ 1.224, 154.9 ],
      [ 1.492, 156 ], [ 2.304, 121.9 ], [ 2.407, 118.6 ], [ 2.482, 117.5 ], [ 2.544, 128.5 ],
      [ 2.599, 112 ], [ 2.654, 75.78 ], [ 2.695, 45.03 ], [ 2.75, 16.48 ], [ 2.771, 8.787 ],
      [ 2.806, 0 ]
],
    "totImpulseNs": 361,
    "totalWeightG": 566,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 2.9,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J250",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J250FJ",
    "diameter": 54,
    "impulseClass": "J",
    "length": 241,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001ec",
    "propInfo": "Black Max",
    "propWeightG": 487,
    "samples": [
      [ 0, 0 ], [ 0.011, 132.2 ], [ 0.021, 263.3 ], [ 0.084, 236.9 ], [ 0.168, 252.2 ], [ 0.294, 238.9 ],
      [ 0.494, 261.3 ], [ 0.715, 285.7 ], [ 0.993, 295.9 ], [ 1.177, 306 ], [ 1.267, 305 ],
      [ 1.498, 302 ], [ 1.64, 292.8 ], [ 2.002, 253.2 ], [ 2.344, 224.7 ], [ 2.391, 225.7 ],
      [ 2.502, 233.8 ], [ 2.57, 185 ], [ 2.659, 116.9 ], [ 2.685, 76.25 ], [ 2.738, 32.54 ],
      [ 2.77, 17.29 ], [ 2.796, 0 ]
],
    "totImpulseNs": 731,
    "totalWeightG": 907,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 556.8,
    "burnTimeS": 2.79,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K513",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K513FJ",
    "diameter": 54,
    "impulseClass": "K",
    "length": 410,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 658.3,
    "motorId": "5f4294d200023100000001ee",
    "propInfo": "Black Max",
    "propWeightG": 974,
    "samples": [
      [ 0, 0 ], [ 0.007, 555.1 ], [ 0.015, 652.7 ], [ 0.041, 551.7 ], [ 0.059, 592.1 ], [ 0.074, 551.7 ],
      [ 0.2, 575.3 ], [ 0.282, 582 ], [ 0.412, 605.6 ], [ 0.519, 615.6 ], [ 0.679, 635.8 ],
      [ 0.823, 642.6 ], [ 0.986, 649.3 ], [ 1.12, 652.7 ], [ 1.328, 656 ], [ 1.48, 649.3 ],
      [ 1.602, 629.1 ], [ 1.854, 585.4 ], [ 1.999, 555.1 ], [ 2.054, 545 ], [ 2.08, 545 ],
      [ 2.114, 528.2 ], [ 2.184, 524.8 ], [ 2.229, 521.5 ], [ 2.258, 504.6 ], [ 2.295, 474.4 ],
      [ 2.325, 437.3 ], [ 2.358, 380.2 ], [ 2.37, 349.9 ], [ 2.399, 312.9 ], [ 2.451, 272.5 ],
      [ 2.492, 238.9 ], [ 2.529, 161.5 ], [ 2.57, 84.11 ], [ 2.592, 43.73 ], [ 2.618, 26.91 ],
      [ 2.655, 13.46 ], [ 2.692, 6.728 ], [ 2.726, 0 ]
],
    "totImpulseNs": 1496,
    "totalWeightG": 1647,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 828,
    "burnTimeS": 2.5,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K828",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K828FJ",
    "diameter": 54,
    "impulseClass": "K",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000001ef",
    "propInfo": "Black Max",
    "propWeightG": 1373,
    "samples": [
      [ 0, 0 ], [ 0.01, 1112 ], [ 0.02, 1112 ], [ 0.04, 1239 ], [ 0.06, 1304 ], [ 0.08, 1135 ],
      [ 0.13, 1078 ], [ 0.2, 1032 ], [ 0.5, 1016 ], [ 0.65, 993.2 ], [ 1, 1005 ], [ 1.08, 985.5 ],
      [ 1.19, 974 ], [ 1.42, 974 ], [ 1.51, 954.8 ], [ 1.69, 935.7 ], [ 1.75, 912.6 ], [ 1.83, 885.8 ],
      [ 1.89, 893.5 ], [ 1.95, 843.6 ], [ 2, 774.6 ], [ 2.15, 667.2 ], [ 2.2, 444.8 ], [ 2.23, 364.3 ],
      [ 2.27, 260.8 ], [ 2.33, 184.1 ], [ 2.39, 111.2 ], [ 2.5, 0 ], [ 2.5, 49.85 ]
],
    "totImpulseNs": 2120,
    "totalWeightG": 2223,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 763.6,
    "burnTimeS": 2.27,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K763",
    "dataFiles": 1,
    "designation": "K763GT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 894.7,
    "motorId": "5f4294d200023100000001f0",
    "propInfo": "Green Tornado",
    "propWeightG": 975,
    "samples": [
      [ 0, 0 ], [ 0.05, 498.4 ], [ 0.07, 565.1 ], [ 0.08, 623 ], [ 0.1, 689.8 ], [ 0.12, 720.9 ],
      [ 0.25, 801 ], [ 0.38, 845.5 ], [ 0.65, 867.8 ], [ 0.83, 854.4 ], [ 1.95, 778.8 ], [ 2.1, 712 ],
      [ 2.15, 400.5 ], [ 2.25, 44.5 ], [ 2.27, 0 ]
],
    "totImpulseNs": 1740,
    "totalWeightG": 1792,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 556,
    "burnTimeS": 2.51,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K555",
    "dataFiles": 1,
    "designation": "K555GT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 665.5,
    "motorId": "5f4294d200023100000001f1",
    "propInfo": "Green Tornado",
    "propWeightG": 780,
    "samples": [
      [ 0, 0 ], [ 0.025, 267 ], [ 0.05, 338.2 ], [ 0.1, 471.7 ], [ 0.12, 498.4 ], [ 0.15, 511.8 ],
      [ 0.18, 522.9 ], [ 0.2, 534 ], [ 0.7, 631.9 ], [ 0.75, 636.4 ], [ 0.9, 645.3 ], [ 1.15, 636.4 ],
      [ 1.57, 623 ], [ 1.87, 614.1 ], [ 2.17, 600.8 ], [ 2.25, 578.5 ], [ 2.27, 480.6 ], [ 2.3, 356 ],
      [ 2.35, 178 ], [ 2.45, 66.75 ], [ 2.51, 0 ]
],
    "totImpulseNs": 1397,
    "totalWeightG": 1577,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 467,
    "burnTimeS": 2.24,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J465",
    "dataFiles": 1,
    "designation": "J465GT",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 662.7,
    "motorId": "5f4294d200023100000001f2",
    "propInfo": "Green Tornado",
    "propWeightG": 566,
    "samples": [
      [ 0, 0 ], [ 0.09, 364.9 ], [ 0.1, 382.7 ], [ 0.14, 400.5 ], [ 1.16, 662.6 ], [ 1.27, 650 ],
      [ 2, 338.2 ], [ 2.05, 231.4 ], [ 2.1, 124.6 ], [ 2.24, 0 ]
],
    "totImpulseNs": 1023,
    "totalWeightG": 1182,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 221.6,
    "burnTimeS": 5.938,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K222",
    "dataFiles": 1,
    "designation": "K222WC",
    "diameter": 54,
    "impulseClass": "K",
    "length": 402,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 444.4,
    "motorId": "5f4294d200023100000001f3",
    "propInfo": "White Cloud",
    "propWeightG": 800,
    "samples": [
      [ 0, 0 ], [ 0.1, 400.5 ], [ 0.15, 378.3 ], [ 0.4, 422.8 ], [ 0.5, 347.1 ], [ 1.15, 289.3 ],
      [ 2.25, 267 ], [ 3, 231.4 ], [ 4, 169.1 ], [ 5.938, 44.5 ], [ 6.2, 22.25 ], [ 6.6, 0 ]
],
    "totImpulseNs": 1314,
    "totalWeightG": 1597,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 167.3,
    "burnTimeS": 5.68,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J167",
    "dataFiles": 1,
    "designation": "J167WC",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 366.5,
    "motorId": "5f4294d200023100000001f4",
    "propInfo": "White Cloud",
    "propWeightG": 600,
    "samples": [
      [ 0, 0 ], [ 0.1, 307.1 ], [ 0.2, 298.1 ], [ 0.4, 369.4 ], [ 0.5, 311.5 ], [ 0.6, 267 ],
      [ 1.5, 235.8 ], [ 5.85, 17.8 ], [ 6.1, 0 ]
],
    "totImpulseNs": 950,
    "totalWeightG": 1216,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 442.5,
    "burnTimeS": 2.57,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J450",
    "dataFiles": 1,
    "designation": "J450BL",
    "diameter": 54,
    "impulseClass": "J",
    "length": 402,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 601.8,
    "motorId": "5f4294d200023100000001f5",
    "propInfo": "Black Lightning",
    "propWeightG": 658,
    "samples": [
      [ 0, 0 ], [ 0.08, 124.6 ], [ 0.19, 333.8 ], [ 0.3, 413.9 ], [ 0.68, 556.3 ], [ 0.8, 578.5 ],
      [ 1.05, 601 ], [ 1.49, 547.4 ], [ 2.2, 409.4 ], [ 2.3, 267 ], [ 2.4, 84.55 ], [ 2.57, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1121,
    "totalWeightG": 1455,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 364.9,
    "burnTimeS": 2.38,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J365",
    "dataFiles": 1,
    "designation": "J365BL",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 498.7,
    "motorId": "5f4294d200023100000001f6",
    "propInfo": "Black Lightning",
    "propWeightG": 498,
    "samples": [
      [ 0, 0 ], [ 0.04, 102.3 ], [ 0.06, 155.8 ], [ 0.1, 192.8 ], [ 0.1596, 242.3 ], [ 0.4, 315.9 ],
      [ 0.6, 400.5 ], [ 0.7, 436.1 ], [ 0.88, 480.6 ], [ 1.18, 498.4 ], [ 1.6, 445 ], [ 2.05, 378.3 ],
      [ 2.14, 213.6 ], [ 2.2, 102.3 ], [ 2.3, 22.25 ], [ 2.38, 0 ]
],
    "sparky": true,
    "totImpulseNs": 852.5,
    "totalWeightG": 1204,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 326.5,
    "burnTimeS": 5.18,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K327",
    "dataFiles": 1,
    "designation": "K327WC",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 684.5,
    "motorId": "5f4294d200023100000001f7",
    "propInfo": "White Cloud",
    "propWeightG": 1000,
    "samples": [
      [ 0, 0 ], [ 0.05, 556.3 ], [ 0.15, 520.6 ], [ 0.5, 684.5 ], [ 0.65, 534 ], [ 5.18, 22.25 ],
      [ 5.5, 0 ]
],
    "totImpulseNs": 1616,
    "totalWeightG": 1817,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 533.4,
    "burnTimeS": 2.66,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K533",
    "dataFiles": 1,
    "designation": "K533BL",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 699.5,
    "motorId": "5f4294d200023100000001f8",
    "propInfo": "Black Lightning",
    "propWeightG": 900,
    "samples": [
      [ 0, 0 ], [ 0.1, 467.3 ], [ 0.75, 694.2 ], [ 1.2, 680.9 ], [ 1.55, 631.9 ], [ 2.25, 489.5 ],
      [ 2.65, 17.8 ], [ 2.7, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1420,
    "totalWeightG": 1717,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 11.57,
    "burnTimeS": 2.83,
    "caseInfo": "RMS-24/40",
    "certOrg": "National Association of Rocketry",
    "commonName": "E11",
    "dataFiles": 2,
    "delays": "3",
    "designation": "E11J",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/E11.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 19.85,
    "motorId": "5f4294d200023100000001f9",
    "propInfo": "Blackjack",
    "propWeightG": 25,
    "samples": [
      [ 0, 0 ], [ 0.07254, 14.37 ], [ 0.1618, 17.63 ], [ 0.2065, 18.37 ], [ 0.4185, 19.26 ],
      [ 0.731, 18.37 ], [ 1.317, 14.22 ], [ 1.92, 9.037 ], [ 2.511, 2.222 ], [ 2.83, 0 ]
],
    "totImpulseNs": 32.74,
    "totalWeightG": 62.4,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 612,
    "burnTimeS": 1.09,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I600",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "I600R",
    "diameter": 38,
    "impulseClass": "I",
    "length": 345.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 811.7,
    "motorId": "5f4294d200023100000001fa",
    "propInfo": "Redline",
    "propWeightG": 324,
    "samples": [
      [ 0, 0 ], [ 0.005, 40.44 ], [ 0.046, 817.8 ], [ 0.059, 813.3 ], [ 0.1, 772.8 ], [ 0.2, 736.9 ],
      [ 0.4, 696.4 ], [ 0.5, 669.5 ], [ 0.6, 620.1 ], [ 0.796, 539.2 ], [ 0.894, 485.3 ],[ 0.951, 453.8 ],
      [ 0.964, 435.8 ], [ 1, 274.1 ], [ 1.052, 152.8 ], [ 1.106, 62.9 ], [ 1.144, 13.48 ],
      [ 1.18, 0 ]
],
    "totImpulseNs": 597.3,
    "totalWeightG": 602,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 540,
    "burnTimeS": 1.18,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I540",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "634I540-16A",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.csrocketry.com/rocket-motors/cesaroni/motors/pro-38/5g-reloads/cesaroni-i540-16a-white-thunder-rocket-motor.html",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "motorId": "5f4294d200023100000001fb",
    "propInfo": "White Thunder",
    "propWeightG": 309,
    "samples": [
      [ 0, 0 ], [ 0.03, 597.9 ], [ 0.04, 611.3 ], [ 0.06, 605.6 ], [ 0.12, 612.4 ], [ 0.24, 624.5 ],
      [ 0.36, 626 ], [ 0.48, 623.6 ], [ 0.6, 616.4 ], [ 0.72, 598.1 ], [ 0.84, 583.2 ], [ 0.95, 568.9 ],
      [ 0.96, 558.5 ], [ 0.98, 533.5 ], [ 1.02, 436.5 ], [ 1.06, 303.1 ], [ 1.09, 184.9 ],
      [ 1.13, 74.27 ], [ 1.18, 0 ]
],
    "totImpulseNs": 634.6,
    "totalWeightG": 598.2,
    "type": "reload",
    "updatedOn": "2022-04-29"
  },
  {
    "availability": "regular",
    "avgThrustN": 613,
    "burnTimeS": 8.13,
    "caseInfo": "Pro98-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L610",
    "dataFiles": 2,
    "designation": "4842L610-P",
    "diameter": 98,
    "impulseClass": "L",
    "length": 394,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 930,
    "motorId": "5f4294d200023100000001fc",
    "propInfo": "Classic",
    "propWeightG": 2415,
    "samples": [
      [ 0, 0 ], [ 0.06, 262.5 ], [ 0.12, 667.2 ], [ 0.25, 929.7 ], [ 0.39, 871.2 ], [ 0.65, 849.8 ],
      [ 1.05, 823.1 ], [ 1.5, 785.7 ], [ 2, 747.3 ], [ 2.5, 707.3 ], [ 3, 667.2 ], [ 3.48, 641.4 ],
      [ 4, 593.3 ], [ 4.47, 561.2 ], [ 5, 523.8 ], [ 5.44, 502.4 ], [ 5.68, 491.7 ], [ 6, 475.7 ],
      [ 6.5, 459.7 ], [ 7.01, 443.6 ], [ 7.5, 413.7 ], [ 8, 284.7 ], [ 8.12, 53.3 ], [ 8.13, 0 ]
],
    "totImpulseNs": 4842,
    "totalWeightG": 4975,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 794,
    "burnTimeS": 12.76,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M795",
    "dataFiles": 2,
    "designation": "10133M795-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 794,
    "motorId": "5f4294d200023100000001fd",
    "propInfo": "Classic",
    "propWeightG": 4892,
    "samples": [
      [ 0, 0 ], [ 0.15, 612.3 ], [ 0.21, 1533 ], [ 0.245, 1722 ], [ 0.43, 1718 ], [ 0.5, 1543 ],
      [ 0.62, 1430 ], [ 0.8, 1390 ], [ 1, 1374 ], [ 1.5, 1339 ], [ 2, 1305 ], [ 3, 1272 ], [ 4, 1204 ],
      [ 5, 1078 ], [ 6, 928 ], [ 7, 743 ], [ 8, 563 ], [ 9, 424.9 ], [ 10, 299.7 ], [ 11, 196.2 ],
      [ 12, 116.8 ], [ 12.7, 65.43 ], [ 12.76, 0 ]
],
    "totImpulseNs": 10130,
    "totalWeightG": 8492,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1057,
    "burnTimeS": 7.23,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1060",
    "dataFiles": 2,
    "designation": "7441M1060-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 548,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1495,
    "motorId": "5f4294d200023100000001fe",
    "propInfo": "Classic",
    "propWeightG": 3622,
    "samples": [
      [ 0, 0 ], [ 0.07, 131 ], [ 0.1, 594 ], [ 0.2, 1453 ], [ 0.238, 1494 ], [ 0.378, 1450 ],
      [ 0.5, 1423 ], [ 1, 1462 ], [ 1.5, 1456 ], [ 2, 1430 ], [ 2.5, 1376 ], [ 3, 1280 ], [ 3.5, 1190 ],
      [ 4, 1051 ], [ 4.5, 976 ], [ 5, 883 ], [ 5.5, 835 ], [ 6, 793 ], [ 6.5, 321 ], [ 7, 13 ],
      [ 7.229, 7 ], [ 7.23, 0 ]
],
    "totImpulseNs": 7441,
    "totalWeightG": 6673,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1456,
    "burnTimeS": 6.87,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1450",
    "dataFiles": 2,
    "designation": "9955M1450-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2416,
    "motorId": "5f4294d200023100000001ff",
    "propInfo": "Classic",
    "propWeightG": 4830,
    "samples": [
      [ 0, 0 ], [ 0.01, 60 ], [ 0.06, 524 ], [ 0.1, 2164 ], [ 0.151, 2416 ], [ 0.25, 2162 ],
      [ 0.5, 2037 ], [ 0.75, 2022 ], [ 1, 2009 ], [ 1.5, 2006 ], [ 2, 1968 ], [ 2.5, 1895 ],
      [ 3, 1770 ], [ 3.5, 1673 ], [ 4, 1517 ], [ 4.5, 1337 ], [ 5, 1166 ], [ 5.5, 954 ], [ 5.8, 687 ],
      [ 6.2, 360 ], [ 6.86, 79 ], [ 6.87, 0 ]
],
    "totImpulseNs": 9955,
    "totalWeightG": 8578,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1120,
    "burnTimeS": 12.49,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N1100",
    "dataFiles": 2,
    "designation": "14005N1100-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1046,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1120,
    "motorId": "5f4294d20002310000000200",
    "propInfo": "Classic",
    "propWeightG": 6790,
    "samples": [
      [ 0, 0 ], [ 0.16, 2624 ], [ 0.33, 2708 ], [ 0.91, 2055 ], [ 1.22, 1896 ], [ 2.44, 1793 ],
      [ 3.66, 1625 ], [ 4.88, 1402 ], [ 6.12, 1158 ], [ 7.41, 854 ], [ 9.77, 494 ], [ 12.18, 111.2 ],
      [ 12.19, 0 ]
],
    "totImpulseNs": 14010,
    "totalWeightG": 11590,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 54.78,
    "burnTimeS": 2.79,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G55",
    "dataFiles": 1,
    "designation": "150G55-P",
    "diameter": 38,
    "impulseClass": "G",
    "length": 308,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 256.4,
    "motorId": "5f4294d20002310000000201",
    "samples": [
      [ 0, 0 ], [ 0.053, 144.1 ], [ 0.124, 75.17 ], [ 0.212, 73.42 ], [ 0.313, 70.98 ], [ 0.649, 62.94 ],
      [ 0.879, 57.34 ], [ 1.02, 55.24 ], [ 1.162, 54.19 ], [ 1.239, 56.99 ], [ 1.498, 54.19 ],
      [ 1.539, 51.4 ], [ 1.634, 50 ], [ 1.693, 52.1 ], [ 1.822, 51.75 ], [ 1.899, 50 ], [ 2.047, 48.25 ],
      [ 2.094, 46.15 ], [ 2.17, 39.86 ], [ 2.247, 36.36 ], [ 2.577, 22.38 ], [ 2.955, 15.38 ],
      [ 3.138, 0 ]
],
    "totImpulseNs": 150.6,
    "totalWeightG": 465.8,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 460,
    "burnTimeS": 4.33,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K460",
    "dataFiles": 1,
    "designation": "1988K460-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 762,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 768,
    "motorId": "5f4294d20002310000000202",
    "samples": [
      [ 0, 0 ], [ 0.048, 263.8 ], [ 0.181, 789.8 ], [ 0.21, 769.5 ], [ 0.229, 728.9 ], [ 0.31, 711.7 ],
      [ 0.344, 694.5 ], [ 0.368, 672.7 ], [ 0.411, 658.6 ], [ 0.477, 638.4 ], [ 0.535, 625.9 ],
      [ 0.573, 618.1 ], [ 0.601, 607.1 ], [ 0.764, 496.3 ], [ 0.792, 483.8 ], [ 0.826, 477.6 ],
      [ 0.964, 458.9 ], [ 1.093, 429.2 ], [ 1.232, 533.8 ], [ 1.27, 563.4 ], [ 1.298, 602.5 ],
      [ 1.356, 613.4 ], [ 1.432, 630.6 ], [ 1.489, 629 ], [ 1.551, 619.6 ], [ 1.599, 614.9 ],
      [ 1.642, 616.5 ], [ 1.718, 633.7 ], [ 1.8, 643 ], [ 1.857, 657.1 ], [ 1.895, 654 ],[ 1.957, 669.6 ],
      [ 2.019, 677.4 ], [ 2.081, 677.4 ], [ 2.153, 652.4 ], [ 2.277, 607.1 ], [ 2.396, 575.9 ],
      [ 2.477, 558.8 ], [ 2.525, 549.4 ], [ 2.597, 510.4 ], [ 2.678, 437 ], [ 2.764, 363.7 ],
      [ 2.883, 285.6 ], [ 3.141, 181.1 ], [ 3.341, 124.9 ], [ 3.728, 63.99 ], [ 4, 0 ]
],
    "totImpulseNs": 1988,
    "totalWeightG": 3650,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 598.8,
    "burnTimeS": 5.33,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L600",
    "dataFiles": 1,
    "designation": "3161L600-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 1016,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 1647,
    "motorId": "5f4294d20002310000000203",
    "samples": [
      [ 0, 0 ], [ 0.072, 871.3 ], [ 0.119, 580.9 ], [ 0.215, 674.3 ], [ 0.263, 564.2 ], [ 0.31, 704.4 ],
      [ 0.43, 831.2 ], [ 0.477, 787.8 ], [ 0.561, 1148 ], [ 0.633, 1098 ], [ 0.752, 1125 ],
      [ 0.883, 1102 ], [ 0.979, 804.5 ], [ 1.134, 761.1 ], [ 1.241, 847.9 ], [ 1.337, 767.8 ],
      [ 1.42, 754.4 ], [ 1.468, 774.5 ], [ 1.552, 744.4 ], [ 1.946, 711 ], [ 2.626, 684.3 ],
      [ 3.056, 674.3 ], [ 3.39, 614.2 ], [ 3.473, 630.9 ], [ 3.593, 627.6 ], [ 3.724, 610.9 ],
      [ 3.796, 574.2 ], [ 3.927, 450.7 ], [ 4.058, 380.6 ], [ 4.393, 257 ], [ 4.727, 166.9 ],
      [ 5.168, 63.43 ], [ 5.443, 0 ]
],
    "totImpulseNs": 3161,
    "totalWeightG": 5134,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 660,
    "burnTimeS": 8.47,
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M700",
    "dataFiles": 1,
    "designation": "5592M700-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1473,
    "manufacturer": "West Coast Hybrids",
    "manufacturerAbbrev": "WCH",
    "maxThrustN": 1928,
    "motorId": "5f4294d20002310000000204",
    "samples": [
      [ 0, 0 ], [ 0.06, 387.3 ], [ 0.12, 931.7 ], [ 0.215, 1005 ], [ 0.251, 1257 ], [ 0.394, 1253 ],
      [ 0.49, 1023 ], [ 0.645, 869.6 ], [ 0.765, 964.6 ], [ 0.813, 825.8 ], [ 1.028, 781.9 ],
      [ 1.183, 898.9 ], [ 1.41, 902.5 ], [ 1.924, 909.8 ], [ 2.295, 858.7 ], [ 2.51, 855 ],
      [ 2.773, 869.6 ], [ 2.976, 898.9 ], [ 3.43, 880.6 ], [ 4.231, 829.4 ], [ 5.187, 760 ],
      [ 5.378, 738.1 ], [ 5.558, 712.5 ], [ 5.677, 679.6 ], [ 5.833, 595.6 ], [ 5.952, 526.2 ],
      [ 6.263, 405.6 ], [ 6.884, 270.4 ], [ 7.542, 153.5 ], [ 8.438, 0 ]
],
    "totImpulseNs": 5592,
    "totalWeightG": 7268,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 136,
    "burnTimeS": 3.55,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I110",
    "dataFiles": 2,
    "designation": "I110-LW",
    "diameter": 38,
    "impulseClass": "I",
    "length": 298,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 284,
    "motorId": "5f4294d20002310000000205",
    "propInfo": "Loki White",
    "samples": [
      [ 0, 0 ], [ 0.001, 282.4 ], [ 0.0358, 218.7 ], [ 0.1228, 170.9 ], [ 0.2156, 163.6 ],
      [ 0.4012, 169.1 ], [ 1.503, 176.5 ], [ 2.002, 152.6 ], [ 2.501, 108.4 ], [ 3, 64.33 ],
      [ 4.001, 0 ]
],
    "totImpulseNs": 487.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 215,
    "burnTimeS": 5.3,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J175",
    "dataFiles": 2,
    "designation": "J175-LW",
    "diameter": 54,
    "impulseClass": "J",
    "length": 330,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 478,
    "motorId": "5f4294d20002310000000206",
    "propInfo": "Loki White",
    "propWeightG": 654,
    "samples": [
      [ 0, 0 ], [ 0.01, 4.8 ], [ 0.1, 420.4 ], [ 0.2, 347.5 ], [ 0.3, 349.9 ], [ 0.4, 364.5 ],
      [ 0.5, 320.7 ], [ 1, 296.4 ], [ 1.5, 279.4 ], [ 2, 260 ], [ 2.5, 238.1 ], [ 3, 209 ],[ 3.5, 172 ],
      [ 4, 133.6 ], [ 4.5, 102 ], [ 5, 75.3 ], [ 5.5, 46.2 ], [ 6, 17 ], [ 6.5, 0 ]
],
    "totImpulseNs": 1146,
    "totalWeightG": 1250,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 840,
    "burnTimeS": 1.3,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J820",
    "dataFiles": 2,
    "designation": "J820-LW",
    "diameter": 54,
    "impulseClass": "J",
    "length": 330,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1281,
    "motorId": "5f4294d20002310000000207",
    "propInfo": "Loki White",
    "propWeightG": 643,
    "samples": [
      [ 0, 0 ], [ 0.01, 4.8 ], [ 0.02, 982.4 ], [ 0.07, 1033 ], [ 0.11, 982.4 ], [ 0.2, 959.9 ],
      [ 0.3, 943 ], [ 0.4, 947 ], [ 0.5, 972.8 ], [ 0.6, 981.4 ], [ 0.7, 990 ], [ 0.8, 990 ],
      [ 0.9, 981.4 ], [ 0.95, 1033 ], [ 1, 981.4 ], [ 1.1, 641.4 ], [ 1.2, 353 ], [ 1.3, 206.6 ],
      [ 1.4, 120.5 ], [ 1.5, 0 ]
],
    "totImpulseNs": 1108,
    "totalWeightG": 1210,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 554.5,
    "burnTimeS": 3.04,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K555",
    "dataFiles": 1,
    "designation": "K555-BG",
    "diameter": 54,
    "impulseClass": "K",
    "length": 1220,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 1200,
    "motorId": "5f4294d20002310000000208",
    "propWeightG": 450,
    "samples": [
      [ 0, 0 ], [ 0.05034, 769.9 ], [ 0.3356, 889.6 ], [ 0.5593, 1386 ], [ 0.8445, 872.5 ],
      [ 1.012, 581.7 ], [ 2.489, 342.2 ], [ 2.567, 222.4 ], [ 2.64, 136.9 ], [ 2.74, 51.33 ],
      [ 2.813, 17.11 ], [ 3.04, 0 ]
],
    "totImpulseNs": 1686,
    "totalWeightG": 2038,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 586,
    "burnTimeS": 3.6,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K543",
    "dataFiles": 1,
    "designation": "K543-BS",
    "diameter": 76,
    "impulseClass": "K",
    "length": 813,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 2129,
    "motorId": "5f4294d20002310000000209",
    "propWeightG": 500,
    "samples": [
      [ 0, 0 ], [ 0.001, 900 ], [ 2.991, 472.9 ], [ 3.125, 108.5 ], [ 3.601, 0 ]
],
    "totImpulseNs": 2096,
    "totalWeightG": 3606,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 896,
    "burnTimeS": 2.67,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K888",
    "dataFiles": 1,
    "designation": "K888-BM",
    "diameter": 76,
    "impulseClass": "K",
    "length": 1016,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 3025,
    "motorId": "5f4294d2000231000000020a",
    "propWeightG": 625,
    "samples": [
      [ 0, 0 ], [ 0.001, 1365 ], [ 0.501, 1365 ], [ 1.042, 1070 ], [ 2.498, 434.1 ], [ 2.543, 124 ],
      [ 2.701, 0 ]
],
    "totImpulseNs": 2400,
    "totalWeightG": 4173,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1531,
    "burnTimeS": 3.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1428",
    "dataFiles": 1,
    "designation": "L1428-SF",
    "diameter": 76,
    "impulseClass": "L",
    "length": 1753,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 3064,
    "motorId": "5f4294d2000231000000020b",
    "propWeightG": 1150,
    "samples": [
      [ 0, 0 ], [ 0.001, 1425 ], [ 0.4041, 2752 ], [ 0.801, 2725 ], [ 1.21, 1512 ], [ 2.651, 800 ],
      [ 3.001, 800 ], [ 3.101, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4733,
    "totalWeightG": 5738,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1693,
    "burnTimeS": 3.35,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1491",
    "dataFiles": 1,
    "designation": "M1491-BM",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1372,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 5706,
    "motorId": "5f4294d2000231000000020c",
    "propWeightG": 664,
    "samples": [
      [ 0, 0 ], [ 0.001, 2725 ], [ 0.4825, 2636 ], [ 0.8129, 2287 ], [ 1.334, 1860 ], [ 3.001, 900 ],
      [ 3.092, 503.9 ], [ 3.351, 0 ]
],
    "totImpulseNs": 5676,
    "totalWeightG": 4876,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 245,
    "burnTimeS": 1.06,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H248",
    "dataFiles": 1,
    "designation": "H248-PVC",
    "diameter": 38,
    "impulseClass": "H",
    "length": 719,
    "manufacturer": "Contrail Rockets",
    "manufacturerAbbrev": "Contrail",
    "maxThrustN": 746,
    "motorId": "5f4294d2000231000000020d",
    "propWeightG": 184,
    "samples": [
      [ 0, 0 ], [ 0.002237, 465.4 ], [ 0.08277, 698 ], [ 0.1119, 342.2 ], [ 0.5369, 266.9 ],
      [ 0.6398, 191.6 ], [ 0.7002, 184.8 ], [ 0.7383, 164.2 ], [ 1.1, 0 ]
],
    "totImpulseNs": 261.5,
    "totalWeightG": 793.8,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 10.58,
    "burnTimeS": 7.02,
    "certOrg": "National Association of Rocketry",
    "commonName": "F10",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F10",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Apogee/F10.pdf",
    "length": 92,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 22.69,
    "motorId": "5f4294d2000231000000020e",
    "propWeightG": 40,
    "samples": [
      [ 0, 0 ], [ 0.015, 28.22 ], [ 0.077, 26.08 ], [ 0.201, 24.93 ], [ 0.31, 22.81 ], [ 0.464, 20.18 ],
      [ 0.573, 17.89 ], [ 0.789, 16.07 ], [ 1.068, 13.95 ], [ 1.393, 12.63 ], [ 1.718, 11.15 ],
      [ 2.166, 9.844 ], [ 2.677, 9.515 ], [ 3.311, 9.187 ], [ 3.683, 8.859 ], [ 3.791, 9.679 ],
      [ 4.101, 9.679 ], [ 4.658, 9.515 ], [ 5.168, 9.023 ], [ 5.725, 9.023 ], [ 6.112, 8.531 ],
      [ 6.329, 8.859 ], [ 6.499, 7.546 ], [ 6.685, 5.742 ], [ 6.778, 4.921 ], [ 6.917, 2.625 ],
      [ 7.025, 1.312 ], [ 7.13, 0 ]
],
    "totImpulseNs": 74.26,
    "totalWeightG": 82.5,
    "type": "SU",
    "updatedOn": "2020-09-01"
  },
  {
    "availability": "regular",
    "avgThrustN": 6.47,
    "burnTimeS": 5.84,
    "certOrg": "National Association of Rocketry",
    "commonName": "E6",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "E6",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Apogee/E6.pdf",
    "length": 77,
    "manufacturer": "Apogee Components",
    "manufacturerAbbrev": "Apogee",
    "maxThrustN": 16.56,
    "motorId": "5f4294d2000231000000020f",
    "propWeightG": 22,
    "samples": [
      [ 0, 0 ], [ 0.056, 18.59 ], [ 0.112, 20.12 ], [ 0.168, 17.57 ], [ 0.307, 14.38 ], [ 0.531, 10.45 ],
      [ 0.894, 7.696 ], [ 1.146, 6.244 ], [ 1.691, 5.808 ], [ 2.836, 5.663 ], [ 3.898, 5.517 ],
      [ 4.275, 5.227 ], [ 4.415, 4.937 ], [ 5.058, 5.082 ], [ 5.519, 5.227 ], [ 5.603, 6.679 ],
      [ 5.729, 3.921 ], [ 5.882, 2.323 ], [ 5.966, 1.016 ], [ 6.06, 0 ]
],
    "totImpulseNs": 37.8,
    "totalWeightG": 46,
    "type": "SU",
    "updatedOn": "2020-09-01"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.93,
    "burnTimeS": 3.98,
    "certOrg": "National Association of Rocketry",
    "commonName": "D5",
    "dataFiles": 2,
    "designation": "D5-P",
    "diameter": 20,
    "impulseClass": "D",
    "infoUrl": "http://www.nar.org/SandT/pdf/Quest/D5_cn.pdf",
    "length": 88,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 11.49,
    "motorId": "5f4294d20002310000000210",
    "propInfo": "black powder",
    "propWeightG": 25,
    "samples": [
      [ 0, 0 ], [ 0.096, 1.241 ], [ 0.252, 5.897 ], [ 0.304, 8.586 ], [ 0.357, 10.55 ], [ 0.391, 11.48 ],
      [ 0.435, 9.828 ], [ 0.557, 6.103 ], [ 0.583, 5.172 ], [ 0.67, 5.172 ], [ 1.078, 4.966 ],
      [ 1.2, 4.345 ], [ 1.73, 4.759 ], [ 1.8, 4.759 ], [ 1.887, 4.138 ], [ 2.391, 5.069 ],
      [ 2.626, 4.966 ], [ 3.009, 5.379 ], [ 3.357, 5.276 ], [ 3.661, 5.69 ], [ 3.835, 3.103 ],
      [ 3.887, 1.655 ], [ 3.983, 0 ]
],
    "totImpulseNs": 19.61,
    "totalWeightG": 38,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 805,
    "burnTimeS": 2.2,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K805",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K805G",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000211",
    "propInfo": "Mojave Green",
    "propWeightG": 871.1,
    "samples": [
      [ 0, 0 ], [ 0.001, 7.669 ], [ 0.004882, 437.2 ], [ 0.0437, 797.6 ], [ 0.07864, 828.3 ],
      [ 0.1019, 774.6 ], [ 0.1602, 736.3 ], [ 0.199, 743.9 ], [ 0.2999, 774.6 ], [ 0.4008, 789.9 ],
      [ 0.5018, 797.6 ], [ 0.6027, 813 ], [ 0.6998, 813 ], [ 0.8007, 813 ], [ 0.9016, 813 ],
      [ 1.003, 813 ], [ 1.1, 782.3 ], [ 1.201, 766.9 ], [ 1.298, 759.3 ], [ 1.402, 759.3 ],
      [ 1.499, 743.9 ], [ 1.6, 720.9 ], [ 1.697, 697.9 ], [ 1.802, 682.6 ], [ 1.899, 659.6 ],
      [ 2, 644.2 ], [ 2.101, 628.9 ], [ 2.202, 644.2 ], [ 2.303, 544.5 ], [ 2.401, 0 ]
],
    "totImpulseNs": 1762,
    "totalWeightG": 1543,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2030,
    "burnTimeS": 2.6,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2030",
    "dataFiles": 1,
    "designation": "M2030G-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 653,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000212",
    "propInfo": "Mojave Green",
    "propWeightG": 2663,
    "samples": [
      [ 0, 0 ], [ 0.007764, 1342 ], [ 0.07764, 2147 ], [ 0.1553, 1841 ], [ 0.4969, 2224 ],
      [ 0.7842, 2608 ], [ 1.002, 2646 ], [ 1.359, 2531 ], [ 1.491, 2416 ], [ 1.995, 1802 ],
      [ 2.391, 1419 ], [ 2.5, 805.3 ], [ 2.562, 191.7 ], [ 2.675, 0 ]
],
    "totImpulseNs": 5485,
    "totalWeightG": 4906,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2100,
    "burnTimeS": 3.7,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2100",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M2100G",
    "diameter": 98,
    "impulseClass": "M",
    "length": 598,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000213",
    "propInfo": "Mojave Green",
    "propWeightG": 3948,
    "samples": [
      [ 0, 0 ], [ 0.014, 72.79 ], [ 0.017, 1148 ], [ 0.021, 1553 ], [ 0.038, 1593 ], [ 0.042, 1909 ],
      [ 0.052, 2062 ], [ 0.077, 2151 ], [ 0.115, 2208 ], [ 0.167, 2240 ], [ 0.223, 2256 ],
      [ 0.272, 2256 ], [ 0.31, 2232 ], [ 0.415, 2281 ], [ 0.59, 2386 ], [ 0.701, 2410 ], [ 0.876, 2507 ],
      [ 0.959, 2556 ], [ 1.043, 2588 ], [ 1.176, 2677 ], [ 1.263, 2782 ], [ 1.333, 2831 ],
      [ 1.437, 2847 ], [ 1.517, 2879 ], [ 1.608, 2968 ], [ 1.667, 2944 ], [ 1.81, 2936 ],[ 1.926, 2895 ],
      [ 1.999, 2847 ], [ 2.041, 2782 ], [ 2.152, 2531 ], [ 2.393, 1949 ], [ 2.484, 1787 ],
      [ 2.56, 1674 ], [ 2.738, 1504 ], [ 2.836, 1440 ], [ 2.958, 1367 ], [ 3.091, 1286 ],[ 3.178, 1229 ],
      [ 3.23, 1197 ], [ 3.283, 1181 ], [ 3.401, 1189 ], [ 3.447, 1140 ], [ 3.478, 1035 ],[ 3.513, 913.9 ],
      [ 3.516, 541.9 ], [ 3.527, 444.8 ], [ 3.541, 0 ]
],
    "totImpulseNs": 7802,
    "totalWeightG": 6918,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 76,
    "burnTimeS": 1.5,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G76",
    "dataFiles": 3,
    "delays": "4,5,6,7,8,10",
    "designation": "G76G",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G76G.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 196,
    "motorId": "5f4294d20002310000000214",
    "propInfo": "Mojave Green",
    "propWeightG": 60,
    "samples": [
      [ 0, 0 ], [ 0.004, 4.24 ], [ 0.006, 9.683 ], [ 0.016, 54.4 ], [ 0.03, 98.17 ], [ 0.034, 108.5 ],
      [ 0.04, 121.2 ], [ 0.048, 133 ], [ 0.058, 142.3 ], [ 0.068, 147.2 ], [ 0.082, 149.4 ],
      [ 0.112, 146.9 ], [ 0.15, 138.8 ], [ 0.17, 136 ], [ 0.376, 112.2 ], [ 0.466, 103.1 ],
      [ 0.482, 104.4 ], [ 0.56, 92.65 ], [ 0.606, 92.87 ], [ 0.644, 84.21 ], [ 0.748, 73.94 ],
      [ 0.76, 74.28 ], [ 0.79, 69.7 ], [ 0.804, 70.6 ], [ 0.822, 66.76 ], [ 0.856, 62.69 ],
      [ 1.154, 40 ], [ 1.374, 19.91 ], [ 1.474, 12.52 ], [ 1.574, 7.418 ], [ 1.78, 1.19 ],
      [ 2, 0 ]
],
    "totImpulseNs": 118,
    "totalWeightG": 147,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 8035,
    "burnTimeS": 5.1,
    "caseInfo": "Pro150-40k",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O8000",
    "dataFiles": 2,
    "designation": "40960O8000-P",
    "diameter": 161,
    "impulseClass": "O",
    "length": 957,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 8605,
    "motorId": "5f4294d20002310000000215",
    "propInfo": "White Thunder",
    "propWeightG": 18610,
    "samples": [
      [ 0, 0 ], [ 0.045, 3965 ], [ 0.046, 6743 ], [ 0.047, 8624 ], [ 0.125, 7929 ], [ 0.239, 8161 ],
      [ 0.364, 8392 ], [ 0.489, 8508 ], [ 0.614, 8537 ], [ 0.773, 8392 ], [ 0.989, 8421 ],
      [ 1.273, 8479 ], [ 1.602, 8624 ], [ 2.011, 8566 ], [ 2.33, 8566 ], [ 2.682, 8479 ],[ 3.102, 8277 ],
      [ 3.568, 8045 ], [ 3.886, 7900 ], [ 4.239, 7669 ], [ 4.591, 7524 ], [ 4.739, 7524 ],
      [ 4.909, 7264 ], [ 4.955, 7003 ], [ 4.977, 6540 ], [ 4.989, 5846 ], [ 5, 5006 ], [ 5.023, 4051 ],
      [ 5.034, 3068 ], [ 5.045, 1997 ], [ 5.08, 1013 ], [ 5.114, 318.3 ], [ 5.17, 0 ]
],
    "totImpulseNs": 40960,
    "totalWeightG": 32670,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 5787,
    "burnTimeS": 5.23,
    "caseInfo": "Pro150-30K",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O5800",
    "dataFiles": 2,
    "designation": "30600O5800-P",
    "diameter": 161,
    "impulseClass": "O",
    "length": 754,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 6339,
    "motorId": "5f4294d20002310000000216",
    "propInfo": "White Thunder",
    "propWeightG": 13950,
    "samples": [
      [ 0, 0 ], [ 0.069, 6338 ], [ 0.103, 5701 ], [ 0.218, 5875 ], [ 0.378, 6135 ], [ 0.561, 6338 ],
      [ 0.745, 6222 ], [ 0.985, 6222 ], [ 1.18, 6193 ], [ 1.455, 6309 ], [ 1.753, 6367 ],[ 1.994, 6338 ],
      [ 2.269, 6395 ], [ 2.509, 6309 ], [ 2.83, 6193 ], [ 3.14, 6048 ], [ 3.426, 5875 ], [ 3.69, 5730 ],
      [ 3.965, 5585 ], [ 4.263, 5383 ], [ 4.572, 5296 ], [ 4.939, 5180 ], [ 5.053, 5035 ],
      [ 5.11, 4717 ], [ 5.133, 4225 ], [ 5.145, 3675 ], [ 5.156, 3039 ], [ 5.179, 2344 ],[ 5.214, 1476 ],
      [ 5.259, 607.7 ], [ 5.294, 57.88 ], [ 5.295, 0 ]
],
    "totImpulseNs": 30610,
    "totalWeightG": 26370,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 113.5,
    "burnTimeS": 1.24,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G115",
    "dataFiles": 1,
    "delays": "4,6,8,10,13",
    "designation": "141G115-13A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 134.4,
    "motorId": "5f4294d20002310000000217",
    "propInfo": "White Thunder",
    "propWeightG": 61.8,
    "samples": [
      [ 0, 0 ], [ 0.007874, 10.34 ], [ 0.01837, 117.7 ], [ 0.021, 128.4 ], [ 0.04462, 131.9 ],
      [ 0.05774, 125.9 ], [ 0.06299, 119.4 ], [ 0.07874, 113.8 ], [ 0.1076, 115.9 ], [ 0.1496, 119 ],
      [ 0.189, 119.4 ], [ 0.2336, 122 ], [ 0.2782, 122.4 ], [ 0.3228, 122.8 ], [ 0.3806, 123.3 ],
      [ 0.4409, 122.8 ], [ 0.4803, 123.3 ], [ 0.5407, 122.8 ], [ 0.5906, 122.4 ], [ 0.622, 120.7 ],
      [ 0.6798, 120.3 ], [ 0.7349, 119.4 ], [ 0.79, 117.7 ], [ 0.8451, 116.8 ], [ 0.8819, 116.4 ],
      [ 0.9291, 113.4 ], [ 0.979, 113.4 ], [ 1.032, 111.2 ], [ 1.071, 108.2 ], [ 1.113, 108.2 ],
      [ 1.165, 106 ], [ 1.184, 112.5 ], [ 1.186, 96.55 ], [ 1.197, 81.03 ], [ 1.202, 51.72 ],
      [ 1.213, 30.17 ], [ 1.215, 16.81 ], [ 1.218, 7.759 ], [ 1.24, 0 ]
],
    "totImpulseNs": 140.6,
    "totalWeightG": 195,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 121.4,
    "burnTimeS": 2.15,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H120",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "261H120-14A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 149.4,
    "motorId": "5f4294d20002310000000218",
    "propInfo": "Red Lightning",
    "propWeightG": 136,
    "samples": [
      [ 0, 0 ], [ 0.016, 53.02 ], [ 0.029, 107.1 ], [ 0.036, 124.5 ], [ 0.049, 129.5 ], [ 0.062, 117.8 ],
      [ 0.072, 98.22 ], [ 0.131, 123.8 ], [ 0.199, 136.6 ], [ 0.258, 144.1 ], [ 0.313, 147.7 ],
      [ 0.369, 146.3 ], [ 0.441, 145.2 ], [ 0.558, 143.4 ], [ 0.683, 141.6 ], [ 0.777, 140.9 ],
      [ 0.859, 139.1 ], [ 0.98, 136.3 ], [ 1.097, 133.1 ], [ 1.251, 128.8 ], [ 1.434, 122.8 ],
      [ 1.558, 118.9 ], [ 1.639, 117.1 ], [ 1.731, 117.1 ], [ 1.884, 117.1 ], [ 1.927, 105.3 ],
      [ 1.959, 88.96 ], [ 1.995, 68.33 ], [ 2.031, 41.99 ], [ 2.083, 18.5 ], [ 2.142, 6.761 ],
      [ 2.181, 2.135 ], [ 2.24, 0 ]
],
    "totImpulseNs": 260.7,
    "totalWeightG": 295,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 226.3,
    "burnTimeS": 1.21,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H225",
    "dataFiles": 1,
    "delays": "5,7,9,11,14",
    "designation": "273H225-14A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 254.1,
    "motorId": "5f4294d20002310000000219",
    "propInfo": "White Thunder",
    "propWeightG": 123.6,
    "samples": [
      [ 0, 0 ], [ 0.002625, 263.8 ], [ 0.02362, 246.5 ], [ 0.03412, 234.5 ], [ 0.04199, 219.8 ],
      [ 0.09711, 237.1 ], [ 0.1365, 242.2 ], [ 0.2021, 242.2 ], [ 0.273, 242.2 ], [ 0.3307, 241.4 ],
      [ 0.378, 243.1 ], [ 0.4436, 243.1 ], [ 0.5039, 242.2 ], [ 0.5696, 241.4 ], [ 0.6352, 240.5 ],
      [ 0.7218, 238.8 ], [ 0.8215, 237.1 ], [ 0.9003, 234.5 ], [ 0.9685, 227.6 ], [ 1.037, 221.5 ],
      [ 1.068, 217.2 ], [ 1.118, 206 ], [ 1.131, 196.5 ], [ 1.142, 216.4 ], [ 1.152, 153.4 ],
      [ 1.16, 103.4 ], [ 1.171, 52.59 ], [ 1.189, 19.83 ], [ 1.21, 0 ]
],
    "totImpulseNs": 273.2,
    "totalWeightG": 193,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 345.1,
    "burnTimeS": 1.18,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I345",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "408I345-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 402.6,
    "motorId": "5f4294d2000231000000021a",
    "propInfo": "White Thunder",
    "propWeightG": 185.4,
    "samples": [
      [ 0, 0 ], [ 0.0105, 398.3 ], [ 0.03412, 376.7 ], [ 0.04462, 358.6 ], [ 0.07874, 358.6 ],
      [ 0.1286, 360.3 ], [ 0.1811, 362.9 ], [ 0.252, 363.8 ], [ 0.3228, 365.5 ], [ 0.3963, 362.9 ],
      [ 0.4541, 361.2 ], [ 0.5249, 359.5 ], [ 0.6037, 356 ], [ 0.6903, 352.6 ], [ 0.7638, 348.3 ],
      [ 0.8136, 348.3 ], [ 0.8583, 345.7 ], [ 0.916, 344 ], [ 1.005, 339.7 ], [ 1.05, 336.2 ],
      [ 1.081, 338.8 ], [ 1.1, 302.6 ], [ 1.121, 225 ], [ 1.136, 141.4 ], [ 1.147, 83.62 ],
      [ 1.152, 23.28 ], [ 1.18, 0 ]
],
    "totImpulseNs": 407.6,
    "totalWeightG": 395,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 253,
    "burnTimeS": 2.04,
    "caseInfo": "Pro38-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I255",
    "dataFiles": 1,
    "delays": "7,9,11,13,16",
    "designation": "517I255-16A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 303,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 313.9,
    "motorId": "5f4294d2000231000000021b",
    "propInfo": "Red Lightning",
    "propWeightG": 273.2,
    "samples": [
      [ 0, 0 ], [ 0.016, 105.8 ], [ 0.029, 213.8 ], [ 0.036, 248.6 ], [ 0.037, 272 ], [ 0.065, 252.2 ],
      [ 0.108, 278.4 ], [ 0.173, 298.3 ], [ 0.248, 308.3 ], [ 0.34, 313.9 ], [ 0.467, 311.1 ],
      [ 0.585, 304 ], [ 0.66, 298.3 ], [ 0.761, 294.8 ], [ 0.882, 287.7 ], [ 0.996, 278.4 ],
      [ 1.104, 272.8 ], [ 1.248, 258.5 ], [ 1.421, 249.3 ], [ 1.568, 250 ], [ 1.649, 245.1 ],
      [ 1.734, 243.6 ], [ 1.8, 207.4 ], [ 1.881, 114.4 ], [ 1.953, 55.4 ], [ 2.022, 20.6 ],
      [ 2.025, 0 ]
],
    "totImpulseNs": 517.3,
    "totalWeightG": 516,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 472.2,
    "burnTimeS": 1.14,
    "caseInfo": "Pro38-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I470",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "540I470-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 303,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 555.2,
    "motorId": "5f4294d2000231000000021c",
    "propInfo": "White Thunder",
    "propWeightG": 247.2,
    "samples": [
      [ 0, 0 ], [ 0.005249, 517.2 ], [ 0.01312, 563.8 ], [ 0.03675, 537.9 ], [ 0.03937, 506.9 ],
      [ 0.06824, 519 ], [ 0.09449, 519 ], [ 0.1312, 520.7 ], [ 0.1785, 524.1 ], [ 0.2178, 525.9 ],
      [ 0.2677, 525.9 ], [ 0.2992, 527.6 ], [ 0.3465, 522.4 ], [ 0.3885, 520.7 ], [ 0.4226, 522.4 ],
      [ 0.4593, 525.9 ], [ 0.5092, 522.4 ], [ 0.5538, 517.2 ], [ 0.5853, 515.5 ], [ 0.6378, 515.5 ],
      [ 0.6877, 508.6 ], [ 0.7297, 505.2 ], [ 0.7717, 508.6 ], [ 0.8241, 503.4 ], [ 0.8609, 496.5 ],
      [ 0.9003, 494.8 ], [ 0.9265, 491.4 ], [ 0.9738, 482.8 ], [ 0.9974, 415.5 ], [ 1.005, 374.1 ],
      [ 1.024, 337.9 ], [ 1.05, 282.8 ], [ 1.066, 231 ], [ 1.076, 181 ], [ 1.087, 134.5 ],
      [ 1.1, 86.21 ], [ 1.11, 32.76 ], [ 1.14, 0 ]
],
    "totImpulseNs": 540.1,
    "totalWeightG": 495,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 335.5,
    "burnTimeS": 1.93,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J335",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "649J335-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 410.6,
    "motorId": "5f4294d2000231000000021d",
    "propInfo": "Red Lightning",
    "propWeightG": 341.5,
    "samples": [
      [ 0, 0 ], [ 0.013, 313.7 ], [ 0.025, 364.3 ], [ 0.038, 409.5 ], [ 0.091, 409.5 ], [ 0.148, 405.7 ],
      [ 0.242, 402.6 ], [ 0.374, 405.7 ], [ 0.515, 401.1 ], [ 0.735, 387.3 ], [ 0.892, 378.9 ],
      [ 1.108, 360.5 ], [ 1.25, 349.7 ], [ 1.366, 346.7 ], [ 1.504, 344.4 ], [ 1.551, 334.4 ],
      [ 1.607, 320.6 ], [ 1.667, 257.7 ], [ 1.708, 179.5 ], [ 1.777, 95.87 ], [ 1.833, 44.48 ],
      [ 1.925, 14.57 ], [ 1.987, 0.767 ], [ 2.012, 0 ]
],
    "totImpulseNs": 649.2,
    "totalWeightG": 627,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 408.9,
    "burnTimeS": 1.89,
    "caseInfo": "Pro38-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J410",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "774J410-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 421,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 512.1,
    "motorId": "5f4294d2000231000000021e",
    "propInfo": "Red Lightning",
    "propWeightG": 409.8,
    "samples": [
      [ 0, 0 ], [ 0.023, 375.4 ], [ 0.029, 446.2 ], [ 0.042, 511 ], [ 0.11, 499 ], [ 0.22, 495.4 ],
      [ 0.442, 491.8 ], [ 0.675, 475 ], [ 0.901, 464.2 ], [ 1.092, 448.6 ], [ 1.221, 437.8 ],
      [ 1.34, 429.4 ], [ 1.492, 419.8 ], [ 1.553, 389.8 ], [ 1.592, 349.1 ], [ 1.65, 273.5 ],
      [ 1.689, 196.7 ], [ 1.75, 122.4 ], [ 1.809, 64.77 ], [ 1.889, 28.79 ], [ 1.941, 13.2 ],
      [ 1.989, 0 ]
],
    "totImpulseNs": 773.8,
    "totalWeightG": 735,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 324,
    "burnTimeS": 2.2,
    "caseInfo": "54/550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J348",
    "dataFiles": 1,
    "designation": "J348B",
    "diameter": 54,
    "impulseClass": "J",
    "length": 609,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 1553,
    "motorId": "5f4294d2000231000000021f",
    "propInfo": "ABS/B, 550cc nitrous",
    "propWeightG": 91,
    "samples": [
      [ 0, 0 ], [ 0.001, 8.88 ], [ 0.021, 451.8 ], [ 0.131, 557.6 ], [ 0.141, 1204 ], [ 0.201, 617.6 ],
      [ 0.401, 549 ], [ 0.601, 494.7 ], [ 0.791, 406.1 ], [ 1.011, 354.6 ], [ 1.201, 334.6 ],
      [ 1.311, 303.1 ], [ 1.401, 237.3 ], [ 1.601, 148.7 ], [ 1.801, 82.92 ], [ 2.001, 37.17 ],
      [ 2.201, 0 ]
],
    "totImpulseNs": 716,
    "totalWeightG": 1224,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 323,
    "burnTimeS": 3.5,
    "caseInfo": "54/550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J263",
    "dataFiles": 1,
    "designation": "J263G",
    "diameter": 54,
    "impulseClass": "J",
    "length": 749,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 2354,
    "motorId": "5f4294d20002310000000220",
    "propInfo": "ABS/G, 830cc nitrous",
    "propWeightG": 89,
    "samples": [
      [ 0, 0 ], [ 0.01, 1359 ], [ 0.09, 794.3 ], [ 0.15, 559.9 ], [ 0.21, 419.3 ], [ 0.4, 296.9 ],
      [ 0.61, 286.5 ], [ 0.81, 265.6 ], [ 1.01, 244.8 ], [ 1.2, 247.4 ], [ 1.4, 244.8 ], [ 1.6, 231.8 ],
      [ 1.81, 200.5 ], [ 2.01, 205.7 ], [ 2.21, 205.7 ], [ 2.41, 195.3 ], [ 2.6, 174.5 ],[ 2.8, 166.7 ],
      [ 2.91, 161.5 ], [ 3, 138 ], [ 3.08, 98.96 ], [ 3.21, 62.5 ], [ 3.41, 28.74 ], [ 3.6, 0 ]
],
    "totImpulseNs": 1248,
    "totalWeightG": 1338,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 347,
    "burnTimeS": 3,
    "caseInfo": "54/830",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J337",
    "dataFiles": 1,
    "designation": "J337B",
    "diameter": 54,
    "impulseClass": "J",
    "length": 749,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 1876,
    "motorId": "5f4294d20002310000000221",
    "propInfo": "ABS/B, 830cc nitrous",
    "propWeightG": 91,
    "samples": [
      [ 0, 0 ], [ 0.001, 8.88 ], [ 0.011, 1521 ], [ 0.101, 585.3 ], [ 0.201, 651.3 ], [ 0.411, 589.5 ],
      [ 0.501, 548.2 ], [ 0.601, 540 ], [ 0.991, 461.7 ], [ 1.401, 416.3 ], [ 1.601, 391.6 ],
      [ 1.801, 387.5 ], [ 1.911, 364.9 ], [ 2.011, 239.4 ], [ 2.201, 171 ], [ 2.401, 119.7 ],
      [ 2.601, 72.68 ], [ 2.801, 42.76 ], [ 3.001, 21.68 ], [ 3.101, 0 ]
],
    "totImpulseNs": 1073,
    "totalWeightG": 1338,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 262,
    "burnTimeS": 4.9,
    "caseInfo": "54/830",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J261",
    "dataFiles": 1,
    "designation": "J261G",
    "diameter": 54,
    "impulseClass": "J",
    "length": 749,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 2354,
    "motorId": "5f4294d20002310000000222",
    "propInfo": "ABS/G, 830cc nitrous",
    "propWeightG": 91,
    "samples": [
      [ 0, 0 ], [ 0.02, 1303 ], [ 0.09, 903.9 ], [ 0.21, 591.2 ], [ 0.42, 349.6 ], [ 0.61, 304.6 ],
      [ 0.81, 329.6 ], [ 1.01, 277.2 ], [ 1.21, 294.6 ], [ 1.39, 232.2 ], [ 1.61, 197.3 ],
      [ 1.82, 256 ], [ 2, 274.7 ], [ 2.2, 269.7 ], [ 2.41, 189.8 ], [ 2.61, 294.6 ], [ 2.82, 212.2 ],
      [ 3.01, 254.7 ], [ 3.21, 225.3 ], [ 3.41, 194.8 ], [ 3.59, 156.3 ], [ 3.74, 229.7 ],
      [ 3.85, 237.7 ], [ 3.9, 156.3 ], [ 4, 112.8 ], [ 4.2, 140.2 ], [ 4.4, 192.3 ], [ 4.48, 106.7 ],
      [ 4.61, 54.86 ], [ 4.76, 33.52 ], [ 4.95, 0 ]
],
    "totImpulseNs": 1248,
    "totalWeightG": 1338,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 388,
    "burnTimeS": 3.57,
    "caseInfo": "54/1130",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K347",
    "dataFiles": 1,
    "designation": "K347B",
    "diameter": 54,
    "impulseClass": "K",
    "length": 911,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 1901,
    "motorId": "5f4294d20002310000000223",
    "propInfo": "ABS/B, 1130cc nitrous",
    "propWeightG": 91,
    "samples": [
      [ 0, 0 ], [ 0.001, 8.88 ], [ 0.031, 1475 ], [ 0.111, 972.2 ], [ 0.191, 737.3 ], [ 0.401, 697.2 ],
      [ 0.611, 673.9 ], [ 0.811, 653.9 ], [ 1.011, 600.4 ], [ 1.201, 550.4 ], [ 1.401, 530.4 ],
      [ 1.601, 533.8 ], [ 1.801, 517.1 ], [ 1.891, 397 ], [ 1.951, 493.2 ], [ 2.061, 393.6 ],
      [ 2.131, 443.7 ], [ 2.301, 365.4 ], [ 2.301, 292.3 ], [ 2.401, 257.9 ], [ 2.601, 184.8 ],
      [ 2.791, 124.7 ], [ 3.001, 81.67 ], [ 3.211, 51.58 ], [ 3.411, 21.49 ], [ 3.581, 0 ]
],
    "totImpulseNs": 1529,
    "totalWeightG": 1542,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 282,
    "burnTimeS": 6.1,
    "caseInfo": "54/1130",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K257",
    "dataFiles": 1,
    "designation": "K257G",
    "diameter": 54,
    "impulseClass": "K",
    "length": 914,
    "manufacturer": "Sky Ripper Systems",
    "manufacturerAbbrev": "SkyR",
    "maxThrustN": 2461,
    "motorId": "5f4294d20002310000000224",
    "propInfo": "ABS/G, 1130cc nitrous",
    "propWeightG": 90,
    "samples": [
      [ 0, 0 ], [ 0.07, 1133 ], [ 0.2, 530 ], [ 0.4, 333.7 ], [ 0.59, 363.1 ], [ 0.8, 347.2 ],
      [ 0.98, 366.4 ], [ 1.2, 356.6 ], [ 1.4, 310.8 ], [ 1.6, 274.8 ], [ 1.81, 337.7 ], [ 2, 356.6 ],
      [ 2.2, 294.4 ], [ 2.4, 314.1 ], [ 2.6, 294.4 ], [ 2.81, 333.7 ], [ 3, 340.2 ], [ 3.22, 319.8 ],
      [ 3.41, 292.7 ], [ 3.59, 249.3 ], [ 3.79, 278.1 ], [ 4, 243.9 ], [ 4.21, 281.4 ], [ 4.41, 235.5 ],
      [ 4.6, 251.9 ], [ 4.81, 211.4 ], [ 5.01, 222.2 ], [ 5.2, 189.7 ], [ 5.41, 153.2 ], [ 5.6, 81.4 ],
      [ 5.8, 23.94 ], [ 6.1, 0 ]
],
    "totImpulseNs": 1733,
    "totalWeightG": 1541,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 240.8,
    "burnTimeS": 3.35,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J240",
    "dataFiles": 1,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "806J240-16A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 299.4,
    "motorId": "5f4294d20002310000000225",
    "propInfo": "Red Lightning",
    "propWeightG": 446,
    "samples": [
      [ 0, 0 ], [ 0.01, 13.16 ], [ 0.02, 24.09 ], [ 0.03, 94.03 ], [ 0.04, 187.4 ], [ 0.05, 242.4 ],
      [ 0.06, 257.9 ], [ 0.07, 263.6 ], [ 0.08, 269.1 ], [ 0.09, 273.4 ], [ 0.1, 268.8 ],[ 0.2, 247.2 ],
      [ 0.3, 277.1 ], [ 0.4, 282.4 ], [ 0.5, 288.5 ], [ 0.6, 287.9 ], [ 0.7, 289.1 ], [ 0.8, 291.3 ],
      [ 0.9, 287.7 ], [ 1, 292.6 ], [ 1.1, 292.6 ], [ 1.2, 284.6 ], [ 1.3, 287.3 ], [ 1.4, 280.3 ],
      [ 1.5, 274.9 ], [ 1.6, 273.6 ], [ 1.7, 266.1 ], [ 1.8, 263.5 ], [ 1.9, 262.4 ], [ 2, 258 ],
      [ 2.1, 250.5 ], [ 2.2, 258.4 ], [ 2.3, 240.8 ], [ 2.4, 236.5 ], [ 2.5, 228.5 ], [ 2.6, 230.9 ],
      [ 2.7, 221.3 ], [ 2.8, 218.4 ], [ 2.9, 218.4 ], [ 3, 205.1 ], [ 3.1, 130.8 ], [ 3.2, 42.1 ],
      [ 3.348, 0 ]
],
    "totImpulseNs": 806,
    "totalWeightG": 875,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 353.3,
    "burnTimeS": 3.37,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J355",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1190J355-17A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 329,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 434.3,
    "motorId": "5f4294d20002310000000226",
    "propInfo": "Red Lightning",
    "propWeightG": 669,
    "samples": [
      [ 0, 0 ], [ 0.01, 12.67 ], [ 0.02, 7.51 ], [ 0.03, 99.23 ], [ 0.04, 265.9 ], [ 0.05, 366.8 ],
      [ 0.06, 390.5 ], [ 0.07, 391.2 ], [ 0.08, 392.5 ], [ 0.09, 391.9 ], [ 0.1, 386.5 ],[ 0.2, 394.9 ],
      [ 0.3, 413.2 ], [ 0.4, 422.6 ], [ 0.5, 430.8 ], [ 0.6, 428.6 ], [ 0.7, 430.9 ], [ 0.8, 426.2 ],
      [ 0.9, 422.7 ], [ 1, 416.4 ], [ 1.1, 410.7 ], [ 1.2, 409.3 ], [ 1.3, 403.3 ], [ 1.4, 398.9 ],
      [ 1.5, 394 ], [ 1.6, 389.4 ], [ 1.7, 383.5 ], [ 1.8, 375.3 ], [ 1.9, 369.2 ], [ 2, 365.7 ],
      [ 2.1, 359 ], [ 2.2, 352 ], [ 2.3, 345.3 ], [ 2.4, 342 ], [ 2.5, 340 ], [ 2.6, 329.3 ],
      [ 2.7, 314.5 ], [ 2.8, 309.3 ], [ 2.9, 302.8 ], [ 3, 274.4 ], [ 3.1, 238.4 ], [ 3.2, 136 ],
      [ 3.3, 46.35 ], [ 3.369, 0 ]
],
    "totImpulseNs": 1190,
    "totalWeightG": 1175,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 498.2,
    "burnTimeS": 3.2,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K500",
    "dataFiles": 1,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "1596K500-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 607.9,
    "motorId": "5f4294d20002310000000227",
    "propInfo": "Red Lightning",
    "propWeightG": 892,
    "samples": [
      [ 0, 0 ], [ 0.01, 11.87 ], [ 0.02, 0.001 ], [ 0.03, 292.3 ], [ 0.04, 374.7 ], [ 0.05, 432.9 ],
      [ 0.06, 479.8 ], [ 0.07, 505.5 ], [ 0.08, 509.5 ], [ 0.09, 584.9 ], [ 0.1, 499 ], [ 0.2, 546.6 ],
      [ 0.3, 579.9 ], [ 0.4, 588.1 ], [ 0.5, 595.3 ], [ 0.6, 594.6 ], [ 0.7, 594.5 ], [ 0.8, 593.8 ],
      [ 0.9, 584.9 ], [ 1, 583.3 ], [ 1.1, 575.3 ], [ 1.2, 568.1 ], [ 1.3, 564.3 ], [ 1.4, 560.3 ],
      [ 1.5, 540.3 ], [ 1.6, 543.1 ], [ 1.7, 532.6 ], [ 1.8, 532.5 ], [ 1.9, 508.6 ], [ 2, 505.4 ],
      [ 2.1, 496.3 ], [ 2.2, 483.9 ], [ 2.3, 476.3 ], [ 2.4, 467 ], [ 2.5, 454.8 ], [ 2.6, 454.3 ],
      [ 2.7, 437.9 ], [ 2.8, 436.1 ], [ 2.9, 415.7 ], [ 3, 327.7 ], [ 3.1, 133.2 ], [ 3.203, 0 ]
],
    "totImpulseNs": 1596,
    "totalWeightG": 1476,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 637.3,
    "burnTimeS": 3.13,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K635",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1994K635-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 778.7,
    "motorId": "5f4294d20002310000000228",
    "propInfo": "Red Lightning",
    "propWeightG": 1115,
    "samples": [
      [ 0, 0 ], [ 0.01, 0.872 ], [ 0.02, 3.983 ], [ 0.03, 312.7 ], [ 0.04, 629.5 ], [ 0.05, 633.1 ],
      [ 0.06, 659.5 ], [ 0.07, 680.5 ], [ 0.08, 689.5 ], [ 0.09, 694.1 ], [ 0.1, 698.7 ],[ 0.2, 735.3 ],
      [ 0.3, 759.8 ], [ 0.4, 757 ], [ 0.5, 760.2 ], [ 0.6, 760.8 ], [ 0.7, 756.5 ], [ 0.8, 754.1 ],
      [ 0.9, 741.6 ], [ 1, 741.6 ], [ 1.1, 727.9 ], [ 1.2, 724 ], [ 1.3, 713.5 ], [ 1.4, 708.6 ],
      [ 1.5, 704.8 ], [ 1.6, 688.4 ], [ 1.7, 677.7 ], [ 1.8, 661.6 ], [ 1.9, 647 ], [ 2, 637.3 ],
      [ 2.1, 635.9 ], [ 2.2, 623.9 ], [ 2.3, 619.7 ], [ 2.4, 594 ], [ 2.5, 576.9 ], [ 2.6, 559.6 ],
      [ 2.7, 562.1 ], [ 2.8, 497.6 ], [ 2.9, 352.1 ], [ 3, 187.3 ], [ 3.1, 77.42 ], [ 3.129, 0 ]
],
    "totImpulseNs": 1994,
    "totalWeightG": 1768,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 749.8,
    "burnTimeS": 3.14,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K750",
    "dataFiles": 1,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "2352K750-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 946.3,
    "motorId": "5f4294d20002310000000229",
    "propInfo": "Red Lightning",
    "propWeightG": 1321,
    "samples": [
      [ 0, 0 ], [ 0.019, 796.4 ], [ 0.084, 846.6 ], [ 0.233, 904 ], [ 0.434, 947 ], [ 0.817, 932.7 ],
      [ 1.549, 846.6 ], [ 1.854, 817.9 ], [ 2.113, 796.4 ], [ 2.294, 760.5 ], [ 2.398, 674.4 ],
      [ 2.521, 516.6 ], [ 2.599, 509.4 ], [ 2.878, 243.9 ], [ 2.962, 122 ], [ 3.059, 50.22 ],
      [ 3.144, 7.175 ], [ 3.189, 0 ]
],
    "totImpulseNs": 2353,
    "totalWeightG": 2057,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1031,
    "burnTimeS": 2.7,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1030",
    "dataFiles": 1,
    "delays": "P",
    "designation": "2788L1030-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1223,
    "motorId": "5f4294d2000231000000022a",
    "propInfo": "Red Lightning",
    "propWeightG": 1516,
    "samples": [
      [ 0, 0 ], [ 0.01, 5.768 ], [ 0.02, 8.076 ], [ 0.03, 1539 ], [ 0.04, 1197 ], [ 0.05, 1219 ],
      [ 0.06, 1123 ], [ 0.07, 1077 ], [ 0.08, 1060 ], [ 0.09, 1046 ], [ 0.1, 1076 ], [ 0.2, 1175 ],
      [ 0.3, 1206 ], [ 0.4, 1219 ], [ 0.5, 1208 ], [ 0.6, 1216 ], [ 0.7, 1222 ], [ 0.8, 1199 ],
      [ 0.9, 1202 ], [ 1, 1198 ], [ 1.1, 1184 ], [ 1.2, 1179 ], [ 1.3, 1174 ], [ 1.4, 1158 ],
      [ 1.5, 1164 ], [ 1.6, 1157 ], [ 1.7, 1173 ], [ 1.8, 1162 ], [ 1.9, 1158 ], [ 2, 1132 ],
      [ 2.1, 940.7 ], [ 2.2, 794.5 ], [ 2.3, 759.9 ], [ 2.4, 640.7 ], [ 2.5, 469.2 ], [ 2.6, 258.4 ],
      [ 2.7, 79.61 ], [ 2.704, 0 ]
],
    "totImpulseNs": 2788,
    "totalWeightG": 2338,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1620,
    "burnTimeS": 1.51,
    "caseInfo": "Pro98-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1620",
    "dataFiles": 1,
    "designation": "2440K1620-P",
    "diameter": 98,
    "impulseClass": "K",
    "length": 240,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1851,
    "motorId": "5f4294d2000231000000022b",
    "propInfo": "Vmax",
    "propWeightG": 1193,
    "samples": [
      [ 0, 0 ], [ 0.01, 29.61 ], [ 0.02, 199.2 ], [ 0.03, 1108 ], [ 0.04, 1272 ], [ 0.05, 1403 ],
      [ 0.06, 1328 ], [ 0.07, 1440 ], [ 0.08, 1436 ], [ 0.08, 1827 ], [ 0.09, 1419 ], [ 0.1, 1474 ],
      [ 0.2, 1570 ], [ 0.3, 1661 ], [ 0.4, 1742 ], [ 0.5, 1782 ], [ 0.6, 1816 ], [ 0.7, 1845 ],
      [ 0.9, 1796 ], [ 1, 1779 ], [ 1.1, 1723 ], [ 1.2, 1652 ], [ 1.3, 1533 ], [ 1.35, 1487 ],
      [ 1.4, 1451 ], [ 1.45, 1262 ], [ 1.46, 1111 ], [ 1.47, 929.5 ], [ 1.48, 628 ], [ 1.49, 382.3 ],
      [ 1.5, 223.4 ], [ 1.531, 0 ]
],
    "totImpulseNs": 2440,
    "totalWeightG": 3077,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3137,
    "burnTimeS": 1.53,
    "caseInfo": "Pro98-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L3150",
    "dataFiles": 1,
    "designation": "4807L3150-P",
    "diameter": 98,
    "impulseClass": "L",
    "length": 394,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3670,
    "motorId": "5f4294d2000231000000022c",
    "propInfo": "Vmax",
    "propWeightG": 2386,
    "samples": [
      [ 0, 0 ], [ 0.01, 140.8 ], [ 0.02, 2283 ], [ 0.03, 3657 ], [ 0.04, 2890 ], [ 0.05, 3078 ],
      [ 0.06, 3168 ], [ 0.07, 3075 ], [ 0.08, 2825 ], [ 0.09, 2960 ], [ 0.1, 2984 ], [ 0.2, 3110 ],
      [ 0.3, 3283 ], [ 0.4, 3420 ], [ 0.5, 3517 ], [ 0.6, 3605 ], [ 0.7, 3610 ], [ 0.8, 3559 ],
      [ 0.9, 3487 ], [ 1, 3402 ], [ 1.1, 3297 ], [ 1.2, 3155 ], [ 1.3, 2925 ], [ 1.4, 2766 ],
      [ 1.45, 2346 ], [ 1.47, 1629 ], [ 1.5, 883.4 ], [ 1.52, 411.9 ], [ 1.55, 65.38 ], [ 1.569, 0 ]
],
    "totImpulseNs": 4808,
    "totalWeightG": 4731,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 4770,
    "burnTimeS": 1.53,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M4770",
    "dataFiles": 1,
    "designation": "7312M4770-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 548,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 6053,
    "motorId": "5f4294d2000231000000022d",
    "propInfo": "Vmax",
    "propWeightG": 3579,
    "samples": [
      [ 0, 0 ], [ 0.01, 15.38 ], [ 0.02, 89.99 ], [ 0.03, 3053 ], [ 0.04, 5854 ], [ 0.05, 4311 ],
      [ 0.06, 4754 ], [ 0.07, 4889 ], [ 0.08, 4443 ], [ 0.09, 4331 ], [ 0.1, 4188 ], [ 0.2, 4606 ],
      [ 0.3, 4900 ], [ 0.4, 5061 ], [ 0.5, 5197 ], [ 0.6, 5391 ], [ 0.7, 5393 ], [ 0.8, 5366 ],
      [ 0.9, 5311 ], [ 1, 5219 ], [ 1.1, 5064 ], [ 1.2, 4849 ], [ 1.4, 4558 ], [ 1.45, 3979 ],
      [ 1.5, 3119 ], [ 1.52, 1765 ], [ 1.55, 216.5 ], [ 1.6, 17.31 ], [ 1.617, 0 ]
],
    "totImpulseNs": 7312,
    "totalWeightG": 6503,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1889,
    "burnTimeS": 5.25,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1890",
    "dataFiles": 1,
    "designation": "9876M1890-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2402,
    "motorId": "5f4294d2000231000000022e",
    "propInfo": "Red Lightning",
    "propWeightG": 5280,
    "samples": [
      [ 0, 0 ], [ 0.01, 63.45 ], [ 0.02, 360 ], [ 0.03, 580.3 ], [ 0.04, 763.8 ], [ 0.05, 1020 ],
      [ 0.06, 1255 ], [ 0.07, 1439 ], [ 0.08, 1571 ], [ 0.09, 1651 ], [ 0.1, 1679 ], [ 0.2, 1950 ],
      [ 0.3, 1947 ], [ 0.4, 1985 ], [ 0.5, 2014 ], [ 0.6, 2106 ], [ 0.7, 2056 ], [ 0.8, 2078 ],
      [ 0.9, 2095 ], [ 1, 2124 ], [ 1.1, 2127 ], [ 1.2, 2130 ], [ 1.3, 2162 ], [ 1.4, 2187 ],
      [ 1.5, 2199 ], [ 1.6, 2227 ], [ 1.7, 2240 ], [ 1.8, 2234 ], [ 1.9, 2224 ], [ 2, 2226 ],
      [ 2.1, 2226 ], [ 2.2, 2197 ], [ 2.3, 2207 ], [ 2.4, 2176 ], [ 2.6, 2168 ], [ 2.8, 2124 ],
      [ 3, 2064 ], [ 3.2, 2030 ], [ 3.4, 1909 ], [ 3.6, 1834 ], [ 3.8, 1765 ], [ 4, 1702 ],[ 4.2, 1619 ],
      [ 4.4, 1572 ], [ 4.6, 1505 ], [ 4.8, 1517 ], [ 4.9, 1477 ], [ 5, 1213 ], [ 5.1, 624.2 ],
      [ 5.2, 266.9 ], [ 5.3, 94.99 ], [ 5.365, 0 ]
],
    "totImpulseNs": 9876,
    "totalWeightG": 8823,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 34.9,
    "burnTimeS": 1.63,
    "caseInfo": "RMS-24/60",
    "certOrg": "National Association of Rocketry",
    "commonName": "F35",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,11",
    "designation": "F35W",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F35W.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 55.2,
    "motorId": "5f4294d2000231000000022f",
    "propInfo": "White Lightning",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.007, 39.45 ], [ 0.012, 51.84 ], [ 0.019, 49.88 ], [ 0.034, 57.87 ], [ 0.048, 58.36 ],
      [ 0.058, 57.22 ], [ 0.077, 54.45 ], [ 0.098, 54.94 ], [ 0.106, 53.96 ], [ 0.201, 53.47 ],
      [ 0.299, 53.31 ], [ 0.398, 52.01 ], [ 0.498, 52.01 ], [ 0.549, 49.56 ], [ 0.601, 48.91 ],
      [ 0.653, 47.28 ], [ 0.702, 45.65 ], [ 0.752, 44.67 ], [ 0.802, 43.2 ], [ 0.898, 39.78 ],
      [ 0.946, 39.62 ], [ 0.984, 36.84 ], [ 1.003, 37.33 ], [ 1.102, 33.58 ], [ 1.144, 30.16 ],
      [ 1.2, 22.33 ], [ 1.298, 10.92 ], [ 1.346, 6.521 ], [ 1.398, 3.26 ], [ 1.448, 1.793 ],
      [ 1.497, 0.978 ], [ 1.6, 0 ]
],
    "totImpulseNs": 57.1,
    "totalWeightG": 85,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 79.9,
    "burnTimeS": 1.38,
    "certOrg": "National Association of Rocketry",
    "commonName": "G78",
    "dataFiles": 3,
    "delays": "4,6,7,8,10",
    "designation": "G78G/L",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G78G.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 101.9,
    "motorId": "5f4294d20002310000000230",
    "propInfo": "Mojave Green",
    "propWeightG": 59.7,
    "samples": [
      [ 0, 0 ], [ 0.006, 1.158 ], [ 0.008, 7.49 ], [ 0.01, 33.76 ], [ 0.012, 64.6 ], [ 0.014, 62.93 ],
      [ 0.016, 58.83 ], [ 0.018, 74.91 ], [ 0.02, 85.01 ], [ 0.022, 91.11 ], [ 0.026, 93.99 ],
      [ 0.028, 98.43 ], [ 0.032, 97.65 ], [ 0.038, 102.2 ], [ 0.074, 97.32 ], [ 0.124, 95.43 ],
      [ 0.376, 99.32 ], [ 0.68, 99.43 ], [ 0.994, 91.66 ], [ 1.246, 83.01 ], [ 1.282, 77.35 ],
      [ 1.316, 61.93 ], [ 1.36, 44.63 ], [ 1.424, 29.1 ], [ 1.504, 21.22 ], [ 1.598, 19.34 ],
      [ 1.656, 16.34 ], [ 1.676, 13.9 ], [ 1.678, 11.79 ], [ 1.714, 5.094 ], [ 1.734, 1.389 ],
      [ 1.808, 0 ]
],
    "totImpulseNs": 109.9,
    "totalWeightG": 125,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 1520,
    "burnTimeS": 0.71,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J1520",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1092J1520-17A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 329,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1687,
    "motorId": "5f4294d20002310000000231",
    "propInfo": "Vmax",
    "propWeightG": 537,
    "samples": [
      [ 0, 0 ], [ 0.01, 216.5 ], [ 0.02, 1199 ], [ 0.03, 1183 ], [ 0.04, 1265 ], [ 0.05, 1373 ],
      [ 0.06, 1505 ], [ 0.07, 1549 ], [ 0.08, 1516 ], [ 0.09, 1499 ], [ 0.1, 1484 ], [ 0.15, 1570 ],
      [ 0.2, 1591 ], [ 0.3, 1618 ], [ 0.4, 1624 ], [ 0.5, 1662 ], [ 0.6, 1599 ], [ 0.67, 1467 ],
      [ 0.68, 1293 ], [ 0.69, 920.7 ], [ 0.7, 537.6 ], [ 0.71, 213.1 ], [ 0.72, 68.07 ], [ 0.737, 0 ]
],
    "totImpulseNs": 1093,
    "totalWeightG": 1026,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2045,
    "burnTimeS": 0.69,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K2045",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1408K2045-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2231,
    "motorId": "5f4294d20002310000000232",
    "propInfo": "Vmax",
    "propWeightG": 716,
    "samples": [
      [ 0, 0 ], [ 0.01, 1165 ], [ 0.02, 1855 ], [ 0.03, 1907 ], [ 0.04, 1947 ], [ 0.05, 1945 ],
      [ 0.06, 1970 ], [ 0.07, 1995 ], [ 0.08, 1999 ], [ 0.09, 2018 ], [ 0.1, 2035 ], [ 0.15, 2042 ],
      [ 0.2, 2139 ], [ 0.3, 2184 ], [ 0.4, 2165 ], [ 0.5, 2149 ], [ 0.6, 2155 ], [ 0.62, 2121 ],
      [ 0.63, 2107 ], [ 0.65, 2092 ], [ 0.655, 2039 ], [ 0.67, 1508 ], [ 0.68, 982.6 ], [ 0.69, 481.9 ],
      [ 0.7, 184.6 ], [ 0.72, 36.15 ], [ 0.734, 0 ]
],
    "totImpulseNs": 1408,
    "totalWeightG": 1290,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1057,
    "burnTimeS": 0.7,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J1055",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "747J1055-17A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1162,
    "motorId": "5f4294d20002310000000233",
    "propInfo": "Vmax",
    "propWeightG": 358,
    "samples": [
      [ 0, 0 ], [ 0.01, 412.3 ], [ 0.02, 896.4 ], [ 0.03, 1057 ], [ 0.04, 1100 ], [ 0.05, 1066 ],
      [ 0.06, 1041 ], [ 0.07, 1011 ], [ 0.08, 998.7 ], [ 0.09, 1028 ], [ 0.1, 1051 ], [ 0.15, 1086 ],
      [ 0.2, 1102 ], [ 0.3, 1118 ], [ 0.4, 1110 ], [ 0.5, 1104 ], [ 0.6, 1113 ], [ 0.67, 1046 ],
      [ 0.68, 748.4 ], [ 0.69, 382.6 ], [ 0.7, 172.7 ], [ 0.71, 80.38 ], [ 0.72, 39.23 ],[ 0.733, 0 ]
],
    "totImpulseNs": 746.6,
    "totalWeightG": 772,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 80.46,
    "burnTimeS": 1.52,
    "caseInfo": "38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G80",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "G80-LW",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20White.pdf",
    "length": 127,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 129.4,
    "motorId": "5f4294d20002310000000234",
    "propInfo": "Loki White",
    "propWeightG": 60,
    "samples": [
      [ 0, 0 ], [ 0.009317, 112.1 ], [ 0.4161, 112.1 ], [ 0.8768, 89.68 ], [ 1.5, 0 ]
],
    "totImpulseNs": 122.5,
    "totalWeightG": 255,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 46.2,
    "burnTimeS": 2.76,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G46",
    "dataFiles": 1,
    "delays": "2,4,6,8,11",
    "designation": "127G46-11A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 112.7,
    "motorId": "5f4294d20002310000000235",
    "propInfo": "Classic",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 3.945 ], [ 0.02, 26.59 ], [ 0.03, 61.3 ], [ 0.04, 78.59 ], [ 0.05, 88.04 ],
      [ 0.06, 57.05 ], [ 0.07, 48.21 ], [ 0.08, 45.63 ], [ 0.09, 47.68 ], [ 0.1, 49.42 ],[ 0.2, 58.49 ],
      [ 0.3, 60.35 ], [ 0.4, 59.36 ], [ 0.5, 59.74 ], [ 0.6, 57.05 ], [ 0.7, 56.18 ], [ 0.8, 53.83 ],
      [ 0.9, 53.6 ], [ 1, 51.78 ], [ 1.1, 50.79 ], [ 1.2, 47.49 ], [ 1.3, 46.77 ], [ 1.4, 45.9 ],
      [ 1.5, 44.68 ], [ 1.6, 45.02 ], [ 1.7, 44.57 ], [ 1.8, 43.28 ], [ 1.9, 42.33 ], [ 2, 41.95 ],
      [ 2.1, 42.45 ], [ 2.2, 40.13 ], [ 2.3, 39.68 ], [ 2.4, 36.41 ], [ 2.5, 29.63 ], [ 2.6, 13.62 ],
      [ 2.7, 3.945 ], [ 2.76, 0 ]
],
    "totImpulseNs": 127.3,
    "totalWeightG": 197,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 125.1,
    "burnTimeS": 2.15,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H125",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "266H125-12A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 152.9,
    "motorId": "5f4294d20002310000000236",
    "propInfo": "Classic",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.01, 1.252 ], [ 0.02, 2.921 ], [ 0.03, 9.635 ], [ 0.04, 78.03 ], [ 0.05, 128 ],
      [ 0.06, 139.1 ], [ 0.07, 98.55 ], [ 0.08, 93.12 ], [ 0.09, 98.02 ], [ 0.1, 102.9 ],[ 0.2, 128.4 ],
      [ 0.3, 135.9 ], [ 0.4, 140.3 ], [ 0.5, 146 ], [ 0.6, 148.6 ], [ 0.7, 149.9 ], [ 0.8, 150.6 ],
      [ 0.9, 152.7 ], [ 1, 154.1 ], [ 1.1, 153.5 ], [ 1.2, 152 ], [ 1.3, 151.5 ], [ 1.4, 146.8 ],
      [ 1.5, 146 ], [ 1.6, 141.4 ], [ 1.7, 139.2 ], [ 1.8, 133.6 ], [ 1.9, 70.78 ], [ 2, 18.47 ],
      [ 2.1, 4.59 ], [ 2.15, 0 ]
],
    "totImpulseNs": 285.6,
    "totalWeightG": 293,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 590.5,
    "burnTimeS": 4.059,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K590",
    "dataFiles": 1,
    "delays": "6,7,8,9,10,11,12,13,14,15",
    "designation": "2398K590-15A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1498,
    "motorId": "5f4294d20002310000000237",
    "propInfo": "Classic/Dual Thrust",
    "propWeightG": 1169,
    "samples": [
      [ 0, 0 ], [ 0.01, 2.69 ], [ 0.02, 1589 ], [ 0.03, 1588 ], [ 0.04, 1812 ], [ 0.05, 1717 ],
      [ 0.06, 1666 ], [ 0.07, 1487 ], [ 0.08, 1465 ], [ 0.09, 1411 ], [ 0.2, 1419 ], [ 0.4, 1393 ],
      [ 0.5, 1374 ], [ 0.6, 1354 ], [ 0.7, 961.8 ], [ 0.8, 514.6 ], [ 0.9, 525.3 ], [ 1, 526.1 ],
      [ 1.1, 516.9 ], [ 1.2, 520.3 ], [ 1.4, 518 ], [ 1.6, 505.3 ], [ 1.8, 488.8 ], [ 2, 471.1 ],
      [ 2.2, 471.5 ], [ 2.4, 481.5 ], [ 2.6, 461.5 ], [ 2.8, 454.9 ], [ 3, 450.3 ], [ 3.3, 429.2 ],
      [ 3.4, 389.9 ], [ 3.5, 315 ], [ 3.7, 204.6 ], [ 3.9, 118.8 ], [ 4, 89.22 ], [ 4.1, 68.07 ],
      [ 4.2, 34.61 ], [ 4.301, 0 ]
],
    "totImpulseNs": 2398,
    "totalWeightG": 1994,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3179,
    "burnTimeS": 4.47,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N3180",
    "dataFiles": 1,
    "designation": "14200N3180-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3770,
    "motorId": "5f4294d20002310000000238",
    "propInfo": "Red Lightning",
    "propWeightG": 7460,
    "samples": [
      [ 0, 0 ], [ 0.01, 96.91 ], [ 0.02, 72.3 ], [ 0.03, 349.6 ], [ 0.04, 698.4 ], [ 0.05, 1353 ],
      [ 0.06, 1866 ], [ 0.07, 2431 ], [ 0.08, 2995 ], [ 0.09, 3257 ], [ 0.1, 3388 ], [ 0.2, 3461 ],
      [ 0.3, 3453 ], [ 0.4, 3468 ], [ 0.5, 3476 ], [ 0.6, 3479 ], [ 0.7, 3484 ], [ 0.8, 3528 ],
      [ 0.9, 3534 ], [ 1, 3556 ], [ 1.1, 3563 ], [ 1.2, 3582 ], [ 1.4, 3685 ], [ 1.6, 3738 ],
      [ 1.8, 3714 ], [ 2, 3660 ], [ 2.2, 3594 ], [ 2.4, 3513 ], [ 2.6, 3376 ], [ 2.8, 3309 ],
      [ 3, 3207 ], [ 3.2, 3125 ], [ 3.4, 3081 ], [ 3.6, 3029 ], [ 3.8, 2969 ], [ 4, 2960 ],[ 4.1, 2753 ],
      [ 4.2, 2027 ], [ 4.3, 1133 ], [ 4.4, 509.6 ], [ 4.5, 154.6 ], [ 4.6, 25 ], [ 4.611, 0 ]
],
    "totImpulseNs": 14200,
    "totalWeightG": 12200,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 122.5,
    "burnTimeS": 1.9,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H123",
    "dataFiles": 3,
    "delays": "5,7,9,11,14",
    "designation": "232H123-14A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 153.3,
    "motorId": "5f4294d20002310000000239",
    "propInfo": "Skidmark",
    "propWeightG": 123,
    "samples": [
      [ 0, 0 ], [ 0.01849, 130.6 ], [ 0.03328, 151.1 ], [ 0.05178, 142.3 ], [ 0.06186, 131.1 ],
      [ 0.07027, 111 ], [ 0.08506, 94.38 ], [ 0.1331, 117.8 ], [ 0.2404, 128.1 ], [ 0.3772, 136.4 ],
      [ 0.4438, 139.9 ], [ 0.5067, 141.8 ], [ 0.6287, 148.2 ], [ 0.7396, 152.6 ], [ 0.8506, 153.1 ],
      [ 1.028, 152.1 ], [ 1.183, 148.2 ], [ 1.294, 142.8 ], [ 1.372, 139.4 ], [ 1.431, 135 ],
      [ 1.52, 131.5 ], [ 1.616, 135.3 ], [ 1.663, 130.2 ], [ 1.672, 95.35 ], [ 1.683, 68.95 ],
      [ 1.709, 45.48 ], [ 1.738, 22.98 ], [ 1.801, 11.25 ], [ 1.871, 4.401 ], [ 1.938, 2.746 ],
      [ 1.998, 0 ]
],
    "sparky": true,
    "totImpulseNs": 232.4,
    "totalWeightG": 297,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 641,
    "burnTimeS": 1.17,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J712",
    "dataFiles": 2,
    "delays": "7,9,12,15",
    "designation": "J712-LB",
    "diameter": 38,
    "impulseClass": "J",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Blue.pdf",
    "length": 406,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1278,
    "motorId": "5f4294d2000231000000023a",
    "propInfo": "Loki Blue",
    "propWeightG": 372,
    "samples": [
      [ 0, 0 ], [ 0.01006, 892.9 ], [ 0.03688, 789.7 ], [ 0.399, 746 ], [ 0.8013, 698.4 ],
      [ 1.003, 662.7 ], [ 1.062, 172.4 ], [ 1.2, 0 ]
],
    "totImpulseNs": 751.9,
    "totalWeightG": 752,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 1511,
    "burnTimeS": 2.56,
    "caseInfo": "76/3600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1482",
    "dataFiles": 2,
    "designation": "L1482-LB",
    "diameter": 76,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1813,
    "motorId": "5f4294d2000231000000023b",
    "propInfo": "Loki Blue",
    "propWeightG": 1839,
    "samples": [
      [ 0, 0 ], [ 0.007764, 1345 ], [ 0.04658, 1241 ], [ 0.4891, 1552 ], [ 1.017, 1569 ],[ 1.995, 1603 ],
      [ 2.391, 1586 ], [ 2.484, 1759 ], [ 2.508, 103.4 ], [ 2.6, 0 ]
],
    "totImpulseNs": 3882,
    "totalWeightG": 3538,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 89.28,
    "burnTimeS": 2.56,
    "caseInfo": "38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H90",
    "dataFiles": 2,
    "delays": "5,8,10,14",
    "designation": "H90-LR",
    "diameter": 38,
    "impulseClass": "H",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Red.pdf",
    "length": 117,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 157.6,
    "motorId": "5f4294d2000231000000023c",
    "propInfo": "Loki Red",
    "propWeightG": 120,
    "samples": [
      [ 0, 0 ], [ 0.05435, 146.6 ], [ 1.514, 94.83 ], [ 2.197, 44.83 ], [ 2.609, 0 ]
],
    "totImpulseNs": 234.4,
    "totalWeightG": 335,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 234.6,
    "burnTimeS": 2.08,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I210",
    "dataFiles": 2,
    "delays": "5,8,10,14",
    "designation": "I210-LR",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Red.pdf",
    "length": 292,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 422.8,
    "motorId": "5f4294d2000231000000023d",
    "propInfo": "Loki Red",
    "propWeightG": 240,
    "samples": [
      [ 0, 0 ], [ 0.003882, 452.6 ], [ 0.0427, 387.9 ], [ 1.002, 271.6 ], [ 1.809, 77.59 ],
      [ 2.3, 0 ]
],
    "totImpulseNs": 489.8,
    "totalWeightG": 540,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "OOP",
    "avgThrustN": 335,
    "burnTimeS": 2.15,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J320",
    "dataFiles": 2,
    "delays": "5,8,10,14",
    "designation": "J320-LR",
    "diameter": 38,
    "impulseClass": "J",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Red.pdf",
    "length": 406,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 550.9,
    "motorId": "5f4294d2000231000000023e",
    "propInfo": "Loki Red",
    "propWeightG": 372,
    "samples": [
      [ 0, 0 ], [ 0.01553, 534 ], [ 0.1009, 439.8 ], [ 1.002, 377 ], [ 1.941, 319.4 ], [ 2.189, 78.53 ],
      [ 2.6, 0 ]
],
    "totImpulseNs": 720.9,
    "totalWeightG": 752,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 169.2,
    "burnTimeS": 1.53,
    "caseInfo": "38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H160",
    "dataFiles": 2,
    "delays": "7,9,12,15",
    "designation": "H160-LB",
    "diameter": 38,
    "impulseClass": "H",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Blue.pdf",
    "length": 117,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 377.2,
    "motorId": "5f4294d2000231000000023f",
    "propInfo": "Loki Blue",
    "propWeightG": 120,
    "samples": [
      [ 0, 0 ], [ 0.01553, 288.8 ], [ 0.5994, 237.1 ], [ 1.031, 107.8 ], [ 1.5, 0 ]
],
    "totImpulseNs": 260.1,
    "totalWeightG": 335,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 437,
    "burnTimeS": 1.21,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I430",
    "dataFiles": 2,
    "delays": "7,9,12,15",
    "designation": "I430-LB",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Blue.pdf",
    "length": 292,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 639.5,
    "motorId": "5f4294d20002310000000240",
    "propInfo": "Loki Blue",
    "propWeightG": 240,
    "samples": [
      [ 0, 0 ], [ 0.009317, 534.5 ], [ 0.03106, 448.3 ], [ 0.09627, 465.5 ], [ 0.3727, 456.9 ],
      [ 0.7484, 439.7 ], [ 1.149, 422.4 ], [ 1.2, 0 ]
],
    "totImpulseNs": 532.5,
    "totalWeightG": 540,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "OOP",
    "avgThrustN": 25,
    "burnTimeS": 1.5,
    "caseInfo": "SU 29x76",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "E25",
    "dataFiles": 2,
    "delays": "4,7,P",
    "designation": "E25",
    "diameter": 29,
    "impulseClass": "E",
    "length": 76,
    "manufacturer": "Roadrunner Rocketry",
    "manufacturerAbbrev": "Roadrunner",
    "maxThrustN": 40,
    "motorId": "5f4294d20002310000000241",
    "propInfo": "Rapid propellant",
    "propWeightG": 20.6,
    "samples": [
      [ 0, 0 ], [ 0.001, 1.16 ], [ 0.001, 4.09 ], [ 0.011, 13.92 ], [ 0.021, 24.48 ], [ 0.026, 28.33 ],
      [ 0.041, 33.03 ], [ 0.046, 33.64 ], [ 0.071, 33.52 ], [ 0.121, 35.29 ], [ 0.196, 36.57 ],
      [ 0.246, 38.28 ], [ 0.281, 37.67 ], [ 0.316, 38.66 ], [ 0.351, 37.73 ], [ 0.386, 37.97 ],
      [ 0.451, 36.69 ], [ 0.561, 36.57 ], [ 0.731, 32.3 ], [ 0.821, 29.49 ], [ 0.901, 26.07 ],
      [ 0.921, 25.82 ], [ 0.946, 24.18 ], [ 0.996, 22.77 ], [ 1.036, 20.02 ], [ 1.081, 18.56 ],
      [ 1.166, 14.22 ], [ 1.191, 13.61 ], [ 1.311, 7.204 ], [ 1.396, 4.274 ], [ 1.446, 3.602 ],
      [ 1.491, 2.198 ], [ 1.506, 0.7936 ], [ 1.507, 0 ]
],
    "totImpulseNs": 39,
    "totalWeightG": 78,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3469,
    "burnTimeS": 2.11,
    "caseInfo": "AMW 75-7600",
    "certOrg": "National Association of Rocketry",
    "commonName": "M3500",
    "dataFiles": 2,
    "designation": "M3500R",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 4076,
    "motorId": "5f4294d20002310000000242",
    "propInfo": "Redline",
    "propWeightG": 4341,
    "samples": [
      [ 0, 0 ], [ 0.106, 8.867 ], [ 0.122, 135.6 ], [ 0.13, 670.9 ], [ 0.14, 1588 ], [ 0.156, 2300 ],
      [ 0.178, 2751 ], [ 0.188, 2731 ], [ 0.196, 2914 ], [ 0.2, 2843 ], [ 0.208, 2985 ], [ 0.21, 2904 ],
      [ 0.248, 3028 ], [ 0.932, 3723 ], [ 1.588, 4059 ], [ 1.986, 3901 ], [ 2.028, 3760 ],
      [ 2.04, 3496 ], [ 2.054, 3523 ], [ 2.074, 3313 ], [ 2.082, 2962 ], [ 2.09, 2965 ], [ 2.104, 2485 ],
      [ 2.164, 1016 ], [ 2.188, 602.4 ], [ 2.198, 218.8 ], [ 2.206, 440.9 ], [ 2.212, 274.6 ],
      [ 2.22, 474.1 ], [ 2.222, 353.7 ], [ 2.24, 180.1 ], [ 2.35, 0 ]
],
    "totImpulseNs": 7310,
    "totalWeightG": 7173,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 815,
    "burnTimeS": 0.9,
    "caseInfo": "AMW 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J740",
    "dataFiles": 1,
    "delays": "P",
    "designation": "J740G",
    "diameter": 38,
    "impulseClass": "J",
    "length": 370,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 956,
    "motorId": "5f4294d20002310000000243",
    "propInfo": "Mojave Green",
    "propWeightG": 308,
    "samples": [
      [ 0, 0 ], [ 0.003, 84.03 ], [ 0.009, 94.76 ], [ 0.015, 459.5 ], [ 0.026, 661.5 ], [ 0.039, 740.2 ],
      [ 0.044, 763.4 ], [ 0.054, 775.9 ], [ 0.07, 783.1 ], [ 0.078, 792 ], [ 0.087, 827.8 ],
      [ 0.119, 856.4 ], [ 0.167, 885 ], [ 0.241, 902.9 ], [ 0.323, 919 ], [ 0.421, 919 ],[ 0.464, 915.4 ],
      [ 0.495, 911.8 ], [ 0.546, 908.2 ], [ 0.568, 915.4 ], [ 0.589, 920.8 ], [ 0.616, 938.6 ],
      [ 0.634, 947.6 ], [ 0.649, 956.5 ], [ 0.659, 949.4 ], [ 0.681, 913.6 ], [ 0.7, 870.7 ],
      [ 0.715, 831.4 ], [ 0.727, 781.3 ], [ 0.736, 693.7 ], [ 0.751, 548.9 ], [ 0.765, 362.9 ],
      [ 0.772, 185.9 ], [ 0.78, 286.1 ], [ 0.788, 173.4 ], [ 0.793, 116.2 ], [ 0.807, 59 ],
      [ 0.828, 28.61 ], [ 0.844, 0 ]
],
    "totImpulseNs": 682,
    "totalWeightG": 724,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2435,
    "burnTimeS": 1.25,
    "caseInfo": "AMW 54-2550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L2300G",
    "diameter": 54,
    "impulseClass": "L",
    "length": 728,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 2943,
    "motorId": "5f4294d20002310000000244",
    "propInfo": "Mojave Green",
    "propWeightG": 1310,
    "samples": [
      [ 0, 0 ], [ 0.013, 2930 ], [ 0.019, 2243 ], [ 0.029, 2277 ], [ 0.033, 2500 ], [ 0.043, 2480 ],
      [ 0.054, 2534 ], [ 0.07, 2505 ], [ 0.088, 2471 ], [ 0.11, 2451 ], [ 0.151, 2447 ], [ 0.207, 2480 ],
      [ 0.277, 2534 ], [ 0.443, 2654 ], [ 0.503, 2717 ], [ 0.528, 2741 ], [ 0.575, 2795 ],
      [ 0.603, 2824 ], [ 0.636, 2843 ], [ 0.651, 2916 ], [ 0.664, 2891 ], [ 0.686, 2872 ],
      [ 0.729, 2858 ], [ 0.757, 2833 ], [ 0.772, 2795 ], [ 0.794, 2780 ], [ 0.804, 2853 ],
      [ 0.812, 2790 ], [ 0.83, 2722 ], [ 0.855, 2630 ], [ 0.874, 2577 ], [ 0.912, 2509 ],[ 0.93, 2519 ],
      [ 0.943, 2461 ], [ 0.977, 2355 ], [ 0.991, 2224 ], [ 0.997, 2355 ], [ 1.013, 1886 ],
      [ 1.042, 1426 ], [ 1.061, 938 ], [ 1.074, 498 ], [ 1.096, 246.6 ], [ 1.117, 149.9 ],
      [ 1.136, 106.4 ], [ 1.172, 48.35 ], [ 1.249, 0 ]
],
    "totImpulseNs": 2756,
    "totalWeightG": 2630,
    "type": "reload",
    "updatedOn": "2020-01-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 72.6,
    "burnTimeS": 1.5,
    "certOrg": "National Association of Rocketry",
    "commonName": "G79",
    "dataFiles": 1,
    "delays": "4,6,7,8,10",
    "designation": "G79W/L",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G79.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 93.9,
    "motorId": "5f4294d20002310000000245",
    "propInfo": "White Lightning",
    "propWeightG": 65,
    "samples": [
      [ 0, 0 ], [ 0.014, 1.138 ], [ 0.018, 3.213 ], [ 0.022, 6.202 ], [ 0.034, 27.37 ], [ 0.04, 33.58 ],
      [ 0.044, 34.85 ], [ 0.062, 33.52 ], [ 0.096, 39.92 ], [ 0.128, 56.98 ], [ 0.138, 60.09 ],
      [ 0.182, 59.62 ], [ 0.23, 68.89 ], [ 0.266, 71.34 ], [ 0.3, 71.62 ], [ 0.36, 75.82 ],
      [ 0.484, 79.62 ], [ 0.516, 78.63 ], [ 0.678, 82.11 ], [ 0.7, 78.84 ], [ 0.72, 78.18 ],
      [ 0.772, 79.56 ], [ 1.054, 73.72 ], [ 1.086, 71.15 ], [ 1.182, 68.86 ], [ 1.42, 57.25 ],
      [ 1.52, 40.76 ], [ 1.562, 30.58 ], [ 1.628, 11.8 ], [ 1.652, 7.171 ], [ 1.68, 3.96 ],
      [ 1.738, 1.676 ], [ 1.928, 0 ]
],
    "totImpulseNs": 108.3,
    "totalWeightG": 124,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 80.12,
    "burnTimeS": 1.28,
    "certOrg": "National Association of Rocketry",
    "commonName": "G77",
    "dataFiles": 2,
    "delays": "4,6,7,8,10",
    "designation": "G77R/L",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G77.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 100.5,
    "motorId": "5f4294d20002310000000246",
    "propInfo": "Redline",
    "propWeightG": 64,
    "samples": [
      [ 0, 0 ], [ 0.01, 19.12 ], [ 0.018, 15.67 ], [ 0.059, 66.59 ], [ 0.084, 72.35 ], [ 0.122, 75.11 ],
      [ 0.151, 77.42 ], [ 0.173, 77.65 ], [ 0.271, 85.94 ], [ 0.337, 88.02 ], [ 0.424, 91.94 ],
      [ 0.475, 93.32 ], [ 0.523, 94.93 ], [ 0.57, 96.31 ], [ 0.61, 96.31 ], [ 0.644, 99.31 ],
      [ 0.656, 96.77 ], [ 0.728, 95.62 ], [ 0.792, 94.24 ], [ 0.902, 91.01 ], [ 0.961, 88.48 ],
      [ 1.017, 88.02 ], [ 1.047, 84.56 ], [ 1.071, 81.11 ], [ 1.099, 76.04 ], [ 1.127, 69.36 ],
      [ 1.158, 61.06 ], [ 1.188, 44.93 ], [ 1.213, 30.18 ], [ 1.231, 18.2 ], [ 1.277, 0 ]
],
    "totImpulseNs": 102.9,
    "totalWeightG": 122.5,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 135.9,
    "burnTimeS": 0.62,
    "certOrg": "National Association of Rocketry",
    "commonName": "G142",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "G142T",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G142.pdf",
    "length": 113,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 173.9,
    "motorId": "5f4294d20002310000000247",
    "propInfo": "8222ALF",
    "propWeightG": 44,
    "samples": [
      [ 0, 0 ], [ 0.016, 4.061 ], [ 0.018, 6.538 ], [ 0.02, 13.44 ], [ 0.022, 24.42 ], [ 0.024, 43.74 ],
      [ 0.026, 72.85 ], [ 0.028, 106.9 ], [ 0.03, 132 ], [ 0.032, 141.5 ], [ 0.034, 147.7 ],
      [ 0.038, 154.1 ], [ 0.054, 165.6 ], [ 0.076, 166.3 ], [ 0.084, 168.8 ], [ 0.118, 172.9 ],
      [ 0.15, 173.4 ], [ 0.224, 171.8 ], [ 0.3, 164.8 ], [ 0.372, 156.7 ], [ 0.418, 149 ],
      [ 0.432, 145 ], [ 0.452, 137.1 ], [ 0.518, 96.88 ], [ 0.568, 69.39 ], [ 0.576, 63.16 ],
      [ 0.618, 21.48 ], [ 0.628, 13.57 ], [ 0.64, 7.985 ], [ 0.652, 2.922 ], [ 0.672, 0.9663 ],
      [ 0.9, 0.6876 ], [ 0.901, 0 ]
],
    "totImpulseNs": 84.3,
    "totalWeightG": 98,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 1833,
    "burnTimeS": 4.5,
    "caseInfo": "RMS-98/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1800",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1800FJ",
    "diameter": 98,
    "impulseClass": "M",
    "length": 751,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3956,
    "motorId": "5f4294d20002310000000248",
    "propInfo": "Black Max",
    "propWeightG": 5599,
    "samples": [
      [ 0, 0 ], [ 0.021, 3007 ], [ 0.043, 1965 ], [ 0.059, 1858 ], [ 0.069, 1766 ], [ 0.134, 1830 ],
      [ 0.192, 1823 ], [ 0.267, 1823 ], [ 0.363, 1865 ], [ 0.502, 1936 ], [ 0.582, 1922 ],
      [ 0.684, 1965 ], [ 1.132, 2071 ], [ 1.581, 2149 ], [ 1.923, 2199 ], [ 2.142, 2220 ],
      [ 2.393, 2220 ], [ 2.463, 2199 ], [ 2.655, 2107 ], [ 2.831, 2007 ], [ 2.922, 1979 ],
      [ 3.029, 1915 ], [ 3.21, 1809 ], [ 3.349, 1695 ], [ 3.526, 1589 ], [ 3.723, 1440 ],[ 3.851, 1397 ],
      [ 3.921, 1362 ], [ 4.017, 1255 ], [ 4.097, 1121 ], [ 4.167, 978.8 ], [ 4.263, 780.2 ],
      [ 4.327, 624.2 ], [ 4.402, 453.9 ], [ 4.428, 375.9 ], [ 4.482, 276.6 ], [ 4.53, 177.3 ],
      [ 4.583, 99.3 ], [ 4.653, 42.56 ], [ 4.76, 21.28 ], [ 4.952, 0 ]
],
    "totImpulseNs": 8208,
    "totalWeightG": 9162,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 459.1,
    "burnTimeS": 3.95,
    "caseInfo": "AMW 75-2500",
    "certOrg": "National Association of Rocketry",
    "commonName": "K500",
    "dataFiles": 2,
    "designation": "K500SK",
    "diameter": 75,
    "impulseClass": "K",
    "length": 368,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 841.3,
    "motorId": "5f4294d20002310000000249",
    "propInfo": "Skidmark",
    "propWeightG": 1124,
    "samples": [
      [ 0, 0 ], [ 0.018, 194.4 ], [ 0.031, 207.2 ], [ 0.036, 268.5 ], [ 0.103, 319.7 ], [ 0.138, 322.3 ],
      [ 0.17, 314.6 ], [ 0.67, 309.5 ], [ 0.692, 322.3 ], [ 0.902, 319.7 ], [ 0.929, 332.5 ],
      [ 1.263, 355.5 ], [ 1.585, 383.6 ], [ 1.969, 411.8 ], [ 2.112, 416.9 ], [ 2.272, 447.6 ],
      [ 2.415, 488.5 ], [ 2.46, 485.9 ], [ 2.634, 534.5 ], [ 2.83, 618.9 ], [ 3.018, 711 ],
      [ 3.143, 767.3 ], [ 3.228, 815.9 ], [ 3.339, 841.4 ], [ 3.402, 826.1 ], [ 3.504, 785.2 ],
      [ 3.585, 718.7 ], [ 3.661, 631.7 ], [ 3.728, 519.2 ], [ 3.772, 365.7 ], [ 3.826, 181.6 ],
      [ 3.862, 99.74 ], [ 3.893, 43.48 ], [ 3.946, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1812,
    "totalWeightG": 2713,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 408.9,
    "burnTimeS": 2.8,
    "caseInfo": "RMS-54/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J401",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J401FJ",
    "diameter": 54,
    "impulseClass": "J",
    "length": 325,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 480,
    "motorId": "5f4294d2000231000000024a",
    "propInfo": "Black Max",
    "propWeightG": 511,
    "samples": [
      [ 0, 0 ], [ 0.011, 440.4 ], [ 0.022, 488.1 ], [ 0.075, 427.8 ], [ 0.116, 422.2 ], [ 0.191, 446 ],
      [ 0.277, 453 ], [ 0.382, 462.9 ], [ 0.513, 468.5 ], [ 0.712, 472.7 ], [ 0.775, 471.3 ],
      [ 0.948, 471.3 ], [ 1.06, 472.7 ], [ 1.281, 469.9 ], [ 1.431, 462.9 ], [ 1.607, 453 ],
      [ 1.858, 426.4 ], [ 2.034, 403.9 ], [ 2.135, 384.3 ], [ 2.202, 378.7 ], [ 2.258, 374.5 ],
      [ 2.277, 374.5 ], [ 2.3, 380.1 ], [ 2.322, 380.1 ], [ 2.345, 370.3 ], [ 2.367, 359.1 ],
      [ 2.408, 314.2 ], [ 2.461, 244.1 ], [ 2.502, 186.5 ], [ 2.566, 103.8 ], [ 2.607, 64.52 ],
      [ 2.652, 42.08 ], [ 2.704, 18.23 ], [ 2.783, 0 ]
],
    "totImpulseNs": 1115,
    "totalWeightG": 912,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2200,
    "burnTimeS": 2.32,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2200",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L2200G",
    "diameter": 75,
    "impulseClass": "L",
    "length": 681,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d2000231000000024b",
    "propInfo": "Mojave Green",
    "propWeightG": 2518,
    "samples": [
      [ 0, 0 ], [ 0.011, 1195 ], [ 0.024, 2030 ], [ 0.037, 2381 ], [ 0.05, 2542 ], [ 0.1, 2571 ],
      [ 0.15, 2561 ], [ 0.2, 2523 ], [ 0.25, 2485 ], [ 0.3, 2523 ], [ 0.35, 2571 ], [ 0.4, 2675 ],
      [ 0.5, 2912 ], [ 0.6, 3073 ], [ 0.7, 3073 ], [ 0.8, 3102 ], [ 0.9, 3092 ], [ 1, 3092 ],
      [ 1.1, 2959 ], [ 1.186, 2808 ], [ 1.227, 2438 ], [ 1.27, 2258 ], [ 1.3, 2163 ], [ 1.4, 1992 ],
      [ 1.5, 1878 ], [ 1.6, 1793 ], [ 1.7, 1688 ], [ 1.8, 1613 ], [ 1.9, 1584 ], [ 2, 1537 ],
      [ 2.048, 1499 ], [ 2.084, 1404 ], [ 2.102, 1167 ], [ 2.134, 796.8 ], [ 2.186, 455.3 ],
      [ 2.237, 237.1 ], [ 2.3, 94.86 ], [ 2.4, 0 ]
],
    "totImpulseNs": 5104,
    "totalWeightG": 4783,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1509,
    "burnTimeS": 3.46,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1500",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1500G",
    "diameter": 75,
    "impulseClass": "M",
    "length": 681,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d2000231000000024c",
    "propInfo": "Mojave Green",
    "propWeightG": 2631,
    "samples": [
      [ 0, 0 ], [ 0.012, 1184 ], [ 0.023, 1614 ], [ 0.05, 1695 ], [ 0.1, 1717 ], [ 0.15, 1695 ],
      [ 0.2, 1604 ], [ 0.25, 1517 ], [ 0.3, 1491 ], [ 0.4, 1474 ], [ 0.5, 1485 ], [ 0.6, 1512 ],
      [ 0.7, 1534 ], [ 0.8, 1571 ], [ 0.9, 1609 ], [ 1, 1630 ], [ 1.1, 1657 ], [ 1.2, 1684 ],
      [ 1.3, 1695 ], [ 1.4, 1695 ], [ 1.5, 1700 ], [ 1.6, 1700 ], [ 1.7, 1706 ], [ 1.8, 1690 ],
      [ 1.9, 1663 ], [ 2, 1657 ], [ 2.1, 1609 ], [ 2.2, 1582 ], [ 2.3, 1555 ], [ 2.4, 1523 ],
      [ 2.5, 1491 ], [ 2.6, 1464 ], [ 2.7, 1431 ], [ 2.8, 1415 ], [ 2.9, 1388 ], [ 3, 1361 ],
      [ 3.1, 1372 ], [ 3.2, 1286 ], [ 3.25, 1227 ], [ 3.3, 973.9 ], [ 3.4, 478.9 ], [ 3.45, 220.6 ],
      [ 3.5, 80.71 ], [ 3.6, 0 ]
],
    "totImpulseNs": 5220,
    "totalWeightG": 4896,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 316.5,
    "burnTimeS": 8.82,
    "caseInfo": "RMS-98/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L339",
    "dataFiles": 3,
    "delays": "P",
    "designation": "L339N",
    "diameter": 98,
    "impulseClass": "L",
    "length": 302,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 445.5,
    "motorId": "5f4294d2000231000000024d",
    "propInfo": "Warp 9",
    "propWeightG": 1796,
    "samples": [
      [ 0, 0 ], [ 0.049, 2.884 ], [ 0.074, 373.4 ], [ 0.123, 399.4 ], [ 0.156, 408.1 ], [ 0.279, 416 ],
      [ 0.427, 417.4 ], [ 0.476, 421 ], [ 0.509, 416.7 ], [ 0.632, 416 ], [ 0.837, 414.5 ],
      [ 0.894, 418.9 ], [ 0.943, 405.2 ], [ 1.026, 400.1 ], [ 1.124, 402.3 ], [ 1.255, 398 ],
      [ 1.362, 392.9 ], [ 1.51, 391.5 ], [ 1.575, 394.4 ], [ 1.592, 385.7 ], [ 1.657, 387.1 ],
      [ 1.838, 385 ], [ 2.026, 382.8 ], [ 2.174, 379.9 ], [ 2.404, 377.1 ], [ 2.478, 378.5 ],
      [ 2.552, 377.1 ], [ 2.617, 378.5 ], [ 2.683, 367 ], [ 2.773, 359.8 ], [ 2.806, 363.4 ],
      [ 2.937, 359.8 ], [ 3.232, 351.1 ], [ 3.314, 355.4 ], [ 3.356, 351.1 ], [ 3.815, 345.3 ],
      [ 3.979, 340.3 ], [ 4.233, 336.7 ], [ 4.414, 333.8 ], [ 4.668, 330.9 ], [ 4.996, 329.5 ],
      [ 5.39, 323.7 ], [ 5.686, 320.1 ], [ 5.989, 314.3 ], [ 6.178, 310.7 ], [ 6.325, 306.4 ],
      [ 6.514, 302.1 ], [ 6.621, 302.1 ], [ 6.851, 297.8 ], [ 7.195, 290.5 ], [ 7.277, 293.4 ],
      [ 7.326, 287.7 ], [ 7.441, 283.3 ], [ 7.49, 286.2 ], [ 7.54, 283.3 ], [ 7.696, 275.4 ],
      [ 7.81, 274 ], [ 7.884, 276.8 ], [ 7.942, 278.3 ], [ 8.015, 276.1 ], [ 8.073, 270.4 ],
      [ 8.188, 155 ], [ 8.212, 119 ], [ 8.278, 54.07 ], [ 8.311, 26.68 ], [ 8.352, 7.93 ],
      [ 8.426, 0 ]
],
    "totImpulseNs": 3043,
    "totalWeightG": 3210,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1133,
    "burnTimeS": 2.14,
    "caseInfo": "RMS-54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1050",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K1050W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 627,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2172,
    "motorId": "5f4294d2000231000000024e",
    "propInfo": "White Lightning",
    "propWeightG": 1265,
    "samples": [
      [ 0, 0 ], [ 0.001, 934.5 ], [ 0.051, 1469 ], [ 0.101, 1335 ], [ 0.151, 1291 ], [ 0.251, 1246 ],
      [ 0.751, 1246 ], [ 1.251, 1202 ], [ 1.501, 1157 ], [ 1.651, 1157 ], [ 1.751, 1246 ],
      [ 1.901, 890 ], [ 2.101, 578.5 ], [ 2.201, 222.5 ], [ 2.461, 0 ]
],
    "totImpulseNs": 2426,
    "totalWeightG": 2203,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2550,
    "burnTimeS": 2.5,
    "caseInfo": "76/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2550",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M2550-LB",
    "diameter": 76,
    "impulseClass": "M",
    "length": 785,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "motorId": "5f4294d2000231000000024f",
    "propInfo": "Loki Blue",
    "propWeightG": 3130,
    "samples": [
      [ 0, 0 ], [ 0.007764, 2974 ], [ 0.06211, 2888 ], [ 0.427, 2888 ], [ 0.8618, 3017 ],[ 1.498, 2974 ],
      [ 2.12, 2414 ], [ 2.453, 474.1 ], [ 2.6, 0 ]
],
    "totImpulseNs": 6502,
    "totalWeightG": 5530,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 100,
    "burnTimeS": 2,
    "caseInfo": "38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H100",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "H100-SF",
    "diameter": 38,
    "impulseClass": "H",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Spitfire.pdf",
    "length": 177,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "motorId": "5f4294d20002310000000250",
    "propInfo": "Spitfire",
    "propWeightG": 120,
    "samples": [
      [ 0, 0 ], [ 0.01553, 127.6 ], [ 0.1514, 127.6 ], [ 0.4775, 139.7 ], [ 0.7415, 146.6 ],
      [ 1.005, 150 ], [ 1.137, 122.4 ], [ 1.308, 86.21 ], [ 1.599, 39.66 ], [ 2, 0 ]
],
    "sparky": true,
    "totImpulseNs": 200,
    "totalWeightG": 335,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 34.1,
    "burnTimeS": 1.66,
    "certOrg": "National Association of Rocketry",
    "commonName": "F32",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F32T",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F32T.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 61.3,
    "motorId": "5f4294d20002310000000251",
    "propInfo": "Blue Thunder",
    "propWeightG": 31,
    "samples": [
      [ 0, 0 ], [ 0.17, 0.2367 ], [ 0.192, 0.7941 ], [ 0.202, 2.686 ], [ 0.204, 3.571 ], [ 0.208, 7.969 ],
      [ 0.21, 11.79 ], [ 0.212, 16.83 ], [ 0.22, 39.23 ], [ 0.224, 46.78 ], [ 0.228, 51.9 ],
      [ 0.232, 55.3 ], [ 0.236, 56.96 ], [ 0.258, 58.4 ], [ 0.288, 55.24 ], [ 0.316, 53.47 ],
      [ 0.394, 49.59 ], [ 0.458, 47.4 ], [ 0.73, 41.22 ], [ 0.904, 39.38 ], [ 1.11, 35.67 ],
      [ 1.198, 35.09 ], [ 1.22, 33.83 ], [ 1.26, 33.5 ], [ 1.478, 27.38 ], [ 1.49, 27.75 ],
      [ 1.51, 25.44 ], [ 1.676, 11.27 ], [ 1.768, 5.947 ], [ 1.852, 2.914 ], [ 1.952, 0.7458 ],
      [ 2.064, 0 ]
],
    "totImpulseNs": 56.9,
    "totalWeightG": 64,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 79.9,
    "burnTimeS": 1.78,
    "caseInfo": "Kosdon 29-150",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G82",
    "dataFiles": 1,
    "delays": "M",
    "designation": "G82W",
    "diameter": 29,
    "impulseClass": "G",
    "length": 206,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 103.7,
    "motorId": "5f4294d20002310000000252",
    "propInfo": "White Lightning",
    "propWeightG": 90,
    "samples": [
      [ 0, 0 ], [ 0.011, 50.34 ], [ 0.028, 53.5 ], [ 0.055, 76.3 ], [ 0.068, 80.16 ], [ 0.111, 74.02 ],
      [ 0.134, 74.02 ], [ 0.216, 79.46 ], [ 0.295, 83.67 ], [ 0.374, 89.98 ], [ 0.406, 91.91 ],
      [ 0.431, 89.63 ], [ 0.453, 92.44 ], [ 0.474, 93.84 ], [ 0.489, 91.39 ], [ 0.519, 93.31 ],
      [ 0.551, 90.33 ], [ 0.604, 91.91 ], [ 0.642, 92.26 ], [ 0.728, 94.37 ], [ 0.79, 96.65 ],
      [ 0.85, 98.4 ], [ 0.905, 99.45 ], [ 0.954, 101.7 ], [ 0.995, 103 ], [ 1.025, 103.8 ],
      [ 1.076, 102.4 ], [ 1.112, 102.4 ], [ 1.142, 102.3 ], [ 1.174, 98.58 ], [ 1.221, 95.24 ],
      [ 1.238, 93.49 ], [ 1.274, 98.23 ], [ 1.287, 89.63 ], [ 1.317, 87.88 ], [ 1.351, 85.6 ],
      [ 1.434, 81.91 ], [ 1.479, 78.58 ], [ 1.503, 73.84 ], [ 1.533, 65.08 ], [ 1.565, 50.52 ],
      [ 1.594, 36.84 ], [ 1.62, 28.59 ], [ 1.65, 21.05 ], [ 1.701, 14.21 ], [ 1.737, 8.946 ],
      [ 1.829, 5.613 ], [ 1.883, 4.385 ], [ 1.959, 0 ]
],
    "totImpulseNs": 142.7,
    "totalWeightG": 231,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 137,
    "burnTimeS": 1.03,
    "caseInfo": "Kosdon 29-150",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G135",
    "dataFiles": 1,
    "delays": "M",
    "designation": "G135R",
    "diameter": 29,
    "impulseClass": "G",
    "length": 206,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 158.8,
    "motorId": "5f4294d20002310000000253",
    "propInfo": "Redline",
    "propWeightG": 80,
    "samples": [
      [ 0, 0 ], [ 0.011, 156.9 ], [ 0.016, 63.46 ], [ 0.027, 98.19 ], [ 0.061, 109.9 ], [ 0.094, 111.1 ],
      [ 0.14, 116.2 ], [ 0.197, 127.6 ], [ 0.26, 135.1 ], [ 0.316, 139.2 ], [ 0.354, 141.4 ],
      [ 0.408, 147.1 ], [ 0.459, 149 ], [ 0.502, 153.4 ], [ 0.546, 156.6 ], [ 0.6, 158.2 ],
      [ 0.684, 156 ], [ 0.743, 152.8 ], [ 0.816, 150 ], [ 0.875, 148.1 ], [ 0.91, 146.8 ],
      [ 0.963, 151.2 ], [ 0.981, 152.2 ], [ 0.993, 149 ], [ 1.014, 103.6 ], [ 1.02, 61.25 ],
      [ 1.034, 22.73 ], [ 1.039, 7.893 ], [ 1.049, 0 ]
],
    "totImpulseNs": 143,
    "totalWeightG": 226,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 133,
    "burnTimeS": 1.85,
    "caseInfo": "Kosdon 29-250",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H130",
    "dataFiles": 1,
    "delays": "M",
    "designation": "H130W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 291,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 223.2,
    "motorId": "5f4294d20002310000000254",
    "propInfo": "White Lightning",
    "propWeightG": 140,
    "samples": [
      [ 0, 0 ], [ 0.016, 154.8 ], [ 0.024, 194.7 ], [ 0.055, 224.1 ], [ 0.071, 201.8 ], [ 0.078, 227 ],
      [ 0.089, 183.4 ], [ 0.123, 172.1 ], [ 0.196, 172.1 ], [ 0.254, 176.7 ], [ 0.348, 179.6 ],
      [ 0.426, 180.9 ], [ 0.481, 179.2 ], [ 0.554, 178.3 ], [ 0.649, 166.2 ], [ 0.672, 165.3 ],
      [ 0.777, 172.5 ], [ 0.86, 172.9 ], [ 0.947, 171.6 ], [ 1.038, 167 ], [ 1.138, 161.1 ],
      [ 1.185, 157.4 ], [ 1.232, 152.8 ], [ 1.266, 143.1 ], [ 1.294, 119.6 ], [ 1.326, 101.1 ],
      [ 1.412, 70.08 ], [ 1.53, 46.58 ], [ 1.647, 32.73 ], [ 1.674, 29.38 ], [ 1.718, 17.63 ],
      [ 1.838, 12.17 ], [ 1.909, 0 ]
],
    "totImpulseNs": 246.3,
    "totalWeightG": 709,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 221.8,
    "burnTimeS": 1.08,
    "caseInfo": "Kosdon 29-250",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H225",
    "dataFiles": 1,
    "delays": "M",
    "designation": "H225R",
    "diameter": 29,
    "impulseClass": "H",
    "length": 291,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 281,
    "motorId": "5f4294d20002310000000255",
    "propInfo": "Redline",
    "propWeightG": 133,
    "samples": [
      [ 0, 0 ], [ 0.025, 104.6 ], [ 0.039, 112.5 ], [ 0.053, 123.8 ], [ 0.091, 141.9 ], [ 0.104, 177.3 ],
      [ 0.131, 189.6 ], [ 0.16, 196 ], [ 0.26, 219.5 ], [ 0.336, 229.8 ], [ 0.345, 225.9 ],
      [ 0.385, 237.7 ], [ 0.43, 251.9 ], [ 0.491, 260.8 ], [ 0.527, 264.7 ], [ 0.562, 267.2 ],
      [ 0.623, 271.1 ], [ 0.668, 268.2 ], [ 0.713, 269.6 ], [ 0.742, 268.2 ], [ 0.794, 262.8 ],
      [ 0.818, 262.3 ], [ 0.855, 259.3 ], [ 0.891, 258.8 ], [ 0.927, 263.7 ], [ 0.957, 272.6 ],
      [ 0.971, 275.5 ], [ 0.982, 271.6 ], [ 0.991, 230.3 ], [ 1.008, 185.6 ], [ 1.025, 162.1 ],
      [ 1.055, 72.69 ], [ 1.078, 32.41 ], [ 1.121, 0 ]
],
    "totImpulseNs": 241.2,
    "totalWeightG": 316,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 525.5,
    "burnTimeS": 1.22,
    "caseInfo": "Kosdon 38-640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J520",
    "dataFiles": 1,
    "delays": "14",
    "designation": "J520F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 368,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 683.6,
    "motorId": "5f4294d20002310000000256",
    "propInfo": "Kosdon Fast",
    "propWeightG": 327,
    "samples": [
      [ 0, 0 ], [ 0.018, 696.9 ], [ 0.024, 547.6 ], [ 0.031, 737.1 ], [ 0.039, 529.5 ], [ 0.051, 524.3 ],
      [ 0.054, 415.3 ], [ 0.063, 547.6 ], [ 0.079, 613.8 ], [ 0.094, 543.7 ], [ 0.106, 533.4 ],
      [ 0.127, 546.3 ], [ 0.146, 546.3 ], [ 0.191, 548.9 ], [ 0.28, 547.6 ], [ 0.379, 560.6 ],
      [ 0.473, 577.5 ], [ 0.558, 591.8 ], [ 0.629, 599.5 ], [ 0.689, 598.2 ], [ 0.787, 596.9 ],
      [ 0.827, 593 ], [ 0.892, 587.9 ], [ 0.947, 574.9 ], [ 0.971, 572.3 ], [ 0.997, 576.2 ],
      [ 1.015, 582.7 ], [ 1.026, 582.7 ], [ 1.039, 552.8 ], [ 1.069, 443.8 ], [ 1.1, 330.9 ],
      [ 1.12, 275.1 ], [ 1.145, 254.3 ], [ 1.161, 224.5 ], [ 1.193, 135 ], [ 1.209, 77.86 ],
      [ 1.227, 32.44 ], [ 1.255, 0 ]
],
    "totImpulseNs": 645.9,
    "totalWeightG": 722,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 776.1,
    "burnTimeS": 1.84,
    "caseInfo": "Kosdon 54-1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K700",
    "dataFiles": 1,
    "delays": "14",
    "designation": "K700F",
    "diameter": 54,
    "impulseClass": "K",
    "length": 406,
    "manufacturer": "Kosdon by AeroTech",
    "manufacturerAbbrev": "KBA",
    "maxThrustN": 909,
    "motorId": "5f4294d20002310000000257",
    "propInfo": "Kosdon Fast",
    "propWeightG": 734,
    "samples": [
      [ 0, 0 ], [ 0.011, 790 ], [ 0.029, 815.1 ], [ 0.045, 831.3 ], [ 0.057, 754 ], [ 0.084, 746.8 ],
      [ 0.118, 741.4 ], [ 0.184, 757.6 ], [ 0.422, 800.8 ], [ 0.454, 818.7 ], [ 0.748, 872.7 ],
      [ 0.798, 870.9 ], [ 0.853, 890.7 ], [ 0.923, 903.3 ], [ 1, 912.3 ], [ 1.084, 908.7 ],
      [ 1.209, 897.9 ], [ 1.315, 872.7 ], [ 1.395, 843.9 ], [ 1.454, 815.1 ], [ 1.517, 786.4 ],
      [ 1.565, 777.4 ], [ 1.587, 775.6 ], [ 1.61, 786.4 ], [ 1.635, 754 ], [ 1.658, 656.8 ],
      [ 1.678, 543.4 ], [ 1.694, 494.8 ], [ 1.71, 446.3 ], [ 1.753, 300.5 ], [ 1.773, 208.7 ],
      [ 1.796, 131.4 ], [ 1.814, 50.38 ], [ 1.85, 0 ]
],
    "totImpulseNs": 1434,
    "totalWeightG": 1503,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1390,
    "burnTimeS": 2.63,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1390",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1390G",
    "diameter": 75,
    "impulseClass": "L",
    "length": 530,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d20002310000000258",
    "propInfo": "Mojave Green",
    "propWeightG": 1973,
    "samples": [
      [ 0, 0 ], [ 0.018, 906.2 ], [ 0.041, 1249 ], [ 0.055, 1337 ], [ 0.099, 1370 ], [ 0.173, 1403 ],
      [ 0.236, 1412 ], [ 0.284, 1412 ], [ 0.678, 1587 ], [ 0.869, 1650 ], [ 0.939, 1641 ],
      [ 1.102, 1626 ], [ 1.197, 1629 ], [ 1.293, 1620 ], [ 1.374, 1608 ], [ 1.455, 1602 ],
      [ 1.518, 1590 ], [ 1.592, 1550 ], [ 1.691, 1499 ], [ 1.838, 1424 ], [ 1.96, 1367 ],[ 2.1, 1313 ],
      [ 2.225, 1261 ], [ 2.343, 1228 ], [ 2.417, 1213 ], [ 2.479, 1204 ], [ 2.524, 1198 ],
      [ 2.564, 1198 ], [ 2.601, 1165 ], [ 2.638, 1075 ], [ 2.66, 957.4 ], [ 2.689, 840 ],[ 2.712, 713.5 ],
      [ 2.737, 590.1 ], [ 2.763, 427.5 ], [ 2.8, 295 ], [ 2.844, 153.5 ], [ 2.877, 69.25 ],
      [ 2.911, 0 ]
],
    "totImpulseNs": 3949,
    "totalWeightG": 3879,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 396,
    "burnTimeS": 1.65,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J396",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "J396-SF",
    "diameter": 38,
    "impulseClass": "J",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Spitfire.pdf",
    "length": 406,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "motorId": "5f4294d20002310000000259",
    "propInfo": "Spitfire",
    "propWeightG": 372,
    "samples": [
      [ 0, 0 ], [ 0.006211, 396.6 ], [ 0.2019, 413.8 ], [ 0.4006, 439.7 ], [ 0.5932, 491.4 ],
      [ 0.7174, 543.1 ], [ 0.8012, 508.6 ], [ 0.9938, 491.4 ], [ 1.202, 448.3 ], [ 1.401, 250 ],
      [ 1.6, 0 ]
],
    "sparky": true,
    "totImpulseNs": 650,
    "totalWeightG": 752,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 183.3,
    "burnTimeS": 0.69,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G185",
    "dataFiles": 1,
    "delays": "3,5,7,9,12",
    "designation": "128G185-12A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 212.2,
    "motorId": "5f4294d2000231000000025a",
    "propInfo": "Vmax",
    "propWeightG": 62.1,
    "samples": [
      [ 0, 0 ], [ 0.01, 108.7 ], [ 0.02, 131.2 ], [ 0.05, 153.1 ], [ 0.1, 169 ], [ 0.2, 189.9 ],
      [ 0.3, 199.9 ], [ 0.35, 203.6 ], [ 0.4, 205 ], [ 0.45, 202.6 ], [ 0.5, 203.1 ], [ 0.55, 199.3 ],
      [ 0.6, 194.7 ], [ 0.61, 194.1 ], [ 0.63, 193.5 ], [ 0.64, 193.7 ], [ 0.65, 202.9 ],[ 0.67, 163.4 ],
      [ 0.68, 80.44 ], [ 0.69, 0 ]
],
    "totImpulseNs": 128,
    "totalWeightG": 188.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 396,
    "burnTimeS": 0.64,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H400",
    "dataFiles": 1,
    "delays": "4,6,8,10,13",
    "designation": "255H400-13A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 448,
    "motorId": "5f4294d2000231000000025b",
    "propInfo": "Vmax",
    "propWeightG": 122.4,
    "samples": [
      [ 0, 0 ], [ 0.01, 222.2 ], [ 0.02, 305.3 ], [ 0.03, 314.5 ], [ 0.05, 342.5 ], [ 0.1, 382.5 ],
      [ 0.15, 407.4 ], [ 0.2, 420.8 ], [ 0.25, 433.4 ], [ 0.3, 439.2 ], [ 0.4, 436.4 ], [ 0.5, 422.7 ],
      [ 0.6, 423.1 ], [ 0.62, 364.9 ], [ 0.63, 117.6 ], [ 0.64, 0 ]
],
    "totImpulseNs": 255,
    "totalWeightG": 283.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 196,
    "burnTimeS": 2.02,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I195",
    "dataFiles": 1,
    "delays": "7,9,11,13,16",
    "designation": "396I195-16A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 241.5,
    "motorId": "5f4294d2000231000000025c",
    "propInfo": "Red Lightning",
    "propWeightG": 204.9,
    "samples": [
      [ 0, 0 ], [ 0.001, 10.17 ], [ 0.011, 67.55 ], [ 0.021, 179.7 ], [ 0.031, 203.4 ], [ 0.041, 206.1 ],
      [ 0.051, 192.2 ], [ 0.061, 195.7 ], [ 0.071, 177.7 ], [ 0.081, 181.7 ], [ 0.091, 186.8 ],
      [ 0.101, 195.7 ], [ 0.201, 226.6 ], [ 0.301, 235.4 ], [ 0.401, 231 ], [ 0.501, 231.7 ],
      [ 0.601, 226 ], [ 0.701, 222.6 ], [ 0.801, 223.7 ], [ 0.901, 210.6 ], [ 1.001, 208.5 ],
      [ 1.101, 205.5 ], [ 1.201, 204 ], [ 1.301, 196.6 ], [ 1.401, 191.5 ], [ 1.501, 188.6 ],
      [ 1.601, 183.5 ], [ 1.701, 186.4 ], [ 1.801, 181.7 ], [ 1.901, 113.7 ], [ 2.001, 29.6 ],
      [ 2.021, 0 ]
],
    "totImpulseNs": 369.2,
    "totalWeightG": 417,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 566,
    "burnTimeS": 0.65,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I566",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "370I566-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 640.9,
    "motorId": "5f4294d2000231000000025d",
    "propInfo": "Vmax",
    "propWeightG": 183.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 396.8 ], [ 0.02, 462.7 ], [ 0.04, 473.2 ], [ 0.07, 512.1 ], [ 0.1, 537.9 ],
      [ 0.15, 572.9 ], [ 0.2, 587.7 ], [ 0.25, 600.4 ], [ 0.3, 609.8 ], [ 0.4, 611.4 ], [ 0.5, 602.6 ],
      [ 0.6, 600.3 ], [ 0.61, 607.5 ], [ 0.62, 628.6 ], [ 0.63, 612.2 ], [ 0.64, 388.7 ],[ 0.65, 0 ]
],
    "totImpulseNs": 370,
    "totalWeightG": 384.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 805.3,
    "burnTimeS": 0.52,
    "caseInfo": "Pro38-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I800",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "419I800-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 303,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 932.5,
    "motorId": "5f4294d2000231000000025e",
    "propInfo": "Vmax",
    "propWeightG": 221,
    "samples": [
      [ 0, 0 ], [ 0.01, 276.1 ], [ 0.02, 680.5 ], [ 0.05, 741 ], [ 0.1, 791.4 ], [ 0.2, 830.9 ],
      [ 0.3, 851.5 ], [ 0.4, 853.8 ], [ 0.5, 888.7 ], [ 0.51, 760.6 ], [ 0.52, 0 ]
],
    "totImpulseNs": 419.2,
    "totalWeightG": 457,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1113,
    "burnTimeS": 2.12,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1085",
    "dataFiles": 1,
    "designation": "2412K1085-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1204,
    "motorId": "5f4294d2000231000000025f",
    "propInfo": "White Thunder",
    "propWeightG": 1199,
    "samples": [
      [ 0, 0 ], [ 0.01, 52.67 ], [ 0.02, 656.8 ], [ 0.03, 979.4 ], [ 0.04, 1059 ], [ 0.05, 983.7 ],
      [ 0.06, 948.4 ], [ 0.07, 939.6 ], [ 0.08, 938.8 ], [ 0.09, 957.2 ], [ 0.1, 950.2 ],[ 0.2, 1003 ],
      [ 0.3, 1046 ], [ 0.4, 1069 ], [ 0.5, 1085 ], [ 0.6, 1104 ], [ 0.7, 1139 ], [ 0.8, 1149 ],
      [ 0.9, 1161 ], [ 1, 1174 ], [ 1.1, 1162 ], [ 1.2, 1149 ], [ 1.3, 1138 ], [ 1.4, 1123 ],
      [ 1.5, 1113 ], [ 1.6, 1087 ], [ 1.7, 1072 ], [ 1.8, 1052 ], [ 1.9, 1044 ], [ 2, 1032 ],
      [ 2.02, 1036 ], [ 2.04, 1034 ], [ 2.06, 1037 ], [ 2.1, 1025 ], [ 2.12, 1026 ], [ 2.2, 663.9 ],
      [ 2.22, 422.1 ], [ 2.25, 136.6 ], [ 2.27, 40.83 ], [ 2.281, 0 ]
],
    "totImpulseNs": 2412,
    "totalWeightG": 2430,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1194,
    "burnTimeS": 1.69,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1200",
    "dataFiles": 1,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "2014K1200-16A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1364,
    "motorId": "5f4294d20002310000000260",
    "propInfo": "White Thunder",
    "propWeightG": 960,
    "samples": [
      [ 0, 0 ], [ 0.01, 492.3 ], [ 0.02, 1369 ], [ 0.05, 1236 ], [ 0.1, 1279 ], [ 0.2, 1311 ],
      [ 0.3, 1320 ], [ 0.4, 1331 ], [ 0.5, 1325 ], [ 0.7, 1323 ], [ 0.8, 1304 ], [ 0.9, 1281 ],
      [ 1, 1250 ], [ 1.1, 1218 ], [ 1.2, 1199 ], [ 1.3, 1159 ], [ 1.4, 1113 ], [ 1.5, 1100 ],
      [ 1.55, 941.8 ], [ 1.6, 726.1 ], [ 1.62, 559.2 ], [ 1.64, 399.9 ], [ 1.66, 317.7 ],[ 1.67, 247.3 ],
      [ 1.68, 198.1 ], [ 1.69, 67.3 ], [ 1.7, 0 ]
],
    "totImpulseNs": 2014,
    "totalWeightG": 1632,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1437,
    "burnTimeS": 1.65,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1440",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "2372K1440-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1829,
    "motorId": "5f4294d20002310000000261",
    "propInfo": "White Thunder",
    "propWeightG": 1129,
    "samples": [
      [ 0, 0 ], [ 0.01, 1062 ], [ 0.01, 2168 ], [ 0.02, 2036 ], [ 0.07, 1692 ], [ 0.1, 1805 ],
      [ 0.2, 1747 ], [ 0.3, 1722 ], [ 0.4, 1712 ], [ 0.5, 1709 ], [ 0.6, 1682 ], [ 0.7, 1679 ],
      [ 0.8, 1638 ], [ 0.9, 1601 ], [ 1.01, 1540 ], [ 1.11, 1463 ], [ 1.21, 1398 ], [ 1.3, 1111 ],
      [ 1.4, 1040 ], [ 1.45, 922.2 ], [ 1.5, 701.1 ], [ 1.55, 556.5 ], [ 1.6, 272.7 ], [ 1.65, 66.53 ],
      [ 1.66, 0 ]
],
    "totImpulseNs": 2372,
    "totalWeightG": 1893,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1771,
    "burnTimeS": 2,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1720",
    "dataFiles": 1,
    "designation": "3660L1720-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1946,
    "motorId": "5f4294d20002310000000262",
    "propInfo": "White Thunder",
    "propWeightG": 1755,
    "samples": [
      [ 0, 0 ], [ 0.01, 25.46 ], [ 0.02, 1041 ], [ 0.03, 1841 ], [ 0.04, 1947 ], [ 0.05, 1836 ],
      [ 0.06, 1754 ], [ 0.07, 1728 ], [ 0.08, 1668 ], [ 0.09, 1618 ], [ 0.1, 1597 ], [ 0.2, 1741 ],
      [ 0.3, 1728 ], [ 0.4, 1770 ], [ 0.5, 1826 ], [ 0.6, 1864 ], [ 0.7, 1903 ], [ 0.8, 1931 ],
      [ 0.9, 1926 ], [ 1, 1917 ], [ 1.1, 1873 ], [ 1.2, 1830 ], [ 1.3, 1836 ], [ 1.4, 1806 ],
      [ 1.5, 1788 ], [ 1.6, 1756 ], [ 1.7, 1727 ], [ 1.8, 1695 ], [ 1.9, 1698 ], [ 2, 1727 ],
      [ 2.02, 1739 ], [ 2.04, 1641 ], [ 2.05, 1504 ], [ 2.08, 557.2 ], [ 2.1, 179.2 ], [ 2.12, 84.93 ],
      [ 2.129, 0 ]
],
    "totImpulseNs": 3660,
    "totalWeightG": 3341,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2451,
    "burnTimeS": 1.9,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L2375",
    "dataFiles": 2,
    "designation": "4864L2375-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2798,
    "motorId": "5f4294d20002310000000263",
    "propInfo": "White Thunder",
    "propWeightG": 2322,
    "samples": [
      [ 0, 0 ], [ 0.01, 18.15 ], [ 0.02, 1195 ], [ 0.03, 2264 ], [ 0.04, 2354 ], [ 0.05, 2301 ],
      [ 0.06, 2230 ], [ 0.07, 2219 ], [ 0.08, 2194 ], [ 0.09, 2195 ], [ 0.1, 2221 ], [ 0.2, 2464 ],
      [ 0.3, 2416 ], [ 0.4, 2459 ], [ 0.5, 2482 ], [ 0.6, 2557 ], [ 0.7, 2560 ], [ 0.8, 2589 ],
      [ 0.9, 2608 ], [ 1, 2600 ], [ 1.1, 2567 ], [ 1.2, 2552 ], [ 1.3, 2513 ], [ 1.4, 2465 ],
      [ 1.5, 2407 ], [ 1.6, 2366 ], [ 1.7, 2339 ], [ 1.8, 2323 ], [ 1.9, 2311 ], [ 1.95, 2285 ],
      [ 1.97, 2113 ], [ 2, 1550 ], [ 2.02, 1116 ], [ 2.04, 591.8 ], [ 2.05, 408 ], [ 2.06, 243.7 ],
      [ 2.08, 103.3 ], [ 2.1, 38.56 ], [ 2.108, 0 ]
],
    "totImpulseNs": 4864,
    "totalWeightG": 4161,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 231.8,
    "burnTimeS": 4.08,
    "certOrg": "National Association of Rocketry",
    "commonName": "J230",
    "dataFiles": 2,
    "designation": "J230SK-P",
    "diameter": 54,
    "impulseClass": "J",
    "infoUrl": "http://www.nar.org/SandT/pdf/Animal/J230SK.pdf",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 276.7,
    "motorId": "5f4294d20002310000000264",
    "propWeightG": 525.7,
    "samples": [
      [ 0, 0 ], [ 0.026, 147 ], [ 0.039, 214.2 ], [ 0.076, 239.4 ], [ 0.161, 247.8 ], [ 0.306, 247.7 ],
      [ 0.488, 249.7 ], [ 0.754, 260.1 ], [ 0.948, 268.4 ], [ 1.227, 274.6 ], [ 1.59, 276.7 ],
      [ 1.905, 270.1 ], [ 2.195, 267.9 ], [ 2.498, 259.4 ], [ 2.643, 253 ], [ 2.824, 248.8 ],
      [ 3.115, 242.3 ], [ 3.369, 229.7 ], [ 3.478, 221.1 ], [ 3.55, 204.3 ], [ 3.61, 170.7 ],
      [ 3.658, 126.5 ], [ 3.718, 82.44 ], [ 3.79, 61.42 ], [ 3.899, 40.37 ], [ 3.983, 17.23 ],
      [ 4.08, 0 ]
],
    "sparky": true,
    "totImpulseNs": 945.9,
    "totalWeightG": 1169,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 326.1,
    "burnTimeS": 3.37,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J325",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1099J325-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 540.3,
    "motorId": "5f4294d20002310000000265",
    "propInfo": "Tamed Tiger",
    "propWeightG": 537,
    "samples": [
      [ 0, 0 ], [ 0.013, 334.7 ], [ 0.034, 534.2 ], [ 0.046, 523.5 ], [ 0.072, 540.7 ], [ 0.084, 474.1 ],
      [ 0.156, 474.1 ], [ 0.215, 474.1 ], [ 0.342, 463.4 ], [ 0.519, 454.8 ], [ 1.004, 414.1 ],
      [ 1.286, 392.6 ], [ 1.78, 328.3 ], [ 2.222, 274.6 ], [ 2.458, 251 ], [ 2.555, 240.3 ],
      [ 2.606, 233.9 ], [ 2.766, 216.7 ], [ 2.863, 206 ], [ 2.922, 199.5 ], [ 2.964, 188.8 ],
      [ 3.011, 173.8 ], [ 3.057, 150.2 ], [ 3.104, 124.4 ], [ 3.15, 98.69 ], [ 3.243, 57.93 ],
      [ 3.289, 34.33 ], [ 3.369, 0 ]
],
    "totImpulseNs": 1099,
    "totalWeightG": 1230,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 392.5,
    "burnTimeS": 2.74,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J395",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1079J395-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 470.2,
    "motorId": "5f4294d20002310000000266",
    "propInfo": "Red Rhino",
    "propWeightG": 573,
    "samples": [
      [ 0, 0 ], [ 0.032, 336.1 ], [ 0.036, 390.5 ], [ 0.068, 417.6 ], [ 0.079, 420.1 ], [ 0.093, 417.6 ],
      [ 0.108, 412.7 ], [ 0.172, 432.5 ], [ 0.219, 447.3 ], [ 0.265, 454.7 ], [ 0.308, 459.6 ],
      [ 0.391, 462.1 ], [ 0.441, 467.1 ], [ 0.484, 459.6 ], [ 0.527, 462.1 ], [ 0.606, 459.6 ],
      [ 0.753, 454.7 ], [ 1.025, 437.4 ], [ 1.215, 422.6 ], [ 1.247, 432.5 ], [ 1.298, 432.5 ],
      [ 1.376, 425.1 ], [ 1.566, 417.6 ], [ 1.706, 417.6 ], [ 1.753, 417.6 ], [ 1.771, 412.7 ],
      [ 1.814, 412.7 ], [ 1.882, 397.9 ], [ 1.9, 402.8 ], [ 1.928, 397.9 ], [ 1.961, 397.9 ],
      [ 1.989, 392.9 ], [ 2.04, 392.9 ], [ 2.075, 390.5 ], [ 2.129, 397.9 ], [ 2.151, 385.5 ],
      [ 2.179, 375.6 ], [ 2.215, 365.7 ], [ 2.244, 378.1 ], [ 2.262, 373.2 ], [ 2.29, 368.2 ],
      [ 2.319, 363.3 ], [ 2.366, 353.4 ], [ 2.394, 358.3 ], [ 2.416, 350.9 ], [ 2.43, 343.5 ],
      [ 2.452, 326.2 ], [ 2.484, 313.8 ], [ 2.556, 227.4 ], [ 2.624, 135.9 ], [ 2.76, 0 ]
],
    "totImpulseNs": 1079,
    "totalWeightG": 1268,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 403.6,
    "burnTimeS": 2.42,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J400",
    "dataFiles": 1,
    "designation": "977J400-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 499.4,
    "motorId": "5f4294d20002310000000267",
    "propInfo": "Black Bear",
    "propWeightG": 723,
    "samples": [
      [ 0, 0 ], [ 0.018, 298.4 ], [ 0.025, 343.2 ], [ 0.033, 381.1 ], [ 0.048, 364.3 ], [ 0.058, 397.9 ],
      [ 0.071, 364.3 ], [ 0.084, 386.7 ], [ 0.099, 365.7 ], [ 0.106, 390.9 ], [ 0.119, 334.8 ],
      [ 0.137, 353.1 ], [ 0.218, 368.5 ], [ 0.316, 385.3 ], [ 0.428, 402.1 ], [ 0.562, 417.5 ],
      [ 0.709, 431.5 ], [ 0.77, 437.1 ], [ 0.886, 451.1 ], [ 0.975, 455.3 ], [ 1.033, 456.7 ],
      [ 1.122, 462.3 ], [ 1.21, 469.3 ], [ 1.284, 470.7 ], [ 1.365, 480.5 ], [ 1.428, 473.5 ],
      [ 1.504, 469.3 ], [ 1.577, 466.5 ], [ 1.658, 455.3 ], [ 1.798, 435.7 ], [ 1.909, 404.9 ],
      [ 1.998, 381.1 ], [ 2.069, 361.5 ], [ 2.109, 351.7 ], [ 2.124, 336.2 ], [ 2.15, 341.8 ],
      [ 2.182, 348.9 ], [ 2.188, 355.9 ], [ 2.205, 340.4 ], [ 2.233, 320.8 ], [ 2.286, 243.8 ],
      [ 2.319, 197.5 ], [ 2.355, 138.7 ], [ 2.408, 65.85 ], [ 2.458, 0 ]
],
    "totImpulseNs": 976.9,
    "totalWeightG": 1382,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 476,
    "burnTimeS": 2.15,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J475",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1025J475-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 597.7,
    "motorId": "5f4294d20002310000000268",
    "propInfo": "Black Bear",
    "propWeightG": 723,
    "samples": [
      [ 0, 0 ], [ 0.008, 250.2 ], [ 0.032, 402.1 ], [ 0.037, 458.7 ], [ 0.045, 449.8 ], [ 0.056, 464.7 ],
      [ 0.077, 458.7 ], [ 0.08, 464.7 ], [ 0.099, 455.7 ], [ 0.117, 455.7 ], [ 0.128, 449.8 ],
      [ 0.141, 455.7 ], [ 0.155, 443.8 ], [ 0.165, 455.7 ], [ 0.197, 464.7 ], [ 0.232, 473.6 ],
      [ 0.256, 482.6 ], [ 0.261, 491.5 ], [ 0.267, 482.6 ], [ 0.299, 494.5 ], [ 0.36, 515.3 ],
      [ 0.459, 536.2 ], [ 0.594, 566 ], [ 0.714, 580.9 ], [ 0.826, 598.7 ], [ 0.93, 607.7 ],
      [ 1.005, 613.6 ], [ 1.074, 607.7 ], [ 1.112, 601.7 ], [ 1.176, 598.7 ], [ 1.25, 589.8 ],
      [ 1.304, 574.9 ], [ 1.32, 580.9 ], [ 1.333, 574.9 ], [ 1.368, 568.9 ], [ 1.466, 533.2 ],
      [ 1.568, 491.5 ], [ 1.65, 455.7 ], [ 1.703, 428.9 ], [ 1.741, 402.1 ], [ 1.765, 399.1 ],
      [ 1.802, 387.2 ], [ 1.818, 390.2 ], [ 1.826, 387.2 ], [ 1.845, 387.2 ], [ 1.869, 390.2 ],
      [ 1.879, 387.2 ], [ 1.901, 375.3 ], [ 1.906, 354.5 ], [ 1.938, 294.9 ], [ 1.981, 214.5 ],
      [ 2.015, 148.9 ], [ 2.061, 83.4 ], [ 2.101, 17.87 ], [ 2.117, 0 ]
],
    "totImpulseNs": 1025,
    "totalWeightG": 1410,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 742.8,
    "burnTimeS": 1.61,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J745",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1196J745-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 864.2,
    "motorId": "5f4294d20002310000000269",
    "propInfo": "Wild Wolf",
    "propWeightG": 549,
    "samples": [
      [ 0, 0 ], [ 0.016, 666.5 ], [ 0.019, 715.3 ], [ 0.029, 744.8 ], [ 0.04, 746.3 ], [ 0.053, 768.5 ],
      [ 0.063, 778.8 ], [ 0.075, 812.8 ], [ 0.087, 823.1 ], [ 0.112, 839.4 ], [ 0.127, 835 ],
      [ 0.144, 846.8 ], [ 0.156, 846.8 ], [ 0.173, 848.3 ], [ 0.205, 848.3 ], [ 0.213, 848.3 ],
      [ 0.221, 858.6 ], [ 0.242, 858.6 ], [ 0.269, 860.1 ], [ 0.279, 863 ], [ 0.3, 860.1 ],
      [ 0.322, 864.5 ], [ 0.34, 861.6 ], [ 0.348, 858.6 ], [ 0.362, 861.6 ], [ 0.385, 860.1 ],
      [ 0.414, 858.6 ], [ 0.443, 855.7 ], [ 0.459, 861.6 ], [ 0.47, 858.6 ], [ 0.478, 861.6 ],
      [ 0.489, 855.7 ], [ 0.528, 849.7 ], [ 0.55, 855.7 ], [ 0.585, 848.3 ], [ 0.619, 849.7 ],
      [ 0.637, 846.8 ], [ 0.661, 846.8 ], [ 0.736, 835 ], [ 0.826, 817.2 ], [ 0.92, 795.1 ],
      [ 1.057, 755.2 ], [ 1.156, 722.7 ], [ 1.228, 707.9 ], [ 1.305, 693.1 ], [ 1.347, 679.8 ],
      [ 1.392, 666.5 ], [ 1.416, 666.5 ], [ 1.426, 666.5 ], [ 1.448, 666.5 ], [ 1.477, 666.5 ],
      [ 1.48, 665 ], [ 1.485, 634 ], [ 1.493, 561.6 ], [ 1.515, 366.5 ], [ 1.533, 257.1 ],
      [ 1.555, 162.6 ], [ 1.583, 81.28 ], [ 1.61, 0 ]
],
    "totImpulseNs": 1196,
    "totalWeightG": 1220,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1365,
    "burnTimeS": 0.68,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J1365",
    "dataFiles": 1,
    "delays": "P",
    "designation": "932J1365-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1568,
    "motorId": "5f4294d2000231000000026a",
    "propInfo": "Scalded Tiger",
    "propWeightG": 444,
    "samples": [
      [ 0, 0 ], [ 0.007, 1577 ], [ 0.011, 1084 ], [ 0.014, 1402 ], [ 0.019, 1084 ], [ 0.023, 1402 ],
      [ 0.027, 1197 ], [ 0.03, 1267 ], [ 0.049, 1312 ], [ 0.078, 1380 ], [ 0.124, 1447 ],[ 0.164, 1484 ],
      [ 0.219, 1515 ], [ 0.267, 1523 ], [ 0.311, 1532 ], [ 0.361, 1526 ], [ 0.391, 1506 ],
      [ 0.455, 1447 ], [ 0.489, 1419 ], [ 0.526, 1394 ], [ 0.564, 1371 ], [ 0.595, 1354 ],
      [ 0.612, 1343 ], [ 0.62, 1334 ], [ 0.624, 1301 ], [ 0.633, 1185 ], [ 0.643, 881.2 ],
      [ 0.649, 661.6 ], [ 0.655, 458.9 ], [ 0.663, 290 ], [ 0.676, 135.1 ], [ 0.687, 0 ]
],
    "totImpulseNs": 932.4,
    "totalWeightG": 1119,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 457.1,
    "burnTimeS": 3.24,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K455",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1483K455-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 787.1,
    "motorId": "5f4294d2000231000000026b",
    "propInfo": "Tamed Tiger",
    "propWeightG": 716,
    "samples": [
      [ 0, 0 ], [ 0.021, 715.7 ], [ 0.035, 789 ], [ 0.052, 735.9 ], [ 0.069, 689.9 ], [ 0.09, 684.1 ],
      [ 0.114, 681.2 ], [ 0.138, 691.3 ], [ 0.19, 682.7 ], [ 0.345, 664 ], [ 0.528, 645.3 ],
      [ 0.583, 641 ], [ 0.735, 625.2 ], [ 0.835, 616.6 ], [ 0.983, 589.3 ], [ 1.163, 567.7 ],
      [ 1.304, 544.7 ], [ 1.598, 493 ], [ 1.663, 480 ], [ 1.932, 434 ], [ 1.984, 426.9 ],[ 2.019, 426.9 ],
      [ 2.077, 408.2 ], [ 2.119, 405.3 ], [ 2.157, 399.5 ], [ 2.205, 388.1 ], [ 2.232, 386.6 ],
      [ 2.315, 369.4 ], [ 2.364, 363.6 ], [ 2.398, 360.7 ], [ 2.433, 346.4 ], [ 2.481, 340.6 ],
      [ 2.522, 333.4 ], [ 2.636, 306.1 ], [ 2.705, 286 ], [ 2.753, 267.3 ], [ 2.802, 240 ],
      [ 2.85, 207 ], [ 2.916, 159.5 ], [ 3.012, 106.4 ], [ 3.081, 79.05 ], [ 3.164, 40.24 ],
      [ 3.24, 0 ]
],
    "totImpulseNs": 1483,
    "totalWeightG": 1504,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 532.6,
    "burnTimeS": 2.71,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K535",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1422K535-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 633.6,
    "motorId": "5f4294d2000231000000026c",
    "propInfo": "Red Rhino",
    "propWeightG": 764,
    "samples": [
      [ 0, 0 ], [ 0.023, 447.7 ], [ 0.04, 534.4 ], [ 0.049, 547.4 ], [ 0.078, 569 ], [ 0.092, 564.7 ],
      [ 0.116, 553.1 ], [ 0.139, 574.8 ], [ 0.173, 589.2 ], [ 0.199, 606.6 ], [ 0.222, 605.1 ],
      [ 0.246, 616.7 ], [ 0.277, 619.6 ], [ 0.298, 625.4 ], [ 0.318, 621 ], [ 0.35, 623.9 ],
      [ 0.378, 626.8 ], [ 0.402, 623.9 ], [ 0.439, 626.8 ], [ 0.462, 628.2 ], [ 0.517, 623.9 ],
      [ 0.572, 623.9 ], [ 0.592, 629.7 ], [ 0.612, 622.5 ], [ 0.647, 625.4 ], [ 0.691, 618.1 ],
      [ 0.722, 613.8 ], [ 0.743, 621 ], [ 0.771, 621 ], [ 0.826, 613.8 ], [ 0.861, 608 ],[ 0.919, 602.2 ],
      [ 0.988, 603.7 ], [ 1.034, 596.5 ], [ 1.101, 599.4 ], [ 1.147, 584.9 ], [ 1.19, 583.5 ],
      [ 1.219, 586.4 ], [ 1.265, 576.2 ], [ 1.326, 576.2 ], [ 1.346, 570.5 ], [ 1.375, 569 ],
      [ 1.416, 566.1 ], [ 1.485, 571.9 ], [ 1.531, 567.6 ], [ 1.56, 550.3 ], [ 1.58, 557.5 ],
      [ 1.673, 547.4 ], [ 1.725, 554.6 ], [ 1.754, 547.4 ], [ 1.783, 540.1 ], [ 1.811, 543 ],
      [ 1.936, 532.9 ], [ 2.109, 519.9 ], [ 2.184, 511.3 ], [ 2.236, 502.6 ], [ 2.262, 501.1 ],
      [ 2.288, 502.6 ], [ 2.317, 491 ], [ 2.355, 467.9 ], [ 2.395, 447.7 ], [ 2.444, 407.3 ],
      [ 2.473, 382.7 ], [ 2.511, 322.1 ], [ 2.542, 252.7 ], [ 2.574, 195 ], [ 2.612, 125.6 ],
      [ 2.652, 70.77 ], [ 2.71, 0 ]
],
    "totImpulseNs": 1442,
    "totalWeightG": 1559,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 664.1,
    "burnTimeS": 2.06,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K665",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1379K665-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 830.1,
    "motorId": "5f4294d2000231000000026d",
    "propInfo": "Black Bear",
    "propWeightG": 964,
    "samples": [
      [ 0, 0 ], [ 0.011, 429.8 ], [ 0.036, 591.3 ], [ 0.042, 620 ], [ 0.063, 636.4 ], [ 0.076, 636.4 ],
      [ 0.097, 624.1 ], [ 0.109, 624.1 ], [ 0.137, 635.1 ], [ 0.21, 658.3 ], [ 0.341, 695.3 ],
      [ 0.413, 724 ], [ 0.497, 740.5 ], [ 0.714, 781.5 ], [ 0.825, 803.4 ], [ 0.937, 810.3 ],
      [ 0.972, 818.5 ], [ 1.029, 819.8 ], [ 1.101, 817.1 ], [ 1.16, 808.9 ], [ 1.273, 773.3 ],
      [ 1.337, 755.5 ], [ 1.396, 729.5 ], [ 1.469, 689.8 ], [ 1.551, 636.4 ], [ 1.625, 589.9 ],
      [ 1.694, 548.8 ], [ 1.745, 521.5 ], [ 1.77, 509.1 ], [ 1.791, 509.1 ], [ 1.806, 506.4 ],
      [ 1.829, 494.1 ], [ 1.863, 446.2 ], [ 1.89, 396.9 ], [ 1.913, 342.2 ], [ 1.928, 290.2 ],
      [ 1.947, 231.3 ], [ 1.972, 164.2 ], [ 1.985, 131.4 ], [ 2.006, 95.81 ], [ 2.027, 61.59 ],
      [ 2.046, 0 ]
],
    "totImpulseNs": 1379,
    "totalWeightG": 1737,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 672.4,
    "burnTimeS": 2.68,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K670",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1806K670-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 820.7,
    "motorId": "5f4294d2000231000000026e",
    "propInfo": "Red Rhino",
    "propWeightG": 955,
    "samples": [
      [ 0, 0 ], [ 0.033, 610.4 ], [ 0.052, 752.1 ], [ 0.06, 776 ], [ 0.076, 785.8 ], [ 0.098, 813.9 ],
      [ 0.109, 822.3 ], [ 0.133, 818.1 ], [ 0.166, 825.1 ], [ 0.185, 820.9 ], [ 0.21, 813.9 ],
      [ 0.226, 825.1 ], [ 0.253, 818.1 ], [ 0.286, 820.9 ], [ 0.321, 818.1 ], [ 0.457, 809.7 ],
      [ 0.593, 805.5 ], [ 0.688, 802.6 ], [ 0.729, 791.4 ], [ 0.803, 792.8 ], [ 0.86, 780.2 ],
      [ 0.925, 778.8 ], [ 0.988, 770.4 ], [ 1.061, 760.5 ], [ 1.116, 759.1 ], [ 1.176, 752.1 ],
      [ 1.271, 735.3 ], [ 1.309, 738.1 ], [ 1.344, 735.3 ], [ 1.363, 731.1 ], [ 1.41, 735.3 ],
      [ 1.448, 735.3 ], [ 1.494, 731.1 ], [ 1.524, 735.3 ], [ 1.557, 731.1 ], [ 1.616, 721.3 ],
      [ 1.654, 714.2 ], [ 1.682, 721.3 ], [ 1.728, 705.8 ], [ 1.766, 705.8 ], [ 1.799, 705.8 ],
      [ 1.826, 698.8 ], [ 1.856, 690.4 ], [ 1.889, 687.6 ], [ 1.921, 689 ], [ 1.946, 684.8 ],
      [ 1.976, 684.8 ], [ 2, 675 ], [ 2.014, 682 ], [ 2.049, 669.3 ], [ 2.063, 659.5 ], [ 2.106, 649.7 ],
      [ 2.136, 651.1 ], [ 2.163, 655.3 ], [ 2.177, 648.3 ], [ 2.199, 651.1 ], [ 2.212, 646.9 ],
      [ 2.22, 638.5 ], [ 2.248, 617.4 ], [ 2.267, 600.6 ], [ 2.283, 599.2 ], [ 2.316, 568.3 ],
      [ 2.359, 516.4 ], [ 2.384, 464.5 ], [ 2.4, 425.2 ], [ 2.444, 327 ], [ 2.474, 259.6 ],
      [ 2.52, 171.2 ], [ 2.563, 112.3 ], [ 2.61, 63.15 ], [ 2.645, 0 ]
],
    "totImpulseNs": 1806,
    "totalWeightG": 1885,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 700.1,
    "burnTimeS": 2.37,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K700",
    "dataFiles": 1,
    "designation": "1660K700-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 867.1,
    "motorId": "5f4294d2000231000000026f",
    "propInfo": "Black Bear",
    "propWeightG": 1205,
    "samples": [
      [ 0, 0 ], [ 0.015, 605.7 ], [ 0.02, 693.8 ], [ 0.037, 714.8 ], [ 0.056, 744.2 ], [ 0.073, 712 ],
      [ 0.1, 695.2 ], [ 0.132, 685.4 ], [ 0.157, 691 ], [ 0.235, 710.6 ], [ 0.333, 731.6 ],
      [ 0.428, 749.8 ], [ 0.531, 762.4 ], [ 0.617, 774.9 ], [ 0.69, 781.9 ], [ 0.764, 790.3 ],
      [ 0.876, 802.9 ], [ 0.957, 812.7 ], [ 1.023, 819.7 ], [ 1.084, 826.7 ], [ 1.192, 832.3 ],
      [ 1.246, 840.7 ], [ 1.307, 833.7 ], [ 1.393, 826.7 ], [ 1.444, 821.1 ], [ 1.508, 811.3 ],
      [ 1.574, 794.5 ], [ 1.633, 776.3 ], [ 1.713, 744.2 ], [ 1.777, 709.2 ], [ 1.853, 668.6 ],
      [ 1.916, 640.7 ], [ 1.956, 615.5 ], [ 2.012, 602.9 ], [ 2.044, 588.9 ], [ 2.083, 553.9 ],
      [ 2.115, 519 ], [ 2.146, 484 ], [ 2.169, 463 ], [ 2.186, 426.6 ], [ 2.222, 328.7 ],[ 2.249, 253.2 ],
      [ 2.279, 179 ], [ 2.306, 123.1 ], [ 2.352, 58.75 ], [ 2.379, 0 ]
],
    "totImpulseNs": 1660,
    "totalWeightG": 2088,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 854.1,
    "burnTimeS": 2.02,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K855",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1725K855-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1035,
    "motorId": "5f4294d20002310000000270",
    "propInfo": "Black Bear",
    "propWeightG": 1205,
    "samples": [
      [ 0, 0 ], [ 0.008, 864 ], [ 0.023, 756.8 ], [ 0.027, 899.7 ], [ 0.042, 857.7 ], [ 0.046, 916.6 ],
      [ 0.058, 880.8 ], [ 0.073, 868.2 ], [ 0.088, 851.4 ], [ 0.098, 855.6 ], [ 0.123, 872.4 ],
      [ 0.177, 889.2 ], [ 0.281, 920.8 ], [ 0.402, 943.9 ], [ 0.494, 962.8 ], [ 0.559, 973.3 ],
      [ 0.636, 985.9 ], [ 0.711, 990.1 ], [ 0.784, 1003 ], [ 0.859, 1011 ], [ 0.932, 1013 ],
      [ 1.017, 1022 ], [ 1.08, 1011 ], [ 1.136, 1001 ], [ 1.246, 977.5 ], [ 1.303, 967 ],[ 1.374, 935.5 ],
      [ 1.455, 891.3 ], [ 1.505, 855.6 ], [ 1.584, 798.8 ], [ 1.64, 750.5 ], [ 1.688, 727.4 ],
      [ 1.726, 714.7 ], [ 1.745, 712.6 ], [ 1.757, 689.5 ], [ 1.782, 668.5 ], [ 1.826, 626.5 ],
      [ 1.845, 590.7 ], [ 1.87, 498.2 ], [ 1.901, 353.2 ], [ 1.928, 239.6 ], [ 1.951, 159.8 ],
      [ 1.972, 100.9 ], [ 1.997, 69.37 ], [ 2.018, 0 ]
],
    "totImpulseNs": 1725,
    "totalWeightG": 2121,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1722,
    "burnTimeS": 0.68,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1720",
    "dataFiles": 1,
    "designation": "1176K1720-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 2007,
    "motorId": "5f4294d20002310000000271",
    "propInfo": "Scalded Tiger",
    "propWeightG": 592,
    "samples": [
      [ 0, 0 ], [ 0.007, 1806 ], [ 0.011, 1608 ], [ 0.016, 1702 ], [ 0.02, 1514 ], [ 0.024, 1744 ],
      [ 0.029, 1542 ], [ 0.032, 1751 ], [ 0.039, 1598 ], [ 0.042, 1737 ], [ 0.047, 1692 ],
      [ 0.05, 1747 ], [ 0.054, 1716 ], [ 0.058, 1737 ], [ 0.065, 1772 ], [ 0.072, 1765 ],[ 0.081, 1824 ],
      [ 0.09, 1827 ], [ 0.115, 1859 ], [ 0.137, 1880 ], [ 0.144, 1869 ], [ 0.148, 1824 ],[ 0.15, 1845 ],
      [ 0.17, 1866 ], [ 0.201, 1886 ], [ 0.234, 1911 ], [ 0.276, 1918 ], [ 0.312, 1928 ],[ 0.345, 1921 ],
      [ 0.384, 1914 ], [ 0.426, 1886 ], [ 0.487, 1827 ], [ 0.526, 1793 ], [ 0.555, 1758 ],
      [ 0.575, 1751 ], [ 0.593, 1737 ], [ 0.604, 1737 ], [ 0.615, 1726 ], [ 0.621, 1702 ],
      [ 0.624, 1650 ], [ 0.628, 1591 ], [ 0.631, 1549 ], [ 0.643, 964.1 ], [ 0.655, 518.6 ],
      [ 0.666, 268 ], [ 0.674, 181 ], [ 0.684, 0 ]
],
    "totImpulseNs": 1176,
    "totalWeightG": 1351,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 986.2,
    "burnTimeS": 2.7,
    "caseInfo": "AMW 54-2550",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L985",
    "dataFiles": 1,
    "delays": "P",
    "designation": "2665L985-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1541,
    "motorId": "5f4294d20002310000000272",
    "propInfo": "Tamed Tiger",
    "propWeightG": 1295,
    "samples": [
      [ 0, 0 ], [ 0.017, 1484 ], [ 0.033, 1394 ], [ 0.061, 1316 ], [ 0.081, 1251 ], [ 0.103, 1290 ],
      [ 0.134, 1307 ], [ 0.17, 1310 ], [ 0.27, 1288 ], [ 0.396, 1271 ], [ 0.485, 1262 ], [ 0.56, 1231 ],
      [ 0.618, 1254 ], [ 0.699, 1243 ], [ 0.827, 1220 ], [ 1.087, 1178 ], [ 1.273, 1159 ],
      [ 1.443, 1136 ], [ 1.619, 1119 ], [ 1.755, 1097 ], [ 1.83, 1091 ], [ 1.878, 1086 ],[ 1.917, 1083 ],
      [ 1.942, 1060 ], [ 1.972, 1021 ], [ 2.034, 906.1 ], [ 2.07, 813.5 ], [ 2.12, 701.3 ],
      [ 2.162, 634 ], [ 2.195, 594.7 ], [ 2.246, 530.2 ], [ 2.298, 451.6 ], [ 2.34, 395.5 ],
      [ 2.407, 328.2 ], [ 2.454, 272.1 ], [ 2.53, 210.4 ], [ 2.56, 187.9 ], [ 2.602, 162.7 ],
      [ 2.649, 115 ], [ 2.688, 0 ]
],
    "totImpulseNs": 2665,
    "totalWeightG": 2572,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 225,
    "burnTimeS": 1.2,
    "caseInfo": "38/390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H225",
    "dataFiles": 1,
    "designation": "H225BL",
    "diameter": 38,
    "impulseClass": "H",
    "length": 249,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 240.3,
    "motorId": "5f4294d20002310000000273",
    "propInfo": "Black Lightning",
    "propWeightG": 173,
    "samples": [
      [ 0, 0 ], [ 0.01507, 113.8 ], [ 0.03264, 193.1 ], [ 0.04896, 172.4 ], [ 0.167, 256.9 ],
      [ 0.3101, 296.5 ], [ 0.5122, 303.4 ], [ 0.6177, 305.2 ], [ 0.8387, 298.3 ], [ 0.8989, 291.4 ],
      [ 0.98, 266.9 ], [ 1.02, 244.7 ], [ 1.05, 222.4 ], [ 1.263, 10.34 ], [ 1.3, 0 ]
],
    "sparky": true,
    "totImpulseNs": 226,
    "totalWeightG": 466,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 235,
    "burnTimeS": 1.4,
    "caseInfo": "38/390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I223",
    "dataFiles": 1,
    "designation": "I223GT",
    "diameter": 38,
    "impulseClass": "I",
    "length": 249,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 240.3,
    "motorId": "5f4294d20002310000000274",
    "propInfo": "Green Tornado",
    "propWeightG": 181,
    "samples": [
      [ 0, 0 ], [ 0.02, 170 ], [ 0.05, 190 ], [ 0.141, 225 ], [ 0.4623, 255 ], [ 0.6817, 258 ],
      [ 0.8874, 260 ], [ 1.148, 255 ], [ 1.324, 224.1 ], [ 1.405, 129.3 ], [ 1.48, 0 ]
],
    "totImpulseNs": 327.9,
    "totalWeightG": 466,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 186,
    "burnTimeS": 1.69,
    "caseInfo": "38/390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H186",
    "dataFiles": 1,
    "designation": "H186RT",
    "diameter": 38,
    "impulseClass": "H",
    "length": 249,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 220.3,
    "motorId": "5f4294d20002310000000275",
    "propInfo": "Red Twister",
    "propWeightG": 178,
    "samples": [
      [ 0, 0 ], [ 0.008, 40.18 ], [ 0.042, 133.6 ], [ 0.058, 178 ], [ 0.1, 196.9 ], [ 0.192, 204 ],
      [ 0.483, 215 ], [ 0.842, 220.3 ], [ 1.075, 219 ], [ 1.267, 219 ], [ 1.35, 207 ], [ 1.42, 165 ],
      [ 1.48, 118.9 ], [ 1.542, 75 ], [ 1.69, 0 ]
],
    "totImpulseNs": 312.1,
    "totalWeightG": 472,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 389,
    "burnTimeS": 1.42,
    "caseInfo": "38/640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I389",
    "dataFiles": 1,
    "designation": "I389GT",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 454.9,
    "motorId": "5f4294d20002310000000276",
    "propInfo": "Green Tornado",
    "propWeightG": 303,
    "samples": [
      [ 0, 0 ], [ 0.016, 182 ], [ 0.025, 277.1 ], [ 0.045, 379.7 ], [ 0.065, 413.4 ], [ 0.366, 450 ],
      [ 0.683, 454.9 ], [ 1.066, 443.3 ], [ 1.144, 421 ], [ 1.183, 385 ], [ 1.241, 280.8 ],
      [ 1.35, 92.87 ], [ 1.42, 0 ]
],
    "totImpulseNs": 553.1,
    "totalWeightG": 693,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 324,
    "burnTimeS": 1.65,
    "caseInfo": "38/640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I324",
    "dataFiles": 1,
    "designation": "I324RT",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 369.2,
    "motorId": "5f4294d20002310000000277",
    "propInfo": "Red Twister",
    "propWeightG": 299,
    "samples": [
      [ 0, 0 ], [ 0.015, 238.5 ], [ 0.09, 310 ], [ 0.25, 360 ], [ 0.65, 365 ], [ 1.068, 369.2 ],
      [ 1.256, 365 ], [ 1.35, 340 ], [ 1.487, 195.4 ], [ 1.65, 0 ]
],
    "totImpulseNs": 526.5,
    "totalWeightG": 688,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 395,
    "burnTimeS": 2.59,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J395",
    "dataFiles": 1,
    "designation": "J395RT",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 460.2,
    "motorId": "5f4294d20002310000000278",
    "propInfo": "Red Twister",
    "propWeightG": 524,
    "samples": [
      [ 0, 0 ], [ 0.06, 267 ], [ 0.1, 333.8 ], [ 0.16, 356 ], [ 0.21, 360.4 ], [ 0.33, 387.1 ],
      [ 1, 460.2 ], [ 1.5, 453.9 ], [ 2.2, 409.4 ], [ 2.4, 360.4 ], [ 2.48, 267 ], [ 2.57, 44.5 ],
      [ 2.59, 0 ]
],
    "totImpulseNs": 1026,
    "totalWeightG": 1160,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 520,
    "burnTimeS": 2.61,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K520",
    "dataFiles": 1,
    "designation": "K520RT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 593.1,
    "motorId": "5f4294d20002310000000279",
    "propInfo": "Red Twister",
    "propWeightG": 698,
    "samples": [
      [ 0, 0 ], [ 0.05, 400.5 ], [ 0.07, 471.7 ], [ 0.16, 502.9 ], [ 0.23, 498.4 ], [ 0.35, 529.5 ],
      [ 0.87, 593.1 ], [ 1.2, 605.2 ], [ 1.55, 578.5 ], [ 1.85, 569.6 ], [ 2.32, 511.8 ],[ 2.45, 387.1 ],
      [ 2.58, 26.7 ], [ 2.61, 0 ]
],
    "totImpulseNs": 1356,
    "totalWeightG": 1442,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 700,
    "burnTimeS": 2.41,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K700",
    "dataFiles": 1,
    "designation": "K700RT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 492,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 788.2,
    "motorId": "5f4294d2000231000000027a",
    "propInfo": "Red Twister",
    "propWeightG": 856,
    "samples": [
      [ 0, 0 ], [ 0.12, 534 ], [ 0.18, 658.6 ], [ 0.2, 725.4 ], [ 0.24, 747.6 ], [ 0.28, 747.6 ],
      [ 0.4, 783.2 ], [ 0.65, 788.2 ], [ 1, 787.6 ], [ 1.65, 769.9 ], [ 2, 729.8 ], [ 2.1, 712 ],
      [ 2.2, 645.3 ], [ 2.38, 115.7 ], [ 2.41, 0 ]
],
    "totImpulseNs": 1691,
    "totalWeightG": 1716,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1185,
    "burnTimeS": 2.1,
    "caseInfo": "54/2550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1185",
    "dataFiles": 1,
    "designation": "K1185GT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1380,
    "motorId": "5f4294d2000231000000027b",
    "propInfo": "Green Tornado",
    "propWeightG": 1314,
    "samples": [
      [ 0, 0 ], [ 0.01, 575 ], [ 0.06, 810 ], [ 0.12, 1050 ], [ 0.17, 1175 ], [ 0.68, 1345 ],
      [ 0.82, 1366 ], [ 0.92, 1379 ], [ 1.65, 1291 ], [ 1.8, 1268 ], [ 1.85, 1224 ], [ 1.87, 1068 ],
      [ 1.92, 801 ], [ 2.05, 222.5 ], [ 2.1, 0 ]
],
    "totImpulseNs": 2460,
    "totalWeightG": 2541,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 985,
    "burnTimeS": 3.6,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L985",
    "dataFiles": 1,
    "designation": "L985GT",
    "diameter": 75,
    "impulseClass": "L",
    "length": 498,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1202,
    "motorId": "5f4294d2000231000000027c",
    "propInfo": "Green Tornado",
    "propWeightG": 1875,
    "samples": [
      [ 0, 0 ], [ 0.2, 725 ], [ 0.3, 795.5 ], [ 0.75, 950 ], [ 1.5, 1120 ], [ 2, 1120 ], [ 3.4, 1113 ],
      [ 3.6, 0 ]
],
    "totImpulseNs": 3580,
    "totalWeightG": 3632,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 789,
    "burnTimeS": 4.17,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L789",
    "dataFiles": 1,
    "designation": "L789RT",
    "diameter": 75,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 996.3,
    "motorId": "5f4294d2000231000000027d",
    "propInfo": "Red Twister",
    "propWeightG": 1796,
    "samples": [
      [ 0, 0 ], [ 0.05, 600 ], [ 0.1, 650 ], [ 0.2, 712 ], [ 0.7542, 850 ], [ 1.5, 900 ], [ 2, 906 ],
      [ 3.413, 867.8 ], [ 3.55, 833.3 ], [ 3.928, 344.8 ], [ 4.17, 0 ]
],
    "totImpulseNs": 3252,
    "totalWeightG": 3378,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1079,
    "burnTimeS": 13.05,
    "caseInfo": "RMS-98/15360",
    "certOrg": "National Association of Rocketry",
    "commonName": "N1000",
    "dataFiles": 1,
    "delays": "P",
    "designation": "N1000W",
    "diameter": 98,
    "impulseClass": "N",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/N1000W.pdf",
    "length": 1046,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2262,
    "motorId": "5f4294d2000231000000027e",
    "propInfo": "White Lightning",
    "propWeightG": 8293,
    "samples": [
      [ 0, 0 ], [ 0.01, 40.21 ], [ 0.012, 148.4 ], [ 0.016, 598.9 ], [ 0.02, 890.1 ], [ 0.024, 995.1 ],
      [ 0.026, 1158 ], [ 0.03, 1342 ], [ 0.038, 1557 ], [ 0.07, 1960 ], [ 0.082, 2051 ], [ 0.102, 2111 ],
      [ 0.156, 2120 ], [ 0.204, 2073 ], [ 0.318, 2091 ], [ 0.376, 1990 ], [ 0.43, 1826 ],[ 0.496, 1734 ],
      [ 0.63, 1658 ], [ 0.74, 1702 ], [ 0.762, 1633 ], [ 0.814, 1670 ], [ 1.632, 1594 ], [ 1.672, 1551 ],
      [ 3.048, 1533 ], [ 5.302, 1379 ], [ 6.806, 1171 ], [ 10.77, 424.7 ], [ 13.44, 106 ],
      [ 14.52, 12.61 ], [ 15.98, 0 ]
],
    "totImpulseNs": 14130,
    "totalWeightG": 12770,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2776,
    "burnTimeS": 5.2,
    "caseInfo": "AMW 98-17500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N2801",
    "dataFiles": 1,
    "designation": "SK-98-17500",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1213,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 4199,
    "motorId": "5f4294d2000231000000027f",
    "propInfo": "Skidmark",
    "samples": [
      [ 0, 0 ], [ 0.08, 2768 ], [ 0.111, 3226 ], [ 0.234, 3410 ], [ 0.475, 3522 ], [ 0.685, 3532 ],
      [ 1.073, 3603 ], [ 1.573, 3664 ], [ 2.301, 3664 ], [ 2.943, 3532 ], [ 3.362, 3379 ],
      [ 3.498, 3216 ], [ 3.603, 2962 ], [ 3.665, 2606 ], [ 3.72, 2300 ], [ 3.905, 1883 ],[ 4.393, 1048 ],
      [ 4.535, 783.7 ], [ 4.868, 519.1 ], [ 5.417, 0 ]
],
    "sparky": true,
    "totImpulseNs": 11940,
    "totalWeightG": 13680,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1666,
    "burnTimeS": 3.42,
    "caseInfo": "75/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1665",
    "dataFiles": 1,
    "designation": "M1665WC",
    "diameter": 75,
    "impulseClass": "M",
    "length": 787,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2299,
    "motorId": "5f4294d20002310000000280",
    "propInfo": "White Cloud",
    "propWeightG": 2950,
    "samples": [
      [ 0, 0 ], [ 0.075, 2068 ], [ 0.125, 2002 ], [ 0.7, 2224 ], [ 0.85, 2269 ], [ 1.6, 2269 ],
      [ 2.12, 1802 ], [ 2.65, 1179 ], [ 3.22, 444.8 ], [ 3.42, 0 ]
],
    "totImpulseNs": 5705,
    "totalWeightG": 5620,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1354,
    "burnTimeS": 3.82,
    "caseInfo": "75/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1355",
    "dataFiles": 1,
    "designation": "M1355RT",
    "diameter": 75,
    "impulseClass": "M",
    "length": 787,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1981,
    "motorId": "5f4294d20002310000000281",
    "propInfo": "Red Twister",
    "propWeightG": 2831,
    "samples": [
      [ 0, 0 ], [ 0.05, 1334 ], [ 0.85, 1779 ], [ 1.35, 1868 ], [ 1.7, 1890 ], [ 2.15, 1859 ],
      [ 3.5, 489.3 ], [ 3.81, 0 ]
],
    "totImpulseNs": 5165,
    "totalWeightG": 5501,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1065,
    "burnTimeS": 3.95,
    "caseInfo": "75/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1065",
    "dataFiles": 1,
    "designation": "L1065BL",
    "diameter": 75,
    "impulseClass": "L",
    "length": 787,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1932,
    "motorId": "5f4294d20002310000000282",
    "propInfo": "Black Lightning",
    "propWeightG": 2693,
    "samples": [
      [ 0, 0 ], [ 0.02, 1334 ], [ 0.1, 1312 ], [ 0.65, 1779 ], [ 1.75, 1757 ], [ 2.2, 1246 ],
      [ 2.75, 667.2 ], [ 3.5, 222.4 ], [ 3.95, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4210,
    "totalWeightG": 5363,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 180.9,
    "burnTimeS": 1.873,
    "caseInfo": "Pro38-3G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I180",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "338I180-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 243,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 213,
    "motorId": "5f4294d20002310000000284",
    "propInfo": "Skidmark",
    "propWeightG": 200,
    "samples": [
      [ 0, 0 ], [ 0.003, 130.4 ], [ 0.028, 173.4 ], [ 0.062, 156.1 ], [ 0.197, 180.9 ], [ 0.644, 206.6 ],
      [ 0.962, 214.9 ], [ 1.336, 205.1 ], [ 1.482, 199 ], [ 1.585, 196.8 ], [ 1.638, 171.1 ],
      [ 1.7, 91.23 ], [ 1.795, 24.88 ], [ 1.962, 0 ]
],
    "sparky": true,
    "totImpulseNs": 338.4,
    "totalWeightG": 394,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 223.4,
    "burnTimeS": 1.946,
    "caseInfo": "Pro38-4G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I223",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "434I223-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 302,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 259.6,
    "motorId": "5f4294d20002310000000285",
    "propInfo": "Skidmark",
    "propWeightG": 266,
    "samples": [
      [ 0, 0 ], [ 0.016, 199.2 ], [ 0.024, 260.2 ], [ 0.07, 207.3 ], [ 0.148, 227.6 ], [ 0.425, 244.7 ],
      [ 0.721, 254.5 ], [ 1.015, 256.9 ], [ 1.3, 248.8 ], [ 1.459, 240.6 ], [ 1.615, 235 ],
      [ 1.712, 195.9 ], [ 1.782, 119.5 ], [ 1.9, 24.39 ], [ 1.984, 0 ]
],
    "sparky": true,
    "totImpulseNs": 434.1,
    "totalWeightG": 494,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 296.7,
    "burnTimeS": 1.813,
    "caseInfo": "Pro38-5G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I297",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "543I297-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 360,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 375.9,
    "motorId": "5f4294d20002310000000286",
    "propInfo": "Skidmark",
    "propWeightG": 329,
    "samples": [
      [ 0, 0 ], [ 0.013, 340.9 ], [ 0.036, 375.1 ], [ 0.069, 354.8 ], [ 0.129, 335.8 ], [ 0.281, 338.4 ],
      [ 0.662, 344.7 ], [ 0.855, 344.7 ], [ 1.084, 329.5 ], [ 1.295, 319.4 ], [ 1.359, 313 ],
      [ 1.447, 314.3 ], [ 1.534, 269.9 ], [ 1.637, 119.1 ], [ 1.681, 69.7 ], [ 1.74, 40.55 ],
      [ 1.846, 12.67 ], [ 1.943, 0 ]
],
    "sparky": true,
    "totImpulseNs": 542.8,
    "totalWeightG": 591,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 380.6,
    "burnTimeS": 1.63,
    "caseInfo": "Pro38-6G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J381",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "660J381-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 419,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 503.1,
    "motorId": "5f4294d20002310000000287",
    "propInfo": "Skidmark",
    "propWeightG": 396,
    "samples": [
      [ 0, 0 ], [ 0.048, 505 ], [ 0.111, 473.2 ], [ 0.294, 461.5 ], [ 0.968, 436.8 ], [ 1.353, 392 ],
      [ 1.385, 366.1 ], [ 1.556, 137.7 ], [ 1.662, 47.09 ], [ 1.706, 22.37 ], [ 1.874, 0 ]
],
    "sparky": true,
    "totImpulseNs": 659.9,
    "totalWeightG": 688,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 441.7,
    "burnTimeS": 2.48,
    "caseInfo": "AMW 54-1050",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J440",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1109J440-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 662.1,
    "motorId": "5f4294d20002310000000288",
    "propInfo": "Blue Baboon",
    "propWeightG": 528.4,
    "samples": [
      [ 0, 0 ], [ 0.01, 30 ], [ 0.02, 700 ], [ 0.06, 790 ], [ 0.1, 800 ], [ 0.2, 760 ], [ 0.4, 725 ],
      [ 0.6, 695 ], [ 0.8, 680 ], [ 1, 658 ], [ 1.32, 600 ], [ 1.55, 400 ], [ 1.7, 180 ], [ 1.87, 175 ],
      [ 2, 75 ], [ 2.1, 0 ]
],
    "totImpulseNs": 1109,
    "totalWeightG": 1229,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 475.4,
    "burnTimeS": 2.6,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J475",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1233J475-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 627.5,
    "motorId": "5f4294d20002310000000289",
    "propInfo": "Blue Baboon",
    "propWeightG": 704.5,
    "samples": [
      [ 0, 0 ], [ 0.03, 30 ], [ 0.04, 500 ], [ 0.075, 600 ], [ 0.1, 515 ], [ 0.2, 530 ], [ 0.4, 540 ],
      [ 0.6, 535 ], [ 0.8, 535 ], [ 1, 530 ], [ 1.2, 520 ], [ 1.4, 510 ], [ 1.6, 500 ], [ 1.8, 490 ],
      [ 2, 480 ], [ 2.2, 490 ], [ 2.28, 450 ], [ 2.43, 180 ], [ 2.5, 100 ], [ 2.6, 60 ], [ 2.8, 28 ],
      [ 3, 10 ], [ 3.2, 0 ]
],
    "totImpulseNs": 1233,
    "totalWeightG": 1493,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 712.2,
    "burnTimeS": 2.51,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K710",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1791K710-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 766.8,
    "motorId": "5f4294d2000231000000028a",
    "propInfo": "Blue Baboon",
    "propWeightG": 880.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 500 ], [ 0.02, 850 ], [ 0.025, 910 ], [ 0.03, 840 ], [ 0.06, 860 ],[ 0.12, 875 ],
      [ 0.2, 875 ], [ 0.3, 872 ], [ 1.1, 815 ], [ 1.85, 740 ], [ 1.95, 720 ], [ 2.2, 295 ],[ 2.37, 280 ],
      [ 2.5, 90 ], [ 2.6, 20 ], [ 2.8, 0 ]
],
    "totImpulseNs": 1791,
    "totalWeightG": 1812,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1128,
    "burnTimeS": 2.27,
    "caseInfo": "AMW 54-2550",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1130",
    "dataFiles": 3,
    "delays": "P",
    "designation": "2551K1130-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1549,
    "motorId": "5f4294d2000231000000028b",
    "propInfo": "Blue Baboon",
    "propWeightG": 1334,
    "samples": [
      [ 0, 0 ], [ 0.01, 1000 ], [ 0.013, 1490 ], [ 0.02, 1548 ], [ 0.04, 1500 ], [ 0.09, 1335 ],
      [ 0.2, 1325 ], [ 1, 1325 ], [ 1.5, 1325 ], [ 1.63, 1345 ], [ 1.7, 1155 ], [ 1.8, 805 ],
      [ 1.9, 685 ], [ 2, 475 ], [ 2.1, 315 ], [ 2.2, 145 ], [ 2.3, 0 ]
],
    "totImpulseNs": 2551,
    "totalWeightG": 2574,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 4891,
    "burnTimeS": 7.6,
    "caseInfo": "Pro150-40k",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O4900",
    "dataFiles": 2,
    "delays": "P",
    "designation": "37148O4900-P",
    "diameter": 161,
    "impulseClass": "O",
    "length": 957,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 5587,
    "motorId": "5f4294d2000231000000028c",
    "propInfo": "Blue Streak",
    "propWeightG": 17700,
    "samples": [
      [ 0, 0 ], [ 0.06, 800 ], [ 0.1, 4000 ], [ 0.15, 5500 ], [ 0.25, 5160 ], [ 0.45, 5130 ],
      [ 0.8, 5400 ], [ 1, 5300 ], [ 2, 5450 ], [ 3, 5347 ], [ 4, 5160 ], [ 5, 4950 ], [ 6, 4700 ],
      [ 6.8, 4400 ], [ 7.05, 4400 ], [ 7.3, 3800 ], [ 7.6, 300 ], [ 7.8, 0 ]
],
    "totImpulseNs": 37150,
    "totalWeightG": 32650,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 36.4,
    "burnTimeS": 1.42,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F36",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "51F36-14A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 46.3,
    "motorId": "5f4294d2000231000000028d",
    "propInfo": "Blue Streak",
    "propWeightG": 25.6,
    "samples": [
      [ 0, 0 ], [ 0.01, 12 ], [ 0.02, 25 ], [ 0.03, 41 ], [ 0.04, 42 ], [ 0.05, 42 ], [ 0.06, 40 ],
      [ 0.07, 34 ], [ 0.08, 34 ], [ 0.09, 35 ], [ 0.1, 36 ], [ 0.2, 40 ], [ 0.3, 42 ], [ 0.4, 43 ],
      [ 0.5, 43 ], [ 0.6, 43 ], [ 0.7, 43 ], [ 0.8, 42 ], [ 0.9, 41 ], [ 1, 40 ], [ 1.1, 38 ],
      [ 1.24, 37 ], [ 1.3, 12 ], [ 1.4, 2 ], [ 1.5, 0 ]
],
    "totImpulseNs": 51.5,
    "totalWeightG": 101,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 83.1,
    "burnTimeS": 1.29,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G83",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "107G83-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 99,
    "motorId": "5f4294d2000231000000028e",
    "propInfo": "Blue Streak",
    "propWeightG": 51.1,
    "samples": [
      [ 0, 0 ], [ 0.01, 13 ], [ 0.02, 82 ], [ 0.03, 98 ], [ 0.04, 100 ], [ 0.05, 90 ], [ 0.06, 86 ],
      [ 0.12, 93 ], [ 0.2, 96 ], [ 0.3, 96 ], [ 0.7, 92 ], [ 0.9, 88 ], [ 1.07, 83 ], [ 1.1, 80 ],
      [ 1.2, 30 ], [ 1.3, 4 ], [ 1.33, 0 ]
],
    "totImpulseNs": 107.4,
    "totalWeightG": 145,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 133.1,
    "burnTimeS": 1.23,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H133",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "163H133-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 191.1,
    "motorId": "5f4294d2000231000000028f",
    "propInfo": "Blue Streak",
    "propWeightG": 76.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 10 ], [ 0.017, 100 ], [ 0.023, 190 ], [ 0.028, 200 ], [ 0.042, 140 ],
      [ 0.05, 135 ], [ 0.12, 152 ], [ 0.35, 154 ], [ 1, 135 ], [ 1.065, 110 ], [ 1.2, 25 ],[ 1.28, 0 ]
],
    "totImpulseNs": 163.3,
    "totalWeightG": 190,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 171.3,
    "burnTimeS": 1.27,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H170",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "217H170-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 243.8,
    "motorId": "5f4294d20002310000000290",
    "propInfo": "Blue Streak",
    "propWeightG": 102.3,
    "samples": [
      [ 0, 0 ], [ 0.01, 150 ], [ 0.015, 200 ], [ 0.025, 240 ], [ 0.075, 210 ], [ 0.13, 205 ],
      [ 0.35, 204 ], [ 0.7, 205 ], [ 0.8, 190 ], [ 0.9, 185 ], [ 0.99, 173 ], [ 1.1, 60 ], [ 1.22, 25 ],
      [ 1.34, 0 ]
],
    "totImpulseNs": 217.1,
    "totalWeightG": 232,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 201.7,
    "burnTimeS": 1.29,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H200",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "261H200-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 303.2,
    "motorId": "5f4294d20002310000000291",
    "propInfo": "Blue Streak",
    "propWeightG": 127.9,
    "samples": [
      [ 0, 0 ], [ 0.01, 40 ], [ 0.018, 280 ], [ 0.026, 295 ], [ 0.06, 250 ], [ 0.15, 236 ],[ 0.65, 235 ],
      [ 0.8, 227 ], [ 0.9, 212 ], [ 0.98, 205 ], [ 1.09, 135 ], [ 1.2, 68 ], [ 1.3, 20 ], [ 1.4, 0 ]
],
    "totImpulseNs": 260.8,
    "totalWeightG": 274,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 254.2,
    "burnTimeS": 1.24,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H255",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "315H255-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 411.1,
    "motorId": "5f4294d20002310000000292",
    "propInfo": "Blue Streak",
    "propWeightG": 153.4,
    "samples": [
      [ 0, 0 ], [ 0.015, 10 ], [ 0.018, 260 ], [ 0.024, 404 ], [ 0.03, 412 ], [ 0.047, 375 ],
      [ 0.08, 340 ], [ 0.2, 316 ], [ 0.4, 303 ], [ 0.6, 295 ], [ 0.87, 285 ], [ 0.93, 273 ],
      [ 1, 200 ], [ 1.1, 80 ], [ 1.252, 0 ]
],
    "totImpulseNs": 315.4,
    "totalWeightG": 318,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 821.6,
    "burnTimeS": 2.9,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K820",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "2383K820-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1471,
    "motorId": "5f4294d20002310000000293",
    "propInfo": "Blue Streak",
    "propWeightG": 1164,
    "samples": [
      [ 0, 0 ], [ 0.01, 800 ], [ 0.02, 1400 ], [ 0.029, 1720 ], [ 0.04, 960 ], [ 0.1, 1050 ],
      [ 0.2, 1060 ], [ 0.4, 1040 ], [ 0.6, 1030 ], [ 1, 1020 ], [ 1.5, 980 ], [ 2, 880 ], [ 2.1, 800 ],
      [ 2.2, 640 ], [ 2.3, 520 ], [ 2.4, 410 ], [ 2.5, 390 ], [ 2.6, 280 ], [ 2.7, 180 ], [ 2.8, 110 ],
      [ 2.9, 50 ], [ 3, 0 ]
],
    "totImpulseNs": 2383,
    "totalWeightG": 1982,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 991,
    "burnTimeS": 2.8,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L990",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2771L990-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1703,
    "motorId": "5f4294d20002310000000294",
    "propInfo": "Blue Streak",
    "propWeightG": 1369,
    "samples": [
      [ 0, 0 ], [ 0.01, 800 ], [ 0.012, 1450 ], [ 0.02, 1625 ], [ 0.05, 1320 ], [ 0.15, 1230 ],
      [ 1.8, 1150 ], [ 1.88, 1105 ], [ 2, 845 ], [ 2.203, 825 ], [ 2.41, 355 ], [ 2.6, 320 ],
      [ 2.9, 0 ]
],
    "totImpulseNs": 2771,
    "totalWeightG": 2236,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1396,
    "burnTimeS": 3.51,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1395",
    "dataFiles": 2,
    "delays": "P",
    "designation": "4895L1395-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1780,
    "motorId": "5f4294d20002310000000295",
    "propInfo": "Blue Streak",
    "propWeightG": 2365,
    "samples": [
      [ 0, 0 ], [ 0.02, 100 ], [ 0.04, 1400 ], [ 0.1, 1800 ], [ 0.2, 1500 ], [ 0.4, 1540 ],[ 0.8, 1591 ],
      [ 1.1, 1641 ], [ 2.4, 1481 ], [ 2.8, 1446 ], [ 3, 1500 ], [ 3.18, 830 ], [ 3.35, 100 ],
      [ 3.45, 0 ]
],
    "totImpulseNs": 4895,
    "totalWeightG": 4323,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1668,
    "burnTimeS": 3.63,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1670",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6026M1670-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2232,
    "motorId": "5f4294d20002310000000296",
    "propInfo": "Blue Streak",
    "propWeightG": 2130,
    "samples": [
      [ 0, 0 ], [ 0.055, 100 ], [ 0.092, 1500 ], [ 0.1, 2000 ], [ 0.15, 2200 ], [ 0.2, 1800 ],
      [ 0.5, 1950 ], [ 1, 2034 ], [ 1.5, 2000 ], [ 2, 1900 ], [ 2.5, 1760 ], [ 2.9, 1700 ],[ 3, 1650 ],
      [ 3.3, 530 ], [ 3.4, 350 ], [ 3.9, 0 ]
],
    "totImpulseNs": 6042,
    "totalWeightG": 5231,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 117.8,
    "burnTimeS": 1.35,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G118",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "159G118-15A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 157.3,
    "motorId": "5f4294d20002310000000297",
    "propInfo": "Blue Streak",
    "propWeightG": 76.7,
    "samples": [
      [ 0, 0 ], [ 0.005, 1.3 ], [ 0.012, 80 ], [ 0.02, 156 ], [ 0.04, 148 ], [ 0.05, 105 ],[ 0.11, 121 ],
      [ 0.3, 131 ], [ 0.5, 134 ], [ 0.7, 130 ], [ 0.95, 126 ], [ 1.13, 126 ], [ 1.18, 115 ],
      [ 1.27, 40 ], [ 1.31, 20 ], [ 1.35, 6 ], [ 1.37, 0 ]
],
    "totImpulseNs": 159.1,
    "totalWeightG": 188,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 57.2,
    "burnTimeS": 1.89,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G57",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "108G57-12A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 85.4,
    "motorId": "5f4294d20002310000000298",
    "propInfo": "Classic",
    "propWeightG": 51.4,
    "samples": [
      [ 0, 0 ], [ 0.01, 8 ], [ 0.02, 70 ], [ 0.04, 88 ], [ 0.05, 80 ], [ 0.06, 71 ], [ 0.08, 76 ],
      [ 0.11, 79 ], [ 0.2, 81 ], [ 0.6, 72 ], [ 1, 56 ], [ 1.5, 47 ], [ 1.6, 48 ], [ 1.7, 28 ],
      [ 1.8, 14 ], [ 1.9, 3 ], [ 1.93, 0 ]
],
    "totImpulseNs": 107.8,
    "totalWeightG": 146,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 90.3,
    "burnTimeS": 1.82,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H90",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "164H90-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 154.1,
    "motorId": "5f4294d20002310000000299",
    "propInfo": "Classic",
    "propWeightG": 77.1,
    "samples": [
      [ 0, 0 ], [ 0.021, 10 ], [ 0.028, 154 ], [ 0.055, 155 ], [ 0.075, 108 ], [ 0.13, 120 ],
      [ 0.35, 115 ], [ 1.4, 83 ], [ 1.48, 84 ], [ 1.56, 73 ], [ 1.69, 22 ], [ 1.76, 8 ], [ 1.85, 0 ]
],
    "totImpulseNs": 164.2,
    "totalWeightG": 163,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 118.4,
    "burnTimeS": 1.83,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H118",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "216H118-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 191.3,
    "motorId": "5f4294d2000231000000029a",
    "propInfo": "Classic",
    "propWeightG": 102.8,
    "samples": [
      [ 0, 0 ], [ 0.01, 7 ], [ 0.02, 144 ], [ 0.04, 183 ], [ 0.06, 182 ], [ 0.08, 176 ], [ 0.09, 173 ],
      [ 0.1, 172 ], [ 0.2, 160 ], [ 0.4, 147 ], [ 0.5, 142 ], [ 0.7, 134 ], [ 0.8, 127 ], [ 1, 121 ],
      [ 1.1, 118 ], [ 1.2, 115 ], [ 1.4, 109 ], [ 1.5, 104 ], [ 1.6, 75 ], [ 1.7, 31 ], [ 1.8, 13 ],
      [ 1.9, 0 ]
],
    "totImpulseNs": 216.2,
    "totalWeightG": 232,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 141.8,
    "burnTimeS": 1.89,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H140",
    "dataFiles": 2,
    "delays": "2,4,6,8,11",
    "designation": "268H140-11A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 247.8,
    "motorId": "5f4294d2000231000000029b",
    "propInfo": "Classic",
    "propWeightG": 128.5,
    "samples": [
      [ 0, 0 ], [ 0.015, 30 ], [ 0.025, 240 ], [ 0.04, 245 ], [ 0.09, 220 ], [ 0.2, 200 ], [ 0.4, 185 ],
      [ 0.8, 160 ], [ 1.4, 140 ], [ 1.49, 100 ], [ 1.63, 50 ], [ 1.75, 35 ], [ 1.9, 12 ], [ 2.05, 0 ]
],
    "totImpulseNs": 268,
    "totalWeightG": 277,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 161.5,
    "burnTimeS": 1.93,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H160",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "312H160-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 334.8,
    "motorId": "5f4294d2000231000000029c",
    "propInfo": "Classic",
    "propWeightG": 154.2,
    "samples": [
      [ 0, 0 ], [ 0.015, 10 ], [ 0.022, 260 ], [ 0.04, 316 ], [ 0.1, 280 ], [ 0.25, 241 ], [ 0.6, 201 ],
      [ 1, 175 ], [ 1.27, 165 ], [ 1.35, 140 ], [ 1.45, 100 ], [ 1.65, 48 ], [ 1.85, 25 ], [ 2.2, 0 ]
],
    "totImpulseNs": 311.7,
    "totalWeightG": 319,
    "type": "reload",
    "updatedOn": "2022-07-21"
  },
  {
    "availability": "regular",
    "avgThrustN": 419.9,
    "burnTimeS": 2.4,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J420",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "1008J420-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 783.1,
    "motorId": "5f4294d2000231000000029d",
    "propInfo": "Classic",
    "propWeightG": 479.4,
    "samples": [
      [ 0, 0 ], [ 0.015, 360 ], [ 0.02, 660 ], [ 0.035, 800 ], [ 0.075, 660 ], [ 0.25, 625 ],
      [ 0.7, 560 ], [ 1.45, 495 ], [ 1.51, 480 ], [ 1.62, 350 ], [ 1.8, 220 ], [ 1.9, 150 ],
      [ 2, 120 ], [ 2.25, 48 ], [ 2.62, 0 ]
],
    "totImpulseNs": 1008,
    "totalWeightG": 874,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 204.2,
    "burnTimeS": 1.7,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I204",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "348I204-13A",
    "diameter": 29,
    "impulseClass": "I",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 356.8,
    "motorId": "5f4294d2000231000000029e",
    "propInfo": "Imax",
    "propWeightG": 185.4,
    "samples": [
      [ 0, 0 ], [ 0.01, 100 ], [ 0.012, 395 ], [ 0.03, 310 ], [ 0.3, 286 ], [ 0.5, 270 ], [ 0.7, 251 ],
      [ 1, 228 ], [ 1.1, 215 ], [ 1.2, 165 ], [ 1.3, 125 ], [ 1.4, 95 ], [ 1.5, 52 ], [ 1.6, 36 ],
      [ 1.72, 0 ]
],
    "totImpulseNs": 347.7,
    "totalWeightG": 349,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 531.2,
    "burnTimeS": 2.1,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J530",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "1115J530-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 828.8,
    "motorId": "5f4294d2000231000000029f",
    "propInfo": "Imax",
    "propWeightG": 576.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 30 ], [ 0.02, 700 ], [ 0.06, 790 ], [ 0.1, 800 ], [ 0.2, 760 ], [ 0.4, 725 ],
      [ 0.6, 695 ], [ 0.8, 680 ], [ 1, 658 ], [ 1.32, 600 ], [ 1.55, 400 ], [ 1.7, 180 ], [ 1.87, 175 ],
      [ 2, 75 ], [ 2.1, 0 ]
],
    "totImpulseNs": 1116,
    "totalWeightG": 977,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 583.8,
    "burnTimeS": 4.55,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L585",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2653L585-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 679.8,
    "motorId": "5f4294d200023100000002a0",
    "propInfo": "Imax",
    "propWeightG": 1450,
    "samples": [
      [ 0, 0 ], [ 0.01, 200 ], [ 0.02, 300 ], [ 0.04, 500 ], [ 0.08, 630 ], [ 0.1, 650 ], [ 0.25, 629 ],
      [ 0.4, 639 ], [ 0.8, 648 ], [ 1.2, 654 ], [ 1.6, 659 ], [ 2, 653 ], [ 2.4, 640 ], [ 2.8, 610 ],
      [ 3.2, 580 ], [ 3.6, 550 ], [ 4, 515 ], [ 4.2, 510 ], [ 4.45, 110 ], [ 4.57, 0 ]
],
    "totImpulseNs": 2653,
    "totalWeightG": 2784,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1537,
    "burnTimeS": 4.44,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1540",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6819M1540-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2329,
    "motorId": "5f4294d200023100000002a1",
    "propInfo": "Imax",
    "propWeightG": 3624,
    "samples": [
      [ 0, 0 ], [ 0.02, 800 ], [ 0.04, 1250 ], [ 0.06, 1800 ], [ 0.08, 2400 ], [ 0.15, 2060 ],
      [ 0.2, 2000 ], [ 0.35, 2100 ], [ 0.55, 1940 ], [ 0.7, 1900 ], [ 1.7, 1830 ], [ 2.5, 1720 ],
      [ 3.38, 1550 ], [ 3.83, 680 ], [ 4, 530 ], [ 4.5, 0 ]
],
    "totImpulseNs": 6819,
    "totalWeightG": 5906,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 996.5,
    "burnTimeS": 3.63,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L995",
    "dataFiles": 2,
    "delays": "P",
    "designation": "3618L995-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1405,
    "motorId": "5f4294d200023100000002a2",
    "propInfo": "Red Lightning",
    "propWeightG": 1913,
    "samples": [
      [ 0, 0 ], [ 0.02, 50 ], [ 0.05, 200 ], [ 0.09, 1110 ], [ 0.14, 1250 ], [ 0.25, 1211 ],
      [ 0.9, 1220 ], [ 1.2, 1280 ], [ 1.4, 1245 ], [ 2.4, 940 ], [ 3.1, 745 ], [ 3.32, 740 ],
      [ 3.6, 110 ], [ 3.8, 0 ]
],
    "totImpulseNs": 3618,
    "totalWeightG": 3591,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1811,
    "burnTimeS": 3.39,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1810",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6128M1810-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2159,
    "motorId": "5f4294d200023100000002a3",
    "propInfo": "Red Lightning",
    "propWeightG": 3196,
    "samples": [
      [ 0, 0 ], [ 0.01, 30 ], [ 0.03, 120 ], [ 0.065, 1000 ], [ 0.093, 1500 ], [ 0.11, 1950 ],
      [ 0.16, 1850 ], [ 0.35, 1915 ], [ 0.6, 1965 ], [ 1.15, 2085 ], [ 1.35, 2080 ], [ 2.15, 1970 ],
      [ 2.6, 1820 ], [ 2.95, 1810 ], [ 3.06, 1715 ], [ 3.15, 1185 ], [ 3.32, 240 ], [ 3.4, 90 ],
      [ 3.58, 0 ]
],
    "totImpulseNs": 6132,
    "totalWeightG": 5416,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 612.1,
    "burnTimeS": 2.5,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K610",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1531K610-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 730.6,
    "motorId": "5f4294d200023100000002a4",
    "propInfo": "Skidmark",
    "propWeightG": 861,
    "samples": [
      [ 0, 0 ], [ 0.01, 300 ], [ 0.02, 745 ], [ 0.035, 650 ], [ 0.06, 560 ], [ 0.12, 635 ],[ 0.21, 670 ],
      [ 0.4, 695 ], [ 0.7, 700 ], [ 1, 690 ], [ 1.35, 665 ], [ 1.7, 630 ], [ 2.05, 585 ], [ 2.19, 590 ],
      [ 2.24, 500 ], [ 2.3, 350 ], [ 2.4, 205 ], [ 2.5, 60 ], [ 2.6, 15 ], [ 2.7, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1531,
    "totalWeightG": 1765,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 106,
    "burnTimeS": 1.31,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G106",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "138G106-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 128.7,
    "motorId": "5f4294d200023100000002a5",
    "propInfo": "Skidmark",
    "propWeightG": 75,
    "samples": [
      [ 0, 0 ], [ 0.018, 20 ], [ 0.023, 80 ], [ 0.03, 131 ], [ 0.05, 105 ], [ 0.065, 75 ], [ 0.1, 94 ],
      [ 0.25, 104 ], [ 0.4, 111 ], [ 0.65, 120 ], [ 0.86, 123 ], [ 1.05, 124 ], [ 1.13, 121 ],
      [ 1.17, 110 ], [ 1.2, 85 ], [ 1.23, 40 ], [ 1.25, 20 ], [ 1.3, 5 ], [ 1.36, 0 ]
],
    "sparky": true,
    "totImpulseNs": 138.3,
    "totalWeightG": 187,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 123,
    "burnTimeS": 1.44,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H123",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "176H123-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 149.8,
    "motorId": "5f4294d200023100000002a6",
    "propInfo": "Skidmark",
    "propWeightG": 100,
    "samples": [
      [ 0, 0 ], [ 0.021, 10 ], [ 0.028, 139 ], [ 0.055, 139 ], [ 0.075, 108 ], [ 0.13, 120 ],
      [ 0.35, 132 ], [ 0.65, 140 ], [ 1, 144 ], [ 1.15, 143 ], [ 1.22, 145 ], [ 1.35, 23 ],[ 1.53, 0 ]
],
    "sparky": true,
    "totImpulseNs": 176.5,
    "totalWeightG": 228,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 159,
    "burnTimeS": 1.4,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H160",
    "dataFiles": 1,
    "delays": "5,7,9,11,14",
    "designation": "220H160-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 191.9,
    "motorId": "5f4294d200023100000002a7",
    "propInfo": "Skidmark",
    "propWeightG": 125,
    "samples": [
      [ 0, 0 ], [ 0.01, 10 ], [ 0.02, 160 ], [ 0.03, 185 ], [ 0.09, 170 ], [ 0.25, 168 ], [ 0.5, 176 ],
      [ 0.9, 184 ], [ 1.1, 185 ], [ 1.16, 180 ], [ 1.31, 28 ], [ 1.383, 0 ]
],
    "sparky": true,
    "totImpulseNs": 220.5,
    "totalWeightG": 272,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 179.6,
    "burnTimeS": 1.44,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H180",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "258H180-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 266.1,
    "motorId": "5f4294d200023100000002a8",
    "propInfo": "Skidmark",
    "propWeightG": 150,
    "samples": [
      [ 0, 0 ], [ 0.01, 10 ], [ 0.02, 250 ], [ 0.03, 270 ], [ 0.07, 239 ], [ 0.13, 227 ], [ 0.35, 218 ],
      [ 0.6, 216 ], [ 0.8, 207 ], [ 0.98, 208 ], [ 1.06, 204 ], [ 1.15, 135 ], [ 1.23, 65 ],
      [ 1.4, 12 ], [ 1.56, 0 ]
],
    "sparky": true,
    "totImpulseNs": 258,
    "totalWeightG": 314,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 454.1,
    "burnTimeS": 3,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K454",
    "dataFiles": 2,
    "delays": "10,11,12,13,14,15,16,17,18,19",
    "designation": "1364K454-19A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 620,
    "motorId": "5f4294d200023100000002a9",
    "propInfo": "Skidmark",
    "propWeightG": 769.2,
    "samples": [
      [ 0, 0 ], [ 0.021, 10 ], [ 0.028, 421 ], [ 0.032, 586 ], [ 0.069, 405 ], [ 0.2, 475 ],
      [ 0.4, 510 ], [ 0.6, 530 ], [ 0.9, 532 ], [ 1.2, 525 ], [ 1.6, 505 ], [ 2, 470 ], [ 2.4, 415 ],
      [ 2.55, 410 ], [ 2.69, 430 ], [ 2.93, 70 ], [ 3, 27 ], [ 3.15, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1364,
    "totalWeightG": 1391,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 513.4,
    "burnTimeS": 3.22,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K515",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1654K515-16A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 718.5,
    "motorId": "5f4294d200023100000002aa",
    "propInfo": "Skidmark",
    "propWeightG": 961.5,
    "samples": [
      [ 0, 0 ], [ 0.01, 10 ], [ 0.02, 250 ], [ 0.028, 550 ], [ 0.035, 710 ], [ 0.06, 540 ],[ 0.065, 530 ],
      [ 0.1, 560 ], [ 0.25, 600 ], [ 0.5, 612 ], [ 0.75, 612 ], [ 1.1, 602 ], [ 1.5, 578 ],[ 2, 531 ],
      [ 2.6, 460 ], [ 2.78, 458 ], [ 2.85, 450 ], [ 2.95, 300 ], [ 3.03, 200 ], [ 3.18, 40 ],
      [ 3.35, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1654,
    "totalWeightG": 1654,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 231,
    "burnTimeS": 2.32,
    "certOrg": "National Association of Rocketry",
    "commonName": "I250",
    "dataFiles": 1,
    "designation": "I250",
    "diameter": 54,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Alpha/I250.pdf",
    "length": 711,
    "manufacturer": "Alpha Hybrids",
    "manufacturerAbbrev": "Alpha",
    "maxThrustN": 375,
    "motorId": "5f4294d200023100000002ab",
    "propWeightG": 353.8,
    "samples": [
      [ 0, 0 ], [ 0.002, 78.7 ], [ 0.004, 84.88 ], [ 0.006, 131.6 ], [ 0.01, 285.7 ], [ 0.012, 299.1 ],
      [ 0.016, 253.2 ], [ 0.022, 294.5 ], [ 0.042, 282.7 ], [ 0.056, 314.2 ], [ 0.116, 345 ],
      [ 0.136, 317.5 ], [ 0.196, 344.4 ], [ 0.21, 325.1 ], [ 0.298, 342 ], [ 0.312, 321.2 ],
      [ 0.316, 341.7 ], [ 0.33, 322.6 ], [ 0.334, 345.5 ], [ 0.348, 319 ], [ 0.362, 339.9 ],
      [ 0.366, 318.9 ], [ 0.4779, 307.2 ], [ 0.5719, 330.3 ], [ 0.5839, 308 ], [ 0.5879, 326.3 ],
      [ 0.7919, 294.5 ], [ 1.462, 268.7 ], [ 1.6, 228.3 ], [ 1.808, 92.62 ], [ 2.34, 16.84 ],
      [ 2.77, 0 ]
],
    "totImpulseNs": 540,
    "totalWeightG": 1854,
    "type": "hybrid",
    "updatedOn": "2022-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 316,
    "burnTimeS": 1.5,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I316",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "I316-SF",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Spitfire.pdf",
    "length": 292,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "motorId": "5f4294d200023100000002ac",
    "propInfo": "Spitfire",
    "propWeightG": 240,
    "samples": [
      [ 0, 0 ], [ 0.009317, 448.3 ], [ 0.1273, 491.4 ], [ 0.2422, 500 ], [ 0.3478, 482.8 ],
      [ 0.4845, 439.7 ], [ 1, 258.6 ], [ 1.5, 0 ]
],
    "sparky": true,
    "totImpulseNs": 408.6,
    "totalWeightG": 540,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 1242,
    "burnTimeS": 4.485,
    "caseInfo": "76/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1200",
    "dataFiles": 2,
    "designation": "M1200-SF",
    "diameter": 76,
    "impulseClass": "M",
    "length": 785,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1550,
    "motorId": "5f4294d200023100000002ad",
    "propInfo": "Spitfire",
    "propWeightG": 2968,
    "samples": [
      [ 0, 0 ], [ 0.02, 1018 ], [ 0.5, 1203 ], [ 1, 1303 ], [ 1.5, 1398 ], [ 2, 1515 ], [ 2.5, 1499 ],
      [ 3, 1409 ], [ 3.5, 1203 ], [ 4, 411.6 ], [ 4.25, 89.71 ], [ 4.5, 0 ]
],
    "sparky": true,
    "totImpulseNs": 5123,
    "totalWeightG": 5368,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 783.3,
    "burnTimeS": 3.83,
    "caseInfo": "76/3600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L780",
    "dataFiles": 2,
    "designation": "L780-SF",
    "diameter": 76,
    "impulseClass": "L",
    "length": 498,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 995,
    "motorId": "5f4294d200023100000002ae",
    "propInfo": "Spitfire",
    "propWeightG": 1800,
    "samples": [
      [ 0, 0 ], [ 0.02, 506.6 ], [ 0.5, 696.6 ], [ 1, 844.3 ], [ 1.5, 897.1 ], [ 2, 934 ], [ 2.5, 918.2 ],
      [ 3, 865.4 ], [ 3.5, 802.1 ], [ 3.8, 0 ]
],
    "sparky": true,
    "totImpulseNs": 3006,
    "totalWeightG": 3500,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 835,
    "burnTimeS": 2.73,
    "caseInfo": "54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K830",
    "dataFiles": 2,
    "designation": "K830-SF",
    "diameter": 54,
    "impulseClass": "K",
    "length": 726,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1174,
    "motorId": "5f4294d200023100000002af",
    "propInfo": "Spitfire",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.02, 670.2 ], [ 0.5034, 739.3 ], [ 0.9983, 840.5 ], [ 1.502, 918.3 ], [ 1.997, 980.5 ],
      [ 2.496, 1136 ], [ 2.7, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2287,
    "totalWeightG": 2400,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 687,
    "burnTimeS": 2.35,
    "caseInfo": "54/2000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K690",
    "dataFiles": 2,
    "designation": "K690-SF",
    "diameter": 54,
    "impulseClass": "K",
    "length": 498,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 910,
    "motorId": "5f4294d200023100000002b0",
    "propInfo": "Spitfire",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.01, 643.8 ], [ 0.25, 688.7 ], [ 0.5, 730.9 ], [ 0.7496, 770.4 ], [ 1, 807.4 ],
      [ 1.25, 796.8 ], [ 1.5, 762.5 ], [ 1.75, 733.5 ], [ 2, 696.6 ], [ 2.25, 659.6 ], [ 2.35, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1616,
    "totalWeightG": 2400,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 350,
    "burnTimeS": 2.67,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J350",
    "dataFiles": 2,
    "designation": "J350-SF",
    "diameter": 54,
    "impulseClass": "J",
    "length": 327,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 448,
    "motorId": "5f4294d200023100000002b1",
    "propInfo": "Spitfire",
    "propWeightG": 590,
    "samples": [
      [ 0, 0 ], [ 0.02, 325.9 ], [ 0.4992, 377.4 ], [ 1.003, 445.5 ], [ 1.502, 437.7 ], [ 2.001, 404.7 ],
      [ 2.202, 383.3 ], [ 2.5, 48.81 ], [ 2.7, 0 ]
],
    "sparky": true,
    "totImpulseNs": 931,
    "totalWeightG": 1260,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 648,
    "burnTimeS": 1.45,
    "caseInfo": "38/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J650",
    "dataFiles": 2,
    "designation": "J650-SF",
    "diameter": 38,
    "impulseClass": "J",
    "length": 625,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 974,
    "motorId": "5f4294d200023100000002b2",
    "propInfo": "Spitfire",
    "propWeightG": 573,
    "samples": [
      [ 0, 0 ], [ 0.01, 744.1 ], [ 0.2, 754.6 ], [ 0.4, 770.4 ], [ 0.6, 833.8 ], [ 0.8, 876 ],
      [ 1, 923.5 ], [ 1.1, 401.1 ], [ 1.2, 295.5 ], [ 1.45, 0 ]
],
    "sparky": true,
    "totImpulseNs": 942,
    "totalWeightG": 1143,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 484,
    "burnTimeS": 2.19,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J485",
    "dataFiles": 1,
    "designation": "J485WC",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 600,
    "motorId": "5f4294d200023100000002b3",
    "propInfo": "White Cloud",
    "propWeightG": 575,
    "samples": [
      [ 0, 0 ], [ 0.05, 263.9 ], [ 0.095, 401.8 ], [ 0.15, 444.8 ], [ 0.5032, 544.3 ], [ 0.8, 573.8 ],
      [ 1.075, 569.4 ], [ 1.5, 542.7 ], [ 2, 444.8 ], [ 2.1, 311.4 ], [ 2.19, 0 ]
],
    "totImpulseNs": 1060,
    "totalWeightG": 1190,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 631,
    "burnTimeS": 2.24,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K630",
    "dataFiles": 1,
    "designation": "K630WC",
    "diameter": 54,
    "impulseClass": "K",
    "length": 402,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 827,
    "motorId": "5f4294d200023100000002b4",
    "propInfo": "White Cloud",
    "propWeightG": 765,
    "samples": [
      [ 0, 0 ], [ 0.042, 359.5 ], [ 0.05, 440.9 ], [ 0.075, 556 ], [ 0.15, 622.8 ], [ 0.217, 667.2 ],
      [ 0.9, 800.7 ], [ 1.159, 796.2 ], [ 1.339, 778.4 ], [ 1.517, 720.6 ], [ 1.9, 556 ],[ 2.1, 133.4 ],
      [ 2.24, 0 ]
],
    "totImpulseNs": 1415,
    "totalWeightG": 1560,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 805.6,
    "burnTimeS": 2.19,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K805",
    "dataFiles": 1,
    "designation": "K805WC",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1103,
    "motorId": "5f4294d200023100000002b5",
    "propInfo": "White Cloud",
    "propWeightG": 950,
    "samples": [
      [ 0, 0 ], [ 0.025, 423.5 ], [ 0.04032, 667.2 ], [ 0.067, 702.8 ], [ 0.095, 747.3 ],[ 0.5516, 1001 ],
      [ 0.8, 1090 ], [ 1.25, 1068 ], [ 1.75, 667.2 ], [ 2.19, 0 ]
],
    "totImpulseNs": 1765,
    "totalWeightG": 1775,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 673.8,
    "burnTimeS": 2.98,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K675",
    "dataFiles": 2,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "2010K675-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 991.6,
    "motorId": "5f4294d200023100000002b6",
    "propInfo": "Skidmark",
    "propWeightG": 1141,
    "samples": [
      [ 0, 0 ], [ 0.01, 10 ], [ 0.02, 154 ], [ 0.025, 930 ], [ 0.04, 850 ], [ 0.05, 800 ], [ 0.07, 680 ],
      [ 0.1, 700 ], [ 0.2, 750 ], [ 0.3, 780 ], [ 0.5, 795 ], [ 1, 808 ], [ 1.5, 795 ], [ 1.9, 751 ],
      [ 2.26, 700 ], [ 2.43, 450 ], [ 2.6, 440 ], [ 2.68, 400 ], [ 2.96, 40 ], [ 3.09, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2010,
    "totalWeightG": 1940,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 814.9,
    "burnTimeS": 2.83,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K815",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2304K815-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1247,
    "motorId": "5f4294d200023100000002b7",
    "propInfo": "Skidmark",
    "propWeightG": 1342,
    "samples": [
      [ 0, 0 ], [ 0.01, 400 ], [ 0.015, 600 ], [ 0.02, 1200 ], [ 0.05, 620 ], [ 0.07, 720 ],
      [ 0.1, 825 ], [ 0.3, 910 ], [ 1, 945 ], [ 1.5, 970 ], [ 1.8, 970 ], [ 2.12, 930 ], [ 2.3, 650 ],
      [ 2.43, 640 ], [ 2.58, 300 ], [ 2.7, 150 ], [ 2.85, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2304,
    "totalWeightG": 2197,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 38.5,
    "burnTimeS": 1.07,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F36",
    "dataFiles": 2,
    "delays": "2,4,6,8,11",
    "designation": "41F36-11A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 72.7,
    "motorId": "5f4294d200023100000002b8",
    "propInfo": "Smoky Sam",
    "propWeightG": 69,
    "samples": [
      [ 0, 0 ], [ 0.01, 12 ], [ 0.02, 46 ], [ 0.03, 75 ], [ 0.04, 79 ], [ 0.06, 77 ], [ 0.07, 62 ],
      [ 0.08, 32 ], [ 0.1, 35 ], [ 0.2, 38 ], [ 0.3, 39 ], [ 0.4, 41 ], [ 0.5, 43 ], [ 0.6, 43 ],
      [ 0.7, 43 ], [ 0.8, 43 ], [ 0.85, 47 ], [ 0.92, 54 ], [ 0.95, 32 ], [ 0.99, 8 ], [ 1.05, 0 ]
],
    "totImpulseNs": 41.2,
    "totalWeightG": 104,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 87.7,
    "burnTimeS": 0.96,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G88",
    "dataFiles": 2,
    "delays": "2,4,6,8,11",
    "designation": "84G88-11A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 123.6,
    "motorId": "5f4294d200023100000002b9",
    "propInfo": "Smoky Sam",
    "propWeightG": 59,
    "samples": [
      [ 0, 0 ], [ 0.03, 7 ], [ 0.04, 60 ], [ 0.05, 90 ], [ 0.06, 113 ], [ 0.07, 116 ], [ 0.08, 113 ],
      [ 0.1, 86 ], [ 0.12, 81 ], [ 0.15, 86 ], [ 0.3, 92 ], [ 0.4, 95 ], [ 0.5, 97 ], [ 0.75, 100 ],
      [ 0.82, 108 ], [ 0.84, 106 ], [ 0.9, 56 ], [ 0.93, 25 ], [ 1, 0 ]
],
    "totImpulseNs": 84.3,
    "totalWeightG": 152,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 130.8,
    "burnTimeS": 0.96,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G131",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "125G131-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 182.6,
    "motorId": "5f4294d200023100000002ba",
    "propInfo": "Smoky Sam",
    "propWeightG": 88.6,
    "samples": [
      [ 0, 0 ], [ 0.01, 6 ], [ 0.02, 40 ], [ 0.03, 116 ], [ 0.04, 131 ], [ 0.05, 146 ], [ 0.06, 147 ],
      [ 0.07, 140 ], [ 0.08, 136 ], [ 0.09, 130 ], [ 0.1, 126 ], [ 0.2, 129 ], [ 0.3, 132 ],
      [ 0.4, 137 ], [ 0.5, 139 ], [ 0.6, 141 ], [ 0.7, 142 ], [ 0.8, 145 ], [ 0.85, 150 ], [ 0.87, 155 ],
      [ 0.9, 116 ], [ 0.93, 60 ], [ 0.95, 30 ], [ 1, 3 ], [ 1.1, 0 ]
],
    "totImpulseNs": 125.2,
    "totalWeightG": 200,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 175.1,
    "burnTimeS": 0.95,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H175",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "166H175-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 221.6,
    "motorId": "5f4294d200023100000002bb",
    "propInfo": "Smoky Sam",
    "propWeightG": 118.1,
    "samples": [
      [ 0, 0 ], [ 0.021, 13 ], [ 0.032, 175 ], [ 0.057, 215 ], [ 0.08, 180 ], [ 0.13, 175 ],
      [ 0.4, 185 ], [ 0.6, 190 ], [ 0.76, 195 ], [ 0.84, 236 ], [ 0.88, 150 ], [ 0.92, 30 ],
      [ 1, 0 ]
],
    "totImpulseNs": 166,
    "totalWeightG": 247,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 237.2,
    "burnTimeS": 0.88,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H237",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "206H237-13A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 295.9,
    "motorId": "5f4294d200023100000002bc",
    "propInfo": "Smoky Sam",
    "propWeightG": 147.6,
    "samples": [
      [ 0, 0 ], [ 0.026, 10 ], [ 0.028, 154 ], [ 0.033, 238 ], [ 0.055, 270 ], [ 0.07, 234 ],
      [ 0.71, 259 ], [ 0.78, 296 ], [ 0.8, 280 ], [ 0.87, 50 ], [ 0.9, 10 ], [ 0.937, 0 ]
],
    "totImpulseNs": 206.2,
    "totalWeightG": 294,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 296,
    "burnTimeS": 0.85,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H295",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "253H295-13A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 359.5,
    "motorId": "5f4294d200023100000002bd",
    "propInfo": "Smoky Sam",
    "propWeightG": 177.1,
    "samples": [
      [ 0, 0 ], [ 0.03, 15 ], [ 0.031, 320 ], [ 0.05, 355 ], [ 0.075, 325 ], [ 0.13, 312 ],[ 0.35, 315 ],
      [ 0.66, 327 ], [ 0.72, 368 ], [ 0.75, 370 ], [ 0.78, 290 ], [ 0.8, 150 ], [ 0.85, 25 ],
      [ 0.925, 0 ]
],
    "totImpulseNs": 252.7,
    "totalWeightG": 342,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1356,
    "burnTimeS": 2.97,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1355",
    "dataFiles": 2,
    "designation": "4025L1355-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1792,
    "motorId": "5f4294d200023100000002be",
    "propInfo": "Smoky Sam",
    "propWeightG": 3012,
    "samples": [
      [ 0, 0 ], [ 0.1, 80 ], [ 0.12, 1300 ], [ 0.15, 1600 ], [ 0.2, 1500 ], [ 0.3, 1540 ], [ 0.4, 1560 ],
      [ 1.05, 1660 ], [ 1.3, 1750 ], [ 1.4, 1750 ], [ 1.8, 1590 ], [ 2.2, 1270 ], [ 2.6, 860 ],
      [ 2.82, 680 ], [ 3.05, 83 ], [ 3.25, 0 ]
],
    "totImpulseNs": 4026,
    "totalWeightG": 4962,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1686,
    "burnTimeS": 3.01,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1685",
    "dataFiles": 2,
    "designation": "5069L1685-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2570,
    "motorId": "5f4294d200023100000002bf",
    "propInfo": "Smoky Sam",
    "propWeightG": 3773,
    "samples": [
      [ 0, 0 ], [ 0.055, 100 ], [ 0.07, 1000 ], [ 0.092, 2300 ], [ 0.12, 2150 ], [ 0.4, 2150 ],
      [ 0.9, 2050 ], [ 1.1, 2060 ], [ 1.3, 2150 ], [ 1.6, 1900 ], [ 2.1, 1600 ], [ 2.5, 1150 ],
      [ 2.7, 1000 ], [ 2.85, 750 ], [ 3.02, 200 ], [ 3.18, 0 ]
],
    "totImpulseNs": 5069,
    "totalWeightG": 6051,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 442.7,
    "burnTimeS": 1.07,
    "caseInfo": "Pro54-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I445",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "475I445-16A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 143,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 526.2,
    "motorId": "5f4294d200023100000002c0",
    "propInfo": "Vmax",
    "propWeightG": 212.7,
    "samples": [
      [ 0, 0 ], [ 0.02, 315 ], [ 0.04, 380 ], [ 0.054, 365 ], [ 0.15, 440 ], [ 0.25, 493 ],[ 0.4, 528 ],
      [ 0.55, 529 ], [ 0.7, 502 ], [ 0.8, 485 ], [ 0.9, 460 ], [ 0.95, 430 ], [ 0.99, 300 ],
      [ 1.02, 100 ], [ 1.05, 44 ], [ 1.1, 0 ]
],
    "totImpulseNs": 474.9,
    "totalWeightG": 575,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 58.9,
    "burnTimeS": 0.96,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F59",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "57F59-12A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 70.9,
    "motorId": "5f4294d200023100000002c1",
    "propInfo": "White Thunder",
    "propWeightG": 26.1,
    "samples": [
      [ 0, 0 ], [ 0.01, 16 ], [ 0.02, 62 ], [ 0.03, 67 ], [ 0.04, 71 ], [ 0.07, 58 ], [ 0.1, 63 ],
      [ 0.2, 67 ], [ 0.3, 69 ], [ 0.4, 67 ], [ 0.5, 65 ], [ 0.6, 63 ], [ 0.7, 61 ], [ 0.87, 60 ],
      [ 0.9, 23 ], [ 0.97, 0 ]
],
    "totImpulseNs": 57,
    "totalWeightG": 99,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 125.8,
    "burnTimeS": 0.93,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G126",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "116G126-13A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 167.4,
    "motorId": "5f4294d200023100000002c2",
    "propInfo": "White Thunder",
    "propWeightG": 52.1,
    "samples": [
      [ 0, 0 ], [ 0.01, 55 ], [ 0.02, 168 ], [ 0.03, 157 ], [ 0.04, 148 ], [ 0.05, 125 ], [ 0.1, 135 ],
      [ 0.2, 141 ], [ 0.3, 142 ], [ 0.4, 141 ], [ 0.6, 133 ], [ 0.75, 127 ], [ 0.81, 128 ],[ 0.86, 60 ],
      [ 0.9, 15 ], [ 0.95, 0 ]
],
    "totImpulseNs": 116,
    "totalWeightG": 145,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 162.7,
    "burnTimeS": 1.02,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H163",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "166H163-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 216.9,
    "motorId": "5f4294d200023100000002c3",
    "propInfo": "White Thunder",
    "propWeightG": 78.2,
    "samples": [
      [ 0, 0 ], [ 0.018, 10 ], [ 0.026, 225 ], [ 0.055, 165 ], [ 0.1, 170 ], [ 0.2, 175 ], [ 0.35, 180 ],
      [ 0.9, 175 ], [ 0.95, 100 ], [ 0.98, 45 ], [ 1.062, 0 ]
],
    "totImpulseNs": 166.3,
    "totalWeightG": 187,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 254.3,
    "burnTimeS": 0.9,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H255",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "229H255-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 363,
    "motorId": "5f4294d200023100000002c4",
    "propInfo": "White Thunder",
    "propWeightG": 104.3,
    "samples": [
      [ 0, 0 ], [ 0.018, 10 ], [ 0.023, 345 ], [ 0.039, 325 ], [ 0.05, 276 ], [ 0.13, 287 ],
      [ 0.35, 292 ], [ 0.75, 259 ], [ 0.78, 265 ], [ 0.87, 40 ], [ 0.936, 0 ]
],
    "totImpulseNs": 229.3,
    "totalWeightG": 232,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 399.3,
    "burnTimeS": 0.71,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H399",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "282H399-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 545.8,
    "motorId": "5f4294d200023100000002c5",
    "propInfo": "White Thunder",
    "propWeightG": 132.6,
    "samples": [
      [ 0, 0 ], [ 0.015, 10 ], [ 0.02, 320 ], [ 0.024, 555 ], [ 0.05, 490 ], [ 0.075, 475 ],
      [ 0.1, 468 ], [ 0.2, 463 ], [ 0.53, 451 ], [ 0.55, 460 ], [ 0.575, 400 ], [ 0.64, 200 ],
      [ 0.685, 25 ], [ 0.71, 0 ]
],
    "totImpulseNs": 282.2,
    "totalWeightG": 294,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1252,
    "burnTimeS": 1.56,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1250",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1951K1250-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1661,
    "motorId": "5f4294d200023100000002c6",
    "propInfo": "Wild Wolf",
    "propWeightG": 915,
    "samples": [
      [ 0, 0 ], [ 0.01, 600 ], [ 0.02, 1400 ], [ 0.03, 1610 ], [ 0.05, 1360 ], [ 0.07, 1410 ],
      [ 0.1, 1440 ], [ 0.15, 1470 ], [ 0.2, 1480 ], [ 0.4, 1480 ], [ 0.8, 1395 ], [ 1.28, 1185 ],
      [ 1.36, 985 ], [ 1.4, 680 ], [ 1.45, 570 ], [ 1.5, 210 ], [ 1.6, 60 ], [ 1.7, 0 ]
],
    "totImpulseNs": 1951,
    "totalWeightG": 1815,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 86.7,
    "burnTimeS": 1.94,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H87",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "168H87-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 139.2,
    "motorId": "5f4294d200023100000002c7",
    "propInfo": "Imax",
    "propWeightG": 92.7,
    "samples": [
      [ 0, 0 ], [ 0.018, 5 ], [ 0.027, 73 ], [ 0.032, 139.2 ], [ 0.048, 117 ], [ 0.056, 87 ],
      [ 0.081, 95 ], [ 0.147, 101 ], [ 0.336, 103 ], [ 0.52, 101 ], [ 1.6, 83 ], [ 1.686, 81 ],
      [ 1.72, 79 ], [ 1.778, 60 ], [ 1.82, 38 ], [ 1.94, 0 ]
],
    "totImpulseNs": 167.9,
    "totalWeightG": 205,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 225.6,
    "burnTimeS": 1.35,
    "caseInfo": "Pro29-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H226",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "305H226-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 365,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 362.3,
    "motorId": "5f4294d200023100000002c8",
    "propInfo": "Skidmark",
    "propWeightG": 175,
    "samples": [
      [ 0, 0 ], [ 0.007, 9 ], [ 0.012, 178 ], [ 0.017, 320 ], [ 0.023, 362 ], [ 0.047, 319 ],
      [ 0.08, 302 ], [ 0.14, 291 ], [ 0.615, 276 ], [ 0.95, 257.5 ], [ 1, 215 ], [ 1.077, 105 ],
      [ 1.18, 81 ], [ 1.29, 31 ], [ 1.34, 16 ], [ 1.5, 0 ]
],
    "sparky": true,
    "totImpulseNs": 305,
    "totalWeightG": 360,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 340.6,
    "burnTimeS": 0.84,
    "caseInfo": "Pro29-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H340",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "287H340-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 365,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 447.5,
    "motorId": "5f4294d200023100000002c9",
    "propInfo": "Smoky Sam",
    "propWeightG": 206.7,
    "samples": [
      [ 0, 0 ], [ 0.017, 6 ], [ 0.024, 255 ], [ 0.0311, 395 ], [ 0.035, 423 ], [ 0.043, 447.5 ],
      [ 0.06, 398 ], [ 0.064, 389 ], [ 0.075, 397.5 ], [ 0.25, 388 ], [ 0.4, 382 ], [ 0.542, 376 ],
      [ 0.62, 379 ], [ 0.66, 400 ], [ 0.68, 407 ], [ 0.705, 398 ], [ 0.74, 248 ], [ 0.8, 59.5 ],
      [ 0.85, 8 ], [ 0.9, 0 ]
],
    "totImpulseNs": 287.3,
    "totalWeightG": 391,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 52.5,
    "burnTimeS": 2.87,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G50",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "150G50-15A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 78.8,
    "motorId": "5f4294d200023100000002ca",
    "propInfo": "Imax",
    "propWeightG": 77.7,
    "samples": [
      [ 0, 0 ], [ 0.02, 10 ], [ 0.04, 58 ], [ 0.052, 72 ], [ 0.07, 65 ], [ 0.106, 43 ], [ 0.23, 51 ],
      [ 0.4, 55 ], [ 0.85, 57 ], [ 1.5, 55 ], [ 2, 51 ], [ 2.5, 44.5 ], [ 2.78, 44 ], [ 2.86, 37 ],
      [ 2.93, 20 ], [ 3.02, 0 ]
],
    "totImpulseNs": 150,
    "totalWeightG": 218,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 101.7,
    "burnTimeS": 2.82,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H100",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "286H100-15A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 117.9,
    "motorId": "5f4294d200023100000002cb",
    "propInfo": "Imax",
    "propWeightG": 154.4,
    "samples": [
      [ 0, 0 ], [ 0.016, 68 ], [ 0.056, 116 ], [ 0.09, 118 ], [ 0.14, 115 ], [ 0.5, 116 ], [ 0.6, 114 ],
      [ 1.25, 113 ], [ 2.32, 99 ], [ 2.44, 100 ], [ 2.55, 89 ], [ 2.77, 0 ]
],
    "totImpulseNs": 286.4,
    "totalWeightG": 327,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 216.1,
    "burnTimeS": 2.94,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I216",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "636I216-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 379.5,
    "motorId": "5f4294d200023100000002cc",
    "propInfo": "Classic",
    "propWeightG": 312.5,
    "samples": [
      [ 0, 0 ], [ 0.017, 35 ], [ 0.03, 300 ], [ 0.035, 345 ], [ 0.05, 325 ], [ 0.07, 275 ],[ 0.14, 292 ],
      [ 0.26, 296 ], [ 0.8, 280 ], [ 1.1, 280.4 ], [ 1.62, 255 ], [ 1.8, 226 ], [ 2.105, 210 ],
      [ 2.2, 195 ], [ 2.45, 80 ], [ 2.75, 36 ], [ 3.1, 0 ]
],
    "totImpulseNs": 636.1,
    "totalWeightG": 601,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 638.4,
    "burnTimeS": 4.28,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L640",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2772L640-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1590,
    "motorId": "5f4294d200023100000002cd",
    "propInfo": "Classic/Dual Thrust",
    "propWeightG": 1293,
    "samples": [
      [ 0, 0 ], [ 0.02, 1200 ], [ 0.034, 1540 ], [ 0.07, 1300 ], [ 0.15, 1460 ], [ 0.35, 1510 ],
      [ 0.65, 1540 ], [ 0.7, 1510 ], [ 0.79, 800 ], [ 0.86, 570.3 ], [ 3.26, 518 ], [ 3.55, 330 ],
      [ 3.65, 318 ], [ 4.15, 102 ], [ 4.5, 30 ], [ 5, 0 ]
],
    "totImpulseNs": 2772,
    "totalWeightG": 2244,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1409,
    "burnTimeS": 3.43,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1410",
    "dataFiles": 2,
    "delays": "P",
    "designation": "4828L1410-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1670,
    "motorId": "5f4294d200023100000002ce",
    "propInfo": "Skidmark",
    "propWeightG": 2875,
    "samples": [
      [ 0, 0 ], [ 0.04, 133 ], [ 0.065, 1200 ], [ 0.077, 1510 ], [ 0.13, 1250 ], [ 0.35, 1400 ],
      [ 1, 1530 ], [ 1.5, 1595 ], [ 2, 1630 ], [ 2.3, 1600 ], [ 2.6, 1510 ], [ 2.9, 1350 ],[ 3.25, 1032 ],
      [ 3.4, 120 ], [ 3.48, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4828,
    "totalWeightG": 5115,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 49.4,
    "burnTimeS": 7.68,
    "caseInfo": "RMS-38/360",
    "certOrg": "National Association of Rocketry",
    "commonName": "I49",
    "dataFiles": 2,
    "delays": "P",
    "designation": "I49N",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/I49N.pdf",
    "length": 184,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 63.7,
    "motorId": "5f4294d200023100000002cf",
    "propInfo": "Warp 9",
    "propWeightG": 205,
    "samples": [
      [ 0, 0 ], [ 0.008, 1.406 ], [ 0.025, 48.59 ], [ 0.093, 52.03 ], [ 0.429, 57.34 ], [ 0.665, 60.31 ],
      [ 0.841, 60.63 ], [ 1.06, 62.34 ], [ 1.295, 63.44 ], [ 1.556, 62.34 ], [ 1.825, 62.34 ],
      [ 1.994, 60.63 ], [ 2.212, 60.16 ], [ 3.003, 56.72 ], [ 3.785, 52.34 ], [ 4.349, 49.38 ],
      [ 4.997, 46.56 ], [ 5.998, 43.44 ], [ 6.393, 42.03 ], [ 6.831, 41.56 ], [ 6.999, 36.88 ],
      [ 7.083, 33.13 ], [ 7.184, 31.41 ], [ 7.369, 23.13 ], [ 7.495, 16.41 ], [ 7.764, 4.219 ],
      [ 7.882, 1.875 ], [ 7.941, 0 ]
],
    "totImpulseNs": 383,
    "totalWeightG": 398,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 60.8,
    "burnTimeS": 7.99,
    "caseInfo": "RMS-38/480",
    "certOrg": "National Association of Rocketry",
    "commonName": "I59",
    "dataFiles": 2,
    "delays": "P",
    "designation": "I59WN",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/I59WN.pdf",
    "length": 232,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 172.9,
    "motorId": "5f4294d200023100000002d0",
    "propInfo": "White Lightning/Warp 9",
    "propWeightG": 272,
    "samples": [
      [ 0, 0 ], [ 0.009, 0.357 ], [ 0.046, 138.6 ], [ 0.12, 150 ], [ 0.193, 147.9 ], [ 0.368, 157.9 ],
      [ 0.506, 167.9 ], [ 0.699, 171.1 ], [ 0.791, 171.8 ], [ 0.893, 168.6 ], [ 1.058, 158.2 ],
      [ 1.233, 146.4 ], [ 1.417, 132.1 ], [ 1.509, 125.7 ], [ 1.61, 100.4 ], [ 1.675, 80.36 ],
      [ 1.785, 60.36 ], [ 1.96, 53.57 ], [ 2.209, 48.57 ], [ 2.531, 45.36 ], [ 2.669, 43.21 ],
      [ 3.009, 40.71 ], [ 3.387, 40.71 ], [ 4.021, 38.93 ], [ 4.261, 37.5 ], [ 4.997, 36.43 ],
      [ 6, 33.93 ], [ 6.883, 31.43 ], [ 7.003, 33.57 ], [ 7.187, 30.71 ], [ 7.334, 27.5 ],
      [ 7.574, 22.5 ], [ 7.96, 17.5 ], [ 8.117, 9.643 ], [ 8.319, 4.643 ], [ 8.604, 0 ]
],
    "totImpulseNs": 486,
    "totalWeightG": 487,
    "type": "reload",
    "updatedOn": "2022-07-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 125.3,
    "burnTimeS": 1.27,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G125",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "159G125-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 171.8,
    "motorId": "5f4294d200023100000002d1",
    "propInfo": "Red Lightning",
    "propWeightG": 81.9,
    "samples": [
      [ 0, 0 ], [ 0.004, 15.68 ], [ 0.022, 170.8 ], [ 0.039, 116.9 ], [ 0.122, 142.6 ], [ 0.236, 149.7 ],
      [ 0.589, 142.6 ], [ 0.801, 131.3 ], [ 1.068, 122.1 ], [ 1.118, 107.9 ], [ 1.145, 78.42 ],
      [ 1.174, 43.13 ], [ 1.211, 21.47 ], [ 1.247, 8.775 ], [ 1.299, 0 ]
],
    "totImpulseNs": 159.6,
    "totalWeightG": 194.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 233.5,
    "burnTimeS": 1.33,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H233",
    "dataFiles": 2,
    "delays": "7,9,11,14",
    "designation": "311H233-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 354.7,
    "motorId": "5f4294d200023100000002d2",
    "propInfo": "Red Lightning",
    "propWeightG": 163.7,
    "samples": [
      [ 0, 0 ], [ 0.004, 98.64 ], [ 0.01, 263.8 ], [ 0.018, 348 ], [ 0.051, 263.8 ], [ 0.156, 299.2 ],
      [ 0.82, 262.4 ], [ 0.988, 228.5 ], [ 1.05, 214.5 ], [ 1.111, 142.4 ], [ 1.17, 84.22 ],
      [ 1.242, 49.32 ], [ 1.331, 17.68 ], [ 1.399, 0 ]
],
    "totImpulseNs": 311.5,
    "totalWeightG": 327.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1777,
    "burnTimeS": 3.34,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1770",
    "dataFiles": 2,
    "delays": "P",
    "designation": "5933M1770-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2195,
    "motorId": "5f4294d200023100000002d3",
    "propInfo": "Skidmark",
    "propWeightG": 3520,
    "samples": [
      [ 0, 0 ], [ 0.035, 182 ], [ 0.05, 1005 ], [ 0.06, 1505 ], [ 0.07, 1865 ], [ 0.108, 1765 ],
      [ 0.215, 1865 ], [ 0.3, 1865 ], [ 0.4, 1845 ], [ 0.6, 1870 ], [ 1, 1945 ], [ 1.3, 1982 ],
      [ 2, 1970 ], [ 2.7, 1800 ], [ 2.9, 1870 ], [ 2.97, 1865 ], [ 3.1, 1350 ], [ 3.2, 700 ],
      [ 3.26, 300 ], [ 3.32, 100 ], [ 3.36, 60 ], [ 3.48, 0 ]
],
    "sparky": true,
    "totImpulseNs": 5933,
    "totalWeightG": 5993,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1276,
    "burnTimeS": 2.077,
    "caseInfo": "AMW 54-2550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1276",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2730L1276-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1496,
    "motorId": "5f4294d200023100000002d4",
    "propInfo": "Red Rhino",
    "propWeightG": 1475,
    "samples": [
      [ 0, 0 ], [ 0.015, 76.92 ], [ 0.017, 692.3 ], [ 0.026, 1495 ], [ 0.037, 1244 ], [ 0.052, 1401 ],
      [ 0.084, 1308 ], [ 0.127, 1308 ], [ 0.181, 1368 ], [ 0.289, 1401 ], [ 0.384, 1408 ],
      [ 0.807, 1421 ], [ 0.993, 1462 ], [ 1.215, 1492 ], [ 1.673, 1475 ], [ 1.727, 1385 ],
      [ 1.798, 1084 ], [ 1.947, 531.8 ], [ 1.986, 351.2 ], [ 2.047, 177.3 ], [ 2.092, 93.65 ],
      [ 2.144, 33.45 ], [ 2.185, 0 ]
],
    "totImpulseNs": 2729,
    "totalWeightG": 2960,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 779.9,
    "burnTimeS": 2.696,
    "caseInfo": "Pro54-5G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K780",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,12,13,14,15",
    "designation": "2108K780-15A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 489,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1016,
    "motorId": "5f4294d200023100000002d5",
    "propInfo": "Blue Streak",
    "propWeightG": 1140,
    "samples": [
      [ 0, 0 ], [ 0.008, 15.97 ], [ 0.016, 511.1 ], [ 0.017, 1014 ], [ 0.039, 892.4 ], [ 0.065, 936.4 ],
      [ 0.094, 942.4 ], [ 0.153, 916.4 ], [ 0.205, 906.4 ], [ 0.294, 914.4 ], [ 0.455, 900.4 ],
      [ 0.718, 896.4 ], [ 1.016, 886.5 ], [ 1.38, 858.5 ], [ 1.799, 818.6 ], [ 1.913, 804.6 ],
      [ 2.072, 798.6 ], [ 2.225, 788.6 ], [ 2.272, 758.7 ], [ 2.34, 606.9 ], [ 2.379, 561 ],
      [ 2.412, 495.1 ], [ 2.558, 211.6 ], [ 2.628, 141.8 ], [ 2.711, 77.86 ], [ 2.756, 0 ]
],
    "totImpulseNs": 2108,
    "totalWeightG": 1700,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 629.1,
    "burnTimeS": 2.677,
    "caseInfo": "Pro54-4G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K630",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,12,13,14,15",
    "designation": "1679K630-15A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 405,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 787.3,
    "motorId": "5f4294d200023100000002d6",
    "propInfo": "Blue Streak",
    "propWeightG": 912,
    "samples": [
      [ 0, 0 ], [ 0.014, 164.4 ], [ 0.025, 676.3 ], [ 0.034, 791.4 ], [ 0.059, 781.1 ], [ 0.074, 674.2 ],
      [ 0.102, 705.1 ], [ 0.212, 713.3 ], [ 0.696, 715.3 ], [ 0.974, 721.5 ], [ 1.143, 709.2 ],
      [ 1.489, 680.4 ], [ 2.055, 629 ], [ 2.284, 612.6 ], [ 2.318, 587.9 ], [ 2.355, 518 ],
      [ 2.389, 427.6 ], [ 2.417, 382.3 ], [ 2.471, 349.4 ], [ 2.51, 265.2 ], [ 2.57, 127.4 ],
      [ 2.598, 76.06 ], [ 2.646, 49.33 ], [ 2.816, 0 ]
],
    "totImpulseNs": 1679,
    "totalWeightG": 1410,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 448.5,
    "burnTimeS": 2.754,
    "caseInfo": "Pro54-3G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J449",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,12,13,14,15",
    "designation": "1261J449-15A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 321,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 589.5,
    "motorId": "5f4294d200023100000002d7",
    "propInfo": "Blue Streak",
    "propWeightG": 624,
    "samples": [
      [ 0, 0 ], [ 0.012, 16.22 ], [ 0.021, 330.3 ], [ 0.027, 504.3 ], [ 0.046, 586.9 ], [ 0.055, 508.7 ],
      [ 0.07, 440.9 ], [ 0.137, 477.8 ], [ 0.241, 495.4 ], [ 0.348, 505.8 ], [ 0.69, 514.6 ],
      [ 1.153, 504.3 ], [ 1.938, 468.9 ], [ 2.292, 452.7 ], [ 2.417, 455.6 ], [ 2.475, 446.8 ],
      [ 2.505, 440.9 ], [ 2.52, 408.4 ], [ 2.566, 293.4 ], [ 2.612, 157.8 ], [ 2.688, 69.3 ],
      [ 2.771, 28.02 ], [ 2.847, 0 ]
],
    "totImpulseNs": 1261,
    "totalWeightG": 1122,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 292.7,
    "burnTimeS": 2.848,
    "caseInfo": "Pro54-2G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J293",
    "dataFiles": 2,
    "delays": "3,4,5,6,7,8,9,10,11,12,13",
    "designation": "838J293-13A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 237,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 386.1,
    "motorId": "5f4294d200023100000002d8",
    "propInfo": "Blue Streak",
    "propWeightG": 416,
    "samples": [
      [ 0, 0 ], [ 0.019, 22.85 ], [ 0.032, 250.3 ], [ 0.044, 386.3 ], [ 0.06, 302.2 ], [ 0.073, 256.5 ],
      [ 0.13, 285.6 ], [ 0.266, 309.5 ], [ 0.487, 318.8 ], [ 0.734, 325 ], [ 1.432, 324 ],
      [ 1.786, 309.5 ], [ 2.074, 294.9 ], [ 2.33, 280.4 ], [ 2.488, 273.1 ], [ 2.58, 272.1 ],
      [ 2.634, 258.6 ], [ 2.713, 163 ], [ 2.795, 70.62 ], [ 2.852, 20.77 ], [ 2.896, 0 ]
],
    "totImpulseNs": 837.9,
    "totalWeightG": 840,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 149.5,
    "burnTimeS": 3.175,
    "caseInfo": "Pro54-1G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I150",
    "dataFiles": 2,
    "delays": "1,2,3,4,5,6,7,8,9,10,11",
    "designation": "465I150-11A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 153,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 179.2,
    "motorId": "5f4294d200023100000002d9",
    "propInfo": "Blue Streak",
    "propWeightG": 226,
    "samples": [
      [ 0, 0 ], [ 0.017, 11.04 ], [ 0.039, 103.4 ], [ 0.045, 164.8 ], [ 0.065, 179.9 ], [ 0.09, 153.4 ],
      [ 0.166, 162.6 ], [ 0.194, 161.9 ], [ 0.303, 169.3 ], [ 0.483, 171.8 ], [ 0.666, 173.3 ],
      [ 0.834, 171.5 ], [ 1.275, 168.5 ], [ 1.725, 156 ], [ 2.559, 128.1 ], [ 2.77, 122.5 ],
      [ 2.899, 118.9 ], [ 2.978, 119.2 ], [ 3.003, 99.35 ], [ 3.034, 82.79 ], [ 3.087, 66.23 ],
      [ 3.16, 19.5 ], [ 3.208, 0 ]
],
    "totImpulseNs": 465.1,
    "totalWeightG": 570,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 218.3,
    "burnTimeS": 2.251,
    "caseInfo": "Pro54-1G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I218",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,10,11,12,13,14",
    "designation": "491I218-14A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 153,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 294.2,
    "motorId": "5f4294d200023100000002da",
    "propInfo": "White Thunder",
    "propWeightG": 230,
    "samples": [
      [ 0, 0 ], [ 0.01, 12.22 ], [ 0.022, 129.8 ], [ 0.047, 215.9 ], [ 0.072, 250.8 ], [ 0.079, 208.4 ],
      [ 0.11, 234 ], [ 0.188, 249.1 ], [ 0.436, 263.7 ], [ 0.783, 259 ], [ 1.195, 235.7 ],
      [ 1.614, 201.4 ], [ 1.834, 191.5 ], [ 1.964, 183.9 ], [ 2.074, 178.1 ], [ 2.143, 179.8 ],
      [ 2.172, 87.88 ], [ 2.188, 50.05 ], [ 2.217, 18.62 ], [ 2.252, 0 ]
],
    "totImpulseNs": 491.2,
    "totalWeightG": 580,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 250,
    "burnTimeS": 2.732,
    "caseInfo": "Pro54-2G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J250",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15",
    "designation": "683J250-15A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 237,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 337.6,
    "motorId": "5f4294d200023100000002db",
    "propInfo": "Skidmark",
    "propWeightG": 406,
    "samples": [
      [ 0, 0 ], [ 0.031, 191.5 ], [ 0.051, 271.1 ], [ 0.082, 161.1 ], [ 0.148, 206.7 ], [ 0.3, 234.4 ],
      [ 0.413, 244.3 ], [ 0.635, 264.8 ], [ 0.849, 270.2 ], [ 1.06, 276.5 ], [ 1.449, 283.6 ],
      [ 1.644, 277.4 ], [ 1.8, 279.2 ], [ 1.932, 272.9 ], [ 2.135, 261.3 ], [ 2.291, 249.6 ],
      [ 2.447, 247 ], [ 2.525, 251.4 ], [ 2.579, 227.3 ], [ 2.614, 157.5 ], [ 2.665, 51.9 ],
      [ 2.727, 14.32 ], [ 2.825, 6.263 ], [ 2.965, 0 ]
],
    "sparky": true,
    "totImpulseNs": 682.8,
    "totalWeightG": 830,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 577.4,
    "burnTimeS": 1.553,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J580",
    "dataFiles": 2,
    "delays": "8,10,12,14,17",
    "designation": "896J580-17A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 510,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 691.5,
    "motorId": "5f4294d200023100000002dc",
    "propInfo": "Smoky Sam",
    "propWeightG": 588,
    "samples": [
      [ 0, 0 ], [ 0.006, 82.31 ], [ 0.013, 461 ], [ 0.016, 692.9 ], [ 0.031, 600.1 ], [ 0.041, 634.6 ],
      [ 0.058, 624.1 ], [ 0.243, 621.1 ], [ 0.405, 621.1 ], [ 0.511, 631.6 ], [ 0.632, 646.5 ],
      [ 0.898, 672 ], [ 1.019, 686.9 ], [ 1.098, 691.4 ], [ 1.163, 675 ], [ 1.229, 514.8 ],
      [ 1.325, 496.9 ], [ 1.36, 446 ], [ 1.405, 332.2 ], [ 1.461, 221.5 ], [ 1.518, 71.84 ],
      [ 1.559, 28.43 ], [ 1.59, 8.98 ], [ 1.613, 0 ]
],
    "totImpulseNs": 896.4,
    "totalWeightG": 1044,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 603.5,
    "burnTimeS": 1.655,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J600",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "999J600-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 510,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 721,
    "motorId": "5f4294d200023100000002dd",
    "propInfo": "Red Lightning",
    "propWeightG": 551,
    "samples": [
      [ 0, 0 ], [ 0.011, 533.3 ], [ 0.023, 683.3 ], [ 0.029, 721.9 ], [ 0.051, 721.9 ], [ 0.091, 701.2 ],
      [ 0.202, 701.2 ], [ 0.376, 696.7 ], [ 0.572, 698.2 ], [ 0.72, 696.7 ], [ 0.847, 698.2 ],
      [ 0.963, 711.5 ], [ 1.028, 720.5 ], [ 1.109, 710.1 ], [ 1.19, 698.2 ], [ 1.245, 681.8 ],
      [ 1.304, 610.5 ], [ 1.385, 420.4 ], [ 1.408, 371.4 ], [ 1.515, 147.1 ], [ 1.61, 62.39 ],
      [ 1.716, 0 ]
],
    "totImpulseNs": 998.6,
    "totalWeightG": 906,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 150.8,
    "burnTimeS": 1.38,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H151",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "207H151-15A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 214.8,
    "motorId": "5f4294d200023100000002de",
    "propInfo": "Red Lightning",
    "propWeightG": 109.1,
    "samples": [
      [ 0, 0 ], [ 0.008, 125.3 ], [ 0.021, 209.6 ], [ 0.052, 160.9 ], [ 0.118, 186.8 ], [ 0.293, 190.7 ],
      [ 0.465, 183.5 ], [ 0.683, 171.6 ], [ 0.988, 145.6 ], [ 1.133, 134.1 ], [ 1.221, 74.29 ],
      [ 1.293, 29.81 ], [ 1.372, 10.01 ], [ 1.398, 6.521 ], [ 1.4, 0 ]
],
    "totImpulseNs": 207.2,
    "totalWeightG": 238,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 194.3,
    "burnTimeS": 1.34,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H194",
    "dataFiles": 2,
    "delays": "7,9,11,14",
    "designation": "260H194-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 292,
    "motorId": "5f4294d200023100000002df",
    "propInfo": "Red Lightning",
    "propWeightG": 136.4,
    "samples": [
      [ 0, 0 ], [ 0.006, 179.8 ], [ 0.011, 244.1 ], [ 0.021, 286 ], [ 0.049, 204.9 ], [ 0.117, 233.8 ],
      [ 0.181, 241.3 ], [ 0.386, 236.2 ], [ 0.98, 193.8 ], [ 1.075, 170.5 ], [ 1.136, 144.4 ],
      [ 1.19, 80.58 ], [ 1.285, 22.82 ], [ 1.347, 0 ]
],
    "totImpulseNs": 260.3,
    "totalWeightG": 284,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1304,
    "burnTimeS": 4.94,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6438M1300-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2827,
    "motorId": "5f4294d200023100000002e0",
    "propInfo": "Imax/Dual Thrust",
    "propWeightG": 3437,
    "samples": [
      [ 0, 0 ], [ 0.009, 394.1 ], [ 0.057, 934.8 ], [ 0.086, 2146 ], [ 0.154, 2615 ], [ 0.314, 2827 ],
      [ 0.671, 2759 ], [ 0.97, 2752 ], [ 1.082, 1173 ], [ 1.187, 1120 ], [ 2.14, 1173 ], [ 2.7, 1140 ],
      [ 3.884, 915.2 ], [ 4.372, 771.9 ], [ 4.6, 400.6 ], [ 4.697, 335.5 ], [ 4.9, 120.5 ],
      [ 4.901, 0 ]
],
    "totImpulseNs": 6738,
    "totalWeightG": 5657,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 4127,
    "burnTimeS": 4.31,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N4100",
    "dataFiles": 2,
    "delays": "P",
    "designation": "17790N4100-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 4797,
    "motorId": "5f4294d200023100000002e1",
    "propInfo": "Red Lightning",
    "propWeightG": 9101,
    "samples": [
      [ 0, 0 ], [ 0.003, 203.9 ], [ 0.05, 2363 ], [ 0.078, 3947 ], [ 0.121, 4281 ], [ 0.652, 4370 ],
      [ 1.123, 4454 ], [ 1.655, 4773 ], [ 2.353, 4621 ], [ 3.035, 4511 ], [ 3.7, 4376 ], [ 3.733, 4182 ],
      [ 3.887, 2969 ], [ 4.036, 1589 ], [ 4.197, 533.2 ], [ 4.262, 240.5 ], [ 4.3, 0 ]
],
    "totImpulseNs": 17790,
    "totalWeightG": 14750,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1633,
    "burnTimeS": 5.03,
    "caseInfo": "ABC 75-7600",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1630",
    "dataFiles": 2,
    "delays": "P",
    "designation": "8212M1630-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 6805,
    "motorId": "5f4294d200023100000002e2",
    "propInfo": "Tamed Tiger",
    "propWeightG": 3948,
    "samples": [
      [ 0, 0 ], [ 0.003, 147.5 ], [ 0.032, 2041 ], [ 0.078, 3235 ], [ 0.158, 3368 ], [ 0.463, 3259 ],
      [ 0.647, 2992 ], [ 0.949, 2697 ], [ 1.052, 2041 ], [ 1.101, 1884 ], [ 1.392, 1908 ],
      [ 1.786, 1813 ], [ 3.6, 1327 ], [ 3.899, 875.4 ], [ 4.595, 347.3 ], [ 4.857, 195.1 ],
      [ 4.891, 166.5 ], [ 4.9, 0 ]
],
    "totImpulseNs": 8212,
    "totalWeightG": 7237,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 13.39,
    "burnTimeS": 1.4,
    "certOrg": "National Association of Rocketry",
    "commonName": "D10",
    "dataFiles": 2,
    "delays": "3,5,7",
    "designation": "D10W",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/D10.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 25.13,
    "motorId": "5f4294d200023100000002e3",
    "propInfo": "White Lightning",
    "propWeightG": 9.8,
    "samples": [
      [ 0, 0 ], [ 0.007, 23 ], [ 0.018, 25 ], [ 0.027, 20.25 ], [ 0.066, 20.25 ], [ 0.073, 18.5 ],
      [ 0.094, 20.25 ], [ 0.112, 20.75 ], [ 0.137, 19.75 ], [ 0.163, 21.5 ], [ 0.202, 20.75 ],
      [ 0.231, 20.75 ], [ 0.254, 22.75 ], [ 0.27, 20.75 ], [ 0.504, 20 ], [ 0.536, 18.25 ],
      [ 0.607, 17 ], [ 0.687, 14.75 ], [ 0.751, 14.25 ], [ 0.84, 11.25 ], [ 0.998, 8.25 ],
      [ 1.024, 8.25 ], [ 1.248, 2.5 ], [ 1.385, 0 ]
],
    "totImpulseNs": 18.75,
    "totalWeightG": 25.9,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 1141,
    "burnTimeS": 3.7,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1170",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1170FJ",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1489,
    "motorId": "5f4294d200023100000002e4",
    "propInfo": "Black Max",
    "propWeightG": 2800,
    "samples": [
      [ 0, 0 ], [ 0.031, 992.6 ], [ 0.072, 1400 ], [ 0.113, 1473 ], [ 0.144, 1390 ], [ 0.351, 1285 ],
      [ 1.495, 1431 ], [ 1.856, 1431 ], [ 2.227, 1358 ], [ 2.753, 1202 ], [ 2.928, 1170 ],
      [ 3, 1066 ], [ 3.113, 679.2 ], [ 3.247, 355.3 ], [ 3.361, 177.6 ], [ 3.464, 83.59 ],
      [ 3.67, 0 ]
],
    "totImpulseNs": 4232,
    "totalWeightG": 4990,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 362.3,
    "burnTimeS": 2.804,
    "caseInfo": "Pro54-3G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J360",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,12,13,14,15",
    "designation": "1016J360-15A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 321,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 559.3,
    "motorId": "5f4294d200023100000002e5",
    "propInfo": "Skidmark",
    "propWeightG": 606,
    "samples": [
      [ 0, 0 ], [ 0.017, 560.7 ], [ 0.058, 287.4 ], [ 0.167, 328.2 ], [ 0.358, 363.7 ], [ 0.538, 374.4 ],
      [ 0.85, 397.4 ], [ 1.163, 401 ], [ 1.458, 401 ], [ 1.733, 395.7 ], [ 1.983, 381.5 ],
      [ 2.271, 356.6 ], [ 2.496, 340.6 ], [ 2.592, 351.3 ], [ 2.646, 289.2 ], [ 2.679, 172.1 ],
      [ 2.725, 86.94 ], [ 2.804, 19.52 ], [ 2.904, 5.323 ], [ 2.975, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1016,
    "totalWeightG": 1104,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 595.4,
    "burnTimeS": 1.654,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J595",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "985J595-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 510,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 925.4,
    "motorId": "5f4294d200023100000002e6",
    "propInfo": "Blue Streak",
    "propWeightG": 511,
    "samples": [
      [ 0, 0 ], [ 0.009, 644.4 ], [ 0.015, 925 ], [ 0.032, 868.9 ], [ 0.075, 752.5 ], [ 0.168, 729.6 ],
      [ 0.315, 719.2 ], [ 0.475, 713 ], [ 0.632, 713 ], [ 0.775, 704.6 ], [ 0.897, 702.6 ],
      [ 0.979, 700.5 ], [ 1.109, 683.9 ], [ 1.178, 600.7 ], [ 1.226, 507.2 ], [ 1.293, 411.6 ],
      [ 1.451, 270.2 ], [ 1.529, 205.8 ], [ 1.614, 112.2 ], [ 1.644, 58.2 ], [ 1.707, 0 ]
],
    "totImpulseNs": 985,
    "totalWeightG": 866,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 426.9,
    "burnTimeS": 1.837,
    "caseInfo": "Pro38-6G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J425",
    "dataFiles": 1,
    "delays": "7,9,11,13,16",
    "designation": "784J425-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 419,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 631,
    "motorId": "5f4294d200023100000002e7",
    "propInfo": "Blue Streak",
    "propWeightG": 405,
    "samples": [
      [ 0, 0 ], [ 0.006, 4.399 ], [ 0.01, 92.39 ], [ 0.011, 272.8 ], [ 0.012, 434.1 ], [ 0.013, 507.4 ],
      [ 0.016, 589.5 ], [ 0.02, 668.7 ], [ 0.021, 720 ], [ 0.027, 659.9 ], [ 0.037, 632 ],
      [ 0.053, 589.5 ], [ 0.069, 566 ], [ 0.098, 554.3 ], [ 0.135, 552.9 ], [ 0.167, 548.5 ],
      [ 0.206, 547 ], [ 0.251, 542.6 ], [ 0.289, 542.6 ], [ 0.326, 538.2 ], [ 0.369, 533.8 ],
      [ 0.41, 526.5 ], [ 0.455, 527.9 ], [ 0.489, 523.5 ], [ 0.508, 520.6 ], [ 0.524, 520.6 ],
      [ 0.561, 517.7 ], [ 0.589, 522.1 ], [ 0.62, 517.7 ], [ 0.656, 513.3 ], [ 0.687, 510.3 ],
      [ 0.72, 505.9 ], [ 0.75, 507.4 ], [ 0.787, 505.9 ], [ 0.818, 504.5 ], [ 0.856, 500.1 ],
      [ 0.887, 495.7 ], [ 0.919, 494.2 ], [ 0.952, 492.7 ], [ 0.987, 489.8 ], [ 1.017, 489.8 ],
      [ 1.05, 485.4 ], [ 1.078, 482.5 ], [ 1.113, 482.5 ], [ 1.146, 479.5 ], [ 1.18, 479.5 ],
      [ 1.217, 472.2 ], [ 1.252, 473.7 ], [ 1.288, 470.7 ], [ 1.321, 464.9 ], [ 1.348, 451.7 ],
      [ 1.366, 431.1 ], [ 1.382, 401.8 ], [ 1.395, 378.3 ], [ 1.407, 357.8 ], [ 1.421, 338.7 ],
      [ 1.445, 325.6 ], [ 1.476, 313.8 ], [ 1.498, 300.6 ], [ 1.521, 278.6 ], [ 1.541, 249.3 ],
      [ 1.556, 224.4 ], [ 1.568, 206.8 ], [ 1.58, 184.8 ], [ 1.596, 161.3 ], [ 1.607, 140.8 ],
      [ 1.625, 117.3 ], [ 1.641, 93.85 ], [ 1.67, 73.32 ], [ 1.69, 63.06 ], [ 1.717, 52.79 ],
      [ 1.745, 48.39 ], [ 1.772, 45.46 ], [ 1.796, 42.53 ], [ 1.821, 42.53 ], [ 1.849, 38.13 ],
      [ 1.884, 29.33 ], [ 1.906, 26.4 ], [ 1.929, 19.06 ], [ 1.955, 16.13 ], [ 1.978, 10.27 ],
      [ 1.996, 0 ]
],
    "totImpulseNs": 783.9,
    "totalWeightG": 700,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 357,
    "burnTimeS": 1.845,
    "caseInfo": "Pro38-5G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J357",
    "dataFiles": 2,
    "delays": "8,10,12,14,17",
    "designation": "658J357-17A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 260,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 512.3,
    "motorId": "5f4294d200023100000002e8",
    "propInfo": "Blue Streak",
    "propWeightG": 337,
    "samples": [
      [ 0, 0 ], [ 0.019, 514.4 ], [ 0.042, 502.8 ], [ 0.054, 446.5 ], [ 0.073, 434.9 ], [ 0.122, 433.5 ],
      [ 0.258, 430.6 ], [ 0.474, 427.7 ], [ 0.952, 404.6 ], [ 1.236, 381.5 ], [ 1.386, 372.8 ],
      [ 1.454, 367 ], [ 1.487, 345.3 ], [ 1.515, 317.9 ], [ 1.552, 268.8 ], [ 1.615, 196.5 ],
      [ 1.655, 134.4 ], [ 1.721, 49.13 ], [ 1.758, 26.01 ], [ 1.824, 0 ]
],
    "totImpulseNs": 657.6,
    "totalWeightG": 601,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 303.2,
    "burnTimeS": 1.774,
    "caseInfo": "Pro38-4G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I303",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "538I303-16A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 302,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 415.6,
    "motorId": "5f4294d200023100000002e9",
    "propInfo": "Blue Streak",
    "propWeightG": 270,
    "samples": [
      [ 0, 0 ], [ 0.035, 415.8 ], [ 0.053, 402.4 ], [ 0.054, 329.7 ], [ 0.072, 324.1 ], [ 0.109, 348.7 ],
      [ 0.18, 353.2 ], [ 0.28, 357.6 ], [ 0.432, 354.3 ], [ 0.557, 353.2 ], [ 0.684, 348.7 ],
      [ 0.886, 337.5 ], [ 1.054, 325.2 ], [ 1.212, 310.7 ], [ 1.371, 299.5 ], [ 1.475, 292.8 ],
      [ 1.519, 277.2 ], [ 1.542, 245.9 ], [ 1.568, 204.5 ], [ 1.609, 149.8 ], [ 1.639, 108.4 ],
      [ 1.757, 50.29 ], [ 1.827, 22.35 ], [ 1.887, 0 ]
],
    "totImpulseNs": 537.6,
    "totalWeightG": 500,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 236.3,
    "burnTimeS": 1.753,
    "caseInfo": "Pro38-3G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I236",
    "dataFiles": 2,
    "delays": "8,10,12,14,17",
    "designation": "413I236-17A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 243,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 313.7,
    "motorId": "5f4294d200023100000002ea",
    "propInfo": "Blue Streak",
    "propWeightG": 204,
    "samples": [
      [ 0, 0 ], [ 0.028, 290.8 ], [ 0.042, 313.6 ], [ 0.049, 267.9 ], [ 0.053, 253.6 ], [ 0.072, 246 ],
      [ 0.095, 257 ], [ 0.127, 262 ], [ 0.153, 266.3 ], [ 0.243, 268.8 ], [ 0.338, 269.6 ],
      [ 0.454, 270.5 ], [ 0.633, 266.3 ], [ 0.753, 262.9 ], [ 0.89, 256.1 ], [ 1.01, 251.9 ],
      [ 1.119, 247.7 ], [ 1.277, 236.7 ], [ 1.353, 231.6 ], [ 1.453, 227.4 ], [ 1.525, 225.7 ],
      [ 1.555, 195.3 ], [ 1.581, 158.9 ], [ 1.618, 119.2 ], [ 1.685, 89.6 ], [ 1.729, 56.63 ],
      [ 1.743, 36.35 ], [ 1.766, 21.98 ], [ 1.81, 0 ]
],
    "totImpulseNs": 413,
    "totalWeightG": 400,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 152.4,
    "burnTimeS": 1.914,
    "caseInfo": "Pro38-2G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H152",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "276H152-15A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 185,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 212.4,
    "motorId": "5f4294d200023100000002eb",
    "propInfo": "Blue Streak",
    "propWeightG": 138,
    "samples": [
      [ 0, 0 ], [ 0.018, 212.5 ], [ 0.051, 207.4 ], [ 0.059, 153.1 ], [ 0.077, 153.1 ], [ 0.099, 163.2 ],
      [ 0.139, 167 ], [ 0.168, 174.6 ], [ 0.223, 173.3 ], [ 0.256, 175.8 ], [ 0.304, 175.8 ],
      [ 0.396, 179.6 ], [ 0.538, 175.8 ], [ 0.659, 174.6 ], [ 0.714, 174.6 ], [ 0.758, 174.6 ],
      [ 0.802, 170.8 ], [ 0.89, 172 ], [ 0.974, 165.7 ], [ 1.022, 168.2 ], [ 1.084, 163.2 ],
      [ 1.212, 155.6 ], [ 1.293, 151.8 ], [ 1.385, 146.7 ], [ 1.447, 149.3 ], [ 1.484, 144.2 ],
      [ 1.531, 145.5 ], [ 1.575, 144.2 ], [ 1.601, 137.9 ], [ 1.615, 134.1 ], [ 1.623, 120.2 ],
      [ 1.663, 82.22 ], [ 1.714, 46.8 ], [ 1.766, 34.15 ], [ 1.788, 30.36 ], [ 1.821, 26.56 ],
      [ 1.828, 0 ]
],
    "totImpulseNs": 275.9,
    "totalWeightG": 298,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 78.41,
    "burnTimeS": 1.801,
    "caseInfo": "Pro38-1G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G78",
    "dataFiles": 1,
    "delays": "6,8,10,12,15",
    "designation": "141G78-15A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 126,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 137.3,
    "motorId": "5f4294d200023100000002ec",
    "propInfo": "Blue Streak",
    "propWeightG": 69,
    "samples": [
      [ 0, 0 ], [ 0.006, 104.1 ], [ 0.018, 137.9 ], [ 0.036, 70.71 ], [ 0.047, 62.24 ], [ 0.084, 73.69 ],
      [ 0.135, 78.18 ], [ 0.238, 84.15 ], [ 0.438, 89.63 ], [ 0.63, 88.14 ], [ 0.859, 87.14 ],
      [ 1.283, 77.18 ], [ 1.447, 70.71 ], [ 1.643, 67.72 ], [ 1.713, 64.23 ], [ 1.743, 54.27 ],
      [ 1.79, 18.42 ], [ 1.818, 6.473 ], [ 1.852, 0 ]
],
    "totImpulseNs": 140.9,
    "totalWeightG": 197,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 519.4,
    "burnTimeS": 1.634,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J520",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "848J520-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 510,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 658.2,
    "motorId": "5f4294d200023100000002ed",
    "propInfo": "Skidmark",
    "propWeightG": 498,
    "samples": [
      [ 0, 0 ], [ 0.034, 658.7 ], [ 0.076, 615 ], [ 0.12, 585.8 ], [ 0.225, 580.5 ], [ 0.533, 622.9 ],
      [ 0.724, 626.9 ], [ 0.83, 612.3 ], [ 0.982, 607 ], [ 1.17, 595.1 ], [ 1.259, 569.9 ],
      [ 1.296, 458.6 ], [ 1.329, 405.6 ], [ 1.378, 388.3 ], [ 1.418, 302.2 ], [ 1.461, 197.5 ],
      [ 1.493, 135.2 ], [ 1.529, 87.47 ], [ 1.587, 45.06 ], [ 1.756, 0 ]
],
    "sparky": true,
    "totImpulseNs": 848.4,
    "totalWeightG": 850,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 976,
    "burnTimeS": 1.25,
    "caseInfo": "38/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J1000",
    "dataFiles": 2,
    "delays": "7,9,12,15",
    "designation": "J1000-LW",
    "diameter": 38,
    "impulseClass": "J",
    "length": 625,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1400,
    "motorId": "5f4294d200023100000002ee",
    "propInfo": "Loki White",
    "propWeightG": 573,
    "samples": [
      [ 0, 0 ], [ 0.01456, 1400 ], [ 0.1, 1350 ], [ 0.2, 1300 ], [ 0.4, 1300 ], [ 0.6, 1335 ],
      [ 0.7, 1272 ], [ 0.8, 1135 ], [ 1, 395.8 ], [ 1.1, 105.5 ], [ 1.25, 0 ]
],
    "totImpulseNs": 1220,
    "totalWeightG": 1143,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 500,
    "burnTimeS": 6.4,
    "caseInfo": "76/3600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L480",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L480-LR",
    "diameter": 76,
    "impulseClass": "L",
    "length": 498,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 567,
    "motorId": "5f4294d200023100000002ef",
    "propInfo": "Loki Red",
    "propWeightG": 1814,
    "samples": [
      [ 0, 0 ], [ 0.02, 419.5 ], [ 1, 509.2 ], [ 2, 551.5 ], [ 3, 567.3 ], [ 4, 564.6 ], [ 5, 522.4 ],
      [ 6, 401.1 ], [ 6.4, 0 ]
],
    "totImpulseNs": 3200,
    "totalWeightG": 3538,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 920,
    "burnTimeS": 6,
    "caseInfo": "76/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M900",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M900-LR",
    "diameter": 76,
    "impulseClass": "M",
    "length": 785,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1060,
    "motorId": "5f4294d200023100000002f0",
    "propInfo": "Loki Red",
    "propWeightG": 3130,
    "samples": [
      [ 0, 0 ], [ 0.02, 807.4 ], [ 1, 992.1 ], [ 2, 1050 ], [ 3, 1061 ], [ 4, 992.1 ], [ 5, 923.5 ],
      [ 5.5, 591 ], [ 6, 0 ]
],
    "totImpulseNs": 5477,
    "totalWeightG": 5530,
    "type": "reload",
    "updatedOn": "2019-11-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 2946,
    "burnTimeS": 3,
    "caseInfo": "76/8000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M3000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M3000-LW",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1038,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 4780,
    "motorId": "5f4294d200023100000002f1",
    "propInfo": "Loki White",
    "propWeightG": 4350,
    "samples": [
      [ 0, 0 ], [ 0.01, 4781 ], [ 0.5, 3857 ], [ 1.25, 3984 ], [ 2, 2644 ], [ 2.5, 1224 ], [ 3, 0 ]
],
    "totImpulseNs": 8838,
    "totalWeightG": 7160,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3851,
    "burnTimeS": 3.25,
    "caseInfo": "98/12500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N3800",
    "dataFiles": 2,
    "delays": "P",
    "designation": "N3800-LW",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1016,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 4965,
    "motorId": "5f4294d200023100000002f2",
    "propInfo": "Loki White",
    "propWeightG": 6125,
    "samples": [
      [ 0, 0 ], [ 0.01, 4781 ], [ 0.5, 4573 ], [ 1, 4688 ], [ 1.5, 4965 ], [ 2, 3972 ], [ 2.5, 3002 ],
      [ 3, 1986 ], [ 3.25, 0 ]
],
    "totImpulseNs": 12500,
    "totalWeightG": 11600,
    "type": "reload",
    "updatedOn": "2021-07-04"
  },
  {
    "availability": "regular",
    "avgThrustN": 69,
    "burnTimeS": 1.6,
    "caseInfo": "38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G69",
    "dataFiles": 2,
    "delays": "5,8,10,13,17",
    "designation": "HP-G69-SF",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Spitfire.pdf",
    "length": 127,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 97,
    "motorId": "5f4294d200023100000002f3",
    "propInfo": "Spitfire",
    "propWeightG": 63,
    "samples": [
      [ 0, 0 ], [ 0.01, 97.92 ], [ 0.2, 96.54 ], [ 0.4, 94.23 ], [ 0.7995, 76.67 ], [ 1.2, 58.2 ],
      [ 1.6, 0 ]
],
    "sparky": true,
    "totImpulseNs": 110,
    "totalWeightG": 240,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 28.9,
    "burnTimeS": 1.9,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F29",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "55F29-12A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 36,
    "motorId": "5f4294d200023100000002f4",
    "propInfo": "Imax",
    "propWeightG": 30.9,
    "samples": [
      [ 0, 0 ], [ 0.006, 5.661 ], [ 0.038, 23.31 ], [ 0.05, 26.31 ], [ 0.229, 30.26 ], [ 0.327, 32.21 ],
      [ 0.485, 33.16 ], [ 1.031, 31.78 ], [ 1.516, 27.07 ], [ 1.688, 24.83 ], [ 1.765, 20.17 ],
      [ 1.852, 9.467 ], [ 1.963, 2.093 ], [ 2, 0 ]
],
    "totImpulseNs": 54.8,
    "totalWeightG": 105.8,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 223.8,
    "burnTimeS": 1.7,
    "caseInfo": "Pro29-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I224",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "381I224-15A",
    "diameter": 29,
    "impulseClass": "I",
    "length": 365,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 454.4,
    "motorId": "5f4294d200023100000002f5",
    "propInfo": "Classic",
    "propWeightG": 182.5,
    "samples": [
      [ 0, 0 ], [ 0.008, 357.5 ], [ 0.02, 433.1 ], [ 0.111, 343.8 ], [ 0.229, 318.9 ], [ 0.479, 295.9 ],
      [ 0.836, 277.1 ], [ 1.022, 269.6 ], [ 1.097, 233.9 ], [ 1.233, 117.9 ], [ 1.294, 93 ],
      [ 1.39, 62 ], [ 1.667, 26.77 ], [ 1.692, 17.38 ], [ 1.7, 0 ]
],
    "totImpulseNs": 381.5,
    "totalWeightG": 370,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 933.8,
    "burnTimeS": 3.371,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L935",
    "dataFiles": 2,
    "delays": "P",
    "designation": "3147L935-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1586,
    "motorId": "5f4294d200023100000002f6",
    "propInfo": "Imax",
    "propWeightG": 1567,
    "samples": [
      [ 0, 0 ], [ 0.012, 1583 ], [ 0.052, 1366 ], [ 0.159, 1278 ], [ 2.198, 990.3 ], [ 2.514, 719.4 ],
      [ 3.021, 239.8 ], [ 3.104, 160.8 ], [ 3.273, 107.2 ], [ 3.3, 0 ]
],
    "totImpulseNs": 3147,
    "totalWeightG": 2542,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1786,
    "burnTimeS": 4.53,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1790",
    "dataFiles": 2,
    "designation": "8088M1790-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2093,
    "motorId": "5f4294d200023100000002f7",
    "propInfo": "Skidmark",
    "propWeightG": 4706,
    "samples": [
      [ 0, 0 ], [ 0.059, 1792 ], [ 0.199, 1596 ], [ 0.6, 1782 ], [ 1.215, 1914 ], [ 1.973, 2022 ],
      [ 2.742, 1970 ], [ 3.387, 1834 ], [ 3.812, 1653 ], [ 4.28, 1556 ], [ 4.385, 1295 ],[ 4.476, 355 ],
      [ 4.541, 91.69 ], [ 4.597, 0 ]
],
    "sparky": true,
    "totImpulseNs": 8089,
    "totalWeightG": 8273,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2586,
    "burnTimeS": 4.28,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2600",
    "dataFiles": 2,
    "designation": "11077N2600-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3048,
    "motorId": "5f4294d200023100000002f8",
    "propInfo": "Skidmark",
    "propWeightG": 6618,
    "samples": [
      [ 0, 0 ], [ 0.063, 2794 ], [ 0.199, 2498 ], [ 0.485, 2620 ], [ 0.907, 2778 ], [ 1.367, 2903 ],
      [ 1.749, 2949 ], [ 2.051, 2972 ], [ 2.764, 2817 ], [ 3.549, 2392 ], [ 3.767, 2310 ],
      [ 3.958, 2386 ], [ 4.177, 639.2 ], [ 4.265, 138.4 ], [ 4.297, 0 ]
],
    "sparky": true,
    "totImpulseNs": 11080,
    "totalWeightG": 11480,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 3403,
    "burnTimeS": 4.19,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N3400",
    "dataFiles": 2,
    "designation": "14263N3400-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3742,
    "motorId": "5f4294d200023100000002f9",
    "propInfo": "Skidmark",
    "propWeightG": 8282,
    "samples": [
      [ 0, 0 ], [ 0.055, 3602 ], [ 0.177, 3060 ], [ 0.608, 3397 ], [ 1.537, 3811 ], [ 2.252, 3974 ],
      [ 2.86, 3760 ], [ 3.152, 3598 ], [ 3.545, 3346 ], [ 3.71, 3628 ], [ 3.837, 2868 ], [ 3.884, 1908 ],
      [ 3.999, 1511 ], [ 4.188, 273.2 ], [ 4.28, 81.09 ], [ 4.3, 0 ]
],
    "sparky": true,
    "totImpulseNs": 14260,
    "totalWeightG": 13970,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1526,
    "burnTimeS": 4.97,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1520",
    "dataFiles": 2,
    "designation": "7579M1520-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 548,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1825,
    "motorId": "5f4294d200023100000002fa",
    "propInfo": "Blue Streak",
    "propWeightG": 2981,
    "samples": [
      [ 0, 0 ], [ 0.04, 1428 ], [ 0.082, 1706 ], [ 0.176, 1620 ], [ 0.748, 1734 ], [ 1.652, 1827 ],
      [ 2.676, 1716 ], [ 3.89, 1423 ], [ 4.399, 1405 ], [ 4.616, 661.7 ], [ 4.877, 69.65 ],
      [ 4.897, 0 ]
],
    "totImpulseNs": 7579,
    "totalWeightG": 6718,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1797,
    "burnTimeS": 5.49,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1800",
    "dataFiles": 2,
    "designation": "9870M1800-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2241,
    "motorId": "5f4294d200023100000002fb",
    "propInfo": "Blue Streak",
    "propWeightG": 4802,
    "samples": [
      [ 0, 0 ], [ 0.02, 1873 ], [ 0.059, 2177 ], [ 0.265, 1933 ], [ 1.312, 2078 ], [ 1.826, 2109 ],
      [ 3.07, 2010 ], [ 4.468, 1751 ], [ 4.674, 1737 ], [ 4.89, 1023 ], [ 5.178, 451.9 ],[ 5.384, 318.3 ],
      [ 5.548, 99.48 ], [ 5.6, 0 ]
],
    "totImpulseNs": 9870,
    "totalWeightG": 8342,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2836,
    "burnTimeS": 4.85,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2850",
    "dataFiles": 2,
    "designation": "13767N2850-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3377,
    "motorId": "5f4294d200023100000002fc",
    "propInfo": "Blue Streak",
    "propWeightG": 6759,
    "samples": [
      [ 0, 0 ], [ 0.003, 286.9 ], [ 0.085, 3152 ], [ 0.185, 3104 ], [ 0.322, 3078 ], [ 0.945, 3212 ],
      [ 1.725, 3313 ], [ 2.819, 3119 ], [ 4.163, 2776 ], [ 4.376, 1982 ], [ 4.538, 845.9 ],
      [ 4.763, 436 ], [ 4.849, 149.1 ], [ 4.9, 0 ]
],
    "totImpulseNs": 13770,
    "totalWeightG": 11690,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2868,
    "burnTimeS": 6.14,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2900",
    "dataFiles": 2,
    "designation": "17613N2900-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 4165,
    "motorId": "5f4294d200023100000002fd",
    "propInfo": "Classic",
    "propWeightG": 8449,
    "samples": [
      [ 0, 0 ], [ 0.026, 1864 ], [ 0.074, 3605 ], [ 0.122, 4022 ], [ 0.361, 3619 ], [ 1.18, 3498 ],
      [ 1.981, 3587 ], [ 3.493, 3156 ], [ 4.648, 2875 ], [ 4.939, 2322 ], [ 5.205, 1648 ],
      [ 5.629, 945.8 ], [ 5.972, 430.8 ], [ 6.219, 206 ], [ 6.278, 149.8 ], [ 6.296, 0 ]
],
    "totImpulseNs": 17610,
    "totalWeightG": 14170,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1230,
    "burnTimeS": 4.48,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1230",
    "dataFiles": 2,
    "designation": "5506M1230-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1587,
    "motorId": "5f4294d200023100000002fe",
    "propInfo": "Imax",
    "propWeightG": 2900,
    "samples": [
      [ 0, 0 ], [ 0.117, 270.3 ], [ 0.144, 912.8 ], [ 0.212, 1296 ], [ 0.408, 1444 ], [ 1.087, 1498 ],
      [ 1.767, 1507 ], [ 2.403, 1435 ], [ 2.949, 1315 ], [ 3.352, 1209 ], [ 3.648, 1118 ],
      [ 3.909, 868.4 ], [ 4.409, 201.8 ], [ 4.586, 111.1 ], [ 4.67, 79.61 ], [ 4.7, 0 ]
],
    "totImpulseNs": 5507,
    "totalWeightG": 4844,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2147,
    "burnTimeS": 3.47,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2150",
    "dataFiles": 2,
    "designation": "7455M2150-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2614,
    "motorId": "5f4294d200023100000002ff",
    "propInfo": "Red Lightning",
    "propWeightG": 3835,
    "samples": [
      [ 0, 0 ], [ 0.027, 999.5 ], [ 0.067, 1877 ], [ 0.135, 2287 ], [ 0.239, 2414 ], [ 0.451, 2345 ],
      [ 0.796, 2425 ], [ 1.193, 2611 ], [ 1.75, 2381 ], [ 2.33, 2229 ], [ 2.908, 2190 ], [ 3.316, 703.2 ],
      [ 3.461, 138.4 ], [ 3.49, 74.75 ], [ 3.5, 0 ]
],
    "totImpulseNs": 7455,
    "totalWeightG": 6324,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2047,
    "burnTimeS": 3.61,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2045",
    "dataFiles": 2,
    "designation": "7388M2045-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2952,
    "motorId": "5f4294d20002310000000300",
    "propInfo": "Blue Streak",
    "propWeightG": 3547,
    "samples": [
      [ 0, 0 ], [ 0.004, 556.9 ], [ 0.019, 1690 ], [ 0.063, 2359 ], [ 0.153, 2339 ], [ 0.182, 2570 ],
      [ 0.247, 2471 ], [ 0.616, 2498 ], [ 1.028, 2547 ], [ 2.111, 2316 ], [ 2.551, 2274 ],
      [ 2.635, 2254 ], [ 2.796, 1697 ], [ 3.009, 1473 ], [ 3.349, 247.1 ], [ 3.541, 108.7 ],
      [ 3.587, 0 ]
],
    "totImpulseNs": 7388,
    "totalWeightG": 6071,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2050,
    "burnTimeS": 3.31,
    "caseInfo": "ABC 75-7600",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2050",
    "dataFiles": 2,
    "designation": "6774M2050-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 2554,
    "motorId": "5f4294d20002310000000301",
    "propInfo": "Skidmark",
    "propWeightG": 3870,
    "samples": [
      [ 0, 0 ], [ 0.038, 2153 ], [ 0.833, 2506 ], [ 1.189, 2539 ], [ 1.546, 2501 ], [ 1.775, 2415 ],
      [ 1.907, 2280 ], [ 2.168, 2087 ], [ 2.401, 1973 ], [ 2.616, 1910 ], [ 2.776, 1871 ],
      [ 2.918, 1203 ], [ 3.056, 706.6 ], [ 3.309, 135.2 ], [ 3.4, 0 ]
],
    "sparky": true,
    "totImpulseNs": 6775,
    "totalWeightG": 7129,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 981,
    "burnTimeS": 2.17,
    "caseInfo": "54/2550",
    "certOrg": "National Association of Rocketry",
    "commonName": "K980",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K980BL",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1297,
    "motorId": "5f4294d20002310000000302",
    "propInfo": "Black Lightning",
    "propWeightG": 1230,
    "samples": [
      [ 0, 0 ], [ 0.05, 1186 ], [ 0.07, 1022 ], [ 0.25, 1135 ], [ 0.35, 1200 ], [ 0.5, 1225 ],
      [ 0.75, 1297 ], [ 0.91, 1266 ], [ 1.1, 1202 ], [ 1.58, 1046 ], [ 1.65, 1068 ], [ 1.7, 1001 ],
      [ 1.8, 495 ], [ 2, 222.5 ], [ 2.17, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2123,
    "totalWeightG": 2457,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 464,
    "burnTimeS": 5.28,
    "caseInfo": "54/2550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K470",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K470WC",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1050,
    "motorId": "5f4294d20002310000000303",
    "propInfo": "White Cloud",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.025, 1001 ], [ 0.175, 905 ], [ 0.45, 1050 ], [ 0.65, 827.7 ], [ 1.091, 716.5 ],
      [ 1.633, 623 ], [ 5.275, 0 ]
],
    "totImpulseNs": 2450,
    "totalWeightG": 2327,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1075,
    "burnTimeS": 2.24,
    "caseInfo": "54/2550",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1075",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K1075RT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1247,
    "motorId": "5f4294d20002310000000304",
    "propInfo": "Red Twister",
    "propWeightG": 1314,
    "samples": [
      [ 0, 0 ], [ 0.017, 687.7 ], [ 0.042, 1027 ], [ 0.134, 1121 ], [ 0.242, 1171 ], [ 0.325, 1204 ],
      [ 1.225, 1247 ], [ 1.659, 1239 ], [ 1.742, 1223 ], [ 1.8, 1123 ], [ 2.24, 0 ]
],
    "totImpulseNs": 2413,
    "totalWeightG": 2541,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 699,
    "burnTimeS": 3.84,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L695",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L695BL",
    "diameter": 75,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1064,
    "motorId": "5f4294d20002310000000305",
    "propInfo": "Black Lightning",
    "propWeightG": 1650,
    "samples": [
      [ 0, 0 ], [ 0.01, 333.6 ], [ 0.05, 378.1 ], [ 0.4959, 560.3 ], [ 0.995, 724.1 ], [ 1.497, 883.6 ],
      [ 1.99, 1043 ], [ 2.131, 1065 ], [ 2.269, 1065 ], [ 2.505, 1065 ], [ 2.64, 1043 ], [ 2.762, 965.5 ],
      [ 2.954, 797.4 ], [ 3.176, 512.9 ], [ 3.365, 288.8 ], [ 3.691, 38.79 ], [ 3.84, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2669,
    "totalWeightG": 3402,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 424,
    "burnTimeS": 7.95,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L425",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L425WC",
    "diameter": 75,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 756,
    "motorId": "5f4294d20002310000000306",
    "propInfo": "White Cloud",
    "propWeightG": 1750,
    "samples": [
      [ 0, 0 ], [ 0.02, 756.2 ], [ 0.05, 711.7 ], [ 0.125, 756.2 ], [ 0.1808, 689.6 ], [ 0.3616, 662.1 ],
      [ 0.5141, 644.8 ], [ 0.9548, 631 ], [ 1.282, 620.7 ], [ 2.006, 586.2 ], [ 2.963, 524.1 ],
      [ 4.426, 427.6 ], [ 5.461, 341.4 ], [ 6.572, 224.1 ], [ 7.376, 110.3 ], [ 7.95, 0 ]
],
    "totImpulseNs": 3372,
    "totalWeightG": 3502,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1149,
    "burnTimeS": 3.11,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1150",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L1150WC",
    "diameter": 75,
    "impulseClass": "L",
    "length": 497,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1757,
    "motorId": "5f4294d20002310000000307",
    "propInfo": "White Cloud",
    "propWeightG": 1750,
    "samples": [
      [ 0, 0 ], [ 0.1, 1001 ], [ 0.2, 1068 ], [ 0.5, 1179 ], [ 1.25, 1668 ], [ 1.5, 1757 ],[ 1.7, 1735 ],
      [ 2, 1334 ], [ 2.25, 982.7 ], [ 2.5, 711.7 ], [ 2.75, 556 ], [ 3, 266.9 ], [ 3.11, 0 ]
],
    "totImpulseNs": 3575,
    "totalWeightG": 3502,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1613,
    "burnTimeS": 3.71,
    "caseInfo": "75/7600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1610",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M1610BL",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2206,
    "motorId": "5f4294d20002310000000308",
    "propInfo": "Black Lightning",
    "propWeightG": 3674,
    "samples": [
      [ 0, 0 ], [ 0.06277, 1560 ], [ 1.3, 2135 ], [ 1.648, 2207 ], [ 1.795, 2207 ], [ 1.899, 2190 ],
      [ 1.99, 2155 ], [ 2.502, 1716 ], [ 2.825, 1474 ], [ 2.994, 1241 ], [ 3.293, 594.8 ],
      [ 3.5, 355.9 ], [ 3.71, 0 ]
],
    "sparky": true,
    "totImpulseNs": 5973,
    "totalWeightG": 6918,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 92.4,
    "burnTimeS": 10.2,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J99",
    "dataFiles": 1,
    "delays": "P",
    "designation": "J99N",
    "diameter": 54,
    "impulseClass": "J",
    "length": 231,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 151.9,
    "motorId": "5f4294d20002310000000309",
    "propInfo": "Warp 9",
    "propWeightG": 556,
    "samples": [
      [ 0, 0 ], [ 0.031, 117.3 ], [ 0.078, 135 ], [ 0.242, 144.3 ], [ 0.828, 131.4 ], [ 2.539, 110.7 ],
      [ 3.594, 102.1 ], [ 4.82, 94.15 ], [ 5.938, 86.04 ], [ 7.523, 81.22 ], [ 8.984, 76.98 ],
      [ 9.047, 74.08 ], [ 9.547, 16.01 ], [ 9.711, 5.209 ], [ 9.82, 1.929 ], [ 10, 0 ]
],
    "totImpulseNs": 945.2,
    "totalWeightG": 899,
    "type": "reload",
    "updatedOn": "2020-11-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 509.9,
    "burnTimeS": 2.27,
    "caseInfo": "RMS-38/1320",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J510",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J510W",
    "diameter": 38,
    "impulseClass": "J",
    "length": 584.2,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1042,
    "motorId": "5f4294d2000231000000030a",
    "propInfo": "White Lightning",
    "propWeightG": 662,
    "samples": [
      [ 0, 0 ], [ 0.032, 1056 ], [ 0.11, 865.5 ], [ 0.161, 829.9 ], [ 0.267, 794.3 ], [ 0.351, 780.6 ],
      [ 0.512, 705.3 ], [ 0.682, 654.6 ], [ 1.318, 624.5 ], [ 1.461, 475.2 ], [ 1.623, 345.1 ],
      [ 1.934, 153.4 ], [ 2.039, 115 ], [ 2.177, 41.09 ], [ 2.317, 5.478 ], [ 2.5, 0 ]
],
    "totImpulseNs": 1162,
    "totalWeightG": 1080,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1859,
    "burnTimeS": 3.1,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1780",
    "dataFiles": 3,
    "delays": "P",
    "designation": "M1780NT",
    "diameter": 75,
    "impulseClass": "M",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3056,
    "motorId": "5f4294d2000231000000030b",
    "propInfo": "New Blue Thunder",
    "propWeightG": 2560,
    "samples": [
      [ 0, 0 ], [ 0.028, 2204 ], [ 0.118, 2566 ], [ 0.173, 2566 ], [ 0.341, 2214 ], [ 0.44, 2144 ],
      [ 0.636, 2115 ], [ 1.34, 2134 ], [ 1.395, 2204 ], [ 1.648, 2121 ], [ 1.841, 2042 ],[ 1.874, 1895 ],
      [ 2.127, 1643 ], [ 2.353, 1517 ], [ 2.584, 1461 ], [ 2.672, 1348 ], [ 2.763, 1069 ],
      [ 2.829, 723.7 ], [ 2.939, 438.2 ], [ 2.999, 292.1 ], [ 3.101, 172.6 ], [ 3.272, 36.52 ],
      [ 3.5, 0 ]
],
    "totImpulseNs": 5783,
    "totalWeightG": 4715,
    "type": "reload",
    "updatedOn": "2020-11-26"
  },
  {
    "availability": "regular",
    "avgThrustN": 1875,
    "burnTimeS": 4.43,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1845",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1845NT",
    "diameter": 98,
    "impulseClass": "M",
    "length": 597,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3081,
    "motorId": "5f4294d2000231000000030c",
    "propInfo": "Blue Thunder",
    "propWeightG": 3772,
    "samples": [
      [ 0, 0 ], [ 0.024, 2262 ], [ 0.067, 2116 ], [ 1.032, 2268 ], [ 1.354, 2434 ], [ 2.253, 2288 ],
      [ 2.406, 2149 ], [ 2.578, 1821 ], [ 3.065, 1580 ], [ 3.583, 1411 ], [ 3.838, 1364 ],
      [ 3.913, 1219 ], [ 3.995, 685.4 ], [ 4.109, 400.7 ], [ 4.352, 248.3 ], [ 4.498, 33.11 ],
      [ 4.729, 0 ]
],
    "totImpulseNs": 8307,
    "totalWeightG": 6682,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3168,
    "burnTimeS": 4.37,
    "caseInfo": "RMS-98/15360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N3300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "N3300R",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1046,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 4301,
    "motorId": "5f4294d2000231000000030d",
    "propInfo": "Redline",
    "propWeightG": 7512,
    "samples": [
      [ 0, 0 ], [ 0.036, 40.17 ], [ 0.078, 2888 ], [ 0.125, 3776 ], [ 0.157, 3628 ], [ 0.2, 3535 ],
      [ 0.417, 3597 ], [ 0.666, 3628 ], [ 0.962, 3669 ], [ 1.197, 3664 ], [ 1.507, 3655 ],
      [ 1.9, 3620 ], [ 2.206, 3570 ], [ 2.423, 3530 ], [ 2.637, 3423 ], [ 3.008, 3209 ], [ 3.318, 3026 ],
      [ 3.617, 2821 ], [ 3.86, 2647 ], [ 3.963, 2598 ], [ 4.024, 2468 ], [ 4.091, 2267 ],[ 4.163, 1897 ],
      [ 4.284, 1125 ], [ 4.394, 343.7 ], [ 4.455, 129.4 ], [ 4.519, 0 ]
],
    "totImpulseNs": 14040,
    "totalWeightG": 12050,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 21.8,
    "burnTimeS": 1.6,
    "certOrg": "National Association of Rocketry",
    "commonName": "E20",
    "dataFiles": 1,
    "delays": "4,7",
    "designation": "E20W",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/E20.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 34.9,
    "motorId": "5f4294d2000231000000030e",
    "propInfo": "White Lightning",
    "propWeightG": 16.2,
    "samples": [
      [ 0, 0 ], [ 0.001, 0.02904 ], [ 0.025, 0.2276 ], [ 0.039, 1.394 ], [ 0.049, 2.672 ],
      [ 0.057, 5.817 ], [ 0.061, 7.769 ], [ 0.063, 9.004 ], [ 0.065, 10.72 ], [ 0.073, 20.34 ],
      [ 0.075, 22.41 ], [ 0.081, 27.13 ], [ 0.085, 28.99 ], [ 0.091, 30.54 ], [ 0.105, 32.95 ],
      [ 0.125, 34.26 ], [ 0.139, 34.68 ], [ 0.253, 33.85 ], [ 0.361, 32.08 ], [ 0.631, 29.81 ],
      [ 0.673, 28.83 ], [ 0.771, 27.36 ], [ 0.801, 26.39 ], [ 0.901, 24.21 ], [ 1.085, 18.07 ],
      [ 1.135, 16.89 ], [ 1.161, 15.87 ], [ 1.291, 12.19 ], [ 1.373, 9.381 ], [ 1.509, 4.039 ],
      [ 1.553, 2.722 ], [ 1.603, 1.721 ], [ 1.759, 0 ]
],
    "totImpulseNs": 35,
    "totalWeightG": 49,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 4.84,
    "burnTimeS": 0.38,
    "certOrg": "National Association of Rocketry",
    "commonName": "A8",
    "dataFiles": 1,
    "delays": "3",
    "designation": "A8-3",
    "diameter": 18,
    "impulseClass": "A",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 11.14,
    "motorId": "5f4294d2000231000000030f",
    "propInfo": "black powder",
    "propWeightG": 6.3,
    "samples": [
      [ 0, 0 ], [ 0.073, 2.139 ], [ 0.085, 2.599 ], [ 0.097, 3.317 ], [ 0.11, 4.035 ], [ 0.122, 4.34 ],
      [ 0.135, 4.955 ], [ 0.147, 5.569 ], [ 0.16, 6.132 ], [ 0.173, 6.85 ], [ 0.185, 7.361 ],
      [ 0.197, 7.976 ], [ 0.21, 8.281 ], [ 0.222, 8.741 ], [ 0.235, 9.407 ], [ 0.247, 9.712 ],
      [ 0.26, 11.14 ], [ 0.273, 10.06 ], [ 0.285, 8.768 ], [ 0.297, 6.647 ], [ 0.31, 5.042 ],
      [ 0.322, 3.953 ], [ 0.335, 3.019 ], [ 0.347, 2.136 ], [ 0.36, 1.512 ], [ 0.372, 1.043 ],
      [ 0.38, 0 ]
],
    "totImpulseNs": 1.86,
    "totalWeightG": 13.1,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 4.22,
    "burnTimeS": 0.91,
    "certOrg": "National Association of Rocketry",
    "commonName": "B4",
    "dataFiles": 1,
    "delays": "4",
    "designation": "B4-4",
    "diameter": 18,
    "impulseClass": "B",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 12.81,
    "motorId": "5f4294d20002310000000310",
    "propInfo": "black powder",
    "propWeightG": 10.4,
    "samples": [
      [ 0, 0 ], [ 0.033, 1.091 ], [ 0.061, 2.25 ], [ 0.104, 4.228 ], [ 0.138, 6.002 ], [ 0.184, 8.457 ],
      [ 0.222, 10.5 ], [ 0.258, 12.81 ], [ 0.275, 9.412 ], [ 0.289, 6.07 ], [ 0.298, 4.842 ],
      [ 0.309, 4.228 ], [ 0.329, 3.751 ], [ 0.359, 3.546 ], [ 0.444, 3.41 ], [ 0.55, 3.274 ],
      [ 0.646, 3.478 ], [ 0.723, 3.615 ], [ 0.787, 3.751 ], [ 0.814, 3.751 ], [ 0.831, 3.478 ],
      [ 0.843, 2.796 ], [ 0.862, 1.773 ], [ 0.882, 0.818 ], [ 0.904, 0.136 ], [ 0.91, 0 ]
],
    "totImpulseNs": 3.84,
    "totalWeightG": 16.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 80.5,
    "burnTimeS": 1.15,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G80",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "93G80-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 112.2,
    "motorId": "5f4294d20002310000000311",
    "propInfo": "Skidmark",
    "propWeightG": 50,
    "samples": [
      [ 0, 0 ], [ 0.007, 51.31 ], [ 0.025, 108.1 ], [ 0.047, 65.31 ], [ 0.122, 78.03 ], [ 0.307, 89.06 ],
      [ 0.446, 93.86 ], [ 0.581, 95.14 ], [ 0.75, 91.89 ], [ 0.924, 84.25 ], [ 0.972, 83.4 ],
      [ 1.038, 88.35 ], [ 1.064, 67.15 ], [ 1.08, 40.43 ], [ 1.093, 21.63 ], [ 1.11, 9.754 ],
      [ 1.131, 2.545 ], [ 1.178, 0 ]
],
    "sparky": true,
    "totImpulseNs": 92.9,
    "totalWeightG": 143.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 122.2,
    "burnTimeS": 4.12,
    "caseInfo": "Pro54-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I120",
    "dataFiles": 2,
    "delays": "5,6,7,8,9,10,11,12,13,14,15",
    "designation": "502I120-15A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 143,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 152,
    "motorId": "5f4294d20002310000000312",
    "propInfo": "Imax",
    "propWeightG": 263.1,
    "samples": [
      [ 0, 0 ], [ 0.035, 13.69 ], [ 0.047, 87.18 ], [ 0.067, 148.5 ], [ 0.096, 129.7 ], [ 0.212, 141.9 ],
      [ 0.385, 142.1 ], [ 0.671, 141.7 ], [ 1.032, 139.7 ], [ 1.545, 136.9 ], [ 2.15, 127.9 ],
      [ 3.757, 92.43 ], [ 4.031, 89.06 ], [ 4.122, 67.12 ], [ 4.216, 23.44 ], [ 4.283, 5.25 ],
      [ 4.3, 0 ]
],
    "totImpulseNs": 501.8,
    "totalWeightG": 623.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3075,
    "burnTimeS": 1.99,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M3100",
    "dataFiles": 2,
    "designation": "6118M3100-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3709,
    "motorId": "5f4294d20002310000000313",
    "propInfo": "White Thunder",
    "propWeightG": 2903,
    "samples": [
      [ 0, 0 ], [ 0.02, 3118 ], [ 0.057, 2977 ], [ 0.148, 3186 ], [ 0.496, 3392 ], [ 0.817, 3666 ],
      [ 0.936, 3533 ], [ 1.173, 3358 ], [ 1.501, 3199 ], [ 1.717, 3139 ], [ 1.78, 2412 ],[ 1.812, 2130 ],
      [ 1.832, 2032 ], [ 1.937, 346.4 ], [ 1.985, 81.27 ], [ 2, 0 ]
],
    "totImpulseNs": 6118,
    "totalWeightG": 5018,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1674,
    "burnTimeS": 3.68,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1675",
    "dataFiles": 2,
    "designation": "6162M1675-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2499,
    "motorId": "5f4294d20002310000000314",
    "propInfo": "Pink",
    "propWeightG": 3019,
    "samples": [
      [ 0, 0 ], [ 0.04, 1351 ], [ 0.077, 1719 ], [ 0.166, 1877 ], [ 0.307, 2012 ], [ 0.49, 2028 ],
      [ 0.616, 2028 ], [ 1.018, 2073 ], [ 1.594, 1993 ], [ 2.246, 1861 ], [ 2.762, 1754 ],
      [ 2.908, 1738 ], [ 3.023, 1563 ], [ 3.206, 909 ], [ 3.409, 377.8 ], [ 3.493, 278.6 ],
      [ 3.577, 259.7 ], [ 3.69, 113.3 ], [ 3.796, 0 ]
],
    "totImpulseNs": 6162,
    "totalWeightG": 5223,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1592,
    "burnTimeS": 4.74,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1590",
    "dataFiles": 2,
    "designation": "7545M1590-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2499,
    "motorId": "5f4294d20002310000000315",
    "propInfo": "Classic",
    "propWeightG": 3590,
    "samples": [
      [ 0, 0 ], [ 0.028, 1211 ], [ 0.059, 2024 ], [ 0.163, 2235 ], [ 0.214, 2303 ], [ 0.492, 2154 ],
      [ 0.767, 2092 ], [ 1.015, 2103 ], [ 1.335, 2083 ], [ 1.571, 2030 ], [ 2.366, 1779 ],
      [ 3.488, 1534 ], [ 3.755, 1030 ], [ 3.895, 960 ], [ 4.12, 650.3 ], [ 4.207, 591.2 ],
      [ 4.44, 340.7 ], [ 4.665, 199.9 ], [ 4.778, 90.09 ], [ 4.8, 0 ]
],
    "totImpulseNs": 7545,
    "totalWeightG": 6076,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2268,
    "burnTimeS": 2.41,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2250",
    "dataFiles": 2,
    "designation": "5472M2250-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2960,
    "motorId": "5f4294d20002310000000316",
    "propInfo": "C-Star",
    "propWeightG": 2489,
    "samples": [
      [ 0, 0 ], [ 0.016, 2542 ], [ 0.051, 2391 ], [ 0.139, 2582 ], [ 0.259, 2599 ], [ 0.877, 2663 ],
      [ 1.388, 2556 ], [ 1.823, 2458 ], [ 1.86, 2303 ], [ 1.891, 1927 ], [ 1.997, 1453 ],[ 2.195, 1453 ],
      [ 2.244, 1207 ], [ 2.276, 645.6 ], [ 2.336, 517.8 ], [ 2.414, 144.6 ], [ 2.456, 40.35 ],
      [ 2.497, 0 ]
],
    "totImpulseNs": 5472,
    "totalWeightG": 4415,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1562,
    "burnTimeS": 3.42,
    "caseInfo": "Pro98-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1560",
    "dataFiles": 2,
    "designation": "5342M1560-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 394,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1805,
    "motorId": "5f4294d20002310000000317",
    "propInfo": "White Thunder",
    "propWeightG": 2452,
    "samples": [
      [ 0, 0 ], [ 0.037, 1474 ], [ 0.121, 1437 ], [ 0.328, 1523 ], [ 1.299, 1775 ], [ 1.545, 1808 ],
      [ 1.797, 1808 ], [ 1.998, 1787 ], [ 2.208, 1737 ], [ 2.462, 1573 ], [ 2.782, 1415 ],
      [ 3.086, 1310 ], [ 3.213, 1291 ], [ 3.258, 1310 ], [ 3.328, 679.5 ], [ 3.383, 174 ],
      [ 3.428, 68.18 ], [ 3.5, 0 ]
],
    "totImpulseNs": 5342,
    "totalWeightG": 4977,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3421,
    "burnTimeS": 2.92,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M3400",
    "dataFiles": 2,
    "designation": "9994M3400-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3983,
    "motorId": "5f4294d20002310000000318",
    "propInfo": "White Thunder",
    "propWeightG": 4452,
    "samples": [
      [ 0, 0 ], [ 0.021, 3639 ], [ 0.049, 3360 ], [ 0.111, 3301 ], [ 0.374, 3479 ], [ 0.909, 3775 ],
      [ 1.059, 3898 ], [ 1.208, 3885 ], [ 1.571, 3788 ], [ 2.168, 3402 ], [ 2.467, 3246 ],
      [ 2.709, 3292 ], [ 2.734, 3254 ], [ 2.821, 1033 ], [ 2.876, 550.1 ], [ 2.93, 165 ],[ 2.99, 0 ]
],
    "totImpulseNs": 9995,
    "totalWeightG": 8108,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3787,
    "burnTimeS": 4.65,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N3800",
    "dataFiles": 2,
    "designation": "17631N3800-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 5097,
    "motorId": "5f4294d20002310000000319",
    "propInfo": "Blue Streak",
    "propWeightG": 8449,
    "samples": [
      [ 0, 0 ], [ 0.016, 2672 ], [ 0.069, 4632 ], [ 0.159, 4361 ], [ 0.293, 4356 ], [ 1.11, 4591 ],
      [ 1.48, 4704 ], [ 1.847, 4729 ], [ 2.384, 4683 ], [ 2.628, 4550 ], [ 3.141, 4136 ],[ 3.343, 4043 ],
      [ 3.434, 4023 ], [ 3.497, 3788 ], [ 3.582, 3204 ], [ 3.681, 2810 ], [ 3.853, 2385 ],
      [ 4.042, 1459 ], [ 4.198, 972.5 ], [ 4.338, 946.9 ], [ 4.401, 865 ], [ 4.604, 378.8 ],
      [ 4.673, 230.3 ], [ 4.7, 0 ]
],
    "totImpulseNs": 17630,
    "totalWeightG": 14260,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 5774,
    "burnTimeS": 3.49,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N5800",
    "dataFiles": 2,
    "designation": "20146N5800-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 8035,
    "motorId": "5f4294d2000231000000031a",
    "propInfo": "C-Star",
    "propWeightG": 9021,
    "samples": [
      [ 0, 0 ], [ 0.019, 6695 ], [ 0.049, 6720 ], [ 0.103, 6593 ], [ 0.384, 6678 ], [ 1.109, 6957 ],
      [ 1.569, 6940 ], [ 1.991, 6720 ], [ 2.622, 6009 ], [ 3.011, 3276 ], [ 3.192, 2607 ],
      [ 3.334, 1600 ], [ 3.423, 1041 ], [ 3.513, 389.3 ], [ 3.581, 220.1 ], [ 3.594, 0 ]
],
    "totImpulseNs": 20150,
    "totalWeightG": 14830,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1291,
    "burnTimeS": 3.64,
    "caseInfo": "ABC 75-6000",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1290",
    "dataFiles": 2,
    "designation": "4701L1290-P",
    "diameter": 76,
    "impulseClass": "L",
    "length": 785,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1588,
    "motorId": "5f4294d2000231000000031b",
    "propInfo": "Skidmark",
    "propWeightG": 2705,
    "samples": [
      [ 0, 0 ], [ 0.022, 117.6 ], [ 0.081, 786 ], [ 0.11, 797.2 ], [ 0.176, 1227 ], [ 0.691, 1357 ],
      [ 1.231, 1462 ], [ 1.761, 1477 ], [ 2.008, 1467 ], [ 2.311, 1417 ], [ 2.835, 1299 ],
      [ 3.101, 1236 ], [ 3.167, 1230 ], [ 3.34, 1322 ], [ 3.373, 1286 ], [ 3.532, 365.9 ],
      [ 3.602, 201.6 ], [ 3.734, 91.48 ], [ 3.782, 69.08 ], [ 3.8, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4701,
    "totalWeightG": 5399,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 207.4,
    "burnTimeS": 1.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H200",
    "dataFiles": 0,
    "delays": "13",
    "designation": "H200S",
    "diameter": 24,
    "impulseClass": "H",
    "length": 383,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000346",
    "propInfo": "Slow",
    "propWeightG": 117.8,
    "totImpulseNs": 247.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 381.7,
    "burnTimeS": 0.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H365",
    "dataFiles": 0,
    "delays": "15",
    "designation": "H365F",
    "diameter": 24,
    "impulseClass": "H",
    "length": 383,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000347",
    "propInfo": "Fast",
    "propWeightG": 122.3,
    "totImpulseNs": 259.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 511.2,
    "burnTimeS": 0.57,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H470",
    "dataFiles": 0,
    "designation": "H470F",
    "diameter": 24,
    "impulseClass": "H",
    "length": 489,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000348",
    "propInfo": "Fast",
    "propWeightG": 151,
    "totImpulseNs": 293.7,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 171.9,
    "burnTimeS": 0.97,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H155",
    "dataFiles": 1,
    "delays": "7",
    "designation": "H155F",
    "diameter": 29,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000349",
    "propInfo": "Fast",
    "propWeightG": 80,
    "samples": [
      [ 0, 0 ], [ 0.005, 39.95 ], [ 0.009, 78.25 ], [ 0.025, 121.8 ], [ 0.032, 123.9 ], [ 0.057, 123.4 ],
      [ 0.081, 123.9 ], [ 0.121, 124.2 ], [ 0.172, 129.4 ], [ 0.201, 131.9 ], [ 0.296, 135.4 ],
      [ 0.337, 137.9 ], [ 0.397, 138.7 ], [ 0.436, 134.6 ], [ 0.493, 139.3 ], [ 0.552, 135.4 ],
      [ 0.599, 139 ], [ 0.691, 137.6 ], [ 0.796, 135.4 ], [ 0.888, 133 ], [ 0.935, 129.1 ],
      [ 0.971, 130.8 ], [ 0.991, 128.6 ], [ 1.015, 112.2 ], [ 1.057, 89.2 ], [ 1.093, 67.31 ],
      [ 1.152, 44.6 ], [ 1.192, 32.56 ], [ 1.236, 21.34 ], [ 1.264, 16.42 ], [ 1.291, 12.59 ],
      [ 1.312, 10.12 ], [ 1.352, 5.746 ], [ 1.393, 2.736 ], [ 1.465, 0 ]
],
    "totImpulseNs": 160.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 71.6,
    "burnTimeS": 1.9,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G65",
    "dataFiles": 1,
    "delays": "11",
    "designation": "G65DH",
    "diameter": 29,
    "impulseClass": "G",
    "length": 206,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034a",
    "propInfo": "Dirty Harry",
    "propWeightG": 93.6,
    "samples": [
      [ 0, 0 ], [ 0.028, 31.8 ], [ 0.049, 70.29 ], [ 0.082, 58.74 ], [ 0.142, 64.41 ], [ 0.716, 79.61 ],
      [ 1.179, 88.72 ], [ 1.568, 75.35 ], [ 1.606, 74.34 ], [ 1.611, 68.06 ], [ 1.704, 57.12 ],
      [ 1.773, 45.98 ], [ 1.812, 30.18 ], [ 1.853, 18.84 ], [ 1.894, 11.34 ], [ 1.936, 5.469 ],
      [ 2.026, 0 ]
],
    "totImpulseNs": 136.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 116.1,
    "burnTimeS": 1.87,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H105",
    "dataFiles": 0,
    "designation": "H105DH",
    "diameter": 29,
    "impulseClass": "H",
    "length": 289,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034b",
    "propInfo": "Dirty Harry",
    "propWeightG": 143.5,
    "totImpulseNs": 216.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 260.5,
    "burnTimeS": 1.03,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H255",
    "dataFiles": 0,
    "delays": "9",
    "designation": "H255F",
    "diameter": 29,
    "impulseClass": "H",
    "length": 292,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034c",
    "propInfo": "Fast",
    "propWeightG": 125.3,
    "totImpulseNs": 266,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 438.5,
    "burnTimeS": 0.77,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I385",
    "dataFiles": 0,
    "delays": "12",
    "designation": "I385F",
    "diameter": 29,
    "impulseClass": "I",
    "length": 384,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034d",
    "propInfo": "Fast",
    "propWeightG": 154,
    "totImpulseNs": 326.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 550.9,
    "burnTimeS": 0.73,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I560",
    "dataFiles": 1,
    "delays": "20",
    "designation": "I560F",
    "diameter": 29,
    "impulseClass": "I",
    "length": 597,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034e",
    "propInfo": "Fast",
    "propWeightG": 246.1,
    "samples": [
      [ 0, 0 ], [ 0.01, 589.5 ], [ 0.05, 867.3 ], [ 0.08, 850.4 ], [ 0.11, 858.5 ], [ 0.14, 870.5 ],
      [ 0.17, 884.5 ], [ 0.2, 899.9 ], [ 0.23, 917.5 ], [ 0.27, 932.4 ], [ 0.3, 948.2 ], [ 0.33, 963.3 ],
      [ 0.36, 973.9 ], [ 0.39, 943 ], [ 0.42, 678.7 ], [ 0.45, 237.1 ], [ 0.48, 221.4 ], [ 0.52, 212.5 ],
      [ 0.55, 202.7 ], [ 0.58, 200 ], [ 0.61, 188.2 ], [ 0.64, 145.2 ], [ 0.68, 96.66 ], [ 0.71, 60.02 ],
      [ 0.74, 32.13 ], [ 0.77, 10.5 ], [ 0.8, 0 ]
],
    "totImpulseNs": 404.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 318.8,
    "burnTimeS": 1.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I300",
    "dataFiles": 0,
    "designation": "I300F",
    "diameter": 38,
    "impulseClass": "I",
    "length": 254,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000034f",
    "propInfo": "Fast",
    "propWeightG": 181.2,
    "totImpulseNs": 390.7,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 167.3,
    "burnTimeS": 2.59,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I170",
    "dataFiles": 0,
    "delays": "11",
    "designation": "I170DH",
    "diameter": 38,
    "impulseClass": "I",
    "length": 314,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000350",
    "propInfo": "Dirty Harry",
    "propWeightG": 287,
    "totImpulseNs": 433.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 224.3,
    "burnTimeS": 2.4,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I220",
    "dataFiles": 0,
    "delays": "9",
    "designation": "I220S",
    "diameter": 38,
    "impulseClass": "I",
    "length": 314,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000351",
    "propInfo": "Slow",
    "propWeightG": 253.7,
    "totImpulseNs": 534.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 437.7,
    "burnTimeS": 1.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I420",
    "dataFiles": 0,
    "delays": "12",
    "designation": "I420F",
    "diameter": 38,
    "impulseClass": "I",
    "length": 314,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000352",
    "propInfo": "Fast",
    "propWeightG": 244.6,
    "totImpulseNs": 532.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 296.5,
    "burnTimeS": 2.27,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J280",
    "dataFiles": 0,
    "delays": "11",
    "designation": "J280S",
    "diameter": 38,
    "impulseClass": "J",
    "length": 368,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000353",
    "propInfo": "Slow",
    "propWeightG": 314.1,
    "totImpulseNs": 671.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 273.4,
    "burnTimeS": 2.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I255",
    "dataFiles": 0,
    "delays": "10",
    "designation": "I255DH",
    "diameter": 38,
    "impulseClass": "I",
    "length": 368,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000354",
    "propInfo": "Dirty Harry",
    "propWeightG": 353.3,
    "totImpulseNs": 602.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 619.4,
    "burnTimeS": 1.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J450",
    "dataFiles": 0,
    "delays": "10",
    "designation": "J450F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 368,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000355",
    "propInfo": "Fast",
    "propWeightG": 308,
    "totImpulseNs": 673.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 361.8,
    "burnTimeS": 2.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J345",
    "dataFiles": 0,
    "delays": "18",
    "designation": "J345S",
    "diameter": 38,
    "impulseClass": "J",
    "length": 476,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000356",
    "propInfo": "Slow",
    "propWeightG": 380.5,
    "totImpulseNs": 761.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 659.5,
    "burnTimeS": 1.17,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J630",
    "dataFiles": 0,
    "delays": "20",
    "designation": "J630F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 476,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000357",
    "propInfo": "Fast",
    "propWeightG": 374.5,
    "totImpulseNs": 787.4,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 710.5,
    "burnTimeS": 1.17,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J675",
    "dataFiles": 0,
    "delays": "20",
    "designation": "J675F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 476,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000358",
    "propInfo": "Fast",
    "propWeightG": 386.6,
    "totImpulseNs": 814.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 883.8,
    "burnTimeS": 1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J850",
    "dataFiles": 0,
    "delays": "21",
    "designation": "J850F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 576,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000359",
    "propInfo": "Fast",
    "propWeightG": 450,
    "totImpulseNs": 899.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 549.4,
    "burnTimeS": 1.8,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J530",
    "dataFiles": 0,
    "delays": "10",
    "designation": "J530S",
    "diameter": 38,
    "impulseClass": "J",
    "length": 737,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035a",
    "propInfo": "Slow",
    "propWeightG": 561.7,
    "totImpulseNs": 991.7,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1019,
    "burnTimeS": 1.03,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J975",
    "dataFiles": 0,
    "delays": "23,P",
    "designation": "J975F",
    "diameter": 38,
    "impulseClass": "J",
    "length": 740,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035b",
    "propInfo": "Fast",
    "propWeightG": 552.7,
    "totImpulseNs": 1033,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 432.8,
    "burnTimeS": 1.88,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J340",
    "dataFiles": 0,
    "delays": "10",
    "designation": "J340F",
    "diameter": 54,
    "impulseClass": "J",
    "length": 251,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035c",
    "propInfo": "Fast",
    "propWeightG": 361.3,
    "totImpulseNs": 787.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 193.4,
    "burnTimeS": 4.57,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J180",
    "dataFiles": 0,
    "delays": "13",
    "designation": "J180DH",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035d",
    "propInfo": "Dirty Harry",
    "propWeightG": 605.5,
    "totImpulseNs": 884.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 523.2,
    "burnTimeS": 2.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J480",
    "dataFiles": 0,
    "delays": "17",
    "designation": "J480F",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035e",
    "propInfo": "Fast",
    "propWeightG": 522.5,
    "totImpulseNs": 1144,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 306,
    "burnTimeS": 4.03,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J295",
    "dataFiles": 0,
    "delays": "17",
    "designation": "J295DH",
    "diameter": 54,
    "impulseClass": "J",
    "length": 403,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000035f",
    "propInfo": "Dirty Harry",
    "propWeightG": 792.8,
    "totImpulseNs": 1243,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 950.7,
    "burnTimeS": 2.8,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L850",
    "dataFiles": 0,
    "delays": "12",
    "designation": "L850S",
    "diameter": 54,
    "impulseClass": "L",
    "length": 727,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000360",
    "propInfo": "Slow",
    "propWeightG": 1265,
    "totImpulseNs": 2643,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2018,
    "burnTimeS": 1.3,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1860",
    "dataFiles": 0,
    "delays": "23",
    "designation": "L1860F",
    "diameter": 54,
    "impulseClass": "L",
    "length": 727,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000361",
    "propInfo": "Fast",
    "propWeightG": 1255,
    "totImpulseNs": 2643,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 161.8,
    "burnTimeS": 6.9,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J130",
    "dataFiles": 0,
    "delays": "15",
    "designation": "J130S",
    "diameter": 76,
    "impulseClass": "J",
    "length": 244,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000362",
    "propInfo": "Slow",
    "propWeightG": 610,
    "totImpulseNs": 1118,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 274.4,
    "burnTimeS": 4.27,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J250",
    "dataFiles": 0,
    "delays": "7",
    "designation": "J250F",
    "diameter": 76,
    "impulseClass": "J",
    "length": 244,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000363",
    "propInfo": "Fast",
    "propWeightG": 596.5,
    "totImpulseNs": 1167,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 424.5,
    "burnTimeS": 5.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K410",
    "dataFiles": 0,
    "delays": "13",
    "designation": "K410S",
    "diameter": 76,
    "impulseClass": "K",
    "length": 368,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000364",
    "propInfo": "Slow",
    "propWeightG": 1196,
    "totImpulseNs": 2420,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 809.3,
    "burnTimeS": 3.13,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K777",
    "dataFiles": 0,
    "delays": "10",
    "designation": "K777F",
    "diameter": 76,
    "impulseClass": "K",
    "length": 368,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000365",
    "propInfo": "Fast",
    "propWeightG": 1169,
    "totImpulseNs": 2525,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 608.6,
    "burnTimeS": 5.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L585",
    "dataFiles": 0,
    "delays": "8",
    "designation": "L585DH",
    "diameter": 76,
    "impulseClass": "L",
    "length": 495,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000366",
    "propInfo": "Dirty Harry",
    "propWeightG": 1930,
    "totImpulseNs": 3094,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 643.6,
    "burnTimeS": 5.33,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L630",
    "dataFiles": 0,
    "delays": "7",
    "designation": "L630S",
    "diameter": 76,
    "impulseClass": "L",
    "length": 495,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000367",
    "propInfo": "Slow",
    "propWeightG": 1661,
    "totImpulseNs": 3430,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1205,
    "burnTimeS": 2.97,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1175",
    "dataFiles": 0,
    "delays": "30",
    "designation": "L1175F",
    "diameter": 76,
    "impulseClass": "L",
    "length": 495,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000368",
    "propInfo": "Fast",
    "propWeightG": 1667,
    "totImpulseNs": 3567,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1548,
    "burnTimeS": 3.07,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1500",
    "dataFiles": 0,
    "delays": "11",
    "designation": "L1500F",
    "diameter": 76,
    "impulseClass": "L",
    "length": 622,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d20002310000000369",
    "propInfo": "Fast",
    "propWeightG": 2223,
    "totImpulseNs": 4731,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 845.2,
    "burnTimeS": 5.27,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L820",
    "dataFiles": 0,
    "delays": "15",
    "designation": "L820S",
    "diameter": 76,
    "impulseClass": "L",
    "length": 623,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036a",
    "propInfo": "Slow",
    "propWeightG": 2223,
    "totImpulseNs": 4470,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1170,
    "burnTimeS": 4.9,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1130",
    "dataFiles": 0,
    "designation": "M1130S",
    "diameter": 76,
    "impulseClass": "M",
    "length": 775,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036b",
    "propInfo": "Slow",
    "propWeightG": 3069,
    "totImpulseNs": 5764,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2350,
    "burnTimeS": 2.55,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2240",
    "dataFiles": 0,
    "designation": "M2240F",
    "diameter": 76,
    "impulseClass": "M",
    "length": 775,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036c",
    "propInfo": "Fast",
    "propWeightG": 3058,
    "totImpulseNs": 5947,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1059,
    "burnTimeS": 5,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1015",
    "dataFiles": 0,
    "designation": "M1015DH",
    "diameter": 76,
    "impulseClass": "M",
    "length": 781,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036d",
    "propInfo": "Dirty Harry",
    "propWeightG": 3547,
    "totImpulseNs": 5283,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1952,
    "burnTimeS": 3.83,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1845",
    "dataFiles": 0,
    "designation": "M1845S",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1038,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036e",
    "propInfo": "Slow",
    "propWeightG": 3882,
    "totImpulseNs": 7513,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3202,
    "burnTimeS": 2.35,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M3200",
    "dataFiles": 0,
    "designation": "M3200F",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1041,
    "manufacturer": "Kosdon TRM",
    "manufacturerAbbrev": "Kosdon",
    "motorId": "5f4294d2000231000000036f",
    "propInfo": "Fast",
    "propWeightG": 3896,
    "totImpulseNs": 7566,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.6,
    "burnTimeS": 2.97,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G54",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "159G54-12A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 119.2,
    "motorId": "5f4294d20002310000000370",
    "propInfo": "Red Lightning",
    "propWeightG": 86.5,
    "samples": [
      [ 0, 0 ], [ 0.018, 107.3 ], [ 0.031, 113.6 ], [ 0.059, 103.5 ], [ 0.135, 121.7 ], [ 0.22, 104.6 ],
      [ 0.299, 95.53 ], [ 0.432, 88.31 ], [ 0.959, 69.66 ], [ 1.757, 43.48 ], [ 2.418, 20.76 ],
      [ 2.851, 9.478 ], [ 3.013, 5.567 ], [ 3.026, 0 ]
],
    "totImpulseNs": 159.1,
    "totalWeightG": 192.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 158.9,
    "burnTimeS": 1.88,
    "caseInfo": "Pro29-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H159",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "298H159-15A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 320,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 199.7,
    "motorId": "5f4294d20002310000000371",
    "propInfo": "Green3",
    "propWeightG": 176.6,
    "samples": [
      [ 0, 0 ], [ 0.012, 117.3 ], [ 0.032, 158.6 ], [ 0.103, 177.8 ], [ 0.171, 184.9 ], [ 0.299, 185.4 ],
      [ 0.511, 178.1 ], [ 0.717, 179.3 ], [ 1.272, 162.9 ], [ 1.424, 159.6 ], [ 1.519, 152.6 ],
      [ 1.584, 149.8 ], [ 1.632, 146.3 ], [ 1.727, 89.28 ], [ 1.768, 110 ], [ 1.834, 25.98 ],
      [ 1.865, 10.59 ], [ 1.887, 0 ]
],
    "totImpulseNs": 298.2,
    "totalWeightG": 342.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 485.5,
    "burnTimeS": 4.08,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K490",
    "dataFiles": 2,
    "delays": "6,7,8,9,10,11,12,13,14,15,16",
    "designation": "1990K490-16A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 590.9,
    "motorId": "5f4294d20002310000000372",
    "propInfo": "Green3",
    "propWeightG": 1155,
    "samples": [
      [ 0, 0 ], [ 0.013, 445.8 ], [ 0.024, 376.7 ], [ 0.083, 559.2 ], [ 0.121, 584 ], [ 0.316, 573.4 ],
      [ 0.727, 569.9 ], [ 1.116, 553.9 ], [ 1.875, 522 ], [ 1.893, 593.8 ], [ 1.944, 519.4 ],
      [ 2.658, 479.5 ], [ 3.063, 473.3 ], [ 3.358, 444 ], [ 3.618, 448.4 ], [ 3.894, 240.2 ],
      [ 4.055, 31.02 ], [ 4.066, 0 ]
],
    "totImpulseNs": 1978,
    "totalWeightG": 1854,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3710,
    "burnTimeS": 1.83,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M3700",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6800M3700-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 803,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 4158,
    "motorId": "5f4294d20002310000000373",
    "propInfo": "White Thunder",
    "propWeightG": 3019,
    "samples": [
      [ 0, 0 ], [ 0.017, 3816 ], [ 0.04, 3970 ], [ 0.099, 3914 ], [ 0.245, 3958 ], [ 0.563, 3951 ],
      [ 0.727, 4105 ], [ 0.959, 4032 ], [ 1.25, 3982 ], [ 1.511, 3995 ], [ 1.595, 3995 ],[ 1.739, 1307 ],
      [ 1.772, 1110 ], [ 1.83, 221.9 ], [ 1.835, 0 ]
],
    "totImpulseNs": 6800,
    "totalWeightG": 5779,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2075,
    "burnTimeS": 3.03,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2075",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6287M2075-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3453,
    "motorId": "5f4294d20002310000000374",
    "propInfo": "Smoky Sam",
    "propWeightG": 3982,
    "samples": [
      [ 0, 0 ], [ 0.034, 2351 ], [ 0.18, 2652 ], [ 0.243, 2932 ], [ 0.303, 2734 ], [ 0.453, 2625 ],
      [ 0.552, 2564 ], [ 0.813, 2488 ], [ 1.172, 2499 ], [ 2.028, 2038 ], [ 2.415, 1611 ],
      [ 2.567, 1507 ], [ 2.69, 1386 ], [ 3.01, 224.7 ], [ 3.029, 0 ]
],
    "totImpulseNs": 6287,
    "totalWeightG": 7191,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 8.44,
    "burnTimeS": 2.2,
    "certOrg": "National Association of Rocketry",
    "commonName": "D8",
    "dataFiles": 1,
    "delays": "0,3,5",
    "designation": "D8",
    "diameter": 24,
    "impulseClass": "D",
    "length": 70,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 28.88,
    "motorId": "5f4294d20002310000000375",
    "propInfo": "black powder",
    "propWeightG": 22,
    "samples": [
      [ 0, 0 ], [ 0.002, 1.014 ], [ 0.01, 1.565 ], [ 0.13, 5.178 ], [ 0.217, 8.911 ], [ 0.298, 14.81 ],
      [ 0.351, 20.23 ], [ 0.414, 25.29 ], [ 0.442, 28.88 ], [ 0.47, 23.84 ], [ 0.487, 17.46 ],
      [ 0.508, 13.37 ], [ 0.537, 10.96 ], [ 0.6, 9.152 ], [ 0.673, 8.791 ], [ 0.828, 8.309 ],
      [ 0.933, 8.068 ], [ 1.136, 7.105 ], [ 1.35, 6.743 ], [ 1.48, 6.623 ], [ 1.602, 6.502 ],
      [ 1.648, 7.105 ], [ 1.683, 6.382 ], [ 1.743, 6.262 ], [ 1.865, 6.141 ], [ 1.974, 6.141 ],
      [ 2.104, 6.262 ], [ 2.132, 5.419 ], [ 2.17, 3.492 ], [ 2.191, 1.926 ], [ 2.2, 0 ]
],
    "totImpulseNs": 18.59,
    "totalWeightG": 45.6,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.82,
    "burnTimeS": 4.61,
    "certOrg": "National Association of Rocketry",
    "commonName": "D5",
    "dataFiles": 3,
    "delays": "4,6",
    "designation": "D5",
    "diameter": 20,
    "impulseClass": "D",
    "length": 96,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 16.8,
    "motorId": "5f4294d20002310000000376",
    "propInfo": "black powder",
    "propWeightG": 24,
    "samples": [
      [ 0, 0 ], [ 0.01, 1.014 ], [ 0.122, 2.652 ], [ 0.172, 4.836 ], [ 0.273, 7.723 ], [ 0.331, 10.61 ],
      [ 0.39, 13.81 ], [ 0.448, 16.8 ], [ 0.48, 12.4 ], [ 0.484, 8.266 ], [ 0.517, 5.221 ],
      [ 0.713, 4.125 ], [ 0.974, 4.121 ], [ 1.135, 3.338 ], [ 1.324, 3.101 ], [ 1.55, 3.254 ],
      [ 1.971, 3.169 ], [ 2.393, 3.162 ], [ 2.752, 3.391 ], [ 3.069, 3.386 ], [ 3.547, 3.301 ],
      [ 3.694, 3.064 ], [ 3.948, 3.294 ], [ 4.215, 3.29 ], [ 4.412, 3.287 ], [ 4.496, 2.505 ],
      [ 4.586, 1.801 ], [ 4.61, 0 ]
],
    "totImpulseNs": 17.61,
    "totalWeightG": 44.1,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1066,
    "burnTimeS": 2.35,
    "caseInfo": "RMS-75/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K1000T",
    "diameter": 75,
    "impulseClass": "K",
    "length": 382.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1674,
    "motorId": "5f4294d20002310000000377",
    "propInfo": "Blue Thunder",
    "propWeightG": 1234,
    "samples": [
      [ 0, 0 ], [ 0.004, 895.1 ], [ 0.015, 1120 ], [ 0.025, 1093 ], [ 0.095, 1097 ], [ 0.2, 1110 ],
      [ 0.3, 1116 ], [ 0.4, 1123 ], [ 0.5, 1133 ], [ 0.6, 1140 ], [ 0.7, 1136 ], [ 0.8, 1136 ],
      [ 0.9, 1136 ], [ 1, 1140 ], [ 1.1, 1133 ], [ 1.2, 1130 ], [ 1.3, 1126 ], [ 1.4, 1120 ],
      [ 1.5, 1110 ], [ 1.6, 1097 ], [ 1.7, 1064 ], [ 1.8, 1017 ], [ 1.9, 971.1 ], [ 2, 915 ],
      [ 2.1, 868.7 ], [ 2.18, 865.4 ], [ 2.2, 878.6 ], [ 2.218, 858.8 ], [ 2.269, 670.5 ],
      [ 2.3, 578 ], [ 2.332, 445.9 ], [ 2.356, 336.9 ], [ 2.389, 224.6 ], [ 2.436, 105.7 ],
      [ 2.5, 0 ]
],
    "totImpulseNs": 2512,
    "totalWeightG": 2602,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 123.4,
    "burnTimeS": 0.45,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F120",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "56F120-14A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 143.6,
    "motorId": "5f4294d20002310000000378",
    "propInfo": "Vmax",
    "propWeightG": 25.3,
    "samples": [
      [ 0, 0 ], [ 0.013, 79.24 ], [ 0.017, 90.43 ], [ 0.04, 101.4 ], [ 0.125, 127.6 ], [ 0.179, 136.1 ],
      [ 0.222, 139.9 ], [ 0.289, 143.5 ], [ 0.354, 138.6 ], [ 0.394, 125.5 ], [ 0.406, 123.6 ],
      [ 0.416, 125.1 ], [ 0.423, 130 ], [ 0.431, 120.6 ], [ 0.447, 25.59 ], [ 0.453, 8.72 ],
      [ 0.455, 0 ]
],
    "totImpulseNs": 56,
    "totalWeightG": 106.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 243.7,
    "burnTimeS": 0.45,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G250",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "110G250-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 288.3,
    "motorId": "5f4294d20002310000000379",
    "propInfo": "Vmax",
    "propWeightG": 50.6,
    "samples": [
      [ 0, 0 ], [ 0.006, 151.6 ], [ 0.011, 198.1 ], [ 0.016, 203.1 ], [ 0.031, 201.7 ], [ 0.075, 226.2 ],
      [ 0.122, 250.3 ], [ 0.216, 280.2 ], [ 0.25, 287 ], [ 0.287, 284.9 ], [ 0.354, 269.7 ],
      [ 0.374, 258.6 ], [ 0.4, 233.4 ], [ 0.413, 234.1 ], [ 0.42, 227.6 ], [ 0.433, 137.9 ],
      [ 0.445, 33.85 ], [ 0.454, 0 ]
],
    "totImpulseNs": 110,
    "totalWeightG": 141.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 409,
    "burnTimeS": 0.41,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H410",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "168H410-14A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 522.8,
    "motorId": "5f4294d2000231000000037a",
    "propInfo": "Vmax",
    "propWeightG": 75.9,
    "samples": [
      [ 0, 0 ], [ 0.004, 152.8 ], [ 0.009, 341.4 ], [ 0.013, 368 ], [ 0.019, 368 ], [ 0.046, 385.9 ],
      [ 0.074, 416.5 ], [ 0.143, 471.6 ], [ 0.21, 517.5 ], [ 0.253, 500.8 ], [ 0.287, 460.3 ],
      [ 0.338, 383.3 ], [ 0.357, 343.4 ], [ 0.361, 293.6 ], [ 0.369, 263 ], [ 0.378, 253.7 ],
      [ 0.4, 62.44 ], [ 0.414, 0 ]
],
    "totImpulseNs": 167.7,
    "totalWeightG": 182.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 137.1,
    "burnTimeS": 1.89,
    "caseInfo": "Pro54-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I140",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,10,11,12,13,14",
    "designation": "396I140-14A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 143,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 164,
    "motorId": "5f4294d2000231000000037b",
    "propInfo": "Skidmark",
    "propWeightG": 210.4,
    "samples": [
      [ 0, 0 ], [ 0.008, 81.3 ], [ 0.02, 137.2 ], [ 0.043, 157.2 ], [ 0.065, 121.1 ], [ 0.16, 136.1 ],
      [ 0.353, 150.7 ], [ 0.54, 156.3 ], [ 0.702, 160.2 ], [ 0.976, 157 ], [ 1.298, 151.3 ],
      [ 1.619, 146.1 ], [ 2.057, 136.3 ], [ 2.489, 121.5 ], [ 2.604, 119.3 ], [ 2.671, 122.2 ],
      [ 2.708, 111.7 ], [ 2.779, 61.3 ], [ 2.84, 25.22 ], [ 2.901, 8.478 ], [ 2.902, 0 ]
],
    "sparky": true,
    "totImpulseNs": 395.6,
    "totalWeightG": 564.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 398.6,
    "burnTimeS": 4.01,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K400",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,10,11,12,13,14",
    "designation": "1597K400-14A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 484.5,
    "motorId": "5f4294d2000231000000037c",
    "propInfo": "Green3",
    "propWeightG": 924.3,
    "samples": [
      [ 0, 0 ], [ 0.014, 359.2 ], [ 0.102, 475.4 ], [ 1.193, 444.6 ], [ 2.807, 385 ], [ 3.364, 370.2 ],
      [ 3.599, 363.5 ], [ 3.693, 329 ], [ 3.859, 169.7 ], [ 3.967, 57.2 ], [ 4.017, 0 ]
],
    "totImpulseNs": 1597,
    "totalWeightG": 1551,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 157.9,
    "burnTimeS": 9.66,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K160",
    "dataFiles": 2,
    "delays": "6",
    "designation": "1526K160-6",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 282.5,
    "motorId": "5f4294d2000231000000037d",
    "propInfo": "Classic",
    "propWeightG": 772,
    "samples": [
      [ 0, 0 ], [ 0.027, 160.2 ], [ 0.08, 242 ], [ 0.321, 260.7 ], [ 0.455, 259.6 ], [ 0.957, 270.5 ],
      [ 1.593, 272.2 ], [ 2.102, 265.2 ], [ 2.564, 254.3 ], [ 2.925, 239.6 ], [ 3.956, 190 ],
      [ 5.301, 138.8 ], [ 7.617, 67.8 ], [ 9.19, 25.64 ], [ 9.572, 15.81 ], [ 9.679, 0 ]
],
    "totImpulseNs": 1526,
    "totalWeightG": 1472,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 263.3,
    "burnTimeS": 8.68,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K260",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2285K260-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 438.1,
    "motorId": "5f4294d2000231000000037e",
    "propInfo": "Classic",
    "propWeightG": 1149,
    "samples": [
      [ 0, 0 ], [ 0.042, 325.7 ], [ 0.101, 430.4 ], [ 0.422, 422.8 ], [ 0.773, 426.9 ], [ 1.178, 429.8 ],
      [ 1.517, 425.7 ], [ 2.011, 413.4 ], [ 3.195, 356.7 ], [ 4.51, 289.5 ], [ 6.015, 174.3 ],
      [ 6.997, 91.23 ], [ 7.366, 66.67 ], [ 7.902, 43.27 ], [ 8.479, 25.73 ], [ 8.687, 0 ]
],
    "totImpulseNs": 2285,
    "totalWeightG": 2047,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 304,
    "burnTimeS": 8.38,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2546K300-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 561.8,
    "motorId": "5f4294d2000231000000037f",
    "propInfo": "Classic",
    "propWeightG": 1266,
    "samples": [
      [ 0, 0 ], [ 0.036, 495.3 ], [ 0.132, 543.3 ], [ 0.265, 506.9 ], [ 0.734, 493.1 ], [ 1.258, 489.5 ],
      [ 1.811, 482.9 ], [ 2.467, 453.8 ], [ 3.737, 375.3 ], [ 4.705, 299.6 ], [ 6.047, 165.1 ],
      [ 6.474, 120.7 ], [ 6.829, 90.18 ], [ 7.323, 63.27 ], [ 7.72, 46.55 ], [ 8.309, 30.55 ],
      [ 8.37, 0 ]
],
    "totImpulseNs": 2546,
    "totalWeightG": 2270,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 1975,
    "burnTimeS": 7.23,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N1975",
    "dataFiles": 2,
    "designation": "14272N1975-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2568,
    "motorId": "5f4294d20002310000000380",
    "propInfo": "Green3",
    "propWeightG": 8560,
    "samples": [
      [ 0, 0 ], [ 0.04, 1382 ], [ 0.08, 1699 ], [ 0.201, 1781 ], [ 0.447, 1794 ], [ 0.678, 1821 ],
      [ 0.758, 1928 ], [ 0.853, 1842 ], [ 2.425, 2089 ], [ 2.52, 2180 ], [ 2.761, 2199 ],[ 3.434, 2214 ],
      [ 3.715, 2269 ], [ 4.077, 2299 ], [ 4.96, 2238 ], [ 5.492, 2162 ], [ 6.105, 2025 ],[ 6.446, 1879 ],
      [ 6.647, 1669 ], [ 7.074, 602.9 ], [ 7.239, 140.1 ], [ 7.254, 0 ]
],
    "totImpulseNs": 14270,
    "totalWeightG": 13250,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 10220,
    "burnTimeS": 1.01,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N10000",
    "dataFiles": 2,
    "designation": "10347N10000-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 11560,
    "motorId": "5f4294d20002310000000381",
    "propInfo": "Vmax",
    "propWeightG": 5200,
    "samples": [
      [ 0, 0 ], [ 0.009, 8954 ], [ 0.027, 10260 ], [ 0.074, 10750 ], [ 0.125, 11040 ], [ 0.212, 11110 ],
      [ 0.358, 11280 ], [ 0.457, 11480 ], [ 0.548, 11460 ], [ 0.599, 11280 ], [ 0.663, 10870 ],
      [ 0.766, 9988 ], [ 0.837, 9351 ], [ 0.888, 8996 ], [ 0.908, 8968 ], [ 0.923, 8586 ],
      [ 0.942, 7821 ], [ 0.961, 6276 ], [ 0.976, 4491 ], [ 0.988, 3358 ], [ 1.004, 1658 ],
      [ 1.008, 0 ]
],
    "totImpulseNs": 10350,
    "totalWeightG": 9919,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 430.6,
    "burnTimeS": 5.78,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K375",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K375NW",
    "diameter": 54,
    "impulseClass": "K",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1372,
    "motorId": "5f4294d20002310000000382",
    "propInfo": "White Lightning/Warp 9",
    "propWeightG": 1318,
    "samples": [
      [ 0, 0 ], [ 0.027, 887 ], [ 0.036, 1115 ], [ 0.054, 1269 ], [ 0.135, 1280 ], [ 0.197, 1314 ],
      [ 0.292, 1317 ], [ 0.422, 1344 ], [ 0.444, 1302 ], [ 0.489, 1336 ], [ 0.552, 452.8 ],
      [ 0.574, 396.7 ], [ 0.718, 452.8 ], [ 0.794, 460.3 ], [ 0.902, 437.9 ], [ 0.992, 411.7 ],
      [ 1.189, 404.2 ], [ 1.4, 366.8 ], [ 1.575, 381.7 ], [ 1.791, 370.5 ], [ 4, 366.8 ],[ 4.165, 344.3 ],
      [ 4.29, 239.5 ], [ 4.398, 239.5 ], [ 4.537, 183.4 ], [ 4.645, 183.4 ], [ 4.761, 93.56 ],
      [ 5, 93.56 ], [ 5.2, 63.62 ], [ 5.4, 44.91 ], [ 5.6, 33.68 ], [ 5.8, 0 ]
],
    "totImpulseNs": 2228,
    "totalWeightG": 2106,
    "type": "reload",
    "updatedOn": "2020-11-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 545.3,
    "burnTimeS": 4.26,
    "caseInfo": "RMS-54/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K480",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K480W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 579,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1018,
    "motorId": "5f4294d20002310000000383",
    "propInfo": "White Lightning",
    "propWeightG": 1292,
    "samples": [
      [ 0, 0 ], [ 0.03, 535.7 ], [ 0.045, 860.3 ], [ 0.057, 916 ], [ 0.098, 830.2 ], [ 0.159, 832.5 ],
      [ 0.246, 795.4 ], [ 0.307, 811.6 ], [ 0.398, 793.1 ], [ 0.492, 809.3 ], [ 0.557, 823.2 ],
      [ 0.621, 811.6 ], [ 0.689, 779.2 ], [ 0.735, 795.4 ], [ 0.845, 767.6 ], [ 0.989, 718.9 ],
      [ 1.091, 707.3 ], [ 1.25, 684.1 ], [ 1.307, 667.9 ], [ 1.5, 654 ], [ 1.606, 656.3 ],
      [ 1.742, 651.6 ], [ 1.909, 628.4 ], [ 2, 605.3 ], [ 2.25, 586.7 ], [ 2.5, 565.8 ], [ 2.75, 547.3 ],
      [ 2.886, 545 ], [ 3, 524.1 ], [ 3.064, 491.6 ], [ 3.144, 417.4 ], [ 3.25, 394.2 ], [ 3.292, 338.6 ],
      [ 3.451, 271.3 ], [ 3.5, 231.9 ], [ 3.625, 178.6 ], [ 3.7, 143.8 ], [ 3.871, 132.2 ],
      [ 4, 88.12 ], [ 4.133, 41.74 ], [ 4.246, 23.19 ], [ 4.5, 18.55 ], [ 4.8, 0 ]
],
    "totImpulseNs": 2273,
    "totalWeightG": 2078,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 411.9,
    "burnTimeS": 4.33,
    "caseInfo": "75/2500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K411",
    "dataFiles": 0,
    "designation": "K411BL",
    "diameter": 75,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 596.2,
    "motorId": "5f4294d20002310000000384",
    "propInfo": "Black Lightning",
    "propWeightG": 1132,
    "sparky": true,
    "totImpulseNs": 1786,
    "totalWeightG": 2656,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 670,
    "burnTimeS": 3.52,
    "caseInfo": "75/2500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K670",
    "dataFiles": 0,
    "designation": "K670WC",
    "diameter": 75,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 859.1,
    "motorId": "5f4294d20002310000000385",
    "propInfo": "White Cloud",
    "propWeightG": 1184,
    "totImpulseNs": 2365,
    "totalWeightG": 2706,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 74.74,
    "burnTimeS": 1.61,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G75",
    "dataFiles": 1,
    "delays": "4,6,7,8,10",
    "designation": "HP-G75M",
    "diameter": 29,
    "impulseClass": "G",
    "length": 123.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 102,
    "motorId": "5f4294d20002310000000386",
    "propInfo": "Metalstorm",
    "propWeightG": 66.8,
    "samples": [
      [ 0, 0 ], [ 0.01, 81.49 ], [ 0.013, 94.73 ], [ 0.021, 101.5 ], [ 0.037, 87.96 ], [ 0.068, 86.2 ],
      [ 0.154, 87.67 ], [ 0.188, 90.91 ], [ 0.211, 90.02 ], [ 0.31, 95.61 ], [ 0.357, 91.49 ],
      [ 0.464, 93.85 ], [ 0.696, 92.08 ], [ 0.879, 84.73 ], [ 0.907, 85.61 ], [ 0.92, 92.97 ],
      [ 0.939, 77.67 ], [ 1.014, 72.96 ], [ 1.027, 78.55 ], [ 1.053, 71.49 ], [ 1.171, 64.13 ],
      [ 1.304, 52.37 ], [ 1.312, 56.78 ], [ 1.325, 52.66 ], [ 1.374, 48.54 ], [ 1.411, 44.13 ],
      [ 1.442, 36.19 ], [ 1.492, 22.36 ], [ 1.538, 14.12 ], [ 1.585, 7.649 ], [ 1.643, 3.825 ],
      [ 1.7, 2.648 ], [ 1.802, 1.471 ], [ 1.971, 0 ]
],
    "sparky": true,
    "totImpulseNs": 120.4,
    "totalWeightG": 131,
    "type": "SU",
    "updatedOn": "2020-05-01"
  },
  {
    "availability": "regular",
    "avgThrustN": 166.8,
    "burnTimeS": 1.91,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H170",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "H170M",
    "diameter": 38,
    "impulseClass": "H",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 208,
    "motorId": "5f4294d20002310000000387",
    "propInfo": "Metalstorm",
    "propWeightG": 182.5,
    "samples": [
      [ 0, 0 ], [ 0.017, 182.5 ], [ 0.032, 199.6 ], [ 0.104, 196 ], [ 0.205, 180.9 ], [ 0.292, 188.7 ],
      [ 0.314, 198.6 ], [ 0.324, 187.2 ], [ 0.364, 180.9 ], [ 0.423, 192.9 ], [ 0.458, 189.2 ],
      [ 0.574, 197.5 ], [ 0.616, 205.8 ], [ 0.663, 190.3 ], [ 0.71, 190.3 ], [ 0.735, 197.5 ],
      [ 0.876, 188.7 ], [ 0.963, 193.9 ], [ 1.22, 176.8 ], [ 1.245, 185.1 ], [ 1.279, 172.1 ],
      [ 1.46, 152.4 ], [ 1.47, 159.7 ], [ 1.515, 147.2 ], [ 1.591, 136.3 ], [ 1.7, 131.2 ],
      [ 1.73, 114.6 ], [ 1.782, 67.4 ], [ 1.824, 39.4 ], [ 1.886, 18.14 ], [ 1.93, 8.813 ],
      [ 2, 3.111 ], [ 2.138, 0 ]
],
    "sparky": true,
    "totImpulseNs": 319.9,
    "totalWeightG": 330,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 354.1,
    "burnTimeS": 1.836,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J340",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "J340M",
    "diameter": 38,
    "impulseClass": "J",
    "length": 337,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 605,
    "motorId": "5f4294d20002310000000388",
    "propInfo": "Metalstorm",
    "propWeightG": 365,
    "samples": [
      [ 0, 0 ], [ 0.005, 361.6 ], [ 0.008, 501.7 ], [ 0.026, 606.5 ], [ 0.096, 491.4 ], [ 0.214, 453 ],
      [ 0.485, 439.8 ], [ 0.772, 435.3 ], [ 0.826, 426.5 ], [ 0.891, 423.5 ], [ 1.048, 383.7 ],
      [ 1.389, 311.4 ], [ 1.425, 308.4 ], [ 1.459, 309.9 ], [ 1.554, 183 ], [ 1.611, 103.3 ],
      [ 1.699, 57.55 ], [ 1.841, 11.81 ], [ 1.898, 8.854 ], [ 2.19, 0 ]
],
    "sparky": true,
    "totImpulseNs": 651.7,
    "totalWeightG": 577.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 557.4,
    "burnTimeS": 2.85,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K540",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "K540M",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 854,
    "motorId": "5f4294d20002310000000389",
    "propInfo": "Metalstorm",
    "propWeightG": 876.7,
    "samples": [
      [ 0, 0 ], [ 0.013, 625.7 ], [ 0.014, 791.8 ], [ 0.03, 855.5 ], [ 0.073, 764.5 ], [ 0.146, 719 ],
      [ 0.33, 712.2 ], [ 0.394, 734.9 ], [ 0.622, 705.3 ], [ 0.866, 678 ], [ 1.059, 655.3 ],
      [ 1.406, 648.5 ], [ 1.445, 596.1 ], [ 1.544, 605.2 ], [ 1.668, 582.5 ], [ 1.689, 625.7 ],
      [ 1.741, 566.6 ], [ 2.032, 498.3 ], [ 2.088, 480.1 ], [ 2.118, 511.9 ], [ 2.174, 436.9 ],
      [ 2.324, 395.9 ], [ 2.435, 357.2 ], [ 2.525, 304.9 ], [ 2.542, 329.9 ], [ 2.65, 175.2 ],
      [ 2.774, 88.74 ], [ 2.89, 36.41 ], [ 3.006, 13.65 ], [ 3.289, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1596,
    "totalWeightG": 1275,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 0.82,
    "burnTimeS": 2.09,
    "certOrg": "National Association of Rocketry",
    "commonName": "A3",
    "dataFiles": 1,
    "delays": "2,4",
    "designation": "A3T",
    "diameter": 13,
    "impulseClass": "A",
    "length": 55,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 2.79,
    "motorId": "5f4294d2000231000000038a",
    "propInfo": "black powder",
    "propWeightG": 3.6,
    "samples": [
      [ 0, 0 ], [ 0.09259, 0.4942 ], [ 0.1235, 0.9884 ], [ 0.1929, 2.733 ], [ 0.2431, 1.512 ],
      [ 0.2546, 1.294 ], [ 0.2816, 1.134 ], [ 0.3202, 1.032 ], [ 0.3704, 0.9012 ], [ 0.4012, 0.7994 ],
      [ 0.4552, 0.843 ], [ 0.5054, 0.7994 ], [ 0.7485, 0.7849 ], [ 0.841, 0.7994 ], [ 0.9144, 0.6541 ],
      [ 1.003, 0.7122 ], [ 1.254, 0.625 ], [ 1.501, 0.6977 ], [ 1.755, 0.7413 ], [ 2.002, 0.7703 ],
      [ 2.056, 0.7413 ], [ 2.076, 0.5669 ], [ 2.1, 0 ]
],
    "totImpulseNs": 1.71,
    "totalWeightG": 8.8,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 463,
    "burnTimeS": 3.89,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K350",
    "dataFiles": 1,
    "designation": "K350TR-P",
    "diameter": 64,
    "impulseClass": "K",
    "length": 908,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 853,
    "motorId": "5f4294d2000231000000038b",
    "propInfo": "Tribrid: ABS/Alcohol/Nitrous",
    "propWeightG": 1672,
    "samples": [
      [ 0, 0 ], [ 0.077, 8.027 ], [ 0.095, 596 ], [ 0.136, 282.9 ], [ 0.16, 293 ], [ 0.166, 361.2 ],
      [ 0.207, 266.9 ], [ 0.237, 289 ], [ 0.332, 289 ], [ 0.379, 274.9 ], [ 0.734, 274.9 ],
      [ 0.764, 539.8 ], [ 0.817, 461.5 ], [ 1.037, 427.4 ], [ 1.208, 465.6 ], [ 1.309, 421.4 ],
      [ 1.333, 399.3 ], [ 1.475, 441.5 ], [ 1.558, 429.4 ], [ 1.694, 499.7 ], [ 1.812, 489.6 ],
      [ 1.925, 501.7 ], [ 2.067, 481.6 ], [ 2.138, 491.6 ], [ 2.571, 457.5 ], [ 2.92, 423.4 ],
      [ 3.175, 401.3 ], [ 3.435, 395.3 ], [ 3.749, 373.2 ], [ 3.773, 218.7 ], [ 3.803, 202.7 ],
      [ 3.808, 158.5 ], [ 3.844, 202.7 ], [ 4.087, 188.6 ], [ 4.199, 104.3 ], [ 4.584, 90.3 ],
      [ 5.177, 44.15 ], [ 5.271, 46.15 ], [ 5.544, 20.07 ], [ 5.994, 0 ]
],
    "totImpulseNs": 1861,
    "totalWeightG": 11660,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 561,
    "burnTimeS": 3.67,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K600",
    "dataFiles": 0,
    "designation": "K600TR-P",
    "diameter": 64,
    "impulseClass": "K",
    "length": 908,
    "manufacturer": "R.A.T.T. Works",
    "manufacturerAbbrev": "RATT",
    "maxThrustN": 1223,
    "motorId": "5f4294d2000231000000038c",
    "propInfo": "Tribrid: ABS/Alcohol/Nitrous",
    "propWeightG": 2266,
    "totImpulseNs": 2170,
    "totalWeightG": 14410,
    "type": "hybrid",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 379.1,
    "burnTimeS": 12.24,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L400",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L400W",
    "diameter": 98,
    "impulseClass": "L",
    "length": 443.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 770.2,
    "motorId": "5f4294d2000231000000038d",
    "propInfo": "White Lightning",
    "propWeightG": 2696,
    "samples": [
      [ 0, 0 ], [ 0.031, 436.7 ], [ 0.062, 493.9 ], [ 0.063, 587 ], [ 0.108, 634.7 ], [ 0.154, 606.1 ],
      [ 0.277, 622.8 ], [ 0.4, 639.5 ], [ 0.508, 610.9 ], [ 0.538, 601.3 ], [ 0.908, 665.7 ],
      [ 1.092, 684.8 ], [ 1.631, 720.6 ], [ 1.954, 706.3 ], [ 2.385, 703.9 ], [ 2.6, 701.5 ],
      [ 2.769, 689.6 ], [ 3.2, 656.2 ], [ 3.754, 587 ], [ 4.354, 527.3 ], [ 5.262, 434.3 ],
      [ 5.815, 386.6 ], [ 6.477, 336.4 ], [ 7.354, 276.8 ], [ 8.046, 217.1 ], [ 8.846, 167 ],
      [ 9.862, 107.4 ], [ 10.97, 64.43 ], [ 11.97, 40.56 ], [ 12.97, 26.25 ], [ 13.98, 14.32 ],
      [ 14.94, 7.158 ], [ 15.46, 2.386 ], [ 15.94, 0 ]
],
    "totImpulseNs": 4642,
    "totalWeightG": 5170,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1568,
    "burnTimeS": 2.36,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1520",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1520T",
    "diameter": 75,
    "impulseClass": "L",
    "length": 517.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1765,
    "motorId": "5f4294d2000231000000038e",
    "propInfo": "Blue Thunder",
    "propWeightG": 1854,
    "samples": [
      [ 0, 0 ], [ 0.011, 1485 ], [ 0.055, 1506 ], [ 0.089, 1468 ], [ 0.155, 1561 ], [ 0.2, 1572 ],
      [ 0.3, 1582 ], [ 0.4, 1599 ], [ 0.5, 1621 ], [ 0.6, 1642 ], [ 0.7, 1653 ], [ 0.8, 1675 ],
      [ 0.9, 1691 ], [ 1, 1686 ], [ 1.1, 1697 ], [ 1.2, 1691 ], [ 1.3, 1691 ], [ 1.4, 1680 ],
      [ 1.5, 1664 ], [ 1.6, 1637 ], [ 1.7, 1621 ], [ 1.8, 1604 ], [ 1.9, 1577 ], [ 2, 1561 ],
      [ 2.1, 1539 ], [ 2.2, 1501 ], [ 2.227, 1436 ], [ 2.25, 1126 ], [ 2.29, 832 ], [ 2.366, 647.1 ],
      [ 2.4, 456.8 ], [ 2.44, 244.7 ], [ 2.5, 59.82 ], [ 2.6, 0 ]
],
    "totImpulseNs": 3716,
    "totalWeightG": 3651,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 171.8,
    "burnTimeS": 2.4,
    "caseInfo": "RMS-54/426",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I170",
    "dataFiles": 1,
    "delays": "4,6,8,10",
    "designation": "I170G",
    "diameter": 54,
    "impulseClass": "I",
    "length": 147.1,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 207.3,
    "motorId": "5f4294d2000231000000038f",
    "propInfo": "Mojave Green",
    "propWeightG": 227,
    "samples": [
      [ 0, 0 ], [ 0.06, 89 ], [ 0.16, 169 ], [ 0.25, 184.6 ], [ 0.5, 202.4 ], [ 0.75, 207.3 ],
      [ 1, 202.4 ], [ 1.5, 177.9 ], [ 2.18, 137.9 ], [ 2.25, 139.7 ], [ 2.3, 131.2 ], [ 2.35, 22.2 ],
      [ 2.4, 0 ]
],
    "totImpulseNs": 418.5,
    "totalWeightG": 528,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 146.7,
    "burnTimeS": 0.97,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G150",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "143G150-13A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 247.4,
    "motorId": "5f4294d20002310000000390",
    "propInfo": "Blue Streak",
    "propWeightG": 65.9,
    "samples": [
      [ 0, 0 ], [ 0.005, 114.8 ], [ 0.006, 177.4 ], [ 0.01, 222.6 ], [ 0.016, 243.4 ], [ 0.019, 247 ],
      [ 0.037, 215.4 ], [ 0.077, 206.1 ], [ 0.12, 204.5 ], [ 0.163, 200.2 ], [ 0.204, 195.9 ],
      [ 0.249, 192.9 ], [ 0.316, 186.7 ], [ 0.387, 180.4 ], [ 0.444, 176.1 ], [ 0.536, 168.2 ],
      [ 0.616, 160.6 ], [ 0.639, 156.7 ], [ 0.676, 142.5 ], [ 0.715, 114.8 ], [ 0.75, 93.01 ],
      [ 0.784, 72.23 ], [ 0.831, 49.8 ], [ 0.889, 31.66 ], [ 0.923, 21.77 ], [ 0.953, 14.84 ],
      [ 0.996, 8.245 ], [ 1.03, 5.937 ], [ 1.061, 2.309 ], [ 1.099, 0 ]
],
    "totImpulseNs": 142.5,
    "totalWeightG": 159.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 106.9,
    "burnTimeS": 1.3,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G107",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "139G107-12A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 230.7,
    "motorId": "5f4294d20002310000000391",
    "propInfo": "White Thunder",
    "propWeightG": 75.7,
    "samples": [
      [ 0, 0 ], [ 0.006, 133.2 ], [ 0.011, 198.4 ], [ 0.022, 221.8 ], [ 0.046, 212.7 ], [ 0.081, 218.4 ],
      [ 0.125, 214.9 ], [ 0.168, 210.4 ], [ 0.219, 204.4 ], [ 0.253, 195.9 ], [ 0.274, 183.5 ],
      [ 0.305, 88.29 ], [ 0.412, 93.67 ], [ 0.529, 93.99 ], [ 0.663, 94.3 ], [ 0.789, 93.99 ],
      [ 0.899, 91.14 ], [ 0.953, 89.87 ], [ 0.999, 87.03 ], [ 1.03, 81.33 ], [ 1.057, 69.94 ],
      [ 1.102, 54.11 ], [ 1.154, 42.41 ], [ 1.197, 31.65 ], [ 1.277, 17.09 ], [ 1.335, 9.81 ],
      [ 1.398, 3.165 ], [ 1.451, 0 ]
],
    "totImpulseNs": 139.1,
    "totalWeightG": 169.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 236.2,
    "burnTimeS": 0.29,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F240",
    "dataFiles": 2,
    "delays": "8,10,12,15",
    "designation": "68F240-15A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 285.9,
    "motorId": "5f4294d20002310000000392",
    "propInfo": "Vmax",
    "propWeightG": 30.3,
    "samples": [
      [ 0, 0 ], [ 0.004, 100.5 ], [ 0.007, 197.5 ], [ 0.01, 222 ], [ 0.022, 241.4 ], [ 0.028, 237.9 ],
      [ 0.041, 239.4 ], [ 0.058, 252.5 ], [ 0.077, 264 ], [ 0.089, 275.5 ], [ 0.097, 271.5 ],
      [ 0.104, 273.9 ], [ 0.119, 278.6 ], [ 0.147, 281.4 ], [ 0.177, 272.3 ], [ 0.207, 258.4 ],
      [ 0.246, 226.4 ], [ 0.253, 218.5 ], [ 0.259, 188.8 ], [ 0.266, 127 ], [ 0.272, 74.8 ],
      [ 0.28, 31.27 ], [ 0.286, 15.83 ], [ 0.294, 8.707 ], [ 0.31, 3.562 ], [ 0.328, 0 ]
],
    "totImpulseNs": 68.3,
    "totalWeightG": 91.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 116.8,
    "burnTimeS": 1.22,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G117",
    "dataFiles": 2,
    "delays": "4,6,8,11",
    "designation": "142G117-11A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 181.2,
    "motorId": "5f4294d20002310000000393",
    "propInfo": "White",
    "propWeightG": 79.1,
    "samples": [
      [ 0, 0 ], [ 0.008, 168.6 ], [ 0.013, 177.3 ], [ 0.022, 177.9 ], [ 0.035, 171.3 ], [ 0.063, 157.8 ],
      [ 0.103, 154.9 ], [ 0.151, 151.5 ], [ 0.196, 148.9 ], [ 0.246, 147.6 ], [ 0.311, 144.1 ],
      [ 0.391, 140.7 ], [ 0.474, 138.1 ], [ 0.564, 135.7 ], [ 0.662, 131.2 ], [ 0.762, 126 ],
      [ 0.858, 116.7 ], [ 0.928, 101.7 ], [ 0.973, 83 ], [ 1.038, 57.44 ], [ 1.08, 42.69 ],
      [ 1.131, 31.88 ], [ 1.185, 17.66 ], [ 1.224, 9.486 ], [ 1.258, 5.27 ], [ 1.322, 2.372 ],
      [ 1.4, 0.791 ], [ 1.441, 0 ]
],
    "totImpulseNs": 142.3,
    "totalWeightG": 172.5,
    "type": "reload",
    "updatedOn": "2022-08-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 31,
    "burnTimeS": 2.36,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F30",
    "dataFiles": 2,
    "delays": "3,6",
    "designation": "73F30-6A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 56.8,
    "motorId": "5f4294d20002310000000394",
    "propInfo": "White Thunder",
    "propWeightG": 40,
    "samples": [
      [ 0, 0 ], [ 0.014, 54.22 ], [ 0.056, 43.46 ], [ 0.092, 50.19 ], [ 0.16, 54.06 ], [ 0.232, 48.36 ],
      [ 0.363, 45.75 ], [ 0.499, 43.14 ], [ 0.655, 40.29 ], [ 0.843, 37.84 ], [ 1.216, 32.61 ],
      [ 1.368, 30.32 ], [ 1.54, 26.36 ], [ 1.675, 23.51 ], [ 1.861, 19.08 ], [ 2.013, 14.56 ],
      [ 2.159, 10.05 ], [ 2.302, 4.828 ], [ 2.462, 1.504 ], [ 2.598, 0 ]
],
    "totImpulseNs": 73.1,
    "totalWeightG": 102.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 64.7,
    "burnTimeS": 2.23,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G65",
    "dataFiles": 2,
    "delays": "3,5,8",
    "designation": "144G65-8A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 159.7,
    "motorId": "5f4294d20002310000000395",
    "propInfo": "White Thunder",
    "propWeightG": 80,
    "samples": [
      [ 0, 0 ], [ 0.011, 157.9 ], [ 0.024, 149.5 ], [ 0.042, 132.1 ], [ 0.057, 125.5 ], [ 0.094, 134 ],
      [ 0.122, 131.2 ], [ 0.175, 106.4 ], [ 0.269, 94.05 ], [ 0.376, 87.38 ], [ 0.54, 82.14 ],
      [ 0.707, 78.33 ], [ 0.845, 74.76 ], [ 1.002, 71.19 ], [ 1.206, 65.48 ], [ 1.376, 57.86 ],
      [ 1.553, 48.33 ], [ 1.726, 39.05 ], [ 1.914, 28.33 ], [ 2.061, 18.09 ], [ 2.205, 8.571 ],
      [ 2.299, 3.095 ], [ 2.398, 0 ]
],
    "totImpulseNs": 144.3,
    "totalWeightG": 174,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 84.2,
    "burnTimeS": 1.56,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G84",
    "dataFiles": 2,
    "delays": "3,5,7,10",
    "designation": "131G84-10A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 132.3,
    "motorId": "5f4294d20002310000000396",
    "propInfo": "Green3",
    "propWeightG": 77.3,
    "samples": [
      [ 0, 0 ], [ 0.005, 80.26 ], [ 0.017, 94.69 ], [ 0.023, 108.7 ], [ 0.031, 124.8 ], [ 0.044, 129.8 ],
      [ 0.081, 124.3 ], [ 0.149, 116.5 ], [ 0.224, 111.1 ], [ 0.301, 107.6 ], [ 0.386, 103.4 ],
      [ 0.506, 98.2 ], [ 0.623, 94.14 ], [ 0.759, 89.7 ], [ 0.849, 86 ], [ 0.974, 81.37 ],
      [ 1.09, 77.86 ], [ 1.149, 74.72 ], [ 1.171, 78.97 ], [ 1.202, 70.83 ], [ 1.244, 69.17 ],
      [ 1.3, 68.24 ], [ 1.326, 56.78 ], [ 1.373, 52.52 ], [ 1.403, 40.69 ], [ 1.447, 29.04 ],
      [ 1.515, 14.43 ], [ 1.562, 6.103 ], [ 1.597, 2.034 ], [ 1.641, 0 ]
],
    "totImpulseNs": 131,
    "totalWeightG": 172,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 78.8,
    "burnTimeS": 0.86,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F79",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "68F79-13A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 98.3,
    "motorId": "5f4294d20002310000000397",
    "propInfo": "Smoky Sam",
    "propWeightG": 40.1,
    "samples": [
      [ 0, 0 ], [ 0.005, 60 ], [ 0.013, 89.01 ], [ 0.022, 96.29 ], [ 0.043, 81.72 ], [ 0.119, 85.56 ],
      [ 0.198, 87.95 ], [ 0.267, 89.27 ], [ 0.343, 89.93 ], [ 0.404, 90.86 ], [ 0.498, 91.52 ],
      [ 0.555, 89.67 ], [ 0.622, 83.97 ], [ 0.663, 80.53 ], [ 0.704, 78.94 ], [ 0.729, 74.17 ],
      [ 0.747, 66.89 ], [ 0.768, 53.77 ], [ 0.793, 36.56 ], [ 0.821, 18.54 ], [ 0.852, 7.815 ],
      [ 0.892, 2.119 ], [ 0.928, 0.795 ], [ 0.997, 0 ]
],
    "totImpulseNs": 67.8,
    "totalWeightG": 107.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.8,
    "burnTimeS": 3.12,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H54",
    "dataFiles": 2,
    "delays": "3,5,7,10",
    "designation": "168H54-10A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 105,
    "motorId": "5f4294d20002310000000398",
    "propInfo": "White",
    "propWeightG": 96.6,
    "samples": [
      [ 0, 0 ], [ 0.017, 103.1 ], [ 0.047, 83.27 ], [ 0.088, 91.82 ], [ 0.155, 97.84 ], [ 0.199, 89.29 ],
      [ 0.271, 80.26 ], [ 0.406, 76.62 ], [ 0.599, 73.77 ], [ 0.898, 69.02 ], [ 1.182, 66.02 ],
      [ 1.475, 60.48 ], [ 1.785, 53.83 ], [ 2.099, 44.8 ], [ 2.296, 38.95 ], [ 2.577, 27.55 ],
      [ 2.845, 16.15 ], [ 3.097, 6.491 ], [ 3.199, 2.058 ], [ 3.298, 1.108 ], [ 3.5, 0 ]
],
    "totImpulseNs": 167.7,
    "totalWeightG": 209,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 67,
    "burnTimeS": 1.61,
    "caseInfo": "Pro29-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G68",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "108G68-13A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 142,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 94.1,
    "motorId": "5f4294d20002310000000399",
    "propInfo": "White",
    "propWeightG": 59.9,
    "samples": [
      [ 0, 0 ], [ 0.012, 50.33 ], [ 0.017, 79.31 ], [ 0.019, 86.69 ], [ 0.03, 91.83 ], [ 0.047, 73.39 ],
      [ 0.048, 81.69 ], [ 0.049, 65.88 ], [ 0.061, 58.23 ], [ 0.088, 66.8 ], [ 0.12, 70.88 ],
      [ 0.2, 72.99 ], [ 0.307, 75.1 ], [ 0.38, 75.63 ], [ 0.487, 76.42 ], [ 0.614, 76.55 ],
      [ 0.726, 75.63 ], [ 0.818, 73.52 ], [ 0.987, 70.49 ], [ 1.096, 68.12 ], [ 1.269, 63.51 ],
      [ 1.388, 61.53 ], [ 1.446, 57.44 ], [ 1.483, 49.14 ], [ 1.537, 29.38 ], [ 1.569, 18.05 ],
      [ 1.613, 5.665 ], [ 1.638, 2.899 ], [ 1.676, 1.449 ], [ 1.723, 0.395 ], [ 1.759, 0 ]
],
    "totImpulseNs": 107.8,
    "totalWeightG": 155.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 135.1,
    "burnTimeS": 1.6,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H135",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "217H135-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 171.8,
    "motorId": "5f4294d2000231000000039a",
    "propInfo": "White",
    "propWeightG": 119.8,
    "samples": [
      [ 0, 0 ], [ 0.012, 166.8 ], [ 0.054, 158.6 ], [ 0.101, 155.7 ], [ 0.156, 153.1 ], [ 0.223, 151.5 ],
      [ 0.312, 150.4 ], [ 0.566, 151 ], [ 0.706, 149.1 ], [ 0.946, 143.8 ], [ 1.121, 140.4 ],
      [ 1.222, 136.5 ], [ 1.278, 137 ], [ 1.341, 131.4 ], [ 1.374, 126.7 ], [ 1.4, 117.2 ],
      [ 1.431, 100 ], [ 1.482, 71.26 ], [ 1.535, 49.35 ], [ 1.558, 30.88 ], [ 1.598, 11.88 ],
      [ 1.62, 5.015 ], [ 1.644, 2.375 ], [ 1.676, 0 ]
],
    "totImpulseNs": 216.7,
    "totalWeightG": 251.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 242.7,
    "burnTimeS": 1.57,
    "caseInfo": "Pro29-6GXL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I243",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "382I243-13A",
    "diameter": 29,
    "impulseClass": "I",
    "length": 365,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 445.8,
    "motorId": "5f4294d2000231000000039b",
    "propInfo": "White",
    "propWeightG": 212.1,
    "samples": [
      [ 0, 0 ], [ 0.016, 443.4 ], [ 0.03, 416.3 ], [ 0.061, 365.4 ], [ 0.082, 331.7 ], [ 0.122, 317.2 ],
      [ 0.174, 303.3 ], [ 0.268, 298 ], [ 0.367, 295.4 ], [ 0.514, 294.7 ], [ 0.701, 290.7 ],
      [ 0.808, 280.8 ], [ 0.968, 267 ], [ 1.058, 247.8 ], [ 1.105, 224 ], [ 1.155, 207.5 ],
      [ 1.178, 202.2 ], [ 1.206, 167.2 ], [ 1.254, 158.6 ], [ 1.297, 154.6 ], [ 1.336, 132.8 ],
      [ 1.395, 109.7 ], [ 1.486, 69.38 ], [ 1.574, 25.77 ], [ 1.649, 6.608 ], [ 1.692, 0 ]
],
    "totImpulseNs": 381.7,
    "totalWeightG": 398.6,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 270.8,
    "burnTimeS": 2.4,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J270",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "650J270-13A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 238.3,
    "motorId": "5f4294d2000231000000039c",
    "propInfo": "Green3",
    "propWeightG": 376,
    "samples": [
      [ 0, 0 ], [ 0.008, 194.1 ], [ 0.022, 192.7 ], [ 0.061, 279.5 ], [ 0.114, 287.5 ], [ 0.229, 289.3 ],
      [ 0.311, 289.3 ], [ 0.382, 292 ], [ 0.478, 294.3 ], [ 0.587, 296.5 ], [ 0.701, 297.9 ],
      [ 0.828, 301.9 ], [ 0.981, 300.1 ], [ 1.116, 299.7 ], [ 1.233, 296.1 ], [ 1.323, 293.4 ],
      [ 1.488, 289.8 ], [ 1.594, 287.1 ], [ 1.676, 287.5 ], [ 1.701, 293.8 ], [ 1.75, 286.6 ],
      [ 1.797, 290.2 ], [ 1.868, 280.4 ], [ 1.956, 275.9 ], [ 2.052, 276.3 ], [ 2.126, 266 ],
      [ 2.181, 224.2 ], [ 2.234, 163.1 ], [ 2.292, 97.95 ], [ 2.359, 49.87 ], [ 2.428, 15.28 ],
      [ 2.5, 0 ]
],
    "totImpulseNs": 650.3,
    "totalWeightG": 654.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 394.1,
    "burnTimeS": 2.46,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J394",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "970J394-13A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 515.7,
    "motorId": "5f4294d2000231000000039d",
    "propInfo": "Green3",
    "propWeightG": 572.1,
    "samples": [
      [ 0, 0 ], [ 0.014, 331.7 ], [ 0.038, 359.2 ], [ 0.046, 406.6 ], [ 0.08, 441.6 ], [ 0.219, 426.5 ],
      [ 0.331, 421.7 ], [ 0.486, 422.4 ], [ 0.657, 423.8 ], [ 0.795, 430.6 ], [ 0.936, 436.8 ],
      [ 1.12, 439.6 ], [ 1.297, 445.7 ], [ 1.329, 440.2 ], [ 1.381, 449.2 ], [ 1.425, 448.5 ],
      [ 1.472, 448.5 ], [ 1.512, 458.8 ], [ 1.544, 447.8 ], [ 1.572, 462.2 ], [ 1.624, 456.7 ],
      [ 1.731, 445.7 ], [ 1.801, 456.7 ], [ 1.853, 456.7 ], [ 1.873, 474.6 ], [ 1.885, 491.8 ],
      [ 1.952, 451.9 ], [ 2.008, 416.9 ], [ 2.034, 385.3 ], [ 2.078, 376.4 ], [ 2.152, 296.7 ],
      [ 2.229, 203.3 ], [ 2.305, 133.2 ], [ 2.395, 65.93 ], [ 2.48, 19.23 ], [ 2.518, 7.555 ],
      [ 2.55, 0 ]
],
    "totImpulseNs": 970.4,
    "totalWeightG": 938.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 57.9,
    "burnTimeS": 2.36,
    "caseInfo": "Pro38-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G58",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "137G58-13A",
    "diameter": 38,
    "impulseClass": "G",
    "length": 127,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 97.7,
    "motorId": "5f4294d2000231000000039e",
    "propInfo": "White",
    "propWeightG": 76.3,
    "samples": [
      [ 0, 0 ], [ 0.029, 90.25 ], [ 0.046, 69.17 ], [ 0.058, 59.95 ], [ 0.084, 47.17 ], [ 0.171, 57.97 ],
      [ 0.28, 59.55 ], [ 0.455, 61.27 ], [ 0.586, 61.66 ], [ 0.741, 62.32 ], [ 0.952, 63.77 ],
      [ 1.217, 64.69 ], [ 1.43, 63.77 ], [ 1.626, 61.27 ], [ 1.807, 58.1 ], [ 1.959, 53.89 ],
      [ 2.104, 48.35 ], [ 2.168, 47.56 ], [ 2.21, 44.01 ], [ 2.247, 37.29 ], [ 2.329, 22.27 ],
      [ 2.375, 10.28 ], [ 2.414, 2.767 ], [ 2.442, 1.186 ], [ 2.5, 0 ]
],
    "totImpulseNs": 136.8,
    "totalWeightG": 212.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 109.3,
    "burnTimeS": 2.47,
    "caseInfo": "Pro38-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H110",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "269H110-14A",
    "diameter": 38,
    "impulseClass": "H",
    "length": 186,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 127.5,
    "motorId": "5f4294d2000231000000039f",
    "propInfo": "White",
    "propWeightG": 152.6,
    "samples": [
      [ 0, 0 ], [ 0.029, 93.73 ], [ 0.052, 118.1 ], [ 0.093, 98.53 ], [ 0.153, 109.3 ], [ 0.238, 113.4 ],
      [ 0.461, 118.9 ], [ 0.592, 121 ], [ 0.72, 122.7 ], [ 0.896, 124.4 ], [ 1.15, 124.9 ],
      [ 1.246, 122.5 ], [ 1.461, 119.6 ], [ 1.622, 117.4 ], [ 1.79, 115.7 ], [ 1.922, 112.6 ],
      [ 2.114, 112.8 ], [ 2.174, 111.7 ], [ 2.21, 98.87 ], [ 2.259, 69.4 ], [ 2.319, 47.47 ],
      [ 2.365, 28.96 ], [ 2.441, 12.17 ], [ 2.526, 7.368 ], [ 2.597, 2.228 ], [ 2.692, 0 ]
],
    "totImpulseNs": 269.1,
    "totalWeightG": 325.3,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 174.4,
    "burnTimeS": 2.36,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I175",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "411I175-14A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 197.9,
    "motorId": "5f4294d200023100000003a0",
    "propInfo": "White",
    "propWeightG": 228.9,
    "samples": [
      [ 0, 0 ], [ 0.019, 149.4 ], [ 0.038, 180.3 ], [ 0.052, 166.8 ], [ 0.106, 185 ], [ 0.148, 187.6 ],
      [ 0.232, 187.9 ], [ 0.338, 189 ], [ 0.518, 190.8 ], [ 0.604, 192.2 ], [ 0.733, 193.4 ],
      [ 0.877, 195.1 ], [ 1.005, 193.9 ], [ 1.128, 193.4 ], [ 1.333, 193.1 ], [ 1.495, 190.8 ],
      [ 1.648, 188.2 ], [ 1.79, 183.5 ], [ 1.921, 178 ], [ 2.001, 178.3 ], [ 2.043, 176.6 ],
      [ 2.089, 151.2 ], [ 2.147, 115.3 ], [ 2.212, 70.23 ], [ 2.274, 47.11 ], [ 2.32, 22.83 ],
      [ 2.368, 8.671 ], [ 2.396, 3.757 ], [ 2.435, 3.179 ], [ 2.5, 0 ]
],
    "totImpulseNs": 411.4,
    "totalWeightG": 437.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 242.1,
    "burnTimeS": 2.264,
    "caseInfo": "Pro38-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I242",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "548I242-15A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 303,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 284.7,
    "motorId": "5f4294d200023100000003a1",
    "propInfo": "White",
    "propWeightG": 305.2,
    "samples": [
      [ 0, 0 ], [ 0.015, 241 ], [ 0.035, 272.7 ], [ 0.063, 286.1 ], [ 0.094, 277 ], [ 0.184, 265.6 ],
      [ 0.299, 262.8 ], [ 0.403, 262.8 ], [ 0.537, 266.4 ], [ 0.734, 269.9 ], [ 0.884, 271.5 ],
      [ 1.049, 269.9 ], [ 1.223, 265.6 ], [ 1.384, 260.4 ], [ 1.61, 249.3 ], [ 1.727, 243 ],
      [ 1.865, 235.1 ], [ 1.954, 231.1 ], [ 2, 220.8 ], [ 2.088, 155.5 ], [ 2.164, 93.01 ],
      [ 2.211, 43.54 ], [ 2.278, 10.29 ], [ 2.345, 3.958 ], [ 2.402, 0 ]
],
    "totImpulseNs": 548.2,
    "totalWeightG": 549.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 295.4,
    "burnTimeS": 2.31,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J290",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "684J290-15A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 391.6,
    "motorId": "5f4294d200023100000003a2",
    "propInfo": "White",
    "propWeightG": 381.5,
    "samples": [
      [ 0, 0 ], [ 0.015, 334.6 ], [ 0.028, 364.1 ], [ 0.037, 379.4 ], [ 0.061, 391.6 ], [ 0.083, 379.4 ],
      [ 0.107, 372.6 ], [ 0.169, 355.1 ], [ 0.289, 343.5 ], [ 0.381, 341.4 ], [ 0.588, 337.2 ],
      [ 0.803, 334.6 ], [ 1.011, 330.9 ], [ 1.256, 324.5 ], [ 1.498, 321.4 ], [ 1.684, 312.9 ],
      [ 1.805, 306.1 ], [ 1.844, 301.3 ], [ 1.89, 269.1 ], [ 1.942, 243.3 ], [ 1.979, 223.7 ],
      [ 2.043, 156.2 ], [ 2.128, 118.7 ], [ 2.192, 86.02 ], [ 2.279, 35.36 ], [ 2.334, 15.83 ],
      [ 2.402, 0 ]
],
    "totImpulseNs": 683.6,
    "totalWeightG": 659.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 354.7,
    "burnTimeS": 2.31,
    "caseInfo": "Pro38-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J354",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "819J354-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 421,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 521.8,
    "motorId": "5f4294d200023100000003a3",
    "propInfo": "White",
    "propWeightG": 457.8,
    "samples": [
      [ 0, 0 ], [ 0.016, 472.7 ], [ 0.024, 487.7 ], [ 0.04, 519.7 ], [ 0.08, 499.1 ], [ 0.124, 476.3 ],
      [ 0.167, 457.1 ], [ 0.223, 447.2 ], [ 0.357, 430.8 ], [ 0.508, 423.7 ], [ 0.612, 418.7 ],
      [ 0.845, 413 ], [ 1.038, 406.6 ], [ 1.307, 390.3 ], [ 1.422, 382.5 ], [ 1.532, 372.5 ],
      [ 1.624, 369 ], [ 1.687, 364.7 ], [ 1.763, 317.8 ], [ 1.837, 280.8 ], [ 1.94, 220.4 ],
      [ 2.054, 171.3 ], [ 2.143, 128.7 ], [ 2.177, 95.26 ], [ 2.235, 62.56 ], [ 2.321, 24.17 ],
      [ 2.359, 12.09 ], [ 2.402, 4.976 ], [ 2.5, 0 ]
],
    "totImpulseNs": 818.7,
    "totalWeightG": 778.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 98.5,
    "burnTimeS": 6.23,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I100",
    "dataFiles": 2,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "614I100-17A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 358.4,
    "motorId": "5f4294d200023100000003a4",
    "propInfo": "Red Lightning",
    "propWeightG": 350.1,
    "samples": [
      [ 0, 0 ], [ 0.042, 269.3 ], [ 0.043, 332.4 ], [ 0.07, 225.1 ], [ 0.091, 202.3 ], [ 0.14, 234.1 ],
      [ 0.202, 245.5 ], [ 0.349, 220.8 ], [ 0.523, 202.3 ], [ 0.837, 183.3 ], [ 1.186, 164.3 ],
      [ 1.681, 139.6 ], [ 2.358, 110.7 ], [ 3.321, 76.46 ], [ 4.095, 55.56 ], [ 4.919, 37.04 ],
      [ 5.944, 21.37 ], [ 6.726, 12.35 ], [ 7.437, 6.174 ], [ 8.142, 2.849 ], [ 8.735, 0.95 ],
      [ 8.993, 0 ]
],
    "totImpulseNs": 613.6,
    "totalWeightG": 807,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 142.9,
    "burnTimeS": 8.47,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J140",
    "dataFiles": 2,
    "delays": "P",
    "designation": "1211J140-P",
    "diameter": 54,
    "impulseClass": "J",
    "length": 329,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 221.8,
    "motorId": "5f4294d200023100000003a5",
    "propInfo": "White",
    "propWeightG": 680,
    "samples": [
      [ 0, 0 ], [ 0.073, 191.2 ], [ 0.109, 196.1 ], [ 0.219, 193.4 ], [ 0.51, 211.3 ], [ 0.801, 216.2 ],
      [ 0.991, 216.2 ], [ 1.195, 212.5 ], [ 1.53, 209.5 ], [ 2.521, 198.2 ], [ 3.096, 188.2 ],
      [ 3.781, 174.5 ], [ 4.174, 165 ], [ 4.794, 146.5 ], [ 5.464, 125.5 ], [ 6.207, 98.36 ],
      [ 7.001, 65.16 ], [ 7.781, 36.84 ], [ 8.444, 11.27 ], [ 8.771, 4.568 ], [ 9.158, 1.218 ],
      [ 9.464, 0 ]
],
    "totImpulseNs": 1211,
    "totalWeightG": 1280,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 259.8,
    "burnTimeS": 7.78,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K261",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2021K261-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 370.1,
    "motorId": "5f4294d200023100000003a6",
    "propInfo": "White",
    "propWeightG": 1152,
    "samples": [
      [ 0, 0 ], [ 0.035, 283.1 ], [ 0.069, 321.7 ], [ 0.124, 345.8 ], [ 0.228, 336.8 ], [ 0.525, 347.3 ],
      [ 0.85, 359.3 ], [ 1.334, 356.3 ], [ 1.79, 359.3 ], [ 2.039, 354.3 ], [ 2.412, 353.3 ],
      [ 2.793, 349.8 ], [ 3.131, 344.3 ], [ 3.774, 323.7 ], [ 4.652, 278.5 ], [ 5.281, 230.9 ],
      [ 5.571, 206.8 ], [ 5.993, 166.6 ], [ 6.325, 137.5 ], [ 6.615, 102.4 ], [ 7.085, 55.21 ],
      [ 7.362, 35.63 ], [ 7.694, 20.58 ], [ 8.129, 9.536 ], [ 8.502, 2.509 ], [ 8.979, 0 ]
],
    "totImpulseNs": 2021,
    "totalWeightG": 1932,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 432.4,
    "burnTimeS": 1.9,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J430",
    "dataFiles": 2,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "821J430-18A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 546.8,
    "motorId": "5f4294d200023100000003a7",
    "propInfo": "White Thunder",
    "propWeightG": 384,
    "samples": [
      [ 0, 0 ], [ 0.017, 400 ], [ 0.025, 501.1 ], [ 0.034, 538.7 ], [ 0.069, 432.5 ], [ 0.111, 440.6 ],
      [ 0.192, 458.3 ], [ 0.328, 469.4 ], [ 0.508, 473.8 ], [ 0.697, 473.8 ], [ 0.899, 468.6 ],
      [ 0.996, 461.3 ], [ 1.2, 446.5 ], [ 1.401, 429.5 ], [ 1.593, 415.5 ], [ 1.696, 410.3 ],
      [ 1.739, 414.8 ], [ 1.785, 354.2 ], [ 1.807, 277.5 ], [ 1.83, 180.1 ], [ 1.839, 145.4 ],
      [ 1.853, 107.7 ], [ 1.885, 49.45 ], [ 1.914, 19.19 ], [ 1.943, 2.952 ], [ 1.963, 0 ]
],
    "totImpulseNs": 821.1,
    "totalWeightG": 799.8,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 757.7,
    "burnTimeS": 1.67,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J760",
    "dataFiles": 2,
    "delays": "9,10,11,12,13,14,15,16,17,18,19",
    "designation": "1266J760-19A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 329,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 937.3,
    "motorId": "5f4294d200023100000003a8",
    "propInfo": "White Thunder",
    "propWeightG": 576,
    "samples": [
      [ 0, 0 ], [ 0.012, 600.3 ], [ 0.019, 833.3 ], [ 0.026, 938.6 ], [ 0.04, 794.5 ], [ 0.054, 756.9 ],
      [ 0.094, 778.2 ], [ 0.129, 800.8 ], [ 0.165, 813.3 ], [ 0.218, 825.8 ], [ 0.279, 830.8 ],
      [ 0.377, 835.8 ], [ 0.496, 837.1 ], [ 0.617, 829.6 ], [ 0.709, 819.5 ], [ 0.811, 802 ],
      [ 0.917, 785.7 ], [ 1.041, 764.4 ], [ 1.201, 750.6 ], [ 1.331, 741.9 ], [ 1.455, 729.3 ],
      [ 1.488, 729.3 ], [ 1.514, 735.6 ], [ 1.556, 749.4 ], [ 1.568, 729.3 ], [ 1.575, 665.4 ],
      [ 1.589, 533.8 ], [ 1.616, 327.1 ], [ 1.646, 122.8 ], [ 1.659, 72.68 ], [ 1.681, 30.07 ],
      [ 1.713, 6.266 ], [ 1.731, 0 ]
],
    "totImpulseNs": 1266,
    "totalWeightG": 1077,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 934.8,
    "burnTimeS": 1.75,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K940",
    "dataFiles": 2,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "1633K940-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1121,
    "motorId": "5f4294d200023100000003a9",
    "propInfo": "White Thunder",
    "propWeightG": 768,
    "samples": [
      [ 0, 0 ], [ 0.01, 885.7 ], [ 0.011, 1006 ], [ 0.021, 1116 ], [ 0.035, 1087 ], [ 0.053, 963.9 ],
      [ 0.061, 957.9 ], [ 0.094, 975.9 ], [ 0.183, 998.5 ], [ 0.321, 1023 ], [ 0.394, 1038 ],
      [ 0.515, 1047 ], [ 0.626, 1047 ], [ 0.769, 1033 ], [ 0.929, 1014 ], [ 1.089, 982 ],[ 1.24, 942.9 ],
      [ 1.343, 912.8 ], [ 1.426, 894.7 ], [ 1.474, 881.2 ], [ 1.533, 875.2 ], [ 1.575, 873.7 ],
      [ 1.596, 845.1 ], [ 1.606, 800 ], [ 1.642, 615 ], [ 1.661, 479.7 ], [ 1.683, 320.3 ],
      [ 1.703, 200 ], [ 1.735, 76.69 ], [ 1.753, 45.11 ], [ 1.776, 22.56 ], [ 1.797, 0 ]
],
    "totImpulseNs": 1633,
    "totalWeightG": 1367,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2022,
    "burnTimeS": 4.17,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2020",
    "dataFiles": 2,
    "delays": "P",
    "designation": "8429M2020-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 893,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2680,
    "motorId": "5f4294d200023100000003aa",
    "propInfo": "Imax",
    "propWeightG": 4349,
    "samples": [
      [ 0, 0 ], [ 0.023, 2070 ], [ 0.036, 1930 ], [ 0.053, 2148 ], [ 0.073, 2369 ], [ 0.089, 2506 ],
      [ 0.136, 2649 ], [ 0.182, 2627 ], [ 0.262, 2609 ], [ 0.364, 2616 ], [ 0.566, 2624 ],
      [ 1.387, 2576 ], [ 1.639, 2539 ], [ 1.986, 2450 ], [ 2.198, 2395 ], [ 2.457, 2295 ],
      [ 2.708, 2207 ], [ 2.831, 2162 ], [ 2.933, 2089 ], [ 3.036, 1989 ], [ 3.109, 1801 ],
      [ 3.175, 1594 ], [ 3.307, 1336 ], [ 3.45, 1015 ], [ 3.589, 708.5 ], [ 3.698, 601.5 ],
      [ 3.814, 461.3 ], [ 3.996, 339.5 ], [ 4.115, 203 ], [ 4.201, 88.56 ], [ 4.301, 0 ]
],
    "totImpulseNs": 8429,
    "totalWeightG": 7032,
    "type": "reload",
    "updatedOn": "2021-03-16"
  },
  {
    "availability": "regular",
    "avgThrustN": 819.9,
    "burnTimeS": 3.59,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L820",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2946L820-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 984.8,
    "motorId": "5f4294d200023100000003ab",
    "propInfo": "Skidmark",
    "propWeightG": 1760,
    "samples": [
      [ 0, 0 ], [ 0.026, 497.4 ], [ 0.038, 662.3 ], [ 0.058, 738.8 ], [ 0.079, 750.7 ], [ 0.114, 721.6 ],
      [ 0.152, 697.9 ], [ 0.213, 679.4 ], [ 0.377, 719 ], [ 0.547, 754.6 ], [ 0.743, 792.9 ],
      [ 0.962, 839 ], [ 1.067, 860.2 ], [ 1.629, 957.8 ], [ 1.81, 957.8 ], [ 2.044, 942 ],
      [ 2.249, 923.5 ], [ 2.462, 902.4 ], [ 2.994, 808.7 ], [ 3.064, 816.6 ], [ 3.149, 794.2 ],
      [ 3.213, 800.8 ], [ 3.266, 831.1 ], [ 3.339, 866.8 ], [ 3.386, 832.5 ], [ 3.415, 725.6 ],
      [ 3.509, 221.6 ], [ 3.526, 141.2 ], [ 3.591, 47.49 ], [ 3.632, 27.7 ], [ 3.699, 6.596 ],
      [ 3.801, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2946,
    "totalWeightG": 3420,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2082,
    "burnTimeS": 3.28,
    "caseInfo": "Pro75-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2080",
    "dataFiles": 2,
    "delays": "P",
    "designation": "6827M2080-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1025,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2611,
    "motorId": "5f4294d200023100000003ac",
    "propInfo": "Skidmark",
    "propWeightG": 4107,
    "samples": [
      [ 0, 0 ], [ 0.027, 1814 ], [ 0.04, 2084 ], [ 0.067, 2344 ], [ 0.097, 2277 ], [ 0.132, 2262 ],
      [ 0.172, 2270 ], [ 0.218, 2319 ], [ 0.323, 2416 ], [ 0.447, 2491 ], [ 0.541, 2523 ],
      [ 0.676, 2548 ], [ 0.816, 2555 ], [ 1.026, 2523 ], [ 1.23, 2519 ], [ 1.459, 2462 ],[ 1.669, 2369 ],
      [ 1.987, 2241 ], [ 2.21, 2134 ], [ 2.539, 2002 ], [ 2.63, 1992 ], [ 2.7, 1942 ], [ 2.746, 1842 ],
      [ 2.784, 1692 ], [ 2.811, 1543 ], [ 2.843, 1340 ], [ 2.999, 883.6 ], [ 3.083, 520.2 ],
      [ 3.177, 277.9 ], [ 3.271, 128.3 ], [ 3.365, 53.44 ], [ 3.443, 24.94 ], [ 3.5, 0 ]
],
    "sparky": true,
    "totImpulseNs": 6827,
    "totalWeightG": 7040,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1547,
    "burnTimeS": 5.29,
    "caseInfo": "Pro75-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1545",
    "dataFiles": 2,
    "delays": "P",
    "designation": "8187M1545-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1025,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1840,
    "motorId": "5f4294d200023100000003ad",
    "propInfo": "Green3",
    "propWeightG": 4835,
    "samples": [
      [ 0, 0 ], [ 0.038, 1517 ], [ 0.063, 1077 ], [ 0.068, 1282 ], [ 0.076, 1509 ], [ 0.144, 1741 ],
      [ 0.207, 1765 ], [ 0.334, 1749 ], [ 0.537, 1792 ], [ 0.753, 1794 ], [ 1.053, 1776 ],
      [ 1.383, 1789 ], [ 1.704, 1821 ], [ 1.856, 1828 ], [ 2.013, 1799 ], [ 2.601, 1686 ],
      [ 2.905, 1641 ], [ 3.188, 1617 ], [ 3.472, 1599 ], [ 3.738, 1583 ], [ 3.958, 1565 ],
      [ 4.14, 1544 ], [ 4.216, 1544 ], [ 4.33, 1483 ], [ 4.453, 1359 ], [ 4.55, 1187 ], [ 4.723, 1053 ],
      [ 4.876, 891.8 ], [ 4.969, 783.6 ], [ 5.028, 643.8 ], [ 5.231, 184.7 ], [ 5.303, 68.6 ],
      [ 5.396, 0 ]
],
    "totImpulseNs": 8187,
    "totalWeightG": 7878,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3654,
    "burnTimeS": 8.19,
    "caseInfo": "Pro150-40k",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O3700",
    "dataFiles": 2,
    "delays": "P",
    "designation": "29920O3700-P",
    "diameter": 161,
    "impulseClass": "O",
    "length": 957,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 4082,
    "motorId": "5f4294d200023100000003ae",
    "propInfo": "Skidmark",
    "propWeightG": 17160,
    "samples": [
      [ 0, 0 ], [ 0.052, 1009 ], [ 0.071, 1647 ], [ 0.084, 2505 ], [ 0.09, 3184 ], [ 0.116, 3410 ],
      [ 0.187, 3114 ], [ 0.245, 3050 ], [ 0.316, 3108 ], [ 0.445, 3242 ], [ 0.594, 3462 ],
      [ 0.819, 3653 ], [ 1.155, 3677 ], [ 1.503, 3775 ], [ 2.471, 3932 ], [ 3.277, 4013 ],
      [ 4.213, 4030 ], [ 4.787, 3984 ], [ 5.206, 3926 ], [ 5.671, 3868 ], [ 5.981, 3851 ],
      [ 6.413, 3752 ], [ 6.923, 3619 ], [ 7.387, 3497 ], [ 7.645, 3363 ], [ 7.819, 3102 ],
      [ 7.903, 2905 ], [ 7.942, 2383 ], [ 7.981, 1948 ], [ 8.026, 1311 ], [ 8.084, 695.9 ],
      [ 8.135, 359.5 ], [ 8.187, 162.4 ], [ 8.277, 81.19 ], [ 8.387, 0 ]
],
    "sparky": true,
    "totImpulseNs": 29920,
    "totalWeightG": 31350,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1073,
    "burnTimeS": 2.09,
    "caseInfo": "AMW 54-2550",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K1075",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2245K1075-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1607,
    "motorId": "5f4294d200023100000003af",
    "propInfo": "Skidmark",
    "propWeightG": 1259,
    "samples": [
      [ 0, 0 ], [ 0.007, 1574 ], [ 0.012, 1038 ], [ 0.017, 1476 ], [ 0.024, 1083 ], [ 0.029, 1365 ],
      [ 0.034, 1117 ], [ 0.041, 1267 ], [ 0.046, 1162 ], [ 0.049, 1226 ], [ 0.069, 1160 ],
      [ 0.107, 1130 ], [ 0.151, 1109 ], [ 0.21, 1100 ], [ 0.274, 1102 ], [ 0.332, 1102 ],[ 0.432, 1115 ],
      [ 0.523, 1119 ], [ 0.611, 1132 ], [ 0.674, 1141 ], [ 0.766, 1149 ], [ 0.881, 1160 ],
      [ 0.979, 1179 ], [ 1.141, 1192 ], [ 1.257, 1190 ], [ 1.379, 1192 ], [ 1.504, 1203 ],
      [ 1.599, 1211 ], [ 1.67, 1233 ], [ 1.744, 1250 ], [ 1.772, 1226 ], [ 1.802, 1156 ],[ 1.841, 993.3 ],
      [ 1.888, 737 ], [ 1.944, 455 ], [ 2.002, 267 ], [ 2.065, 128.2 ], [ 2.11, 68.36 ], [ 2.149, 34.18 ],
      [ 2.198, 0 ]
],
    "sparky": true,
    "totImpulseNs": 2245,
    "totalWeightG": 2639,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 30.4,
    "burnTimeS": 1.55,
    "certOrg": "National Association of Rocketry",
    "commonName": "F30",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F30FJ",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F30FJ.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 40.6,
    "motorId": "5f4294d200023100000003b0",
    "propInfo": "Black Max",
    "propWeightG": 31.2,
    "samples": [
      [ 0, 0 ], [ 0.056, 22.71 ], [ 0.102, 34.31 ], [ 0.134, 36.36 ], [ 0.2, 36.88 ], [ 0.3, 36.7 ],
      [ 0.4, 37.22 ], [ 0.5, 38.24 ], [ 0.6, 37.9 ], [ 0.7, 37.9 ], [ 0.8, 37.22 ], [ 0.9, 36.36 ],
      [ 1, 35.17 ], [ 1.1, 33.29 ], [ 1.175, 32.61 ], [ 1.2, 31.41 ], [ 1.26, 26.97 ], [ 1.3, 22.71 ],
      [ 1.4, 9.219 ], [ 1.453, 5.805 ], [ 1.5, 4.097 ], [ 1.6, 0 ]
],
    "totImpulseNs": 47,
    "totalWeightG": 70.6,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 138,
    "burnTimeS": 1.14,
    "caseInfo": "RMS-29/40-120",
    "certOrg": "National Association of Rocketry",
    "commonName": "G138",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "HP-G138T",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G138T.pdf",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 190.1,
    "motorId": "5f4294d200023100000003b1",
    "propInfo": "Blue Thunder",
    "propWeightG": 70,
    "samples": [
      [ 0, 0 ], [ 0.002, 113.5 ], [ 0.043, 118.7 ], [ 0.098, 125.1 ], [ 0.138, 126.2 ], [ 0.198, 133.6 ],
      [ 0.251, 141 ], [ 0.293, 146 ], [ 0.347, 148.9 ], [ 0.398, 156.9 ], [ 0.447, 160.9 ],
      [ 0.5, 165.9 ], [ 0.548, 169.6 ], [ 0.601, 173.7 ], [ 0.646, 177.4 ], [ 0.697, 183.1 ],
      [ 0.739, 182 ], [ 0.798, 182.5 ], [ 0.839, 181.2 ], [ 0.901, 182.5 ], [ 0.947, 194.7 ],
      [ 0.972, 185.3 ], [ 0.986, 174.6 ], [ 0.998, 161.5 ], [ 1, 156.7 ], [ 1.012, 143.6 ],
      [ 1.018, 133.8 ], [ 1.026, 120.3 ], [ 1.031, 112 ], [ 1.036, 98.44 ], [ 1.041, 89.05 ],
      [ 1.044, 77.48 ], [ 1.049, 67.66 ], [ 1.054, 54.78 ], [ 1.059, 46.71 ], [ 1.064, 33.18 ],
      [ 1.073, 23.14 ], [ 1.082, 12.66 ], [ 1.098, 0.873 ], [ 1.099, 0 ]
],
    "totImpulseNs": 157.1,
    "totalWeightG": 148,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 1288,
    "burnTimeS": 5.94,
    "caseInfo": "Pro98-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1290",
    "dataFiles": 2,
    "delays": "P",
    "designation": "7649M1290-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 548,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1562,
    "motorId": "5f4294d200023100000003b2",
    "propInfo": "White",
    "propWeightG": 4295,
    "samples": [
      [ 0, 0 ], [ 0.024, 227.1 ], [ 0.063, 873.4 ], [ 0.107, 1162 ], [ 0.166, 1400 ], [ 0.292, 1358 ],
      [ 0.892, 1435 ], [ 1.398, 1513 ], [ 2.484, 1559 ], [ 2.748, 1550 ], [ 3.009, 1507 ],
      [ 4.091, 1282 ], [ 5.347, 1026 ], [ 5.473, 984.7 ], [ 5.596, 718.3 ], [ 5.663, 469.4 ],
      [ 5.789, 214 ], [ 5.856, 192.1 ], [ 6.046, 0 ]
],
    "totImpulseNs": 7649,
    "totalWeightG": 7411,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1760,
    "burnTimeS": 5.89,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N1800",
    "dataFiles": 2,
    "delays": "P",
    "designation": "10367N1800-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2216,
    "motorId": "5f4294d200023100000003b3",
    "propInfo": "White",
    "propWeightG": 5727,
    "samples": [
      [ 0, 0 ], [ 0.077, 1769 ], [ 0.123, 2206 ], [ 0.193, 2042 ], [ 0.339, 1989 ], [ 0.744, 1994 ],
      [ 2.398, 2144 ], [ 2.726, 2119 ], [ 2.977, 2078 ], [ 3.933, 1786 ], [ 4.643, 1525 ],
      [ 4.986, 1444 ], [ 5.152, 1353 ], [ 5.225, 1261 ], [ 5.348, 994.4 ], [ 5.437, 738.9 ],
      [ 5.541, 622.2 ], [ 5.73, 355.6 ], [ 5.915, 75 ], [ 5.931, 0 ]
],
    "totImpulseNs": 10370,
    "totalWeightG": 9180,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2502,
    "burnTimeS": 6.09,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2501",
    "dataFiles": 2,
    "delays": "P",
    "designation": "15227N2501-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3892,
    "motorId": "5f4294d200023100000003b4",
    "propInfo": "White",
    "propWeightG": 8496,
    "samples": [
      [ 0, 0 ], [ 0.039, 1716 ], [ 0.059, 2836 ], [ 0.141, 3488 ], [ 0.224, 3189 ], [ 0.373, 3136 ],
      [ 2.237, 2999 ], [ 2.905, 2928 ], [ 3.45, 2770 ], [ 3.462, 2787 ], [ 4.537, 2408 ],[ 4.804, 1949 ],
      [ 5.165, 1685 ], [ 5.511, 1182 ], [ 6.013, 313.1 ], [ 6.088, 0 ]
],
    "totImpulseNs": 15230,
    "totalWeightG": 13310,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 933.2,
    "burnTimeS": 1.7,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K935",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1581K935-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1073,
    "motorId": "5f4294d200023100000003b5",
    "propInfo": "Wild Wolf",
    "propWeightG": 732,
    "samples": [
      [ 0, 0 ], [ 0.01, 1073 ], [ 0.02, 1044 ], [ 0.04, 974.2 ], [ 0.07, 1016 ], [ 0.1, 1029 ],
      [ 0.14, 1041 ], [ 0.17, 1050 ], [ 0.22, 1060 ], [ 0.25, 1064 ], [ 0.29, 1070 ], [ 0.34, 1073 ],
      [ 0.39, 1072 ], [ 0.42, 1070 ], [ 0.45, 1073 ], [ 0.49, 1070 ], [ 0.52, 1070 ], [ 0.58, 1064 ],
      [ 0.64, 1060 ], [ 0.7, 1055 ], [ 0.76, 1045 ], [ 0.81, 1035 ], [ 0.85, 1029 ], [ 0.88, 1022 ],
      [ 0.91, 1018 ], [ 0.94, 1015 ], [ 0.97, 1009 ], [ 1, 1003 ], [ 1.06, 985.7 ], [ 1.12, 965.9 ],
      [ 1.16, 950.9 ], [ 1.21, 936.7 ], [ 1.26, 919 ], [ 1.3, 903.3 ], [ 1.34, 890 ], [ 1.38, 876.2 ],
      [ 1.43, 861.1 ], [ 1.48, 847 ], [ 1.51, 719.7 ], [ 1.54, 619.6 ], [ 1.56, 510.3 ], [ 1.58, 415.1 ],
      [ 1.6, 295.7 ], [ 1.63, 181.5 ], [ 1.66, 66.76 ], [ 1.7, 0 ]
],
    "totImpulseNs": 1581,
    "totalWeightG": 1508,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 579,
    "burnTimeS": 3.19,
    "caseInfo": "AMW 54-1750",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K580",
    "dataFiles": 1,
    "delays": "P",
    "designation": "1852K580-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 1077,
    "motorId": "5f4294d200023100000003b6",
    "propInfo": "Tamed Tiger",
    "propWeightG": 895,
    "samples": [
      [ 0, 0 ], [ 0.02, 624.9 ], [ 0.03, 1077 ], [ 0.07, 846.7 ], [ 0.12, 884.6 ], [ 0.2, 855.5 ],
      [ 0.29, 840.9 ], [ 0.39, 835.1 ], [ 0.48, 822.6 ], [ 0.56, 808.6 ], [ 0.66, 800.6 ],
      [ 0.74, 784.6 ], [ 0.83, 776.7 ], [ 0.9, 762.7 ], [ 0.96, 760.7 ], [ 1.03, 748.7 ],[ 1.1, 734.3 ],
      [ 1.14, 713.2 ], [ 1.18, 720.8 ], [ 1.27, 704.8 ], [ 1.35, 682.8 ], [ 1.43, 666.9 ],
      [ 1.51, 650.9 ], [ 1.59, 632.9 ], [ 1.67, 622.9 ], [ 1.73, 605 ], [ 1.79, 599 ], [ 1.86, 579 ],
      [ 1.94, 561 ], [ 1.99, 559 ], [ 2.03, 539.1 ], [ 2.09, 533.1 ], [ 2.16, 517.1 ], [ 2.24, 507.1 ],
      [ 2.31, 491.1 ], [ 2.39, 477.2 ], [ 2.45, 463.2 ], [ 2.51, 442.3 ], [ 2.55, 407.4 ],
      [ 2.6, 360.8 ], [ 2.65, 320.1 ], [ 2.69, 276.4 ], [ 2.76, 232.8 ], [ 2.82, 194.9 ],[ 2.96, 122.2 ],
      [ 3.2, 0 ]
],
    "totImpulseNs": 1852,
    "totalWeightG": 1823,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 340,
    "burnTimeS": 1.86,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I350",
    "dataFiles": 2,
    "delays": "6,8,10",
    "designation": "I350R",
    "diameter": 38,
    "impulseClass": "I",
    "length": 355.6,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 473,
    "motorId": "5f4294d200023100000003b7",
    "propInfo": "Redline",
    "propWeightG": 348,
    "samples": [
      [ 0, 0 ], [ 0.004, 2.809 ], [ 0.008, 207.9 ], [ 0.031, 272.5 ], [ 0.035, 359.6 ], [ 0.046, 407.4 ],
      [ 0.081, 432.6 ], [ 0.104, 435.5 ], [ 0.127, 427 ], [ 0.184, 435.5 ], [ 0.223, 443.9 ],
      [ 0.299, 469.2 ], [ 0.399, 480.4 ], [ 0.503, 486 ], [ 0.603, 474.8 ], [ 0.702, 469.2 ],
      [ 0.802, 457.9 ], [ 0.906, 443.9 ], [ 1.002, 432.6 ], [ 1.098, 415.8 ], [ 1.205, 390.5 ],
      [ 1.298, 368 ], [ 1.401, 345.6 ], [ 1.443, 325.9 ], [ 1.497, 216.3 ], [ 1.532, 101.1 ],
      [ 1.57, 33.71 ], [ 1.605, 0 ]
],
    "totImpulseNs": 634.6,
    "totalWeightG": 616,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 74.3,
    "burnTimeS": 0.33,
    "caseInfo": "Pro24-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "E75",
    "dataFiles": 2,
    "delays": "8,10,12,14,17",
    "designation": "25E75-17A",
    "diameter": 24,
    "impulseClass": "E",
    "length": 69,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 99.9,
    "motorId": "5f4294d200023100000003b8",
    "propInfo": "Vmax",
    "propWeightG": 104,
    "samples": [
      [ 0, 0 ], [ 0.009, 84.21 ], [ 0.012, 95.1 ], [ 0.023, 77.08 ], [ 0.027, 68.7 ], [ 0.047, 73.45 ],
      [ 0.092, 81.83 ], [ 0.118, 83.84 ], [ 0.141, 86.47 ], [ 0.192, 86.97 ], [ 0.222, 85.34 ],
      [ 0.25, 80.08 ], [ 0.26, 78.33 ], [ 0.281, 82.96 ], [ 0.287, 78.21 ], [ 0.306, 24.78 ],
      [ 0.314, 14.14 ], [ 0.326, 8.509 ], [ 0.329, 0 ]
],
    "totImpulseNs": 24.8,
    "totalWeightG": 52,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 98.1,
    "burnTimeS": 1.17,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G100",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "114G100-14A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 178.8,
    "motorId": "5f4294d200023100000003b9",
    "propInfo": "Skidmark",
    "propWeightG": 63.3,
    "samples": [
      [ 0, 0 ], [ 0.009, 148.5 ], [ 0.024, 178.2 ], [ 0.061, 145.3 ], [ 0.475, 117.4 ], [ 0.63, 106.2 ],
      [ 0.833, 85.13 ], [ 0.961, 51.76 ], [ 1.024, 29.97 ], [ 1.125, 13.85 ], [ 1.186, 0 ]
],
    "sparky": true,
    "totImpulseNs": 114.5,
    "totalWeightG": 159,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 127.4,
    "burnTimeS": 1.08,
    "caseInfo": "Pro24-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G127",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "137G127-14A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 182.1,
    "motorId": "5f4294d200023100000003ba",
    "propInfo": "Red Lightning",
    "propWeightG": 70.5,
    "samples": [
      [ 0, 0 ], [ 0.018, 164.5 ], [ 0.029, 175.5 ], [ 0.042, 172.1 ], [ 0.14, 179.8 ], [ 0.378, 158.8 ],
      [ 0.578, 138.6 ], [ 0.763, 121.5 ], [ 0.838, 97.64 ], [ 0.9, 62.32 ], [ 0.966, 32.62 ],
      [ 1.056, 13.05 ], [ 1.081, 0 ]
],
    "totImpulseNs": 137.3,
    "totalWeightG": 166,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 85,
    "burnTimeS": 0.87,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F85",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "74F85-15A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 105.6,
    "motorId": "5f4294d200023100000003bb",
    "propInfo": "White Thunder",
    "propWeightG": 33.7,
    "samples": [
      [ 0, 0 ], [ 0.01, 76.07 ], [ 0.023, 100.2 ], [ 0.04, 92.42 ], [ 0.118, 100.9 ], [ 0.283, 102.4 ],
      [ 0.51, 96.44 ], [ 0.688, 87.44 ], [ 0.787, 25.91 ], [ 0.852, 7.206 ], [ 0.873, 0 ]
],
    "totImpulseNs": 73.6,
    "totalWeightG": 96,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 740.1,
    "burnTimeS": 2.53,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K740",
    "dataFiles": 2,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "1874K740-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 883.9,
    "motorId": "5f4294d200023100000003bc",
    "propInfo": "C-Star",
    "propWeightG": 846.2,
    "samples": [
      [ 0, 0 ], [ 0.02, 735.2 ], [ 0.052, 853.4 ], [ 0.486, 869.3 ], [ 0.893, 848.9 ], [ 1.903, 735.2 ],
      [ 2.131, 722.7 ], [ 2.32, 330.7 ], [ 2.462, 101.1 ], [ 2.5, 0 ]
],
    "totImpulseNs": 1874,
    "totalWeightG": 1469,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 244.3,
    "burnTimeS": 3.55,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J244",
    "dataFiles": 2,
    "delays": "4,5,6,7,8,9,10,11,12,13,14",
    "designation": "867J244-14A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 270.3,
    "motorId": "5f4294d200023100000003bd",
    "propInfo": "White",
    "propWeightG": 472.1,
    "samples": [
      [ 0, 0 ], [ 0.029, 154.1 ], [ 0.1, 262.5 ], [ 0.161, 235.7 ], [ 0.484, 256.6 ], [ 0.853, 261.2 ],
      [ 1.348, 266.5 ], [ 1.904, 262.5 ], [ 2.422, 251.9 ], [ 2.855, 235.7 ], [ 3.176, 223.1 ],
      [ 3.252, 221.8 ], [ 3.362, 185 ], [ 3.5, 0 ]
],
    "totImpulseNs": 867.2,
    "totalWeightG": 911,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 363.6,
    "burnTimeS": 3.52,
    "caseInfo": "Pro54-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K360",
    "dataFiles": 2,
    "delays": "3,4,5,6,7,8,9,10,11,12,13",
    "designation": "1281K360-13A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 329,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 405.5,
    "motorId": "5f4294d200023100000003be",
    "propInfo": "White",
    "propWeightG": 708.2,
    "samples": [
      [ 0, 0 ], [ 0.034, 289.3 ], [ 0.077, 362.3 ], [ 0.463, 387.5 ], [ 1.106, 398.6 ], [ 1.564, 405.2 ],
      [ 2.063, 398.6 ], [ 2.57, 383.5 ], [ 3.101, 354.8 ], [ 3.18, 343.7 ], [ 3.417, 105.3 ],
      [ 3.5, 0 ]
],
    "totImpulseNs": 1281,
    "totalWeightG": 1232,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 837.9,
    "burnTimeS": 9,
    "caseInfo": "Pro75-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M840",
    "dataFiles": 2,
    "delays": "P",
    "designation": "7521M840-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 879.3,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2043,
    "motorId": "5f4294d200023100000003bf",
    "propInfo": "White",
    "propWeightG": 4249,
    "samples": [
      [ 0, 0 ], [ 0.072, 1980 ], [ 0.246, 1447 ], [ 0.835, 1121 ], [ 2.132, 1094 ], [ 3.922, 1003 ],
      [ 5.441, 810.2 ], [ 6.559, 621.5 ], [ 7.357, 472.8 ], [ 8.547, 199.8 ], [ 8.973, 97.67 ],
      [ 9.009, 0 ]
],
    "totImpulseNs": 7521,
    "totalWeightG": 6954,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 555.1,
    "burnTimeS": 4.33,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K555",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2406K555-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 646.7,
    "motorId": "5f4294d200023100000003c0",
    "propInfo": "White",
    "propWeightG": 1407,
    "samples": [
      [ 0, 0 ], [ 0.038, 426.1 ], [ 0.07, 585.3 ], [ 0.137, 522.4 ], [ 0.751, 543.9 ], [ 1.732, 629.1 ],
      [ 2.057, 639.5 ], [ 3.221, 571.8 ], [ 3.84, 516 ], [ 4.091, 472.2 ], [ 4.163, 399 ],
      [ 4.274, 148.9 ], [ 4.314, 0 ]
],
    "totImpulseNs": 2406,
    "totalWeightG": 2759,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 849.1,
    "burnTimeS": 4.34,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L851",
    "dataFiles": 2,
    "delays": "P",
    "designation": "3683L851-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 989.9,
    "motorId": "5f4294d200023100000003c1",
    "propInfo": "White",
    "propWeightG": 2110,
    "samples": [
      [ 0, 0 ], [ 0.059, 971.3 ], [ 0.102, 855.2 ], [ 0.485, 838.7 ], [ 1.353, 911.6 ], [ 1.824, 980.1 ],
      [ 3.107, 884 ], [ 3.68, 816.6 ], [ 4.031, 732.6 ], [ 4.128, 579 ], [ 4.256, 246.4 ],
      [ 4.339, 0 ]
],
    "totImpulseNs": 3683,
    "totalWeightG": 3789,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3294,
    "burnTimeS": 5.86,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N3301",
    "dataFiles": 2,
    "delays": "P",
    "designation": "19318N3301-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 5076,
    "motorId": "5f4294d200023100000003c2",
    "propInfo": "White",
    "propWeightG": 10660,
    "samples": [
      [ 0, 0 ], [ 0.027, 5000 ], [ 0.074, 4201 ], [ 0.114, 4416 ], [ 0.325, 4091 ], [ 1.438, 3993 ],
      [ 2.391, 3964 ], [ 3.139, 3843 ], [ 4.033, 3524 ], [ 4.425, 2558 ], [ 4.774, 2315 ],
      [ 5.318, 1117 ], [ 5.793, 329.9 ], [ 5.875, 0 ]
],
    "totImpulseNs": 19320,
    "totalWeightG": 16530,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 162.6,
    "burnTimeS": 3.19,
    "caseInfo": "Pro54-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I165",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "518I165-17A",
    "diameter": 54,
    "impulseClass": "I",
    "length": 69,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 190.5,
    "motorId": "5f4294d200023100000003c3",
    "propInfo": "C-Star",
    "propWeightG": 230.1,
    "samples": [
      [ 0, 0 ], [ 0.027, 133.4 ], [ 0.056, 174.8 ], [ 0.067, 180 ], [ 0.497, 188.6 ], [ 1.018, 184 ],
      [ 1.704, 170 ], [ 2.95, 129.4 ], [ 3.05, 130.7 ], [ 3.168, 44.66 ], [ 3.213, 17.86 ],
      [ 3.271, 3.518 ], [ 3.5, 0 ]
],
    "totImpulseNs": 512.2,
    "totalWeightG": 594,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 23510,
    "burnTimeS": 1.31,
    "caseInfo": "Pro130-",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O25000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "30795O25000-P",
    "diameter": 132,
    "impulseClass": "O",
    "infoUrl": "http://www.pro38.com/products/pro130/motor/MotorData.php?prodid=30795O25000-P",
    "length": 1407,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 29280,
    "motorId": "5f4294d200023100000003c4",
    "propInfo": "Vmax",
    "propWeightG": 14470,
    "samples": [
      [ 0, 0 ], [ 0.008, 21110 ], [ 0.022, 24710 ], [ 0.042, 26280 ], [ 0.067, 24390 ], [ 0.201, 24040 ],
      [ 0.56, 25050 ], [ 0.927, 24080 ], [ 1.202, 22820 ], [ 1.25, 23600 ], [ 1.297, 4853 ],
      [ 1.32, 0 ]
],
    "totImpulseNs": 30790,
    "totalWeightG": 23560,
    "type": "SU",
    "updatedOn": "2021-07-04"
  },
  {
    "availability": "regular",
    "avgThrustN": 162,
    "burnTimeS": 1.74,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H178",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "H178DM",
    "diameter": 38,
    "impulseClass": "H",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 202,
    "motorId": "5f4294d200023100000003c5",
    "propInfo": "Dark Matter",
    "propWeightG": 177,
    "samples": [
      [ 0, 0 ], [ 0.016, 132.7 ], [ 0.099, 165.7 ], [ 0.146, 163 ], [ 0.201, 163.4 ], [ 0.256, 164.9 ],
      [ 0.301, 168.5 ], [ 0.358, 173.2 ], [ 0.401, 176.4 ], [ 0.457, 177.9 ], [ 0.498, 183 ],
      [ 0.6, 198.8 ], [ 0.635, 204.3 ], [ 0.703, 204.3 ], [ 0.799, 215.7 ], [ 0.901, 210.6 ],
      [ 0.999, 211.8 ], [ 1.049, 205.1 ], [ 1.098, 206.3 ], [ 1.202, 196.8 ], [ 1.297, 178.3 ],
      [ 1.351, 172.8 ], [ 1.404, 178.3 ], [ 1.434, 186.2 ], [ 1.499, 125.6 ], [ 1.539, 71.25 ],
      [ 1.566, 44.88 ], [ 1.582, 31.49 ], [ 1.601, 27.95 ], [ 1.606, 16.53 ], [ 1.617, 18.89 ],
      [ 1.644, 8.66 ], [ 1.684, 4.33 ], [ 1.735, 0 ]
],
    "sparky": true,
    "totImpulseNs": 283,
    "totalWeightG": 324,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 327,
    "burnTimeS": 1.72,
    "caseInfo": "RMS-38/720",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I327",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "I327DM",
    "diameter": 38,
    "impulseClass": "I",
    "length": 337,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 400,
    "motorId": "5f4294d200023100000003c6",
    "propInfo": "Dark Matter",
    "propWeightG": 354,
    "samples": [
      [ 0, 0 ], [ 0.009, 393.7 ], [ 0.026, 432.6 ], [ 0.161, 387.1 ], [ 0.245, 382.7 ], [ 0.303, 371 ],
      [ 0.601, 372.5 ], [ 0.702, 371 ], [ 0.798, 375.4 ], [ 0.983, 371.7 ], [ 1.04, 370.3 ],
      [ 1.104, 371.7 ], [ 1.199, 343.1 ], [ 1.3, 335.8 ], [ 1.335, 357.8 ], [ 1.365, 327.8 ],
      [ 1.399, 265.4 ], [ 1.428, 185.5 ], [ 1.451, 156.9 ], [ 1.493, 112.2 ], [ 1.553, 68.19 ],
      [ 1.601, 32.26 ], [ 1.656, 11.73 ], [ 1.723, 0 ]
],
    "sparky": true,
    "totImpulseNs": 539,
    "totalWeightG": 628,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 438,
    "burnTimeS": 2.89,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K456",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K456DM",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 534,
    "motorId": "5f4294d200023100000003c7",
    "propInfo": "Dark Matter",
    "propWeightG": 866,
    "samples": [
      [ 0, 0 ], [ 0.027, 444.1 ], [ 0.038, 519.6 ], [ 0.066, 482.9 ], [ 0.129, 461.3 ], [ 0.302, 468 ],
      [ 0.389, 468.7 ], [ 0.499, 477 ], [ 0.655, 476.2 ], [ 0.798, 483.7 ], [ 0.946, 488.9 ],
      [ 1.096, 499.4 ], [ 1.401, 500.9 ], [ 1.603, 503.9 ], [ 1.801, 501.6 ], [ 1.905, 498.6 ],
      [ 2.1, 485.9 ], [ 2.302, 456 ], [ 2.404, 435.9 ], [ 2.505, 420.2 ], [ 2.574, 398.5 ],
      [ 2.607, 373.1 ], [ 2.645, 356.6 ], [ 2.703, 289.3 ], [ 2.768, 178.7 ], [ 2.804, 123.4 ],
      [ 2.837, 88.96 ], [ 2.875, 50.84 ], [ 2.908, 24.67 ], [ 2.957, 10.47 ], [ 3.037, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1281,
    "totalWeightG": 1484,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 900,
    "burnTimeS": 4.4,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L900",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L900DM",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "motorId": "5f4294d200023100000003c8",
    "propInfo": "Dark Matter",
    "propWeightG": 2472,
    "samples": [
      [ 0, 0 ], [ 0.016, 578 ], [ 0.041, 870.4 ], [ 0.086, 851.8 ], [ 0.303, 931.2 ], [ 0.417, 944.7 ],
      [ 0.515, 910.9 ], [ 0.998, 936.3 ], [ 1.272, 976.9 ], [ 1.501, 992.1 ], [ 1.747, 1019 ],
      [ 2, 1029 ], [ 2.205, 1055 ], [ 2.495, 1048 ], [ 2.77, 1034 ], [ 3.003, 998.8 ], [ 3.166, 981.9 ],
      [ 3.498, 909.2 ], [ 3.592, 880.5 ], [ 3.702, 890.7 ], [ 3.768, 856.9 ], [ 3.862, 672.6 ],
      [ 3.964, 442.8 ], [ 4.021, 353.2 ], [ 4.111, 285.6 ], [ 4.242, 104.8 ], [ 4.3, 50.7 ],
      [ 4.369, 21.97 ], [ 4.484, 0 ]
],
    "sparky": true,
    "totImpulseNs": 3868,
    "totalWeightG": 4724,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1103,
    "burnTimeS": 1.6,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1103",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K1103X",
    "diameter": 54,
    "impulseClass": "K",
    "length": 401,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1780,
    "motorId": "5f4294d200023100000003c9",
    "propInfo": "Propellant X",
    "propWeightG": 830,
    "samples": [
      [ 0, 0 ], [ 0.012, 1336 ], [ 0.016, 1505 ], [ 0.028, 1620 ], [ 0.045, 1500 ], [ 0.081, 1393 ],
      [ 0.151, 1331 ], [ 0.201, 1306 ], [ 0.399, 1281 ], [ 0.602, 1244 ], [ 0.803, 1196 ],
      [ 1.001, 1144 ], [ 1.203, 1074 ], [ 1.296, 1042 ], [ 1.384, 1032 ], [ 1.408, 1004 ],
      [ 1.423, 961.9 ], [ 1.438, 839.8 ], [ 1.46, 737.6 ], [ 1.49, 510.9 ], [ 1.505, 446.1 ],
      [ 1.603, 176.9 ], [ 1.608, 209.3 ], [ 1.638, 97.19 ], [ 1.688, 0 ]
],
    "totImpulseNs": 1789,
    "totalWeightG": 1459,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1465,
    "burnTimeS": 5.11,
    "caseInfo": "98/11000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1465",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M1465BL",
    "diameter": 98,
    "impulseClass": "M",
    "length": 870,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2219,
    "motorId": "5f4294d200023100000003ca",
    "propInfo": "Black Lightning",
    "propWeightG": 4992,
    "samples": [
      [ 0, 0 ], [ 0.33, 1113 ], [ 1.65, 1752 ], [ 3, 2219 ], [ 3.513, 2100 ], [ 3.983, 1824 ],
      [ 4.3, 1335 ], [ 4.453, 334 ], [ 5.2, 0 ]
],
    "sparky": true,
    "totImpulseNs": 7532,
    "totalWeightG": 9801,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1025,
    "burnTimeS": 9.36,
    "caseInfo": "98/11000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1025",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M1025WC",
    "diameter": 98,
    "impulseClass": "M",
    "length": 870,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1852,
    "motorId": "5f4294d200023100000003cb",
    "propInfo": "White Cloud",
    "propWeightG": 5329,
    "samples": [
      [ 0, 0 ], [ 0.1, 1780 ], [ 0.5, 1558 ], [ 3.256, 1500 ], [ 6.179, 795.1 ], [ 9.363, 0 ]
],
    "totImpulseNs": 9580,
    "totalWeightG": 10140,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1406,
    "burnTimeS": 4.8,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1305",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1305M",
    "diameter": 98,
    "impulseClass": "M",
    "length": 597,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2098,
    "motorId": "5f4294d200023100000003cc",
    "propInfo": "Metalstorm",
    "propWeightG": 4080,
    "samples": [
      [ 0, 0 ], [ 0.016, 1288 ], [ 0.043, 1463 ], [ 0.08, 1371 ], [ 0.407, 1623 ], [ 0.535, 1640 ],
      [ 0.787, 1706 ], [ 0.985, 1694 ], [ 1.13, 1717 ], [ 1.328, 1694 ], [ 1.349, 1928 ],[ 1.397, 1726 ],
      [ 1.751, 1712 ], [ 2.018, 1688 ], [ 2.275, 1691 ], [ 2.297, 1892 ], [ 2.318, 1679 ],
      [ 2.929, 1412 ], [ 3.748, 1113 ], [ 4.24, 906.1 ], [ 4.503, 802.5 ], [ 4.717, 663.3 ],
      [ 4.813, 538.9 ], [ 4.92, 387.9 ], [ 5.027, 245.8 ], [ 5.166, 145.1 ], [ 5.263, 79.95 ],
      [ 5.386, 32.57 ], [ 5.498, 23.69 ], [ 5.707, 0 ]
],
    "sparky": true,
    "totImpulseNs": 6891,
    "totalWeightG": 7098,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1070,
    "burnTimeS": 5.2,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1075",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1075DM",
    "diameter": 98,
    "impulseClass": "M",
    "length": 597,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1330,
    "motorId": "5f4294d200023100000003cd",
    "propInfo": "Dark Matter",
    "propWeightG": 3846,
    "samples": [
      [ 0, 0 ], [ 0.02, 927.5 ], [ 0.112, 988.1 ], [ 0.26, 1034 ], [ 0.53, 1081 ], [ 0.897, 1124 ],
      [ 1.509, 1198 ], [ 1.998, 1240 ], [ 2.355, 1261 ], [ 2.503, 1264 ], [ 2.773, 1238 ],
      [ 2.997, 1223 ], [ 3.502, 1162 ], [ 4.001, 1064 ], [ 4.501, 906.7 ], [ 4.741, 834.8 ],
      [ 4.878, 742 ], [ 4.96, 664.4 ], [ 5.062, 448.6 ], [ 5.164, 223.4 ], [ 5.26, 79.5 ],
      [ 5.352, 30.29 ], [ 5.5, 0 ]
],
    "sparky": true,
    "totImpulseNs": 5571,
    "totalWeightG": 6971,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 992,
    "burnTimeS": 3.79,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1040",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1040DM",
    "diameter": 75,
    "impulseClass": "L",
    "length": 681,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1255,
    "motorId": "5f4294d200023100000003ce",
    "propInfo": "Dark Matter",
    "propWeightG": 2602,
    "samples": [
      [ 0, 0 ], [ 0.018, 1126 ], [ 0.042, 1036 ], [ 0.053, 1003 ], [ 0.116, 989 ], [ 0.504, 1050 ],
      [ 0.794, 1096 ], [ 1.002, 1146 ], [ 1.252, 1192 ], [ 1.499, 1238 ], [ 1.753, 1262 ],
      [ 1.912, 1268 ], [ 2.095, 1255 ], [ 2.511, 1135 ], [ 2.772, 1057 ], [ 3.012, 967 ],[ 3.153, 933.1 ],
      [ 3.217, 863.7 ], [ 3.291, 818 ], [ 3.34, 758.7 ], [ 3.488, 438.6 ], [ 3.552, 284.5 ],
      [ 3.598, 220.2 ], [ 3.657, 167.7 ], [ 3.746, 118.5 ], [ 3.88, 42.34 ], [ 3.996, 6.774 ],
      [ 4.127, 0 ]
],
    "sparky": true,
    "totImpulseNs": 3769,
    "totalWeightG": 4717,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 30.8,
    "burnTimeS": 0.85,
    "caseInfo": "Pro24-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "E31",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "26E31-15A",
    "diameter": 24,
    "impulseClass": "E",
    "length": 69,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 52.6,
    "motorId": "5f4294d200023100000003cf",
    "propInfo": "White Thunder",
    "propWeightG": 11.1,
    "samples": [
      [ 0, 0 ], [ 0.02, 43.82 ], [ 0.027, 39.96 ], [ 0.049, 26.78 ], [ 0.113, 32.6 ], [ 0.193, 34.74 ],
      [ 0.282, 35.81 ], [ 0.5, 34.44 ], [ 0.727, 29.28 ], [ 0.771, 22.74 ], [ 0.807, 9.561 ],
      [ 0.84, 3.563 ], [ 0.87, 0 ]
],
    "totImpulseNs": 26.1,
    "totalWeightG": 52,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 23.4,
    "burnTimeS": 1.03,
    "caseInfo": "Pro24-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "E22",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "24E22-13A",
    "diameter": 24,
    "impulseClass": "E",
    "length": 69,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 34.4,
    "motorId": "5f4294d200023100000003d0",
    "propInfo": "Smoky Sam",
    "propWeightG": 13.4,
    "samples": [
      [ 0, 0 ], [ 0.008, 18.29 ], [ 0.026, 30 ], [ 0.038, 30.79 ], [ 0.067, 18.71 ], [ 0.101, 21.88 ],
      [ 0.33, 26.08 ], [ 0.528, 28.04 ], [ 0.716, 27.88 ], [ 0.841, 23.54 ], [ 0.912, 17.83 ],
      [ 0.987, 7 ], [ 1.016, 3.333 ], [ 1.065, 1.083 ], [ 1.087, 0 ]
],
    "totImpulseNs": 24.2,
    "totalWeightG": 56.5,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 50,
    "burnTimeS": 1.19,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F50",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "60F50-13A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 69.9,
    "motorId": "5f4294d200023100000003d1",
    "propInfo": "Skidmark",
    "propWeightG": 31.7,
    "samples": [
      [ 0, 0 ], [ 0.015, 64.98 ], [ 0.022, 69.52 ], [ 0.064, 55.54 ], [ 0.118, 62.81 ], [ 0.342, 62.15 ],
      [ 0.536, 59.41 ], [ 0.743, 53.84 ], [ 0.884, 46.94 ], [ 0.976, 40.05 ], [ 1.096, 12.56 ],
      [ 1.246, 2.078 ], [ 1.298, 0 ]
],
    "sparky": true,
    "totImpulseNs": 59.7,
    "totalWeightG": 93.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 50,
    "burnTimeS": 1.5,
    "caseInfo": "Pro24-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F51",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "75F51-12A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 133,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 85.1,
    "motorId": "5f4294d200023100000003d2",
    "propInfo": "Classic",
    "propWeightG": 33,
    "samples": [
      [ 0, 0 ], [ 0.02, 75.92 ], [ 0.031, 84.15 ], [ 0.062, 70.44 ], [ 0.117, 73.66 ], [ 1.211, 38.74 ],
      [ 1.376, 14.78 ], [ 1.456, 7.271 ], [ 1.532, 3.337 ], [ 1.577, 0 ]
],
    "totImpulseNs": 75,
    "totalWeightG": 95,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 452.7,
    "burnTimeS": 2.24,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J453",
    "dataFiles": 2,
    "delays": "7,9,11,13,16",
    "designation": "1013J453-16A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 725.3,
    "motorId": "5f4294d200023100000003d3",
    "propInfo": "White",
    "propWeightG": 585.5,
    "samples": [
      [ 0, 0 ], [ 0.018, 663.8 ], [ 0.04, 725.2 ], [ 0.105, 630.2 ], [ 0.23, 578.4 ], [ 0.451, 543.9 ],
      [ 1.454, 535.3 ], [ 1.797, 291.6 ], [ 1.91, 189 ], [ 2.088, 128.5 ], [ 2.276, 19.18 ],
      [ 2.364, 0 ]
],
    "totImpulseNs": 1013,
    "totalWeightG": 964.3,
    "type": "reload",
    "updatedOn": "2021-02-26"
  },
  {
    "availability": "regular",
    "avgThrustN": 315.1,
    "burnTimeS": 2.08,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J316",
    "dataFiles": 2,
    "delays": "8,10,12,14,17",
    "designation": "654J316-17A",
    "diameter": 38,
    "impulseClass": "J",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 448.7,
    "motorId": "5f4294d200023100000003d4",
    "propInfo": "Pink",
    "propWeightG": 256.3,
    "samples": [
      [ 0, 0 ], [ 0.017, 393.1 ], [ 0.026, 443.3 ], [ 0.064, 381.1 ], [ 0.223, 393.1 ], [ 0.503, 379.3 ],
      [ 1.029, 354.2 ], [ 1.52, 320.8 ], [ 1.672, 313 ], [ 1.695, 296.3 ], [ 1.816, 145.8 ],
      [ 1.901, 88.41 ], [ 2.043, 32.85 ], [ 2.184, 0 ]
],
    "totImpulseNs": 654.3,
    "totalWeightG": 607.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 804.5,
    "burnTimeS": 3.52,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L805",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2833L805-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1634,
    "motorId": "5f4294d200023100000003d5",
    "propInfo": "White",
    "propWeightG": 1640,
    "samples": [
      [ 0, 0 ], [ 0.012, 1618 ], [ 0.037, 1172 ], [ 0.173, 1028 ], [ 0.388, 994.1 ], [ 0.626, 985.7 ],
      [ 2.318, 886.3 ], [ 3.054, 448.4 ], [ 3.464, 126.9 ], [ 3.608, 40.19 ], [ 3.827, 0 ]
],
    "totImpulseNs": 2833,
    "totalWeightG": 2503,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 651.5,
    "burnTimeS": 3.07,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K650",
    "dataFiles": 2,
    "delays": "11,12,13,14,15,16,17,18,19,20,21",
    "designation": "1997K650-21A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1355,
    "motorId": "5f4294d200023100000003d6",
    "propInfo": "Pink",
    "propWeightG": 1135,
    "samples": [
      [ 0, 0 ], [ 0.011, 1354 ], [ 0.028, 793.8 ], [ 0.186, 815.4 ], [ 2.578, 601.2 ], [ 2.689, 480 ],
      [ 2.855, 189.3 ], [ 3.086, 43.18 ], [ 3.451, 0 ]
],
    "totImpulseNs": 1997,
    "totalWeightG": 1710,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2036,
    "burnTimeS": 1.14,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K2000",
    "dataFiles": 2,
    "designation": "2330K2000-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2481,
    "motorId": "5f4294d200023100000003d7",
    "propInfo": "Vmax",
    "propWeightG": 1102,
    "samples": [
      [ 0, 0 ], [ 0.01, 1980 ], [ 0.029, 1635 ], [ 0.161, 1947 ], [ 0.321, 2190 ], [ 0.457, 2398 ],
      [ 0.548, 2474 ], [ 0.678, 2383 ], [ 0.768, 2263 ], [ 0.977, 1924 ], [ 1.027, 1883 ],
      [ 1.104, 859.6 ], [ 1.149, 87.72 ], [ 1.191, 0 ]
],
    "totImpulseNs": 2330,
    "totalWeightG": 2465,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3209,
    "burnTimeS": 1.03,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L3200",
    "dataFiles": 2,
    "designation": "3300L3200-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3723,
    "motorId": "5f4294d200023100000003d8",
    "propInfo": "Vmax",
    "propWeightG": 1555,
    "samples": [
      [ 0, 0 ], [ 0.008, 3315 ], [ 0.024, 2673 ], [ 0.108, 2975 ], [ 0.415, 3669 ], [ 0.524, 3712 ],
      [ 0.644, 3669 ], [ 0.819, 3226 ], [ 0.911, 3022 ], [ 0.937, 3051 ], [ 0.957, 2900 ],
      [ 1.022, 288.1 ], [ 1.039, 51.95 ], [ 1.055, 0 ]
],
    "totImpulseNs": 3300,
    "totalWeightG": 3264,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 644.8,
    "burnTimeS": 5.3,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L645",
    "dataFiles": 2,
    "designation": "3419L645-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 776.6,
    "motorId": "5f4294d200023100000003d9",
    "propInfo": "Green3",
    "propWeightG": 2072,
    "samples": [
      [ 0, 0 ], [ 0.05, 511.2 ], [ 0.12, 647.6 ], [ 0.256, 689.2 ], [ 0.425, 684.5 ], [ 1.554, 736.6 ],
      [ 2.043, 765 ], [ 3.075, 687.3 ], [ 4.557, 601.2 ], [ 4.756, 550.1 ], [ 4.985, 412.8 ],
      [ 5.281, 69.11 ], [ 5.39, 0 ]
],
    "totImpulseNs": 3420,
    "totalWeightG": 3752,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 735.7,
    "burnTimeS": 2.66,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K735",
    "dataFiles": 2,
    "designation": "1955K735-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 911,
    "motorId": "5f4294d200023100000003da",
    "propInfo": "Skidmark",
    "propWeightG": 1173,
    "samples": [
      [ 0, 0 ], [ 0.053, 683.2 ], [ 0.142, 593.6 ], [ 0.408, 697.3 ], [ 0.893, 848.1 ], [ 1.109, 886.9 ],
      [ 1.24, 882.2 ], [ 1.362, 889.3 ], [ 2, 783.3 ], [ 2.346, 706.7 ], [ 2.439, 649 ], [ 2.612, 123.7 ],
      [ 2.676, 36.51 ], [ 2.754, 12.96 ], [ 2.831, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1955,
    "totalWeightG": 2509,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 907.1,
    "burnTimeS": 3.15,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L910",
    "dataFiles": 2,
    "designation": "2856L910-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1086,
    "motorId": "5f4294d200023100000003db",
    "propInfo": "C-Star",
    "propWeightG": 1270,
    "samples": [
      [ 0, 0 ], [ 0.034, 858.7 ], [ 0.056, 921.7 ], [ 0.305, 952.4 ], [ 0.718, 983.2 ], [ 1.221, 1048 ],
      [ 1.642, 1006 ], [ 1.842, 973.4 ], [ 2.951, 773.4 ], [ 3.035, 584.6 ], [ 3.108, 169.2 ],
      [ 3.152, 72.73 ], [ 3.182, 27.97 ], [ 3.262, 0 ]
],
    "totImpulseNs": 2856,
    "totalWeightG": 2616,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1350,
    "burnTimeS": 3.16,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1350",
    "dataFiles": 2,
    "designation": "4263L1350-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1673,
    "motorId": "5f4294d200023100000003dc",
    "propInfo": "C-Star",
    "propWeightG": 1905,
    "samples": [
      [ 0, 0 ], [ 0.016, 1422 ], [ 0.034, 1345 ], [ 0.049, 1502 ], [ 0.081, 1415 ], [ 0.21, 1432 ],
      [ 0.453, 1432 ], [ 0.809, 1462 ], [ 1.07, 1534 ], [ 1.28, 1541 ], [ 2.661, 1284 ], [ 2.843, 1277 ],
      [ 2.932, 1116 ], [ 3.037, 488.8 ], [ 3.163, 82.88 ], [ 3.284, 0 ]
],
    "totImpulseNs": 4263,
    "totalWeightG": 3571,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 660.5,
    "burnTimeS": 3.68,
    "caseInfo": "Pro75-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K661",
    "dataFiles": 2,
    "designation": "2430K661-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 350,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 761.3,
    "motorId": "5f4294d200023100000003dd",
    "propInfo": "Blue Streak",
    "propWeightG": 1182,
    "samples": [
      [ 0, 0 ], [ 0.041, 588.6 ], [ 0.073, 659.4 ], [ 0.122, 635.2 ], [ 0.225, 634.2 ], [ 0.679, 713.4 ],
      [ 1.039, 751.6 ], [ 1.241, 758.1 ], [ 1.832, 727.4 ], [ 2.298, 687.3 ], [ 2.729, 667.8 ],
      [ 3.195, 645.4 ], [ 3.367, 670.5 ], [ 3.584, 182.5 ], [ 3.672, 55.88 ], [ 3.72, 18.63 ],
      [ 3.798, 0 ]
],
    "totImpulseNs": 2430,
    "totalWeightG": 2528,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1046,
    "burnTimeS": 3.56,
    "caseInfo": "Pro75-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L1050",
    "dataFiles": 2,
    "designation": "3727L1050-P",
    "diameter": 75,
    "impulseClass": "L",
    "length": 486,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1208,
    "motorId": "5f4294d200023100000003de",
    "propInfo": "Blue Streak",
    "propWeightG": 1774,
    "samples": [
      [ 0, 0 ], [ 0.035, 721.4 ], [ 0.081, 1110 ], [ 0.131, 1057 ], [ 0.249, 1100 ], [ 0.913, 1194 ],
      [ 1.125, 1207 ], [ 2.271, 1100 ], [ 2.797, 1056 ], [ 3.076, 1043 ], [ 3.122, 996.5 ],
      [ 3.23, 807.1 ], [ 3.452, 289.9 ], [ 3.544, 123.5 ], [ 3.685, 36.23 ], [ 3.731, 0 ]
],
    "totImpulseNs": 3727,
    "totalWeightG": 3448,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2543,
    "burnTimeS": 7.04,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2540",
    "dataFiles": 2,
    "designation": "17907N2540-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2915,
    "motorId": "5f4294d200023100000003df",
    "propInfo": "Green3",
    "propWeightG": 10700,
    "samples": [
      [ 0, 0 ], [ 0.073, 2587 ], [ 0.11, 2790 ], [ 0.398, 2762 ], [ 1.14, 2762 ], [ 1.73, 2828 ],
      [ 2.613, 2895 ], [ 4.16, 2748 ], [ 5.666, 2566 ], [ 5.972, 2415 ], [ 6.338, 1953 ],[ 6.819, 745.6 ],
      [ 7.061, 101.5 ], [ 7.222, 0 ]
],
    "totImpulseNs": 17910,
    "totalWeightG": 16280,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1827,
    "burnTimeS": 3.07,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1830",
    "dataFiles": 2,
    "designation": "5604M1830-P",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://pro38.com/products/pro75/motor/MotorData.php?prodid=5604M1830-P",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2342,
    "motorId": "5f4294d200023100000003e0",
    "propInfo": "C-Star",
    "propWeightG": 2542,
    "samples": [
      [ 0, 0 ], [ 0.018, 2301 ], [ 0.028, 1953 ], [ 0.155, 2009 ], [ 1.111, 2121 ], [ 1.452, 2054 ],
      [ 1.782, 1958 ], [ 2.608, 1695 ], [ 2.677, 1546 ], [ 3.06, 115 ], [ 3.165, 0 ]
],
    "totImpulseNs": 5604,
    "totalWeightG": 4524,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1396,
    "burnTimeS": 4.49,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1401",
    "dataFiles": 1,
    "designation": "6268M1401-P",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://pro38.com/products/pro75/motor/MotorData.php?prodid=6268M1401-P",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1670,
    "motorId": "5f4294d200023100000003e1",
    "propInfo": "White",
    "propWeightG": 3508,
    "samples": [
      [ 0, 0 ], [ 0.079, 1669 ], [ 0.134, 1562 ], [ 0.189, 1513 ], [ 0.512, 1507 ], [ 1.009, 1519 ],
      [ 1.506, 1574 ], [ 1.679, 1598 ], [ 1.861, 1604 ], [ 2.003, 1574 ], [ 2.5, 1513 ], [ 2.981, 1483 ],
      [ 3.501, 1471 ], [ 3.722, 1434 ], [ 3.785, 1404 ], [ 3.911, 1191 ], [ 4.053, 1009 ],
      [ 4.116, 808 ], [ 4.235, 595 ], [ 4.345, 407 ], [ 4.447, 200 ], [ 4.589, 30 ], [ 4.794, 60 ],
      [ 4.99, 0 ]
],
    "totImpulseNs": 6268,
    "totalWeightG": 5774,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1160,
    "burnTimeS": 5.07,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1160",
    "dataFiles": 2,
    "designation": "5880M1160-P",
    "diameter": 75,
    "impulseClass": "M",
    "infoUrl": "http://pro38.com/products/pro75/motor/MotorData.php?prodid=5880M1160-P",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1382,
    "motorId": "5f4294d200023100000003e2",
    "propInfo": "Green3",
    "propWeightG": 3454,
    "samples": [
      [ 0, 0 ], [ 0.038, 929.5 ], [ 0.061, 745.2 ], [ 0.093, 1073 ], [ 0.189, 1183 ], [ 0.619, 1272 ],
      [ 1.382, 1311 ], [ 1.824, 1382 ], [ 2.424, 1270 ], [ 3.668, 1165 ], [ 3.68, 1166 ],[ 4.334, 1110 ],
      [ 4.527, 1040 ], [ 4.735, 763.3 ], [ 5.049, 102 ], [ 5.136, 0 ]
],
    "totImpulseNs": 5880,
    "totalWeightG": 5698,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 11.17,
    "burnTimeS": 2.44,
    "certOrg": "National Association of Rocketry",
    "commonName": "E12",
    "dataFiles": 2,
    "delays": "0,4,6,8",
    "designation": "E12",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/E12.pdf",
    "length": 95,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 33.29,
    "motorId": "5f4294d200023100000003e3",
    "propInfo": "black powder",
    "propWeightG": 35.9,
    "samples": [
      [ 0, 0 ], [ 0.052, 5.045 ], [ 0.096, 9.91 ], [ 0.196, 24.14 ], [ 0.251, 31.35 ], [ 0.287, 32.97 ],
      [ 0.3, 29.91 ], [ 0.344, 17.12 ], [ 0.37, 14.41 ], [ 0.4, 12.97 ], [ 0.5, 11.71 ], [ 0.6, 11.17 ],
      [ 0.7, 10.63 ], [ 0.8, 10.09 ], [ 0.9, 9.73 ], [ 1, 9.55 ], [ 1.101, 9.91 ], [ 1.2, 9.55 ],
      [ 1.3, 9.73 ], [ 1.4, 9.73 ], [ 1.5, 9.73 ], [ 1.6, 9.73 ], [ 1.7, 9.55 ], [ 1.8, 9.73 ],
      [ 1.9, 9.73 ], [ 2, 9.55 ], [ 2.1, 9.55 ], [ 2.2, 9.73 ], [ 2.3, 9.19 ], [ 2.375, 9.37 ],
      [ 2.4, 5.95 ], [ 2.44, 0 ]
],
    "totImpulseNs": 27.24,
    "totalWeightG": 58.7,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "OOP",
    "avgThrustN": 32.95,
    "burnTimeS": 1.02,
    "certOrg": "National Association of Rocketry",
    "commonName": "E30",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "E30",
    "diameter": 24,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/E30.pdf",
    "length": 70,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 49.3,
    "motorId": "5f4294d200023100000003e4",
    "propInfo": "black powder",
    "propWeightG": 17.8,
    "samples": [
      [ 0, 0 ], [ 0.01, 49 ], [ 0.02, 49 ], [ 0.05, 46 ], [ 0.1, 44 ], [ 0.2, 43 ], [ 0.25, 42 ],
      [ 0.3, 41 ], [ 0.35, 40 ], [ 0.4, 39 ], [ 0.45, 38 ], [ 0.5, 37 ], [ 0.55, 35 ], [ 0.6, 33 ],
      [ 0.65, 32 ], [ 0.7, 31 ], [ 0.75, 30 ], [ 0.8, 27 ], [ 0.85, 25 ], [ 0.9, 20 ], [ 0.91, 19 ],
      [ 0.93, 12 ], [ 0.95, 5 ], [ 0.97, 1 ], [ 1, 0 ]
],
    "totImpulseNs": 33.56,
    "totalWeightG": 47,
    "type": "SU",
    "updatedOn": "2020-12-02"
  },
  {
    "availability": "regular",
    "avgThrustN": 26.96,
    "burnTimeS": 2.31,
    "certOrg": "National Association of Rocketry",
    "commonName": "F26",
    "dataFiles": 2,
    "delays": "6",
    "designation": "F26",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/F26FJ.pdf",
    "length": 98,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 39.16,
    "motorId": "5f4294d200023100000003e5",
    "propWeightG": 43.1,
    "samples": [
      [ 0, 0 ], [ 0.041, 38.29 ], [ 0.114, 36.32 ], [ 0.293, 34.35 ], [ 0.497, 32.94 ], [ 0.774, 32.38 ],
      [ 1, 31.25 ], [ 1.254, 28.72 ], [ 1.498, 25.34 ], [ 1.743, 22.24 ], [ 2.003, 17.74 ],
      [ 2.077, 15.48 ], [ 2.304, 5.349 ], [ 2.484, 1.689 ], [ 2.61, 0 ]
],
    "totImpulseNs": 62.19,
    "totalWeightG": 100.8,
    "type": "SU",
    "updatedOn": "2019-10-05"
  },
  {
    "availability": "regular",
    "avgThrustN": 53.73,
    "burnTimeS": 1.43,
    "certOrg": "National Association of Rocketry",
    "commonName": "F50",
    "dataFiles": 1,
    "delays": "6",
    "designation": "F50",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/F50T.pdf",
    "length": 98,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 79.59,
    "motorId": "5f4294d200023100000003e6",
    "propWeightG": 37.9,
    "samples": [
      [ 0, 0 ], [ 0.012, 51.38 ], [ 0.023, 61.2 ], [ 0.026, 66.12 ], [ 0.044, 66.56 ], [ 0.082, 69.69 ],
      [ 0.152, 73.26 ], [ 0.208, 75.05 ], [ 0.237, 77.28 ], [ 0.254, 76.83 ], [ 0.272, 77.73 ],
      [ 0.307, 77.73 ], [ 0.33, 76.83 ], [ 0.336, 78.62 ], [ 0.342, 76.83 ], [ 0.354, 79.59 ],
      [ 0.363, 76.39 ], [ 0.371, 77.76 ], [ 0.395, 76.39 ], [ 0.447, 75.94 ], [ 0.523, 73.71 ],
      [ 0.652, 68.34 ], [ 0.81, 60.3 ], [ 0.828, 62.54 ], [ 0.836, 58.08 ], [ 0.901, 53.6 ],
      [ 1.079, 37.07 ], [ 1.158, 29.48 ], [ 1.196, 25.46 ], [ 1.246, 16.98 ], [ 1.301, 9.38 ],
      [ 1.43, 0 ]
],
    "totImpulseNs": 76.83,
    "totalWeightG": 84.4,
    "type": "SU",
    "updatedOn": "2020-12-01"
  },
  {
    "availability": "regular",
    "avgThrustN": 40.88,
    "burnTimeS": 2.38,
    "certOrg": "National Association of Rocketry",
    "commonName": "G40",
    "dataFiles": 2,
    "delays": "4,7",
    "designation": "G40",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Estes/G40.pdf",
    "length": 124,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 84.51,
    "motorId": "5f4294d200023100000003e7",
    "propWeightG": 55.1,
    "samples": [
      [ 0, 0 ], [ 0.024, 74.33 ], [ 0.057, 67 ], [ 0.252, 65.88 ], [ 0.5, 63.06 ], [ 0.765, 60.25 ],
      [ 1, 54.05 ], [ 1.25, 47.3 ], [ 1.502, 36.6 ], [ 1.751, 25.34 ], [ 1.999, 12.95 ], [ 2.121, 3.941 ],
      [ 2.3, 0 ]
],
    "totImpulseNs": 97.14,
    "totalWeightG": 122.9,
    "type": "SU",
    "updatedOn": "2022-07-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 77.6,
    "burnTimeS": 1.7,
    "certOrg": "National Association of Rocketry",
    "commonName": "G80",
    "dataFiles": 0,
    "delays": "7,10",
    "designation": "G80",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/G80T.pdf",
    "length": 128,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 108.5,
    "motorId": "5f4294d200023100000003e8",
    "propWeightG": 62.5,
    "totImpulseNs": 136.6,
    "totalWeightG": 128.3,
    "type": "SU",
    "updatedOn": "2022-07-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 2326,
    "burnTimeS": 4.29,
    "caseInfo": "Pro75-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M2245",
    "dataFiles": 2,
    "designation": "9977M2245-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1025,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3357,
    "motorId": "5f4294d200023100000003e9",
    "propInfo": "Imax",
    "propWeightG": 5074,
    "samples": [
      [ 0, 0 ], [ 0.043, 3046 ], [ 0.136, 2630 ], [ 1.393, 3003 ], [ 2.168, 3007 ], [ 2.708, 2972 ],
      [ 2.837, 2884 ], [ 3.152, 1956 ], [ 3.397, 1409 ], [ 3.878, 562.2 ], [ 4.371, 0 ]
],
    "totImpulseNs": 9977,
    "totalWeightG": 8182,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 145.2,
    "burnTimeS": 0.96,
    "caseInfo": "Pro24-6G",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G145",
    "dataFiles": 2,
    "delays": "6,8,10,12,15",
    "designation": "140G145-15A",
    "diameter": 24,
    "impulseClass": "G",
    "length": 228,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 346.9,
    "motorId": "5f4294d200023100000003ea",
    "propInfo": "Pink",
    "propWeightG": 66.7,
    "samples": [
      [ 0, 0 ], [ 0.007, 286.7 ], [ 0.013, 346 ], [ 0.022, 310.6 ], [ 0.039, 227 ], [ 0.066, 203.1 ],
      [ 0.343, 174.8 ], [ 0.672, 138.5 ], [ 0.775, 75.66 ], [ 0.899, 29.2 ], [ 0.982, 13.27 ],
      [ 1.015, 0 ]
],
    "totImpulseNs": 139.7,
    "totalWeightG": 161.9,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2174,
    "burnTimeS": 5.55,
    "caseInfo": "Pro98-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N2200",
    "dataFiles": 2,
    "delays": "P",
    "designation": "12066N2200-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 2879,
    "motorId": "5f4294d200023100000003eb",
    "propInfo": "Pink",
    "propWeightG": 6122,
    "samples": [
      [ 0, 0 ], [ 0.033, 2756 ], [ 0.143, 2435 ], [ 0.624, 2555 ], [ 1.814, 2602 ], [ 2.67, 2462 ],
      [ 4.186, 2130 ], [ 4.66, 2064 ], [ 4.796, 1853 ], [ 5.067, 775.9 ], [ 5.236, 474.9 ],
      [ 5.497, 180.6 ], [ 5.86, 0 ]
],
    "totImpulseNs": 12070,
    "totalWeightG": 11360,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 128.5,
    "burnTimeS": 4.41,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I125",
    "dataFiles": 2,
    "delays": "3,5,7,10",
    "designation": "567I125-10A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 294.3,
    "motorId": "5f4294d200023100000003ec",
    "propInfo": "White",
    "propWeightG": 335,
    "samples": [
      [ 0, 0 ], [ 0.046, 174.7 ], [ 0.089, 267.8 ], [ 0.135, 236 ], [ 0.258, 193.8 ], [ 0.35, 182 ],
      [ 0.553, 174.7 ], [ 1.124, 176.1 ], [ 1.502, 173 ], [ 1.806, 162.3 ], [ 2.439, 136 ],
      [ 3.372, 84.78 ], [ 4.005, 36.68 ], [ 4.395, 14.88 ], [ 4.585, 0 ]
],
    "totImpulseNs": 566.6,
    "totalWeightG": 647,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 6365,
    "burnTimeS": 1.36,
    "caseInfo": "Pro98-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M6400",
    "dataFiles": 2,
    "delays": "P",
    "designation": "8634M6400-P",
    "diameter": 98,
    "impulseClass": "M",
    "length": 702,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 7238,
    "motorId": "5f4294d200023100000003ed",
    "propInfo": "Vmax",
    "propWeightG": 4175,
    "samples": [
      [ 0, 0 ], [ 0.011, 6080 ], [ 0.135, 6598 ], [ 0.354, 7081 ], [ 0.503, 7245 ], [ 0.713, 7163 ],
      [ 0.954, 6708 ], [ 1.183, 5688 ], [ 1.233, 5461 ], [ 1.26, 4915 ], [ 1.288, 3395 ],[ 1.331, 800.9 ],
      [ 1.383, 0 ]
],
    "totImpulseNs": 8634,
    "totalWeightG": 7919,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3417,
    "burnTimeS": 6.16,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "O3400",
    "dataFiles": 2,
    "delays": "P",
    "designation": "21062O3400-P",
    "diameter": 98,
    "impulseClass": "O",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 4750,
    "motorId": "5f4294d200023100000003ee",
    "propInfo": "Imax",
    "propWeightG": 10930,
    "samples": [
      [ 0, 0 ], [ 0.04, 3960 ], [ 0.052, 4433 ], [ 0.101, 4515 ], [ 0.19, 4421 ], [ 0.38, 4391 ],
      [ 0.965, 4444 ], [ 2.176, 4699 ], [ 2.887, 4592 ], [ 3.658, 4226 ], [ 4.17, 2855 ],[ 4.493, 2559 ],
      [ 4.881, 1619 ], [ 5.483, 868.8 ], [ 6.137, 248.2 ], [ 6.322, 0 ]
],
    "totImpulseNs": 21060,
    "totalWeightG": 16840,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 657,
    "burnTimeS": 11.5,
    "caseInfo": "RMS-75/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M685",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M685W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 935.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1517,
    "motorId": "5f4294d200023100000003f0",
    "propInfo": "White Lightning",
    "propWeightG": 4320,
    "samples": [
      [ 0, 0 ], [ 0.083, 1333 ], [ 0.13, 1368 ], [ 0.249, 1361 ], [ 0.308, 1380 ], [ 0.403, 1359 ],
      [ 0.675, 1185 ], [ 1.018, 1073 ], [ 1.456, 996 ], [ 1.977, 958.8 ], [ 2.995, 914.6 ],
      [ 3.99, 856.4 ], [ 4.985, 781.9 ], [ 5.494, 730.7 ], [ 5.991, 679.5 ], [ 7.258, 542.2 ],
      [ 7.862, 463.1 ], [ 8.015, 456.1 ], [ 8.998, 330.5 ], [ 9.993, 207.1 ], [ 10.51, 137.3 ],
      [ 11.5, 34.91 ], [ 11.99, 0 ]
],
    "totImpulseNs": 7561,
    "totalWeightG": 7008,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 115.9,
    "burnTimeS": 1.95,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H135",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-H135W",
    "diameter": 29,
    "impulseClass": "H",
    "length": 216,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 159.9,
    "motorId": "5f4294d200023100000003f1",
    "propInfo": "White Lightning",
    "propWeightG": 82,
    "samples": [
      [ 0, 0 ], [ 0.014, 159.8 ], [ 0.045, 110.9 ], [ 0.104, 144.4 ], [ 0.131, 135.6 ], [ 0.252, 154.3 ],
      [ 0.293, 130.7 ], [ 0.387, 149.9 ], [ 1.005, 140 ], [ 1.261, 119.2 ], [ 1.297, 142.2 ],
      [ 1.324, 109.8 ], [ 1.387, 120.3 ], [ 1.518, 110.4 ], [ 1.595, 80.73 ], [ 1.685, 60.41 ],
      [ 1.797, 33.5 ], [ 1.896, 14.28 ], [ 2.072, 0 ]
],
    "totImpulseNs": 225.8,
    "totalWeightG": 212,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 14.38,
    "burnTimeS": 3.45,
    "certOrg": "National Association of Rocketry",
    "commonName": "F15",
    "dataFiles": 3,
    "delays": "0,4,6,8",
    "designation": "F15",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/F15.pdf",
    "length": 114,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 25.26,
    "motorId": "5f4294d200023100000003f2",
    "propInfo": "black powder",
    "propWeightG": 60,
    "samples": [
      [ 0, 0 ], [ 0.148, 7.638 ], [ 0.228, 12.25 ], [ 0.294, 16.39 ], [ 0.353, 20.21 ], [ 0.382, 22.76 ],
      [ 0.419, 25.26 ], [ 0.477, 23.07 ], [ 0.52, 20.84 ], [ 0.593, 19.09 ], [ 0.688, 17.5 ],
      [ 0.855, 16.23 ], [ 1.037, 15.43 ], [ 1.205, 14.95 ], [ 1.423, 14.63 ], [ 1.452, 15.74 ],
      [ 1.503, 14.79 ], [ 1.736, 14.62 ], [ 1.955, 14.3 ], [ 2.21, 14.14 ], [ 2.494, 13.82 ],
      [ 2.763, 13.34 ], [ 3.12, 13.33 ], [ 3.382, 13.01 ], [ 3.404, 9.352 ], [ 3.418, 4.895 ],
      [ 3.45, 0 ]
],
    "totImpulseNs": 49.61,
    "totalWeightG": 102,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 206.7,
    "burnTimeS": 1.14,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H195",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-H195NT",
    "diameter": 29,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 247.2,
    "motorId": "5f4294d200023100000003f3",
    "propInfo": "New Blue Thunder",
    "propWeightG": 115,
    "samples": [
      [ 0, 0 ], [ 0.003, 178.1 ], [ 0.005, 224.1 ], [ 0.013, 252.5 ], [ 0.019, 256.6 ], [ 0.022, 248.5 ],
      [ 0.058, 239.6 ], [ 0.1, 240.4 ], [ 0.199, 235.6 ], [ 0.255, 228.6 ], [ 0.285, 227.4 ],
      [ 0.301, 230.8 ], [ 0.399, 223 ], [ 0.5, 223 ], [ 0.603, 220.4 ], [ 0.774, 217.1 ],[ 0.802, 211.6 ],
      [ 0.899, 201.6 ], [ 0.961, 180.6 ], [ 0.999, 169.9 ], [ 1.023, 161.1 ], [ 1.054, 155.2 ],
      [ 1.101, 69.3 ], [ 1.169, 0 ]
],
    "totImpulseNs": 236.1,
    "totalWeightG": 197,
    "type": "SU",
    "updatedOn": "2020-11-26"
  },
  {
    "availability": "regular",
    "avgThrustN": 5636,
    "burnTimeS": 2.42,
    "caseInfo": "Pro98-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N5600",
    "dataFiles": 2,
    "delays": "P",
    "designation": "13628N5600-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1010,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 6789,
    "motorId": "5f4294d200023100000003f4",
    "propInfo": "White Thunder",
    "propWeightG": 6363,
    "samples": [
      [ 0, 0 ], [ 0.024, 6593 ], [ 0.064, 5986 ], [ 0.309, 6214 ], [ 0.806, 6376 ], [ 0.921, 6452 ],
      [ 2.14, 5131 ], [ 2.186, 5110 ], [ 2.215, 4850 ], [ 2.38, 844.4 ], [ 2.462, 0 ]
],
    "totImpulseNs": 13630,
    "totalWeightG": 11280,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 140,
    "burnTimeS": 2.4,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I140",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-I140W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 202.7,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 181,
    "motorId": "5f4294d200023100000003f5",
    "propInfo": "White Lightning",
    "propWeightG": 183,
    "samples": [
      [ 0, 0 ], [ 0.011, 96.26 ], [ 0.024, 80.21 ], [ 0.049, 160.4 ], [ 0.062, 144 ], [ 0.114, 160.4 ],
      [ 0.159, 164.1 ], [ 0.238, 174.6 ], [ 0.314, 173.9 ], [ 0.419, 179 ], [ 0.627, 178.3 ],
      [ 0.835, 176.8 ], [ 0.943, 177.2 ], [ 1.146, 164.8 ], [ 1.349, 148.8 ], [ 1.459, 141.1 ],
      [ 1.562, 131.3 ], [ 1.773, 113.4 ], [ 2.192, 83.86 ], [ 2.295, 67.09 ], [ 2.395, 37.55 ],
      [ 2.435, 31.36 ], [ 2.47, 20.42 ], [ 2.478, 0 ]
],
    "totImpulseNs": 336,
    "totalWeightG": 356,
    "type": "SU",
    "updatedOn": "2022-01-10"
  },
  {
    "availability": "regular",
    "avgThrustN": 280,
    "burnTimeS": 1.9,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I280",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-I280DM",
    "diameter": 38,
    "impulseClass": "I",
    "length": 356.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 386,
    "motorId": "5f4294d200023100000003f6",
    "propInfo": "Dark Matter",
    "propWeightG": 355,
    "samples": [
      [ 0, 0 ], [ 0.016, 342.4 ], [ 0.048, 297 ], [ 0.111, 316.2 ], [ 0.173, 328.2 ], [ 0.202, 327.5 ],
      [ 0.261, 322.5 ], [ 0.334, 311.9 ], [ 0.498, 310.5 ], [ 0.602, 308.4 ], [ 0.705, 307.7 ],
      [ 0.802, 310.5 ], [ 0.902, 310.5 ], [ 1, 306.2 ], [ 1.025, 316.9 ], [ 1.057, 309.1 ],
      [ 1.1, 308.4 ], [ 1.139, 317.6 ], [ 1.205, 307.7 ], [ 1.298, 304.1 ], [ 1.336, 316.2 ],
      [ 1.4, 299.9 ], [ 1.5, 296.3 ], [ 1.55, 298.4 ], [ 1.595, 306.9 ], [ 1.634, 295.6 ],
      [ 1.652, 280.7 ], [ 1.716, 253.1 ], [ 1.743, 224 ], [ 1.8, 153.8 ], [ 1.9, 62.38 ],[ 1.97, 20.56 ],
      [ 1.971, 0 ]
],
    "sparky": true,
    "totImpulseNs": 561,
    "totalWeightG": 616,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 270,
    "burnTimeS": 2.6,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J270",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-J270W",
    "diameter": 38,
    "impulseClass": "J",
    "length": 356.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 356,
    "motorId": "5f4294d200023100000003f7",
    "propInfo": "White Lightning",
    "propWeightG": 381,
    "samples": [
      [ 0, 0 ], [ 0.007, 367.6 ], [ 0.02, 325.1 ], [ 0.074, 353 ], [ 0.136, 330 ], [ 0.176, 343.9 ],
      [ 0.273, 324.5 ], [ 0.33, 342.7 ], [ 0.357, 326.3 ], [ 0.399, 330.6 ], [ 0.473, 310.6 ],
      [ 0.553, 325.7 ], [ 0.734, 340.9 ], [ 0.744, 357.3 ], [ 0.761, 315.4 ], [ 0.788, 337.3 ],
      [ 0.897, 340.9 ], [ 1.009, 333.6 ], [ 1.053, 354.2 ], [ 1.105, 320.9 ], [ 1.18, 345.7 ],
      [ 1.214, 321.5 ], [ 1.249, 345.7 ], [ 1.274, 314.2 ], [ 1.5, 316 ], [ 1.601, 310 ],[ 1.916, 303.9 ],
      [ 1.956, 265.1 ], [ 2, 214.1 ], [ 2.052, 179.5 ], [ 2.102, 158.9 ], [ 2.201, 117.7 ],
      [ 2.268, 106.2 ], [ 2.303, 87.95 ], [ 2.369, 55.8 ], [ 2.407, 49.74 ], [ 2.486, 39.43 ],
      [ 2.558, 21.84 ], [ 2.563, 0 ]
],
    "totImpulseNs": 703,
    "totalWeightG": 642,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 15.97,
    "burnTimeS": 2.09,
    "certOrg": "National Association of Rocketry",
    "commonName": "E16",
    "dataFiles": 2,
    "delays": "0,4,6,8",
    "designation": "E16",
    "diameter": 29,
    "impulseClass": "E",
    "infoUrl": "http://www.nar.org/SandT/pdf/Estes/E16.pdf",
    "length": 114,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 26.44,
    "motorId": "5f4294d200023100000003f8",
    "propInfo": "black powder",
    "propWeightG": 40,
    "samples": [
      [ 0, 0 ], [ 0.15, 1.371 ], [ 0.186, 1.92 ], [ 0.206, 3.387 ], [ 0.242, 5.587 ], [ 0.252, 7.422 ],
      [ 0.277, 8.705 ], [ 0.333, 13.47 ], [ 0.359, 15.86 ], [ 0.374, 16.59 ], [ 0.394, 18.61 ],
      [ 0.435, 21.54 ], [ 0.476, 24.66 ], [ 0.521, 26.44 ], [ 0.643, 21.72 ], [ 0.725, 20.43 ],
      [ 0.821, 19.51 ], [ 0.898, 18.96 ], [ 1.025, 18.22 ], [ 1.142, 18.03 ], [ 1.259, 17.84 ],
      [ 1.396, 17.47 ], [ 1.569, 17.28 ], [ 1.757, 17.27 ], [ 1.895, 17.09 ], [ 2.027, 17.82 ],
      [ 2.042, 12.49 ], [ 2.052, 8.457 ], [ 2.063, 4.97 ], [ 2.09, 0 ]
],
    "totImpulseNs": 33.38,
    "totalWeightG": 84,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 125,
    "burnTimeS": 0.95,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G125",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "G125T",
    "diameter": 29,
    "impulseClass": "G",
    "length": 124,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 160,
    "motorId": "5f4294d200023100000003f9",
    "propInfo": "Blue Thunder",
    "propWeightG": 59.6,
    "samples": [
      [ 0, 0 ], [ 0.008, 89.65 ], [ 0.017, 117.2 ], [ 0.027, 119.7 ], [ 0.047, 111 ], [ 0.115, 124.8 ],
      [ 0.2, 134.1 ], [ 0.399, 149.3 ], [ 0.601, 155.2 ], [ 0.8, 138.3 ], [ 0.824, 133.8 ],
      [ 0.878, 88.96 ], [ 0.914, 44.48 ], [ 0.933, 22.41 ], [ 0.974, 2.069 ], [ 1.001, 0 ]
],
    "totImpulseNs": 128,
    "totalWeightG": 125,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 115,
    "burnTimeS": 1.5,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H115",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-H115DM",
    "diameter": 29,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 126,
    "motorId": "5f4294d200023100000003fa",
    "propInfo": "Dark Matter",
    "propWeightG": 113,
    "samples": [
      [ 0, 0 ], [ 0.014, 89.27 ], [ 0.024, 130.7 ], [ 0.036, 108.2 ], [ 0.048, 101.6 ], [ 0.081, 111.2 ],
      [ 0.202, 121.1 ], [ 0.405, 123.2 ], [ 0.598, 123.8 ], [ 0.8, 123.5 ], [ 1.002, 122.3 ],
      [ 1.2, 114.2 ], [ 1.269, 111.8 ], [ 1.293, 111.5 ], [ 1.35, 119.3 ], [ 1.367, 117.8 ],
      [ 1.393, 111.8 ], [ 1.429, 88.96 ], [ 1.467, 67.02 ], [ 1.524, 44.18 ], [ 1.562, 22.84 ],
      [ 1.583, 13.22 ], [ 1.6, 6.913 ], [ 1.626, 3.306 ], [ 1.662, 1.202 ], [ 1.693, 0 ]
],
    "sparky": true,
    "totImpulseNs": 172,
    "totalWeightG": 205,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 500,
    "burnTimeS": 1.24,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I500",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-I500T",
    "diameter": 38,
    "impulseClass": "I",
    "length": 356,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 613,
    "motorId": "5f4294d200023100000003fb",
    "propInfo": "Blue Thunder",
    "propWeightG": 312,
    "samples": [
      [ 0, 0 ], [ 0.008, 447.1 ], [ 0.014, 534.3 ], [ 0.028, 581.6 ], [ 0.052, 504 ], [ 0.09, 514.9 ],
      [ 0.11, 533.1 ], [ 0.131, 524.6 ], [ 0.177, 551.3 ], [ 0.202, 540.4 ], [ 0.23, 552.5 ],
      [ 0.29, 544 ], [ 0.399, 552.5 ], [ 0.481, 553.7 ], [ 0.526, 563.4 ], [ 0.565, 554.9 ],
      [ 0.6, 564.6 ], [ 0.638, 558.5 ], [ 0.728, 567 ], [ 0.8, 561 ], [ 0.922, 537.9 ], [ 0.979, 535.5 ],
      [ 0.999, 546.4 ], [ 1.02, 550.1 ], [ 1.043, 535.5 ], [ 1.064, 444.6 ], [ 1.091, 322.3 ],
      [ 1.11, 267.8 ], [ 1.141, 225.4 ], [ 1.152, 181.7 ], [ 1.16, 238.7 ], [ 1.163, 149 ],
      [ 1.174, 118.7 ], [ 1.2, 118.7 ], [ 1.255, 86.02 ], [ 1.312, 46.04 ], [ 1.335, 0 ]
],
    "totImpulseNs": 620,
    "totalWeightG": 576,
    "type": "SU",
    "updatedOn": "2021-12-08"
  },
  {
    "availability": "regular",
    "avgThrustN": 1365,
    "burnTimeS": 3.5,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1365",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1365M",
    "diameter": 75,
    "impulseClass": "L",
    "length": 665,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1735,
    "motorId": "5f4294d200023100000003fc",
    "propInfo": "Metalstorm",
    "propWeightG": 2648,
    "samples": [
      [ 0, 0 ], [ 0.009, 166.8 ], [ 0.013, 618.3 ], [ 0.022, 940.8 ], [ 0.03, 1019 ], [ 0.046, 1161 ],
      [ 0.059, 1357 ], [ 0.063, 1497 ], [ 0.076, 1617 ], [ 0.102, 1435 ], [ 0.15, 1430 ],[ 0.193, 1472 ],
      [ 0.245, 1519 ], [ 0.258, 1541 ], [ 0.282, 1592 ], [ 0.347, 1624 ], [ 0.393, 1610 ],
      [ 0.486, 1575 ], [ 0.556, 1555 ], [ 0.627, 1539 ], [ 0.673, 1512 ], [ 0.747, 1479 ],
      [ 0.779, 1481 ], [ 0.831, 1497 ], [ 0.885, 1506 ], [ 0.94, 1524 ], [ 0.987, 1466 ],[ 1.009, 1532 ],
      [ 1.026, 1495 ], [ 1.098, 1535 ], [ 1.142, 1463 ], [ 1.185, 1506 ], [ 1.224, 1477 ],
      [ 1.246, 1524 ], [ 1.296, 1483 ], [ 1.322, 1517 ], [ 1.346, 1472 ], [ 1.441, 1501 ],
      [ 1.504, 1508 ], [ 1.528, 1601 ], [ 1.589, 1510 ], [ 1.699, 1512 ], [ 1.732, 1512 ],
      [ 1.801, 1463 ], [ 1.864, 1497 ], [ 1.964, 1521 ], [ 2.007, 1510 ], [ 2.068, 1450 ],
      [ 2.129, 1443 ], [ 2.201, 1510 ], [ 2.237, 1463 ], [ 2.374, 1475 ], [ 2.457, 1417 ],
      [ 2.546, 1428 ], [ 2.632, 1368 ], [ 2.687, 1403 ], [ 2.776, 1343 ], [ 2.834, 1426 ],
      [ 2.897, 1308 ], [ 2.93, 1348 ], [ 2.947, 1317 ], [ 2.982, 1361 ], [ 3.051, 1354 ],[ 3.071, 1330 ],
      [ 3.14, 1130 ], [ 3.175, 1030 ], [ 3.227, 842.9 ], [ 3.277, 582.7 ], [ 3.307, 449.3 ],
      [ 3.353, 329.2 ], [ 3.414, 184.6 ], [ 3.479, 88.96 ], [ 3.527, 48.93 ], [ 3.587, 20.02 ],
      [ 3.663, 17.79 ], [ 3.711, 8.896 ], [ 3.752, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4780,
    "totalWeightG": 4908,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1254,
    "burnTimeS": 3.7,
    "caseInfo": "RMS-75/6400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1250",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1250DM",
    "diameter": 75,
    "impulseClass": "L",
    "length": 801,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2042,
    "motorId": "5f4294d200023100000003fd",
    "propInfo": "Dark Matter",
    "propWeightG": 2565,
    "samples": [
      [ 0, 0 ], [ 0.032, 1511 ], [ 0.049, 1295 ], [ 0.211, 1334 ], [ 0.247, 1397 ], [ 0.256, 1554 ],
      [ 0.284, 1380 ], [ 0.491, 1354 ], [ 1.493, 1449 ], [ 1.72, 1469 ], [ 1.862, 1478 ],[ 2.008, 1459 ],
      [ 2.499, 1334 ], [ 3.002, 1174 ], [ 3.108, 889.6 ], [ 3.156, 667.2 ], [ 3.245, 444.8 ],
      [ 3.42, 225.7 ], [ 3.505, 94.85 ], [ 3.529, 62.14 ], [ 3.574, 39.25 ], [ 3.663, 16.35 ],
      [ 3.785, 6.541 ], [ 3.842, 0 ]
],
    "sparky": true,
    "totImpulseNs": 4374,
    "totalWeightG": 5647,
    "type": "reload",
    "updatedOn": "2020-08-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 2199,
    "burnTimeS": 5.4,
    "caseInfo": "RMS-98/15360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N2220",
    "dataFiles": 2,
    "delays": "P",
    "designation": "N2220DM",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1046,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 4472,
    "motorId": "5f4294d200023100000003fe",
    "propInfo": "Dark Matter",
    "propWeightG": 7183,
    "samples": [
      [ 0, 0 ], [ 0.045, 2638 ], [ 0.067, 2529 ], [ 0.084, 3063 ], [ 0.124, 2426 ], [ 0.186, 2548 ],
      [ 0.231, 2600 ], [ 0.298, 2555 ], [ 0.371, 2645 ], [ 0.534, 2638 ], [ 0.979, 2561 ],
      [ 1.142, 2606 ], [ 2.002, 2638 ], [ 2.289, 2638 ], [ 2.761, 2580 ], [ 3.121, 2497 ],
      [ 3.436, 2336 ], [ 3.678, 2214 ], [ 3.79, 2130 ], [ 4.004, 1654 ], [ 4.415, 888 ], [ 4.538, 630.6 ],
      [ 4.656, 450.5 ], [ 4.82, 289.6 ], [ 5.005, 135.1 ], [ 5.219, 25.74 ], [ 5.371, 0 ]
],
    "sparky": true,
    "totImpulseNs": 10660,
    "totalWeightG": 12000,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 44,
    "burnTimeS": 1,
    "certOrg": "National Association of Rocketry",
    "commonName": "F44",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "F44W",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/F44W.pdf",
    "length": 70,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 50,
    "motorId": "5f4294d200023100000003ff",
    "propInfo": "White Lightning",
    "propWeightG": 19.7,
    "samples": [
      [ 0, 0 ], [ 0.02, 2.676 ], [ 0.026, 6.02 ], [ 0.034, 11.2 ], [ 0.063, 24.92 ], [ 0.135, 59.03 ],
      [ 0.15, 61.37 ], [ 0.2, 63.21 ], [ 0.299, 65.22 ], [ 0.4, 65.05 ], [ 0.5, 63.38 ], [ 0.6, 58.53 ],
      [ 0.668, 55.02 ], [ 0.686, 53.68 ], [ 0.687, 54.01 ], [ 0.7, 49.16 ], [ 0.719, 43.81 ],
      [ 0.774, 22.07 ], [ 0.795, 13.21 ], [ 0.812, 7.86 ], [ 0.825, 4.515 ], [ 0.854, 2.007 ],
      [ 0.899, 0.167 ], [ 0.998, 0 ]
],
    "totImpulseNs": 41.5,
    "totalWeightG": 48,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 74,
    "burnTimeS": 1.1,
    "certOrg": "National Association of Rocketry",
    "commonName": "G74",
    "dataFiles": 2,
    "delays": "6,8",
    "designation": "G74W",
    "diameter": 29,
    "impulseClass": "G",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/G74W.pdf",
    "length": 93,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 95,
    "motorId": "5f4294d20002310000000400",
    "propInfo": "White Lightning",
    "propWeightG": 39.3,
    "samples": [
      [ 0, 0 ], [ 0.023, 6.551 ], [ 0.05, 21.84 ], [ 0.088, 44.85 ], [ 0.124, 67.36 ], [ 0.135, 71.56 ],
      [ 0.148, 74.42 ], [ 0.197, 80.13 ], [ 0.248, 85 ], [ 0.298, 87.52 ], [ 0.397, 89.7 ],
      [ 0.499, 91.22 ], [ 0.6, 90.38 ], [ 0.699, 90.38 ], [ 0.751, 89.54 ], [ 0.8, 87.86 ],
      [ 0.9, 84.5 ], [ 0.954, 82.14 ], [ 0.971, 81.81 ], [ 0.987, 79.96 ], [ 1.002, 76.43 ],
      [ 1.023, 67.19 ], [ 1.065, 44.52 ], [ 1.094, 22.17 ], [ 1.107, 15.29 ], [ 1.126, 6.887 ],
      [ 1.138, 3.36 ], [ 1.157, 1.344 ], [ 1.18, 0.168 ], [ 1.192, 0 ]
],
    "totImpulseNs": 82.75,
    "totalWeightG": 87,
    "type": "SU",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2645,
    "burnTimeS": 9.2,
    "caseInfo": "152/35000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "O2700",
    "dataFiles": 1,
    "delays": "P",
    "designation": "O2700BL",
    "diameter": 152,
    "impulseClass": "O",
    "length": 1206,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 3920,
    "motorId": "5f4294d20002310000000401",
    "propInfo": "Black Lightning",
    "propWeightG": 15150,
    "samples": [
      [ 0, 0 ], [ 0.037, 446.3 ], [ 0.056, 612.8 ], [ 0.121, 627.9 ], [ 0.26, 1210 ], [ 0.261, 1331 ],
      [ 0.501, 1740 ], [ 0.734, 2322 ], [ 0.994, 2595 ], [ 2.006, 3147 ], [ 3.008, 3646 ],
      [ 3.519, 3805 ], [ 4.318, 3888 ], [ 4.494, 3851 ], [ 4.995, 3692 ], [ 5.478, 3578 ],
      [ 6.193, 3162 ], [ 6.249, 3245 ], [ 6.314, 3102 ], [ 7.001, 2345 ], [ 7.456, 1778 ],
      [ 7.864, 1210 ], [ 7.994, 1052 ], [ 8.208, 839.7 ], [ 8.431, 605.2 ], [ 8.598, 506.9 ],
      [ 8.821, 355.6 ], [ 9.044, 234.5 ], [ 9.331, 143.7 ], [ 9.443, 121 ], [ 9.499, 0 ]
],
    "sparky": true,
    "totImpulseNs": 24330,
    "totalWeightG": 33340,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3.83,
    "burnTimeS": 1.26,
    "certOrg": "Austrialian Model Rocket Society Inc.",
    "commonName": "B6",
    "dataFiles": 1,
    "designation": "SCR-B6",
    "diameter": 18,
    "impulseClass": "B",
    "length": 70,
    "manufacturer": "Southern Cross Rocketry",
    "manufacturerAbbrev": "SCR",
    "maxThrustN": 12.98,
    "motorId": "5f4294d20002310000000402",
    "propInfo": "black powder",
    "propWeightG": 6,
    "samples": [
      [ 0, 0 ], [ 0.3545, 12.2 ], [ 0.4782, 3.63 ], [ 0.5113, 2.79 ], [ 0.55, 2.353 ], [ 1.006, 2.286 ],
      [ 1.111, 1.815 ], [ 1.182, 0.1008 ], [ 1.207, 0.06723 ]
],
    "totImpulseNs": 4.84,
    "totalWeightG": 19,
    "type": "SU",
    "updatedOn": "2024-01-22"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3.06,
    "burnTimeS": 2.89,
    "certOrg": "Austrialian Model Rocket Society Inc.",
    "commonName": "C6",
    "dataFiles": 1,
    "delays": "0,3,5",
    "designation": "SCR-C6",
    "diameter": 18,
    "impulseClass": "C",
    "length": 70,
    "manufacturer": "Southern Cross Rocketry",
    "manufacturerAbbrev": "SCR",
    "maxThrustN": 12.31,
    "motorId": "5f4294d20002310000000403",
    "propInfo": "black powder",
    "propWeightG": 12,
    "samples": [
      [ 0, 0 ], [ 0.09939, 1.884 ], [ 0.4583, 12.28 ], [ 0.5163, 4.964 ], [ 0.5991, 3.429 ],
      [ 0.7703, 3.227 ], [ 0.8669, 2.857 ], [ 1.502, 3.092 ], [ 2.054, 2.891 ], [ 2.631, 2.428 ],
      [ 2.9, 0 ]
],
    "totImpulseNs": 8.83,
    "totalWeightG": 25,
    "type": "SU",
    "updatedOn": "2024-01-22"
  },
  {
    "availability": "OOP",
    "avgThrustN": 3.17,
    "burnTimeS": 0.75,
    "certOrg": "Austrialian Model Rocket Society Inc.",
    "commonName": "A8",
    "dataFiles": 1,
    "delays": "3,5",
    "designation": "SCR-A8",
    "diameter": 18,
    "impulseClass": "A",
    "length": 70,
    "manufacturer": "Southern Cross Rocketry",
    "manufacturerAbbrev": "SCR",
    "maxThrustN": 7.18,
    "motorId": "5f4294d20002310000000404",
    "propInfo": "black powder",
    "propWeightG": 3,
    "samples": [
      [ 0, 0 ], [ 0.08062, 0.4034 ], [ 0.1391, 1.311 ], [ 0.2137, 3.042 ], [ 0.3998, 8.706 ],
      [ 0.582, 2.303 ], [ 0.6311, 1.16 ], [ 0.6853, 0.3529 ], [ 0.7372, 0.01681 ]
],
    "totImpulseNs": 2.38,
    "totalWeightG": 15,
    "type": "SU",
    "updatedOn": "2024-01-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 182,
    "burnTimeS": 1.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H182",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-H182R",
    "diameter": 29,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 192,
    "motorId": "5f4294d20002310000000405",
    "propInfo": "Redline",
    "propWeightG": 115,
    "samples": [
      [ 0, 0 ], [ 0.007, 82.4 ], [ 0.031, 159.2 ], [ 0.091, 158.4 ], [ 0.186, 182.4 ], [ 0.5, 192 ],
      [ 0.904, 188.8 ], [ 1.054, 175.2 ], [ 1.106, 180.8 ], [ 1.144, 200.8 ], [ 1.163, 162.4 ],
      [ 1.201, 36.8 ], [ 1.212, 16.8 ], [ 1.235, 0 ]
],
    "totImpulseNs": 218,
    "totalWeightG": 207,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 205,
    "burnTimeS": 1.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I205",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-I205W",
    "diameter": 29,
    "impulseClass": "I",
    "length": 305,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 253,
    "motorId": "5f4294d20002310000000406",
    "propInfo": "White Lightning",
    "propWeightG": 188,
    "samples": [
      [ 0, 0 ], [ 0.008, 377.3 ], [ 0.028, 241.9 ], [ 0.36, 256.3 ], [ 0.711, 246.3 ], [ 0.861, 233.3 ],
      [ 1.121, 230.4 ], [ 1.146, 214.6 ], [ 1.242, 211.7 ], [ 1.349, 141.1 ], [ 1.404, 125.3 ],
      [ 1.489, 72 ], [ 1.602, 41.76 ], [ 1.712, 11.52 ], [ 1.787, 12.96 ], [ 1.892, 0 ]
],
    "totImpulseNs": 345,
    "totalWeightG": 315,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 45,
    "burnTimeS": 6,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H45",
    "dataFiles": 2,
    "delays": "P",
    "designation": "HP-H45W",
    "diameter": 38,
    "impulseClass": "H",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 87,
    "motorId": "5f4294d20002310000000407",
    "propInfo": "White Lightning",
    "propWeightG": 180,
    "samples": [
      [ 0, 0 ], [ 0.04, 84 ], [ 0.854, 86 ], [ 1.377, 84.8 ], [ 2.046, 79.2 ], [ 2.69, 66 ],
      [ 3.286, 51.6 ], [ 4.414, 28.4 ], [ 5.042, 16 ], [ 5.429, 9.2 ], [ 6.113, 6.4 ], [ 6.186, 0 ]
],
    "totImpulseNs": 320,
    "totalWeightG": 365,
    "type": "SU",
    "updatedOn": "2022-03-11"
  },
  {
    "availability": "regular",
    "avgThrustN": 417.6,
    "burnTimeS": 1.629,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J425",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-J425R",
    "diameter": 38,
    "impulseClass": "J",
    "length": 356,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 473.3,
    "motorId": "5f4294d20002310000000408",
    "propInfo": "Redline",
    "propWeightG": 364,
    "samples": [
      [ 0, 0 ], [ 0.018, 267.9 ], [ 0.062, 368.1 ], [ 0.242, 420.1 ], [ 0.524, 443.2 ], [ 0.902, 452.9 ],
      [ 1.225, 449 ], [ 1.389, 433.6 ], [ 1.502, 452.9 ], [ 1.53, 420.1 ], [ 1.542, 356.5 ],
      [ 1.599, 67.45 ], [ 1.618, 28.91 ], [ 1.648, 0 ]
],
    "totImpulseNs": 680.3,
    "totalWeightG": 631,
    "type": "SU",
    "updatedOn": "2020-02-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 80.16,
    "burnTimeS": 7.92,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I65",
    "dataFiles": 2,
    "delays": "P",
    "designation": "HP-I65W",
    "diameter": 54,
    "impulseClass": "I",
    "length": 218,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 145,
    "motorId": "5f4294d20002310000000409",
    "propInfo": "White Lightning",
    "propWeightG": 377,
    "samples": [
      [ 0, 0 ], [ 0.023, 148.3 ], [ 0.211, 196.3 ], [ 0.398, 167.8 ], [ 0.797, 149.9 ], [ 1.395, 135.2 ],
      [ 2.238, 122.2 ], [ 3.199, 99.39 ], [ 4.711, 69.25 ], [ 5.367, 48.88 ], [ 6.246, 29.33 ],
      [ 6.926, 15.48 ], [ 7.395, 11.41 ], [ 8.496, 0 ]
],
    "totImpulseNs": 635,
    "totalWeightG": 752,
    "type": "SU",
    "updatedOn": "2022-08-31"
  },
  {
    "availability": "regular",
    "avgThrustN": 535,
    "burnTimeS": 2.8,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K535",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-K535W",
    "diameter": 54,
    "impulseClass": "K",
    "length": 358,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 655,
    "motorId": "5f4294d2000231000000040a",
    "propInfo": "White Lightning",
    "propWeightG": 745,
    "samples": [
      [ 0, 0 ], [ 0.015, 467.3 ], [ 0.063, 642.2 ], [ 0.104, 613.5 ], [ 0.23, 627.8 ], [ 0.419, 620.7 ],
      [ 0.883, 623 ], [ 1.335, 599.1 ], [ 1.646, 556 ], [ 1.843, 500.8 ], [ 2.017, 450.5 ],
      [ 2.258, 405 ], [ 2.488, 366.6 ], [ 2.6, 230 ], [ 2.611, 172.5 ], [ 2.648, 124.6 ],[ 2.696, 81.48 ],
      [ 2.789, 40.74 ], [ 2.837, 9.585 ], [ 2.952, 0 ]
],
    "totImpulseNs": 1434,
    "totalWeightG": 1264,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1000,
    "burnTimeS": 2.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1000",
    "dataFiles": 2,
    "delays": "10,12,14,16,18",
    "designation": "HP-L1000W",
    "diameter": 54,
    "impulseClass": "L",
    "length": 635,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1261,
    "motorId": "5f4294d2000231000000040b",
    "propInfo": "White Lightning",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.004, 10.66 ], [ 0.011, 1269 ], [ 0.04, 1322 ], [ 0.195, 1226 ], [ 0.249, 1269 ],
      [ 0.296, 1242 ], [ 0.372, 1253 ], [ 0.416, 1216 ], [ 0.6, 1226 ], [ 0.788, 1216 ], [ 1.066, 1184 ],
      [ 1.261, 1168 ], [ 1.507, 1125 ], [ 1.746, 1088 ], [ 1.865, 1050 ], [ 1.995, 1045 ],
      [ 2.093, 911.7 ], [ 2.158, 746.4 ], [ 2.263, 554.5 ], [ 2.389, 405.2 ], [ 2.577, 191.9 ],
      [ 2.693, 85.31 ], [ 2.761, 42.65 ], [ 3, 0 ]
],
    "totImpulseNs": 2714,
    "totalWeightG": 2194,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.14,
    "burnTimeS": 2.86,
    "caseInfo": "RMS-18/20",
    "certOrg": "National Association of Rocketry",
    "commonName": "C3",
    "dataFiles": 2,
    "delays": "P",
    "designation": "C3.4T",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "https://nar.org/SandT/pdf/Aerotech/AT_C3.4_2015.pdf",
    "length": 72,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 9.08,
    "motorId": "5f4294d2000231000000040c",
    "propInfo": "Blue Thunder",
    "propWeightG": 5.2,
    "samples": [
      [ 0, 0 ], [ 0.023, 3.188 ], [ 0.028, 5.669 ], [ 0.093, 9.08 ], [ 0.235, 8.208 ], [ 0.427, 6.881 ],
      [ 0.513, 6.188 ], [ 0.6, 5.438 ], [ 0.666, 4.803 ], [ 0.762, 3.649 ], [ 0.838, 2.668 ],
      [ 0.97, 2.149 ], [ 1.228, 1.918 ], [ 1.522, 1.918 ], [ 1.8, 1.86 ], [ 2.013, 1.745 ],
      [ 2.068, 2.034 ], [ 2.134, 1.803 ], [ 2.326, 1.803 ], [ 2.509, 1.745 ], [ 2.645, 1.687 ],
      [ 2.721, 1.457 ], [ 2.807, 0.879 ], [ 2.86, 0 ]
],
    "totImpulseNs": 8.96,
    "totalWeightG": 23.9,
    "type": "reload",
    "updatedOn": "2022-07-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 2.12,
    "burnTimeS": 8.14,
    "caseInfo": "RMS-18/20",
    "certOrg": "National Association of Rocketry",
    "commonName": "D2",
    "dataFiles": 2,
    "delays": "P",
    "designation": "D2.3T",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/D2.3.pdf",
    "length": 72,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 10.14,
    "motorId": "5f4294d2000231000000040d",
    "propInfo": "Blue Thunder",
    "propWeightG": 10.7,
    "samples": [
      [ 0, 0 ], [ 0.02, 5.071 ], [ 0.039, 9.478 ], [ 0.093, 10.14 ], [ 0.184, 9.478 ], [ 0.275, 8.429 ],
      [ 0.438, 6.82 ], [ 0.657, 4.372 ], [ 0.747, 3.392 ], [ 0.838, 2.413 ], [ 1.056, 2.064 ],
      [ 1.365, 1.923 ], [ 1.892, 1.783 ], [ 2.528, 1.713 ], [ 2.946, 1.713 ], [ 3.219, 1.643 ],
      [ 4.091, 1.573 ], [ 4.727, 1.573 ], [ 5.218, 1.573 ], [ 5.636, 1.573 ], [ 6.217, 1.573 ],
      [ 6.726, 1.433 ], [ 7.199, 1.503 ], [ 7.635, 1.363 ], [ 7.907, 1.224 ], [ 8.016, 0.734 ],
      [ 8.14, 0 ]
],
    "totImpulseNs": 17.21,
    "totalWeightG": 29.3,
    "type": "reload",
    "updatedOn": "2022-03-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 69.1,
    "burnTimeS": 0.77,
    "caseInfo": "Pro24-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F70",
    "dataFiles": 2,
    "delays": "5,7,9,11,14",
    "designation": "53F70-14A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 101,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 97,
    "motorId": "5f4294d2000231000000040e",
    "propInfo": "White Thunder",
    "propWeightG": 22.5,
    "samples": [
      [ 0, 0 ], [ 0.001, 8.303 ], [ 0.013, 85.68 ], [ 0.023, 96.15 ], [ 0.052, 78.82 ], [ 0.1, 83.63 ],
      [ 0.379, 77.86 ], [ 0.641, 62.58 ], [ 0.665, 55.72 ], [ 0.706, 23.95 ], [ 0.744, 9.146 ],
      [ 0.787, 2.768 ], [ 0.816, 0 ]
],
    "totImpulseNs": 52.9,
    "totalWeightG": 73,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 49.6,
    "burnTimeS": 1,
    "caseInfo": "Pro24-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F51",
    "dataFiles": 2,
    "delays": "4,6,8,10,13",
    "designation": "50F51-13A",
    "diameter": 24,
    "impulseClass": "F",
    "length": 101,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 69.4,
    "motorId": "5f4294d2000231000000040f",
    "propInfo": "Blue Streak",
    "propWeightG": 22,
    "samples": [
      [ 0, 0 ], [ 0.001, 5.145 ], [ 0.027, 67.98 ], [ 0.051, 53.81 ], [ 0.06, 52.88 ], [ 0.092, 55.92 ],
      [ 0.119, 57.94 ], [ 0.17, 59.71 ], [ 0.3, 61.15 ], [ 0.462, 58.95 ], [ 0.569, 55.58 ],
      [ 0.675, 52.2 ], [ 0.778, 46.39 ], [ 0.846, 38.12 ], [ 0.917, 20.32 ], [ 1.009, 3.542 ],
      [ 1.032, 1.602 ], [ 1.045, 0 ]
],
    "totImpulseNs": 49.9,
    "totalWeightG": 73,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 31.6,
    "burnTimeS": 1.67,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F32",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "53F32-12A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 49.3,
    "motorId": "5f4294d20002310000000410",
    "propInfo": "White",
    "propWeightG": 29.9,
    "samples": [
      [ 0, 0 ], [ 0.002, 2.999 ], [ 0.035, 48.04 ], [ 0.065, 24.05 ], [ 0.111, 31.03 ], [ 0.162, 32.79 ],
      [ 0.36, 34.81 ], [ 0.521, 35.59 ], [ 0.665, 36.7 ], [ 0.787, 35.98 ], [ 1.436, 29.2 ],
      [ 1.513, 27.25 ], [ 1.576, 21.58 ], [ 1.641, 7.171 ], [ 1.726, 0 ]
],
    "totImpulseNs": 52.8,
    "totalWeightG": 107,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 31.1,
    "burnTimeS": 1.78,
    "caseInfo": "Pro29-1G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "F31",
    "dataFiles": 2,
    "delays": "3,5,7,9,12",
    "designation": "56F31-12A",
    "diameter": 29,
    "impulseClass": "F",
    "length": 98,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 65,
    "motorId": "5f4294d20002310000000411",
    "propInfo": "Classic",
    "propWeightG": 70,
    "samples": [
      [ 0, 0 ], [ 0.001, 3.483 ], [ 0.023, 64.05 ], [ 0.05, 31.35 ], [ 0.059, 28.46 ], [ 0.095, 32.03 ],
      [ 0.212, 36.19 ], [ 0.344, 37.55 ], [ 1.567, 26.16 ], [ 1.631, 26.93 ], [ 1.663, 25.32 ],
      [ 1.785, 3.653 ], [ 1.828, 0 ]
],
    "totImpulseNs": 55.5,
    "totalWeightG": 102,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 32.7,
    "burnTimeS": 4.38,
    "caseInfo": "Pro29-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "G33",
    "dataFiles": 2,
    "delays": "2,4,6,9",
    "designation": "143G33-9A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 187,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 66.1,
    "motorId": "5f4294d20002310000000412",
    "propInfo": "Mellow",
    "propWeightG": 79.1,
    "samples": [
      [ 0, 0 ], [ 0.009, 5.657 ], [ 0.035, 62.82 ], [ 0.07, 27.44 ], [ 0.143, 34.79 ], [ 0.228, 38.59 ],
      [ 0.568, 39.77 ], [ 1.358, 37.66 ], [ 3.126, 36.14 ], [ 3.405, 31.58 ], [ 3.762, 21.62 ],
      [ 4.224, 6.671 ], [ 4.461, 0 ]
],
    "totImpulseNs": 143.1,
    "totalWeightG": 197,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 42.2,
    "burnTimeS": 4.41,
    "caseInfo": "Pro29-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H42",
    "dataFiles": 1,
    "delays": "1,3,5,7,10",
    "designation": "186H42-10A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 231,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 83.5,
    "motorId": "5f4294d20002310000000413",
    "propInfo": "Mellow",
    "propWeightG": 105.5,
    "samples": [
      [ 0, 0 ], [ 0.015, 8.71 ], [ 0.035, 82.8 ], [ 0.059, 43.55 ], [ 0.118, 48.39 ], [ 0.261, 54.95 ],
      [ 0.391, 55.48 ], [ 1.21, 51.29 ], [ 2.797, 49.46 ], [ 3.029, 45.16 ], [ 3.757, 20.54 ],
      [ 4.374, 4.409 ], [ 4.448, 0 ]
],
    "totImpulseNs": 186,
    "totalWeightG": 242,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 52.7,
    "burnTimeS": 4.44,
    "caseInfo": "Pro29-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "H53",
    "dataFiles": 1,
    "delays": "3,5,7,9,12",
    "designation": "234H53-12A",
    "diameter": 29,
    "impulseClass": "H",
    "length": 276,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 101.8,
    "motorId": "5f4294d20002310000000414",
    "propInfo": "Mellow",
    "propWeightG": 131.9,
    "samples": [
      [ 0, 0 ], [ 0.006, 8.958 ], [ 0.027, 101 ], [ 0.042, 63.23 ], [ 0.102, 71.01 ], [ 0.2, 77.99 ],
      [ 0.362, 79.83 ], [ 0.98, 71.14 ], [ 2.561, 65.34 ], [ 2.663, 60.86 ], [ 3.661, 16.73 ],
      [ 4.011, 9.353 ], [ 4.316, 4.347 ], [ 4.447, 0 ]
],
    "totImpulseNs": 234.2,
    "totalWeightG": 286,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 55.2,
    "burnTimeS": 7.51,
    "caseInfo": "Pro38-3G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "I55",
    "dataFiles": 1,
    "delays": "2,4,6,9",
    "designation": "395I55-9A",
    "diameter": 38,
    "impulseClass": "I",
    "length": 245,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 94.5,
    "motorId": "5f4294d20002310000000415",
    "propInfo": "Mellow",
    "propWeightG": 223.8,
    "samples": [
      [ 0, 0 ], [ 0.009, 10.02 ], [ 0.033, 92.3 ], [ 0.066, 57.67 ], [ 0.094, 55.33 ], [ 0.222, 64.31 ],
      [ 0.471, 67.95 ], [ 2.088, 63.27 ], [ 3.753, 61.7 ], [ 5.195, 62.23 ], [ 5.789, 43.87 ],
      [ 6.105, 40.09 ], [ 6.765, 13.67 ], [ 7.085, 6.639 ], [ 7.246, 0 ]
],
    "totImpulseNs": 394.6,
    "totalWeightG": 437,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 94.4,
    "burnTimeS": 6.82,
    "caseInfo": "Pro38-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J94",
    "dataFiles": 1,
    "delays": "P",
    "designation": "644J94-P",
    "diameter": 38,
    "impulseClass": "J",
    "length": 367,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 172.5,
    "motorId": "5f4294d20002310000000416",
    "propInfo": "Mellow",
    "propWeightG": 372.9,
    "samples": [
      [ 0, 0 ], [ 0.005, 19.48 ], [ 0.014, 168.6 ], [ 0.05, 134 ], [ 0.113, 134 ], [ 0.203, 146.1 ],
      [ 0.752, 134 ], [ 1.787, 116.9 ], [ 3.075, 110.5 ], [ 4.236, 108.3 ], [ 4.803, 77.67 ],
      [ 5.474, 56.3 ], [ 6.375, 17.58 ], [ 6.879, 6.413 ], [ 6.928, 0 ]
],
    "totImpulseNs": 644,
    "totalWeightG": 660,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 148.4,
    "burnTimeS": 6.4,
    "caseInfo": "Pro38-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J150",
    "dataFiles": 1,
    "delays": "P",
    "designation": "949J150-P",
    "diameter": 38,
    "impulseClass": "J",
    "length": 500,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 343.2,
    "motorId": "5f4294d20002310000000417",
    "propInfo": "Mellow",
    "propWeightG": 567.7,
    "samples": [
      [ 0, 0 ], [ 0.004, 33.05 ], [ 0.03, 324.6 ], [ 0.12, 248.7 ], [ 0.617, 225 ], [ 1.385, 201.3 ],
      [ 2.306, 183.5 ], [ 3.425, 177.1 ], [ 4.149, 123.7 ], [ 4.934, 79.66 ], [ 5.59, 42.37 ],
      [ 6.318, 19.49 ], [ 6.434, 0 ]
],
    "totImpulseNs": 949.2,
    "totalWeightG": 951,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 266.8,
    "burnTimeS": 9.91,
    "caseInfo": "Pro54-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L265",
    "dataFiles": 2,
    "delays": "P",
    "designation": "2645L265-P",
    "diameter": 54,
    "impulseClass": "L",
    "length": 649,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 471.1,
    "motorId": "5f4294d20002310000000418",
    "propInfo": "Mellow",
    "propWeightG": 1603,
    "samples": [
      [ 0, 0 ], [ 0.007, 30.04 ], [ 0.021, 461.1 ], [ 0.188, 425.2 ], [ 0.607, 399.9 ], [ 1.353, 354.5 ],
      [ 1.939, 336.3 ], [ 3.592, 319.2 ], [ 6.053, 312.1 ], [ 8.09, 133.1 ], [ 8.508, 96 ],
      [ 9.485, 47.11 ], [ 9.882, 0 ]
],
    "totImpulseNs": 2645,
    "totalWeightG": 2481,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 520.2,
    "burnTimeS": 3.29,
    "caseInfo": "Pro54-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K520",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "1711K520-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 404,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 619.2,
    "motorId": "5f4294d20002310000000419",
    "propInfo": "White",
    "propWeightG": 944,
    "samples": [
      [ 0, 0 ], [ 0.007, 121.7 ], [ 0.023, 591.8 ], [ 0.075, 615.5 ], [ 0.151, 580.8 ], [ 1.629, 568.1 ],
      [ 2.177, 535.1 ], [ 2.903, 476.8 ], [ 2.993, 414.3 ], [ 3.242, 97.22 ], [ 3.34, 0 ]
],
    "totImpulseNs": 1711,
    "totalWeightG": 1576,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 600.3,
    "burnTimeS": 3.55,
    "caseInfo": "Pro54-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K600",
    "dataFiles": 1,
    "delays": "7,8,9,10,11,12,13,14,15,16,17",
    "designation": "2130K600-17A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 488,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 799.7,
    "motorId": "5f4294d2000231000000041a",
    "propInfo": "White",
    "propWeightG": 1180,
    "samples": [
      [ 0, 0 ], [ 0.002, 97.24 ], [ 0.007, 785.1 ], [ 0.03, 741.9 ], [ 0.101, 745.5 ], [ 0.148, 731.1 ],
      [ 0.455, 699.9 ], [ 1.711, 669.9 ], [ 2.313, 619.4 ], [ 2.911, 559.4 ], [ 3.093, 420.2 ],
      [ 3.513, 60.02 ], [ 3.535, 0 ]
],
    "totImpulseNs": 2130,
    "totalWeightG": 1892,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 710.4,
    "burnTimeS": 3.35,
    "caseInfo": "Pro54-6G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K711",
    "dataFiles": 1,
    "delays": "8,9,10,11,12,13,14,15,16,17,18",
    "designation": "2377K711-18A",
    "diameter": 54,
    "impulseClass": "K",
    "length": 572,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1701,
    "motorId": "5f4294d2000231000000041b",
    "propInfo": "White",
    "propWeightG": 1398,
    "samples": [
      [ 0, 0 ], [ 0.002, 239.9 ], [ 0.003, 1703 ], [ 0.03, 976.7 ], [ 0.149, 903.2 ], [ 0.474, 855.7 ],
      [ 1.791, 780.1 ], [ 2.397, 721.7 ], [ 2.65, 546.7 ], [ 2.976, 395.4 ], [ 3.343, 105.9 ],
      [ 3.359, 0 ]
],
    "totImpulseNs": 2377,
    "totalWeightG": 2198,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 143.7,
    "burnTimeS": 4.87,
    "caseInfo": "Pro54-2G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "J145",
    "dataFiles": 1,
    "delays": "9,10,11,12,13,14,15,16,17,18,19",
    "designation": "699J145-19A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 236,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 505.3,
    "motorId": "5f4294d2000231000000041c",
    "propInfo": "Skidmark",
    "propWeightG": 417,
    "samples": [
      [ 0, 0 ], [ 0.011, 39.12 ], [ 0.018, 498.2 ], [ 0.059, 224.9 ], [ 0.154, 257.3 ], [ 0.488, 235.3 ],
      [ 1.881, 180.3 ], [ 3.612, 78.85 ], [ 4.669, 31.17 ], [ 4.896, 22.62 ], [ 4.944, 0 ]
],
    "sparky": true,
    "totImpulseNs": 699.1,
    "totalWeightG": 837,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1111,
    "burnTimeS": 4.68,
    "caseInfo": "Pro75-4G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "M1101",
    "dataFiles": 2,
    "delays": "P",
    "designation": "5198M1101-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 621,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 1475,
    "motorId": "5f4294d2000231000000041d",
    "propInfo": "White",
    "propWeightG": 2993,
    "samples": [
      [ 0, 0 ], [ 0.003, 281.7 ], [ 0.05, 1437 ], [ 0.121, 1363 ], [ 0.366, 1264 ], [ 0.59, 1230 ],
      [ 1.745, 1322 ], [ 2.835, 1166 ], [ 4, 974.6 ], [ 4.158, 839.4 ], [ 4.668, 82.63 ],[ 4.736, 0 ]
],
    "totImpulseNs": 5198,
    "totalWeightG": 4938,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 393.7,
    "burnTimeS": 12.52,
    "caseInfo": "Pro75-5G",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "L395",
    "dataFiles": 2,
    "delays": "P",
    "designation": "4937L395-P",
    "diameter": 75,
    "impulseClass": "L",
    "infoUrl": "http://www.pro38.com/products/pro75/motor/MotorData.php?prodid=4937L395-P",
    "length": 757,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 587.5,
    "motorId": "5f4294d2000231000000041e",
    "propInfo": "Mellow",
    "propWeightG": 3423,
    "samples": [
      [ 0, 0 ], [ 0.04, 91.16 ], [ 0.129, 484.2 ], [ 0.209, 578.7 ], [ 0.547, 553.6 ], [ 2.043, 511.8 ],
      [ 7.329, 439.1 ], [ 8.632, 424 ], [ 9.573, 297.7 ], [ 11.4, 100.4 ], [ 12.45, 30.11 ],
      [ 12.5, 0 ]
],
    "totImpulseNs": 4937,
    "totalWeightG": 5706,
    "type": "reload",
    "updatedOn": "2021-12-10"
  },
  {
    "availability": "regular",
    "avgThrustN": 1561,
    "burnTimeS": 10.76,
    "caseInfo": "Pro98-6GXL",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "N1560",
    "dataFiles": 2,
    "delays": "P",
    "designation": "16803N1560-P",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1239,
    "manufacturer": "Cesaroni Technology",
    "manufacturerAbbrev": "Cesaroni",
    "maxThrustN": 3216,
    "motorId": "5f4294d2000231000000041f",
    "propInfo": "White",
    "propWeightG": 9946,
    "samples": [
      [ 0, 0 ], [ 0.007, 260.3 ], [ 0.067, 2888 ], [ 0.171, 3198 ], [ 0.291, 2826 ], [ 0.894, 2421 ],
      [ 2.503, 2231 ], [ 3.866, 2041 ], [ 5.333, 1723 ], [ 9.244, 570.2 ], [ 10.71, 165.3 ],
      [ 10.82, 0 ]
],
    "totImpulseNs": 16800,
    "totalWeightG": 15860,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 591.2,
    "burnTimeS": 2.26,
    "caseInfo": "AMW 54-1400",
    "certOrg": "Canadian Association of Rocketry",
    "commonName": "K590",
    "dataFiles": 0,
    "designation": "1337K590-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 723.9,
    "motorId": "5f4294d20002310000000420",
    "propInfo": "Black Bear",
    "propWeightG": 964,
    "totImpulseNs": 1337,
    "totalWeightG": 1717,
    "type": "reload",
    "updatedOn": "2021-06-13"
  },
  {
    "availability": "OOP",
    "avgThrustN": 235,
    "burnTimeS": 1.4,
    "caseInfo": "38/390",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I235",
    "dataFiles": 0,
    "designation": "I235WC",
    "diameter": 38,
    "impulseClass": "I",
    "length": 249,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 291.3,
    "motorId": "5f4294d20002310000000421",
    "propInfo": "White Cloud",
    "propWeightG": 173,
    "totImpulseNs": 324,
    "totalWeightG": 466,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 392,
    "burnTimeS": 1.2,
    "caseInfo": "38/640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I392",
    "dataFiles": 0,
    "designation": "I392BL",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 508.2,
    "motorId": "5f4294d20002310000000422",
    "propInfo": "Black Lightning",
    "propWeightG": 270,
    "sparky": true,
    "totImpulseNs": 416.1,
    "totalWeightG": 657,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 462,
    "burnTimeS": 1.2,
    "caseInfo": "38/640",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I462",
    "dataFiles": 0,
    "designation": "I462WC",
    "diameter": 38,
    "impulseClass": "I",
    "length": 370,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 557.1,
    "motorId": "5f4294d20002310000000423",
    "propInfo": "White Cloud",
    "propWeightG": 288,
    "totImpulseNs": 556.7,
    "totalWeightG": 678,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 607,
    "burnTimeS": 1.15,
    "caseInfo": "38/780",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J607",
    "dataFiles": 0,
    "designation": "J607WC",
    "diameter": 38,
    "impulseClass": "J",
    "length": 370,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 803.6,
    "motorId": "5f4294d20002310000000424",
    "propInfo": "White Cloud",
    "propWeightG": 370,
    "totImpulseNs": 677.9,
    "totalWeightG": 824,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 695,
    "burnTimeS": 2.11,
    "caseInfo": "54/1400",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K695",
    "dataFiles": 0,
    "designation": "K695BT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 402,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 922.3,
    "motorId": "5f4294d20002310000000425",
    "propInfo": "Blue Typhoon",
    "propWeightG": 816,
    "totImpulseNs": 1463,
    "totalWeightG": 1497,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 828,
    "burnTimeS": 2.16,
    "caseInfo": "54/1750",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K828",
    "dataFiles": 0,
    "designation": "K828BT",
    "diameter": 54,
    "impulseClass": "K",
    "length": 491,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1301,
    "motorId": "5f4294d20002310000000426",
    "propInfo": "Blue Typhoon",
    "propWeightG": 870,
    "totImpulseNs": 1786,
    "totalWeightG": 1836,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 235,
    "burnTimeS": 9.1,
    "caseInfo": "75/2500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K235",
    "dataFiles": 0,
    "designation": "K235WC",
    "diameter": 75,
    "impulseClass": "K",
    "length": 728,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 428.2,
    "motorId": "5f4294d20002310000000427",
    "propInfo": "White Cloud",
    "propWeightG": 1180,
    "totImpulseNs": 2074,
    "totalWeightG": 2770,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 745,
    "burnTimeS": 7.75,
    "caseInfo": "75/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M745",
    "dataFiles": 1,
    "designation": "M745WC",
    "diameter": 75,
    "impulseClass": "M",
    "length": 787,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1424,
    "motorId": "5f4294d20002310000000428",
    "propInfo": "White Cloud",
    "propWeightG": 2950,
    "samples": [
      [ 0, 0 ], [ 0.1, 1112 ], [ 0.15, 1246 ], [ 0.3, 1446 ], [ 0.6, 1548 ], [ 0.7, 1557 ],[ 0.75, 1334 ],
      [ 7.25, 0 ]
],
    "totImpulseNs": 5368,
    "totalWeightG": 5620,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1085,
    "burnTimeS": 7,
    "caseInfo": "75/7600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1085",
    "dataFiles": 1,
    "designation": "M1085WC",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2267,
    "motorId": "5f4294d20002310000000429",
    "propInfo": "White Cloud",
    "propWeightG": 3810,
    "samples": [
      [ 0, 0 ], [ 0.01, 2002 ], [ 0.05, 1935 ], [ 0.25, 2113 ], [ 0.4, 2224 ], [ 0.45, 2269 ],
      [ 0.5, 2291 ], [ 0.55, 2291 ], [ 0.6, 2224 ], [ 0.9, 1846 ], [ 1.1, 1690 ], [ 1.45, 1535 ],
      [ 3.65, 978.6 ], [ 4.65, 667.2 ], [ 6.7, 88.96 ], [ 7.2, 0 ]
],
    "totImpulseNs": 6958,
    "totalWeightG": 7058,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2925,
    "burnTimeS": 2.71,
    "caseInfo": "75/7600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2925",
    "dataFiles": 1,
    "designation": "M2925WC",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1039,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 4532,
    "motorId": "5f4294d2000231000000042a",
    "propInfo": "White Cloud",
    "propWeightG": 3810,
    "samples": [
      [ 0, 0 ], [ 0.02, 2580 ], [ 0.05, 3025 ], [ 0.1, 3292 ], [ 0.5, 3870 ], [ 1, 4448 ], [ 1.35, 4537 ],
      [ 1.5, 4226 ], [ 1.85, 2447 ], [ 2.1, 1334 ], [ 2.32, 778.4 ], [ 2.75, 0 ]
],
    "totImpulseNs": 7898,
    "totalWeightG": 7058,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1565,
    "burnTimeS": 5.11,
    "caseInfo": "98/11000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1565",
    "dataFiles": 0,
    "designation": "M1565BL",
    "diameter": 98,
    "impulseClass": "M",
    "length": 870,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2219,
    "motorId": "5f4294d2000231000000042b",
    "propInfo": "Black Lightning",
    "propWeightG": 4992,
    "sparky": true,
    "totImpulseNs": 7533,
    "totalWeightG": 9801,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2717,
    "burnTimeS": 4.1,
    "caseInfo": "98/11000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N2717",
    "dataFiles": 1,
    "designation": "N2717WC",
    "diameter": 98,
    "impulseClass": "N",
    "length": 870,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 4200,
    "motorId": "5f4294d2000231000000042c",
    "propInfo": "White Cloud",
    "propWeightG": 5328,
    "samples": [
      [ 0, 0 ], [ 0.047, 165 ], [ 0.063, 377.1 ], [ 0.071, 907.3 ], [ 0.075, 1650 ], [ 0.08475, 2241 ],
      [ 0.2417, 2465 ], [ 0.5964, 2965 ], [ 0.8914, 3379 ], [ 1.128, 3688 ], [ 1.329, 3947 ],
      [ 1.522, 4101 ], [ 1.723, 4195 ], [ 1.841, 4195 ], [ 1.901, 4195 ], [ 1.936, 4136 ],
      [ 2.009, 4034 ], [ 2.191, 3707 ], [ 2.379, 3345 ], [ 2.608, 2931 ], [ 2.808, 2569 ],
      [ 2.991, 2207 ], [ 3.208, 1810 ], [ 3.428, 1397 ], [ 3.525, 1207 ], [ 3.671, 883.8 ],
      [ 3.841, 459.6 ], [ 3.927, 282.8 ], [ 4.014, 200.3 ], [ 4.271, 58.92 ], [ 4.393, 0 ]
],
    "totImpulseNs": 10810,
    "totalWeightG": 10140,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1720,
    "burnTimeS": 8.3,
    "caseInfo": "98/17500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N1720",
    "dataFiles": 1,
    "designation": "N1720WC",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1213,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 3613,
    "motorId": "5f4294d2000231000000042d",
    "propInfo": "White Cloud",
    "propWeightG": 7380,
    "samples": [
      [ 0, 0 ], [ 0.033, 3572 ], [ 0.06, 3290 ], [ 0.067, 3101 ], [ 0.113, 3245 ], [ 0.2073, 3276 ],
      [ 0.293, 3303 ], [ 0.42, 3474 ], [ 0.54, 3591 ], [ 0.607, 3591 ], [ 0.673, 3585 ], [ 0.72, 3506 ],
      [ 0.773, 3428 ], [ 0.82, 3336 ], [ 0.867, 3251 ], [ 0.893, 3205 ], [ 1.14, 3101 ], [ 1.467, 2898 ],
      [ 2.153, 2610 ], [ 2.587, 2447 ], [ 3, 2276 ], [ 4.013, 1812 ], [ 4.993, 1341 ], [ 6.013, 804.6 ],
      [ 6.647, 510.2 ], [ 6.847, 431 ], [ 6.991, 379.3 ], [ 7.155, 310.3 ], [ 7.431, 258.6 ],
      [ 7.682, 189.7 ], [ 7.889, 120.7 ], [ 8.053, 51.72 ], [ 8.253, 0 ]
],
    "totImpulseNs": 14240,
    "totalWeightG": 15420,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 2645,
    "burnTimeS": 9.18,
    "caseInfo": "152/35000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "O2645",
    "dataFiles": 1,
    "designation": "O2645BL",
    "diameter": 152,
    "impulseClass": "O",
    "length": 1203,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 3920,
    "motorId": "5f4294d2000231000000042e",
    "propInfo": "Black Lightning",
    "propWeightG": 15150,
    "samples": [
      [ 0, 0 ], [ 0.037, 446.3 ], [ 0.056, 612.8 ], [ 0.121, 627.9 ], [ 0.26, 1210 ], [ 0.261, 1331 ],
      [ 0.501, 1740 ], [ 0.734, 2322 ], [ 0.994, 2595 ], [ 2.006, 3147 ], [ 3.008, 3646 ],
      [ 3.519, 3805 ], [ 4.318, 3888 ], [ 4.494, 3851 ], [ 4.995, 3692 ], [ 5.478, 3578 ],
      [ 6.193, 3162 ], [ 6.249, 3245 ], [ 6.314, 3102 ], [ 7.001, 2345 ], [ 7.456, 1778 ],
      [ 7.864, 1210 ], [ 7.994, 1052 ], [ 8.208, 839.7 ], [ 8.431, 605.2 ], [ 8.598, 506.9 ],
      [ 8.821, 355.6 ], [ 9.044, 234.5 ], [ 9.331, 143.7 ], [ 9.443, 121 ], [ 9.499, 0 ]
],
    "sparky": true,
    "totImpulseNs": 24330,
    "totalWeightG": 33340,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 4925,
    "burnTimeS": 6.71,
    "caseInfo": "152/35000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "O4925",
    "dataFiles": 0,
    "designation": "O4925WC",
    "diameter": 152,
    "impulseClass": "O",
    "length": 1203,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 6827,
    "motorId": "5f4294d2000231000000042f",
    "propInfo": "White Cloud",
    "propWeightG": 17420,
    "totImpulseNs": 33010,
    "totalWeightG": 33880,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 535,
    "burnTimeS": 2.07,
    "caseInfo": "54/1050",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J535",
    "dataFiles": 1,
    "designation": "J535BT",
    "diameter": 54,
    "impulseClass": "J",
    "length": 326,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 662.4,
    "motorId": "5f4294d20002310000000430",
    "propInfo": "Blue Typhoon",
    "propWeightG": 635,
    "samples": [
      [ 0, 0 ], [ 0.014, 243.1 ], [ 0.019, 621 ], [ 0.029, 684.4 ], [ 0.04, 599.8 ], [ 0.06, 669.9 ],
      [ 0.069, 630.2 ], [ 0.088, 673.8 ], [ 0.095, 646.1 ], [ 0.164, 652.7 ], [ 0.176, 625 ],
      [ 0.236, 605.1 ], [ 0.319, 607.8 ], [ 0.498, 628.9 ], [ 0.736, 644.8 ], [ 1, 643.5 ],
      [ 1.164, 636.8 ], [ 1.35, 611.7 ], [ 1.502, 581.4 ], [ 1.652, 552.3 ], [ 1.698, 529.8 ],
      [ 1.752, 445.3 ], [ 1.776, 358.1 ], [ 1.8, 268.2 ], [ 1.831, 177 ], [ 1.883, 104.4 ],
      [ 1.979, 44.92 ], [ 2.021, 26.43 ], [ 2.145, 0 ]
],
    "totImpulseNs": 1114,
    "totalWeightG": 1225,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1357,
    "burnTimeS": 3.817,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1350",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1350W",
    "diameter": 75,
    "impulseClass": "M",
    "length": 622,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1765,
    "motorId": "5f4294d20002310000000431",
    "propInfo": "White Lightning",
    "propWeightG": 2690,
    "samples": [
      [ 0, 0 ], [ 0.03, 1429 ], [ 0.044, 1725 ], [ 0.056, 1548 ], [ 0.078, 1621 ], [ 0.115, 1589 ],
      [ 0.178, 1643 ], [ 0.455, 1750 ], [ 0.496, 1725 ], [ 0.54, 1737 ], [ 1.132, 1747 ],[ 1.499, 1731 ],
      [ 1.639, 1715 ], [ 2.505, 1334 ], [ 3.086, 1051 ], [ 3.179, 985.1 ], [ 3.227, 928.5 ],
      [ 3.315, 670.4 ], [ 3.419, 446.9 ], [ 3.46, 396.6 ], [ 3.545, 299 ], [ 3.697, 173.1 ],
      [ 3.737, 173.1 ], [ 3.845, 81.83 ], [ 3.996, 0 ]
],
    "totImpulseNs": 5178,
    "totalWeightG": 4808,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 552,
    "burnTimeS": 0.56,
    "caseInfo": "38 DMS",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H550",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "HP-H550ST",
    "diameter": 38,
    "impulseClass": "H",
    "length": 206,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 640,
    "motorId": "5f4294d20002310000000432",
    "propInfo": "Super Thunder",
    "propWeightG": 176,
    "samples": [
      [ 0, 0 ], [ 0.008, 445.4 ], [ 0.009, 533.8 ], [ 0.01, 565.6 ], [ 0.013, 586.8 ], [ 0.015, 623.3 ],
      [ 0.021, 639.8 ], [ 0.036, 606.8 ], [ 0.045, 629.2 ], [ 0.057, 615.1 ], [ 0.061, 635.1 ],
      [ 0.067, 619.8 ], [ 0.101, 637.5 ], [ 0.116, 622.2 ], [ 0.157, 643.4 ], [ 0.174, 623.3 ],
      [ 0.196, 623.3 ], [ 0.205, 639.8 ], [ 0.231, 631.6 ], [ 0.234, 618.6 ], [ 0.255, 611.6 ],
      [ 0.268, 628.1 ], [ 0.314, 595.1 ], [ 0.334, 601 ], [ 0.362, 571.5 ], [ 0.386, 565.6 ],
      [ 0.401, 556.2 ], [ 0.426, 518.5 ], [ 0.445, 503.1 ], [ 0.493, 447.8 ], [ 0.505, 447.8 ],
      [ 0.511, 456 ], [ 0.519, 451.3 ], [ 0.535, 357 ], [ 0.545, 266.3 ], [ 0.551, 179.1 ],
      [ 0.559, 91.91 ], [ 0.565, 42.42 ], [ 0.572, 12.96 ], [ 0.579, 0 ]
],
    "totImpulseNs": 312,
    "totalWeightG": 316,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 297,
    "burnTimeS": 4.1,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J300",
    "dataFiles": 2,
    "delays": "P",
    "designation": "J300LR",
    "diameter": 54,
    "impulseClass": "J",
    "length": 327,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 401,
    "motorId": "5f4294d20002310000000433",
    "propInfo": "Loki Red",
    "propWeightG": 620,
    "samples": [
      [ 0, 0 ], [ 0.015, 78.25 ], [ 0.025, 89.31 ], [ 0.06, 358.1 ], [ 0.076, 395.5 ], [ 0.111, 403.1 ],
      [ 0.176, 381 ], [ 0.287, 365.7 ], [ 0.504, 363.2 ], [ 0.998, 361.5 ], [ 1.497, 363.2 ],
      [ 2.001, 344.5 ], [ 2.227, 331.7 ], [ 2.504, 310.4 ], [ 2.993, 270.5 ], [ 3.255, 242.4 ],
      [ 3.502, 222.8 ], [ 3.578, 222.8 ], [ 3.643, 190.5 ], [ 3.699, 134.4 ], [ 3.724, 118.2 ],
      [ 3.8, 106.3 ], [ 3.926, 44.23 ], [ 3.996, 29.77 ], [ 4.278, 0 ]
],
    "totImpulseNs": 1208,
    "totalWeightG": 1315,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 526,
    "burnTimeS": 3.9,
    "caseInfo": "54/2000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K527",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K527LR",
    "diameter": 54,
    "impulseClass": "K",
    "length": 492,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 754,
    "motorId": "5f4294d20002310000000434",
    "propInfo": "Loki Red",
    "propWeightG": 1015,
    "samples": [
      [ 0, 0 ], [ 0.023, 87.87 ], [ 0.051, 624.5 ], [ 0.06, 701.4 ], [ 0.079, 759.4 ], [ 0.125, 707.6 ],
      [ 0.176, 682.5 ], [ 0.269, 654.3 ], [ 0.501, 649.6 ], [ 1.01, 644.9 ], [ 1.265, 640.2 ],
      [ 1.52, 646.4 ], [ 1.701, 643.3 ], [ 2.035, 618.2 ], [ 2.526, 568 ], [ 2.827, 539.7 ],
      [ 2.943, 530.3 ], [ 2.999, 513.1 ], [ 3.04, 442.5 ], [ 3.115, 356.2 ], [ 3.309, 219.7 ],
      [ 3.481, 91 ], [ 3.546, 59.62 ], [ 3.652, 43.93 ], [ 3.791, 37.66 ], [ 3.967, 10.98 ],
      [ 4.129, 0 ]
],
    "totImpulseNs": 1983,
    "totalWeightG": 1973,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1648,
    "burnTimeS": 4,
    "caseInfo": "76/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1650",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1650LC",
    "diameter": 76,
    "impulseClass": "M",
    "length": 784,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 3719,
    "motorId": "5f4294d20002310000000435",
    "propInfo": "Cocktail",
    "propWeightG": 3230,
    "samples": [
      [ 0, 0 ], [ 0.015, 1913 ], [ 0.046, 3780 ], [ 0.097, 3150 ], [ 0.132, 2866 ], [ 0.183, 2643 ],
      [ 0.295, 2458 ], [ 0.407, 2351 ], [ 0.509, 2297 ], [ 0.91, 2097 ], [ 1.495, 1921 ],[ 1.612, 1882 ],
      [ 2.772, 1337 ], [ 3.266, 1045 ], [ 3.342, 960.3 ], [ 3.398, 860.5 ], [ 3.505, 799 ],
      [ 3.606, 630 ], [ 3.81, 361.1 ], [ 3.901, 238.2 ], [ 4.008, 153.7 ], [ 4.613, 0 ]
],
    "totImpulseNs": 6535,
    "totalWeightG": 5630,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3464,
    "burnTimeS": 2.7,
    "caseInfo": "76/8000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M3464",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M3464LB",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1038,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 4328,
    "motorId": "5f4294d20002310000000436",
    "propInfo": "Loki Blue",
    "propWeightG": 4464,
    "samples": [
      [ 0, 0 ], [ 0.007, 4907 ], [ 0.02, 4008 ], [ 0.047, 3867 ], [ 0.35, 3867 ], [ 0.502, 3867 ],
      [ 0.579, 3893 ], [ 1.239, 4278 ], [ 1.808, 4304 ], [ 1.886, 4304 ], [ 1.919, 4188 ],
      [ 2, 3404 ], [ 2.064, 2916 ], [ 2.152, 2569 ], [ 2.242, 2569 ], [ 2.283, 2441 ], [ 2.347, 1966 ],
      [ 2.384, 1542 ], [ 2.434, 1105 ], [ 2.485, 835 ], [ 2.545, 719.4 ], [ 2.596, 475.3 ],
      [ 2.717, 205.6 ], [ 2.862, 51.39 ], [ 2.976, 0 ]
],
    "totImpulseNs": 9395,
    "totalWeightG": 7597,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1127,
    "burnTimeS": 3.29,
    "caseInfo": "75/3500",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1112",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L1112BT",
    "diameter": 75,
    "impulseClass": "L",
    "length": 498,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 1297,
    "motorId": "5f4294d20002310000000437",
    "propInfo": "Blue Typhoon",
    "propWeightG": 1800,
    "samples": [
      [ 0, 0 ], [ 0.05, 1132 ], [ 0.071, 1174 ], [ 0.113, 1177 ], [ 0.176, 1211 ], [ 0.263, 1294 ],
      [ 0.397, 1145 ], [ 0.493, 1129 ], [ 0.786, 1182 ], [ 1.062, 1254 ], [ 1.078, 1233 ],
      [ 1.229, 1275 ], [ 1.308, 1254 ], [ 1.492, 1283 ], [ 2.002, 1294 ], [ 2.236, 1299 ],
      [ 2.345, 1283 ], [ 2.545, 1227 ], [ 2.608, 1198 ], [ 2.688, 1190 ], [ 2.759, 1206 ],
      [ 2.809, 1169 ], [ 2.884, 892.7 ], [ 2.922, 672.2 ], [ 3.009, 504.8 ], [ 3.223, 225.8 ],
      [ 3.26, 108.9 ], [ 3.331, 37.2 ], [ 3.44, 5.314 ], [ 3.595, 0 ]
],
    "totImpulseNs": 3709,
    "totalWeightG": 3628,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "OOP",
    "avgThrustN": 1952,
    "burnTimeS": 3.09,
    "caseInfo": "75/6000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1952",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M1952BT",
    "diameter": 75,
    "impulseClass": "M",
    "length": 785,
    "manufacturer": "Gorilla Rocket Motors",
    "manufacturerAbbrev": "Gorilla",
    "maxThrustN": 2692,
    "motorId": "5f4294d20002310000000438",
    "propInfo": "Blue Typhoon",
    "propWeightG": 2970,
    "samples": [
      [ 0, 0 ], [ 0.025, 1639 ], [ 0.033, 2398 ], [ 0.046, 2101 ], [ 0.075, 2520 ], [ 0.096, 2599 ],
      [ 0.104, 2689 ], [ 0.187, 2689 ], [ 0.228, 2620 ], [ 0.274, 2445 ], [ 0.316, 2329 ],
      [ 0.403, 2276 ], [ 0.523, 2292 ], [ 1.001, 2339 ], [ 1.188, 2345 ], [ 1.499, 2329 ],
      [ 1.637, 2302 ], [ 1.794, 2270 ], [ 2.093, 2239 ], [ 2.193, 2207 ], [ 2.243, 2143 ],
      [ 2.368, 1777 ], [ 2.505, 1310 ], [ 2.525, 1241 ], [ 2.584, 1220 ], [ 2.609, 1119 ],
      [ 2.663, 960.1 ], [ 2.733, 763.9 ], [ 2.8, 567.6 ], [ 2.87, 413.8 ], [ 2.987, 238.7 ],
      [ 3.178, 63.65 ], [ 3.41, 0 ]
],
    "totImpulseNs": 6032,
    "totalWeightG": 5443,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1026,
    "burnTimeS": 1.2,
    "caseInfo": "38/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J1026",
    "dataFiles": 2,
    "delays": "P",
    "designation": "J1026CT",
    "diameter": 38,
    "impulseClass": "J",
    "length": 625.5,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1212,
    "motorId": "5f4294d20002310000000439",
    "propInfo": "Cocktail",
    "propWeightG": 616,
    "samples": [
      [ 0, 0 ], [ 0.019, 62.8 ], [ 0.034, 795.4 ], [ 0.045, 1167 ], [ 0.07, 1183 ], [ 0.149, 1154 ],
      [ 0.249, 1146 ], [ 0.4, 1162 ], [ 0.49, 1185 ], [ 0.6, 1193 ], [ 0.8, 1180 ], [ 0.851, 1172 ],
      [ 0.899, 1170 ], [ 0.922, 1159 ], [ 0.947, 1167 ], [ 0.979, 1128 ], [ 1.009, 1068 ],
      [ 1.089, 669.9 ], [ 1.13, 486.7 ], [ 1.142, 468.4 ], [ 1.151, 408.2 ], [ 1.161, 316.6 ],
      [ 1.176, 222.4 ], [ 1.201, 128.2 ], [ 1.227, 65.42 ], [ 1.264, 26.17 ], [ 1.297, 0 ]
],
    "totImpulseNs": 1267,
    "totalWeightG": 1172,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1029,
    "burnTimeS": 1.24,
    "caseInfo": "38/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1127",
    "dataFiles": 2,
    "delays": "5,7,11,14",
    "designation": "K1127-LB",
    "diameter": 38,
    "impulseClass": "K",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Blue%201200%20case.pdf",
    "length": 625.5,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1428,
    "motorId": "5f4294d2000231000000043a",
    "propInfo": "Loki Blue",
    "propWeightG": 624,
    "samples": [
      [ 0, 0 ], [ 0.009, 77.98 ], [ 0.021, 1003 ], [ 0.033, 1369 ], [ 0.045, 1210 ], [ 0.058, 1366 ],
      [ 0.067, 1264 ], [ 0.08, 1361 ], [ 0.092, 1256 ], [ 0.105, 1331 ], [ 0.117, 1242 ],[ 0.13, 1312 ],
      [ 0.142, 1261 ], [ 0.155, 1304 ], [ 0.167, 1261 ], [ 0.218, 1291 ], [ 0.395, 1326 ],
      [ 0.542, 1369 ], [ 0.689, 1393 ], [ 0.726, 1382 ], [ 0.755, 1344 ], [ 0.814, 1111 ],
      [ 0.867, 949.2 ], [ 0.874, 1100 ], [ 0.883, 962.6 ], [ 0.908, 847 ], [ 0.989, 548.5 ],
      [ 0.997, 363 ], [ 1.003, 715.2 ], [ 1.014, 494.8 ], [ 1.048, 406 ], [ 1.091, 352.2 ],
      [ 1.152, 228.6 ], [ 1.198, 158.6 ], [ 1.244, 69.91 ], [ 1.286, 32.27 ], [ 1.37, 0 ]
],
    "totImpulseNs": 1285,
    "totalWeightG": 1172,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 834,
    "burnTimeS": 4.6,
    "caseInfo": "76/3600",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L840",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L840CT",
    "diameter": 76,
    "impulseClass": "L",
    "length": 498,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1170,
    "motorId": "5f4294d2000231000000043b",
    "propInfo": "Cocktail",
    "propWeightG": 2074,
    "samples": [
      [ 0, 0 ], [ 0.021, 889.6 ], [ 0.046, 1182 ], [ 0.077, 1082 ], [ 0.139, 978.6 ], [ 0.303, 971.2 ],
      [ 0.662, 1016 ], [ 1.011, 1108 ], [ 1.33, 1149 ], [ 1.689, 1190 ], [ 1.971, 1164 ],[ 2.089, 1145 ],
      [ 2.3, 1034 ], [ 2.633, 893.4 ], [ 2.823, 822.9 ], [ 3.455, 656.1 ], [ 3.737, 596.8 ],
      [ 3.891, 585.7 ], [ 3.984, 385.5 ], [ 4.112, 355.9 ], [ 4.215, 203.9 ], [ 4.292, 140.9 ],
      [ 4.384, 103.8 ], [ 4.589, 63.02 ], [ 4.8, 0 ]
],
    "totImpulseNs": 3889,
    "totalWeightG": 3748,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1966,
    "burnTimeS": 3.7,
    "caseInfo": "76/8000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1969",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1969SF",
    "diameter": 76,
    "impulseClass": "M",
    "length": 1038,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 2447,
    "motorId": "5f4294d2000231000000043c",
    "propInfo": "Spitfire",
    "propWeightG": 4266,
    "samples": [
      [ 0, 0 ], [ 0.036, 263.3 ], [ 0.044, 1504 ], [ 0.075, 2205 ], [ 0.087, 2357 ], [ 0.186, 2205 ],
      [ 0.23, 2281 ], [ 0.274, 2240 ], [ 0.503, 2348 ], [ 1.003, 2432 ], [ 1.058, 2455 ],[ 1.495, 2307 ],
      [ 2.002, 2236 ], [ 2.315, 2187 ], [ 2.501, 2182 ], [ 2.628, 2169 ], [ 2.815, 2245 ],
      [ 2.882, 2191 ], [ 3.045, 1339 ], [ 3.096, 1143 ], [ 3.164, 1044 ], [ 3.267, 964 ],[ 3.378, 807.8 ],
      [ 3.5, 504.3 ], [ 3.639, 267.8 ], [ 3.77, 102.7 ], [ 3.881, 22.32 ], [ 3.96, 0 ]
],
    "sparky": true,
    "totImpulseNs": 7296,
    "totalWeightG": 7189,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1036,
    "burnTimeS": 3.57,
    "caseInfo": "54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1040",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1040LR",
    "diameter": 54,
    "impulseClass": "L",
    "length": 736,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1422,
    "motorId": "5f4294d2000231000000043d",
    "propInfo": "Loki Red",
    "propWeightG": 1684,
    "samples": [
      [ 0, 0 ], [ 0.015, 1021 ], [ 0.03, 1238 ], [ 0.074, 1289 ], [ 0.115, 1325 ], [ 0.218, 1232 ],
      [ 0.392, 1189 ], [ 0.514, 1197 ], [ 0.744, 1232 ], [ 1.032, 1297 ], [ 1.251, 1341 ],
      [ 1.517, 1392 ], [ 2.013, 1395 ], [ 2.161, 1387 ], [ 2.257, 1343 ], [ 2.416, 1067 ],
      [ 2.453, 999.5 ], [ 2.512, 961.6 ], [ 2.612, 939.9 ], [ 2.735, 755.7 ], [ 2.838, 604 ],
      [ 2.897, 509.2 ], [ 3.012, 474 ], [ 3.212, 257.3 ], [ 3.297, 170.6 ], [ 3.404, 119.2 ],
      [ 3.475, 113.8 ], [ 3.549, 92.09 ], [ 3.619, 54.17 ], [ 3.7, 0 ]
],
    "totImpulseNs": 3707,
    "totalWeightG": 2962,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2052,
    "burnTimeS": 2.34,
    "caseInfo": "54/4000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2050",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L2050LW",
    "diameter": 54,
    "impulseClass": "L",
    "length": 1114,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 3331,
    "motorId": "5f4294d2000231000000043e",
    "propInfo": "Loki White",
    "propWeightG": 2388,
    "samples": [
      [ 0, 0 ], [ 0.027, 366.3 ], [ 0.028, 137.4 ], [ 0.043, 2237 ], [ 0.053, 2702 ], [ 0.074, 2787 ],
      [ 0.133, 2682 ], [ 0.218, 2610 ], [ 0.324, 2708 ], [ 0.495, 2859 ], [ 0.691, 3061 ],
      [ 0.87, 3173 ], [ 0.968, 3330 ], [ 1.066, 3251 ], [ 1.125, 3140 ], [ 1.157, 2859 ],[ 1.181, 2702 ],
      [ 1.213, 2617 ], [ 1.306, 2479 ], [ 1.338, 2172 ], [ 1.41, 1962 ], [ 1.503, 1871 ],[ 1.614, 1426 ],
      [ 1.753, 1020 ], [ 1.859, 870 ], [ 1.997, 628 ], [ 2, 667.2 ], [ 2.125, 431.7 ], [ 2.316, 235.5 ],
      [ 2.521, 0 ]
],
    "totImpulseNs": 4802,
    "totalWeightG": 4056,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 260,
    "burnTimeS": 2.7,
    "caseInfo": "DMS",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J250",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J250W",
    "diameter": 54,
    "impulseClass": "J",
    "length": 218.2,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 338,
    "motorId": "5f4294d2000231000000043f",
    "propInfo": "White Lightning",
    "propWeightG": 363,
    "samples": [
      [ 0, 0 ], [ 0.005, 149.9 ], [ 0.016, 124.4 ], [ 0.048, 224 ], [ 0.051, 290.7 ], [ 0.077, 309.8 ],
      [ 0.221, 324.1 ], [ 0.269, 316.1 ], [ 0.497, 335.7 ], [ 0.997, 324.1 ], [ 1.497, 288.6 ],
      [ 1.713, 266.9 ], [ 2.003, 210.2 ], [ 2.162, 176.9 ], [ 2.487, 119.7 ], [ 2.516, 125.5 ],
      [ 2.718, 91.61 ], [ 2.766, 45.54 ], [ 2.814, 18 ], [ 2.867, 7.943 ], [ 2.902, 0 ]
],
    "totImpulseNs": 704,
    "totalWeightG": 708,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 97.7,
    "burnTimeS": 2.32,
    "caseInfo": "DMS",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H100",
    "dataFiles": 2,
    "delays": "6,8,10",
    "designation": "H100W_DMS",
    "diameter": 38,
    "impulseClass": "H",
    "length": 153.1,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 137.8,
    "motorId": "5f4294d20002310000000440",
    "propInfo": "White Lightning",
    "propWeightG": 121,
    "samples": [
      [ 0, 0 ], [ 0.004, 46.92 ], [ 0.031, 66.29 ], [ 0.047, 97.33 ], [ 0.096, 109 ], [ 0.193, 116.5 ],
      [ 0.302, 124.9 ], [ 0.391, 123 ], [ 0.498, 127.9 ], [ 0.604, 128.8 ], [ 0.696, 130.2 ],
      [ 0.798, 128.6 ], [ 0.902, 128.8 ], [ 0.998, 127.4 ], [ 1.098, 126.5 ], [ 1.2, 116.9 ],
      [ 1.3, 114.6 ], [ 1.4, 110.2 ], [ 1.5, 98.27 ], [ 1.6, 90.8 ], [ 1.7, 80.06 ], [ 1.802, 68.62 ],
      [ 1.9, 65.36 ], [ 2, 56.95 ], [ 2.1, 49.48 ], [ 2.202, 25.21 ], [ 2.298, 5.135 ], [ 2.398, 0 ]
],
    "totImpulseNs": 226.8,
    "totalWeightG": 261,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1090,
    "burnTimeS": 2.45,
    "caseInfo": "RMS-54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1090",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L1090W",
    "diameter": 54,
    "impulseClass": "L",
    "length": 625.8,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1487,
    "motorId": "5f4294d20002310000000441",
    "propInfo": "White Lightning",
    "propWeightG": 1400,
    "samples": [
      [ 0, 0 ], [ 0.009, 1115 ], [ 0.012, 1253 ], [ 0.052, 1322 ], [ 0.116, 1253 ], [ 0.205, 1218 ],
      [ 0.252, 1269 ], [ 0.295, 1240 ], [ 0.347, 1264 ], [ 0.393, 1251 ], [ 0.417, 1220 ],
      [ 0.527, 1216 ], [ 0.631, 1229 ], [ 0.793, 1209 ], [ 0.813, 1249 ], [ 0.827, 1203 ],
      [ 1.007, 1192 ], [ 1.25, 1165 ], [ 1.499, 1130 ], [ 1.718, 1084 ], [ 1.747, 1099 ],[ 1.776, 1062 ],
      [ 2.008, 1045 ], [ 2.095, 886.2 ], [ 2.199, 664.1 ], [ 2.271, 540.9 ], [ 2.346, 446.4 ],
      [ 2.39, 404.6 ], [ 2.404, 380.4 ], [ 2.439, 382.6 ], [ 2.5, 274.9 ], [ 2.554, 222.1 ],
      [ 2.598, 162.7 ], [ 2.624, 147.3 ], [ 2.688, 79.16 ], [ 2.754, 32.98 ], [ 2.962, 0 ]
],
    "totImpulseNs": 2671,
    "totalWeightG": 2432,
    "type": "reload",
    "updatedOn": "2020-11-25"
  },
  {
    "availability": "regular",
    "avgThrustN": 130,
    "burnTimeS": 1.65,
    "caseInfo": "RMS-38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H130",
    "dataFiles": 2,
    "delays": "4,6,8,10,12,14",
    "designation": "H130W",
    "diameter": 38,
    "impulseClass": "H",
    "length": 152,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 260,
    "motorId": "5f4294d20002310000000442",
    "propInfo": "White Lightning",
    "propWeightG": 120,
    "samples": [
      [ 0, 0 ], [ 0.011, 90.91 ], [ 0.029, 180.7 ], [ 0.04, 208 ], [ 0.055, 235.2 ], [ 0.086, 260.2 ],
      [ 0.11, 250.6 ], [ 0.147, 246.6 ], [ 0.19, 252.8 ], [ 0.226, 242.6 ], [ 0.266, 235.2 ],
      [ 0.304, 238.6 ], [ 0.348, 226.7 ], [ 0.4, 215.3 ], [ 0.419, 220.5 ], [ 0.432, 208 ],
      [ 0.531, 177.3 ], [ 0.601, 151.7 ], [ 1.184, 69.32 ], [ 1.216, 69.32 ], [ 1.351, 46.59 ],
      [ 1.402, 40.34 ], [ 1.633, 0 ]
],
    "totImpulseNs": 210,
    "totalWeightG": 237,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 180,
    "burnTimeS": 1.8,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I180",
    "dataFiles": 2,
    "delays": "4,6,8,10,12,14",
    "designation": "I180W",
    "diameter": 38,
    "impulseClass": "I",
    "length": 191,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 362,
    "motorId": "5f4294d20002310000000443",
    "propInfo": "White Lightning",
    "propWeightG": 180,
    "samples": [
      [ 0, 0 ], [ 0.027, 133.7 ], [ 0.029, 224.5 ], [ 0.033, 328.7 ], [ 0.042, 338.3 ], [ 0.061, 327.2 ],
      [ 0.082, 337.6 ], [ 0.088, 347.2 ], [ 0.117, 361.2 ], [ 0.203, 360.5 ], [ 0.243, 350.9 ],
      [ 0.289, 350.9 ], [ 0.358, 313.2 ], [ 0.368, 303.6 ], [ 0.387, 312.4 ], [ 0.446, 278.5 ],
      [ 0.502, 260 ], [ 0.634, 223.1 ], [ 0.762, 195.7 ], [ 0.998, 151.4 ], [ 1.5, 68.69 ],
      [ 1.736, 26.59 ], [ 1.845, 9.602 ], [ 1.956, 0 ]
],
    "totImpulseNs": 326,
    "totalWeightG": 385,
    "type": "reload",
    "updatedOn": "2020-11-25"
  },
  {
    "availability": "regular",
    "avgThrustN": 2504,
    "burnTimeS": 1.86,
    "caseInfo": "RMS-98/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2500",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L2500ST",
    "diameter": 98,
    "impulseClass": "L",
    "length": 443,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2830,
    "motorId": "5f4294d20002310000000444",
    "propInfo": "Super Thunder",
    "propWeightG": 2313,
    "samples": [
      [ 0, 0 ], [ 0.016, 24.71 ], [ 0.027, 449.8 ], [ 0.03, 1330 ], [ 0.039, 1784 ], [ 0.043, 2041 ],
      [ 0.08, 2224 ], [ 0.118, 2358 ], [ 0.198, 2476 ], [ 0.399, 2679 ], [ 0.495, 2748 ],[ 0.601, 2788 ],
      [ 0.701, 2822 ], [ 0.799, 2822 ], [ 1.001, 2807 ], [ 1.199, 2758 ], [ 1.399, 2669 ],
      [ 1.501, 2565 ], [ 1.6, 2402 ], [ 1.675, 2234 ], [ 1.752, 2007 ], [ 1.777, 1819 ], [ 1.805, 1334 ],
      [ 1.827, 884.7 ], [ 1.852, 434.9 ], [ 1.87, 242.2 ], [ 1.895, 88.96 ], [ 1.923, 0 ]
],
    "totImpulseNs": 4668,
    "totalWeightG": 4989,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 4533,
    "burnTimeS": 1.6,
    "caseInfo": "RMS-98/7680",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M4500",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M4500ST",
    "diameter": 98,
    "impulseClass": "M",
    "length": 597,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 5549,
    "motorId": "5f4294d20002310000000445",
    "propInfo": "Super Thunder",
    "propWeightG": 3425,
    "samples": [
      [ 0, 0 ], [ 0.014, 2656 ], [ 0.016, 3935 ], [ 0.025, 4162 ], [ 0.032, 4004 ], [ 0.033, 4102 ],
      [ 0.098, 4565 ], [ 0.199, 4840 ], [ 0.4, 5224 ], [ 0.506, 5440 ], [ 0.536, 5421 ], [ 0.597, 5490 ],
      [ 0.798, 5549 ], [ 0.904, 5460 ], [ 1, 5263 ], [ 1.08, 5096 ], [ 1.112, 4968 ], [ 1.201, 4545 ],
      [ 1.341, 3561 ], [ 1.449, 2676 ], [ 1.492, 2302 ], [ 1.52, 1987 ], [ 1.535, 1712 ],[ 1.572, 905.1 ],
      [ 1.593, 511.6 ], [ 1.625, 186.9 ], [ 1.65, 59.03 ], [ 1.675, 19.68 ], [ 1.691, 0 ]
],
    "totImpulseNs": 7301,
    "totalWeightG": 6622,
    "type": "reload",
    "updatedOn": "2020-11-25"
  },
  {
    "availability": "regular",
    "avgThrustN": 627,
    "burnTimeS": 2.42,
    "caseInfo": "38/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K627",
    "dataFiles": 1,
    "delays": "5,7,11,14",
    "designation": "K627LR",
    "diameter": 38,
    "impulseClass": "K",
    "length": 625.5,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 987,
    "motorId": "5f4294d20002310000000446",
    "propInfo": "Loki Red",
    "propWeightG": 688,
    "samples": [
      [ 0, 0 ], [ 0.014, 735 ], [ 0.034, 989.3 ], [ 0.073, 983.1 ], [ 0.166, 905.6 ], [ 0.298, 840.5 ],
      [ 0.501, 806.4 ], [ 0.746, 815.7 ], [ 1.002, 825 ], [ 1.193, 843.6 ], [ 1.258, 849.8 ],
      [ 1.303, 831.2 ], [ 1.348, 784.6 ], [ 1.5, 669.9 ], [ 1.756, 437.3 ], [ 1.951, 276 ],
      [ 2.004, 245 ], [ 2.086, 210.9 ], [ 2.142, 183 ], [ 2.252, 105.4 ], [ 2.322, 74.43 ],
      [ 2.443, 49.62 ], [ 2.499, 27.91 ], [ 2.564, 0 ]
],
    "totImpulseNs": 1519,
    "totalWeightG": 1236,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 124,
    "burnTimeS": 1.94,
    "caseInfo": "38/240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H125",
    "dataFiles": 1,
    "delays": "8,10,12,14,18",
    "designation": "H125-CT",
    "diameter": 38,
    "impulseClass": "H",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20cocktail.pdf",
    "length": 177.8,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 206,
    "motorId": "5f4294d20002310000000447",
    "propInfo": "Cocktail",
    "propWeightG": 122,
    "samples": [
      [ 0, 0 ], [ 0.031, 15.69 ], [ 0.043, 51.39 ], [ 0.058, 133.4 ], [ 0.059, 156.9 ], [ 0.08, 197.3 ],
      [ 0.096, 205.9 ], [ 0.14, 191.8 ], [ 0.325, 190.2 ], [ 0.501, 182.8 ], [ 0.779, 164.7 ],
      [ 0.89, 153.8 ], [ 1.003, 139.6 ], [ 1.21, 113.8 ], [ 1.5, 72.57 ], [ 1.721, 43.93 ],
      [ 1.784, 34.13 ], [ 1.844, 29.81 ], [ 1.876, 25.5 ], [ 1.909, 20 ], [ 2.001, 0 ]
],
    "totImpulseNs": 240,
    "totalWeightG": 342,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 377,
    "burnTimeS": 1.38,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I377",
    "dataFiles": 1,
    "delays": "8,10,12,14,18",
    "designation": "I377-CT",
    "diameter": 38,
    "impulseClass": "I",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20cocktail.pdf",
    "length": 292.1,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 659,
    "motorId": "5f4294d20002310000000448",
    "propInfo": "Cocktail",
    "propWeightG": 250,
    "samples": [
      [ 0, 0 ], [ 0.012, 178.2 ], [ 0.015, 501.6 ], [ 0.023, 609.9 ], [ 0.032, 646.8 ], [ 0.052, 657 ],
      [ 0.068, 625.1 ], [ 0.118, 581.8 ], [ 0.199, 558.9 ], [ 0.399, 502.9 ], [ 0.602, 444.3 ],
      [ 0.691, 411.2 ], [ 0.823, 356.5 ], [ 0.924, 313.2 ], [ 1.021, 264.8 ], [ 1.19, 179.5 ],
      [ 1.258, 138.8 ], [ 1.327, 90.4 ], [ 1.382, 43.29 ], [ 1.43, 0 ]
],
    "totImpulseNs": 522,
    "totalWeightG": 560,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 69,
    "burnTimeS": 1.94,
    "caseInfo": "38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G66",
    "dataFiles": 1,
    "delays": "5,8,11,15",
    "designation": "G66-LR",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20Red.pdf",
    "length": 122.2,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 115,
    "motorId": "5f4294d20002310000000449",
    "propWeightG": 72,
    "samples": [
      [ 0, 0 ], [ 0.01, 4.448 ], [ 0.027, 3.14 ], [ 0.051, 12.3 ], [ 0.07, 22.76 ], [ 0.092, 45.01 ],
      [ 0.104, 66.72 ], [ 0.111, 80.07 ], [ 0.13, 89.49 ], [ 0.145, 97.86 ], [ 0.176, 106.5 ],
      [ 0.21, 112.3 ], [ 0.246, 114.6 ], [ 0.287, 115.7 ], [ 0.359, 113.3 ], [ 0.506, 106.8 ],
      [ 0.598, 102.6 ], [ 0.757, 94.72 ], [ 1.006, 80.85 ], [ 1.196, 66.46 ], [ 1.473, 44.48 ],
      [ 1.51, 41.08 ], [ 1.691, 26.43 ], [ 1.763, 21.46 ], [ 1.823, 16.75 ], [ 1.879, 14.13 ],
      [ 2.016, 7.326 ], [ 2.091, 4.448 ], [ 2.117, 4.71 ], [ 2.129, 0 ]
],
    "totImpulseNs": 134,
    "totalWeightG": 250,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 70,
    "burnTimeS": 1.7,
    "caseInfo": "38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G70",
    "dataFiles": 1,
    "delays": "8,10,12,14,18",
    "designation": "G70-CT",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "https://www.lokiresearch.com/images/Documents/38mm%20cocktail.pdf",
    "length": 122.2,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 101,
    "motorId": "5f4294d2000231000000044a",
    "propInfo": "Cocktail",
    "propWeightG": 64,
    "samples": [
      [ 0, 0 ], [ 0.021, 3.271 ], [ 0.034, 10.2 ], [ 0.057, 14.81 ], [ 0.069, 22.13 ], [ 0.084, 44.25 ],
      [ 0.092, 66.95 ], [ 0.103, 85.42 ], [ 0.111, 91.2 ], [ 0.157, 95.43 ], [ 0.199, 97.93 ],
      [ 0.3, 99.85 ], [ 0.34, 101 ], [ 0.4, 100.4 ], [ 0.495, 99.28 ], [ 0.6, 95.81 ], [ 0.614, 96.2 ],
      [ 0.7, 93.31 ], [ 0.799, 88.12 ], [ 0.904, 82.35 ], [ 1.004, 75.42 ], [ 1.199, 59.64 ],
      [ 1.398, 42.71 ], [ 1.484, 35.79 ], [ 1.545, 30.78 ], [ 1.6, 24.43 ], [ 1.637, 19.82 ],
      [ 1.681, 12.7 ], [ 1.723, 6.926 ], [ 1.799, 0 ]
],
    "totImpulseNs": 119,
    "totalWeightG": 242,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 94,
    "burnTimeS": 1.29,
    "caseInfo": "38/120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G94",
    "dataFiles": 1,
    "delays": "5,8,11,14,18",
    "designation": "HP-G94-IB",
    "diameter": 38,
    "impulseClass": "G",
    "infoUrl": "http://www.lokiresearch.com/images/Documents/38mm%20Ice%20Blue.pdf",
    "length": 122,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 131,
    "motorId": "5f4294d2000231000000044b",
    "propInfo": "Ice Blue",
    "propWeightG": 62,
    "samples": [
      [ 0, 0 ], [ 0.006, 14.03 ], [ 0.03, 67.19 ], [ 0.04, 96.6 ], [ 0.049, 111.2 ], [ 0.058, 118.7 ],
      [ 0.075, 125.7 ], [ 0.141, 124.1 ], [ 0.206, 126.5 ], [ 0.344, 131.7 ], [ 0.37, 129.8 ],
      [ 0.403, 129.5 ], [ 0.458, 127.1 ], [ 0.507, 126 ], [ 0.609, 121.4 ], [ 0.69, 115.5 ],
      [ 0.75, 109 ], [ 0.816, 100.9 ], [ 0.887, 88.77 ], [ 0.992, 67.19 ], [ 1.055, 53.7 ],
      [ 1.111, 42.9 ], [ 1.157, 33.46 ], [ 1.214, 22.66 ], [ 1.257, 14.03 ], [ 1.314, 6.476 ],
      [ 1.345, 4.587 ], [ 1.382, 2.159 ], [ 1.411, 1.619 ], [ 1.424, 0 ]
],
    "totImpulseNs": 121,
    "totalWeightG": 240,
    "type": "reload",
    "updatedOn": "2021-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 1378,
    "burnTimeS": 3.88,
    "caseInfo": "54/4000",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1378",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M1378LR",
    "diameter": 54,
    "impulseClass": "M",
    "length": 1108,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 2270,
    "motorId": "5f4294d2000231000000044c",
    "propInfo": "Loki Red",
    "propWeightG": 2600,
    "samples": [
      [ 0, 0 ], [ 0.033, 880.4 ], [ 0.034, 1332 ], [ 0.035, 1784 ], [ 0.049, 2217 ], [ 0.05, 2319 ],
      [ 0.065, 2240 ], [ 0.175, 2254 ], [ 0.252, 2166 ], [ 0.386, 2097 ], [ 0.512, 2051 ],
      [ 0.776, 2047 ], [ 0.939, 2065 ], [ 0.991, 2070 ], [ 1.503, 2176 ], [ 1.568, 2208 ],
      [ 1.702, 1908 ], [ 1.719, 1867 ], [ 1.788, 1858 ], [ 1.824, 1830 ], [ 1.877, 1742 ],
      [ 1.902, 1747 ], [ 1.946, 1609 ], [ 2.015, 1493 ], [ 2.076, 1447 ], [ 2.137, 1443 ],
      [ 2.194, 1438 ], [ 2.239, 1406 ], [ 2.288, 1337 ], [ 2.393, 1143 ], [ 2.499, 981.8 ],
      [ 2.58, 871.2 ], [ 2.706, 742.1 ], [ 2.775, 719.1 ], [ 2.856, 686.8 ], [ 3.007, 562.4 ],
      [ 3.202, 401 ], [ 3.405, 267.4 ], [ 3.466, 235.1 ], [ 3.527, 230.5 ], [ 3.608, 184.4 ],
      [ 3.681, 175.2 ], [ 3.783, 170.6 ], [ 3.868, 129.1 ], [ 3.953, 96.8 ], [ 4.022, 73.75 ],
      [ 4.079, 0 ]
],
    "totImpulseNs": 5363,
    "totalWeightG": 4331,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 5959,
    "burnTimeS": 1.38,
    "caseInfo": "RMS-98/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M6000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M6000ST",
    "diameter": 98,
    "impulseClass": "M",
    "length": 808,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 6789,
    "motorId": "5f4294d2000231000000044d",
    "propInfo": "Super Thunder",
    "propWeightG": 3868,
    "samples": [
      [ 0, 0 ], [ 0.025, 115.2 ], [ 0.031, 2679 ], [ 0.037, 4349 ], [ 0.039, 4968 ], [ 0.043, 5083 ],
      [ 0.052, 4939 ], [ 0.072, 5011 ], [ 0.116, 5429 ], [ 0.153, 5616 ], [ 0.178, 5775 ],
      [ 0.202, 5818 ], [ 0.399, 6293 ], [ 0.599, 6624 ], [ 0.8, 6956 ], [ 0.928, 7085 ], [ 1.002, 7100 ],
      [ 1.093, 7071 ], [ 1.143, 7013 ], [ 1.198, 6826 ], [ 1.302, 6466 ], [ 1.37, 6120 ],[ 1.405, 5976 ],
      [ 1.438, 5890 ], [ 1.457, 5775 ], [ 1.469, 5443 ], [ 1.492, 4450 ], [ 1.506, 3557 ],
      [ 1.521, 2736 ], [ 1.541, 1757 ], [ 1.556, 1210 ], [ 1.576, 748.8 ], [ 1.595, 446.4 ],
      [ 1.622, 187.2 ], [ 1.651, 72 ], [ 1.736, 0 ]
],
    "totImpulseNs": 8247,
    "totalWeightG": 8030,
    "type": "reload",
    "updatedOn": "2022-05-11"
  },
  {
    "availability": "regular",
    "avgThrustN": 222,
    "burnTimeS": 1.05,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H219",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "H219T",
    "diameter": 38,
    "impulseClass": "H",
    "length": 155,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 279,
    "motorId": "5f4294d2000231000000044e",
    "propInfo": "Blue Thunder",
    "propWeightG": 109,
    "samples": [
      [ 0, 0 ], [ 0.009, 88.8 ], [ 0.012, 133.2 ], [ 0.017, 221.3 ], [ 0.019, 243.5 ], [ 0.04, 257 ],
      [ 0.104, 278.5 ], [ 0.112, 296 ], [ 0.124, 275.8 ], [ 0.203, 276.5 ], [ 0.294, 273.1 ],
      [ 0.4, 264.4 ], [ 0.499, 254.3 ], [ 0.6, 241.5 ], [ 0.65, 234.8 ], [ 0.698, 226 ], [ 0.74, 213.9 ],
      [ 0.8, 191.7 ], [ 0.842, 177.6 ], [ 0.891, 161.4 ], [ 0.937, 150.7 ], [ 0.957, 151.4 ],
      [ 0.971, 133.9 ], [ 0.999, 91.49 ], [ 1.024, 44.4 ], [ 1.04, 21.53 ], [ 1.055, 8.072 ],
      [ 1.081, 0 ]
],
    "totImpulseNs": 233,
    "totalWeightG": 261,
    "type": "SU",
    "updatedOn": "2020-10-16"
  },
  {
    "availability": "regular",
    "avgThrustN": 465,
    "burnTimeS": 2.27,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J450",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J450DM",
    "diameter": 54,
    "impulseClass": "J",
    "length": 359,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 558,
    "motorId": "5f4294d2000231000000044f",
    "propInfo": "Dark Matter",
    "propWeightG": 510,
    "samples": [
      [ 0, 0 ], [ 0.012, 270.7 ], [ 0.018, 356.9 ], [ 0.024, 392.1 ], [ 0.033, 377.5 ], [ 0.062, 371.5 ],
      [ 0.121, 414 ], [ 0.263, 449.2 ], [ 0.453, 467.4 ], [ 0.527, 492.9 ], [ 0.627, 501.4 ],
      [ 0.701, 505 ], [ 0.787, 509.9 ], [ 0.802, 541.4 ], [ 0.834, 526.9 ], [ 0.941, 528.1 ],
      [ 1.047, 541.4 ], [ 1.083, 532.9 ], [ 1.142, 537.8 ], [ 1.293, 537.8 ], [ 1.396, 519.6 ],
      [ 1.494, 520.8 ], [ 1.633, 512.3 ], [ 1.666, 495.3 ], [ 1.725, 491.6 ], [ 1.814, 474.6 ],
      [ 1.899, 455.2 ], [ 1.97, 439.4 ], [ 2.03, 437 ], [ 2.071, 433.4 ], [ 2.089, 415.2 ],
      [ 2.133, 356.9 ], [ 2.169, 267.1 ], [ 2.21, 179.7 ], [ 2.249, 88.62 ], [ 2.266, 41.27 ],
      [ 2.311, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1055,
    "totalWeightG": 1223,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 409,
    "burnTimeS": 3.2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K400",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K400C",
    "diameter": 54,
    "impulseClass": "K",
    "length": 359,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 546,
    "motorId": "5f4294d20002310000000450",
    "propInfo": "Classic",
    "propWeightG": 493,
    "samples": [
      [ 0, 0 ], [ 0.008, 376.4 ], [ 0.025, 526.2 ], [ 0.034, 548.5 ], [ 0.063, 500.2 ], [ 0.178, 527.4 ],
      [ 0.394, 522.5 ], [ 0.69, 502.7 ], [ 1.003, 487.8 ], [ 1.494, 446.9 ], [ 2.002, 402.4 ],
      [ 2.243, 377.6 ], [ 2.501, 364 ], [ 2.658, 354.1 ], [ 2.772, 338 ], [ 2.844, 304.6 ],
      [ 2.882, 268.7 ], [ 2.929, 177 ], [ 2.963, 116.4 ], [ 3.001, 99.05 ], [ 3.064, 94.09 ],
      [ 3.123, 61.9 ], [ 3.208, 18.57 ], [ 3.259, 0 ]
],
    "totImpulseNs": 1304,
    "totalWeightG": 1194,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 922,
    "burnTimeS": 2,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K850",
    "dataFiles": 2,
    "delays": "8,10,12,14,16",
    "designation": "K850DM",
    "diameter": 54,
    "impulseClass": "K",
    "length": 635,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1094,
    "motorId": "5f4294d20002310000000451",
    "propInfo": "Dark Matter",
    "propWeightG": 1224,
    "samples": [
      [ 0, 0 ], [ 0.02, 50.82 ], [ 0.032, 236.4 ], [ 0.034, 450.7 ], [ 0.04, 1049 ], [ 0.044, 810.8 ],
      [ 0.048, 901.4 ], [ 0.05, 859.4 ], [ 0.066, 848.4 ], [ 0.158, 914.7 ], [ 0.326, 961.1 ],
      [ 0.615, 1030 ], [ 0.923, 1065 ], [ 1.075, 1061 ], [ 1.233, 1041 ], [ 1.369, 994.2 ],
      [ 1.407, 1019 ], [ 1.483, 978.8 ], [ 1.545, 974.3 ], [ 1.567, 985.4 ], [ 1.591, 963.3 ],
      [ 1.673, 947.8 ], [ 1.712, 952.2 ], [ 1.76, 910.3 ], [ 1.854, 702.6 ], [ 1.926, 444.1 ],
      [ 1.978, 225.4 ], [ 2.028, 79.54 ], [ 2.07, 24.3 ], [ 2.118, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1869,
    "totalWeightG": 2041,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 931,
    "burnTimeS": 2.04,
    "caseInfo": "RMS-54/2800",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K990",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "K990DM",
    "diameter": 54,
    "impulseClass": "K",
    "length": 598,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1075,
    "motorId": "5f4294d20002310000000452",
    "propInfo": "Dark Matter",
    "propWeightG": 1224,
    "samples": [
      [ 0, 0 ], [ 0.016, 35.56 ], [ 0.024, 223.8 ], [ 0.028, 868 ], [ 0.036, 1069 ], [ 0.044, 895.2 ],
      [ 0.048, 863.8 ], [ 0.06, 840.8 ], [ 0.228, 966.3 ], [ 0.286, 953.8 ], [ 0.369, 964.2 ],
      [ 0.407, 993.5 ], [ 0.423, 976.8 ], [ 0.498, 989.3 ], [ 0.694, 1025 ], [ 0.865, 1044 ],
      [ 0.962, 1054 ], [ 1.117, 1054 ], [ 1.282, 1029 ], [ 1.51, 983.1 ], [ 1.589, 960.1 ],
      [ 1.688, 953.8 ], [ 1.732, 968.4 ], [ 1.748, 1002 ], [ 1.778, 958 ], [ 1.802, 891 ],
      [ 1.857, 671.4 ], [ 1.913, 447.6 ], [ 1.97, 223.8 ], [ 2.006, 129.7 ], [ 2.063, 46.02 ],
      [ 2.097, 14.64 ], [ 2.137, 0 ]
],
    "sparky": true,
    "totImpulseNs": 1867,
    "totalWeightG": 2109,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1874,
    "burnTimeS": 0.73,
    "caseInfo": "RMS-54/1706",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K2050",
    "dataFiles": 3,
    "delays": "P",
    "designation": "K2050ST",
    "diameter": 54,
    "impulseClass": "K",
    "length": 383,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2086,
    "motorId": "5f4294d20002310000000453",
    "propInfo": "Super Thunder",
    "propWeightG": 674,
    "samples": [
      [ 0, 0 ], [ 0.006, 31.77 ], [ 0.011, 388.3 ], [ 0.012, 886.1 ], [ 0.015, 1783 ], [ 0.02, 2005 ],
      [ 0.023, 2048 ], [ 0.036, 1988 ], [ 0.056, 1981 ], [ 0.095, 2044 ], [ 0.108, 2030 ],
      [ 0.184, 2065 ], [ 0.198, 2048 ], [ 0.3, 2058 ], [ 0.345, 2062 ], [ 0.364, 2037 ], [ 0.402, 2026 ],
      [ 0.499, 1988 ], [ 0.543, 1956 ], [ 0.575, 1913 ], [ 0.602, 1857 ], [ 0.644, 1822 ],
      [ 0.668, 1790 ], [ 0.686, 1680 ], [ 0.693, 1560 ], [ 0.701, 1334 ], [ 0.727, 448.4 ],
      [ 0.739, 162.4 ], [ 0.744, 257.7 ], [ 0.749, 141.2 ], [ 0.755, 42.36 ], [ 0.767, 0 ]
],
    "totImpulseNs": 1384,
    "totalWeightG": 1292,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 1340,
    "burnTimeS": 5.5,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M1340",
    "dataFiles": 2,
    "delays": "P",
    "designation": "M1340W",
    "diameter": 98,
    "impulseClass": "M",
    "length": 557,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1865,
    "motorId": "5f4294d20002310000000454",
    "propInfo": "White Lightning",
    "propWeightG": 4036,
    "samples": [
      [ 0, 0 ], [ 0.016, 42.43 ], [ 0.027, 1054 ], [ 0.043, 862.8 ], [ 0.076, 1330 ], [ 0.098, 1404 ],
      [ 0.152, 1425 ], [ 0.986, 1729 ], [ 1.154, 1810 ], [ 1.518, 1874 ], [ 1.642, 1842 ],
      [ 2, 1888 ], [ 2.558, 1892 ], [ 2.775, 1782 ], [ 2.818, 1708 ], [ 2.894, 1616 ], [ 2.997, 1520 ],
      [ 4, 1011 ], [ 4.509, 763.8 ], [ 4.862, 608.2 ], [ 4.981, 583.4 ], [ 5.062, 526.9 ],
      [ 5.149, 438.5 ], [ 5.322, 226.3 ], [ 5.458, 99.01 ], [ 5.577, 28.29 ], [ 5.642, 0 ]
],
    "totImpulseNs": 7369,
    "totalWeightG": 6998,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 283,
    "burnTimeS": 0.7,
    "caseInfo": "DMS",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H283",
    "dataFiles": 2,
    "delays": "7,9,11,13,15",
    "designation": "H283ST",
    "diameter": 38,
    "impulseClass": "H",
    "length": 155,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 327,
    "motorId": "5f4294d20002310000000455",
    "propInfo": "Super Thunder",
    "samples": [
      [ 0, 0 ], [ 0.003, 133 ], [ 0.005, 223.2 ], [ 0.007, 267.3 ], [ 0.009, 285.7 ], [ 0.017, 296.7 ],
      [ 0.045, 300.4 ], [ 0.049, 310.8 ], [ 0.052, 301.6 ], [ 0.066, 304.7 ], [ 0.081, 312.7 ],
      [ 0.094, 308.4 ], [ 0.108, 315.1 ], [ 0.222, 331.1 ], [ 0.225, 337.2 ], [ 0.229, 332.9 ],
      [ 0.336, 316.3 ], [ 0.371, 312.7 ], [ 0.404, 300.4 ], [ 0.435, 290 ], [ 0.449, 290.6 ],
      [ 0.47, 278.3 ], [ 0.499, 274 ], [ 0.538, 257.5 ], [ 0.572, 239.1 ], [ 0.593, 239.7 ],
      [ 0.606, 226.8 ], [ 0.636, 225.6 ], [ 0.644, 221.9 ], [ 0.658, 178.4 ], [ 0.67, 130 ],
      [ 0.677, 121.4 ], [ 0.688, 91.96 ], [ 0.691, 98.09 ], [ 0.722, 41.69 ], [ 0.738, 23.3 ],
      [ 0.74, 0 ]
],
    "totImpulseNs": 201,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 550,
    "burnTimeS": 0.57,
    "caseInfo": "RMS-38/360",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H550",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "H550ST",
    "diameter": 38,
    "impulseClass": "H",
    "length": 213,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 667,
    "motorId": "5f4294d20002310000000456",
    "propInfo": "Super Thunder",
    "propWeightG": 145,
    "samples": [
      [ 0, 0 ], [ 0.008, 445.4 ], [ 0.009, 533.8 ], [ 0.01, 565.6 ], [ 0.013, 586.8 ], [ 0.015, 623.3 ],
      [ 0.021, 639.8 ], [ 0.036, 606.8 ], [ 0.045, 629.2 ], [ 0.057, 615.1 ], [ 0.061, 635.1 ],
      [ 0.067, 619.8 ], [ 0.101, 637.5 ], [ 0.116, 622.2 ], [ 0.157, 643.4 ], [ 0.174, 623.3 ],
      [ 0.196, 623.3 ], [ 0.205, 639.8 ], [ 0.231, 631.6 ], [ 0.234, 618.6 ], [ 0.255, 611.6 ],
      [ 0.268, 628.1 ], [ 0.314, 595.1 ], [ 0.334, 601 ], [ 0.362, 571.5 ], [ 0.386, 565.6 ],
      [ 0.401, 556.2 ], [ 0.426, 518.5 ], [ 0.445, 503.1 ], [ 0.493, 447.8 ], [ 0.505, 447.8 ],
      [ 0.511, 456 ], [ 0.519, 451.3 ], [ 0.535, 357 ], [ 0.545, 266.3 ], [ 0.551, 179.1 ],
      [ 0.559, 91.91 ], [ 0.565, 42.42 ], [ 0.572, 12.96 ], [ 0.579, 0 ]
],
    "totImpulseNs": 320,
    "totalWeightG": 323,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 875,
    "burnTimeS": 4.44,
    "caseInfo": "DMS",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L875",
    "dataFiles": 2,
    "delays": "P",
    "designation": "L875DM",
    "diameter": 75,
    "impulseClass": "L",
    "length": 638,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1355,
    "motorId": "5f4294d20002310000000457",
    "propInfo": "Dark Matter",
    "propWeightG": 2472,
    "samples": [
      [ 0, 0 ], [ 0.023, 444.1 ], [ 0.028, 853.3 ], [ 0.074, 814.8 ], [ 0.148, 793.8 ], [ 0.158, 874.3 ],
      [ 0.241, 902.2 ], [ 0.287, 928.5 ], [ 0.422, 911 ], [ 0.528, 904 ], [ 0.992, 918 ],[ 1.497, 956.4 ],
      [ 2.007, 1000 ], [ 2.489, 1033 ], [ 2.619, 1039 ], [ 2.827, 1032 ], [ 3.115, 989.7 ],
      [ 3.495, 912.7 ], [ 3.606, 907.5 ], [ 3.843, 671.4 ], [ 3.935, 606.7 ], [ 4, 442.4 ],
      [ 4.097, 220.3 ], [ 4.195, 103.2 ], [ 4.315, 31.47 ], [ 4.478, 10.49 ], [ 4.64, 0 ]
],
    "sparky": true,
    "totImpulseNs": 3887,
    "totalWeightG": 4577,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 441.6,
    "burnTimeS": 1.64,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J435",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "J435WS",
    "diameter": 38,
    "impulseClass": "J",
    "length": 365.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 712.9,
    "motorId": "5f4294d20002310000000458",
    "propInfo": "Super White Lightning",
    "propWeightG": 352,
    "samples": [
      [ 0, 0 ], [ 0.029, 11.08 ], [ 0.03, 41.14 ], [ 0.031, 212 ], [ 0.036, 528.5 ], [ 0.038, 378.2 ],
      [ 0.048, 694.7 ], [ 0.067, 625 ], [ 0.071, 688.3 ], [ 0.083, 631.4 ], [ 0.095, 651.9 ],
      [ 0.105, 610.8 ], [ 0.116, 629.8 ], [ 0.135, 610.8 ], [ 0.145, 621.9 ], [ 0.166, 655.1 ],
      [ 0.171, 625 ], [ 0.304, 601.3 ], [ 0.399, 564.9 ], [ 0.456, 558.6 ], [ 0.504, 547.5 ],
      [ 0.549, 549.1 ], [ 0.613, 538 ], [ 0.672, 531.7 ], [ 0.701, 525.3 ], [ 0.762, 525.3 ],
      [ 0.803, 515.9 ], [ 0.848, 506.4 ], [ 0.903, 501.6 ], [ 0.983, 492.1 ], [ 1.017, 485.8 ],
      [ 1.059, 479.5 ], [ 1.152, 470 ], [ 1.261, 449.4 ], [ 1.276, 454.1 ], [ 1.316, 441.5 ],
      [ 1.366, 425.7 ], [ 1.387, 401.9 ], [ 1.409, 343.4 ], [ 1.42, 375 ], [ 1.428, 340.2 ],
      [ 1.435, 229.4 ], [ 1.451, 242.1 ], [ 1.499, 212 ], [ 1.508, 188.3 ], [ 1.527, 215.2 ],
      [ 1.532, 118.7 ], [ 1.544, 63.3 ], [ 1.558, 132.9 ], [ 1.575, 87.03 ], [ 1.587, 125 ],
      [ 1.601, 83.87 ], [ 1.618, 115.5 ], [ 1.632, 72.79 ], [ 1.648, 87.03 ], [ 1.667, 47.47 ],
      [ 1.686, 45.89 ], [ 1.717, 18.99 ], [ 1.781, 0 ]
],
    "totImpulseNs": 742.8,
    "totalWeightG": 616.4,
    "type": "SU",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 487.4,
    "burnTimeS": 1.38,
    "caseInfo": "RMS-38/1080",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J520",
    "dataFiles": 2,
    "delays": "5,7,9,11,13",
    "designation": "J520W",
    "diameter": 38,
    "impulseClass": "J",
    "length": 502,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 902.7,
    "motorId": "5f4294d20002310000000459",
    "propInfo": "White Lightning",
    "propWeightG": 381,
    "samples": [
      [ 0, 0 ], [ 0.024, 15.52 ], [ 0.034, 131.9 ], [ 0.039, 318.1 ], [ 0.046, 659.5 ], [ 0.049, 752.6 ],
      [ 0.051, 799.1 ], [ 0.066, 756.5 ], [ 0.071, 791.4 ], [ 0.098, 892.2 ], [ 0.137, 861.2 ],
      [ 0.168, 837.9 ], [ 0.195, 810.8 ], [ 0.234, 783.6 ], [ 0.4, 706 ], [ 0.598, 593.5 ],
      [ 0.798, 477.1 ], [ 0.998, 376.3 ], [ 1.056, 333.6 ], [ 1.102, 298.7 ], [ 1.198, 170.7 ],
      [ 1.302, 100.9 ], [ 1.4, 46.55 ], [ 1.478, 0 ]
],
    "totImpulseNs": 671.3,
    "totalWeightG": 757.4,
    "type": "reload",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 69,
    "burnTimeS": 0.88,
    "certOrg": "National Association of Rocketry",
    "commonName": "F67",
    "dataFiles": 2,
    "delays": "4,6,7,9",
    "designation": "F67W",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://www.nar.org/SandT/pdf/Aerotech/AT_F67W_2017.pdf",
    "length": 83,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 86,
    "motorId": "5f4294d2000231000000045a",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.002, 66.66 ], [ 0.008, 71.44 ], [ 0.016, 74.5 ], [ 0.039, 77.94 ], [ 0.068, 80.63 ],
      [ 0.099, 82.59 ], [ 0.148, 84.43 ], [ 0.201, 85.53 ], [ 0.257, 86.15 ], [ 0.298, 86.02 ],
      [ 0.399, 84.19 ], [ 0.483, 82.96 ], [ 0.499, 82.35 ], [ 0.568, 80.02 ], [ 0.601, 78.79 ],
      [ 0.625, 77.94 ], [ 0.654, 78.06 ], [ 0.672, 75.85 ], [ 0.687, 71.07 ], [ 0.7, 67.52 ],
      [ 0.714, 62.01 ], [ 0.731, 53.06 ], [ 0.748, 44.48 ], [ 0.764, 35.66 ], [ 0.778, 26.84 ],
      [ 0.789, 20.95 ], [ 0.797, 18.01 ], [ 0.812, 12.5 ], [ 0.832, 7.107 ], [ 0.849, 3.676 ],
      [ 0.87, 1.225 ], [ 0.898, 0.368 ], [ 0.92, 0 ]
],
    "totImpulseNs": 61.1,
    "totalWeightG": 80.8,
    "type": "SU",
    "updatedOn": "2020-09-01"
  },
  {
    "availability": "regular",
    "avgThrustN": 3.24,
    "burnTimeS": 0.77,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "A3",
    "dataFiles": 3,
    "delays": "4,6",
    "designation": "A3",
    "diameter": 18,
    "impulseClass": "A",
    "length": 81,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 8.4,
    "motorId": "5f4294d2000231000000045b",
    "propInfo": "Black Max",
    "propWeightG": 2.1,
    "samples": [
      [ 0, 0 ], [ 0.002, 0.104 ], [ 0.011, 0.156 ], [ 0.026, 0.563 ], [ 0.037, 2.076 ], [ 0.051, 7.303 ],
      [ 0.061, 6.643 ], [ 0.069, 6.606 ], [ 0.074, 6.406 ], [ 0.085, 6.302 ], [ 0.095, 6.354 ],
      [ 0.109, 6.287 ], [ 0.12, 6.272 ], [ 0.129, 6.317 ], [ 0.148, 5.88 ], [ 0.158, 5.457 ],
      [ 0.174, 5.197 ], [ 0.182, 5.197 ], [ 0.197, 4.997 ], [ 0.206, 5.064 ], [ 0.238, 4.567 ],
      [ 0.248, 4.619 ], [ 0.262, 4.552 ], [ 0.288, 4.285 ], [ 0.296, 4.315 ], [ 0.309, 4.011 ],
      [ 0.34, 3.618 ], [ 0.354, 3.655 ], [ 0.408, 3.144 ], [ 0.431, 2.958 ], [ 0.456, 2.684 ],
      [ 0.466, 2.721 ], [ 0.485, 2.469 ], [ 0.494, 2.432 ], [ 0.525, 2.076 ], [ 0.544, 1.957 ],
      [ 0.552, 1.854 ], [ 0.581, 1.735 ], [ 0.623, 1.379 ], [ 0.67, 1.075 ], [ 0.686, 1.038 ],
      [ 0.738, 0.719 ], [ 0.783, 0.615 ], [ 0.802, 0.512 ], [ 0.861, 0.378 ], [ 0.887, 0.208 ],
      [ 0.9, 0.245 ], [ 0.918, 0.156 ], [ 0.934, 0 ]
],
    "totImpulseNs": 2.5,
    "totalWeightG": 18.2,
    "type": "SU",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.4,
    "burnTimeS": 1.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "B4",
    "dataFiles": 2,
    "delays": "4,6",
    "designation": "B4",
    "diameter": 18,
    "impulseClass": "B",
    "length": 81,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 7.5,
    "motorId": "5f4294d2000231000000045c",
    "propInfo": "Black Max",
    "propWeightG": 3.6,
    "samples": [
      [ 0, 0 ], [ 0.011, 0.102 ], [ 0.024, 0.622 ], [ 0.036, 3.81 ], [ 0.043, 3.886 ], [ 0.074, 5.93 ],
      [ 0.099, 7.061 ], [ 0.138, 6.819 ], [ 0.204, 6.883 ], [ 0.236, 7.099 ], [ 0.263, 7.035 ],
      [ 0.273, 6.883 ], [ 0.298, 7.2 ], [ 0.348, 6.667 ], [ 0.404, 5.994 ], [ 0.452, 5.867 ],
      [ 0.472, 5.651 ], [ 0.493, 5.321 ], [ 0.533, 5.168 ], [ 0.579, 4.762 ], [ 0.606, 4.686 ],
      [ 0.651, 4.381 ], [ 0.771, 3.251 ], [ 0.79, 3.289 ], [ 0.822, 2.921 ], [ 0.852, 2.857 ],
      [ 0.894, 2.451 ], [ 0.952, 2.095 ], [ 0.981, 1.676 ], [ 1.041, 0.965 ], [ 1.089, 0.559 ],
      [ 1.177, 0.114 ], [ 1.184, 0 ]
],
    "totImpulseNs": 4.86,
    "totalWeightG": 19.8,
    "type": "SU",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 55.89,
    "burnTimeS": 0.99,
    "caseInfo": "RMS-24/60",
    "certOrg": "National Association of Rocketry",
    "commonName": "F51",
    "dataFiles": 2,
    "delays": "5,7,9",
    "designation": "F51NT",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/AT_F51NT_2017.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 76.53,
    "motorId": "5f4294d2000231000000045d",
    "propInfo": "New Blue Thunder",
    "propWeightG": 26.5,
    "samples": [
      [ 0, 0 ], [ 0.004, 68.78 ], [ 0.013, 69.03 ], [ 0.018, 70.32 ], [ 0.027, 71.34 ], [ 0.06, 71.34 ],
      [ 0.1, 70.83 ], [ 0.2, 68.52 ], [ 0.3, 66.72 ], [ 0.4, 64.41 ], [ 0.5, 62.36 ], [ 0.6, 59.28 ],
      [ 0.7, 54.92 ], [ 0.722, 53.63 ], [ 0.738, 52.35 ], [ 0.757, 48.76 ], [ 0.775, 44.4 ],
      [ 0.8, 36.44 ], [ 0.85, 18.48 ], [ 0.875, 12.32 ], [ 0.9, 8.982 ], [ 0.95, 4.876 ],[ 1, 3.08 ],
      [ 1.05, 0 ]
],
    "totImpulseNs": 55.11,
    "totalWeightG": 81,
    "type": "reload",
    "updatedOn": "2022-07-16"
  },
  {
    "availability": "regular",
    "avgThrustN": 11.7,
    "burnTimeS": 0.84,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "C12",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "C12",
    "diameter": 18,
    "impulseClass": "C",
    "length": 78.7,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 17.5,
    "motorId": "5f4294d2000231000000045e",
    "propInfo": "Black Max",
    "propWeightG": 10.4,
    "samples": [
      [ 0, 0 ], [ 0.012, 0.342 ], [ 0.023, 1.796 ], [ 0.036, 5.218 ], [ 0.05, 8.127 ], [ 0.051, 9.41 ],
      [ 0.061, 10.35 ], [ 0.088, 9.752 ], [ 0.099, 9.752 ], [ 0.144, 10.27 ], [ 0.165, 10.86 ],
      [ 0.183, 11.63 ], [ 0.334, 13.69 ], [ 0.501, 14.63 ], [ 0.552, 15.06 ], [ 0.582, 14.63 ],
      [ 0.593, 15.23 ], [ 0.618, 15.06 ], [ 0.664, 15.74 ], [ 0.702, 17.54 ], [ 0.711, 17.71 ],
      [ 0.721, 16.85 ], [ 0.727, 14.8 ], [ 0.739, 11.04 ], [ 0.754, 7.271 ], [ 0.765, 5.304 ],
      [ 0.782, 3.336 ], [ 0.8, 2.053 ], [ 0.823, 1.283 ], [ 0.848, 0.77 ], [ 0.889, 0 ]
],
    "totImpulseNs": 9.8,
    "totalWeightG": 23.7,
    "type": "SU",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 15.6,
    "burnTimeS": 0.79,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "D16",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "D16",
    "diameter": 18,
    "impulseClass": "D",
    "length": 79.7,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 22.3,
    "motorId": "5f4294d2000231000000045f",
    "propInfo": "Black Max",
    "propWeightG": 12.5,
    "samples": [
      [ 0, 0 ], [ 0.007, 0.411 ], [ 0.021, 1.745 ], [ 0.028, 8.828 ], [ 0.031, 11.5 ], [ 0.04, 12.11 ],
      [ 0.051, 13.76 ], [ 0.079, 13.04 ], [ 0.167, 14.78 ], [ 0.203, 16.12 ], [ 0.221, 16.01 ],
      [ 0.271, 17.55 ], [ 0.284, 18.17 ], [ 0.296, 17.55 ], [ 0.334, 18.48 ], [ 0.397, 19.5 ],
      [ 0.501, 20.74 ], [ 0.618, 22.27 ], [ 0.636, 22.07 ], [ 0.656, 20.94 ], [ 0.673, 17.76 ],
      [ 0.689, 13.35 ], [ 0.707, 9.033 ], [ 0.724, 6.159 ], [ 0.742, 3.798 ], [ 0.77, 1.95 ],
      [ 0.803, 0.821 ], [ 0.838, 0 ]
],
    "totImpulseNs": 12.4,
    "totalWeightG": 25.6,
    "type": "SU",
    "updatedOn": "2024-06-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 56.91,
    "burnTimeS": 0.84,
    "caseInfo": "RMS-24/60",
    "certOrg": "National Association of Rocketry",
    "commonName": "F62",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "F62FJ",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/AT_F62FJ_2017.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 73.1,
    "motorId": "5f4294d20002310000000460",
    "propInfo": "Black Max",
    "propWeightG": 32.2,
    "samples": [
      [ 0, 0 ], [ 0.002, 52.85 ], [ 0.018, 54.43 ], [ 0.035, 55.55 ], [ 0.059, 56.9 ], [ 0.1, 59.15 ],
      [ 0.2, 63.65 ], [ 0.3, 68.15 ], [ 0.4, 71.52 ], [ 0.459, 72.2 ], [ 0.5, 72.2 ], [ 0.541, 70.62 ],
      [ 0.584, 68.15 ], [ 0.6, 67.92 ], [ 0.618, 67.25 ], [ 0.634, 65.67 ], [ 0.654, 60.73 ],
      [ 0.7, 40.03 ], [ 0.78, 0 ]
],
    "totImpulseNs": 47.57,
    "totalWeightG": 88.1,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 58.06,
    "burnTimeS": 0.85,
    "caseInfo": "RMS-24/60",
    "certOrg": "National Association of Rocketry",
    "commonName": "F63",
    "dataFiles": 2,
    "delays": "4,6,8,10",
    "designation": "F63R",
    "diameter": 24,
    "impulseClass": "F",
    "infoUrl": "http://www.nar.org/SandT/pdf/Aerotech/AT_F63R_2017.pdf",
    "length": 95,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 69.94,
    "motorId": "5f4294d20002310000000461",
    "propInfo": "Redline",
    "propWeightG": 27.6,
    "samples": [
      [ 0, 0 ], [ 0.002, 52.19 ], [ 0.018, 59.1 ], [ 0.035, 63.34 ], [ 0.063, 66.69 ], [ 0.1, 68.92 ],
      [ 0.2, 72.48 ], [ 0.3, 72.93 ], [ 0.35, 71.82 ], [ 0.4, 70.48 ], [ 0.5, 67.58 ], [ 0.6, 63.12 ],
      [ 0.65, 60.66 ], [ 0.665, 58.66 ], [ 0.685, 53.08 ], [ 0.7, 48.17 ], [ 0.8, 0 ]
],
    "totImpulseNs": 49.5,
    "totalWeightG": 82.2,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 6.56,
    "burnTimeS": 19.81,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G8",
    "dataFiles": 3,
    "delays": "P",
    "designation": "G8ST",
    "diameter": 29,
    "impulseClass": "G",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 7.85,
    "motorId": "5f4294d20002310000000462",
    "propInfo": "Super Thunder",
    "propWeightG": 83.4,
    "samples": [
      [ 0, 0 ], [ 0.024, 0.3704 ], [ 0.066, 0.8746 ], [ 0.138, 4.504 ], [ 0.246, 6.621 ],[ 0.426, 6.735 ],
      [ 1.218, 7.323 ], [ 2.082, 7.423 ], [ 3.306, 7.183 ], [ 5.322, 6.838 ], [ 6.978, 6.719 ],
      [ 8.632, 6.551 ], [ 10.14, 6.479 ], [ 12.09, 6.425 ], [ 15.47, 6.333 ], [ 17.63, 6.13 ],
      [ 19.11, 6.038 ], [ 19.51, 5.961 ], [ 19.8, 4.429 ], [ 19.87, 1.669 ], [ 19.94, 0.3341 ],
      [ 19.96, 0 ]
],
    "totImpulseNs": 130,
    "totalWeightG": 157.1,
    "type": "SU",
    "updatedOn": "2021-01-30"
  },
  {
    "availability": "regular",
    "avgThrustN": 10.2,
    "burnTimeS": 13.6,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G11",
    "dataFiles": 2,
    "delays": "P",
    "designation": "G11",
    "diameter": 29,
    "impulseClass": "G",
    "length": 152.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 28.9,
    "motorId": "5f4294d20002310000000463",
    "propInfo": "Super Thunder",
    "propWeightG": 82,
    "samples": [
      [ 0, 0 ], [ 0.084, 30.44 ], [ 0.105, 28.41 ], [ 0.209, 27.74 ], [ 0.419, 24.09 ], [ 0.753, 18.4 ],
      [ 0.9, 14.75 ], [ 1.046, 11.91 ], [ 1.444, 10.01 ], [ 2.051, 9.742 ], [ 3.034, 9.201 ],
      [ 4.018, 9.471 ], [ 5.483, 9.201 ], [ 5.713, 8.93 ], [ 9.375, 9.742 ], [ 9.501, 8.93 ],
      [ 11.05, 9.742 ], [ 12.26, 9.336 ], [ 13.29, 8.66 ], [ 13.46, 7.307 ], [ 13.6, 4.6 ],
      [ 13.77, 1.624 ], [ 13.94, 0 ]
],
    "totImpulseNs": 139,
    "totalWeightG": 153.7,
    "type": "SU",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 11.31,
    "burnTimeS": 12.7,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G12",
    "dataFiles": 4,
    "delays": "P",
    "designation": "G12ST",
    "diameter": 29,
    "impulseClass": "G",
    "length": 156,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 30.88,
    "motorId": "5f4294d20002310000000464",
    "propInfo": "Super Thunder",
    "propWeightG": 82,
    "samples": [
      [ 0, 0 ], [ 0.005, 0.1561 ], [ 0.032, 1.672 ], [ 0.043, 5.258 ], [ 0.078, 21.94 ], [ 0.15, 24 ],
      [ 0.33, 28.21 ], [ 0.478, 31 ], [ 0.502, 31.26 ], [ 0.644, 15.24 ], [ 0.876, 12.27 ],
      [ 1.164, 11.45 ], [ 1.755, 10.68 ], [ 3.003, 10.16 ], [ 4.025, 9.87 ], [ 5.141, 9.955 ],
      [ 6.725, 10.91 ], [ 8.856, 12.02 ], [ 11.23, 12.71 ], [ 12.01, 11.34 ], [ 12.19, 9.21 ],
      [ 12.26, 6.912 ], [ 12.34, 4.543 ], [ 12.47, 1.552 ], [ 12.57, 0.2906 ], [ 12.6, 0 ]
],
    "totImpulseNs": 144,
    "totalWeightG": 150.3,
    "type": "SU",
    "updatedOn": "2021-01-30"
  },
  {
    "availability": "regular",
    "avgThrustN": 326.2,
    "burnTimeS": 2.25,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J326",
    "dataFiles": 1,
    "delays": "8,11,15",
    "designation": "J-326-LR",
    "diameter": 38,
    "impulseClass": "J",
    "length": 417,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 925.8,
    "motorId": "5f4294d20002310000000465",
    "propInfo": "Loki Red",
    "propWeightG": 360,
    "samples": [
      [ 0, 0 ], [ 0.012, 2.182 ], [ 0.024, 61.58 ], [ 0.052, 936.9 ], [ 0.061, 975.8 ], [ 0.1, 615.7 ],
      [ 0.287, 498.2 ], [ 0.467, 455.7 ], [ 0.683, 416.3 ], [ 0.971, 362 ], [ 1.259, 308.9 ],
      [ 1.547, 256.5 ], [ 1.836, 191.4 ], [ 2.123, 74.02 ], [ 2.279, 37.62 ], [ 2.371, 24.45 ],
      [ 2.5, 0 ]
],
    "totImpulseNs": 732.9,
    "totalWeightG": 766,
    "type": "reload",
    "updatedOn": "2019-04-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 474,
    "burnTimeS": 1.49,
    "caseInfo": "38/740",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J474",
    "dataFiles": 1,
    "delays": "18",
    "designation": "J474-CT",
    "diameter": 38,
    "impulseClass": "J",
    "length": 416.9,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 1243,
    "motorId": "5f4294d20002310000000466",
    "propInfo": "Cocktail",
    "propWeightG": 360,
    "samples": [
      [ 0, 0 ], [ 0.018, 21.9 ], [ 0.04, 716.6 ], [ 0.054, 1223 ], [ 0.057, 1221 ], [ 0.085, 936.5 ],
      [ 0.133, 808.5 ], [ 0.247, 732.8 ], [ 0.39, 685.4 ], [ 0.502, 646.9 ], [ 0.647, 568.7 ],
      [ 0.826, 442.7 ], [ 1.006, 327.4 ], [ 1.15, 241.9 ], [ 1.296, 179.4 ], [ 1.474, 85.33 ],
      [ 1.611, 21.27 ], [ 1.65, 0 ]
],
    "totImpulseNs": 709.1,
    "totalWeightG": 755.6,
    "type": "reload",
    "updatedOn": "2019-04-28"
  },
  {
    "availability": "regular",
    "avgThrustN": 80.78,
    "burnTimeS": 1.3,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G72",
    "dataFiles": 2,
    "delays": "6,8,10,12,14",
    "designation": "G72DM-14A",
    "diameter": 29,
    "impulseClass": "G",
    "length": 156.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 105.6,
    "motorId": "5f4294d20002310000000467",
    "propInfo": "Dark Matter",
    "propWeightG": 62.5,
    "samples": [
      [ 0, 0 ], [ 0.001, 0.1874 ], [ 0.025, 5.288 ], [ 0.038, 13.59 ], [ 0.055, 38.13 ], [ 0.097, 59.74 ],
      [ 0.207, 73.12 ], [ 0.319, 83.64 ], [ 0.427, 93.75 ], [ 0.64, 97.64 ], [ 0.75, 103.7 ],
      [ 0.968, 93.15 ], [ 1.137, 92.35 ], [ 1.245, 31.16 ], [ 1.344, 4.883 ], [ 1.4, 0.4773 ],
      [ 1.41, 0 ]
],
    "sparky": true,
    "totImpulseNs": 105,
    "totalWeightG": 131.6,
    "type": "SU",
    "updatedOn": "2019-05-21"
  },
  {
    "availability": "regular",
    "avgThrustN": 168.8,
    "burnTimeS": 1.38,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H169",
    "dataFiles": 2,
    "delays": "5,7,9,11,13",
    "designation": "H169WS-13",
    "diameter": 29,
    "impulseClass": "H",
    "length": 220.7,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 231.4,
    "motorId": "5f4294d20002310000000468",
    "propInfo": "Super White Lightning",
    "propWeightG": 112,
    "samples": [
      [ 0, 0 ], [ 0.021, 3.917 ], [ 0.034, 138.5 ], [ 0.053, 216.8 ], [ 0.08, 231.3 ], [ 0.22, 233.5 ],
      [ 0.299, 228.9 ], [ 0.489, 221.6 ], [ 0.653, 214 ], [ 0.819, 178.6 ], [ 0.945, 148 ],
      [ 1.132, 115.3 ], [ 1.286, 55.19 ], [ 1.35, 30.5 ], [ 1.366, 12.36 ], [ 1.444, 4.642 ],
      [ 1.53, 0 ]
],
    "totImpulseNs": 232.9,
    "totalWeightG": 202.2,
    "type": "SU",
    "updatedOn": "2019-12-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 455.4,
    "burnTimeS": 5.09,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K455",
    "dataFiles": 2,
    "delays": "7,9,11,13,15,17",
    "designation": "K455NW-17",
    "diameter": 54,
    "impulseClass": "K",
    "length": 674.9,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1308,
    "motorId": "5f4294d20002310000000469",
    "propInfo": "White Lightning/Warp 9",
    "propWeightG": 1456,
    "samples": [
      [ 0, 0 ], [ 0.014, 12.18 ], [ 0.034, 263.2 ], [ 0.062, 1219 ], [ 0.111, 1229 ], [ 0.2, 1288 ],
      [ 0.409, 1300 ], [ 0.505, 1234 ], [ 0.548, 1083 ], [ 0.587, 395.8 ], [ 0.62, 445 ],[ 0.85, 476.4 ],
      [ 1.23, 447.1 ], [ 1.725, 437.5 ], [ 2.22, 428.6 ], [ 2.714, 414.9 ], [ 3.208, 401.8 ],
      [ 3.664, 395.3 ], [ 3.941, 399.6 ], [ 4.12, 294.9 ], [ 4.5, 164.8 ], [ 4.881, 101.9 ],
      [ 5.147, 62.23 ], [ 5.329, 28.83 ], [ 5.4, 0 ]
],
    "totImpulseNs": 2328,
    "totalWeightG": 2286,
    "type": "SU",
    "updatedOn": "2020-11-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 1257,
    "burnTimeS": 3.01,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1256",
    "dataFiles": 2,
    "designation": "L1256WS",
    "diameter": 75,
    "impulseClass": "L",
    "length": 560.8,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1509,
    "motorId": "5f4294d2000231000000046a",
    "propInfo": "Super White Lightning",
    "propWeightG": 1964,
    "samples": [
      [ 0, 0 ], [ 0.017, 13.87 ], [ 0.045, 1220 ], [ 0.19, 1400 ], [ 0.465, 1439 ], [ 0.77, 1470 ],
      [ 1.076, 1466 ], [ 1.381, 1445 ], [ 1.723, 1341 ], [ 2.029, 1231 ], [ 2.485, 1098 ],
      [ 2.71, 1064 ], [ 2.788, 829.7 ], [ 2.939, 376 ], [ 3.023, 55.77 ], [ 3.041, 28.07 ],
      [ 3.059, 0 ]
],
    "totImpulseNs": 3785,
    "totalWeightG": 3756,
    "type": "reload",
    "updatedOn": "2019-12-12"
  },
  {
    "availability": "regular",
    "avgThrustN": 2048,
    "burnTimeS": 2.7,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2050",
    "dataFiles": 2,
    "designation": "M2050X-P",
    "diameter": 75,
    "impulseClass": "M",
    "length": 699.7,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2544,
    "motorId": "5f4294d2000231000000046b",
    "propInfo": "Propellant X",
    "propWeightG": 2268,
    "samples": [
      [ 0, 0 ], [ 0.028, 417.2 ], [ 0.067, 2562 ], [ 0.151, 2409 ], [ 0.261, 2392 ], [ 0.372, 2298 ],
      [ 0.557, 2265 ], [ 0.779, 2242 ], [ 0.965, 2220 ], [ 1.15, 2189 ], [ 1.372, 2146 ],[ 1.816, 2060 ],
      [ 2.149, 2028 ], [ 2.325, 2021 ], [ 2.419, 1865 ], [ 2.495, 1462 ], [ 2.611, 804.8 ],
      [ 2.697, 307.6 ], [ 2.736, 91.61 ], [ 2.764, 0 ]
],
    "totImpulseNs": 5493,
    "totalWeightG": 4559,
    "type": "reload",
    "updatedOn": "2021-02-14"
  },
  {
    "availability": "regular",
    "avgThrustN": 5515,
    "burnTimeS": 4.03,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "O5500",
    "dataFiles": 3,
    "delays": "P",
    "designation": "O5500X-PS",
    "diameter": 98,
    "impulseClass": "O",
    "length": 1507,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 7552,
    "motorId": "5f4294d2000231000000046c",
    "propInfo": "Propellant X",
    "propWeightG": 9779,
    "samples": [
      [ 0, 0 ], [ 0.009, 20.41 ], [ 0.044, 7112 ], [ 0.063, 6735 ], [ 0.078, 6898 ], [ 0.094, 6612 ],
      [ 0.109, 6765 ], [ 0.125, 6541 ], [ 0.147, 6582 ], [ 0.194, 6520 ], [ 0.35, 6796 ],[ 0.428, 7092 ],
      [ 0.563, 7286 ], [ 0.694, 7408 ], [ 0.988, 7582 ], [ 1.266, 7622 ], [ 1.491, 7724 ],
      [ 1.581, 7653 ], [ 1.641, 7541 ], [ 1.684, 7500 ], [ 1.716, 7337 ], [ 1.784, 7224 ],
      [ 1.938, 6786 ], [ 2.138, 6327 ], [ 2.491, 5898 ], [ 2.6, 5704 ], [ 2.919, 3541 ], [ 3.022, 3408 ],
      [ 3.138, 2888 ], [ 3.3, 2235 ], [ 3.388, 1673 ], [ 3.441, 1490 ], [ 3.544, 1418 ], [ 3.609, 1296 ],
      [ 3.688, 816.3 ], [ 3.778, 653.1 ], [ 3.819, 581.6 ], [ 3.853, 489.8 ], [ 3.897, 285.7 ],
      [ 3.981, 20.41 ], [ 3.997, 0 ]
],
    "totImpulseNs": 21380,
    "totalWeightG": 16780,
    "type": "SU",
    "updatedOn": "2023-01-11"
  },
  {
    "availability": "regular",
    "avgThrustN": 474.1,
    "burnTimeS": 2.94,
    "caseInfo": "AMW 54-1400",
    "certOrg": "National Association of Rocketry",
    "commonName": "K475",
    "dataFiles": 2,
    "designation": "K475",
    "diameter": 54,
    "impulseClass": "K",
    "length": 403,
    "manufacturer": "Animal Motor Works",
    "manufacturerAbbrev": "AMW",
    "maxThrustN": 588.3,
    "motorId": "5f4294d2000231000000046d",
    "propInfo": "White Wolf",
    "propWeightG": 728.6,
    "samples": [
      [ 0, 0 ], [ 0.022, 127.8 ], [ 0.041, 386 ], [ 0.063, 548.3 ], [ 0.096, 521.3 ], [ 0.134, 499.1 ],
      [ 0.18, 486.8 ], [ 0.285, 486.8 ], [ 0.478, 501.6 ], [ 0.731, 523.7 ], [ 1.096, 553.3 ],
      [ 1.433, 578 ], [ 1.601, 588.3 ], [ 1.756, 582.7 ], [ 1.895, 580.3 ], [ 1.958, 575.3 ],
      [ 2.063, 550.7 ], [ 2.209, 518.8 ], [ 2.344, 477.1 ], [ 2.495, 418 ], [ 2.561, 354.1 ],
      [ 2.582, 334.4 ], [ 2.599, 332 ], [ 2.62, 297.5 ], [ 2.67, 226.2 ], [ 2.707, 157.4 ],
      [ 2.74, 98.35 ], [ 2.799, 49.18 ], [ 2.853, 17.21 ], [ 2.94, 0 ]
],
    "totImpulseNs": 1394,
    "totalWeightG": 1493,
    "type": "reload",
    "updatedOn": "2024-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 87.54,
    "burnTimeS": 1.31,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "G75",
    "dataFiles": 2,
    "delays": "7,9,11,13",
    "designation": "G75M",
    "diameter": 29,
    "impulseClass": "G",
    "length": 157,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 110.7,
    "motorId": "5f4294d2000231000000046e",
    "propInfo": "Metalstorm",
    "propWeightG": 67,
    "samples": [
      [ 0, 0 ], [ 0.021, 1.11 ], [ 0.027, 16.73 ], [ 0.032, 42.59 ], [ 0.071, 76.46 ], [ 0.098, 89.43 ],
      [ 0.146, 95.76 ], [ 0.208, 100.7 ], [ 0.282, 103.4 ], [ 0.345, 100.3 ], [ 0.467, 99.99 ],
      [ 0.619, 103.4 ], [ 0.732, 100.7 ], [ 0.916, 95.36 ], [ 1.048, 86.1 ], [ 1.121, 77.1 ],
      [ 1.211, 68.24 ], [ 1.258, 34.37 ], [ 1.304, 10.55 ], [ 1.347, 4.14 ], [ 1.398, 0 ]
],
    "sparky": true,
    "totImpulseNs": 115.2,
    "totalWeightG": 133,
    "type": "SU",
    "updatedOn": "2020-09-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 176.4,
    "burnTimeS": 1.886,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I175",
    "dataFiles": 2,
    "delays": "5,7,9,11,13",
    "designation": "I175WS",
    "diameter": 38,
    "impulseClass": "I",
    "length": 214,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 260.4,
    "motorId": "5f4294d2000231000000046f",
    "propInfo": "Super White Lightning",
    "samples": [
      [ 0, 0 ], [ 0.022, 2.71 ], [ 0.028, 93.62 ], [ 0.06, 219.8 ], [ 0.097, 244.9 ], [ 0.209, 252.6 ],
      [ 0.299, 249.2 ], [ 0.581, 227 ], [ 0.97, 190.3 ], [ 1.381, 136.1 ], [ 1.749, 109 ],
      [ 1.802, 65.53 ], [ 1.857, 24.89 ], [ 1.908, 10.42 ], [ 1.97, 0 ]
],
    "totImpulseNs": 333,
    "totalWeightG": 348,
    "type": "SU",
    "updatedOn": "2020-04-30"
  },
  {
    "availability": "regular",
    "avgThrustN": 52.65,
    "burnTimeS": 1.26,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F52",
    "dataFiles": 2,
    "delays": "5,6,8,10,12",
    "designation": "F52C",
    "diameter": 29,
    "impulseClass": "F",
    "length": 111.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 64.33,
    "motorId": "5f5e57811e865c0004c955d8",
    "propInfo": "Classic",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.02, 0.267 ], [ 0.024, 3.465 ], [ 0.038, 38.82 ], [ 0.073, 53.88 ], [ 0.143, 56.49 ],
      [ 0.323, 61.32 ], [ 0.826, 57.35 ], [ 1.141, 51.07 ], [ 1.17, 35.61 ], [ 1.225, 11.76 ],
      [ 1.266, 4.559 ], [ 1.291, 1.512 ], [ 1.32, 0.133 ], [ 1.329, 0 ]
],
    "totImpulseNs": 66.2,
    "totalWeightG": 81.4,
    "type": "SU",
    "updatedOn": "2021-07-03"
  },
  {
    "availability": "regular",
    "avgThrustN": 13.89,
    "burnTimeS": 15.22,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H13",
    "dataFiles": 2,
    "delays": "P",
    "designation": "H13ST",
    "diameter": 29,
    "impulseClass": "H",
    "length": 213.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 43.51,
    "motorId": "5f5e58171e865c0004c955f8",
    "propInfo": "Super Thunder",
    "propWeightG": 116.4,
    "samples": [
      [ 0, 0 ], [ 0.005, 0.107 ], [ 0.024, 2.636 ], [ 0.035, 18.98 ], [ 0.081, 32.72 ], [ 0.147, 36.42 ],
      [ 0.379, 44.53 ], [ 0.452, 23.85 ], [ 0.566, 18.89 ], [ 0.818, 16.73 ], [ 1.286, 15.68 ],
      [ 2.114, 14.75 ], [ 3.23, 14.03 ], [ 4.382, 13.93 ], [ 5.786, 13.47 ], [ 7.082, 13.12 ],
      [ 8.666, 12.92 ], [ 10.29, 12.82 ], [ 12.09, 12.61 ], [ 13.6, 12.33 ], [ 14.75, 11.91 ],
      [ 15.23, 11.08 ], [ 15.3, 6.048 ], [ 15.43, 0 ]
],
    "totImpulseNs": 211.2,
    "totalWeightG": 203.4,
    "type": "SU",
    "updatedOn": "2020-09-13"
  },
  {
    "availability": "regular",
    "avgThrustN": 6,
    "burnTimeS": 0.6,
    "certOrg": "Uncertified",
    "commonName": "A6",
    "dataFiles": 2,
    "delays": "4",
    "designation": "A6",
    "diameter": 18,
    "impulseClass": "A",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-A6-4-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0164&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 10.5,
    "motorId": "5f76951c9503a10004898852",
    "propInfo": "black powder",
    "propWeightG": 3.5,
    "samples": [
      [ 0, 0 ], [ 0.088, 0.502 ], [ 0.186, 4.601 ], [ 0.232, 7.089 ], [ 0.314, 10.48 ], [ 0.418, 6.713 ],
      [ 0.467, 3.011 ], [ 0.501, 0.418 ], [ 0.71, 0 ]
],
    "totImpulseNs": 2.5,
    "totalWeightG": 14.5,
    "type": "SU",
    "updatedOn": "2020-10-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 2,
    "burnTimeS": 2.5,
    "certOrg": "Uncertified",
    "commonName": "B2",
    "dataFiles": 1,
    "delays": "0,4,P",
    "designation": "B2",
    "diameter": 18,
    "impulseClass": "B",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-B2-0-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0220&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "motorId": "5f7695bd9503a10004898855",
    "propInfo": "black powder",
    "samples": [
      [ 0, 0 ], [ 0.256, 1.578 ], [ 0.31, 2.042 ], [ 0.361, 2.568 ], [ 0.395, 3.063 ], [ 0.433, 3.62 ],
      [ 0.453, 4.115 ], [ 0.484, 4.765 ], [ 0.501, 4.92 ], [ 0.515, 5.105 ], [ 0.532, 5.012 ],
      [ 0.545, 4.796 ], [ 0.566, 4.455 ], [ 0.583, 3.929 ], [ 0.596, 3.434 ], [ 0.627, 2.97 ],
      [ 0.661, 2.63 ], [ 0.719, 2.166 ], [ 0.801, 1.949 ], [ 2.256, 1.949 ], [ 2.314, 1.825 ],
      [ 2.361, 1.423 ], [ 2.505, 0 ]
],
    "totImpulseNs": 5,
    "type": "SU",
    "updatedOn": "2020-10-02"
  },
  {
    "availability": "regular",
    "avgThrustN": 2,
    "burnTimeS": 5,
    "certOrg": "Uncertified",
    "commonName": "C2",
    "dataFiles": 2,
    "delays": "0,P",
    "designation": "C2",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-C2-0-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0320&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 5,
    "motorId": "5f7696fb9503a10004898857",
    "propInfo": "black powder",
    "propWeightG": 11.3,
    "samples": [
      [ 0, 0 ], [ 0.04, 0.229 ], [ 0.12, 0.658 ], [ 0.211, 1.144 ], [ 0.291, 1.831 ], [ 0.385, 2.86 ],
      [ 0.447, 3.833 ], [ 0.505, 5.001 ], [ 0.567, 3.89 ], [ 0.615, 3.146 ], [ 0.665, 2.66 ],
      [ 0.735, 2.203 ], [ 0.815, 2.088 ], [ 0.93, 1.98 ], [ 4.589, 1.96 ], [ 4.729, 1.888 ],
      [ 4.815, 1.602 ], [ 4.873, 1.259 ], [ 4.969, 0.658 ], [ 5.083, 0 ]
],
    "totImpulseNs": 9.8,
    "totalWeightG": 22.4,
    "type": "SU",
    "updatedOn": "2020-10-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 6,
    "burnTimeS": 1.6,
    "certOrg": "Uncertified",
    "commonName": "C6",
    "dataFiles": 2,
    "delays": "0,3,5,7,P",
    "designation": "C6",
    "diameter": 18,
    "impulseClass": "C",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-C6-0-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0360&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 15,
    "motorId": "5f76974c9503a10004898858",
    "propInfo": "black powder",
    "propWeightG": 9.6,
    "samples": [
      [ 0, 0 ], [ 0.046, 0.953 ], [ 0.168, 5.259 ], [ 0.235, 10.02 ], [ 0.291, 15 ], [ 0.418, 9.87 ],
      [ 0.505, 7.546 ], [ 0.582, 6.631 ], [ 0.679, 6.136 ], [ 0.786, 5.716 ], [ 1.26, 5.678 ],
      [ 1.357, 5.488 ], [ 1.423, 4.992 ], [ 1.469, 4.116 ], [ 1.618, 1.22 ], [ 1.701, 0 ]
],
    "totImpulseNs": 10,
    "totalWeightG": 20.5,
    "type": "SU",
    "updatedOn": "2020-10-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 9,
    "burnTimeS": 2.1,
    "certOrg": "Uncertified",
    "commonName": "D9",
    "dataFiles": 2,
    "delays": "0,3,5,7,P",
    "designation": "D9",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-D9-0-inkl-Elektrozuender-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0400&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 25,
    "motorId": "5f7697979503a10004898859",
    "propInfo": "black powder",
    "propWeightG": 16.1,
    "samples": [
      [ 0, 0 ], [ 0.04, 2.111 ], [ 0.116, 9.685 ], [ 0.213, 25 ], [ 0.286, 15.74 ], [ 0.329, 12.47 ],
      [ 0.369, 10.67 ], [ 0.42, 9.713 ], [ 0.495, 9.178 ], [ 0.597, 8.896 ], [ 1.711, 8.925 ],
      [ 1.826, 8.699 ], [ 1.917, 8.052 ], [ 1.975, 6.954 ], [ 2.206, 1.07 ], [ 2.242, 0 ]
],
    "totImpulseNs": 20,
    "totalWeightG": 27.1,
    "type": "SU",
    "updatedOn": "2020-12-06"
  },
  {
    "availability": "regular",
    "avgThrustN": 66.76,
    "burnTimeS": 20.22,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K76",
    "dataFiles": 2,
    "delays": "P",
    "designation": "K76WN-P",
    "diameter": 54,
    "impulseClass": "K",
    "length": 368,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 299.7,
    "motorId": "5f7d2b90606b5d00041d9ab1",
    "propInfo": "White Lightning/Warp 9",
    "propWeightG": 840,
    "samples": [
      [ 0, 0 ], [ 0.002, 0.89 ], [ 0.024, 14.56 ], [ 0.03, 103.8 ], [ 0.046, 251.3 ], [ 0.122, 277.1 ],
      [ 0.834, 279.8 ], [ 1.266, 284.1 ], [ 2.13, 273.3 ], [ 2.886, 256.7 ], [ 3.084, 151.9 ],
      [ 3.278, 49.95 ], [ 3.71, 39.6 ], [ 4.862, 36.31 ], [ 9.686, 30.08 ], [ 12.96, 28.25 ],
      [ 15.66, 26.82 ], [ 18.51, 25.91 ], [ 19.95, 24.61 ], [ 20.13, 22.17 ], [ 20.29, 14.57 ],
      [ 20.44, 6.13 ], [ 20.58, 0 ]
],
    "totImpulseNs": 1350,
    "totalWeightG": 1278,
    "type": "SU",
    "updatedOn": "2020-10-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 19.1,
    "burnTimeS": 0.52,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "C18",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "C18W",
    "diameter": 18,
    "impulseClass": "C",
    "length": 81,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 23.6,
    "motorId": "5f7d2f71606b5d00041d9aba",
    "propInfo": "Fast White Lightning",
    "propWeightG": 5.6,
    "samples": [
      [ 0, 0 ], [ 0.013, 0.043 ], [ 0.022, 0.937 ], [ 0.03, 2.428 ], [ 0.039, 6.218 ], [ 0.044, 12.44 ],
      [ 0.052, 20.06 ], [ 0.059, 21.72 ], [ 0.071, 22.02 ], [ 0.08, 21.85 ], [ 0.088, 22.19 ],
      [ 0.122, 21.38 ], [ 0.132, 21.42 ], [ 0.143, 21.08 ], [ 0.152, 21.55 ], [ 0.167, 21.68 ],
      [ 0.177, 21.72 ], [ 0.187, 21.93 ], [ 0.197, 21.21 ], [ 0.218, 21.25 ], [ 0.225, 21.98 ],
      [ 0.232, 22.06 ], [ 0.241, 21.08 ], [ 0.247, 20.95 ], [ 0.261, 21.34 ], [ 0.268, 21.38 ],
      [ 0.277, 20.91 ], [ 0.294, 21.08 ], [ 0.313, 21.17 ], [ 0.324, 21.38 ], [ 0.331, 21.38 ],
      [ 0.34, 21.12 ], [ 0.347, 21.12 ], [ 0.356, 21.04 ], [ 0.363, 21.12 ], [ 0.377, 21.55 ],
      [ 0.384, 21.68 ], [ 0.396, 22.15 ], [ 0.404, 22.79 ], [ 0.414, 22.91 ], [ 0.424, 22.79 ],
      [ 0.436, 22.06 ], [ 0.443, 21.34 ], [ 0.454, 20.78 ], [ 0.461, 20.14 ], [ 0.468, 19.46 ],
      [ 0.477, 17.97 ], [ 0.482, 15.93 ], [ 0.49, 13.03 ], [ 0.5, 9.114 ], [ 0.509, 6.133 ],
      [ 0.52, 3.279 ], [ 0.532, 0.894 ], [ 0.539, 0.256 ], [ 0.543, 0 ]
],
    "totImpulseNs": 9.83,
    "totalWeightG": 20.9,
    "type": "SU",
    "updatedOn": "2020-10-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 18.25,
    "burnTimeS": 0.76,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "D20",
    "dataFiles": 2,
    "delays": "4,6,8",
    "designation": "D20W",
    "diameter": 18,
    "impulseClass": "D",
    "length": 81,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 24.34,
    "motorId": "5f7d30aa606b5d00041d9abc",
    "propInfo": "Fast White Lightning",
    "propWeightG": 8.7,
    "samples": [
      [ 0, 0 ], [ 0.001, 1.96 ], [ 0.013, 17.06 ], [ 0.026, 24.7 ], [ 0.07, 19.17 ], [ 0.116, 17.55 ],
      [ 0.181, 16.99 ], [ 0.285, 18.24 ], [ 0.426, 19.5 ], [ 0.564, 19.81 ], [ 0.626, 21.26 ],
      [ 0.667, 19.48 ], [ 0.68, 17.19 ], [ 0.695, 13.68 ], [ 0.713, 8.351 ], [ 0.745, 2.124 ],
      [ 0.764, 0.669 ], [ 0.799, 0.04719 ], [ 0.8, 0 ]
],
    "totImpulseNs": 13.83,
    "totalWeightG": 24.1,
    "type": "SU",
    "updatedOn": "2020-10-07"
  },
  {
    "availability": "OOP",
    "avgThrustN": 42,
    "burnTimeS": 2.56,
    "certOrg": "National Association of Rocketry",
    "commonName": "G42",
    "dataFiles": 1,
    "delays": "4,6,8,10,12",
    "designation": "G42",
    "diameter": 24,
    "impulseClass": "G",
    "length": 144,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 58.63,
    "motorId": "5f7d3c0f606b5d00041d9f9b",
    "propWeightG": 48.8,
    "samples": [
      [ 0, 0 ], [ 0.005, 22.79 ], [ 0.015, 32.15 ], [ 0.024, 29.11 ], [ 0.04, 31.77 ], [ 0.043, 36.81 ],
      [ 0.048, 42.16 ], [ 0.053, 46.94 ], [ 0.06, 51.01 ], [ 0.088, 54.73 ], [ 0.134, 56.67 ],
      [ 0.192, 55.29 ], [ 0.253, 54.75 ], [ 0.312, 54.11 ], [ 0.369, 54.45 ], [ 0.43, 54.21 ],
      [ 0.487, 53.85 ], [ 0.548, 53.67 ], [ 0.605, 53.96 ], [ 0.665, 53.72 ], [ 0.731, 53.28 ],
      [ 0.787, 52.59 ], [ 0.846, 51.96 ], [ 0.906, 51.35 ], [ 0.965, 50.77 ], [ 1.026, 49.24 ],
      [ 1.081, 48.62 ], [ 1.142, 47.27 ], [ 1.213, 45.73 ], [ 1.264, 43.8 ], [ 1.321, 42.33 ],
      [ 1.382, 40.39 ], [ 1.442, 38.48 ], [ 1.502, 36.75 ], [ 1.561, 33.9 ], [ 1.621, 31.98 ],
      [ 1.679, 29.13 ], [ 1.729, 25.86 ], [ 1.784, 22.24 ], [ 1.837, 18.93 ], [ 1.895, 15.86 ],
      [ 1.953, 13.19 ], [ 2.014, 10.81 ], [ 2.076, 8.833 ], [ 2.131, 7.109 ], [ 2.199, 5.416 ],
      [ 2.275, 3.67 ], [ 2.333, 2.581 ], [ 2.404, 1.791 ], [ 2.479, 0.407 ], [ 2.519, 0.231 ]
],
    "totImpulseNs": 89.16,
    "totalWeightG": 95,
    "type": "SU",
    "updatedOn": "2020-10-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 4,
    "burnTimeS": 1.2,
    "certOrg": "Uncertified",
    "commonName": "B4",
    "dataFiles": 2,
    "delays": "0,4,P",
    "designation": "B4",
    "diameter": 18,
    "impulseClass": "B",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-B4-0-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0240&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 8,
    "motorId": "5f8b05a5d5fa3b000447e825",
    "propInfo": "black powder",
    "propWeightG": 5.4,
    "samples": [
      [ 0, 0 ], [ 0.049, 0.83 ], [ 0.096, 1.745 ], [ 0.157, 2.946 ], [ 0.209, 3.89 ], [ 0.273, 4.977 ],
      [ 0.335, 6.093 ], [ 0.38, 7.122 ], [ 0.411, 8.001 ], [ 0.436, 7.065 ], [ 0.465, 6.236 ],
      [ 0.516, 5.063 ], [ 0.564, 4.462 ], [ 0.607, 4.205 ], [ 0.669, 4.09 ], [ 0.9, 4.09 ],
      [ 0.966, 3.976 ], [ 1.025, 3.719 ], [ 1.084, 3.261 ], [ 1.145, 2.546 ], [ 1.212, 1.745 ],
      [ 1.295, 0.772 ], [ 1.364, 0 ]
],
    "totImpulseNs": 5,
    "totalWeightG": 16.3,
    "type": "SU",
    "updatedOn": "2020-10-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 3,
    "burnTimeS": 5.5,
    "certOrg": "Uncertified",
    "commonName": "D3",
    "dataFiles": 2,
    "delays": "0,P",
    "designation": "D3",
    "diameter": 18,
    "impulseClass": "D",
    "infoUrl": "https://www.raketenmodellbau-klima.de/Raketenmodellbau/Motoren-Treibsaetze/Motor-D3-0-6-Stueck-.htm?shop=raketenklima&SessionId=&a=article&ProdNr=0430&t=23&c=712&p=712",
    "length": 70,
    "manufacturer": "Raketenmodellbau Klima",
    "manufacturerAbbrev": "Klima",
    "maxThrustN": 9.01,
    "motorId": "5f8b0753d5fa3b000447e835",
    "propInfo": "black powder",
    "propWeightG": 17,
    "samples": [
      [ 0, 0 ], [ 0.073, 0.229 ], [ 0.178, 0.686 ], [ 0.251, 1.287 ], [ 0.313, 2.203 ], [ 0.375, 3.633 ],
      [ 0.425, 5.006 ], [ 0.473, 6.465 ], [ 0.556, 8.181 ], [ 0.603, 9.01 ], [ 0.655, 6.922 ],
      [ 0.698, 5.463 ], [ 0.782, 4.291 ], [ 0.873, 3.576 ], [ 1.024, 3.146 ], [ 1.176, 2.946 ],
      [ 5.282, 2.918 ], [ 5.491, 2.832 ], [ 5.59, 2.517 ], [ 5.782, 1.859 ], [ 5.924, 1.287 ],
      [ 6.061, 0.715 ], [ 6.17, 0.286 ], [ 6.26, 0 ]
],
    "totImpulseNs": 17.4,
    "totalWeightG": 27.9,
    "type": "SU",
    "updatedOn": "2020-10-27"
  },
  {
    "availability": "regular",
    "avgThrustN": 21.66,
    "burnTimeS": 0.89,
    "caseInfo": "White Lightning",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "D22",
    "dataFiles": 2,
    "delays": "4,6,7,8,10",
    "designation": "D22W",
    "diameter": 24,
    "impulseClass": "D",
    "length": 86.7,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 27.58,
    "motorId": "60159ce4b94d0e00040a8402",
    "propWeightG": 12,
    "samples": [
      [ 0, 0 ], [ 0.021, 1.39 ], [ 0.057, 21.6 ], [ 0.1, 25.01 ], [ 0.172, 27.69 ], [ 0.316, 24.7 ],
      [ 0.46, 22.47 ], [ 0.64, 21.81 ], [ 0.859, 16.23 ], [ 0.895, 6.84 ], [ 0.934, 1.087 ],
      [ 0.972, 0.1795 ], [ 1.008, 0 ]
],
    "totImpulseNs": 19.33,
    "totalWeightG": 37.5,
    "type": "SU",
    "updatedOn": "2021-01-30"
  },
  {
    "availability": "regular",
    "avgThrustN": 23.5,
    "burnTimeS": 1.18,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "E26",
    "dataFiles": 2,
    "delays": "4,6,7,8,10",
    "designation": "E26W",
    "diameter": 24,
    "impulseClass": "E",
    "length": 87.7,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 27.89,
    "motorId": "60159d7db94d0e00040a8404",
    "propInfo": "White Lightning",
    "propWeightG": 18.3,
    "samples": [
      [ 0, 0 ], [ 0.019, 2.037 ], [ 0.058, 16.93 ], [ 0.161, 22.15 ], [ 0.413, 26.96 ], [ 0.667, 25.86 ],
      [ 0.923, 25.63 ], [ 1.08, 26.22 ], [ 1.138, 16.76 ], [ 1.166, 6.116 ], [ 1.192, 0.5682 ],
      [ 1.217, 0 ]
],
    "totImpulseNs": 27.85,
    "totalWeightG": 44,
    "type": "SU",
    "updatedOn": "2021-01-30"
  },
  {
    "availability": "regular",
    "avgThrustN": 1939,
    "burnTimeS": 2.23,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L1940",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L1940X",
    "diameter": 75,
    "impulseClass": "L",
    "length": 560,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2319,
    "motorId": "60ac748b8dc4640004c24d85",
    "propInfo": "Propellant X",
    "propWeightG": 1825,
    "samples": [
      [ 0, 0 ], [ 0.02, 48.14 ], [ 0.04, 2107 ], [ 0.055, 2289 ], [ 0.073, 2150 ], [ 0.156, 2102 ],
      [ 0.397, 2150 ], [ 0.691, 2155 ], [ 0.982, 2118 ], [ 1.41, 2000 ], [ 1.696, 1899 ],[ 1.935, 1840 ],
      [ 2.06, 1819 ], [ 2.103, 1653 ], [ 2.116, 1375 ], [ 2.133, 936 ], [ 2.163, 534.9 ],[ 2.214, 240.7 ],
      [ 2.244, 112.3 ], [ 2.296, 5.349 ], [ 2.309, 0 ]
],
    "totImpulseNs": 4329,
    "totalWeightG": 3856,
    "type": "reload",
    "updatedOn": "2021-05-25"
  },
  {
    "availability": "regular",
    "avgThrustN": 4.22,
    "burnTimeS": 1.17,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "B6",
    "dataFiles": 2,
    "delays": "4,6",
    "designation": "B6W",
    "diameter": 18,
    "impulseClass": "B",
    "length": 79.2,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 9.13,
    "motorId": "60ac76068dc4640004c24d93",
    "propInfo": "White Lightning",
    "propWeightG": 2.8,
    "samples": [
      [ 0, 0 ], [ 0.01, 0.157 ], [ 0.029, 0.677 ], [ 0.045, 2.643 ], [ 0.058, 0.966 ], [ 0.09, 2.048 ],
      [ 0.127, 6.545 ], [ 0.174, 9.296 ], [ 0.214, 9.045 ], [ 0.269, 9.01 ], [ 0.324, 8.039 ],
      [ 0.47, 6.334 ], [ 0.615, 4.492 ], [ 0.795, 2.904 ], [ 0.975, 1.412 ], [ 1.148, 0.536 ],
      [ 1.211, 0 ]
],
    "totImpulseNs": 4.87,
    "totalWeightG": 19.3,
    "type": "SU",
    "updatedOn": "2021-05-25"
  },
  {
    "availability": "regular",
    "avgThrustN": 37.73,
    "burnTimeS": 9.95,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I40",
    "dataFiles": 1,
    "delays": "P",
    "designation": "I40N-P",
    "diameter": 38,
    "impulseClass": "I",
    "length": 203,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 101.7,
    "motorId": "616e6152763045000425980a",
    "propInfo": "Warp 9",
    "propWeightG": 197,
    "samples": [
      [ 0, 0 ], [ 0.021, 58.16 ], [ 0.134, 95.02 ], [ 0.289, 94.59 ], [ 0.371, 89.48 ], [ 0.485, 51.55 ],
      [ 1.072, 41.12 ], [ 2.155, 38.56 ], [ 2.845, 38.35 ], [ 3.619, 37.28 ], [ 4.433, 36.43 ],
      [ 5.474, 35.15 ], [ 6.144, 34.73 ], [ 6.794, 34.73 ], [ 7.433, 34.3 ], [ 7.969, 33.66 ],
      [ 8.794, 32.38 ], [ 9.546, 31.74 ], [ 9.979, 0 ]
],
    "totImpulseNs": 374.9,
    "totalWeightG": 359.9,
    "type": "SU",
    "updatedOn": "2021-10-19"
  },
  {
    "availability": "regular",
    "avgThrustN": 615.1,
    "burnTimeS": 1.2,
    "caseInfo": "RMS-54/852",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J615",
    "dataFiles": 2,
    "delays": "4,8,12,16,20",
    "designation": "J615ST-20A",
    "diameter": 54,
    "impulseClass": "J",
    "length": 240.4,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 933.9,
    "motorId": "616e62f1763045000425980d",
    "propInfo": "Super Thunder",
    "propWeightG": 370.5,
    "samples": [
      [ 0, 0 ], [ 0.003, 2.405 ], [ 0.025, 21.64 ], [ 0.037, 829.7 ], [ 0.052, 726.3 ], [ 0.093, 668.5 ],
      [ 0.174, 680.6 ], [ 0.369, 719 ], [ 0.532, 728.7 ], [ 0.688, 704.6 ], [ 0.851, 649.3 ],
      [ 0.953, 589.2 ], [ 1.031, 531.5 ], [ 1.113, 478.6 ], [ 1.166, 387.2 ], [ 1.198, 254.9 ],
      [ 1.212, 122.6 ], [ 1.243, 0 ]
],
    "totImpulseNs": 745.5,
    "totalWeightG": 801.7,
    "type": "reload",
    "updatedOn": "2021-10-19"
  },
  {
    "availability": "regular",
    "avgThrustN": 1971,
    "burnTimeS": 7.04,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N1975",
    "dataFiles": 1,
    "delays": "P",
    "designation": "N1975W-PS",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1110,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2903,
    "motorId": "616e64637630450004259814",
    "propInfo": "White Lightning",
    "propWeightG": 7676,
    "samples": [
      [ 0, 0 ], [ 0.08, 1985 ], [ 0.217, 2638 ], [ 0.819, 2700 ], [ 1.237, 2769 ], [ 1.663, 2817 ],
      [ 2.137, 2803 ], [ 2.651, 2672 ], [ 3.245, 2404 ], [ 4.032, 2054 ], [ 4.602, 1855 ],
      [ 5.229, 1532 ], [ 5.39, 1353 ], [ 5.566, 1072 ], [ 5.863, 796.9 ], [ 6.161, 604.5 ],
      [ 6.297, 563.3 ], [ 6.763, 316 ], [ 6.996, 199.2 ], [ 7.333, 0 ]
],
    "totImpulseNs": 13890,
    "totalWeightG": 12210,
    "type": "SU",
    "updatedOn": "2021-10-19"
  },
  {
    "availability": "regular",
    "avgThrustN": 2129,
    "burnTimeS": 1.82,
    "certOrg": "National Association of Rocketry",
    "commonName": "L2350",
    "dataFiles": 1,
    "delays": "P",
    "designation": "L2350",
    "diameter": 152,
    "impulseClass": "L",
    "length": 210,
    "manufacturer": "Estes Industries",
    "manufacturerAbbrev": "Estes",
    "maxThrustN": 2585,
    "motorId": "61ec46faa1cd0a000491cf48",
    "propWeightG": 1680,
    "samples": [
      [ 0, 0 ], [ 0.086, 17.85 ], [ 0.098, 50.7 ], [ 0.1061, 128.9 ], [ 0.1124, 259 ], [ 0.1196, 514.3 ],
      [ 0.1256, 807.7 ], [ 0.1392, 1569 ], [ 0.1476, 1942 ], [ 0.1512, 2059 ], [ 0.156, 2172 ],
      [ 0.1656, 2271 ], [ 0.2036, 2213 ], [ 0.378, 2390 ], [ 0.5616, 2529 ], [ 0.6447, 2575 ],
      [ 0.7668, 2584 ], [ 0.992, 2541 ], [ 1.168, 2420 ], [ 1.603, 1862 ], [ 1.664, 1762 ],
      [ 1.714, 1627 ], [ 1.75, 1458 ], [ 1.783, 1205 ], [ 1.832, 638.3 ], [ 1.853, 457.9 ],
      [ 1.892, 238.8 ], [ 1.934, 129 ], [ 1.98, 61.91 ], [ 2.047, 39.04 ], [ 3, 43.09 ], [ 3.001, 0 ]
],
    "totImpulseNs": 3886,
    "totalWeightG": 5550,
    "type": "SU",
    "updatedOn": "2022-01-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 746.6,
    "burnTimeS": 1.74,
    "caseInfo": "RMS-75/1280",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K750",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K750ST",
    "diameter": 75,
    "impulseClass": "K",
    "length": 267,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 835.7,
    "motorId": "627bbef01b11ab0004a4bb20",
    "propInfo": "Super Thunder",
    "propWeightG": 595,
    "samples": [
      [ 0, 0 ], [ 0.014, 4.867 ], [ 0.018, 10.18 ], [ 0.044, 659.3 ], [ 0.068, 606.6 ], [ 0.118, 632.3 ],
      [ 0.17, 658 ], [ 0.22, 681 ], [ 0.263, 703.1 ], [ 0.334, 727.9 ], [ 0.483, 772.1 ],[ 0.577, 792.5 ],
      [ 0.663, 805.8 ], [ 0.723, 809.3 ], [ 0.778, 814.6 ], [ 0.886, 811.9 ], [ 0.975, 807.5 ],
      [ 1.065, 796.9 ], [ 1.148, 788.9 ], [ 1.234, 779.2 ], [ 1.352, 763.7 ], [ 1.456, 749.1 ],
      [ 1.559, 738.5 ], [ 1.625, 737.6 ], [ 1.664, 744.7 ], [ 1.704, 768.6 ], [ 1.715, 759.7 ],
      [ 1.755, 128.8 ], [ 1.767, 41.15 ], [ 1.783, 0 ]
],
    "totImpulseNs": 1299,
    "totalWeightG": 1948,
    "type": "reload",
    "updatedOn": "2022-05-11"
  },
  {
    "availability": "regular",
    "avgThrustN": 33.81,
    "burnTimeS": 1.08,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "E35",
    "dataFiles": 2,
    "delays": "5,8,11",
    "designation": "E35W",
    "diameter": 24,
    "impulseClass": "E",
    "length": 113,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 43.96,
    "motorId": "629ee7b1e0d0450004036826",
    "propInfo": "White Lightning",
    "propWeightG": 25.4,
    "samples": [
      [ 0, 0 ], [ 0.001, 0.626 ], [ 0.028, 3.962 ], [ 0.04, 13.14 ], [ 0.051, 29.61 ], [ 0.178, 34.82 ],
      [ 0.321, 36.49 ], [ 0.516, 37.74 ], [ 0.712, 40.24 ], [ 0.854, 41.91 ], [ 0.947, 43.58 ],
      [ 0.979, 46.5 ], [ 0.999, 41.91 ], [ 1.018, 33.78 ], [ 1.04, 26.48 ], [ 1.069, 19.6 ],
      [ 1.104, 9.383 ], [ 1.122, 3.128 ], [ 1.141, 0 ]
],
    "totImpulseNs": 39.38,
    "totalWeightG": 55,
    "type": "SU",
    "updatedOn": "2022-06-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 42.19,
    "burnTimeS": 1.12,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F41",
    "dataFiles": 2,
    "delays": "5,8,11",
    "designation": "F41W",
    "diameter": 24,
    "impulseClass": "F",
    "length": 113,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 54.16,
    "motorId": "629ee8bae0d045000403682e",
    "propInfo": "White Lightning",
    "propWeightG": 27.1,
    "samples": [
      [ 0, 0 ], [ 0.018, 0.679 ], [ 0.031, 8.821 ], [ 0.048, 25.56 ], [ 0.07, 30.31 ], [ 0.099, 33.25 ],
      [ 0.145, 34.83 ], [ 0.231, 36.64 ], [ 0.409, 41.84 ], [ 0.647, 45.24 ], [ 0.884, 47.5 ],
      [ 0.947, 47.72 ], [ 0.995, 51.57 ], [ 1.032, 51.34 ], [ 1.049, 49.08 ], [ 1.059, 44.56 ],
      [ 1.077, 35.96 ], [ 1.1, 26.69 ], [ 1.167, 9.273 ], [ 1.2, 0 ]
],
    "totImpulseNs": 47.65,
    "totalWeightG": 56.9,
    "type": "SU",
    "updatedOn": "2023-09-23"
  },
  {
    "availability": "regular",
    "avgThrustN": 1777,
    "burnTimeS": 1.37,
    "caseInfo": "RMS-75/2560",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K1800",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K1800ST-P",
    "diameter": 75,
    "impulseClass": "K",
    "length": 402,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2082,
    "motorId": "62f9c49d6ae9e200040442ab",
    "propInfo": "Super Thunder",
    "propWeightG": 1113,
    "samples": [
      [ 0, 0 ], [ 0.012, 9.756 ], [ 0.018, 19.51 ], [ 0.022, 39.02 ], [ 0.025, 180.5 ], [ 0.029, 578 ],
      [ 0.035, 1298 ], [ 0.038, 1720 ], [ 0.042, 1924 ], [ 0.046, 1868 ], [ 0.05, 1780 ],[ 0.052, 1698 ],
      [ 0.056, 1639 ], [ 0.06, 1683 ], [ 0.065, 1734 ], [ 0.071, 1695 ], [ 0.077, 1668 ],[ 0.084, 1717 ],
      [ 0.093, 1693 ], [ 0.102, 1732 ], [ 0.114, 1744 ], [ 0.134, 1759 ], [ 0.163, 1793 ],
      [ 0.212, 1851 ], [ 0.262, 1900 ], [ 0.363, 1983 ], [ 0.447, 2029 ], [ 0.524, 2056 ],
      [ 0.621, 2076 ], [ 0.695, 2071 ], [ 0.769, 2037 ], [ 0.81, 1993 ], [ 0.852, 1946 ],[ 0.891, 1910 ],
      [ 0.947, 1861 ], [ 0.997, 1798 ], [ 1.045, 1732 ], [ 1.086, 1671 ], [ 1.143, 1593 ],
      [ 1.205, 1507 ], [ 1.249, 1456 ], [ 1.287, 1424 ], [ 1.307, 1434 ], [ 1.322, 1432 ],
      [ 1.332, 1361 ], [ 1.34, 1244 ], [ 1.346, 1105 ], [ 1.349, 1010 ], [ 1.353, 912.2 ],
      [ 1.357, 785.4 ], [ 1.364, 612.2 ], [ 1.374, 434.1 ], [ 1.386, 253.7 ], [ 1.401, 104.9 ],
      [ 1.414, 31.71 ], [ 1.424, 0 ]
],
    "totImpulseNs": 2444,
    "totalWeightG": 2772,
    "type": "reload",
    "updatedOn": "2023-10-17"
  },
  {
    "availability": "regular",
    "avgThrustN": 22.35,
    "burnTimeS": 1.64,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "E24",
    "dataFiles": 1,
    "delays": "4,7,10",
    "designation": "E24C",
    "diameter": 29,
    "impulseClass": "E",
    "length": 110,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 33,
    "motorId": "63628c12a244550004a0cf01",
    "propInfo": "Classic",
    "propWeightG": 18.4,
    "samples": [
      [ 0, 0 ], [ 0.025, 2.452 ], [ 0.055, 13.64 ], [ 0.09, 24.06 ], [ 0.122, 27.43 ], [ 0.23, 31.57 ],
      [ 0.297, 33.41 ], [ 0.322, 32.8 ], [ 0.345, 34.18 ], [ 0.414, 34.64 ], [ 0.492, 33.1 ],
      [ 0.612, 30.65 ], [ 0.767, 27.89 ], [ 0.959, 23.6 ], [ 1.288, 15.33 ], [ 1.451, 13.03 ],
      [ 1.483, 13.33 ], [ 1.605, 5.211 ], [ 1.635, 2.605 ], [ 1.693, 0 ]
],
    "totImpulseNs": 36.32,
    "totalWeightG": 70.7,
    "type": "SU",
    "updatedOn": "2022-11-02"
  },
  {
    "availability": "regular",
    "avgThrustN": 55.22,
    "burnTimeS": 1.41,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "F67",
    "dataFiles": 1,
    "delays": "6,9,14",
    "designation": "F67C",
    "diameter": 29,
    "impulseClass": "F",
    "length": 112,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 72.79,
    "motorId": "63628d72a244550004a0cf0a",
    "propInfo": "Classic",
    "propWeightG": 36.8,
    "samples": [
      [ 0, 0 ], [ 0.009, 0.892 ], [ 0.024, 3.569 ], [ 0.033, 20.22 ], [ 0.042, 43.72 ], [ 0.055, 57.1 ],
      [ 0.075, 61.56 ], [ 0.104, 58.88 ], [ 0.126, 60.07 ], [ 0.157, 58.88 ], [ 0.261, 60.67 ],
      [ 0.402, 61.56 ], [ 0.544, 61.26 ], [ 0.659, 60.67 ], [ 0.683, 63.64 ], [ 0.692, 68.7 ],
      [ 0.703, 60.07 ], [ 0.776, 59.78 ], [ 0.91, 58.88 ], [ 1.006, 58.29 ], [ 1.096, 57.1 ],
      [ 1.167, 57.1 ], [ 1.211, 56.8 ], [ 1.269, 51.15 ], [ 1.308, 44.02 ], [ 1.333, 33.9 ],
      [ 1.344, 22.3 ], [ 1.364, 13.09 ], [ 1.406, 5.353 ], [ 1.456, 0 ]
],
    "totImpulseNs": 77.46,
    "totalWeightG": 85.7,
    "type": "SU",
    "updatedOn": "2022-11-03"
  },
  {
    "availability": "regular",
    "avgThrustN": 62.54,
    "burnTimeS": 23.1,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "K62",
    "dataFiles": 1,
    "delays": "P",
    "designation": "K62N",
    "diameter": 54,
    "impulseClass": "K",
    "length": 274,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 258.7,
    "motorId": "63bb643e1d26f30004b4b077",
    "propInfo": "Warp 9",
    "propWeightG": 831,
    "samples": [
      [ 0, 0 ], [ 0.05, 11.39 ], [ 0.126, 215.9 ], [ 0.353, 252 ], [ 0.505, 235.2 ], [ 0.681, 104.5 ],
      [ 1.236, 83.68 ], [ 4.087, 67.84 ], [ 6.534, 65.36 ], [ 8.401, 64.87 ], [ 11.63, 60.91 ],
      [ 15.29, 54.96 ], [ 19.4, 48.03 ], [ 21.24, 45.55 ], [ 21.47, 20.3 ], [ 21.87, 0 ]
],
    "totImpulseNs": 1439,
    "totalWeightG": 1277,
    "type": "SU",
    "updatedOn": "2023-01-09"
  },
  {
    "availability": "regular",
    "avgThrustN": 1261,
    "burnTimeS": 0.85,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J1265",
    "dataFiles": 1,
    "delays": "6,8,10,12,14",
    "designation": "J1265T",
    "diameter": 54,
    "impulseClass": "J",
    "length": 397,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 1745,
    "motorId": "63bb65281d26f30004b4b07b",
    "propInfo": "Super Thunder",
    "propWeightG": 507,
    "samples": [
      [ 0, 0 ], [ 0.021, 33.53 ], [ 0.026, 223.5 ], [ 0.031, 652 ], [ 0.04, 1568 ], [ 0.053, 1412 ],
      [ 0.07, 1330 ], [ 0.092, 1308 ], [ 0.201, 1349 ], [ 0.332, 1382 ], [ 0.444, 1397 ],[ 0.556, 1367 ],
      [ 0.644, 1267 ], [ 0.715, 1177 ], [ 0.739, 1174 ], [ 0.76, 1196 ], [ 0.779, 1270 ],[ 0.799, 1282 ],
      [ 0.812, 1159 ], [ 0.827, 853.1 ], [ 0.844, 499.2 ], [ 0.853, 316.7 ], [ 0.868, 130.4 ],
      [ 0.885, 40.98 ], [ 0.902, 0 ]
],
    "totImpulseNs": 1073,
    "totalWeightG": 1095,
    "type": "SU",
    "updatedOn": "2023-01-09"
  },
  {
    "availability": "regular",
    "avgThrustN": 2239,
    "burnTimeS": 2.32,
    "caseInfo": "RMS-75/5120",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2225",
    "dataFiles": 1,
    "delays": "P",
    "designation": "M2225WS",
    "diameter": 75,
    "impulseClass": "M",
    "length": 692,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 2418,
    "motorId": "63bb66061d26f30004b4b07e",
    "propInfo": "Super White Lightning",
    "propWeightG": 2331,
    "samples": [
      [ 0, 0 ], [ 0.018, 39.69 ], [ 0.037, 1359 ], [ 0.05, 2143 ], [ 0.089, 2257 ], [ 0.129, 2351 ],
      [ 0.176, 2014 ], [ 0.205, 2004 ], [ 0.265, 2262 ], [ 0.756, 2371 ], [ 1.333, 2391 ],
      [ 1.797, 2327 ], [ 2.03, 2292 ], [ 2.164, 2282 ], [ 2.272, 1161 ], [ 2.303, 580.4 ],
      [ 2.335, 208.4 ], [ 2.356, 94.26 ], [ 2.385, 9.922 ], [ 2.408, 0 ]
],
    "totImpulseNs": 5205,
    "totalWeightG": 4695,
    "type": "reload",
    "updatedOn": "2023-01-09"
  },
  {
    "availability": "regular",
    "avgThrustN": 3906,
    "burnTimeS": 4.65,
    "caseInfo": "RMS-98/20480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N4000",
    "dataFiles": 1,
    "delays": "P",
    "designation": "N4000W-PS",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1433,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 5398,
    "motorId": "645072a02cdedd00028df82d",
    "propInfo": "White Lightning",
    "propWeightG": 9800,
    "samples": [
      [ 0, 0 ], [ 0.01, 39.37 ], [ 0.029, 393.7 ], [ 0.03, 1742 ], [ 0.031, 2210 ], [ 0.039, 2874 ],
      [ 0.049, 2534 ], [ 0.05, 3469 ], [ 0.06, 3809 ], [ 0.07, 4080 ], [ 0.078, 4242 ], [ 0.088, 4385 ],
      [ 0.12, 4331 ], [ 0.148, 4227 ], [ 0.247, 4242 ], [ 0.398, 4296 ], [ 0.577, 4350 ],[ 0.774, 4439 ],
      [ 0.964, 4513 ], [ 1.172, 4567 ], [ 1.341, 4621 ], [ 1.51, 4621 ], [ 1.661, 4656 ],[ 1.837, 4675 ],
      [ 2.027, 4710 ], [ 2.157, 4729 ], [ 2.295, 4749 ], [ 2.336, 4749 ], [ 2.505, 4695 ],
      [ 2.633, 4656 ], [ 2.794, 4547 ], [ 2.97, 4478 ], [ 3.131, 4385 ], [ 3.3, 4316 ], [ 3.449, 4262 ],
      [ 3.547, 4262 ], [ 3.607, 4208 ], [ 3.708, 3720 ], [ 3.825, 2894 ], [ 3.927, 2446 ],
      [ 4.054, 2156 ], [ 4.205, 1850 ], [ 4.314, 1471 ], [ 4.394, 1097 ], [ 4.462, 841.5 ],
      [ 4.511, 698.8 ], [ 4.631, 521.7 ], [ 4.761, 265.7 ], [ 4.854, 123 ], [ 4.906, 0 ]
],
    "totImpulseNs": 18180,
    "totalWeightG": 16380,
    "type": "reload",
    "updatedOn": "2023-05-02"
  },
  {
    "availability": "regular",
    "avgThrustN": 13.21,
    "burnTimeS": 18.26,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "H14",
    "dataFiles": 1,
    "delays": "P",
    "designation": "H14ST",
    "diameter": 29,
    "impulseClass": "H",
    "length": 240,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 42.13,
    "motorId": "6457b5ce8cd35f00024615fc",
    "propInfo": "Super Thunder",
    "propWeightG": 134,
    "samples": [
      [ 0, 0 ], [ 0.045, 32.28 ], [ 0.253, 37.1 ], [ 0.343, 40.25 ], [ 0.417, 19.5 ], [ 0.625, 16.95 ],
      [ 1.787, 14.54 ], [ 3.946, 13.66 ], [ 7.029, 13 ], [ 11.84, 12.78 ], [ 15.44, 12.34 ],
      [ 16.37, 12.05 ], [ 16.52, 11.76 ], [ 16.57, 10.66 ], [ 16.83, 10.96 ], [ 17.23, 11.32 ],
      [ 17.59, 12.2 ], [ 17.74, 10.59 ], [ 17.96, 0 ]
],
    "totImpulseNs": 240.1,
    "totalWeightG": 230,
    "type": "SU",
    "updatedOn": "2023-05-07"
  },
  {
    "availability": "regular",
    "avgThrustN": 18.58,
    "burnTimeS": 2.21,
    "certOrg": "National Association of Rocketry",
    "commonName": "F23",
    "dataFiles": 1,
    "delays": "4,7",
    "designation": "F23FJ",
    "diameter": 29,
    "impulseClass": "F",
    "infoUrl": "https://www.nar.org/SandT/pdf/AeroTech/F23FJ.pdf",
    "length": 83,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 29.8,
    "motorId": "654b01231b5ae70002c2beae",
    "propInfo": "Black Max",
    "propWeightG": 30,
    "samples": [
      [ 0, 0 ], [ 0.032, 0.2895 ], [ 0.044, 1.109 ], [ 0.048, 1.795 ], [ 0.054, 5.299 ], [ 0.058, 10.04 ],
      [ 0.06, 13.78 ], [ 0.064, 17.61 ], [ 0.07, 20.61 ], [ 0.072, 20.96 ], [ 0.078, 23.65 ],
      [ 0.08, 23.58 ], [ 0.082, 24.81 ], [ 0.092, 25.4 ], [ 0.096, 26.39 ], [ 0.132, 26.32 ],
      [ 0.154, 26.78 ], [ 0.156, 27.51 ], [ 0.158, 26.62 ], [ 0.188, 26.93 ], [ 0.21, 26.12 ],
      [ 0.216, 26.6 ], [ 0.246, 25.95 ], [ 0.4, 25.98 ], [ 0.654, 24.58 ], [ 0.802, 24.48 ],
      [ 1.338, 19.35 ], [ 1.776, 11.95 ], [ 1.854, 11.08 ], [ 2.05, 4.97 ], [ 2.246, 1.363 ],
      [ 2.374, 0 ]
],
    "totImpulseNs": 41.2,
    "totalWeightG": 83,
    "type": "SU",
    "updatedOn": "2023-11-08"
  },
  {
    "availability": "regular",
    "avgThrustN": 5853,
    "burnTimeS": 6.77,
    "certOrg": "National Association of Rocketry",
    "commonName": "O6000",
    "dataFiles": 2,
    "delays": "P",
    "designation": "O6000W",
    "diameter": 152,
    "impulseClass": "O",
    "length": 1120,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 9564,
    "motorId": "656e92d97f1a4b00027f1588",
    "propInfo": "White Lightning",
    "propWeightG": 22000,
    "samples": [
      [ 0, 0 ], [ 0.001, 480.5 ], [ 0.034, 8808 ], [ 0.089, 6516 ], [ 0.2, 7367 ], [ 0.348, 6877 ],
      [ 0.753, 6716 ], [ 0.797, 7017 ], [ 0.843, 6716 ], [ 0.916, 7017 ], [ 0.993, 6716 ],
      [ 1.021, 7037 ], [ 1.564, 6877 ], [ 1.585, 7137 ], [ 2.144, 7197 ], [ 2.328, 7557 ],
      [ 2.591, 7317 ], [ 2.924, 7567 ], [ 3.133, 7037 ], [ 3.366, 7117 ], [ 3.749, 6867 ],
      [ 3.768, 7117 ], [ 4.325, 6877 ], [ 4.329, 7177 ], [ 4.402, 6877 ], [ 4.907, 7137 ],
      [ 5.157, 5976 ], [ 5.569, 2873 ], [ 5.751, 1912 ], [ 6.234, 1041 ], [ 6.61, 470.4 ],
      [ 6.801, 650.6 ], [ 6.828, 0 ]
],
    "totImpulseNs": 39620,
    "totalWeightG": 31520,
    "type": "SU",
    "updatedOn": "2023-12-05"
  },
  {
    "availability": "regular",
    "avgThrustN": 5344,
    "burnTimeS": 3.58,
    "caseInfo": "Pro-98 6g XL",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "N5500",
    "dataFiles": 1,
    "delays": "P",
    "designation": "N5500LW",
    "diameter": 98,
    "impulseClass": "N",
    "length": 1383,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 6678,
    "motorId": "66225b8cb1ba54000208d749",
    "propInfo": "Loki White",
    "propWeightG": 9182,
    "samples": [
      [ 0, 0 ], [ 0.001, 30.94 ], [ 0.026, 433.2 ], [ 0.09, 6653 ], [ 0.155, 6374 ], [ 0.826, 6220 ],
      [ 1.239, 6220 ], [ 1.6, 6282 ], [ 1.703, 6374 ], [ 1.806, 6313 ], [ 2, 6065 ], [ 2.658, 5694 ],
      [ 2.826, 5694 ], [ 2.877, 5508 ], [ 2.994, 3930 ], [ 3.071, 3063 ], [ 3.11, 2785 ],[ 3.148, 2661 ],
      [ 3.174, 2599 ], [ 3.329, 1826 ], [ 3.355, 1423 ], [ 3.497, 1114 ], [ 3.523, 959.3 ],
      [ 3.535, 1114 ], [ 3.574, 835.5 ], [ 3.613, 526 ], [ 3.665, 309.4 ], [ 3.703, 154.7 ],
      [ 3.729, 92.83 ], [ 3.819, 0 ]
],
    "totImpulseNs": 19140,
    "totalWeightG": 14860,
    "type": "reload",
    "updatedOn": "2024-04-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 669.9,
    "burnTimeS": 1.89,
    "caseInfo": "54/1200",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "J670",
    "dataFiles": 1,
    "delays": "P",
    "designation": "J670LB",
    "diameter": 54,
    "impulseClass": "J",
    "length": 330,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 824.1,
    "motorId": "66225c88b1ba54000208d7b9",
    "propInfo": "Loki Blue",
    "propWeightG": 578,
    "samples": [
      [ 0, 0 ], [ 0.01573, 7.75 ], [ 0.02374, 55.86 ], [ 0.0305, 207.7 ], [ 0.03696, 369.4 ],
      [ 0.04086, 541.2 ], [ 0.04672, 633.8 ], [ 0.0528, 671.2 ], [ 0.06497, 711.4 ], [ 0.09539, 726.8 ],
      [ 0.14, 729.7 ], [ 0.1846, 727 ], [ 0.2292, 723.2 ], [ 0.2738, 728.2 ], [ 0.3184, 736.6 ],
      [ 0.363, 746.5 ], [ 0.4077, 756.6 ], [ 0.4523, 766.1 ], [ 0.4969, 773.1 ], [ 0.5415, 780.7 ],
      [ 0.5861, 787 ], [ 0.6307, 793.2 ], [ 0.6753, 793.2 ], [ 0.7199, 796.4 ], [ 0.7645, 797.1 ],
      [ 0.8092, 796.4 ], [ 0.8538, 792 ], [ 0.8984, 787.6 ], [ 0.943, 781.4 ], [ 0.9876, 774.5 ],
      [ 1.032, 767 ], [ 1.077, 758.5 ], [ 1.121, 748.2 ], [ 1.166, 737 ], [ 1.211, 721.8 ],
      [ 1.255, 706.9 ], [ 1.3, 695.9 ], [ 1.344, 677.1 ], [ 1.389, 659.3 ], [ 1.434, 647.1 ],
      [ 1.478, 627.7 ], [ 1.523, 608.4 ], [ 1.568, 589.9 ], [ 1.612, 571.2 ], [ 1.657, 554.2 ],
      [ 1.701, 547.4 ], [ 1.732, 536.3 ], [ 1.744, 509.3 ], [ 1.752, 481.8 ], [ 1.76, 452.3 ],
      [ 1.768, 422.9 ], [ 1.776, 395.7 ], [ 1.784, 367.1 ], [ 1.793, 338.3 ], [ 1.801, 304 ],
      [ 1.809, 271.5 ], [ 1.817, 237.3 ], [ 1.825, 205.9 ], [ 1.835, 169.9 ], [ 1.847, 134.3 ],
      [ 1.86, 104.9 ], [ 1.874, 75.58 ], [ 1.892, 45.97 ], [ 1.924, 19.82 ], [ 1.957, 8.696 ]
],
    "totImpulseNs": 1270,
    "totalWeightG": 1204,
    "type": "reload",
    "updatedOn": "2024-07-08"
  },
  {
    "availability": "regular",
    "avgThrustN": 425.7,
    "burnTimeS": 1.19,
    "caseInfo": "38/480",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "I426",
    "dataFiles": 1,
    "delays": "P",
    "designation": "I426LB",
    "diameter": 38,
    "impulseClass": "I",
    "length": 306.8,
    "manufacturer": "Loki Research",
    "manufacturerAbbrev": "Loki",
    "maxThrustN": 718.3,
    "motorId": "66225d52b1ba54000208d807",
    "propInfo": "Loki Blue",
    "propWeightG": 234,
    "samples": [
      [ 0, 0 ], [ 0.01229, 12.33 ], [ 0.02648, 43.11 ], [ 0.03003, 114.1 ], [ 0.032, 182.3 ],
      [ 0.03515, 313.8 ], [ 0.036, 397 ], [ 0.03712, 464.3 ], [ 0.0391, 580.3 ], [ 0.04067, 623.4 ],
      [ 0.04567, 675 ], [ 0.05841, 713.9 ], [ 0.07279, 667.5 ], [ 0.09211, 628.3 ], [ 0.1205, 598.6 ],
      [ 0.1577, 579.7 ], [ 0.1968, 567.8 ], [ 0.2358, 552.7 ], [ 0.2728, 547.2 ], [ 0.316, 542 ],
      [ 0.3575, 539.2 ], [ 0.3954, 533.4 ], [ 0.4344, 526 ], [ 0.4721, 515.9 ], [ 0.5164, 501.4 ],
      [ 0.5551, 490.2 ], [ 0.5949, 475.3 ], [ 0.6331, 462.5 ], [ 0.6705, 445.5 ], [ 0.7111, 428.8 ],
      [ 0.7488, 411.4 ], [ 0.7892, 392 ], [ 0.8282, 373.8 ], [ 0.8672, 355.4 ], [ 0.9063, 337.2 ],
      [ 0.9453, 317.7 ], [ 0.9843, 298.4 ], [ 1.022, 281.4 ], [ 1.062, 261.3 ], [ 1.093, 237.6 ],
      [ 1.11, 211.1 ], [ 1.126, 182.7 ], [ 1.144, 152.6 ], [ 1.16, 121.8 ], [ 1.174, 89.94 ],
      [ 1.19, 58.29 ], [ 1.213, 28.73 ], [ 1.247, 11.73 ], [ 1.273, 7.913 ]
],
    "totImpulseNs": 505,
    "totalWeightG": 553.1,
    "type": "reload",
    "updatedOn": "2024-04-22"
  },
  {
    "availability": "regular",
    "avgThrustN": 2774,
    "burnTimeS": 1.14,
    "caseInfo": "RMS-75/3840",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "L2775",
    "dataFiles": 2,
    "designation": "L2775ST-PS",
    "diameter": 75,
    "impulseClass": "L",
    "length": 558.2,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 3096,
    "motorId": "6623cea6f873440002ac69a1",
    "propInfo": "Super Thunder",
    "propWeightG": 1418,
    "samples": [
      [ 0, 0 ], [ 0.01064, 3.54 ], [ 0.02242, 72.44 ], [ 0.02606, 344.2 ], [ 0.02793, 580.5 ],
      [ 0.02933, 832.9 ], [ 0.03027, 1026 ], [ 0.0312, 1216 ], [ 0.03213, 1405 ], [ 0.03307, 1592 ],
      [ 0.034, 1779 ], [ 0.035, 1923 ], [ 0.03634, 2130 ], [ 0.03821, 2348 ], [ 0.04008, 2489 ],
      [ 0.04391, 2605 ], [ 0.05082, 2637 ], [ 0.0611, 2577 ], [ 0.07215, 2545 ], [ 0.08165, 2539 ],
      [ 0.09193, 2554 ], [ 0.1022, 2566 ], [ 0.1125, 2572 ], [ 0.1228, 2592 ], [ 0.133, 2603 ],
      [ 0.1433, 2603 ], [ 0.1536, 2603 ], [ 0.1639, 2619 ], [ 0.1741, 2648 ], [ 0.1844, 2663 ],
      [ 0.1947, 2673 ], [ 0.205, 2687 ], [ 0.2153, 2692 ], [ 0.2255, 2696 ], [ 0.2358, 2705 ],
      [ 0.2461, 2725 ], [ 0.2564, 2746 ], [ 0.2666, 2745 ], [ 0.2769, 2745 ], [ 0.2872, 2758 ],
      [ 0.2975, 2774 ], [ 0.3078, 2784 ], [ 0.318, 2795 ], [ 0.3283, 2809 ], [ 0.3386, 2814 ],
      [ 0.3489, 2811 ], [ 0.3592, 2811 ], [ 0.3694, 2826 ], [ 0.3797, 2841 ], [ 0.39, 2838 ],
      [ 0.4003, 2844 ], [ 0.4105, 2842 ], [ 0.4208, 2844 ], [ 0.4311, 2848 ], [ 0.4414, 2855 ],
      [ 0.4516, 2865 ], [ 0.4619, 2871 ], [ 0.4722, 2873 ], [ 0.4825, 2878 ], [ 0.4928, 2885 ],
      [ 0.503, 2896 ], [ 0.5133, 2904 ], [ 0.5236, 2913 ], [ 0.5339, 2920 ], [ 0.5442, 2920 ],
      [ 0.5544, 2920 ], [ 0.5647, 2927 ], [ 0.575, 2940 ], [ 0.5853, 2947 ], [ 0.5955, 2952 ],
      [ 0.6058, 2957 ], [ 0.6161, 2958 ], [ 0.6264, 2958 ], [ 0.6367, 2964 ], [ 0.6469, 2973 ],
      [ 0.6572, 2977 ], [ 0.6675, 2977 ], [ 0.6778, 2980 ], [ 0.688, 2979 ], [ 0.6983, 2978 ],
      [ 0.7086, 2979 ], [ 0.7189, 2988 ], [ 0.7291, 2992 ], [ 0.7394, 2989 ], [ 0.7497, 2989 ],
      [ 0.76, 2997 ], [ 0.7703, 3004 ], [ 0.7805, 3005 ], [ 0.7908, 3006 ], [ 0.8011, 3008 ],
      [ 0.8114, 3010 ], [ 0.8216, 3004 ], [ 0.8319, 3006 ], [ 0.8422, 3014 ], [ 0.8525, 3016 ],
      [ 0.8628, 3019 ], [ 0.873, 3016 ], [ 0.8833, 3017 ], [ 0.8936, 3017 ], [ 0.9039, 3017 ],
      [ 0.9142, 3021 ], [ 0.9244, 3024 ], [ 0.9347, 3022 ], [ 0.945, 3022 ], [ 0.9553, 3027 ],
      [ 0.9655, 3031 ], [ 0.9758, 3034 ], [ 0.9861, 3041 ], [ 0.9964, 3046 ], [ 1.007, 3049 ],
      [ 1.017, 3048 ], [ 1.027, 3057 ], [ 1.037, 3069 ], [ 1.048, 3076 ], [ 1.058, 3082 ],
      [ 1.068, 3070 ], [ 1.075, 3028 ], [ 1.081, 2939 ], [ 1.086, 2838 ], [ 1.09, 2737 ],[ 1.093, 2631 ],
      [ 1.096, 2510 ], [ 1.098, 2425 ], [ 1.1, 2334 ], [ 1.101, 2234 ], [ 1.103, 2138 ], [ 1.105, 2030 ],
      [ 1.106, 1933 ], [ 1.109, 1828 ], [ 1.111, 1736 ], [ 1.113, 1650 ], [ 1.115, 1568 ],
      [ 1.118, 1445 ], [ 1.122, 1346 ], [ 1.126, 1255 ], [ 1.13, 1162 ], [ 1.133, 1078 ],[ 1.137, 990.9 ],
      [ 1.141, 900.6 ], [ 1.144, 809.7 ], [ 1.147, 701.4 ], [ 1.151, 585.6 ], [ 1.155, 469.4 ],
      [ 1.159, 362.8 ], [ 1.163, 260.6 ], [ 1.168, 173.4 ], [ 1.177, 79.43 ], [ 1.189, 14.36 ]
],
    "totImpulseNs": 3150,
    "totalWeightG": 3479,
    "type": "reload",
    "updatedOn": "2024-04-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 2693,
    "burnTimeS": 3.8,
    "caseInfo": "RMS-75/10240",
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "M2700",
    "dataFiles": 1,
    "designation": "M2700W-PS",
    "diameter": 75,
    "impulseClass": "M",
    "length": 1233,
    "manufacturer": "AeroTech",
    "manufacturerAbbrev": "AeroTech",
    "maxThrustN": 5554,
    "motorId": "6623cf91f873440002ac6a28",
    "propInfo": "White Lightning",
    "propWeightG": 5275,
    "samples": [
      [ 0, 0 ], [ 0.012, 43.93 ], [ 0.035, 3581 ], [ 0.678, 3690 ], [ 1.404, 3844 ], [ 1.661, 3778 ],
      [ 2.234, 3372 ], [ 2.515, 2350 ], [ 2.947, 1406 ], [ 3.696, 87.87 ], [ 3.801, 0 ]
],
    "totImpulseNs": 10640,
    "totalWeightG": 9058,
    "type": "reload",
    "updatedOn": "2024-04-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 13,
    "burnTimeS": 0.38,
    "certOrg": "Tripoli Rocketry Association, Inc.",
    "commonName": "B14",
    "dataFiles": 2,
    "delays": "3,5,7",
    "designation": "B14T",
    "diameter": 18,
    "impulseClass": "B",
    "length": 79,
    "manufacturer": "Quest Aerospace",
    "manufacturerAbbrev": "Quest",
    "maxThrustN": 17.65,
    "motorId": "6623d0bcf873440002ac79dc",
    "propInfo": "Blue Thunder",
    "propWeightG": 2.3,
    "samples": [
      [ 0, 0 ], [ 0.012, 0.27 ], [ 0.047, 10.64 ], [ 0.054, 11.73 ], [ 0.086, 14.45 ], [ 0.098, 14.91 ],
      [ 0.122, 15.27 ], [ 0.138, 16.27 ], [ 0.15, 17 ], [ 0.18, 17.27 ], [ 0.195, 17.45 ],
      [ 0.212, 17 ], [ 0.218, 16.18 ], [ 0.235, 15.82 ], [ 0.337, 13.55 ], [ 0.352, 6.73 ],
      [ 0.389, 3.09 ], [ 0.4, 1.18 ], [ 0.408, 0.55 ], [ 0.415, 0 ]
],
    "totImpulseNs": 4.97,
    "totalWeightG": 16.1,
    "type": "SU",
    "updatedOn": "2024-04-20"
  },
  {
    "availability": "regular",
    "avgThrustN": 20,
    "burnTimeS": 1,
    "certOrg": "Uncertified",
    "commonName": "D20",
    "dataFiles": 1,
    "delays": "0,2,4,6,8",
    "designation": "D20",
    "diameter": 24,
    "impulseClass": "D",
    "length": 70,
    "manufacturer": "Piotr Tendera Rocket Motors",
    "manufacturerAbbrev": "TSP",
    "motorId": "66a0d3eaa8c15d000226a805",
    "propInfo": "composite",
    "propWeightG": 14.8,
    "samples": [
      [ 0, 0 ], [ 0.008, 0.884 ], [ 0.015, 3.094 ], [ 0.022, 5.083 ], [ 0.033, 7.735 ], [ 0.042, 9.945 ],
      [ 0.053, 12.6 ], [ 0.065, 15.25 ], [ 0.075, 17.24 ], [ 0.087, 19.89 ], [ 0.102, 22.54 ],
      [ 0.116, 25.41 ], [ 0.127, 26.96 ], [ 0.136, 28.51 ], [ 0.148, 30.05 ], [ 0.158, 31.6 ],
      [ 0.175, 33.59 ], [ 0.19, 34.48 ], [ 0.203, 35.14 ], [ 0.22, 34.7 ], [ 0.24, 33.81 ],
      [ 0.258, 32.71 ], [ 0.277, 30.94 ], [ 0.293, 28.95 ], [ 0.312, 26.96 ], [ 0.328, 25.41 ],
      [ 0.341, 23.87 ], [ 0.359, 22.54 ], [ 0.374, 21.66 ], [ 0.394, 20.33 ], [ 0.407, 19.89 ],
      [ 0.417, 19.67 ], [ 0.434, 19.23 ], [ 0.455, 18.56 ], [ 0.471, 18.79 ], [ 0.494, 19.01 ],
      [ 0.517, 19.01 ], [ 0.543, 19.01 ], [ 0.573, 19.01 ], [ 0.6, 19.23 ], [ 0.633, 19.23 ],
      [ 0.675, 18.79 ], [ 0.72, 19.23 ], [ 0.761, 19.23 ], [ 0.802, 18.79 ], [ 0.827, 19.01 ],
      [ 0.85, 19.01 ], [ 0.887, 18.79 ], [ 0.921, 18.56 ], [ 0.949, 18.34 ], [ 0.986, 17.46 ],
      [ 0.998, 17.24 ], [ 1.016, 16.57 ], [ 1.04, 14.59 ], [ 1.063, 12.6 ], [ 1.084, 10.39 ],
      [ 1.105, 7.956 ], [ 1.13, 5.525 ], [ 1.16, 2.652 ], [ 1.181, 1.105 ], [ 1.192, 0.221 ],
      [ 1.2, 0 ]
],
    "totImpulseNs": 20,
    "totalWeightG": 45.5,
    "type": "SU",
    "updatedOn": "2024-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 20,
    "burnTimeS": 2,
    "certOrg": "Uncertified",
    "commonName": "E20",
    "dataFiles": 1,
    "delays": "0,2,4,6,8",
    "designation": "E20",
    "diameter": 24,
    "impulseClass": "E",
    "length": 95,
    "manufacturer": "Piotr Tendera Rocket Motors",
    "manufacturerAbbrev": "TSP",
    "motorId": "66a0d44ba8c15d000226a854",
    "propInfo": "composite",
    "propWeightG": 27.6,
    "samples": [
      [ 0, 0 ], [ 0.011, 2.222 ], [ 0.021, 5.111 ], [ 0.039, 8.667 ], [ 0.051, 12.22 ], [ 0.066, 16.22 ],
      [ 0.077, 18.22 ], [ 0.086, 20.44 ], [ 0.098, 22.44 ], [ 0.109, 24.44 ], [ 0.124, 26.89 ],
      [ 0.137, 29.11 ], [ 0.163, 32.67 ], [ 0.18, 33.78 ], [ 0.201, 35.11 ], [ 0.236, 34.22 ],
      [ 0.259, 31.78 ], [ 0.291, 28.67 ], [ 0.315, 26.22 ], [ 0.343, 23.33 ], [ 0.373, 21.33 ],
      [ 0.402, 19.78 ], [ 0.435, 19.33 ], [ 0.482, 19.11 ], [ 0.544, 18.67 ], [ 0.602, 18.67 ],
      [ 0.674, 18.67 ], [ 0.747, 18.89 ], [ 0.807, 18.89 ], [ 0.891, 18.44 ], [ 0.953, 18 ],
      [ 1.002, 18.22 ], [ 1.079, 18.22 ], [ 1.128, 18.44 ], [ 1.201, 18 ], [ 1.3, 17.78 ],
      [ 1.4, 17.78 ], [ 1.507, 17.33 ], [ 1.599, 16.67 ], [ 1.696, 16.89 ], [ 1.798, 17.11 ],
      [ 1.88, 17.33 ], [ 1.944, 17.56 ], [ 2, 17.11 ], [ 2.036, 15.33 ], [ 2.068, 10.89 ],
      [ 2.096, 7.556 ], [ 2.12, 5.111 ], [ 2.147, 2.222 ], [ 2.173, 0.667 ], [ 2.201, 0 ]
],
    "totImpulseNs": 36,
    "totalWeightG": 64,
    "type": "SU",
    "updatedOn": "2024-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 35,
    "burnTimeS": 2,
    "certOrg": "Uncertified",
    "commonName": "F35",
    "dataFiles": 1,
    "delays": "0,2,4,6,8",
    "designation": "F35",
    "diameter": 29,
    "impulseClass": "F",
    "length": 95,
    "manufacturer": "Piotr Tendera Rocket Motors",
    "manufacturerAbbrev": "TSP",
    "motorId": "66a0d4a4a8c15d000226a881",
    "propInfo": "composite",
    "propWeightG": 42.3,
    "samples": [
      [ 0, 0 ], [ 0.011, 2.034 ], [ 0.018, 4.746 ], [ 0.024, 7.458 ], [ 0.031, 10.51 ], [ 0.037, 13.56 ],
      [ 0.046, 16.95 ], [ 0.053, 20 ], [ 0.061, 23.05 ], [ 0.07, 25.76 ], [ 0.079, 28.48 ],
      [ 0.088, 31.52 ], [ 0.096, 34.91 ], [ 0.105, 37.63 ], [ 0.116, 40.34 ], [ 0.127, 43.73 ],
      [ 0.14, 46.44 ], [ 0.153, 49.49 ], [ 0.166, 51.86 ], [ 0.184, 53.56 ], [ 0.201, 54.91 ],
      [ 0.217, 54.91 ], [ 0.238, 53.22 ], [ 0.252, 51.86 ], [ 0.263, 49.83 ], [ 0.273, 48.14 ],
      [ 0.284, 46.44 ], [ 0.295, 44.75 ], [ 0.306, 43.05 ], [ 0.317, 41.7 ], [ 0.328, 39.66 ],
      [ 0.339, 38.64 ], [ 0.352, 36.95 ], [ 0.365, 35.59 ], [ 0.381, 33.9 ], [ 0.398, 33.22 ],
      [ 0.413, 32.54 ], [ 0.438, 32.2 ], [ 0.453, 32.2 ], [ 0.47, 31.86 ], [ 0.49, 31.86 ],
      [ 0.538, 32.88 ], [ 0.564, 33.22 ], [ 0.606, 32.88 ], [ 0.645, 32.54 ], [ 0.689, 32.2 ],
      [ 0.742, 31.52 ], [ 0.785, 30.85 ], [ 0.827, 30.51 ], [ 0.897, 30.85 ], [ 0.96, 30.85 ],
      [ 1, 30.85 ], [ 1.065, 30.85 ], [ 1.135, 30.51 ], [ 1.201, 29.83 ], [ 1.295, 30.17 ],
      [ 1.321, 30.17 ], [ 1.348, 30.17 ], [ 1.374, 30.51 ], [ 1.4, 30.17 ], [ 1.437, 30.17 ],
      [ 1.479, 29.83 ], [ 1.514, 30.17 ], [ 1.601, 30.17 ], [ 1.663, 29.83 ], [ 1.687, 29.15 ],
      [ 1.715, 28.48 ], [ 1.779, 28.48 ], [ 1.816, 28.14 ], [ 1.853, 28.48 ], [ 1.919, 29.49 ],
      [ 1.947, 29.49 ], [ 1.973, 28.48 ], [ 2.002, 27.8 ], [ 2.024, 26.1 ], [ 2.046, 24.07 ],
      [ 2.065, 21.36 ], [ 2.081, 18.3 ], [ 2.096, 15.59 ], [ 2.109, 12.2 ], [ 2.122, 9.831 ],
      [ 2.135, 7.458 ], [ 2.148, 5.085 ], [ 2.175, 2.034 ], [ 2.201, 0 ]
],
    "totImpulseNs": 65,
    "totalWeightG": 91.5,
    "type": "SU",
    "updatedOn": "2024-07-24"
  },
  {
    "availability": "regular",
    "avgThrustN": 12,
    "burnTimeS": 3.3,
    "certOrg": "Uncertified",
    "commonName": "E12",
    "dataFiles": 1,
    "delays": "0,2,4,6,8",
    "designation": "E12",
    "diameter": 24,
    "impulseClass": "E",
    "length": 95,
    "manufacturer": "Piotr Tendera Rocket Motors",
    "manufacturerAbbrev": "TSP",
    "motorId": "66a28863d1517d00027bf38d",
    "propInfo": "composite",
    "propWeightG": 27.6,
    "samples": [
      [ 0, 0 ], [ 0.028, 0.216 ], [ 0.063, 0.431 ], [ 0.103, 0.647 ], [ 0.151, 1.078 ], [ 0.182, 1.509 ],
      [ 0.218, 2.047 ], [ 0.258, 3.017 ], [ 0.289, 3.987 ], [ 0.329, 5.28 ], [ 0.361, 7.112 ],
      [ 0.385, 8.944 ], [ 0.412, 10.78 ], [ 0.432, 12.82 ], [ 0.448, 14.65 ], [ 0.46, 16.27 ],
      [ 0.468, 17.46 ], [ 0.48, 19.83 ], [ 0.484, 20.8 ], [ 0.492, 22.09 ], [ 0.504, 24.35 ],
      [ 0.519, 25.97 ], [ 0.531, 27.37 ], [ 0.535, 28.34 ], [ 0.555, 27.59 ], [ 0.559, 25.65 ],
      [ 0.571, 23.28 ], [ 0.579, 22.2 ], [ 0.595, 21.88 ], [ 0.615, 21.23 ], [ 0.63, 19.93 ],
      [ 0.65, 18.43 ], [ 0.678, 17.03 ], [ 0.71, 15.95 ], [ 0.749, 14.98 ], [ 0.797, 13.9 ],
      [ 0.833, 13.58 ], [ 0.876, 13.36 ], [ 0.924, 13.36 ], [ 0.967, 12.61 ], [ 0.999, 11.96 ],
      [ 1.039, 11.21 ], [ 1.106, 11.1 ], [ 1.193, 11.21 ], [ 1.249, 11.21 ], [ 1.312, 11.1 ],
      [ 1.376, 11.42 ], [ 1.435, 11.42 ], [ 1.483, 11.21 ], [ 1.55, 11.31 ], [ 1.63, 10.78 ],
      [ 1.741, 10.88 ], [ 1.836, 10.88 ], [ 1.891, 11.21 ], [ 1.907, 11.64 ], [ 1.943, 11.1 ],
      [ 1.998, 11.1 ], [ 2.078, 10.99 ], [ 2.094, 11.53 ], [ 2.197, 11.53 ], [ 2.224, 11.1 ],
      [ 2.276, 11.1 ], [ 2.355, 11.1 ], [ 2.407, 11.42 ], [ 2.47, 11.42 ], [ 2.526, 11.53 ],
      [ 2.581, 11.31 ], [ 2.665, 11.53 ], [ 2.696, 11.31 ], [ 2.732, 11.53 ], [ 2.795, 11.75 ],
      [ 2.847, 11.53 ], [ 2.93, 11.53 ], [ 2.994, 11.85 ], [ 3.033, 11.75 ], [ 3.081, 11.85 ],
      [ 3.125, 11.96 ], [ 3.18, 11.96 ], [ 3.275, 11.75 ], [ 3.319, 10.56 ], [ 3.37, 9.052 ],
      [ 3.414, 6.573 ], [ 3.446, 4.095 ], [ 3.481, 1.832 ], [ 3.505, 0.431 ], [ 3.545, 0 ]
],
    "totImpulseNs": 39,
    "totalWeightG": 64,
    "type": "SU",
    "updatedOn": "2024-07-26"
  },
  {
    "availability": "regular",
    "avgThrustN": 12,
    "burnTimeS": 1.4,
    "certOrg": "Uncertified",
    "commonName": "D12",
    "dataFiles": 1,
    "delays": "0,2,4,6,8",
    "designation": "D12",
    "diameter": 24,
    "impulseClass": "D",
    "length": 70,
    "manufacturer": "Piotr Tendera Rocket Motors",
    "manufacturerAbbrev": "TSP",
    "motorId": "66a288cdd1517d00027bf3fc",
    "propInfo": "composite",
    "propWeightG": 14.8,
    "samples": [
      [ 0, 0 ], [ 0.012, 0.208 ], [ 0.067, 0.312 ], [ 0.111, 0.832 ], [ 0.146, 1.351 ], [ 0.186, 2.079 ],
      [ 0.225, 2.495 ], [ 0.249, 3.326 ], [ 0.277, 4.262 ], [ 0.297, 5.198 ], [ 0.328, 6.341 ],
      [ 0.348, 7.796 ], [ 0.368, 9.148 ], [ 0.388, 10.19 ], [ 0.396, 11.44 ], [ 0.411, 12.89 ],
      [ 0.423, 14.35 ], [ 0.435, 16.11 ], [ 0.443, 17.67 ], [ 0.447, 18.82 ], [ 0.459, 21.1 ],
      [ 0.479, 22.87 ], [ 0.487, 24.32 ], [ 0.498, 25.57 ], [ 0.506, 26.92 ], [ 0.518, 28.07 ],
      [ 0.538, 25.99 ], [ 0.542, 24.01 ], [ 0.55, 22.04 ], [ 0.558, 20.89 ], [ 0.574, 20.48 ],
      [ 0.589, 21.1 ], [ 0.597, 21.73 ], [ 0.625, 20.27 ], [ 0.649, 19.13 ], [ 0.676, 17.67 ],
      [ 0.704, 16.53 ], [ 0.744, 15.49 ], [ 0.783, 14.66 ], [ 0.815, 14.03 ], [ 0.85, 14.03 ],
      [ 0.89, 13.62 ], [ 0.934, 13.31 ], [ 0.961, 12.58 ], [ 1.001, 12.58 ], [ 1.032, 11.64 ],
      [ 1.08, 11.85 ], [ 1.147, 11.54 ], [ 1.226, 11.64 ], [ 1.309, 11.64 ], [ 1.381, 11.64 ],
      [ 1.44, 11.75 ], [ 1.491, 11.75 ], [ 1.551, 11.44 ], [ 1.57, 9.979 ], [ 1.582, 8.42 ],
      [ 1.598, 6.653 ], [ 1.61, 5.301 ], [ 1.63, 3.742 ], [ 1.653, 2.495 ], [ 1.677, 1.143 ],
      [ 1.709, 0.52 ], [ 1.733, 0 ]
],
    "totImpulseNs": 19,
    "totalWeightG": 45.5,
    "type": "SU",
    "updatedOn": "2024-07-25"
  }
]
