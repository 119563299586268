import React, { HTMLAttributes } from 'react';

/**
 * Icons from https://icons.getbootstrap.com/
 *
 * To add an icon, copy/paste the <path> element from the SVG source listed on the icons page.  (E.g. )
 */

export default function Icon({
  name,
  size = '1em',
  color = 'currentColor',
  ...props
}: {
  name: string;
  size?: string;
  color?: string;
} & HTMLAttributes<SVGElement>) {
  let path;
  switch (name) {
    case 'card-fill':
      path = (
        <>
          <path d='M 1.5,2 C 0.75,2 0,2.75 0,3.5 v 9 C 0,13.25 0.75,14 1.5,14 h 13 c 0.75,0 1.5,-0.75 1.5,-1.5 v -9 C 16,2.75 15.25,2 14.5,2 Z m 1,2 v 0 h 11 C 13.823223,4 14,4.25 14,4.5 v 2 C 14,6.8232233 13.75,7 13.5,7 H 2.5 C 2.25,7 2,6.75 2,6.5 v -2 C 2,4.25 2.25,4 2.5,4 Z m 0,4.5 v 0 h 11 C 13.75,8.5 14,8.75 14,9 14,9.25 13.75,9.5 13.5,9.5 H 2.5 C 2.25,9.5 2,9.25 2,9 2,8.75 2.25,8.5 2.5,8.5 Z m 0,2.5 v 0 h 7 C 9.75,11 10,11.25 10,11.5 10,11.75 9.75,12 9.5,12 h -7 C 2.25,12 2,11.75 2,11.5 2,11.25 2.25,11 2.5,11 Z' />
        </>
      );
      break;

    case 'card-heading':
      path = (
        <>
          <path d='M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z' />
          <path d='M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z' />
        </>
      );
      break;

    case 'officer': // Custom icon (variant of person-check, made with Inkscape)
      path = (
        <path d='m 13,2 0.77,2.32 2.22,-0.03 -1.86,1.49 0.71,2.21 -1.92,-1.4 -1.78,1.4 L 11.81,5.64 10,4.29 12.34,4.23 Z M 1,14 c 0,0 -1,0 -1,-1 0,-1 1,-4 6,-4 5,0 6,3 6,4 0,1 -1,1 -1,1 z M 6,8 C 10,8 10,2 6,2 2,2 2,8 6,8 Z' />
      );
      break;

    case 'house-fill':
      path = (
        <>
          <path
            fillRule='evenodd'
            d='m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z'
          />
          <path
            fillRule='evenodd'
            d='M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z'
          />
        </>
      );
      break;

    case 'gear-fill': // Custom (variant of gear-fill)
      path = (
        <path d='M 4.7,13 2.95,14.15 1.85,13.05 3,11.3 2.15,9.2 0.05,8.75 V 7.25 L 2.15,6.8 3,4.7 1.85,2.95 2.95,1.85 4.7,3 6.8,2.15 7.25,0.05 h 1.5 L 9.2,2.15 11.3,3 13.05,1.85 14.15,2.95 13,4.7 l 0.85,2.1 2.1,0.45 v 1.5 l -2.1,0.45 -0.85,2.1 1.15,1.75 -1.1,1.1 L 11.3,13 9.2,13.85 8.75,15.95 H 7.25 L 6.8,13.85 Z M 8,5 C 6.33,4.99 5,6.34 5,8 5,9.66 6.34,11 8,11 9.66,11 11,9.65 11,8 11,6.35 9.67,5.01 8,5 Z' />
      );
      break;

    case 'pencil-fill':
      path = (
        <>
          <path d='M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z' />
        </>
      );
      break;

    case 'person-square':
      path = (
        <>
          <path d='M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' />
          <path d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z' />
        </>
      );
      break;

    case 'people-fill':
      path = (
        <>
          <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
          <path d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z' />
          <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z' />
        </>
      );
      break;

    case 'ui-checks':
      path = (
        <path d='M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z' />
      );
      break;

    case 'rocket': // Custom icon, made with Inkscape
      path = (
        <path d='M 16,0 C 14,0 13,1 12,2 L 6,8 H 3 l -2,2 h 3 l 2,2 v 3 L 8,13 V 10 L 14,4 C 15,3 16,2 16,0 Z M 2.972,10.5 C 2.851,10.5 2.737,10.555 2.65,10.642 L 1,12.287 c -0.193,0.192 -0.193,0.51 0,0.7 0.192,0.189 0.5,0.189 0.699,0 l 1.65,-1.645 c 0.193,-0.193 0.193,-0.51 0,-0.7 C 3.251,10.544 3.116,10.492 2.972,10.5 Z m 1.012,0.998 c -0.129,0 -0.249,0.055 -0.34,0.146 L 0.423,14.82 c -0.192,0.193 -0.192,0.5 0,0.7 0.193,0.189 0.5,0.189 0.7,0 l 3.232,-3.178 c 0.189,-0.193 0.189,-0.5 0,-0.699 C 4.276,11.565 4.162,11.513 4.044,11.5 4.026,11.5 4,11.5 3.988,11.5 Z m 0.986,0.998 c -0.011,0 -0.026,-0 -0.041,0 -0.1,0.011 -0.2,0.061 -0.287,0.136 l -1.644,1.657 c -0.193,0.192 -0.193,0.51 0,0.7 0.192,0.189 0.5,0.189 0.699,0 l 1.652,-1.649 c 0.193,-0.193 0.193,-0.51 0,-0.7 C 5.251,12.544 5.114,12.494 4.974,12.498 Z' />
      );
      break;
  }

  return (
    <svg width={size} height={size} fill={color} viewBox='0 0 16 16' {...props}>
      {path}
    </svg>
  );
}
